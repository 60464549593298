<template>
  <Card class="!tw-max-h-[unset] !tw-min-w-[179px] !tw-p-4 tw-box-border">
    <template #content>
      <main class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
        <template v-if="!props.hideFolderUpload">
          <section
            v-for="(item, index) in menu"
            :key="index"
            @click="onHandleAction(item.name)"
            class="tw-flex tw-items-center tw-gap-2 tw-py-2 tw-px-3 pw-box-border tw-rounded-md hover:tw-bg-[#FFF6E5] tw-cursor-pointer"
          >
            <component :is="item.icon"></component>
            <Text
              class="hover:!tw-text-[#FFA500] !tw-capitalize"
              variant="p"
              textWeight="400"
              >{{ item.name }}</Text
            >
          </section>
        </template>
        <section
          v-else
          @click="onHandleAction(documentConstants.FILE)"
          class="tw-flex tw-items-center tw-gap-2 tw-py-2 tw-px-3 pw-box-border tw-rounded-md hover:tw-bg-[#FFF6E5] tw-cursor-pointer"
        >
          <component :is="UploadIcon"></component>
          <Text
            class="hover:!tw-text-[#FFA500] !tw-capitalize"
            variant="p"
            textWeight="400"
            >{{ documentConstants.FILE }}</Text
          >
        </section>

        <v-divider></v-divider>
        <section
          @click="onHandleAction(documentConstants.NEW_FOLDER)"
          class="tw-flex tw-items-center tw-gap-2 tw-p-2 tw-px-3 pw-box-border tw-rounded-md hover:tw-bg-[#FFF6E5] tw-cursor-pointer"
        >
          <AddMiniFolderIcon />
          <Text class="hover:!tw-text-[#FFA500]" variant="p" textWeight="400"
            >New folder</Text
          >
        </section>
      </main>
    </template>
  </Card>
</template>
<script setup>
import { reactive, onMounted, shallowRef } from "vue";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import AddMiniFolderIcon from "@/core/components/icons/AddMiniFolderIcon.vue";
import MiniFolderIcon from "@/core/components/icons/MiniFolderIcon.vue";
import UploadIcon from "@/core/components/icons/UploadIcon.vue";
import documentConstants from "@/modules/documents/contants";
const emits = defineEmits(["on-handle-action"]);
const props = defineProps(["hideFolderUpload"]);

const menu = reactive([
  {
    icon: shallowRef(UploadIcon),
    name: documentConstants.FILE,
  },
  {
    icon: shallowRef(MiniFolderIcon),
    name: documentConstants.FOLDER,
  },
]);

const onHandleAction = (name) => {
  emits("on-handle-action", name);
};
</script>
<style lang="scss" scoped></style>
