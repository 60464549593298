<template>
  <div class="chip__state" :class="getChipDetail.class">
    <v-icon v-if="getChipDetail" :icon="getChipDetail.icon"></v-icon>
    <span>{{ status }}</span>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { NWGStatusEnum } from "@/core/enums/nwgJobEnum";

const props = defineProps({
  status: {
    type: String,
  },
});

const getChipDetail = computed(() =>
  props.status === NWGStatusEnum.COMPLETED
    ? { icon: "mdi-check", class: "completed__chip" }
    : props.status === NWGStatusEnum.OPEN
    ? { icon: "mdi-circle-slice-8", class: "open__chip" }
    : props.status === NWGStatusEnum.CANCELLED
    ? { icon: "mdi-close", class: "cancelled__chip" }
    : props.status === NWGStatusEnum.LOCKED
    ? { icon: "mdi-circle-slice-8", class: "locked__chip" }
    : { icon: "mdi-check", class: "completed__chip" }
);
</script>
<script>
export default {
  name: "StatusChip",
};
</script>
<style lang="scss" scoped>
.chip__state {
  @include fluidFont(12, 12, 18px);
  font-weight: 500;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  max-width: max-content;
  padding: 5px 12px 5px 6px;
  text-transform: capitalize;
  .v-icon {
    font-size: 12px;
  }
}
.completed__chip {
  border: 1px solid #abefc6;
  background-color: #ecfdf3;
  color: #067647;
}
.cancelled__chip {
  border: 1px solid #fecdca;
  background-color: rgba(254, 243, 242, 1);
  color: rgba(180, 35, 24, 1);
}
.open__chip {
  border: 1px solid rgba(75, 75, 255, 1);
  background-color: rgba($white, 1);
  color: rgba(52, 64, 84, 1);
  .v-icon {
    color: rgba(75, 75, 255, 1);
  }
}
.locked__chip {
  border: 1px solid rgba(59, 199, 64, 1);
  background-color: rgba($white, 1);
  color: rgba(52, 64, 84, 1);
  .v-icon {
    color: rgba(59, 199, 64, 1);
  }
}
</style>
