<template>
  <div v-if="isLoading">
    <Loader :show="isLoading" class="tw-z-10" />
  </div>
  <div
    @click="onRouteToTradePassport"
    class="tw-flex tw-items-center tw-gap-[.1rem] tw-cursor-pointer tw-pb-4 tw-w-full"
  >
    <v-icon icon="mdi-chevron-left"></v-icon>
    <Text variant="p" textWeight="600">Back</Text>
  </div>
  <div
    v-if="!isLoading && tradePassportExperiences?.length"
    class="tw-max-w-[1300px] tw-w-full md:!tw-pb-[4rem] md:!tw-box-border"
  >
    <div
      class="tw-w-full tw-rounded-lg tw-bg-[#F8FBFD] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-3"
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-pb-3 tw-box-border tw-gap-4 tw-border-b-dashed-custom"
      >
        <div class="tw-flex tw-flex-col tw-gap-2 tw-justify-start">
          <Text
            variant="h6"
            class="rmd:!tw-text-[22px]"
            lineHeight="18px"
            textAlign="left"
            >Experience</Text
          >
          <Text
            variant="p"
            textWeight="600"
            textColor="rgba(12, 15, 74, 0.8)"
            lineHeight="18px"
            textAlign="left"
            class="md:!tw-hidden"
            >{{ tradePassportExperiences?.length }} Results</Text
          >
        </div>
        <Button
          v-if="!isUserProprtyOwner"
          variant="secondary"
          label="+Add"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          @click="onOpenExperienceModal('add')"
        />
      </div>
      <div class="tw-flex tw-flex-col tw-w-full">
        <!-- items loop here -->
        <div
          v-for="(experience, index) in tradePassportExperiences"
          :key="index"
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-4 tw-box-border"
          :class="{
            'tw-border-b-dashed-custom':
              index !== tradePassportExperiences.length - 1,
            '!tw-pt-0': index === 0,
          }"
        >
          <div class="tw-flex tw-items-start tw-gap-3 tw-w-full md:!tw-gap-2">
            <div
              class="tw-min-w-[68px] tw-w-[68px] tw-h-[68px] tw-rounded-[4.85px] tw-bg-[rgba(12,15,74,0.04)] tw-border-solid tw-border-[#DFDFDF] tw-border-[1px] tw-overflow-hidden md:!tw-min-w-[55px] md:!tw-w-[55px] md:!tw-h-[55px]"
            >
              <img
                v-if="experience?.imageUrl"
                :src="experience?.imageUrl"
                class="tw-w-full tw-h-full tw-object-cover"
              />
              <img
                v-else
                src="@/assets/images/tradepassport-empty-thumbnail.png"
                class="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
            <div
              class="tw-w-full tw-flex tw-items-start tw-justify-between tw-gap-1"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-[.1rem] tw-items-start"
              >
                <div class="tw-flex tw-items-center tw-gap-2">
                  <Text
                    variant="h6"
                    textAlign="left"
                    lineHeight="18px"
                    class="tw-w-[70%] md:!tw-text-[.8rem]"
                    whiteSpace="nowrap"
                    >{{ experience?.companyName }}</Text
                  >
                </div>
                <Text
                  variant="p"
                  textColor="rgba(12, 15, 74, 0.8)"
                  lineHeight="18px"
                  class="md:!tw-text-[.7rem]"
                >
                  {{ experience?.title }}</Text
                >
                <Text
                  variant="p"
                  textColor="rgba(12, 15, 74, 0.8)"
                  lineHeight="18px"
                  class="md:!tw-text-[.7rem]"
                >
                  {{
                    appFilters.formatToDate(experience?.startDate, "MMMM YYYY")
                  }}
                  -
                  {{
                    experience?.endDate
                      ? appFilters.formatToDate(
                          experience?.endDate,
                          "MMMM YYYY"
                        )
                      : "Present"
                  }}</Text
                >
              </div>
              <div
                class="tw-flex tw-items-center tw-gap-2 md:!tw-gap-1"
                v-if="getUserWorkStationFieldAccess"
              >
                <BlueCircularPencilIcon
                  @click="onOpenExperienceModal('edit', experience)"
                  class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
                  v-if="!isUserProprtyOwner"
                />
                <GrayCircularTrashIcon
                  class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
                  @click="onOpenDeleteModal(experience.id)"
                  v-if="!isUserProprtyOwner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="!isLoading && !tradePassportExperiences?.length"
    class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto tw-w-full"
  >
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img md:!tw-w-[50%]">
        <img
          :src="require('../../../assets/images/trade_insurance_empty.png')"
          alt=""
          class="tw-w-full"
        />
      </div>

      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any Experience yet!</h4>
        <p>
          You haven't added any Experience details yet. Upload your Experience
          to build trust and meet industry standards."
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any Experience yet!</h4>
        <p>haven't added any Experience details to this profile yet</p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          @click="onOpenExperienceModal('add')"
          v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
          class="button button-purple"
          width="100%"
          >Add New Experience</v-btn
        >
      </div>
    </div>
  </div>

  <!-- MODAL -->
  <AddOrEditExperienceModal
    v-if="showAddorEditExperienceModal"
    @on-close="onCloseExperienceModal"
    @on-success="initialize"
    :experienceModalType="experienceModalType"
    :selectedExperienceData="selectedExperienceData"
  />

  <ConfirmDeleteExperienceModal
    v-if="showConfirmDeleteMoal"
    :id="selectedId"
    @on-close="onCloseDeleteModal"
    @on-success="onSuccessDelete"
  />
</template>
<script setup>
import { computed, onMounted, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { USER_STORE } from "@/store/modules/user";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { TRADE_PASSPOSRT_ROUTE } from "../routes";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import GrayCircularTrashIcon from "@/core/components/icons/GrayCircularTrashIcon.vue";
import AddTradePassportClientModal from "./modals/AddTradePassportClientModal.vue";
import Loader from "@/core/components/common/Loader.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";
import appFilters from "@/filters";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import AddOrEditExperienceModal from "./modals/AddOrEditExperienceModal.vue";
import ConfirmDeleteExperienceModal from "./modals/ConfirmDeleteExperienceModal.vue";

const store = useStore();
const router = useRouter();
const internalInstance = getCurrentInstance();

const showAddorEditExperienceModal = ref(false);
const isLoading = ref(false);
const showConfirmDeleteMoal = ref(false);
const selectedId = ref(null);
const experienceModalType = ref("");
const selectedExperienceData = ref(null);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const tradePassportExperiences = computed(() => {
  return store.getters[`${USER_STORE}/userExperiences`];
});

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);
const contractorTradePassportWorkstationId = computed(
  () =>
    store.getters[
      `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
    ]
);
const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const getUserWorkStationFieldAccess = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
);

const onOpenExperienceModal = (type, experience = null) => {
  if (experience) {
    selectedExperienceData.value = experience;
  }
  experienceModalType.value = type;
  showAddorEditExperienceModal.value = true;
};

const onCloseExperienceModal = () => {
  selectedExperienceData.value = null;
  showAddorEditExperienceModal.value = false;
};

const onOpenDeleteModal = (id) => {
  selectedId.value = id;
  showConfirmDeleteMoal.value = true;
};

const onCloseDeleteModal = () => {
  showConfirmDeleteMoal.value = false;
};

const onSuccessDelete = async () => {
  selectedId.value = null;
  await initialize();
  onCloseDeleteModal();
  // displayToast here
  displayToastMessage(internalInstance, "Deleted Successfully", "success");
};

const onRouteToTradePassport = async () => {
  await router.push({ name: TRADE_PASSPOSRT_ROUTE });
};

const initialize = async () => {
  try {
    isLoading.value = true;
    if (user.value?.id) {
      await store.dispatch(
        `${USER_STORE}/getAllTradePassportExperience`,
        user.value?.id
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
    console.log(tradePassportExperiences.value, "tradePassportExperiences");
  }
};

onBeforeMount(async () => {
  await initialize();
});
</script>
<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
</style>
