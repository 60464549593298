<template>
  <main
    class="estimate-received-preview tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-4"
  >
    <section
      class="estimate-received-preview__container tw-flex tw-items-start tw-justify-between tw-py-4 tw-box-border tw-border-[0] tw-border-b-[1px] tw-border-[#C5D2F2] tw-border-solid"
    >
      <BackButton label="Back to Details" @click="$emit('view-details')" />
      <div
        v-if="isProjectPending"
        class="tw-flex tw-items-center tw-gap-4 lg:tw-hidden"
      >
        <Button
          class="!tw-w-[200px]"
          variant="secondary"
          label="REJECT"
          @click="onClickRejectEstimate"
        />
        <Button
          class="!tw-w-[200px]"
          label="ACCEPT"
          @click="onClickAcceptEstimate"
        />
      </div>
    </section>
    <section
      class="tw-gap-10 tw-flex xl:tw-gap-5 2xl:tw-flex-col"
      v-if="details"
    >
      <div
        class="tw-flex tw-flex-col tw-gap-4 tw-w-[340px] tw-max-w-full lg:tw-w-full"
      >
        <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start">
          <ContractorDetailsCard :contractorDetails="contractorDetails" />
        </div>
      </div>
      <div
        v-if="isProjectPending"
        class="lg:tw-flex tw-items-center tw-gap-4 tw-hidden sm:tw-flex-col"
      >
        <Button
          class="!tw-w-[200px] sm:!tw-w-full"
          variant="secondary"
          label="REJECT"
          @click="onClickRejectEstimate"
        />
        <Button
          class="!tw-w-[200px] sm:!tw-w-full"
          label="ACCEPT"
          @click="onClickAcceptEstimate"
        />
      </div>
      <div
        class="tw-pb-2 tw-box-border tw-flex tw-flex-col tw-gap-4 tw-w-[calc(100%_-_380px)] tw-max-w-full 2xl:tw-w-full lg:tw-pr-0 lg:tw-pl-0"
      >
        <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
          <div class="tw-w-full tw-flex tw-items-center tw-justify-start">
            <Text variant="h3">{{ details?.project?.name }}</Text>
          </div>
          <div
            class="tw-w-full tw-flex tw-items-center tw-justify-start tw-gap-8"
          >
            <CommercialChip />
            <LabelWithValue
              :justifyStart="true"
              :noBg="true"
              :data="details?.project?.refId"
              label="Project ID:"
            />
          </div>
        </div>
        <v-divider></v-divider>
        <div
          class="tw-bg-white tw-rounded-lg tw-px-4 tw-py-5 tw-shadow-[0px_0px_8px_0px_#0C0F4A14] tw-text-darkBlue tw-text-left"
          v-if="emergencyCallOutDetails"
        >
          <div
            class="tw-flex tw-gap-y-4 tw-gap-x-6 rmd:tw-gap-x-16 tw-flex-wrap"
          >
            <div>
              <div class="tw-text-[12px] tw-font-bold tw-tracking-[0.15px]">
                <p>Emergency call out fee</p>
              </div>
              <div class="tw-tracking-[0.25px] tw-text-[10px] tw-mt-1">
                <p>£{{ emergencyCallOutDetails.emergencyCallOutFee }}</p>
              </div>
            </div>
            <div>
              <div class="tw-text-[12px] tw-font-bold tw-tracking-[0.15px]">
                <p>Emergency call out rate</p>
              </div>
              <div class="tw-tracking-[0.25px] tw-text-[10px] tw-mt-1">
                <p>
                  £{{ emergencyCallOutDetails.emergencyCallOutRate }} / hour
                </p>
              </div>
            </div>
          </div>
          <v-divider class="tw-my-3"></v-divider>
          <div>
            <div class="tw-text-[12px] tw-font-bold tw-tracking-[0.15px]">
              <p>Contractor Note</p>
            </div>
            <div class="tw-tracking-[0.25px] tw-text-[10px] tw-mt-1">
              <p v-html="emergencyCallOutDetails.notes"></p>
            </div>
          </div>
        </div>
        <EstimateReceivedSummaryCard :selectedEstimate="details" />
      </div>
    </section>

    <GeneralDialog
      v-if="isAcceptEstimate || isRejectEstimate"
      width="450px"
      maxWidth="100%"
      :isShowHeader="false"
      class="project_accept_reject__modal"
      :isShowCloseButton="false"
      ><template #body>
        <div class="estimate_icon">
          <img src="@/assets/icons/notepad.svg" alt="" />
        </div>
        <div class="estimate_text">
          <h6>
            Are you sure you want to
            {{ isAcceptEstimate ? "Accept" : "Reject" }} this Historic project?
          </h6>
        </div>
      </template>
      <template #footer>
        <div class="estimate_footer">
          <Button
            variant="tertiary"
            label="cancel"
            class="button button-purple-border"
            @click="
              isAcceptEstimate
                ? onCloseAcceptEstimate()
                : onCloseRejectEstimate()
            "
          />
          <Button
            :label="isAcceptEstimate ? 'Accept' : 'Reject'"
            class="button button-orange"
            @click="
              isAcceptEstimate ? showLoadingScreen() : onConfirmRejectEstimate()
            "
          /></div
      ></template>
    </GeneralDialog>

    <v-dialog
      width="390px"
      max-width="100%"
      v-model="isPostLoadingModal"
      class="modal_box action-modal"
      persistent
    >
      <div class="modal_wrapper">
        <main class="main">
          <div class="content-loader">
            <p class="switching-text">{{ switchingText }}</p>
            <div class="loading-dots">
              <img
                class=""
                cover
                :src="require('@/assets/icons/' + switchingImage)"
              />
            </div>
            <div class="loader__footer">
              <v-stepper
                class="onboard-stepper"
                v-model="currentSelectedTab.name"
              >
                <v-stepper-header class="onboard-stepper__header">
                  <v-stepper-item
                    class="onboard-stepper__item"
                    v-for="(step, index) in tabListItems"
                    :key="index"
                    :value="step.name"
                  ></v-stepper-item>
                </v-stepper-header>
              </v-stepper>
              <v-btn
                class="button button-orange"
                @click="cancelAcceptingProject"
                width="100%"
              >
                Cancel</v-btn
              >
            </div>
          </div>
        </main>
      </div>
    </v-dialog>

    <commonDialog
      v-model="isFinalDialog"
      :isShowHeader="false"
      :isShowBackButton="false"
      width="465px"
      max-width="100%"
      height="auto"
      persistent
      :class="['succes_tradeowner_modal']"
    >
      <template v-slot:body>
        <div class="modal__text">
          <div class="header-title">
            Great job,
            <strong class="tw-ml-2 !tw-capitalize">{{
              details?.workStation?.name
            }}</strong>
          </div>
          <div class="header-sub-title">
            You have successfully added your Record Historic Project
          </div>
        </div>
        <div class="custom__modal__img">
          <img
            src="@/assets/images/ws-creation-success-image.png"
            alt="Accept Historic Project"
          />
        </div>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste
          corporis est a. Temporibus reiciendis tempora quisquam?
        </p>

        <v-btn
          class="button button-orange !tw-mt-[16px] rmd:!tw-mt-[24px]"
          size="large"
          block
          @click="router.push('/project')"
        >
          continue 🚀
        </v-btn>
      </template>
    </commonDialog>
  </main>
</template>
<script lang="ts" setup>
import { computed, ref, onBeforeMount } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import BackButton from "@/core/components/common/BackButton.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ContractorDetailsCard from "@/modules/project/components/common/ContractorDetailsCard.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import EstimateReceivedSummaryCard from "@/modules/project/components/common/EstimateReceivedSummaryCard.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { HistoricProjectStatusEnum } from "@/core/enums/ProjectsEnum";
import JobService from "@/core/services/jobs.service";
import notificationService from "@/core/services/notification.service";
import { USER_STORE } from "@/store/modules/user";

const props = defineProps({
  estimateId: {
    type: Number,
  },
});

const router = useRouter();
const store = useStore();
const details = ref(null) as any;
const isAcceptEstimate = ref(false);
const isRejectEstimate = ref(false);
const currentSelectedTab = ref({ name: "tab1" });
const emergencyCallOutDetails = ref(null) as any;
const isPostLoadingModal = ref(false);
const isFinalDialog = ref(false);
const switchingText = ref("Loading");
const switchingImage = ref("custom-loader.svg");
const tabListItems = ref([
  { name: "tab1" },
  { name: "tab2" },
  { name: "tab3" },
]);

const contractorDetails = computed(() => {
  return details.value?.workStation;
});

const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

const showLoadingScreen = async () => {
  isAcceptEstimate.value = false;
  isPostLoadingModal.value = true;
  // First step: Wait for 1 second
  await delay(1000);
  currentSelectedTab.value = tabListItems.value[0];

  // Second step: Wait for 2 seconds
  await delay(1500);
  currentSelectedTab.value = tabListItems.value[1];

  // Third step: Wait for 5 seconds
  await delay(1000);
  currentSelectedTab.value = tabListItems.value[2];
  if (isPostLoadingModal.value) {
    await onConfirmEstimate();
    isFinalDialog.value = true;
  }
  isPostLoadingModal.value = false;
};

const cancelAcceptingProject = () => {
  isPostLoadingModal.value = false;
};

const onClickRejectEstimate = () => {
  isRejectEstimate.value = true;
};

const onClickAcceptEstimate = () => {
  isAcceptEstimate.value = true;
};

const onConfirmEstimate = async () => {
  isAcceptEstimate.value = false;
  try {
    await store.dispatch(`${PROJECT_STORE}/updateHistoricalProjectStatus`, {
      projectId: details.value?.project.id,
      data: {
        historicalProjectStatus: HistoricProjectStatusEnum.ACCEPTED,
        projectEstimateId: details.value?.id,
      },
    });
    resetNotification();
  } catch (error) {
    console.log("error", error);
  }
};

const resetNotification = async () => {
  store.commit(`${USER_STORE}/resetUserNotifications`);
  const userNotifications =
    (await notificationService.getUserNotificationStatus()) as any;
  userNotifications.forEach((userNotification: object) => {
    store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
  });
};

const onCloseRejectEstimate = () => {
  isRejectEstimate.value = false;
};

const onCloseAcceptEstimate = () => {
  isAcceptEstimate.value = false;
};

const onConfirmRejectEstimate = async () => {
  await store.dispatch(`${PROJECT_STORE}/updateHistoricalProjectStatus`, {
    projectId: details.value?.project.id,
    data: {
      historicalProjectStatus: HistoricProjectStatusEnum.REJECTED,
      projectEstimateId: details.value?.id,
    },
  });
  resetNotification();
  router.push("/properties");
};

const isProjectPending = computed(
  () =>
    details.value?.project?.projectHistoricalStatus ==
    HistoricProjectStatusEnum.PENDING
);

onBeforeMount(async () => {
  details.value = await store.dispatch(
    `${TRADE_ESTIMATE_STORE}/setTradeEstimateDetails`,
    {
      projectEstimateId: props.estimateId,
    }
  );
  emergencyCallOutDetails.value =
    await JobService.getEmergencyJobCallOutDetails(details.value?.project?.id);
});
</script>
<style lang="scss" scoped>
.custom_selector {
  :deep(.v-input__control) {
    .v-field {
      background-color: transparent;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      padding: 0;
      .v-field__field {
        .v-field__input {
          min-height: 40px;
          padding: 12px 16px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          opacity: 1;
          .v-select__selection {
            .v-select__selection-text {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }
          input {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              opacity: 1;
            }
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}

.date_selector {
  :deep(.dp__input_wrap) {
    .dp__input {
      min-height: 40px;
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.4px;
      padding: 12px 30px;
      border-radius: 8px;
    }
    &::placeholder {
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.4px;
      opacity: 1;
    }
  }
}

.switching-text {
  text-align: center;
  @include fluidFont(18, 18, 1.3);
  font-weight: 400;
  color: rgba($PrimaryBlue, 1);
  letter-spacing: 0.4px;
}

.progress-bar {
  background-color: #ddd;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  background-color: #4caf50;
  height: 100%;
  width: 0;
  transition: width 5s linear;
}

.action-modal .modal_wrapper {
  width: 100%;
  border-radius: 30px;
}

.action-modal .modal_wrapper {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      111.5deg,
      rgba(245, 250, 255, 0.3) 0%,
      rgba(211, 233, 255, 0.3) 100%
    ) !important;
}
.modal_wrapper {
  max-width: 350px;
  .main {
    padding: 32px;
    min-height: 330px;
    .loader__content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      min-height: 250px;
      .loader__icon {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
.estimate_icon {
  text-align: center;
}
.estimate_text {
  text-align: center;
  margin-top: 16px;
  h6 {
    @include fluidFont(24, 24, 1.3);
    font-weight: 700;
    color: rgba($blueDark, 1);
    max-width: 70%;
    margin: 0 auto;
  }
  p {
    margin: 40px auto 0;
    @include fluidFont(16, 16, 1.3);
    font-weight: 500;
    color: rgba($blueDark, 0.5);
    max-width: 80%;
    span {
      color: rgba($blueDark, 1);
    }
  }
}
.estimate_footer {
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;
  margin-top: 40px;
  .button {
    width: calc(50% - 6px);
  }
}

.loading-dots {
  position: relative;

  border-radius: 160px;
  background-color: $white;
  margin: 0 auto;
  box-shadow: 1px 1px 5px #f5f5f5;
}
// .switching-text {
//   text-align: center;
//   color: $PrimaryBlue;
//   margin-top: 3rem;
// }
.action-modal .modal_wrapper {
  width: 100%;
  border-radius: 30px;
}

.action-modal .modal_wrapper {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      111.5deg,
      rgba(245, 250, 255, 0.3) 0%,
      rgba(211, 233, 255, 0.3) 100%
    ) !important;
}
.content-loader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 330px;
}
</style>
