<template>
  <div class="job_overview__wrapper">
    <div class="job_overview__profile">
      <div class="job_overview_header">
        <h4>Overview</h4>
        <div class="status_label">
          <AcceptChip label="Verified Client" />
          <v-icon
            icon="mdi-information-variant-circle-outline"
            size="small"
            color="#0C0F4A66"
          ></v-icon>
        </div>
      </div>
      <div class="client_info">
        <h5>Client Information</h5>

        <UserDetailCard
          :name="jobDetail?.customerName"
          :isShowMemberJoinDate="true"
        />
      </div>
    </div>
    <div class="job_overview__content">
      <div class="job_overview__title">
        <h5>Job Overview</h5>
      </div>
      <div class="job_overview__list">
        <div class="job_overview__list__item">
          <div class="left__block">
            <p>Job Name</p>
          </div>
          <div class="right__block">
            <p>{{ jobDetail?.name || "--" }}</p>
          </div>
        </div>
        <div class="job_overview__list__item">
          <div class="left__block">
            <p>Date Posted</p>
          </div>
          <div class="right__block">
            <p>{{ formateDate(jobDetail?.dateCreated) }}</p>
          </div>
        </div>
        <div class="job_overview__list__item">
          <div class="left__block">
            <p>Urgency</p>
          </div>
          <div class="right__block">
            <p>Emergency</p>
          </div>
        </div>
        <div class="job_overview__list__item">
          <div class="left__block">
            <p>Associated Properties</p>
          </div>
          <div class="right__block">
            <p>1</p>
          </div>
        </div>
        <div class="job_overview__list__item">
          <div class="left__block">
            <p>Site Visit</p>
          </div>
          <div class="right__block">
            <p>Enabled</p>
          </div>
        </div>
      </div>
    </div>
    <div class="job_action">
      <Button
        variant="tertiary"
        label="Add customer notes"
        width="auto"
        :disabled="jobCompleted"
        @click="onClickAddCustomerNote"
        class="button button-purple-border"
      />
      <Button
        variant="tertiary"
        label="Mark job as complete"
        :disabled="jobCompleted"
        @click="onClickMarkJobComplete"
        width="auto"
        class="button button-orange"
      />
    </div>
  </div>

  <AddCustomerNotesModal
    v-if="isShowAddCustomerNotesModal"
    @on-close="toggleAddCustomerNotesModal"
    @on-submit="onSubmitNote"
    :isJobCompleted="isJobCompleted"
  />
  <!--  -->
  <JobConfirmModal
    v-if="isShowConfirmModal"
    :isShowUserDetails="isJobCompleted"
    :title="
      isJobCompleted
        ? 'Job Complete'
        : 'Your customer notes have been submitted'
    "
    @on-continue="toggleConfirmModal"
  />
</template>

<script setup>
import UserDetailCard from "@/core/components/common/UserDetailCard.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { formateDate } from "@/core/utils/common";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import { NWG_JOBS_STORE } from "@/store/modules/nwg-job";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import AddCustomerNotesModal from "./models/AddCustomerNotesModal.vue";
import JobConfirmModal from "./models/JobConfirmModal.vue";
import { NWGJobStatus, NWGStatusEnum } from "@/core/enums/nwgJobEnum";
const store = useStore();
const jobDetail = computed(
  () => store.getters[`${NWG_JOBS_STORE}/getJobDetails`]
);
const isJobCompleted = ref(false);

const isShowAddCustomerNotesModal = ref(false);
const isShowConfirmModal = ref(false);
const toggleAddCustomerNotesModal = () => {
  isShowAddCustomerNotesModal.value = !isShowAddCustomerNotesModal.value;
};
const onClickAddCustomerNote = () => {
  isJobCompleted.value = false;
  toggleAddCustomerNotesModal();
};

const jobCompleted = computed(
  () =>
    jobDetail.value.jobStatus === NWGJobStatus.COMPLETED &&
    jobDetail.value.status === NWGStatusEnum.COMPLETED
);
const toggleConfirmModal = () => {
  isShowConfirmModal.value = !isShowConfirmModal.value;
};
const onClickMarkJobComplete = () => {
  if (jobCompleted.value) return;
  isJobCompleted.value = true;
  toggleAddCustomerNotesModal();
};
const onSubmitNote = () => {
  toggleAddCustomerNotesModal();
  toggleConfirmModal();
};
</script>
<script>
export default {};
</script>

<style scoped lang="scss">
.job_overview__wrapper {
  border: 1px solid rgba($bordercolor, 1);
  border-radius: 8px;
  padding: 16px 24px;
  width: 348px;
  max-width: 100%;
  background-color: white;
  margin-top: 0;
  height: 100%;
  max-height: 100%;
  @include respond(s1024) {
    width: 100%;
    margin: 0;
  }
  h5 {
    @include fluidFont(16, 16, 22.59px);
    font-weight: 700;
    color: rgba($blueDark, 1);
  }
  p {
    @include fluidFont(12, 12, 22px);
    font-weight: 400;
    letter-spacing: 0.25px;
    color: rgba($blueDark, 1);
  }
  .job_overview__profile {
    .job_overview_header {
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .status_label {
        display: flex;
        gap: 8px;
      }
    }
    :deep(.client_info) {
      .profile__card {
        margin-top: 12px;
      }
    }
  }
  .job_overview__content {
    padding: 32px 0;
    border-bottom: 1px solid rgba(197, 210, 242, 1);
    .job_overview__list {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .job_overview__list__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left__block {
          p {
            font-weight: 600;
          }
        }
        .right__block {
          p {
            color: rgba($orange, 1);
          }
        }
      }
    }
  }
  .job_action {
    padding-top: 32px;
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
}
</style>
