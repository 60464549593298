<template>
  <TradePassportCardSlot
    class="trade-passport-gallery"
    title="Gallery"
    buttonLabel="+Add Gallery"
    :hideFooter="props.fileList && props.fileList.length <= 8"
    emptyStateImage="gallery-empty"
    :emptyStateLabel="
      isUserProprtyOwner
        ? 'Gallery will appear here'
        : 'Your gallery will appear here'
    "
    :isEmpty="!props.fileList.length"
    :numberOfRemainingItem="props.fileList.length"
    :isCustomActionHeader="
      props.fileList && props.fileList.length ? true : false
    "
    @on-handle-footer-click="onRouteToGalleryTab"
    @on-handle-click="onRouteToGalleryTab"
  >
    <template #custom-action-header>
      <div class="tw-flex tw-gap-3 tw-items-center">
        <Button
          label="+Add"
          variant="secondary"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          @click="onRouteToGalleryTab"
          v-if="!isUserProprtyOwner"
        />
        <BlueCircularPencilIcon
          v-if="!isUserProprtyOwner"
          @click="onRouteToGalleryTab"
        />
      </div>
    </template>
    <template #content>
      <div
        v-if="props.galleryIsLoading"
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center"
      >
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        class="tw-w-full tw-grid tw-gap-3 gallery-content-container"
        v-if="props.fileList && props.fileList?.length && !props.isLoading"
      >
        <template v-for="(file, index) in fileList" :key="index">
          <div class="gallery_item tw-relative">
            <TrashCircularWhiteIcon
              v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
              @click="onOpenDeleteConfirmationModal(file)"
              class="tw-absolute tw-right-[.40rem] tw-top-[.40rem] tw-z-50 tw-cursor-pointer md:!tw-right-[.30rem] md:!tw-top-[.30rem] md:!tw-w-[20px] md:!tw-h-[20px]"
            />
            <div
              v-if="file.docType === 'gallery'"
              @click="onOpenPhotoPreviewModal(file)"
              class="tw-flex tw-items-center tw-justify-center tw-min-h-[100px] tw-max-h-[100px] tw-h-[100px] tw-overflow-hidden tw-rounded-lg tw-relative tw-bg-slate-200 md:!tw-min-h-[69px] md:!tw-max-h-[69px] md:!tw-h-[69px]"
            >
              <v-icon
                @click="onOpenVideo(file)"
                v-if="file.fileType === 'video/mp4'"
                icon="mdi-play-circle"
                color="#264FD5"
                class="tw-absolute tw-right-[40%] tw-top-[40%] tw-z-50 tw-cursor-pointer"
              ></v-icon>

              <img
                v-if="file.attachmentSource && file.fileType === 'video/mp4'"
                class="tw-cursor-pointer"
                :src="require(`@/assets/images/iknowa-thumbnail.png`)"
              />
              <v-img
                v-else-if="
                  file.attachmentSource && file.fileType !== 'video/mp4'
                "
                class="tw-w-full tw-h-full tw-cursor-pointer"
                :class="{
                  'tw-hidden':
                    file?.fileType !== 'image/jpeg' &&
                    file?.fileType !== 'image/png',
                }"
                aspect-ratio="16/9"
                cover
                :src="file?.attachmentSource"
              ></v-img>
              <v-img
                v-else
                class="tw-cursor-pointer"
                :class="{
                  'tw-hidden':
                    file?.fileType !== 'image/jpeg' &&
                    file?.fileType !== 'image/png',
                }"
                :width="60"
                :height="60"
                :src="require(`@/assets/images/project-placeholder.png`)"
              ></v-img>
            </div>
          </div>
        </template>
      </div>
    </template>
  </TradePassportCardSlot>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import TradePassportCardSlot from "../slots/TradePassportCardSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import TrashCircularWhiteIcon from "@/core/components/icons/TrashCircularWhiteIcon.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
// import { propsDef } from "v-calendar/dist/types/src/use/datePicker";

const props = defineProps(["isLoading", "fileList"]);
const emits = defineEmits([
  "route-to-gallery-tab",
  "on-open-delete-confirmation-modal",
  "on-open-photo-preview-modal",
  "on-open-video",
]);

const store = useStore();

const getUserWorkStationFieldAccess = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const onRouteToGalleryTab = () => {
  emits("route-to-gallery-tab");
};

const onOpenVideo = (file) => {
  emits("on-open-video", file);
};
const onOpenPhotoPreviewModal = (file) => {
  emits("on-open-photo-preview-modal", file);
};
const onOpenDeleteConfirmationModal = (file) => {
  emits("on-open-delete-confirmation-modal", file);
};
</script>
<style lang="scss" scoped>
.gallery-content-container {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(69px, 1fr));
  }
}
</style>
