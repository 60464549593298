<template>
  <AnalyticsWidgetLayout
    class="team__widget"
    title="Team Performance"
    :hideToolTip="true"
    headerIcon="teamPerformance"
    :borderBottom="true"
    :isHeaderAction="true"
  >
    <template #header-action>
      <!-- <v-select
        :items="filterItems"
        item-title="range"
        item-value="range"
        variant="outlined"
        placeholder="Last 4 Week"
        bg-color="#fff"
        class="input_field !tw-w-[140px]"
        clearable
        type="select"
        :menu-props="{ contentClass: 'month__selector' }"
      ></v-select> -->
    </template>
    <template #content>
      <div class="team__data desktop__wrapper">
        <v-table
          fixed-header
          height="250px"
          :data-label="'Member Name'"
          v-if="teamPerformance && teamPerformance.length"
        >
          <thead>
            <tr>
              <th class="text-left">Member Name</th>
              <th class="text-left">Revenue Generated</th>
              <th class="text-left">Jobs Completed</th>
              <th class="text-left">Working Days</th>
              <th class="text-left">Role</th>
              <th class="text-left">Customer Rating</th>
              <th class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(member, index) in teamPerformance" :key="index">
              <td class="text-left" :data-label="'Member Name'">
                <div class="member__name">
                  <div class="member_img">
                    <img
                      src="../../../assets/images/avatar-info-image.png"
                      alt=""
                    />
                  </div>
                  <p>{{ member?.name }}</p>
                </div>
              </td>

              <td class="text-left" :data-label="'Revenue Generated'">
                {{ member?.revenue }}
              </td>
              <td class="text-left" :data-label="'Jobs Completed'">
                {{ member?.jobCompletecount }}
              </td>
              <td class="text-left" :data-label="'Working Days'">
                {{ member?.daysWorked }} Days
              </td>
              <td class="text-left" :data-label="'Role'">Contractor</td>
              <td class="text-left" :data-label="'Customer Rating'">
                <div class="view-rating-box">
                  <v-rating
                    active-color="orange"
                    color="rgba(12, 15, 74, 0.26)"
                    empty-icon="mdi-star"
                    readonly
                    :model-value="member?.avgRating"
                  ></v-rating>
                  <p>{{ member?.avgRating }} out of 5</p>
                </div>
              </td>
              <td class="text-center" :data-label="'Action'">
                <img
                  src="../../../assets/icons/dual-message.svg"
                  alt=""
                  class="tw-cursor-not-allowed"
                />
              </td>
            </tr>
          </tbody>
        </v-table>
        <div
          class="tw-w-full tw-h-full tw-flex tw-flex-col tw-p-4 tw-box-border tw-items-center tw-justify-center tw-gap-4"
          v-else
        >
          <img
            :src="require('@/assets/icons/team-performance-empty-screen.svg')"
            alt=""
          />
          <Text variant="p" textWeight="600" textColor="rgba(12, 15, 74, 0.5)"
            >Your Team Performance data will<br />
            appear here</Text
          >
        </div>
      </div>
    </template>
  </AnalyticsWidgetLayout>
</template>

<script lang="ts" setup>
import AnalyticsWidgetLayout from "@/core/components/slots/AnalyticsWidgetLayout.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { ANALYTICS_STORE } from "@/store/modules/analytics";
import Text from "@/core/components/ui/general/Text.vue";

const store = useStore();

const filterItems = ref([
  { range: "Today" },
  { range: "Last 7 Days" },
  { range: "Last 4 Weeks" },
  { range: "Last 3 Months" },
  { range: "Last 12 Months" },
  { range: "Month To date" },
  { range: "Quarter to date" },
  { range: "Year to date" },
  { range: "All time" },
]);

const teamPerformance = computed(
  () => store.getters[`${ANALYTICS_STORE}/getTeamPerformance`]
);
</script>

<style lang="scss" scoped>
.team__widget {
  width: 100%;
  max-width: 100%;
  .team__data {
    width: 100%;
    max-width: 100%;
    .v-table {
      overflow: auto;
      :deep(.v-table__wrapper) {
        scrollbar-width: none;
        table {
          thead {
            // display: table;
            // width: 100%;
            // max-width: 100%;
            tr {
              th {
                height: auto;
                padding: 10px 16px;
                @include fluidFont(14, 14, 1.3);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
                white-space: nowrap;
              }
            }
          }
          tbody {
            // display: table;
            // width: 100%;
            // max-width: 100%;
            tr {
              td {
                height: auto;
                padding: 10px 16px;
                @include fluidFont(14, 14, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 1);
                white-space: nowrap;
                letter-spacing: 0.24px;
                .member__name {
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  .member_img {
                    width: 30px;
                    height: 30px;
                    overflow: hidden;
                    border-radius: 100%;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }
                .view-rating-box {
                  display: flex;
                  gap: 8px;
                  align-items: center;
                  .v-rating {
                    .v-rating__wrapper {
                      .v-rating__item {
                        label {
                          .v-btn {
                            width: auto;
                            height: auto;
                            .v-btn__content {
                              .v-icon {
                                @include fluidFont(12, 12, 1);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.team__data {
  width: 100%;
  max-width: 100%;

  .v-table {
    overflow: auto;

    :deep(.v-table__wrapper) {
      scrollbar-width: none;

      table {
        thead,
        tbody {
          tr {
            th,
            td {
              height: auto;
              padding: 10px 16px;
              font-weight: 500;
              color: rgba($blueDark, 0.5);
              white-space: nowrap;
            }
          }
        }
      }
    }

    /* Responsive styles */
    @media (max-width: 720px) {
      :deep(.v-table__wrapper) {
        height: auto !important;
        table {
          thead {
            display: none; /* Hide table headers on small screens */
          }

          tbody {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;

            tr {
              display: block;
              border: 1px solid rgba($blueDark, 0.1);
              border-radius: 8px;
              padding: 10px;
              td {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 0 !important;
                font-weight: 700 !important;
                border-bottom: 1px solid rgba($blueDark, 0.1);

                &:before {
                  content: attr(data-label);
                  font-weight: 600;
                  color: rgba($blueDark, 0.5);
                  text-align: left;
                }

                &:last-child {
                  margin-bottom: 0;
                  border-bottom: none;
                }
              }

              .member__name {
                display: flex;
                gap: 10px;
                align-items: center;

                .member_img {
                  width: 22px;
                  height: 22px;
                  overflow: hidden;
                  border-radius: 100%;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }

              .view-rating-box {
                display: flex;
                gap: 8px;
                align-items: center;

                .v-rating {
                  gap: 0 !important;

                  .v-rating__wrapper {
                    .v-rating__item {
                      label {
                        .v-btn {
                          width: auto;
                          height: auto;

                          .v-btn__content {
                            font-size: 12px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
