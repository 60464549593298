<template>
  <div
    class="attachment-list !tw-p-4 !tw-box-sizing md:!tw-h-[auto] md:!tw-max-h-[470px] md:!tw-p-[12px] rmd:!tw-h-[425px]"
  >
    <div class="attachment-list__header">
      <!-- <p>Uploaded Files</p> -->
      <div class="tw-flex tw-items-center tw-gap-2">
        <Text variant="h6" textWeight="600">Uploaded Files</Text>
        <div
          v-if="attachmentList?.length"
          class="tw-w-[20px] tw-h-[20px] tw-rounded-full tw-bg-[#1FB27A] tw-flex tw-flex-col tw-items-center tw-justify-center"
        >
          <Text variant="xsmall" textColor="#fff" textWeight="400">{{
            attachmentList.length
          }}</Text>
        </div>
      </div>
    </div>

    <div v-if="attachmentList?.length" class="attachment-list__list">
      <div
        v-for="(attachment, index) of attachmentList"
        :key="index"
        class="attachment-list__item"
        :class="{
          'rmd:!tw-flex rmd:!tw-flex-col rmd:!tw-w-full': isPreviewFlexCol,
        }"
      >
        <div class="attachment-list__image rmd:!tw-w-full">
          <div class="attachment-list__image-init">
            <img
              v-if="
                isProjectDetailPage
                  ? attachment?.uploadedImages?.type?.includes('image')
                  : attachment?.uploadedImages?.imageData?.type?.includes(
                      'image'
                    )
              "
              class="attachment-list__image-covered"
              :src="attachment?.uploadedImages?.url"
              alt="Image"
            />
            <img
              v-else
              class="tw-h-[85px]"
              :src="require('@/assets/icons/sidebar-icons/documents.svg')"
              alt="icon"
            />
            <div
              @click="
                removeImageFromAttachments(
                  index,
                  attachment?.uploadedImages?.id
                )
              "
              class="attachment-list__delete"
            >
              <img src="@/assets/icons/delete-icon-white.svg" alt="Image" />
            </div>
          </div>
          <div class="attachment-list__name">
            <p>{{ attachment?.uploadedImages?.name }}</p>
          </div>
        </div>
        <div class="attachment-list__content">
          <v-btn
            density="compact"
            flat
            :id="`add-in-group-${index}`"
            :ripple="false"
            v-bind="props"
            @click="closeModal"
            class="attachment-list__menu-toggle"
          >
            + Add In a Group
          </v-btn>
          <v-menu
            @update:modelValue="onMenuClose"
            v-model="isShowMenu[index]"
            :persistent="false"
            :activator="`#add-in-group-${index}`"
            :close-on-content-click="false"
            scroll-strategy="close"
          >
            <div class="attachment-list__menu">
              <div class="attachment-list__menu-header">
                <p>Create a Group Or Select Group</p>

                <div @click="closeModal" class="attachment-list__menu-close">
                  <img src="@/assets/icons/cancel.svg" alt="Image" />
                </div>
              </div>

              <div class="attachment-list__menu-inner">
                <div class="attachment-list__menu-group-input">
                  <p>Enter Group Name</p>
                  <v-text-field
                    @update:modelValue="onChangeCategorySearch"
                    class="attachment-list__menu-input"
                    variant="solo-filled"
                    hide-details
                    single-line
                    v-model="attachment.userCategoryName"
                    flat
                    placeholder="Enter group name"
                  ></v-text-field>
                </div>

                <div class="attachment-list__menu-group-list">
                  <div
                    v-for="category of filteredUserCategory"
                    :key="category.label"
                    class="attachment-list__menu-group-list-item"
                    :class="{
                      'is-active':
                        attachment.userCategoryName == category.label,
                    }"
                    @click="onSelectCategory(attachment, category)"
                  >
                    <p>{{ category.label }}</p>
                  </div>
                </div>
              </div>

              <div class="attachment-list__menu-footer">
                <Button
                  variant="secondary"
                  class="button py-0 !tw-w-auto"
                  @click="closeModal"
                  label="CANCEL"
                />
                <Button
                  class="button py-0 !tw-w-auto"
                  variant="accent"
                  @click="closeModal"
                  label="SAVE"
                />
              </div>
            </div>
          </v-menu>
          <p v-if="attachment?.userCategoryName">
            {{ attachment.userCategoryName }}
          </p>
          <v-select
            v-if="properties?.length && properties?.length >= 1"
            placeholder="Select property"
            :items="properties"
            v-model="attachment.propertyId"
            label="Select property"
            item-title="address"
            item-value="id"
            multiple
            variant="outlined"
            density="compact"
            class="select_prop_dropdown"
            :class="{
              'error-border': propertyRequiredError.includes(index),
            }"
            :menu-props="{ contentClass: 'custom__text__selector' }"
            :data-placeholder="'Role'"
          >
          </v-select>
        </div>
      </div>
    </div>
    <div class="no-attachment" v-else>
      Your added project attachments will show up here
    </div>
  </div>
</template>
<script setup>
import { computed, defineModel, onMounted, ref, watch } from "vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
const attachmentList = defineModel("attachmentList");
const emits = defineEmits("on-delete");

const props = defineProps({
  properties: {
    type: Array,
  },
  isProjectDetailPage: {
    type: Boolean,
    default: false,
  },
  propertyRequiredError: {
    type: Array,
    default: () => [],
  },
  isPreviewFlexCol: {
    type: Boolean,
    default: true,
  },
});
const isShowMenu = ref([]);
const userCategory = ref([
  { label: "Floor Plan" },
  { label: "Living Room" },
  { label: "Gardening" },
  { label: "Materials" },
  { label: "Maps" },
  { label: "Others" },
]);

const removeImageFromAttachments = (index, attachmentId) => {
  const attachMentImageId =
    attachmentList.value[index]?.uploadedImages?.attachmentId;
  attachmentList.value.splice(index, 1);
  if (props?.isProjectDetailPage) {
    emits("on-delete", index, attachMentImageId);
  } else {
    emits("on-delete", index, attachmentId);
  }
};
const onSelectCategory = (attachment, category) => {
  attachment.userCategoryName = category.label;
};
const closeModal = () => {
  isShowMenu.value = [];
  addNewCustomeCategory();
};
const addNewCustomeCategory = () => {
  const labels = userCategory.value.map((item) =>
    item.label.toLocaleLowerCase()
  );
  attachmentList.value.forEach((attachment) => {
    if (
      attachment?.userCategoryName &&
      !labels.includes(attachment?.userCategoryName.toLowerCase())
    ) {
      userCategory.value.push({ label: attachment?.userCategoryName });
    }
  });
  filteredUserCategory.value = userCategory.value;
};

const onMenuClose = (val) => {
  if (!val) {
    addNewCustomeCategory();
  }
};

const filteredUserCategory = ref([]);

const onChangeCategorySearch = (newVal) => {
  if (newVal) {
    filteredUserCategory.value = userCategory.value.filter((category) =>
      category.label.toLocaleLowerCase().includes(newVal.toLowerCase())
    );
  } else {
    filteredUserCategory.value = userCategory.value;
  }
};
onMounted(() => {
  filteredUserCategory.value = userCategory.value;
  addNewCustomeCategory();
});
</script>

<script>
export default {
  name: "ProjectAttachmentListComponent",
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.attachment-list {
  background-color: rgba($backgroundcolor, 1);
  border-radius: 8px;
  padding: 16px;
  text-align: left;
  color: #0c0f4a;
  max-width: 100%;
  width: 100%;
  border: 1px solid rgba($bordercolor, 1);
  overflow: hidden;
  @include respond(s720) {
    padding: 0;
  }

  // @include respond(xxl) {
  //   margin: 32px 0 0;
  //   width: 100%;
  // }

  &__header {
    @include fluidFont(14, 14, 25px);
    font-weight: 700;
    letter-spacing: 0.25px;
    padding-bottom: 16px;
    border-bottom: 0.66px dashed #8687a5;
  }

  &__item {
    display: grid;
    grid-template-columns: 156px 1fr;
    align-items: flex-start;
    grid-gap: 10px;
    padding: 16px 0;
    @include respond(md) {
      grid-template-columns: 135px 1fr;
    }

    @media (max-width: 360px) {
      grid-template-columns: 1fr;
    }

    &:not(:last-child) {
      border-bottom: 0.66px dashed #8687a5;
    }
  }

  &__list {
    max-height: 405px;
    overflow: auto;
  }

  &__image {
    &-init {
      position: relative;
      height: 88px;
      overflow: hidden;
      border-radius: 8px;
    }

    &-covered {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    // height: 88px;
    //display: grid;
    align-content: center;
    //  align-items: center;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 360px) {
      height: auto;
    }
  }

  &__name {
    margin-top: 10px;
    @include fluidFont(12, 12, 22px);
    font-weight: 500;
    letter-spacing: 0.25px;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__delete {
    cursor: pointer;
    background-color: #fa4b00b2;
    border: 1px solid #fa4b00;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    img {
      max-width: 12px;
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    &:hover {
      background-color: #fa4b00;
    }
  }

  &__menu-toggle {
    padding: 0;
    text-transform: none;
    color: #4f55f0;
    @include fluidFont(14, 14, 22px);
    font-weight: 600;
    text-decoration: underline;
    width: auto;
    justify-self: flex-start;
    background-color: transparent;
    letter-spacing: 0.29px;

    :deep(.v-btn__overlay),
    :deep(.v-btn__underlay) {
      display: none;
    }
  }

  .select_prop_dropdown {
    margin-top: 10px;
    width: 100%;

    :deep(.v-select__selection-text) {
      font-size: 12px;
    }

    :deep(.v-input__control) {
      position: relative;
      .v-input__slot {
        min-height: 30px;
        &:empty {
          &::before {
            content: attr(data-placeholder);
            color: rgba($blueDark, 1);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
          }
        }
      }
      &.focus {
        .v-input__slot {
          &:empty {
            &::before {
              display: none;
            }
          }
        }
      }
      .v-field {
        padding: 7px 12px 6px;
        border: 1px solid rgba($buttonText, 0.4);
        background-color: rgba($white, 1);
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        letter-spacing: 0.4px;
        color: #80829f;
        align-items: center;
        gap: 4px;
        border-radius: 5px;

        .v-field__field {
          .v-label {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            letter-spacing: 0.4px;
            color: rgba($blueDark, 1);
            opacity: 1;
            display: none;
          }
          .v-field__input {
            padding: 0 !important;
            min-height: auto;
            .v-select__selection {
              .v-select__selection-text {
                color: rgba($blueDark, 1);
                line-height: 1;
              }
            }
            input {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              letter-spacing: 0.4px;
              color: rgba($blueDark, 1);
              top: -7px;
              opacity: 1;
              &::placeholder {
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                letter-spacing: 0.4px;
                color: rgba($blueDark, 1);
                opacity: 1;
              }
            }
          }
        }
        .v-field__outline {
          display: none;
          .v-field__outline__notch {
            .v-label {
              display: none;
            }
          }
        }
        .v-field__prepend-inner {
          .v-icon {
            opacity: 1;
            color: rgba($blueDark, 1);
          }
        }
        .v-field__append-inner {
          .v-icon {
            opacity: 1;
            color: rgba($blueDark, 1);
          }
        }
      }
    }
  }

  :deep(.v-select--selected .v-field .v-field__input > input) {
    opacity: 0 !important;
    padding: 0 !important;
  }
}

.attachment-list__menu {
  box-shadow: 0px 0px 10px 0px #0c0f4a29;
  width: 500px;
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;

  @media (max-width: 767px) {
    width: 342px;
  }

  @media (max-width: 360px) {
    width: 290px;
  }

  &-header {
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.12px;
    font-weight: 600;
    padding-bottom: 16px;
    border-bottom: 0.66px dashed #8687a5;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &-close {
    cursor: pointer;
    flex-shrink: 0;
    margin-left: auto;
  }

  &-group-input {
    padding: 10px;
    display: grid;
    grid-gap: 8px;
    font-weight: 600;
    @include fluidFont(12, 12, 12px);
    letter-spacing: 0.31px;
    background-color: #f5f5f8;
    border-radius: 4px;
  }

  :deep(.attachment-list__menu-input) {
    .v-input__control {
      .v-field {
        border-radius: 6px;
        border: 0.77px solid #80829f66;

        .v-field__input {
          padding: 8px;
          min-height: 30px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.31px;
        }

        .v-field__overlay {
          background-color: #fff;
          opacity: 1;
        }
      }
    }
  }

  &-group-list {
    margin-top: 10px;
    border: 1px solid #80829f66;
    border-radius: 4px;
    display: grid;
    grid-gap: 4px;
    max-height: 165px;
    overflow: auto;

    &-item {
      padding: 6px 12px;
      @include fluidFont(12, 12, 16px);
      font-weight: 500;
      letter-spacing: 0.4;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover,
      &.is-active {
        background-color: #0c0f4a0a;
      }
    }
  }

  &-footer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    flex-wrap: wrap;

    .button {
      height: auto;
      min-height: 26px;
      border-radius: 5px;
      padding: 5px 12px;

      :deep(.v-btn__content) {
        @include fluidFont(10, 10, 12px);
        letter-spacing: 0.09px;
      }

      &-orange {
        min-width: 112px;
      }
    }
  }
}
.no-attachment {
  text-align: center;
  @include fluidFont(14, 14, 1.3);
  color: rgba($blueDark, 0.5);
  letter-spacing: 0.15px;
  margin-top: 16px;
}

.attachment-list__content {
  p {
    background-color: rgba($lightPurple, 0.5);
    color: rgba($blueDark, 1);
    padding: 10px;
    width: max-content;
    @include fluidFont(14, 14, 1.2);
    color: rgba($blueDark, 1);
    // margin-top: 20px;
  }
}

.error-border {
  border: 2px solid red;
}

.attachment-list__list::-webkit-scrollbar {
  display: none;
}
</style>
