<template>
  <div v-if="!loading" class="main_project_details">
    <div
      class="success-floater fixed tw-rounded-[6.03px] tw-border-[1.51px] tw-border-solid tw-border-[#24BD63] tw-h-[36px] tw-px-3 tw-flex tw-gap-2 tw-items-center tw-justify-center"
      :class="{ show: isShowProjectPostSuccessFloater }"
    >
      <CongratsIcon />
      <Text>Congratulations, Your Project is Live on iknowa</Text>
    </div>
    <div
      v-if="projectStatus == ProjectJobStatusEnum.QUOTE_ACCEPTED && !loading"
      class="info-message"
    >
      <v-icon icon="mdi-information-variant-circle" color="#0C0F4A"></v-icon>
      <p>
        The project is ready to start once the contractor confirms this job.
      </p>
    </div>
    <div
      class="main_project_preview !tw-mt-0 tw-relative"
      @click.stop="addComments"
      ref="canvas"
      id="canvas_id"
    >
      <div class="left_block">
        <div class="top_links_main mobile__wrapper">
          <div class="back_results">
            <v-btn
              prepend-icon="mdi-chevron-left"
              variant="plain"
              @click="onClickBackToSearchResults"
              >Back to Search results
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        disputeProject &&
        !(disputeProject?.disputeType === DisputeTypeEnums.CLOSED)
      "
      class="dispute_project tw-mb-4 tw-w-full tw-p-4 tw-box-border tw-rounded-lg tw-border-[#3B67E9] tw-bg-[rgba(59,103,233,0.08)] tw-border-[1px] tw-border-solid tw-flex tw-gap-16 tw-items-center"
    >
      <div class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-4">
        <div
          class="tw-flex tw-pb-4 tw-border-b-dashed-custom tw-box-border tw-w-full"
        >
          <!-- <Text variant="h5" textAlign="left"
            >Early Reconciliation Process (ERP)</Text
          > -->
          <Text variant="h5" textAlign="left">
            <template
              v-if="disputeProject?.disputeType === DisputeTypeEnums.RAISED"
            >
              Early Reconciliation Process (ERP)
            </template>
            <template
              v-else-if="
                disputeProject?.disputeType === DisputeTypeEnums.UNDER_REVIEW
              "
            >
              iknowa Review
            </template>
            <template
              v-else-if="
                disputeProject?.disputeType === DisputeTypeEnums.ADR_REVIEW
              "
            >
              iknowa ADR Review
            </template>
            <template
              v-else-if="
                disputeProject?.disputeType === DisputeTypeEnums.CLOSED
              "
            >
              Dispute Closed
            </template>
          </Text>
        </div>
        <div class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-3">
          <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
            <InfoIcon class="tw-min-w-[15px] tw-w-[15px] tw-h-[15px]" />
            <Text
              variant="h6"
              textWeight="600"
              textAlign="left"
              lineHeight="18px"
            >
              <template
                v-if="disputeProject?.disputeType === DisputeTypeEnums.RAISED"
              >
                Your project has entered into the early reconciliation phase. At
                this point, we encourage you to try and resolve the dispute
                independently. If no agreement is reached, the case will proceed
                to iknowa for review.
              </template>

              <template
                v-else-if="
                  disputeProject?.disputeType === DisputeTypeEnums.UNDER_REVIEW
                "
              >
                Your project is under review. The dispute is currently being
                evaluated. Please await further instructions or resolution from
                iknowa.
              </template>

              <template
                v-else-if="
                  disputeProject?.disputeType === DisputeTypeEnums.ADR_REVIEW
                "
              >
                Your project is in the ADR review phase. We suggest working
                together to resolve the issue. If the case remains unresolved,
                it will be escalated for formal arbitration.
              </template>

              <template
                v-else-if="
                  disputeProject?.disputeType === DisputeTypeEnums.CLOSED
                "
              >
                The dispute has been closed. No further actions are required at
                this point.
              </template>
            </Text>
          </div>
          <div class="tw-flex tw-items-center tw-w-auto tw-gap-2 tw-ml-5">
            <Button
              class="!tw-w-auto"
              variant="secondary"
              label="View Evidence"
              @click="onViewEvidence"
              :isLoading="isRequestedChat"
              :isCapitalize="true"
            />
            <template
              v-if="
                disputeProject?.disputeType !== DisputeTypeEnums.UNDER_REVIEW &&
                disputeProject?.disputeType !== DisputeTypeEnums.ADR_REVIEW
              "
            >
              <Button
                class="!tw-w-auto"
                variant="secondary"
                :label="
                  extensionRequested
                    ? 'View Request Extension'
                    : 'Request for Extension'
                "
                :isCapitalize="true"
                @click="onOpenDSPRequestExtensionModal"
              />
              <Button
                class="!tw-w-auto"
                variant="accent"
                label="close dispute"
                @click="onCloseDispute"
                :isCapitalize="true"
                v-if="disputeProject"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-items-end tw-justify-between tw-gap-4">
        <div
          class="tw-bg-white tw-rounded-[12px] tw-flex tw-items-center tw-justify-center tw-p-4 tw-box-border"
        >
          <Text variant="h5" whiteSpace="nowrap">{{
            disputeProjectErpDueDate
          }}</Text>
        </div>
        <div
          @click="onOpenDSPEvidenceGuidanceModal(false)"
          class="tw-bg-white tw-cursor-pointer tw-rounded-[8px] tw-px-3 tw-py-2 tw-box-border tw-flex tw-items-center tw-justify-center tw-gap-2"
        >
          <v-icon
            icon="mdi-information"
            color="rgba(130, 133, 163, 1)"
          ></v-icon>
          <Text
            class="!tw-text-[11px]"
            textWeight="400"
            textColor="rgba(130, 133, 163, 1)"
            >Guidance For Evidence</Text
          >
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="main_project_preview !tw-mt-0 tw-relative"
      @click.stop="addComments"
      ref="canvas"
      id="canvas_id"
    >
      <div
        class="overlay__wrapper"
        v-if="isShowOverlay"
        :style="overlayStyle"
      ></div>
      <div class="left_block">
        <div class="top_links_main mobile__wrapper">
          <div class="back_results">
            <v-btn
              prepend-icon="mdi-chevron-left"
              variant="plain"
              @click="onClickBackToSearchResults"
              >Back to Search results
            </v-btn>
          </div>

          <EmergencyChip
            v-if="ProjectData?.type === ProjectTypesEnum.EMERGENCY"
          />
          <StandardChip
            v-if="ProjectData?.type === ProjectTypesEnum.STANDARD"
          />
          <RoutineChip v-if="ProjectData?.type === ProjectTypesEnum.ROUTINE" />
        </div>
        <!-- Team Members -->
        <div
          class="proj_components list_block"
          v-if="
            ProjectData?.projectJobStatus?.id !== ProjectJobStatusEnum.COMPLETED
          "
        >
          <h4>Team Members</h4>
          <div class="list_block__item !tw-pb-[10px]">
            <template v-if="ProjectData?.invitedMembersAndRoles?.length">
              <v-list
                v-for="(member, index) in ProjectData?.invitedMembersAndRoles"
                :key="index"
              >
                <v-list-item
                  :prepend-avatar="member?.prependAvatar"
                  :subtitle="member?.role"
                  :title="member?.email"
                >
                </v-list-item>
              </v-list>
            </template>

            <v-btn
              class="button button-purple-border"
              @click="onClickInviteTeamButton"
            >
              invite team member to project
            </v-btn>
          </div>

          <div class="status__buttons">
            <div
              class="save__project__button"
              v-if="
                (ProjectData?.type === ProjectTypesEnum.VIDEO_CONSULTATION &&
                  isShowVideoButton(ProjectData?.projectJobStatus?.id)) ||
                ProjectData?.projectJobStatus?.id ===
                  ProjectJobStatusEnum.DRAFT_PROJECT
              "
            >
              <v-btn
                @click="redirectVideoConsultation"
                class="button mt-2"
                color="#7467B7"
                v-if="
                  ProjectData?.type === ProjectTypesEnum.VIDEO_CONSULTATION &&
                  isShowVideoButton(ProjectData?.projectJobStatus?.id)
                "
              >
                Video Consultation
              </v-btn>

              <v-btn
                class="button button-orange"
                @click="onClickSiteVisit"
                :loading="isShowLoaderOnSaveButton"
                v-if="
                  ProjectData?.projectJobStatus?.id ===
                  ProjectJobStatusEnum.DRAFT_PROJECT
                "
              >
                post live project
              </v-btn>

              <v-btn
                class="save-btn"
                variant="text"
                @click="onClickSaveDraftProject"
                :loading="isShowLoaderOnSaveButton"
                v-if="
                  ProjectData?.projectJobStatus?.id ===
                  ProjectJobStatusEnum.DRAFT_PROJECT
                "
              >
                save project to draft
              </v-btn>
            </div>

            <v-btn
              class="delete-btn"
              variant="text"
              @click="onClickDeleteDraftProject"
              v-if="
                [
                  ProjectJobStatusEnum.DRAFT_PROJECT,
                  ProjectJobStatusEnum.AWAITING_QUOTES,
                  ProjectJobStatusEnum.QUOTES_RECEIVED,
                  ProjectJobStatusEnum.CANCELLED,
                ].includes(ProjectData?.projectJobStatus?.id)
              "
            >
              Delete Project
            </v-btn>
          </div>
          <div
            class="list_block__item__wrapper"
            v-if="
              projectDetails.projectJobStatus?.id ==
              ProjectJobStatusEnum.IN_PROGRESS
            "
          >
            <Button
              v-if="!disputeProject"
              @click="onCreateDisputeProject"
              label="DISPUTE PROJECT"
              variant="accent"
            />
          </div>
        </div>
        <!-- Project Overview -->
        <div class="proj_components list_block project_overview">
          <h4>Project Overview</h4>
          <div class="list_block__item">
            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Job Type</div>
              <div class="list_block__item__wrapper__right">
                {{ projectDetails?.type }}
              </div>
            </div>
            <div
              v-if="projectDetails?.type !== ProjectTypesEnum.EMERGENCY"
              class="list_block__item__wrapper"
            >
              <div
                v-if="
                  [
                    ProjectTypesEnum.STANDARD,
                    ProjectTypesEnum.ROUTINE,
                    ProjectTypesEnum.VIDEO_CONSULTATION,
                  ].includes(projectDetails?.type)
                "
                class="list_block__item__wrapper__left"
              >
                Preferred Start
              </div>
              <div class="list_block__item__wrapper__right">
                {{ projectDetails?.projectSubType }}
              </div>
            </div>
            <div
              v-if="projectDetails?.type !== ProjectTypesEnum.EMERGENCY"
              class="list_block__item__wrapper"
            >
              <div class="list_block__item__wrapper__left">Site Visit</div>
              <div class="list_block__item__wrapper__right">
                {{ ProjectData?.siteVisitStatus ? "Enabled" : "Disabled" }}
              </div>
            </div>
          </div>
          <div class="overview__detail">
            <v-btn
              v-if="isShowProjectRatingButton"
              class="button rating-btn button-purple-border"
              @click="toggleRatingModal(null)"
              >Rate your Experience</v-btn
            >
            <div
              v-for="overview in projectOverviewStatus.default"
              :key="overview.id"
              :class="{
                status_complete: overview.completed,
                'pointer-cursor': overview.statusCode == projectStatus,
              }"
              class="proj_loop overview__detail__item"
            >
              <div class="overview__detail__icon">
                <v-icon>
                  <img
                    :src="
                      overview.iconName
                        ? require(`@/core/svgs/${overview.iconName}.svg`)
                        : require('@/core/uni-assets/svgs/house.svg')
                    "
                    alt=""
                  />
                  <!-- <img src="@/core/svgs/project_live.svg" /> -->
                </v-icon>

                <div class="custom__border"></div>
              </div>
              <div
                class="overview__detail__text"
                @click="onClickRedirectToRelevantTab(overview)"
              >
                <h5>{{ overview.label }}</h5>
                <p>{{ overview.description }}</p>
              </div>
            </div>

            <div
              v-if="!showProjectOverviewDialog"
              class="bottom_icon"
              @click="showProjectOverviewDialog = true"
            >
              <v-icon
                icon="
               mdi-chevron-down
              "
              ></v-icon>
            </div>
            <v-expand-transition>
              <div v-show="showProjectOverviewDialog">
                <div
                  v-for="overview in projectOverviewStatus.expanded"
                  :key="overview.id"
                  :class="{
                    status_complete: overview.completed,
                    'pointer-cursor': overview.statusCode == projectStatus,
                  }"
                  class="proj_loop overview__detail__item"
                >
                  <div class="overview__detail__icon">
                    <v-icon>
                      <img
                        :src="
                          overview.iconName
                            ? require(`@/core/svgs/${overview.iconName}.svg`)
                            : require('@/core/uni-assets/svgs/house.svg')
                        "
                        alt=""
                      />
                    </v-icon>
                    <div class="custom__border"></div>
                  </div>
                  <div
                    class="overview__detail__text"
                    @click="onClickRedirectToRelevantTab(overview)"
                  >
                    <h5>{{ overview.label }}</h5>
                    <p>
                      {{ overview.description }}
                    </p>
                  </div>
                </div>
              </div>
            </v-expand-transition>
            <div
              v-if="showProjectOverviewDialog"
              class="bottom_icon"
              @click="showProjectOverviewDialog = false"
            >
              <v-icon
                icon="
               mdi-chevron-up
              "
              ></v-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="right_block">
        <div class="top_links_main desktop__wrapper">
          <div class="back_results">
            <v-btn
              prepend-icon="mdi-chevron-left"
              variant="plain"
              @click="onClickBackToSearchResults"
              >Back to Search results
            </v-btn>
          </div>

          <EmergencyChip
            v-if="ProjectData?.type === ProjectTypesEnum.EMERGENCY"
          />
          <StandardChip
            v-if="ProjectData?.type === ProjectTypesEnum.STANDARD"
          />
          <RoutineChip v-if="ProjectData?.type === ProjectTypesEnum.ROUTINE" />
          <VideoConsultationChip
            v-if="ProjectData?.type === ProjectTypesEnum.VIDEO_CONSULTATION"
          />
        </div>

        <!-- Start project notice -->
        <AlertCard
          class="tw-mt-8 md:!tw-mt-2 md:!tw-mb-4"
          v-if="
            estimateDetails &&
            estimatePhasesMessage &&
            [
              ProjectJobStatusEnum.QUOTE_CONFIRMED,
              ProjectJobStatusEnum.IN_PROGRESS,
              ProjectJobStatusEnum.COMPLETED,
            ].includes(ProjectData?.projectJobStatus?.id)
          "
        >
          <template #content>
            <div class="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-items-start">
              <Text variant="h6">To Start Project</Text>

              <div v-html="estimatePhasesMessage"></div>
              <Button
                label="Deposit Payment"
                class="!tw-w-[auto] !tw-mt-4"
                @click="redirectToEscrowAccount"
              />
            </div>
          </template>
        </AlertCard>

        <!-- Project Notes -->
        <div class="project_info__wrapper">
          <template v-if="!isEditProjectNoteSection">
            <v-card class="project_info">
              <div class="card_content">
                <div class="card_content__header">
                  <div class="card_content__title">
                    <h5
                      id="project_name"
                      data-comment-element="true"
                      :class="{
                        comment_hover:
                          isShowDashboard.type === 'comment' &&
                          isAlreadyCommented('project_name'),
                      }"
                    >
                      {{ ProjectData?.name }}
                    </h5>
                    <div class="card_content__title_info">
                      <div
                        v-if="getPropertyCategory?.isCommercial"
                        class="raised"
                        data-comment-element="true"
                        id="project_review_item_icon_commercial"
                        :class="{
                          comment_hover:
                            isShowDashboard.type === 'comment' &&
                            isAlreadyCommented(
                              'project_review_item_icon_commercial'
                            ),
                        }"
                      >
                        <CommercialChip />
                      </div>

                      <div
                        v-if="getPropertyCategory?.isResidential"
                        class="raised"
                        data-comment-element="true"
                        id="project_review_item_icon_residential"
                        :class="{
                          comment_hover:
                            isShowDashboard.type === 'comment' &&
                            isAlreadyCommented(
                              'project_review_item_icon_residential'
                            ),
                        }"
                      >
                        <ResidentialChip />
                      </div>
                      <div
                        class="project_id"
                        id="project_id_container"
                        :class="{
                          comment_hover:
                            isShowDashboard.type === 'comment' &&
                            isAlreadyCommented('project_id_container'),
                        }"
                        data-comment-element="true"
                      >
                        <p class="pointer_events_none">Project ID:</p>
                        <span class="pointer_events_none">{{
                          ProjectData?.refId
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <v-icon
                    v-if="
                      projectDetails?.projectJobStatus?.id ===
                        ProjectJobStatusEnum.DRAFT_PROJECT ||
                      projectDetails?.projectJobStatus?.id ===
                        ProjectJobStatusEnum.AWAITING_QUOTES
                    "
                    class="custom_edit"
                    icon="mdi-pencil"
                    @click.stop="updateProjectNameNote(ProjectData)"
                  ></v-icon>
                </div>
                <div class="card_content__footer">
                  <h6
                    id="project_notes_title"
                    data-comment-element="true"
                    :class="{
                      comment_hover:
                        isShowDashboard.type === 'comment' &&
                        isAlreadyCommented('project_notes_title'),
                    }"
                  >
                    Project Notes
                  </h6>
                  <p
                    v-html="ProjectData?.description"
                    id="project_html_description"
                    data-comment-element="true"
                    :class="{
                      comment_hover:
                        isShowDashboard.type === 'comment' &&
                        isAlreadyCommented('project_html_description'),
                    }"
                  ></p>
                </div>
              </div>
            </v-card>
          </template>
          <template v-else>
            <v-card class="edit_project_info">
              <div class="title">
                <h6>Project Title</h6>
                <v-text-field
                  :disabled="
                    projectDetails?.projectFrom === ProjectFrom.RECOMMENDATION
                  "
                  density="compact"
                  placeholder="enter title"
                  v-model="projectTitle"
                  variant="outlined"
                ></v-text-field>
              </div>
              <div class="description">
                <h6>Project Notes</h6>
                <div class="custom_editor">
                  <quill-editor
                    v-model:value="state.content"
                    :options="state.editorOption"
                    :disabled="state.disabled"
                    class="editor_ql"
                  />
                </div>
              </div>
              <div class="edit__buttons">
                <v-btn
                  class="button button-white"
                  @click="onCancelProjectNotes"
                  variant="outlined"
                >
                  cancel
                </v-btn>
                <v-btn
                  class="button button-orange"
                  @click="onClickSaveProjectNotes"
                  :loading="isLoadingProjectNotes"
                  prepend-icon="mdi-content-save"
                >
                  save
                </v-btn>
              </div>
            </v-card>
          </template>
        </div>

        <!-- Specialism -->

        <!-- new specialism -->
        <div class="specialism__wrapper !tw-px-0 !tw-p-0 tw-overflow-hidden">
          <v-expansion-panels v-model="tradeServicesPanel">
            <v-expansion-panel class="collapsible-component !tw-bg-transparent">
              <v-expansion-panel-title class="!tw-py-0 !tw-px-[20px]">
                <div
                  class="tw-w-full tw-flex tw-items-center tw-justify-between tw-gap-4"
                >
                  <Text
                    variant="h5"
                    class="rmd:!tw-text-[22px]"
                    textAlign="left"
                    >Services</Text
                  >
                  <BlueCircularPencilIcon
                    @click.stop="updateSpecialismSection()"
                    v-if="
                      !isEditSpecialismSection &&
                      (projectDetails?.projectJobStatus?.id ===
                        ProjectJobStatusEnum.DRAFT_PROJECT ||
                        projectDetails?.projectJobStatus?.id ===
                          ProjectJobStatusEnum.AWAITING_QUOTES)
                    "
                  />
                  <!-- <v-icon
                    class="custom_edit"
                    v-if="
                      !isEditSpecialismSection &&
                      (projectDetails?.projectJobStatus?.id ===
                        ProjectJobStatusEnum.DRAFT_PROJECT ||
                        projectDetails?.projectJobStatus?.id ===
                          ProjectJobStatusEnum.AWAITING_QUOTES)
                    "
                    icon="mdi-pencil"
                    @click.stop="updateSpecialismSection()"
                  ></v-icon> -->
                </div>
                <template v-slot:actions="{ expanded }">
                  <div
                    class="tw-ml-3 tw-min-w-[36px] tw-w-[36px] tw-h-[36px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-[1.3px] tw-border-solid tw-border-[#DFDFDF] tw-bg-[#F8FBFD] tw-cursor-pointer"
                  >
                    <v-icon
                      icon="mdi-chevron-right"
                      class="!tw-text-[14px]"
                      v-if="!expanded"
                    ></v-icon>
                    <v-icon
                      icon="mdi-chevron-down"
                      class="!tw-text-[14px]"
                      v-else
                    ></v-icon>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="tw-py-0 tw-px-[20px] tw-m-0">
                <div
                  class="specialism__wrapper__content tw-border-t-dashed-custom"
                >
                  <div v-if="!ProjectData?.subCategories?.length">
                    No Services
                  </div>
                  <div class="specialism-card-list">
                    <div
                      class="specialism-card-list__item"
                      v-for="subCategory in ProjectData?.subCategories"
                      :key="subCategory.id"
                    >
                      <v-btn
                        variant="text"
                        aria-label="Delete"
                        class="specialism-card-list__item-delete"
                        prepend-icon="mdi-delete"
                        v-if="isEditSpecialismSection"
                        @click="onClickDeleteCategoryButton(subCategory?.id)"
                      ></v-btn>

                      <div class="specialism-card-list__item-author">
                        <div
                          class="specialism-card-list__item-author-header"
                          :class="
                            specialismCategoryClass(subCategory?.generatedBy)
                          "
                        >
                          <div class="speicalism__icon">
                            <img
                              v-if="
                                specialismCategoryClass(
                                  subCategory?.generatedBy
                                ) === 'krystal'
                              "
                              :src="require('@/assets/icons/krystal.svg')"
                              alt="krystalimg"
                            />
                            <img
                              v-if="
                                specialismCategoryClass(
                                  subCategory?.generatedBy
                                ) === 'property'
                              "
                              :src="require('@/assets/icons/house.svg')"
                              alt="houseimage"
                            />
                          </div>
                          <h6>{{ subCategory?.generatedBy }}</h6>
                          <v-icon
                            icon="mdi-information-variant-circle-outline"
                          />
                        </div>
                        <div class="specialism-card-list__item-author-content">
                          <div class="category_icon">
                            <img
                              v-if="subCategory?.category?.iconName"
                              :src="
                                require('@/assets/category-icons/' +
                                  subCategory?.category?.iconName +
                                  '.svg')
                              "
                              alt="Image"
                            />
                          </div>
                          <div class="category_text">
                            <h5>{{ subCategory?.label }}</h5>
                            <p>{{ subCategory?.category?.poLabel }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="isEditSpecialismSection"
                  class="edit-mode-button !tw-py-4"
                >
                  <v-btn
                    class="button button-purple-border"
                    variant="text"
                    @click="onClickAddMoreSpecialism"
                  >
                    Add more Services
                  </v-btn>
                  <div class="edit__buttons">
                    <v-btn
                      class="button button-white"
                      @click="onClickCancelSpecialismButton"
                      variant="outlined"
                    >
                      cancel
                    </v-btn>
                    <v-btn
                      class="button button-orange"
                      :loading="isLoadingProjectData"
                      @click="onClickSaveSpecialismButton"
                    >
                      save
                    </v-btn>
                  </div>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <!-- Project Properties -->
        <div class="properties_wrapper">
          <div class="header">
            <div class="title">
              <h4
                id="project_properties_title"
                data-comment-element="true"
                :class="{
                  comment_hover:
                    isShowDashboard.type === 'comment' &&
                    isAlreadyCommented('project_properties_title'),
                }"
              >
                Properties
                <span class="count__property pointer_events_none">{{
                  ProjectData?.projectProperties?.length || 0
                }}</span>
              </h4>
            </div>
            <v-btn @click="togglePropertyMapView" class="map__button">
              <span> Open Map View </span
              ><img src="@/assets/icons/zoom.svg" alt=""
            /></v-btn>
            <v-icon
              class="custom_edit"
              v-if="
                !isEditPropertiesSection &&
                (projectDetails?.projectJobStatus?.id ===
                  ProjectJobStatusEnum.DRAFT_PROJECT ||
                  projectDetails?.projectJobStatus?.id ===
                    ProjectJobStatusEnum.AWAITING_QUOTES)
              "
              icon="mdi-pencil"
              @click.stop="updatePropertiesSection()"
            ></v-icon>
          </div>
          <div class="properties_wrapper__content">
            <div
              class="properties_wrapper__left no-scrollbar"
              :class="{ 'tw-pt-3': isEditPropertiesSection }"
            >
              <div
                class="properties_wrapper__info"
                v-for="(property, index) in ProjectData?.projectProperties"
                :key="property?.id"
              >
                <v-icon
                  class="delete__icon"
                  v-if="
                    isEditPropertiesSection &&
                    ProjectData?.projectProperties.length >= 2
                  "
                  icon="mdi-delete"
                  @click.stop="onClickRemoveProperty(property)"
                ></v-icon>

                <div class="properties_wrapper__info__left">
                  <div
                    class="prop_name"
                    :id="`project_property_prop_name_${property?.id}`"
                    :class="{
                      comment_hover:
                        isShowDashboard.type === 'comment' &&
                        isAlreadyCommented(
                          `project_property_prop_name_${property?.id}`
                        ),
                    }"
                    data-comment-element="true"
                  >
                    <p class="pointer_events_none">
                      {{ index + 1 }}
                    </p>
                    <h6 class="pointer_events_none">
                      {{ property?.property?.address }}
                    </h6>
                  </div>
                  <div class="property__type__wrapper">
                    <div
                      class="location"
                      :id="`project_property_prop_location_${property?.id}`"
                      :class="{
                        comment_hover:
                          isShowDashboard.type === 'comment' &&
                          isAlreadyCommented(
                            `project_property_prop_location_${property?.id}`
                          ),
                      }"
                      data-comment-element="true"
                    >
                      <v-icon
                        icon="mdi mdi-map-marker"
                        class="pointer_events_none"
                      ></v-icon>
                      {{ property?.property?.postcode }}
                    </div>

                    <div
                      class="property_type"
                      :id="`project_property_prop_main_type_${property?.id}`"
                      :class="{
                        comment_hover:
                          isShowDashboard.type === 'comment' &&
                          isAlreadyCommented(
                            `project_property_prop_main_type_${property?.id}`
                          ),
                      }"
                      data-comment-element="true"
                    >
                      <ResidentialChip
                        v-if="
                          property?.property?.propertyCategory ===
                          PropertyCategoryEnum.RESIDENTIAL
                        "
                      />
                      <CommercialChip
                        v-if="
                          property?.property?.propertyCategory ===
                          PropertyCategoryEnum.COMMERCIAL
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isEditPropertiesSection" class="edit-mode-button">
            <v-btn
              class="button button-purple-border"
              @click="onClickTogglePropertyListModal"
              variant="text"
            >
              Add more Property
            </v-btn>
            <div class="edit__buttons">
              <v-btn
                class="button button-white"
                :loading="isLoadingOnCancel"
                @click="onClickCancelButton"
                variant="outlined"
              >
                cancel
              </v-btn>
              <v-btn
                class="button button-orange"
                :loading="isLoadingProjectData"
                @click="onClickSaveButton"
              >
                save
              </v-btn>
            </div>
          </div>
        </div>

        <!-- Documents / Attachments -->
        <div
          class="documents_attachment"
          :class="{ 'is-empty': !projectAttachments.length }"
        >
          <div class="title">
            <h3
              id="project_documents_attachment_name"
              :class="{
                comment_hover:
                  isShowDashboard.type === 'comment' &&
                  isAlreadyCommented('project_documents_attachment_name'),
              }"
              data-comment-element="true"
            >
              Documents / Attachments
            </h3>
            <v-select
              v-if="projectAttachments.length"
              v-model="propertyId"
              label="Select property"
              :items="projectAttachments"
              item-title="address"
              item-value="propertyId"
              multiple
              variant="outlined"
              density="compact"
              class="select_prop_dropdown"
              @update:modelValue="changePropertyId"
              :menu-props="{ contentClass: 'custom__text__selector' }"
            >
            </v-select>
            <!-- <v-icon
            class="custom_edit"
            v-if="!projectAttachments.length"
            icon="mdi-pencil"
          ></v-icon> -->
          </div>
          <div v-if="!loadingSelectProperty">
            <v-expansion-panels
              v-model="panel"
              multiple
              class="accordion__wrapper"
            >
              <v-expansion-panel
                v-for="(property, index) in projectAttachments"
                :key="index"
              >
                <v-expansion-panel-title>
                  <v-icon class="house__icon">
                    <img src="@/assets/icons/house.svg" alt="" />
                  </v-icon>
                  <h6
                    :id="`project_property_attachments_doc_label_${property.propertyId}`"
                    data-comment-element="true"
                    :class="{
                      comment_hover:
                        isShowDashboard.type === 'comment' &&
                        isAlreadyCommented(
                          `project_property_attachments_doc_label_${property.propertyId}`
                        ),
                    }"
                  >
                    {{ property?.address }}
                  </h6>
                  <template v-slot:actions="{ expanded }">
                    <v-icon
                      class="custom_edit"
                      v-if="
                        expanded &&
                        !isEditAttachmentsSection &&
                        (projectDetails?.projectJobStatus?.id ===
                          ProjectJobStatusEnum.DRAFT_PROJECT ||
                          projectDetails?.projectJobStatus?.id ===
                            ProjectJobStatusEnum.AWAITING_QUOTES)
                      "
                      icon="mdi-pencil"
                      @click="updateAttachmentsSection(index)"
                    ></v-icon>
                    <v-icon
                      v-else
                      icon="mdi-chevron-down"
                      class="bottom__chevron"
                    ></v-icon>
                  </template>
                </v-expansion-panel-title>

                <div class="group__cards" v-if="isShowGroupCard(index)">
                  <div class="group__card__text">
                    <v-expansion-panel-text>
                      <div
                        class="accordion_inner_title"
                        v-for="category in getFilterCategory(
                          property.categories
                        )"
                        :key="category.id"
                      >
                        <h3
                          :id="`attachments_item_categories_name_${property.propertyId}_${category.id}`"
                          :class="{
                            comment_hover:
                              isShowDashboard.type === 'comment' &&
                              isAlreadyCommented(
                                `attachments_item_categories_name_${property.propertyId}_${category.id}`
                              ),
                          }"
                          data-comment-element="true"
                        >
                          {{ category?.name }}
                        </h3>
                        <div
                          class="doc_main"
                          :id="`attachments_item_categories_content_${property.propertyId}_${category.id}`"
                          :class="{
                            comment_hover:
                              isShowDashboard.type === 'comment' &&
                              isAlreadyCommented(
                                `attachments_item_categories_content_${property.propertyId}_${category.id}`
                              ),
                          }"
                          data-comment-element="true"
                        >
                          <div class="doc__main__list">
                            <div
                              class="doc_main__list__item"
                              v-for="attachment in category.attachments"
                              :key="attachment.id"
                            >
                              <div
                                class="property__img__name"
                                @click.stop="
                                  onClickOpenNewTab(attachment.attachmentUrl)
                                "
                              >
                                <img src="@/assets/icons/image-file-icon.svg" />
                                <!-- <v-icon icon="mdi mdi-image-outline"></v-icon> -->
                                <span>{{ attachment?.originalName }}</span>
                              </div>
                              <div
                                v-if="isEditAttachmentsSection"
                                @click="
                                  onClickRemoveAttachment(
                                    attachment,
                                    property,
                                    category?.name,
                                    index,
                                    category?.id
                                  )
                                "
                              >
                                <v-icon
                                  icon="mdi-close"
                                  class="pointer_events_none"
                                ></v-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- v-if="!isEditAttachmentsSection" -->
                      <div
                        class="property__images"
                        v-if="
                          getNoCategoryAttachmentList(property?.categories)
                            ?.length
                        "
                      >
                        <div
                          v-for="attachment in getNoCategoryAttachmentList(
                            property.categories
                          )"
                          :key="attachment.id"
                          class="property__item__images"
                        >
                          <img
                            :src="attachment?.attachmentUrl"
                            @click="
                              openCarousel(attachment.id, property.categories)
                            "
                          />
                        </div>
                      </div>
                      <div
                        v-if="isEditAttachmentsSection"
                        class="edit-mode-button"
                      >
                        <v-btn
                          class="button button-purple-border"
                          @click="onClickToggleAttachmentModal"
                        >
                          Upload More
                        </v-btn>
                        <div class="edit__buttons">
                          <v-btn
                            class="button button-white"
                            variant="outlined"
                            :loading="isLoadingOnAttachmentCancel"
                            @click="onCancelAttachments"
                          >
                            cancel
                          </v-btn>
                          <v-btn
                            class="button button-orange"
                            size="large"
                            block
                            :loading="isLoadingRemoveAttachments"
                            @click="onClickSaveButtonAttachments"
                          >
                            save
                          </v-btn>
                        </div>
                      </div>
                    </v-expansion-panel-text>
                  </div>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div
            v-else
            class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[500px]"
          >
            <Loader :show="loadingSelectProperty" />
          </div>
          <div>
            <v-btn
              class="button button-purple-border tw-mt-5"
              @click="onClickToggleAttachmentModal"
              aria-disabled=""
              v-if="
                !projectAttachments.length &&
                !isEditSpecialismSection &&
                (projectDetails?.projectJobStatus?.id ===
                  ProjectJobStatusEnum.DRAFT_PROJECT ||
                  projectDetails?.projectJobStatus?.id ===
                    ProjectJobStatusEnum.AWAITING_QUOTES)
              "
            >
              Upload Attachments
            </v-btn>
          </div>
        </div>
        <!-- LOADER -->

        <div
          class="tooltip_wrapper"
          ref="clickOutside"
          v-if="
            ['jobDetails', 'projectDetails'].includes(type) &&
            isShowDashboard.type == 'comment'
          "
        >
          <ToolTipComment
            v-for="(val, index) in projectComments"
            :key="val.id"
            :index="index"
            :commentElement="val"
            :projectId="ProjectData.id"
            :activeComment="activeComment"
            :commentsLength="projectComments.length"
            :userImagesMap="userImagesMap"
            @toggleActiveComment="toggleActiveComment"
            @updateCommentRoomStatus="updateCommentRoomStatus"
            @nextComment="nextComment"
          />
        </div>
      </div>

      <!-- PropertyListModal -->
      <commonDialog
        v-model="isShowPropertyListModal"
        :isShowHeader="false"
        :isShowBackButton="false"
        width="1010px"
        height="auto"
        max-width="100%"
        persistent
        :className="['edit__propertyList__modal']"
      >
        <template v-slot:body>
          <v-icon
            class="close__icon"
            icon="mdi-close"
            @click="onClickTogglePropertyListModal"
          ></v-icon>

          <div class="custom__modal__heading">
            <h3>
              Properties
              <span class="count__property">{{
                ProjectData?.projectProperties?.length
              }}</span>
            </h3>
            <p>
              This job is required across multiple properties, please select any
              you would like to combine into this estimate.
            </p>
            <span class="tw-text-red-600">{{ propertyEditModalAlert }}</span>
          </div>
          <div class="modal___content">
            <div class="property__cards">
              <div class="courses__card__list">
                <v-card
                  class="courses__card"
                  :class="{ 'selected-card': isSelected(property?.id) }"
                  @click="toggleSelection(property?.id)"
                  v-for="(property, index) in propertiesList"
                  :key="index"
                >
                  <div class="courses__card__img">
                    <img
                      class=""
                      cover
                      :src="
                        require('@/assets/images/solar-pv-installation-hero.jpg')
                      "
                    />
                  </div>
                  <div class="courses__card__prefix">
                    <div class="left__prefix">
                      <v-icon>
                        <img src="@/assets/icons/user.svg" alt="" />
                      </v-icon>
                      <p>{{ getPropertyType(property) }}</p>
                    </div>
                    <div class="right__prefix">
                      <v-icon icon="mdi-map-marker"></v-icon>
                      <p>{{ property?.postcode }}</p>
                    </div>
                  </div>
                  <div class="courses__card__text">
                    <h5 class="courses__card__title">
                      {{ property?.address }}
                    </h5>
                  </div>
                </v-card>
              </div>
              <div class="load__wrapper">
                <label
                  v-if="isPaginationAvailable"
                  @click="onLoadMore"
                  class="yellow-text"
                >
                  Load More Properties<v-icon icon="mdi-chevron-right"
                /></label>
              </div>
            </div>

            <div class="footer__button">
              <Button
                label="CANCEL"
                variant="secondary"
                class="button"
                @click="onClickTogglePropertyListModal"
              />
              <Button
                label="SAVE"
                variant="accent"
                class="button"
                :isLoading="isLoadingProjectData"
                @click="onClickPropertyModalSave"
              />
            </div>
          </div>
        </template>
      </commonDialog>

      <!-- AttachmentModal -->
      <commonDialog
        v-model="isShowAttachmentModal"
        :isShowHeader="false"
        :isShowBackButton="false"
        width="832px"
        max-width="100%"
        persistent
        :className="['property__upload__modal']"
      >
        <template v-slot:body>
          <v-icon
            icon="mdi-close"
            class="close__icon"
            @click="onClickToggleAttachmentModal"
          ></v-icon>
          <div
            class="modal__content md:!tw-gap-[.7rem] md:!tw-p-[10px] rmd:!tw-pb-4 rmd:!tw-items-end"
          >
            <div class="left_upload_img">
              <h4>Upload images and filesss.</h4>

              <div class="upload__area md:!tw-mt-[.5rem]">
                <div class="uploade-wrapper md:!tw-h-[100px]">
                  <v-file-input
                    class="upload_file"
                    label="Upload"
                    append-inner-icon="mdi-image-plus"
                    accept="image/png, image/jpeg, image/jpg"
                    variant="solo-filled"
                    v-model="selectedFile"
                    :class="{ 'added-img': imageUrl }"
                    @change="onFileChange"
                    @click:clear="clearImageUrl"
                    multiple
                  >
                  </v-file-input>
                  <v-img
                    class="uploaded-img"
                    :width="200"
                    aspect-ratio="16/9"
                    cover
                    :src="imageUrl"
                  ></v-img>
                  <v-icon icon="mdi mdi-file-image-box"></v-icon>
                  <p class="error_msg" v-if="errorMessage">
                    {{ errorMessage }}
                  </p>
                </div>
                <div class="upload__text">
                  <p>
                    <strong>Drag and drop documents, or</strong>
                    <span>Browse</span>
                  </p>
                  <p class="">
                    Attachments such as images, design ideas and documents help
                    better understand the project and provide increasingly
                    accurate estimates.
                  </p>
                </div>
              </div>
            </div>

            <ProjectAttachmentListComponent
              :attachmentList="attachmentDataList"
              @on-delete="removeImageFromAttachments"
              :properties="userSelectedProperties"
              :isProjectDetailPage="true"
              :propertyRequiredError="propertyRequiredError"
            />
          </div>

          <div class="footer-buttons">
            <Button
              label="Cancel"
              variant="secondary"
              class="button"
              @click="onClickToggleAttachmentModal"
            />
            <Button
              label="Save"
              class="!tw-w-auto button"
              variant="accent"
              @click="onClickSaveButtonAttachmentsModal"
              :isLoading="isLoadingUploadAttachments"
            />
          </div>
        </template>
      </commonDialog>

      <AddCommentComponent
        v-if="activeAddComment && activeAddComment.length"
        :commentData="activeAddComment[0]"
        @onComment="onComment"
      />
      <InviteTeamMember
        @onCloseModal="onCloseModal"
        :projectId="ProjectData?.id"
        v-if="iShowInviteTeamMemberModal"
      />

      <SpecialismMultiSelectModel
        v-if="isShowSpecialismModal"
        :isHistorical="false"
        :projectId="ProjectData.id"
        :selectedSpecialism="ProjectData?.subCategories"
        :isLoading="isLoadingForSaveSpecialism"
        @onClickCloseTab="onClickCloseTab"
        @onClickSaveButtonClick="onClickSaveButtonClick"
      />
    </div>
  </div>
  <div
    v-else
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[500px]"
  >
    <Loader :show="loading" />
  </div>
  <!-- CarouselDialog -->
  <v-dialog
    v-model="isCarouselDialog"
    height="auto"
    max-width="600"
    class="project__carousal__modal"
    :show-arrows="false"
  >
    <v-card>
      <template v-slot:actions>
        <v-btn
          icon="mdi-close"
          @click="isCarouselDialog = false"
          class="d-flex"
        ></v-btn>
        <v-carousel
          hide-delimiter-background
          :show-arrows="carouselImageList?.length > 1"
          v-model="currentIndex"
        >
          <v-carousel-item
            v-for="attachment in carouselImageList"
            :key="attachment.id"
          >
            <v-img
              cover
              :src="attachment?.attachmentUrl"
              height="100%"
              width="100%"
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </template>
    </v-card>
  </v-dialog>
  <ChatCommentsComponent
    :isShowDashboard="isShowDashboard"
    :activeComment="activeComment"
    :projectComments="projectComments"
    :projectId="ProjectData?.id"
    :userImagesMap="userImagesMap"
    @openMessageDashboard="openMessageDashboard"
    @toggleActiveComment="toggleActiveComment"
    @updateCommentRoomStatus="updateCommentRoomStatus"
    v-if="isShowCommentSection"
  />
  <ProjectRateModal
    v-if="isShowReviewModal && projectDetails?.id"
    :type="ProjectRatingTypeEnum.PROPERTY_OWNER"
    :ratingData="projectRatingData"
    @on-close="toggleRatingModal"
    :projectId="projectDetails?.id"
    reviewType="project"
    :projectName="ProjectData?.name"
  />
  <SiteVisitModel
    @onClickBack="onClickSiteVisit"
    @onClickPostProject="onClickPostLiveProject"
    v-if="isShowSiteVisitModal"
    :projectId="ProjectData?.id"
  />
  <ConfirmDeleteProjectModal
    @on-close="onCloseConfirmDeleteProjectModal"
    :projectDetail="ProjectData"
    v-if="isOpenConfirmDeleteProjectModal"
  />

  <SuccessSendingReviewModal
    v-if="isShowSuccessSendReviewModal"
    @on-close="onCloseSuccessSendReviewModal"
  />

  <PropertyWithMapModal
    v-if="isShowPropertyMapView && ProjectData?.projectProperties?.length"
    :propertyList="ProjectData?.projectProperties"
    @close="togglePropertyMapView"
  />
  <DSPModal
    v-if="isOpenDspEvidenceModal"
    :project="projectDetails"
    @close="toggleDspEvidenceModal"
    @submit="submitDisputeProject"
  />
  <DSPInformationModal
    v-if="isOpenDSPInformationModal"
    @close="toggleDSPInformationModal"
    @showDspOverviewModal="toggleDspProjectDetail"
  />
  <DSPOverviewModal
    v-if="isOpenDspOverviewModal"
    @close="toggleDspOverviewModal"
    @onProceedContinue="toggleDSPInformationModal"
    @onOpenGuidanceModal="onOpenDSPEvidenceGuidanceModal"
  />
  <DSPProjectDetailModal
    v-if="isOpenDspProjectModal"
    :estimateData="estimateData"
    @close="toggleDspProjectDetail"
    @continue="continueDisputeProjectDetails"
  />
  <DisputeReasonModal
    v-if="isOpenDisputeReasonModal"
    @close="toggleDisputeReasonModal"
    @sendSelectedReasons="handleSelectedReasons"
  />

  <DSPEvidenceGuidanceModal
    v-if="isOpenDspEvidenceGuidanceModal"
    @on-close="onCloseDSPEvidenceGuidanceModal"
  />

  <DSPRequestExtensionModal
    v-if="isOpenRequestExtensionModal"
    :disputeProject="disputeProject"
    :extensionRequested="extensionRequested"
    @on-close="onCloseDSPRequestExtensionModal"
    :projectDetails="projectDetails"
  />

  <ViewEvidenceModal
    v-if="isShowViewEvidenceModal"
    :projectData="ProjectData"
    @on-close="onCloseViewEvidenceModal"
  />

  <PostProjectLoadingModal
    v-if="isPostLoadingModal"
    :switchingText="switchingText"
    :projectPostProgressLoading="projectPostProgressLoading"
  />
</template>
<script lang="ts">
import {
  ref,
  onMounted,
  computed,
  inject,
  onBeforeUnmount,
  reactive,
} from "vue";
import { useStore } from "vuex";
import { PROJECT, PROJECT_STORE } from "@/store/modules/project";
import Loader from "@/core/components/common/Loader.vue";
import {
  ProjectJobStatusEnum,
  ProjectStatusType,
  ProjectTypesEnum,
  ProjectRatingTypeEnum,
  ProjectFrom,
} from "@/core/enums/ProjectsEnum";
import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import ChatCommentsComponent from "@/core/components/ChatCommentsComponent.vue";
import ToolTipComment from "@/core/components/ToolTipComment.vue";
import AddCommentComponent from "@/core/components/AddCommentComponent.vue";
import { getCurrentInstance } from "vue";
import commentsService from "@/core/services/comments.service";
import { USER_STORE } from "@/store/modules/user";
import { quillEditor } from "vue3-quill";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { getProperties } from "@/core/services/properties.service";
import { CHATMESSAGE } from "@/store/modules/chat-message";
import Text from "@/core/components/ui/general/Text.vue";
import AlertCard from "@/core/components/common/AlertCard.vue";
import Button from "@/core/components/ui/general/Button.vue";
import ProjectService from "@/core/services/project.service";
import { useRouter, useRoute } from "vue-router";
import InviteTeamMember from "./InviteTeamMember.vue";
import { PROJECT_DETAILS_ROUTE, PROJECT_ROUTE } from "../routes";
import { useDisplay } from "vuetify";
import ProjectRateModal from "@/core/components/modals/ProjectRateModal.vue";
import { ProjectPhaseType } from "@/core/enums/ProjectsEnum";
import { EscrowStatusEnum, EstimatePhaseType } from "@/core/enums/estimateEnum";
import {
  AgreeTermsConsumerDisclosure,
  DurationsType,
  EstimatePhaseStatus,
  PropertySideStatus,
} from "@/core/enums/estimateEnum";
import tradeEstimate from "@/store/modules/trade-estimate";
import tradeEstimateService from "@/core/services/trade-estimate.service";
// import SpecialismModel from "@/modules/project/components/SpecialismModel.vue";
import SpecialismMultiSelectModel from "@/modules/project/modal/SpecialismMultiSelectModel.vue";
import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import VideoConsultationChip from "@/core/uni-components/Chips/VideoConsultationChip.vue";
import $axios from "@/core/utils/axios-api-config";
import {
  displayToastMessage,
  getImageApiUrl,
  parseJSON,
} from "@/core/utils/common";
import workstation, { WORKSTATION } from "@/store/modules/workstation";
import DailyIframe from "@daily-co/daily-js";
import projectRatingService from "@/core/services/project-rating.service";
import { LatLngExpression } from "leaflet";
import SiteVisitModel from "./SiteVisitModel.vue";
// import PropertyMapModal from "@/modules/properties/components/Modals/PropertyMapModal.vue";
import { PropertyCategoryEnum } from "@/core/enums/PropertiesEnum";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import ResidentialChip from "@/core/uni-components/Chips/ResidentialChip.vue";
import ProjectAttachmentListComponent from "./common/ProjectAttachmentListComponent.vue";
import ConfirmDeleteProjectModal from "./common/ConfirmDeleteProjectModal.vue";
import currencyFilter from "@/filters/currency.filter";
import SuccessSendingReviewModal from "../modal/SuccessSendingReviewModal.vue";
import PropertyWithMapModal from "@/modules/project/modal/PropertyWithMapModal.vue";
import DSPModal from "@/modules/project/modal/DSPModal.vue";
import DSPInformationModal from "@/modules/project/modal/DSPInformationModal.vue";
import DSPOverviewModal from "@/modules/project/modal/DSPOverviewModal.vue";
import DisputeReasonModal from "@/modules/project/modal/DisputeReasonModal.vue";

import DSPProjectDetailModal from "@/modules/project/modal/DSPProjectDetailModal.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import moment from "moment";
import { ChatTypeEnum } from "@/core/enums/chatsEnums";
import projectServices from "@/core/services/project.service";
import DSPEvidenceGuidanceModal from "../modal/DSPEvidenceGuidanceModal.vue";
import DSPRequestExtensionModal from "../modal/DSPRequestExtensionModal.vue";
import ViewEvidenceModal from "@/core/components/ViewEvidenceModal.vue";
import { DisputeTypeEnums } from "@/core/enums/disputeEnum";
import PostProjectLoadingModal from "../modal/PostProjectLoadingModal.vue";
import CongratsIcon from "@/core/components/icons/CongratsIcon.vue";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";

export default {
  name: "ProjectReviewComponent",
  props: {
    projectRefId: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  components: {
    Loader,
    // AddressMapComponent,
    // PropertyMapModal,
    ChatCommentsComponent,

    ToolTipComment,
    AddCommentComponent,
    quillEditor,
    CommonDialog,
    Text,
    AlertCard,
    Button,
    InviteTeamMember,
    // SpecialismModel,
    SpecialismMultiSelectModel,
    EmergencyChip,
    StandardChip,
    RoutineChip,
    InfoIcon,
    VideoConsultationChip,
    // Overview,
    ProjectRateModal,
    SiteVisitModel,
    CommercialChip,
    ResidentialChip,
    ProjectAttachmentListComponent,
    ConfirmDeleteProjectModal,
    SuccessSendingReviewModal,
    PropertyWithMapModal,
    DSPModal,
    DSPInformationModal,
    DSPOverviewModal,
    DisputeReasonModal,
    DSPProjectDetailModal,
    DSPEvidenceGuidanceModal,
    DSPRequestExtensionModal,
    ViewEvidenceModal,
    PostProjectLoadingModal,
    CongratsIcon,
    BlueCircularPencilIcon,
  },

  setup(props: any, ctx: any) {
    const store = useStore();
    const isCarouselDialog = ref(false);
    const currentIndex = ref(0);
    const ProjectData = ref();
    const projectDataProperties = ref();
    const projectAttachments = ref([]) as any;
    const loading = ref(false);
    const loadingSelectProperty = ref(false);
    const addresses: any = ref([]);
    const isEditProjectNoteSection = ref(false);
    const isEditPropertiesSection = ref(false);
    const isEditSpecialismSection = ref(false);
    const isEditAttachmentsSection = ref(false);
    const isLoadingRemoveAttachments = ref(false);
    const isShowAttachmentModal = ref(false);
    const projectTitle = ref("");
    const isShowPropertyListModal = ref(false);
    const isFetchingPropertiesLoading = ref(false);
    const propertiesList = ref([]) as any;
    const panel = ref([]) as any;
    const imageUrl = ref("");
    const errorMessage = ref("");
    const uploadedImages = ref(null) as any;
    const attachmentDataList = ref([]) as any;
    const userSelectedProperties = ref([]) as any;
    const isLoadingUpdatePropriety = ref(false);
    const showProjectOverviewDialog = ref(false);
    const isLoadingProjectNotes = ref(false);
    const isLoadingProjectData = ref(false);
    const attachmentsUpdateList = ref([]) as any;
    const carouselImageList = ref();
    const availableCategoryList = ref();
    const cloneAttachmentDataList = ref([]) as any;
    const isLoadingUploadAttachments = ref(false);
    const isNewPropertyAdded = ref(false);
    const removeAttachmentsIds = ref([]) as any;
    const clickOutside = ref(null) as any;

    const isShowSpecialismModal = ref(false);

    const tradeServicesPanel = ref([0]);

    const isLoadingForSaveSpecialism = ref(false);

    const iShowInviteTeamMemberModal = ref(false);

    const isShowSuccessSendReviewModal = ref(false);

    const isShowViewEvidenceModal = ref(false);

    const router = useRouter();
    const route = useRoute();

    const isShowLoaderOnSaveButton = ref(false);
    const isShowLoaderOnSaveButtonForDraft = ref(false);
    const vuetify = useDisplay();
    const projectStatus = ref(null) as any;
    const isShowSiteVisitModal = ref(false);
    const userCategory = [
      {
        name: "Floorplan",
      },
      {
        name: "Garden",
      },
      {
        name: "Materials",
      },
      {
        name: "Maps",
      },
    ];
    const SERVICE_CHARGE = 0.03;
    const PHASE_FIRST_CHARGE = 5;
    const MATERIALS = "Materials";
    const paginationData = ref({
      currentPage: 0,
      totalPages: 0,
      totalPropertiesCount: 0,
    });
    const isShowDashboard = ref({
      isActive: true,
      type: "chat",
    });
    const propertyId = ref();
    const isPostLoadingModal = ref(false);
    const switchingImage = ref("postlive-project-stage1.png");
    const internalInstance = getCurrentInstance();
    const projectPostProgressLoading = ref("0");
    const isShowProjectPostSuccessFloater = ref(false);
    const tabListItems = [
      {
        tab: "step1",
        name: "Posting Your Project",
      },
      {
        tab: "step2",
        name: "Applying Dispute Resolution",
      },
    ];
    const inviteMemberList = ref([
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Sam Johnson",
        subtitle: `samjohnson@iknowa.com`,
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Sam Johnson",
        subtitle: "samjohnson@iknowa.com",
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Sam Johnson",
        subtitle: "samjohnson@iknowa.com",
      },
    ]);
    const switchingText = ref(
      `Applying your project <br> <strong>dispute protection...</strong>`
    );
    const currentSelectedTab = ref(tabListItems[0]);
    const activeAddComment: any = ref([]);
    const disputeReasons = ref([]);

    const isMobileView = computed(() => vuetify.mdAndDown.value);

    const deletedProject = ref([]) as any;

    const isLoadingOnCancel = ref(false);

    const isLoadingOnAttachmentCancel = ref(false);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const canvas: any = ref(null);
    const projectComments: any = ref([]);

    const selectedFile = ref([]);
    const activeComment: any = ref({
      elementId: null,
    });
    const pusher = inject("pusher") as any;
    const subscribeCommentRoomsChannelList = ref([]) as any;

    const isOpenDspEvidenceModal = ref(false);
    const isOpenDSPInformationModal = ref(false);

    const isOpenDspEvidenceGuidanceModal = ref(false);
    const isFromDpsOverview = ref(true);

    const isOpenRequestExtensionModal = ref(false);

    const isOpenDspOverviewModal = ref(false);
    const isOpenDisputeReasonModal = ref(false);
    const isOpenDspProjectModal = ref(false);
    const disputeProjectErpDueDate = ref(null) as any;
    const isRequestedChat = ref(false);
    const disputeProjectData = ref({
      reasons: "",
      estimatePhaseIds: [],
      subcontractors: [],
      attachment: "",
      description: "",
      projectRefId: "",
    });

    const onOpenDSPEvidenceGuidanceModal = (isFromOverview = true) => {
      isOpenDspOverviewModal.value = false;
      isOpenDspEvidenceGuidanceModal.value = true;
      isFromDpsOverview.value = isFromOverview;
    };
    const onCloseDSPEvidenceGuidanceModal = () => {
      isOpenDspEvidenceGuidanceModal.value = false;
      if (isFromDpsOverview.value) {
        isOpenDspOverviewModal.value = true;
      }
    };

    const onOpenDSPRequestExtensionModal = () => {
      isOpenRequestExtensionModal.value = true;
    };
    const onCloseDSPRequestExtensionModal = () => {
      isOpenRequestExtensionModal.value = false;
    };
    const toggleDspEvidenceModal = () => {
      isOpenDspEvidenceModal.value = !isOpenDspEvidenceModal.value;
    };
    const toggleDSPInformationModal = () => {
      isOpenDSPInformationModal.value = !isOpenDSPInformationModal.value;
      isOpenDspOverviewModal.value = false;
    };
    const onCreateDisputeProject = () => {
      disputeProjectData.value = {
        reasons: "",
        estimatePhaseIds: [],
        subcontractors: [],
        attachment: "",
        description: "",
        projectRefId: "",
      };

      toggleDspOverviewModal();
    };
    const toggleDspOverviewModal = () => {
      isOpenDspOverviewModal.value = !isOpenDspOverviewModal.value;
    };
    const toggleDisputeReasonModal = () => {
      isOpenDisputeReasonModal.value = !isOpenDisputeReasonModal.value;
    };
    const toggleDspProjectDetail = () => {
      isOpenDSPInformationModal.value = false;
      isOpenDspProjectModal.value = !isOpenDspProjectModal.value;
    };
    const continueDisputeProjectDetails = (data: any) => {
      isOpenDspProjectModal.value = false;
      disputeProjectData.value.estimatePhaseIds = data.phases;
      disputeProjectData.value.subcontractors = data.contractorIds;

      toggleDisputeReasonModal();
    };

    const submitDisputeProject = async (data: any) => {
      try {
        disputeProjectData.value.description = data.description;
        disputeProjectData.value.attachment = data.attachments;
        disputeProjectData.value.projectRefId = projectDetails.value.refId;
        await store.dispatch(
          `${PROJECT_STORE}/disputeProject`,
          disputeProjectData.value
        );
        const successMsg = "Dispute Project SuccessFully";
        displayToastMessage(internalInstance, successMsg, "success");
        await store.dispatch(`${PROJECT_STORE}/getDisputeProject`, {
          projectRefId: projectDetails.value.refId,
        });
      } catch (error: any) {
        if (error.response) {
          if (error.response.status) {
            const errorMessage =
              error.response.data.message ||
              "An error occurred. Please try again.";
            displayToastMessage(internalInstance, errorMessage, "error");
          } else {
            // Handle other status codes or errors
            displayToastMessage(
              internalInstance,
              `Error: ${error.response.statusText}`,
              "error"
            );
          }
        } else {
          displayToastMessage(
            internalInstance,
            "The request took too long and timed out. Please try again later.",
            "error"
          );
        }
      } finally {
        disputeProjectData.value = {
          reasons: "",
          estimatePhaseIds: [],
          subcontractors: [],
          attachment: "",
          description: "",
          projectRefId: "",
        };
        toggleDspEvidenceModal();
        onOpenChatDispute();
      }
    };
    const handleSelectedReasons = (selectedReasons: any) => {
      disputeProjectData.value.reasons = JSON.stringify(selectedReasons);
      isOpenDisputeReasonModal.value = false;

      toggleDspEvidenceModal();
    };
    const onOpenChatDispute = async () => {
      try {
        isRequestedChat.value = true;
        const projectChat = allMessagesRooms.value.find((room: any) => {
          return (
            room.chatType === ChatTypeEnum.DISPUTE_PROJECT &&
            room.project?.id === projectDetails.value.id &&
            room.dispute?.id === disputeProject.value.id
          );
        });

        if (!projectChat) {
          const data = await projectServices.createProjectChat({
            groupName: projectDetails.value.name,
            groupAvatar: "",
            chatType: ChatTypeEnum.DISPUTE_PROJECT,
            projectId: projectDetails.value.id,
            userId: estimateData.value.user.id,
            workstationId: estimateData.value.workStation.id,
            disputeId: disputeProject.value.id,
            users: [
              {
                userId: projectDetails.value.projectCreator.userId,
                workstationId:
                  projectDetails.value.projectCreator.userWorkstationId,
              },
            ],
          });
          await store.dispatch(`${CHATMESSAGE}/setProjectChatRequests`, data);
        }

        await store.dispatch(
          `${CHATMESSAGE}/setProjectChatRequests`,
          projectChat
        );
      } catch (error: any) {
        if (error.response) {
          if (error.response.status) {
            const errorMessage =
              error.response.data.message ||
              "An error occurred. Please try again.";
            displayToastMessage(internalInstance, errorMessage, "error");
          } else {
            // Handle other status codes or errors
            displayToastMessage(
              internalInstance,
              `Error: ${error.response.statusText}`,
              "error"
            );
          }
        } else {
          displayToastMessage(
            internalInstance,
            "The request took too long and timed out. Please try again later.",
            "error"
          );
        }
      } finally {
        isRequestedChat.value = false;
      }
    };
    const onCloseDispute = () => {
      onOpenChatDispute();
    };
    const calculateElapsedTime = (erp_due_date: string) => {
      const now = moment();
      const dueMoment = moment(erp_due_date); // Due date moment

      const isPast = now.isAfter(dueMoment); // Check if the due date is in the past

      const total_seconds = isPast
        ? now.diff(dueMoment, "seconds")
        : dueMoment.diff(now, "seconds");
      const total_minutes = isPast
        ? now.diff(dueMoment, "minutes")
        : dueMoment.diff(now, "minutes");
      const total_hours = isPast
        ? now.diff(dueMoment, "hours")
        : dueMoment.diff(now, "hours");
      const days = isPast
        ? now.diff(dueMoment, "days")
        : dueMoment.diff(now, "days");

      const hours = total_hours - days * 24;
      const minutes = total_minutes - total_hours * 60;
      const seconds = total_seconds - total_minutes * 60;

      let countdown = "";

      if (days > 0) {
        countdown += `${days} Day${days !== 1 ? "s" : ""} `;
      }
      if (hours > 0) {
        countdown += `${hours} Hr${hours !== 1 ? "s" : ""} `;
      }
      if (minutes > 0) {
        countdown += `${minutes} Min${minutes !== 1 ? "s" : ""} `;
      }
      if (seconds > 0) {
        countdown += `${seconds} Sec${seconds !== 1 ? "s" : ""}`;
      }

      return countdown.trim();
    };

    const realTimeSetInterval = setInterval(() => {
      if (disputeProject.value?.erp_due_date)
        disputeProjectErpDueDate.value = calculateElapsedTime(
          disputeProject.value.erp_due_date
        );
    }, 500);

    /* methods */
    const redirectToEscrowAccount = async () => {
      await store.dispatch(
        `${PROJECT_STORE}/setProjectRedirectTabName`,
        "escrow-account"
      );
    };

    const onComment = async (comment: any) => {
      try {
        const payload = {
          projectId: ProjectData.value?.id,
          elementId: activeAddComment.value[0].elementId,
          workStationId: ProjectData.value?.userWorkstation?.id,
        };
        const response: any = await commentsService.createUserCommentsRoom(
          payload
        );
        const userCommentRoomId = response?.id;
        const commentPayload = {
          comment,
          userId: user.value.id,
          commentRoomId: userCommentRoomId,
        };
        await commentsService.createUserComments(commentPayload);
      } catch (error) {
        console.log(error);
      } finally {
        getAllCommentsRoomByProjectId();
      }
      activeAddComment.value = [];
    };

    const addComments = (e: any) => {
      if (!isAlreadyCommented(e?.target?.id)) return;
      if (isShowDashboard.value.type !== "comment") return;
      if (activeAddComment.value && activeAddComment.value.length > 0) {
        activeAddComment.value = [];
        return;
      }

      if (e?.target?.id && e?.target.hasAttribute("data-comment-element")) {
        activeComment.value.elementId = null;
        const canvasId = document.getElementById("canvas_id");
        const rect = canvasId?.getBoundingClientRect();
        const mainElement: any = document.getElementById("main_layout_content");
        if (rect) {
          activeAddComment.value = [
            {
              elementId: e?.target?.id,
              styles: {
                x: isMobileView.value ? e.clientX - 70 : e.clientX - 345,
                y: e.clientY + mainElement.scrollTop - 175,
              },
            },
          ];
        }
      }
    };
    const setProjectData = async () => {
      try {
        // isLoadingProjectData.value = true;
        const projectDataResponse = await store.dispatch(
          `${PROJECT_STORE}/getProjectData`,
          {
            projectRefId: props.projectRefId,
          }
        );
        availableCategoryList.value = await store.dispatch(
          `${PROJECT_STORE}/getProjectCategoryList`,
          projectDataResponse.id
        );
        ProjectData.value = projectDataResponse;
        store.dispatch(`${PROJECT}/projectDetails`, {
          inviteMembers: ProjectData.value?.invitedMembersAndRoles,
        });

        await getPropertyStatus(ProjectData.value.id);

        projectAttachments.value = projectDataResponse?.projectAttachments;

        selectedIndices.value = projectDataResponse?.projectProperties.map(
          (property: any) => property?.property?.id
        );
        ProjectData.value?.projectProperties.map((item: any) => {
          const existingPropertyIndex = userSelectedProperties.value.findIndex(
            (property: any) => property.id === item.property.id
          );
          if (existingPropertyIndex === -1)
            userSelectedProperties.value.push(item.property);
        });
        attachmentDataList.value =
          projectDataResponse?.projectAttachmentProperties.map(
            (propertyItems: any) => ({
              uploadedImages: {
                url: propertyItems[0]?.attachmentUrl,
                name: propertyItems[0]?.projectAttechment?.originalName,
                attachment: propertyItems[0]?.projectAttechment?.attachment,
                attachmentId: propertyItems[0]?.projectAttechment?.id,
                id: propertyItems[0]?.id,
                type: ["jpeg", "png", "svg", "jpg"].includes(
                  propertyItems[0]?.projectAttechment?.originalName
                    .split(".")
                    .pop()
                    .toLowerCase()
                )
                  ? "image"
                  : "",
              },
              propertyId: propertyItems.map(
                (property: any) => property.property.id
              ),
              userCategoryName:
                propertyItems[0]?.projectAttachmentsCategory?.value,
            })
          );
        cloneAttachmentDataList.value = JSON.parse(
          JSON.stringify(attachmentDataList.value)
        );
      } catch (error) {
        console.log();
      } finally {
        // isLoadingProjectData.value = false;
      }
    };
    const getProjectData = async () => {
      try {
        loading.value = true;
        await setProjectData();
        // const propertiesResponse = await store.dispatch(
        //   `${PROJECT_STORE}/getProjectDataProperties`,
        //   {
        //     projectRefId: props.projectRefId,
        //   }
        // );
        projectDataProperties.value = ProjectData.value.projectProperties;

        let addressList: any = [];
        // projectDataProperties.value &&
        //   projectDataProperties.value.map((item: any) => {
        //     if (item?.property?.addressData) {
        //       const address = parseJSON(item.property.addressData);
        //       addressList.push({
        //         address: item.property.address,
        //         uprn: item.property.uprn,
        //         latlang: getLatLng(address),
        //         id: item.property.id,
        //         postcode: item.property.postcode,
        //       });
        //     }
        //   });
        addressList = projectDataProperties.value.map((item: any) => {
          return item?.property;
        });
        addresses.value = addressList;
      } catch (error) {
        console.log("error:", error);
      } finally {
        loading.value = false;
      }
    };
    const getLatLng = (addressData: string): LatLngExpression | undefined => {
      if (!addressData) return undefined;

      try {
        const unParsedData = parseJSON(addressData);
        const latlng: LatLngExpression = [
          unParsedData.LAT ? unParsedData.LAT : unParsedData.lat,
          unParsedData.LNG ? unParsedData.LNG : unParsedData.lng,
        ];
        return latlng;
      } catch (error) {
        console.error("Invalid address data:", error);
        return undefined;
      }
    };
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();

      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      const formattedHours = hours < 10 ? "0" + hours : hours;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

      return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
    };

    const openCarousel = (idx: any = 0, categoryData: any) => {
      isCarouselDialog.value = true;
      carouselImageList.value = getFilterImages(categoryData);
      currentIndex.value =
        carouselImageList.value?.findIndex((item: any) => item?.id == idx) || 0;
    };
    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickSaveDraftProject = async () => {
      try {
        isShowLoaderOnSaveButtonForDraft.value = true;

        await ProjectService.updateProject(
          {
            projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
            inviteMembers: projectDetails.value?.inviteMembers,
          },
          ProjectData.value.id
        );
        displayToast("Your Project Added In Draft Projects.", "success");
        router.push({ name: PROJECT_STORE });
      } catch (error) {
        console.log();
      } finally {
        isShowLoaderOnSaveButtonForDraft.value = false;
      }
    };

    const onClickEditProject = async () => {
      ctx.emit("onClickeditProject", {
        projectRefId: ProjectData.value?.refId,
        data: true,
      });
    };

    const delay = (ms: any) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const randomNumber = computed(() => {
      const min = 350;
      return Math.floor(Math.random() * (1000 - min + 1)) + min;
    });

    const onClickPostLiveProject = async () => {
      try {
        onClickSiteVisit();
        isShowLoaderOnSaveButton.value = false;
        isPostLoadingModal.value = true;

        // If want One Scrren Comment this secction Start
        switchingText.value = "Posting Your Project";
        currentSelectedTab.value = tabListItems[0];
        projectPostProgressLoading.value = "10";

        // First step: Wait for 1 second
        await delay(1000);
        projectPostProgressLoading.value = "30";

        // Third step: Wait for 3 seconds
        await delay(3000);
        switchingText.value = "Applying Dispute Resolution";
        currentSelectedTab.value = tabListItems[1];
        projectPostProgressLoading.value = "70";

        // Third step: Wait for 5 seconds
        await delay(5000);
        switchingText.value = "Applying Dispute Resolution";
        currentSelectedTab.value = tabListItems[2];
        projectPostProgressLoading.value = "100";
        // const createProject = await store.dispatch(
        //   `${PROJECT_STORE}/updateProject`,
        //   {
        //     projectId: props.projectRefId,
        //     data: {
        //       projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
        //     },
        //   }
        // );
        await ProjectService.updateProject(
          {
            projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
            inviteMembers: projectDetails.value?.inviteMembers,
          },
          ProjectData.value.id
        );

        await getProjectData();

        // await store.dispatch(
        //   `${PROJECT_STORE}/setProjectRedirectTabName`,
        //   "active-project"
        // );
      } catch (error) {
        console.log("error : ", error);
      } finally {
        isPostLoadingModal.value = false;
        isShowLoaderOnSaveButton.value = false;
        onShowSuccessPostProjectFloater();
      }
    };

    const onShowSuccessPostProjectFloater = async () => {
      isShowProjectPostSuccessFloater.value = true;

      await delay(3000);
      isShowProjectPostSuccessFloater.value = false;
    };

    const onClickContinuePostProject = () => {
      displayToast("Your Project Added In Active Projects.", "success");
      isPostLoadingModal.value = false;
      router.push({ name: PROJECT_STORE });
    };
    const changePropertyId = async (value: any) => {
      if (value.length > 0) {
        try {
          loadingSelectProperty.value = true;
          let selectedProperties = await store.dispatch(
            `${PROJECT_STORE}/getProjectDataSelectedProperties`,
            {
              projectRefId: props.projectRefId,
              propertyIds: value,
            }
          );
          projectAttachments.value = selectedProperties;
        } catch (error) {
          console.log("error", error);
        } finally {
          loadingSelectProperty.value = false;
        }
      } else {
        projectAttachments.value = ProjectData.value?.projectAttachments;
      }
    };
    const openMessageDashboard = (type: any) => {
      store.dispatch(`${CHATMESSAGE}/setCurrentMessageType`, type);
      if (type == "chat") {
        isShowDashboard.value.type = "chat";
      } else {
        isShowDashboard.value.type = "comment";
      }
      isShowDashboard.value.isActive = true;
    };

    const getStylesByElementById = (elementId: string) => {
      const element = document.getElementById(elementId);
      const elementRect = element?.getBoundingClientRect();
      const mainElement: any = document.getElementById("main_layout_content");
      return elementRect
        ? {
            x: elementRect?.x + 20 - (window.innerWidth > 1279 ? 300 + 50 : 20),
            y:
              elementRect?.y +
              mainElement.scrollTop -
              (window.innerWidth > 541 || window.innerWidth < 350 ? 175 : 150),
          }
        : null;
    };
    const setCommentPosition = () => {
      projectComments.value.forEach((comment: any) => {
        comment.style = getStylesByElementById(comment.elementId);
      });
    };
    const getAllCommentsRoomByProjectId = async () => {
      try {
        const response: any =
          await commentsService.getPropertyOwnerCommentsRoomByProjectId(
            ProjectData.value?.id
          );
        getUserWorkStationProfileImage(activeUserWorkstation);
        if (response) {
          const commentStyles: any = [];
          response?.map((item: any) => {
            getUserWorkStationProfileImage(item.userWorkstation);
            commentStyles.push({
              ...item,
              style: getStylesByElementById(item.elementId),
            });
          });
          projectComments.value = commentStyles;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const filterAttachments = computed(
      () =>
        projectAttachments.value?.map((attachment: any) => ({
          ...attachment,
          categories: Object.values(attachment?.categories)?.map(
            (item: any) => item
          ),
        })) || []
    );

    const imageArray = computed(() => {
      let uniqueUrls = new Set();
      return (
        filterAttachments.value?.flatMap((attachment: any) => {
          return attachment.categories
            .filter((category: any) => {
              if (!uniqueUrls.has(category.attachmentUrl)) {
                uniqueUrls.add(category.attachmentUrl);
                return true;
              }
              return false;
            })
            ?.map((category: any) => ({ src: category.attachmentUrl }));
        }) || []
      );
    });

    const toggleActiveComment = (elementId: any) => {
      activeComment.value.elementId = elementId;
      scrollIntoViewOfScreen(elementId);
    };

    const updateCommentRoomStatus = async (
      commentRoomId: number,
      commentStatus: string
    ) => {
      try {
        await commentsService.updateUserCommentRoomStatus(commentRoomId, {
          status: commentStatus,
        });
        activeComment.value.elementId = null;
        getAllCommentsRoomByProjectId();
      } catch (error) {
        console.log("error", error);
      }
    };

    const nextComment = (idx: number, dir: string) => {
      const len = projectComments.value.length;
      if (idx < len && dir === "right") {
        activeComment.value.elementId = projectComments.value[idx]["elementId"];
      }
      if (idx >= 0 && dir === "left") {
        activeComment.value.elementId = projectComments.value[idx]["elementId"];
      }
      scrollIntoViewOfScreen(activeComment.value.elementId);
    };
    const scrollIntoViewOfScreen = (elementId: any) => {
      if (!elementId) return;
      const element = document.getElementById(elementId);
      const elementRect: any = element?.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const distanceToBottom = viewportHeight - elementRect?.bottom;
      const distanceToTop = elementRect?.top || 0;

      if (distanceToBottom < 0 && element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      if (distanceToTop < 0 && element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    const subscribeProjectCommentRoom = () => {
      const roomChannel = pusher?.subscribe(
        `project-comments-room-${ProjectData.value?.id}`
      );
      roomChannel?.bind("new.commentRoom", async (commentRoomData: any) => {
        if (commentRoomData) {
          await getAllCommentsRoomByProjectId();
        }
      });
      subscribeCommentRoomsChannelList.value.push(roomChannel);
    };

    const closeProjectCommentRoom = () => {
      const roomChannel = pusher?.subscribe(
        `project-comments-close-room-${ProjectData.value?.id}`
      );
      roomChannel?.bind("close.commentRoom", (commentRoomId: any) => {
        if (commentRoomId) {
          projectComments.value = projectComments.value.filter(
            (data: any) => data.id !== commentRoomId
          );
        }
      });
      subscribeCommentRoomsChannelList.value.push(roomChannel);
    };
    const unbindSubscribedCommentsRoomsChannels = () => {
      if (subscribeCommentRoomsChannelList.value.length) {
        subscribeCommentRoomsChannelList.value.forEach((channel: any) => {
          // Unbind all events from channel
          channel?.unbind();
          pusher?.unsubscribe(channel.name);
        });
        subscribeCommentRoomsChannelList.value.length = [];
      }
    };
    const selectedIndices = ref([]) as any;

    const isSelected = (index: number) => {
      return selectedIndices.value.includes(index);
    };

    const toggleSelection = (index: number) => {
      const selectedIndex = selectedIndices.value.indexOf(index);
      if (selectedIndex === -1) {
        selectedIndices.value.push(index);
        propertyEditModalAlert.value = "";
      } else {
        selectedIndices.value.splice(selectedIndex, 1);
      }
    };

    const getPropertyType = (property: any) => {
      return property?.propertyType
        ? property?.propertyType?.trim()
        : typeof property?.epcData === "string"
        ? parseJSON(property?.epcData)
          ? parseJSON(property?.epcData)["property-type"]
          : "NA"
        : property?.epcData
        ? property?.epcData["property-type"]
        : "NA";
    };

    const getPropertiesListing = async (page: number, limit: number) => {
      try {
        const response = await getProperties({
          page: page,
          limit: limit,
          search: "",
        });

        if (response) {
          paginationData.value.currentPage = response.page;
          paginationData.value.totalPages = response.totalPages;
          paginationData.value.totalPropertiesCount = response.totalCount;
          isFetchingPropertiesLoading.value = false;
        }

        if (response?.data?.length) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error);
        isFetchingPropertiesLoading.value = false;
      }
    };

    const state = reactive({
      content: "",
      _content: "",
      editorOption: {
        placeholder: "Description here",
        modules: {},
      },
      disabled: false,
    });

    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      errorMessage.value = "";
      if (fileInput.files && fileInput.files.length > 0) {
        Array.from(fileInput.files).forEach((file: any) => {
          if (file.type.includes("image")) {
            // Check image size limit (200MB)
            if (file.size <= 200 * 1024 * 1024) {
              imageUrl.value = URL.createObjectURL(file);
              uploadedImages.value = {
                url: imageUrl.value,
                name: file.name,
                imageData: file,
                type: "image",
              };
              addPropertyImage();
            } else {
              errorMessage.value = "Image size exceeds the limit (200MB).";
              // Handle error message or display notification
            }
          } else if (file.type.includes("video")) {
            // Check video size limit (20MB)
            if (file.size <= 10 * 1024 * 1024) {
              uploadedImages.value = {
                type: "video",
                name: file.name,
                imageData: file,
              };
              addPropertyImage();
              // Process video file
            } else {
              errorMessage.value = "Video size exceeds the limit (20MB).";
              // Handle error message or display notification
            }
          } else {
            // Allow other file types without size validation
            imageUrl.value = URL.createObjectURL(file);
            uploadedImages.value = {
              url: imageUrl.value,
              name: file.name,
              imageData: file,
            };
            addPropertyImage();
          }
        });
      } else {
        console.error("Error: No file selected.");
      }
    };

    const addPropertyImage = () => {
      attachmentDataList.value.push({
        uploadedImages: { ...uploadedImages.value },
        propertyId:
          userSelectedProperties.value.length == 1
            ? [userSelectedProperties.value[0].id]
            : null,
        userCategoryName: null,
      });
      uploadedImages.value = null;
      isNewPropertyAdded.value = true;
      selectedFile.value = [];
      imageUrl.value = "";
    };

    const isAttachmentDataValid = computed(() => {
      if (!attachmentDataList.value || attachmentDataList.value.length === 0) {
        return false;
      }

      return attachmentDataList.value.every(
        (attachment: { propertyId: null; userCategoryName: null }) =>
          attachment.propertyId !== null && attachment.userCategoryName !== null
      );
    });

    const removeImageFromAttachments = (index: number, attachmentId: any) => {
      removeAttachmentsIds.value.push(attachmentId);
      // attachmentDataList.value.splice(index, 1);
      cloneAttachmentDataList.value.forEach((attachment: any) => {
        if (attachment.uploadedImages.attachmentId === attachmentId) {
          attachment["isDeleted"] = true;
        }
      });
    };

    const onCancelProjectNotes = () => {
      isEditProjectNoteSection.value = false;
    };

    const onClickSaveProjectNotes = async () => {
      try {
        isLoadingProjectNotes.value = true;
        await store.dispatch(`${PROJECT_STORE}/updateProject`, {
          projectId: ProjectData.value?.id,
          data: {
            description: state.content,
            name: projectTitle.value,
          },
        });
        displayToast("Update successfully.", "success");
      } catch {
        console.log();
      } finally {
        await setProjectData();
        isEditProjectNoteSection.value = false;
        isLoadingProjectNotes.value = false;
      }
    };
    const onClickCancelSpecialismButton = async () => {
      isEditSpecialismSection.value = false;
      isLoadingProjectData.value = false;
      await setProjectData();
    };
    const onClickCancelButton = async () => {
      try {
        isLoadingOnCancel.value = true;
        await setProjectData();
        isEditPropertiesSection.value = false;
      } catch (e) {
        console.log("Caught exception", e);
      } finally {
        isLoadingOnCancel.value = false;
        deletedProject.value = [];
      }
    };

    const onClickSaveSpecialismButton = async () => {
      isLoadingProjectData.value = true;
      let selectedCategoryFinal = ProjectData.value?.subCategories.map(
        (subCategory: any) => {
          return subCategory.id;
        }
      );

      await store.dispatch(`${PROJECT_STORE}/updateSubSpecialismCategory`, {
        projectId: ProjectData.value?.id,
        data: {
          categoryData: selectedCategoryFinal,
        },
      });

      onClickCancelSpecialismButton();
    };

    const onClickSaveButton = async () => {
      isLoadingProjectData.value = true;
      await updateProperties(deletedProject.value);
      await setProjectData();
      isEditPropertiesSection.value = false;
      isLoadingProjectData.value = false;
      deletedProject.value = [];
    };

    const onClickTogglePropertyListModal = () => {
      isShowPropertyListModal.value = !isShowPropertyListModal.value;
    };

    const onClickRemoveSpecialism = (projectSpecialism: any) => {
      updateSpecialism([
        {
          categoryId: projectSpecialism?.category?.id,
          isDeleted: true,
        },
      ]);
    };

    const onClickRemoveProperty = async (ProjectDetails: any) => {
      deletedProject.value.push({
        id: ProjectDetails?.id,
        propertyId: ProjectDetails?.property?.id,
        isDeleted: true,
      });
      ProjectData.value.projectProperties =
        ProjectData.value.projectProperties.filter((property: any) => {
          return property?.id !== ProjectDetails?.id;
        });
    };
    const propertyEditModalAlert = ref("");
    const onClickPropertyModalSave = async () => {
      if (!selectedIndices.value?.length) {
        propertyEditModalAlert.value =
          "Project must have at least one property";
        return;
      }
      const projectDefaultPropertyIds =
        ProjectData.value?.projectProperties.map(
          (property: any) => property?.property?.id
        );
      const newSelectedPropertyData = selectedIndices.value
        .filter(
          (propertyId: any) => !projectDefaultPropertyIds.includes(propertyId)
        )
        .map((propertyId: any) => ({ propertyId }));
      const removePropertyData = ProjectData.value?.projectProperties
        .filter(
          (detail: any) => !selectedIndices.value.includes(detail.property.id)
        )
        .map((detail: any) => ({
          id: detail?.id,
          propertyId: detail?.property?.id,
          isDeleted: true,
        }));
      await updateProperties([
        ...removePropertyData,
        ...newSelectedPropertyData,
      ]);
      await setProjectData();

      projectDataProperties.value = ProjectData.value.projectProperties;
      let addressList: any = [];
      addressList = projectDataProperties.value.map((item: any) => {
        return item?.property;
      });
      addresses.value = addressList;
      onClickTogglePropertyListModal();
    };

    const updateSpecialism = async (specialism: any) => {
      try {
        isLoadingUpdatePropriety.value = true;
        if (specialism.length && ProjectData.value?.id)
          await store.dispatch(`${PROJECT_STORE}/updateSpecialism`, {
            projectId: ProjectData.value?.id,
            specialism,
          });
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoadingUpdatePropriety.value = false;
      }
    };

    const updateProperties = async (propertiesData: any) => {
      try {
        isLoadingUpdatePropriety.value = true;
        if (propertiesData.length && ProjectData.value?.id)
          await store.dispatch(`${PROJECT_STORE}/updateProject`, {
            projectId: ProjectData.value?.id,
            data: { propertiesData },
          });
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoadingUpdatePropriety.value = false;
      }
    };

    const updateSpecialismSection = () => {
      isEditSpecialismSection.value = true;

      isEditPropertiesSection.value = false;
      isEditAttachmentsSection.value = false;
      isEditProjectNoteSection.value = false;
    };

    const updatePropertiesSection = async () => {
      isEditPropertiesSection.value = true;

      isEditSpecialismSection.value = false;
      isEditAttachmentsSection.value = false;
      isEditProjectNoteSection.value = false;
      await setProjectData();
    };

    const updateAttachmentsSection = async (index: number) => {
      panel.value.push(index);
      isEditAttachmentsSection.value = true;
      isEditPropertiesSection.value = false;
      isEditSpecialismSection.value = false;
      isEditProjectNoteSection.value = false;

      await setProjectData();
    };

    const onClickSaveButtonAttachments = async () => {
      try {
        isLoadingRemoveAttachments.value = true;
        await store.dispatch(`${PROJECT_STORE}/updateProject`, {
          projectId: ProjectData.value?.id,
          data: {
            attachments: attachmentsUpdateList.value,
          },
        });
        attachmentsUpdateList.value = null;
        await setProjectData();
        isEditAttachmentsSection.value = false;
      } catch {
        console.log();
      } finally {
        isLoadingRemoveAttachments.value = false;
      }
    };

    const onCancelAttachments = async () => {
      try {
        isLoadingOnAttachmentCancel.value = true;
        await setProjectData();
        isEditAttachmentsSection.value = false;
      } catch (e) {
        console.log("Error :", e);
      } finally {
        isLoadingOnAttachmentCancel.value = false;
      }
    };

    const onClickToggleAttachmentModal = () => {
      isShowAttachmentModal.value = !isShowAttachmentModal.value;
      isNewPropertyAdded.value = false;
    };

    const onClickRemoveAttachment = (
      attachment: any,
      category: any,
      categoryName: any,
      attachmentIndex: any,
      categoryId: number
    ) => {
      projectAttachments.value[attachmentIndex].categories[categoryName] =
        projectAttachments.value[attachmentIndex].categories[
          categoryName
        ].filter((category: any) => {
          return category?.id !== attachment?.id;
        });

      attachmentsUpdateList.value.push({
        id: attachment?.id,
        propertyIds: [
          {
            id: category?.id,
            propertyId: category?.propertyId,
            isDeleted: true,
          },
        ],
        attechmentCategoryId: attachment?.categoryId,
        isDeleted: false,
      });
    };

    const updateProjectNameNote = async (ProjectDetails: any) => {
      isEditProjectNoteSection.value = true;
      projectTitle.value = ProjectDetails?.name;
      state.content = ProjectDetails?.description;

      isEditSpecialismSection.value = false;
      isEditPropertiesSection.value = false;
      isEditAttachmentsSection.value = false;

      await setProjectData();
    };

    const propertyRequiredError = ref([]) as any;
    const onClickSaveButtonAttachmentsModal = async () => {
      isLoadingUploadAttachments.value = true;
      propertyRequiredError.value = [];

      for (const index in attachmentDataList.value) {
        if (!attachmentDataList.value[index]?.propertyId?.length) {
          propertyRequiredError.value.push(+index);
        }
      }
      if (propertyRequiredError.value?.length) {
        isLoadingUploadAttachments.value = false;
        return;
      }
      try {
        //new attachment add functionality
        const newAttachmentDataList = attachmentDataList.value.filter(
          (attachment: any) => attachment?.uploadedImages.imageData
        );

        const filterAttachmentDataList = attachmentDataList.value.filter(
          (attachment: any) => !attachment?.uploadedImages.imageData
        );
        // check if new category add then upload that

        for (const attachment of attachmentDataList.value) {
          const existCategory = availableCategoryList.value.find(
            (category: any) => category?.label === attachment?.userCategoryName
          );
          if (!existCategory) {
            const response = attachment?.userCategoryName
              ? await store.dispatch(`${PROJECT_STORE}/attachmentCategory`, {
                  label: attachment.userCategoryName,
                  value: attachment.userCategoryName,
                })
              : {};
            availableCategoryList.value.push(response);
          }
        }

        let newCreatedAttachment = [];
        if (newAttachmentDataList.length) {
          const formData = new FormData();
          newAttachmentDataList.forEach((attachment: any) => {
            if (attachment.uploadedImages.imageData) {
              formData.append(`files`, attachment.uploadedImages.imageData);
            }
          });

          const uploadMultipleImage = await store.dispatch(
            `${USER_STORE}/uploadMultipleImage`,
            formData
          );

          newCreatedAttachment = uploadMultipleImage.map(
            (item: any, index: number) => ({
              publicUrl: item.publicUrl,
              attachment: item.attachment,
              originalName: item.originalName,
              propertyIds: newAttachmentDataList[index]?.propertyId.map(
                (id: any) => ({ propertyId: id, isDeleted: false })
              ),
              attechmentCategoryId: availableCategoryList.value.find(
                (category: any) =>
                  category?.label ===
                  newAttachmentDataList[index]?.userCategoryName
              )?.id,
              isDeleted: false,
            })
          );
        }

        const finalDataAttachment: any = [];
        const projectDefaultPropertyIds =
          ProjectData.value?.projectProperties.map(
            (property: any) => property?.property?.id
          );

        cloneAttachmentDataList.value.forEach((attachment: any, index: any) => {
          let attachmentData;
          if (attachment.isDeleted) {
            attachmentData = {
              id: attachment?.uploadedImages?.attachmentId,
              attachment: attachment?.uploadedImages?.attachment,
              originalName: attachment?.uploadedImages?.name,
              attechmentCategoryId: attachment?.uploadedImages?.attachmentId,
              propertyIds: attachment.propertyId.map((id: any) => ({
                id: attachment?.uploadedImages?.id,
                propertyId: id,
                isDeleted: true,
              })),
              isDeleted: attachment.isDeleted,
            };
          } else {
            const propertyIds: any = [];
            projectDefaultPropertyIds.forEach((propertyId: number) => {
              if (
                attachment.propertyId.includes(propertyId) &&
                filterAttachmentDataList[index]?.propertyId?.includes(
                  propertyId
                )
              ) {
                propertyIds.push({
                  id: attachment?.uploadedImages?.id,
                  propertyId: propertyId,
                  isDeleted: false,
                });
              } else if (
                !attachment.propertyId.includes(propertyId) &&
                filterAttachmentDataList[index]?.propertyId?.includes(
                  propertyId
                )
              ) {
                propertyIds.push({
                  propertyId: propertyId,
                  isDeleted: false,
                });
              } else if (
                attachment.propertyId.includes(propertyId) &&
                !filterAttachmentDataList[index]?.propertyId?.includes(
                  propertyId
                )
              ) {
                propertyIds.push({
                  id: attachment?.uploadedImages?.id,
                  propertyId: propertyId,
                  isDeleted: true,
                });
              }
            });

            attachmentData = {
              id: attachment?.uploadedImages?.attachmentId,
              attachment: attachment?.uploadedImages?.attachment,
              originalName: attachment?.uploadedImages?.name,
              attechmentCategoryId: availableCategoryList.value.find(
                (category: any) =>
                  category.label ===
                  filterAttachmentDataList[index]?.userCategoryName
              )?.id,
              propertyIds: propertyIds,
            };
          }

          finalDataAttachment.push(attachmentData);
        });
        await store.dispatch(`${PROJECT_STORE}/updateProject`, {
          projectId: ProjectData.value?.id,
          data: {
            attachments: [...newCreatedAttachment, ...finalDataAttachment],
          },
        });

        await setProjectData();
        isEditAttachmentsSection.value = false;
      } catch (error) {
        console.log();
      } finally {
        isLoadingUploadAttachments.value = false;
        onClickToggleAttachmentModal();
      }
    };

    const normalizeData = () => {
      const addressList: any = [];
      propertiesList?.value?.length &&
        propertiesList?.value.map((item: any) => {
          if (item.addressData) {
            const address = parseJSON(item.addressData);

            const latlang = getLatLng(address);

            addressList.push({
              address: item.address,
              uprn: item.uprn,
              latlang,
              id: item.id,
              postcode: item.postcode,
            });
          }
        });
      addresses.value = addressList;
    };

    const isPaginationAvailable = computed(() => {
      const { totalPropertiesCount } = paginationData.value;
      return totalPropertiesCount !== propertiesList.value.length;
    });

    const onLoadMore = async () => {
      const { currentPage, totalPages, totalPropertiesCount } =
        paginationData.value;
      isFetchingPropertiesLoading.value = true;
      if (isPaginationAvailable.value) {
        const data = await getPropertiesListing(
          currentPage,
          totalPropertiesCount + 6
        );
        propertiesList.value = [...data];
        isFetchingPropertiesLoading.value = false;
        normalizeData();
      }
    };

    const getFilterCategory: any = (category: any) => {
      const categoryData = Object.entries(category)
        .map((data: any) => ({
          name: data[0],
          id: data[1][0]?.categoryId,
          attachments: data[1],
        }))
        .filter(
          (category) =>
            category?.name != "undefined" && category?.name != "null"
        );
      return categoryData;
    };
    const getNoCategoryAttachmentList: any = (category: any) => {
      const categoryData = Object.entries(category)
        .map((data: any) => ({
          name: data[0],
          id: data[1][0]?.categoryId,
          attachments: data[1],
        }))
        .filter(
          (category) =>
            category?.name == "undefined" || category?.name == "null"
        );
      return categoryData.length ? categoryData[0]?.attachments : [];
    };

    const getFilterImages: any = (category: any) => {
      const filterImages: any = [];
      return Object.values(category)
        .flat()
        .filter((category: any) => {
          if (!filterImages.includes(category.attachmentUrl)) {
            filterImages.push(category.attachmentUrl);
            return true;
          }
          return false;
        });
    };

    const isShowGroupCard = (index: number) => {
      return panel.value.includes(index);
    };

    const getCommentedElementIds: any = computed(
      () =>
        projectComments.value.map((comment: any) => comment?.elementId) || []
    );

    const isAlreadyCommented = (elementId: any) =>
      !getCommentedElementIds.value.includes(elementId);

    const clickOutsideHandler = (event: any) => {
      if (!clickOutside.value?.contains(event?.target))
        activeComment.value.elementId = null;
    };

    const onCloseModal = async () => {
      try {
        loading.value = true;
        iShowInviteTeamMemberModal.value = false;
        const projectDataResponse = await store.dispatch(
          `${PROJECT_STORE}/getProjectData`,
          {
            projectRefId: props.projectRefId,
          }
        );
        ProjectData.value = projectDataResponse;
      } catch (error) {
        console.log("error", error);
      } finally {
        loading.value = false;
      }
    };

    const onClickInviteTeamButton = () => {
      iShowInviteTeamMemberModal.value = true;
    };

    const onClickBackToSearchResults = () => {
      router.push({ name: PROJECT_ROUTE });
    };
    const getPropertyStatus = async (projectId: number) => {
      projectStatus.value = await tradeEstimateService.getPropertyStatus(
        projectId
      );
      if (projectStatus.value) {
        if (
          [
            ProjectJobStatusEnum.DRAFT_PROJECT,
            ProjectJobStatusEnum.CANCELLED,
          ].includes(projectDetails.value.projectJobStatus?.id)
        ) {
          return projectOverviewStatusList.value;
        }
        projectOverviewStatusList.value.forEach((status) => {
          if (status.statusCode <= projectStatus.value) {
            status.completed = true;
          }
        });
      }
    };
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const userImagesMap = ref({}) as any;

    const keyExists = (key: number) => {
      return key in userImagesMap.value;
    };
    const addUserImage = (id: number, imageUrl: any) => {
      if (!keyExists(id)) {
        userImagesMap.value[id] = imageUrl;
        return true;
      } else {
        return false;
      }
    };

    const getUserWorkStationProfileImage = async (userDetails: any) => {
      if (userDetails) {
        const profileImage = userDetails.profileImage;
        if (!keyExists(userDetails.id)) {
          if (!profileImage) {
            addUserImage(userDetails.id, null);
            return null;
          }
          const imageUrl = getImageApiUrl(profileImage, true);
          try {
            const encoded: any = await $axios.get(imageUrl);
            addUserImage(userDetails.id, encoded.publicUrl);
            return encoded.publicUrl;
          } catch (error) {
            return null;
          }
        }
      }
    };

    const onClickSaveButtonClick = async (selectedCategory: any) => {
      try {
        isLoadingForSaveSpecialism.value = true;
        const projectSubCategories = ProjectData.value?.subCategories.map(
          (subCategory: any) => {
            return subCategory.id;
          }
        );

        const removedSubCategories = projectSubCategories.filter(
          (subCategoryId: number) => {
            return !selectedCategory.includes(subCategoryId);
          }
        );

        if (removedSubCategories.length) {
          ProjectData.value.subCategories =
            ProjectData.value.subCategories.filter((subCategory: any) => {
              return !removedSubCategories.includes(subCategory.id);
            });
        }

        const newAddedSubCategoriesIds = selectedCategory.filter(
          (subCategoryId: number) => {
            return !projectSubCategories.includes(subCategoryId);
          }
        );

        if (newAddedSubCategoriesIds.length) {
          const newAddedSubCategoriesData = await store.dispatch(
            `${PROJECT_STORE}/getNewAddedSubCategoryForProject`,
            {
              subCategoriesIds: newAddedSubCategoriesIds,
            }
          );

          newAddedSubCategoriesData.forEach((subCategory: any) => {
            ProjectData.value?.subCategories.push(subCategory);
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoadingForSaveSpecialism.value = false;
        isShowSpecialismModal.value = false;
      }
    };

    const onClickRedirectToRelevantTab = (overview: any) => {
      if (overview.statusCode == projectStatus.value) {
        switch (overview.statusCode) {
          case 2:
            {
              store.dispatch(
                `${PROJECT_STORE}/setProjectRedirectTabName`,
                "trades-estimates"
              );
            }
            break;
          case 4:
            {
              store.dispatch(
                `${PROJECT_STORE}/setProjectRedirectTabName`,
                "escrow-account"
              );
            }
            break;
        }
      }
    };

    const initialize = async () => {
      await getProjectData();
      if (projectStatus.value == ProjectJobStatusEnum.COMPLETED) {
        await checkIsUserRatedProject();
      }
      const data = await getPropertiesListing(1, 6);
      propertiesList.value = data;
      getAllCommentsRoomByProjectId();
      subscribeProjectCommentRoom();
      closeProjectCommentRoom();
      document
        ?.getElementById("main_layout_content")
        ?.addEventListener("scroll", setCommentPosition);
      window.addEventListener("resize", setCommentPosition);
      document.addEventListener("transitionend", setCommentPosition);
      document.addEventListener("click", clickOutsideHandler, true);
    };
    /** mounted*/
    onMounted(async () => {
      await initialize();
      console.log(route, "route");
      if (route.name === "projectDetailsPostSuccess") {
        onShowSuccessPostProjectFloater();
      }
      // const routeData = router.resolve({
      //   name: "project-details",
      //   params: { refId: ProjectData.value.refId },
      // });
      // window.open(window.location.href, "_blank");
    });

    onBeforeUnmount(() => {
      unbindSubscribedCommentsRoomsChannels();
      document
        ?.getElementById("main_layout_content")
        ?.removeEventListener("scroll", setCommentPosition);
      window.removeEventListener("resize", setCommentPosition);
      document.removeEventListener("transitionend", setCommentPosition);
      document.removeEventListener("click", clickOutsideHandler, true);
      store.dispatch(`${CHATMESSAGE}/setCurrentMessageType`, "chat");
      clearestimatedata();
      store.commit(`${PROJECT_STORE}/setProjectDispute`, null);

      if (disputeProject.value) clearInterval(realTimeSetInterval);
    });
    const getProjectEstimateDataWithEscrow = computed(
      () => store.getters[`${PROJECT_STORE}/getProjectEstimateDataWithEscrow`]
    );
    const filterEstimatePhases = computed(() => {
      return getProjectEstimateDataWithEscrow.value?.estimationPhase;
    });
    const projectOverviewStatusList = ref([
      {
        id: 1,
        label: "Project Live On iknowa ",
        description:
          "Your project is now visible on iknowa. Contractors can begin to view and bid on your project.",
        isShowDefault: true,
        statusCode: 1,
        iconName: "project_live",
        completed: false,
      },
      {
        id: 2,
        label: "Estimates Received",
        description:
          "You have received estimates from contractors. Review the bids and select your preferred contractors.",
        isShowDefault: true,
        statusCode: 2,
        iconName: "estimate_received",
        completed: false,
      },
      {
        id: 3,
        label: "Estimate Accepted",
        description:
          "You’ve accepted an estimate and approved the trade contractor for your project. Work will begin once their final availability is confirmed.",
        isShowDefault: true,
        statusCode: 3,
        iconName: "contractor_approved",
        completed: false,
      },
      {
        id: 4,
        label: "Final Confirmation Pending",
        description:
          "Your accepted estimate has been sent to the trade contractor for final confirmation. This  ensures they’re still available to proceed with your project.",
        isShowDefault: true,
        statusCode: 3,
        iconName: "final_confirm_pending",
        completed: false,
      },
      // {
      //   id: 3,
      //   label: "Contractors Approved",
      //   description:
      //     "You have approved the contractors for your project. They will start work as per the agreed schedule.",
      //   isShowDefault: true,
      //   statusCode: 3,
      //   iconName: "contractor_approved",
      //   completed: false,
      // },
      {
        id: 5,
        label: "Secured Escrow Created",
        description:
          "A secured escrow account has been created for your project. Funds will be added and released through the account as work progresses.",
        isShowDefault: false,
        statusCode: 4,
        iconName: "escrow_account",
        completed: false,
      },
      {
        id: 6,
        label: "Project Underway",
        description:
          "The project is in progress. Work is being carried out according to the agreed schedule and scope.",
        isShowDefault: false,
        statusCode: 5,
        iconName: "project_underway",
        completed: false,
      },
      {
        id: 7,
        label: "Project Completion",
        description:
          "Your project has been successfully completed! All work has been approved, and project funds have been released. The project is now saved under your property records.",
        isShowDefault: false,
        statusCode: 6,
        iconName: "completion",
        completed: false,
      },
    ]);

    const projectOverviewStatus = computed(() => ({
      default: projectOverviewStatusList.value.filter(
        (status) => status.isShowDefault
      ),
      expanded: projectOverviewStatusList.value.filter(
        (status) => !status.isShowDefault
      ),
    }));

    const estimateData = computed(
      () => store.getters[`${PROJECT_STORE}/getProjectEstimateDataWithEscrow`]
    );

    const estimateDetails = computed(() => estimateData.value?.estimationPhase);
    const totalPhaseTasks = computed(
      () =>
        estimateDetails.value.filter(
          (phase: any) => phase.phaseType === EstimatePhaseType.LABOUR
        ).length
    );
    const selectedPhases = computed(() => {
      const phases = estimateDetails.value?.filter((phase: any) =>
        [EscrowStatusEnum.NOT_STARTED_POKED].includes(phase?.escrowStatus?.id)
      );

      return phases;
    });

    const filterPhases = computed(() => {
      return selectedPhases.value?.map((phase: any) => {
        return {
          ...phase,
          totalAmount: totalPhaseAmount(
            phase.phaseType,
            phase.subTotal,
            phase.vat,
            phase.isOld
          ),
        };
      });
    });
    const projectDetails = computed(
      () => store.getters[`${PROJECT_STORE}/projectDetails`]
    );

    const totalPhaseAmount = (
      phaseType: EstimatePhaseType,
      amount: number,
      vat: number,
      isFirstPhase: boolean
    ) => {
      let sum = 0;
      let vatSum = amount * vat;
      let phaseCost = amount + vatSum;
      let serviceCost = phaseCost * SERVICE_CHARGE;
      sum += serviceCost + phaseCost;

      if (isFirstPhase) {
        sum += PHASE_FIRST_CHARGE;
      }

      // grantDiscount

      if (
        phaseType === EstimatePhaseType.LABOUR &&
        totalPhaseTasks &&
        estimateData.value?.grantAmount
      ) {
        const grantAmount: number = parseFloat(estimateData.value?.grantAmount);
        const totalTasks: number = parseFloat(totalPhaseTasks.value);
        const grantDiscountTotal = grantAmount / totalTasks;
        const finalAmount = sum - grantDiscountTotal;
        return Math.floor(finalAmount * 100) / 100;
      }

      return sum;
    };

    const onClickAddMoreSpecialism = () => {
      isShowSpecialismModal.value = true;
    };

    const onClickCloseTab = () => {
      isShowSpecialismModal.value = false;
      setProjectData();
    };

    const onClickDeleteCategoryButton = (subCategoryId: number) => {
      ProjectData.value.subCategories = ProjectData.value.subCategories.filter(
        (subCategory: any) => {
          return subCategory.id !== subCategoryId;
        }
      );
    };

    const onClickOpenNewTab = (attachment: any) => {
      try {
        window.open(attachment);
      } catch (e) {
        console.log(e);
      }
    };

    const clearImageUrl = () => {
      imageUrl.value = "";
    };

    const estimatePhasesMessage = computed(() => {
      if (!filterPhases.value?.length) {
        return null;
      }

      const phaseDetails = filterPhases.value
        ?.map((phase: any) => {
          const updatedSubTotal =
            parseFloat(phase?.updatedSubTotal) +
            parseFloat(phase?.updatedSubTotal) * SERVICE_CHARGE;
          return `${
            phase?.phaseType === ProjectPhaseType.LABOUR
              ? phase.name
              : MATERIALS
          } ( ${
            parseFloat(phase?.updatedSubTotal) > 0
              ? currencyFilter.formatToCurrency(updatedSubTotal.toString())
              : currencyFilter.formatToCurrency(phase.totalAmount.toFixed(2))
          })`;
        })
        .join(" & ");
      const totalAmount = filterPhases.value
        .reduce((sum: any, phase: any) => {
          const amount =
            parseFloat(phase?.updatedSubTotal) > 0
              ? parseFloat(phase?.updatedSubTotal) +
                parseFloat(phase?.updatedSubTotal) * SERVICE_CHARGE
              : phase.totalAmount;
          return sum + amount;
        }, 0)
        .toFixed(2);

      return `
    <p><strong>Required Deposit (${currencyFilter.formatToCurrency(
      totalAmount
    )}) </strong></p>
    <p>
      Deposits required for
      <strong>${phaseDetails}</strong> to
      kick-off the project.
    </p>
  `;
    });

    const getProjectRedirectTabName = computed(
      () => store.getters[`${PROJECT_STORE}/getProjectRedirectTabName`]
    );
    const isShowCommentSection = computed(() => {
      return (
        ["jobDetails", "projectDetails"].includes(props.type) &&
        getProjectRedirectTabName.value === "project-overview" &&
        ![
          ProjectJobStatusEnum.CANCELLED,
          ProjectJobStatusEnum.DRAFT_PROJECT,
        ].includes(projectDetails.value?.projectJobStatus?.id)
      );
    });
    const specialismCategoryClass = (val: string) => {
      if (val.toLowerCase() === "krystal") {
        return "krystal";
      }
      if (val.toLowerCase() === "property") {
        return "property";
      }
      if (val.toLowerCase() === "trade") {
        return "trade";
      }
    };

    const redirectVideoConsultation = async () => {
      await store.dispatch(
        `${PROJECT_STORE}/setProjectRedirectTabName`,
        "video consultation"
      );
    };

    const isShowVideoButton = (projectId: any) => {
      return [ProjectJobStatusEnum.IN_PROGRESS].includes(
        projectDetails.value?.projectJobStatus?.id
      );
    };
    const projectRatingData = ref();
    const isShowReviewModal = ref(false);

    const onOpenViewEvidenceModal = () => {
      isShowViewEvidenceModal.value = true;
    };

    const onCloseViewEvidenceModal = () => {
      isShowViewEvidenceModal.value = false;
    };

    const onCloseSuccessSendReviewModal = () => {
      isShowSuccessSendReviewModal.value = false;
    };

    const toggleRatingModal = (ratingData: any) => {
      isShowReviewModal.value = !isShowReviewModal.value;
      if (ratingData) {
        projectRatingData.value = ratingData;
        // open success modal
        isShowSuccessSendReviewModal.value = true;
      }
    };
    const checkIsUserRatedProject = async () => {
      try {
        projectRatingData.value = await projectRatingService.getProjectRating(
          projectDetails.value.id
        );
        if (!projectRatingData.value) {
          toggleRatingModal(null);
        }
      } catch (error) {
        console.log("get project rating", error);
      }
    };
    const isShowProjectRatingButton = computed(
      () =>
        projectRatingData.value &&
        !parseJSON(projectRatingData.value.ratingStatusList)?.length &&
        projectStatus.value == ProjectJobStatusEnum.COMPLETED
    );

    const onClickSiteVisit = async () => {
      const name = ProjectData.value.name?.trim();
      const projectProperties = ProjectData.value.projectProperties?.length;
      let displayError = "";
      if (!name && !projectProperties) {
        displayError =
          "Project name and at least one associated property are required.";
      } else if (!name) {
        displayError = "Project name is required.";
      } else if (!projectProperties) {
        displayError = "At least one associated property is required.";
      } else {
        isShowSiteVisitModal.value = !isShowSiteVisitModal.value;
        return;
      }
      if (displayError) {
        displayToast(displayError, "error");
        return;
      }
    };

    const getPropertyCategory = computed(() => ({
      isResidential: ProjectData.value?.projectProperties?.length
        ? ProjectData.value?.projectProperties.some(
            (property: any) =>
              property?.property?.propertyCategory ===
              PropertyCategoryEnum.RESIDENTIAL
          )
        : false,
      isCommercial: ProjectData.value?.projectProperties?.length
        ? ProjectData.value?.projectProperties.some(
            (property: any) =>
              property?.property?.propertyCategory ===
              PropertyCategoryEnum.COMMERCIAL
          )
        : false,
    }));
    const disputeProject = computed(() => {
      return store.getters[`${PROJECT_STORE}/getDisputeProject`];
    });

    const extensionRequested = computed(() => {
      const isRequested = disputeProject.value?.extension_request?.some(
        (request: any) => request?.status === "pending"
      );

      return isRequested ? true : false;
    });
    const hasExtensionRequest = computed(() => {
      return disputeProject.value?.extension_request?.length;
    });
    const allMessagesRooms = computed(
      () => store.getters[`${CHATMESSAGE}/getChatMessagesRooms`]
    );
    const overlayStyle = computed(() => ({
      position: "absolute",
      left: "0",
      top: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      zIndex: "1",
    }));
    const isShowOverlay = computed(() => {
      if (!disputeProject.value) {
        return false;
      }
      if (disputeProject.value?.estimatePhases?.length) {
        return false;
      }
      return true;
    });

    const onViewEvidence = () => {
      onOpenViewEvidenceModal();
      console.log(disputeProject.value, "disputeProject");
    };

    const isOpenConfirmDeleteProjectModal = ref(false);
    const onClickDeleteDraftProject = () => {
      isOpenConfirmDeleteProjectModal.value = true;
    };

    const onCloseConfirmDeleteProjectModal = () => {
      isOpenConfirmDeleteProjectModal.value = false;
    };

    const clearestimatedata = () => {
      store.commit(`${PROJECT_STORE}/resetestimatedata`);
    };
    const isShowPropertyMapView = ref(false);
    const togglePropertyMapView = () => {
      isShowPropertyMapView.value = !isShowPropertyMapView.value;
    };

    return {
      handleSelectedReasons,
      onClickAddMoreSpecialism,
      estimateDetails,
      isShowSpecialismModal,
      projectOverviewStatus,
      selectedPhases,
      estimatePhasesMessage,
      projectDetails,
      isShowLoaderOnSaveButtonForDraft,
      isShowGroupCard,
      isCarouselDialog,
      currentIndex,
      imageArray,
      openCarousel,
      isShowDashboard,
      openMessageDashboard,
      ProjectData,
      filterAttachments,
      formatDate,
      projectDataProperties,
      propertyId,
      changePropertyId,
      loading,
      onClickSaveDraftProject,
      ProjectStatusType,
      onClickEditProject,
      addresses,
      isPostLoadingModal,
      currentSelectedTab,
      switchingText,
      onClickPostLiveProject,
      tabListItems,
      switchingImage,
      addComments,
      activeAddComment,
      canvas,
      onComment,
      projectComments,
      activeComment,
      toggleActiveComment,
      updateCommentRoomStatus,
      nextComment,
      inviteMemberList,
      updateProjectNameNote,
      isEditProjectNoteSection,
      projectTitle,
      state,
      onCancelProjectNotes,
      onClickSaveProjectNotes,
      isEditPropertiesSection,
      updatePropertiesSection,
      onClickCancelButton,
      onClickRemoveProperty,
      isShowPropertyListModal,
      onClickTogglePropertyListModal,
      isFetchingPropertiesLoading,
      propertiesList,
      isSelected,
      selectedIndices,
      toggleSelection,
      getPropertyType,
      updateAttachmentsSection,
      panel,
      isEditAttachmentsSection,
      isLoadingRemoveAttachments,
      onClickSaveButtonAttachments,
      onCancelAttachments,
      onClickRemoveAttachment,
      loadingSelectProperty,
      isShowAttachmentModal,
      onClickToggleAttachmentModal,
      imageUrl,
      onFileChange,
      errorMessage,
      uploadedImages,
      addPropertyImage,
      attachmentDataList,
      isAttachmentDataValid,
      removeImageFromAttachments,
      userSelectedProperties,
      userCategory,
      onClickPropertyModalSave,
      onClickSaveButton,
      isPaginationAvailable,
      onLoadMore,
      isLoadingUpdatePropriety,
      showProjectOverviewDialog,
      isLoadingProjectNotes,
      isLoadingProjectData,
      projectAttachments,
      getFilterCategory,
      getFilterImages,
      attachmentsUpdateList,
      carouselImageList,
      isLoadingUploadAttachments,
      onClickSaveButtonAttachmentsModal,
      removeAttachmentsIds,
      isAlreadyCommented,
      getCommentedElementIds,
      clickOutside,
      isEditSpecialismSection,
      updateSpecialismSection,
      onClickRemoveSpecialism,
      onClickCancelSpecialismButton,
      onClickSaveSpecialismButton,
      redirectToEscrowAccount,
      isShowLoaderOnSaveButton,
      onCloseModal,
      iShowInviteTeamMemberModal,
      onClickInviteTeamButton,
      onClickBackToSearchResults,
      onClickCloseTab,
      onClickDeleteCategoryButton,
      onClickOpenNewTab,
      ProjectJobStatusEnum,
      ProjectTypesEnum,
      isShowCommentSection,
      isLoadingOnCancel,
      clearImageUrl,
      isLoadingOnAttachmentCancel,
      selectedFile,
      userImagesMap,
      onClickSaveButtonClick,
      isLoadingForSaveSpecialism,
      specialismCategoryClass,
      redirectVideoConsultation,
      isShowVideoButton,
      isShowProjectRatingButton,
      toggleRatingModal,
      isShowReviewModal,
      ProjectRatingTypeEnum,
      projectRatingData,
      onClickContinuePostProject,
      ProjectFrom,
      isShowSiteVisitModal,
      onClickSiteVisit,
      getPropertyCategory,
      PropertyCategoryEnum,
      getNoCategoryAttachmentList,
      propertyEditModalAlert,
      onClickDeleteDraftProject,
      onCloseConfirmDeleteProjectModal,
      isOpenConfirmDeleteProjectModal,
      propertyRequiredError,
      isShowSuccessSendReviewModal,
      onCloseSuccessSendReviewModal,
      onClickRedirectToRelevantTab,
      projectStatus,
      isShowPropertyMapView,
      togglePropertyMapView,
      isOpenDspEvidenceModal,
      isOpenDSPInformationModal,
      isOpenDspOverviewModal,
      isOpenDisputeReasonModal,
      isOpenDspProjectModal,
      toggleDspEvidenceModal,
      toggleDSPInformationModal,
      onCreateDisputeProject,
      toggleDspOverviewModal,
      toggleDisputeReasonModal,
      disputeProject,
      estimateData,
      disputeProjectData,
      submitDisputeProject,
      toggleDspProjectDetail,
      continueDisputeProjectDetails,
      disputeProjectErpDueDate,
      onOpenChatDispute,
      onCloseDispute,
      isRequestedChat,
      allMessagesRooms,
      isOpenDspEvidenceGuidanceModal,
      onCloseDSPEvidenceGuidanceModal,
      onOpenDSPEvidenceGuidanceModal,
      isOpenRequestExtensionModal,
      onOpenDSPRequestExtensionModal,
      onCloseDSPRequestExtensionModal,
      onViewEvidence,
      isShowViewEvidenceModal,
      onCloseViewEvidenceModal,
      onOpenViewEvidenceModal,
      hasExtensionRequest,
      extensionRequested,
      isShowOverlay,
      overlayStyle,
      DisputeTypeEnums,
      projectPostProgressLoading,
      isShowProjectPostSuccessFloater,
      tradeServicesPanel,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/project/styles/projectReview.scss";

.collapsible-component {
  :deep(.v-expansion-panel-title) {
    min-height: 62px;
    font-weight: 700;
  }
  :deep(.v-expansion-panel__shadow) {
    // box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
    box-shadow: none;
  }
  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
  }
}

:deep(.v-select__selection-text) {
  font-size: 12px;
}

.success-floater {
  transition: top 0.3s ease-in-out;
  box-shadow: 0px 3px 15.4px 0px #00000029;
  width: 448px !important;
  top: -41px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  z-index: 99;

  @media screen and (max-width: 768px) {
    width: 373px !important;
  }
}

.success-floater.show {
  top: 3.5rem !important;
}
:deep(.v-select__selection-text) {
  font-size: 12px;
}

:deep(.onboard-stepper) {
  box-shadow: none !important;
  overflow: unset !important;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  .v-stepper-header {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    gap: 0.5rem;
    overflow: unset;
    padding-top: 16px;
    // margin-top: 30px;
  }
  .v-stepper-item {
    padding: 0;
    flex: 1 0 0%;
    flex-direction: column;
    align-items: center;

    .v-avatar {
      background-color: rgba(#ffa500, 0.5);
      @include fluidFont(0, 0, 1);
      width: 100% !important;
      border-radius: 10px;
      height: 0.375rem !important;
      margin: 0;
    }

    &--selected {
      .v-avatar {
        background-color: #ffa500 !important;
      }
    }
  }
}
:deep(.prop_img_details) {
  :deep(.v-btn) {
    :deep(.v-btn__overlay),
    :deep(.v-btn__underlay) {
      display: none;
    }
  }
}
.save-btn {
  height: auto;
  @include fluidFont(14, 14, 1.3);
  font-weight: 500;
  letter-spacing: 0.15px;
  color: rgba(74, 146, 229, 1);
  padding: 0;
  opacity: 1;
}
.delete-btn {
  height: auto;
  @include fluidFont(14, 14, 1.3);
  font-weight: 500;
  letter-spacing: 0.15px;
  color: rgba(255, 54, 54, 1);
  padding: 0;
  opacity: 1;
}
.attachment-list {
  width: calc(100% - 400px);
  max-width: 100%;
  height: 470px;
  // padding: 0 !important;
  @include respond(md) {
    width: 100%;
  }
}
.pointer-cursor {
  cursor: pointer;
}
.properties_wrapper__info__left {
  .prop_name {
    display: flex;
    gap: 8px;
    align-items: start !important;
    p {
      width: 16px;
      height: 16px;
      background-color: rgba($blueDark, 1);
      color: white;
      font-size: 10px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h6 {
      font-size: 12px;
      font-weight: 400;
      color: rgba($blueDark, 1);
      width: calc(100% - 20px) !important;
      max-width: 100% !important;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
  .property__type__wrapper {
    padding-top: 10px;
  }
}
.properties_wrapper__info {
  border: 1px solid rgba($bordercolor, 1);
  background-color: rgba($white, 1) !important;
}
.properties_wrapper,
.is-empty {
  background-color: rgba($backgroundcolor, 1) !important;
  border: 1px solid rgba($bordercolor, 1) !important;
}
.properties_wrapper__content {
  display: unset !important;
}

.properties_wrapper__left {
  width: 100% !important;
  max-width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row !important;
  max-height: 370px !important;
  margin-top: 26px !important;
  .properties_wrapper__info {
    width: calc(33.33% - 11px) !important;
    max-width: 100% !important;
    @include respond(s720) {
      width: calc(50% - 8px) !important;
    }
    @include respond(sm) {
      width: 100% !important;
    }
  }
}
// .properties_wrapper__content {
//   padding-top: 14px !important;
// }
.map__button {
  padding: 8px;
  background-color: rgba($white, 1);
  border-radius: 8px;
  border: 1px solid rgba($bordercolor, 1);
  box-shadow: none;
  :deep(.v-btn__content) {
    @include fluidFont(14, 14, 18.65px);
    font-weight: 400;
    color: rgba($blueDark, 1);
    text-transform: capitalize;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  @include respond(sm) {
    order: 3;
    width: 100%;
    max-width: 100%;
  }
}
.main_project_preview .right_block .properties_wrapper .header .title {
  width: calc(100% - 250px);
  max-width: 100%;
  @include respond(sm) {
    order: 1;
    width: calc(100% - 190px);
  }
}
.main_project_preview .right_block .properties_wrapper .header {
  flex-wrap: wrap;
  gap: 10px;
}
.custom_edit {
  @include respond(sm) {
    order: 2;
  }
}
.info-message {
  padding: 10px;
  border: 1px solid rgba($orange, 1);
  border-radius: 6px;
  background-color: rgba($orange, 0.06);
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  p {
    @include fluidFont(16, 16, 20.08px);
    font-weight: 600;
    color: rgba($blueDark, 1);
    text-align: left;
  }
}

// .main_project_details:has(.dispute_project) .overlay__wrapper {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba($white, 0.5);
//   z-index: 1;
// }
</style>
