<template>
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83333 7.83594C4.05333 7.83594 0.5 8.72927 0.5 10.5026V11.1693C0.5 11.5359 0.8 11.8359 1.16667 11.8359H10.5C10.8667 11.8359 11.1667 11.5359 11.1667 11.1693V10.5026C11.1667 8.72927 7.61333 7.83594 5.83333 7.83594Z"
      fill="#3366FF"
    />
    <path
      d="M2.99073 3.83333H8.6774C8.8574 3.83333 9.00406 3.68667 9.00406 3.50667V3.49333C9.00406 3.31333 8.8574 3.16667 8.6774 3.16667H8.4974C8.4974 2.18 7.9574 1.33333 7.16406 0.866667V1.5C7.16406 1.68667 7.0174 1.83333 6.83073 1.83333C6.64406 1.83333 6.4974 1.68667 6.4974 1.5V0.593333C6.28406 0.54 6.06406 0.5 5.83073 0.5C5.5974 0.5 5.3774 0.54 5.16406 0.593333V1.5C5.16406 1.68667 5.0174 1.83333 4.83073 1.83333C4.64406 1.83333 4.4974 1.68667 4.4974 1.5V0.866667C3.70406 1.33333 3.16406 2.18 3.16406 3.16667H2.99073C2.81073 3.16667 2.66406 3.31333 2.66406 3.49333V3.51333C2.66406 3.68667 2.81073 3.83333 2.99073 3.83333Z"
      fill="#3366FF"
    />
    <path
      d="M5.83115 6.50078C7.07115 6.50078 8.10448 5.64712 8.40448 4.5H3.25781C3.55781 5.64712 4.59115 6.50078 5.83115 6.50078Z"
      fill="#3366FF"
    />
    <path
      d="M12.7222 1.83333V0.944444C12.7222 0.7 12.5222 0.5 12.2778 0.5C12.0333 0.5 11.8333 0.7 11.8333 0.944444V1.83333H10.9444C10.7 1.83333 10.5 2.03333 10.5 2.27778C10.5 2.52222 10.7 2.72222 10.9444 2.72222H11.8333V3.61111C11.8333 3.85555 12.0333 4.05556 12.2778 4.05556C12.5222 4.05556 12.7222 3.85555 12.7222 3.61111V2.72222H13.6111C13.8556 2.72222 14.0556 2.52222 14.0556 2.27778C14.0556 2.03333 13.8556 1.83333 13.6111 1.83333H12.7222Z"
      fill="#3366FF"
    />
  </svg>
</template>
<script>
export default {
  name: "TradeAvatarYellow",
};
</script>
