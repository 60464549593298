<template>
  <GeneralDialog
    class="dispute-evidence-modal !tw-gap-0"
    @on-close="onCloseModal"
    maxWidth="100%"
    width="588px"
    borderRadius="20px"
    :isShowCloseButton="true"
    :isShowHeader="true"
    :isTitleStrong="true"
    headerTitle="Manage Member"
    headerDescription="Please upload the certificates to meet the entry requirements."
    headerDescriptionTextSize="h6"
    headerDescriptionLineHeight="20px"
    gap="0"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <v-form ref="formRef" @submit.prevent :rules="validation">
            <div class="modal-body">
              <div class="modal-statement modal-content">
                <label class="tw-pb-2">Candidate </label>
                <InputField
                  class="!tw-w-full custom-selector"
                  v-model="candidateName"
                  :rules="validation.candidate"
                  placeholder="Candidate name here"
                ></InputField>
              </div>
              <div class="modal-statement modal-content">
                <label class="tw-pb-2">Assign Certificates </label>

                <v-select
                  :items="getCertificateList"
                  item-title="certificate.name"
                  item-value="id"
                  v-model="selectedCertificateId"
                  class="custom_selector"
                  density="compact"
                  placeholder="e.g Plumbing & Heating"
                  :center-affix="true"
                  :single-line="true"
                  :menu-props="{ contentClass: 'text__selector' }"
                  variant="outlined"
                  multiple
                >
                  <template v-slot:item="{ props, item }">
                    <div v-bind="props">
                      <v-checkbox
                        color="#4B4BFF"
                        :id="item.id"
                        :label="item.raw?.certificate?.name"
                        v-model="item.raw.isSelected"
                      ></v-checkbox>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="modal-footer">
              <v-btn
                class="button button-purple-border"
                @click="onCloseModal"
                :disabled="isLoading"
              >
                Cancel
              </v-btn>
              <v-btn
                class="button button-purple"
                @click="onSubmit"
                :loading="isLoading"
              >
                Submit
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed, reactive } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
const store = useStore();
import InputField from "@/core/components/common/InputField.vue";
import { MEMBER_STORE } from "@/store/modules/manage-member";

const emits = defineEmits(["on-close", "on-submit"]);
const props = defineProps({
  eligibility: {
    type: Object,
    default: null,
  },
  candidate: {
    type: Object,
    default: null,
  },
});

const getCertificateList = ref([]);

const internalInstance = getCurrentInstance();
const formRef = ref<any>(null);
const isLoading = ref<boolean>(false);
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const validation = ref({
  candidate: [(v: string) => !!v || "Candidate name required"],
  attachments: [
    (v: any[]) => v.length > 0 || "At least one attachment is required",
  ],
});

const candidateName = ref<string>("");

const selectedCertificateId = ref(null);

const onSubmit = async () => {
  const isFormValid = await formRef.value.validate();
  if (!isFormValid.valid) return;

  try {
    isLoading.value = true;
    const removeCertificate = getCertificateList.value.filter(
      (certificate: any) =>
        assingCertificateIds.value?.includes(certificate.id) &&
        !certificate.isSelected
    );
    const newAssignCertificates = getCertificateList.value.filter(
      (certificate: any) =>
        !assingCertificateIds.value?.includes(certificate.id) &&
        certificate.isSelected
    );
    if (newAssignCertificates?.length)
      for (const certificate of newAssignCertificates) {
        await store.dispatch(`${USER_STORE}/addCertificateRelatedMembers`, {
          userId: user?.value?.id,
          certificateRecordId: certificate.id,
          teamMemberIds: {
            teamMemberIds: [props.candidate?.toUserId],
          },
        });
      }
    if (removeCertificate?.length)
      for (const certificate of removeCertificate) {
        await store.dispatch(`${USER_STORE}/removeCertificateRelatedMembers`, {
          userId: user?.value?.id,
          certificateRecordId: certificate.id,
          teamMemberIds: {
            teamMemberIds: [props.candidate?.toUserId],
          },
        });
      }

    if (
      props.candidate.userName ||
      props.candidate?.email?.split("@")[0] !== candidateName.value
    ) {
      const membersWithTagsStringified = {
        userName: candidateName.value,
      };

      const response = await store.dispatch(
        `${MEMBER_STORE}/updateMemberDetails`,
        {
          members: membersWithTagsStringified,
          userId: user.value?.id,
          userWorkstationMemberId: props.candidate.id,
        }
      );
    }
  } catch (error: any) {
    displayToastMessage(internalInstance, error.message, "error");
  } finally {
    isLoading.value = false;
    emits("on-submit");
  }
};

const onCloseModal = () => {
  return emits("on-close");
};
const assingCertificateIds = computed(() =>
  props.candidate?.certificateEligible?.userCertificate
    .filter((certificate: any) => isCheck(certificate))
    .map((certificate: any) => certificate.id)
);

const isCheck = (data: any) => {
  return data?.assignedUserIds?.length
    ? data?.assignedUserIds?.includes(props.candidate?.toUserId)
    : false;
};

onMounted(() => {
  if (props.candidate) {
    candidateName.value =
      props.candidate.userName || props.candidate?.email?.split("@")[0];
  }
  if (props.candidate?.certificateEligible?.userCertificate?.length) {
    getCertificateList.value =
      props.candidate?.certificateEligible?.userCertificate.map(
        (certificate: any) =>
          reactive({
            ...certificate,
            isSelected: isCheck(certificate),
          })
      );
    selectedCertificateId.value = assingCertificateIds.value;
  }
});
</script>

<style lang="scss" scoped>
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}

.custom-file-upload {
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: #f8fbfd;
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}

.upload_file {
  :deep(.v-label) {
    margin-top: 0 !important;
  }
}

.upload_file {
  :deep(.v-field__input) {
    display: none;
  }
  :deep(.v-field__clearable) {
    display: none;
  }
}
</style>
