<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="832px"
    max-width="100%"
    persistent
    :className="['phaseproof__upload__modal']"
  >
    <template v-slot:body>
      <v-icon
        icon="mdi-close"
        class="close__icon"
        @click="onClickToggleAttachmentModal"
      ></v-icon>
      <div class="modal__content">
        <div class="left_upload_img tw-mb-8">
          <h4>Upload images and files.</h4>
          <div class="upload__area">
            <div class="uploade-wrapper">
              <v-file-input
                class="upload_file"
                label="Upload"
                v-model="imageValue"
                :clearable="imageUrl ? true : false"
                append-inner-icon="mdi-image-plus"
                variant="solo-filled"
                :class="{ 'added-img': imageUrl }"
                @change="onFileChange"
                @click:clear="clearImageUrl"
                multiple
              >
                <!-- append-icon="mdi-upload" -->
                <!-- <v-icon icon="mdi mdi-file-image-box"></v-icon> -->
              </v-file-input>
              <v-img
                class="uploaded-img"
                :width="200"
                aspect-ratio="16/9"
                cover
                :src="imageUrl"
              ></v-img>
            </div>
            <div class="upload__text">
              <p>
                <strong>Drag and drop documents, or</strong>
                <span>Browse</span>
              </p>
              <p class="">
                Attachments such as images, design ideas and documents help
                better understand the project and provide increasingly accurate
                estimates.
              </p>
            </div>
          </div>
          <!-- <div class="selected-info">
            <v-btn
              aria-label="Refresh"
              class="button button-orange"
              @click="addPropertyImage"
              :disabled="!uploadedImages"
              >{{ attachmentDataList.length ? "ADD ANOTHER" : "add" }}</v-btn
            >
          </div> -->
          <p class="error_msg tw-text-red-500" v-if="errorMessage">
            {{ errorMessage }}
          </p>
        </div>

        <div class="image__upload__wrapper">
          <div v-if="attachmentDataList.length">
            <h5>Uploaded Files</h5>
            <div class="uploaded_details_wrapper">
              <v-form :rules="validation" ref="attachmentFormRef">
                <div
                  class="show_uploaded_details"
                  v-for="(attachment, index) in attachmentDataList"
                  :key="index"
                >
                  <v-img
                    class="uploaded-img"
                    :width="156"
                    :height="88"
                    aspect-ratio="16/9"
                    cover
                    :src="attachment?.uploadedImages?.url"
                  ></v-img>
                  <div class="prop_img_details">
                    <h5>{{ attachment?.uploadedImages?.name }}</h5>
                    <v-btn
                      @click="
                        removeImageFromAttachments(
                          index,
                          attachment?.uploadedImages?.attachmentId
                        )
                      "
                      variant="text"
                      class="button button-lightGray"
                    >
                      <img src="@/assets/icons/delete.svg" alt="" />
                    </v-btn>
                  </div>

                  <div class="upload__list">
                    <v-select
                      v-model="attachment.propertyId"
                      label="Select property"
                      :items="projectProperties"
                      :rules="validation.property"
                      item-title="address"
                      item-value="id"
                      multiple
                      variant="outlined"
                      density="compact"
                      class="selectprop_dropdown"
                      :menu-props="{ contentClass: 'dropdown__selector' }"
                    >
                    </v-select>
                    <v-select
                      v-model="attachment.taskId"
                      label="Select Task"
                      :items="phaseDetails.estimationPhaseTasks"
                      item-title="name"
                      item-value="id"
                      variant="outlined"
                      density="compact"
                      class="selectprop_dropdown"
                      :menu-props="{ contentClass: 'dropdown__selector' }"
                    >
                    </v-select>
                  </div>
                </div>
              </v-form>
            </div>
          </div>
          <div v-else class="blank_project">
            <p>Your added project attachments will show up here</p>

            <CommonLoader :loading="isLoading" class="loader-container" />
          </div>
        </div>
      </div>

      <div class="footer-buttons">
        <v-btn
          class="button button-purple-border"
          @click="onClickToggleAttachmentModal"
          variant="outlined"
          :disabled="isLoadingUploadAttachments"
        >
          cancel
        </v-btn>
        <v-btn
          class="button button-orange"
          :loading="isLoadingUploadAttachments"
          :disabled="isLoading"
          @click="onClickSaveButtonAttachmentsModal"
        >
          save
        </v-btn>
      </div>
    </template>
  </CommonDialog>
</template>
<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { onMounted, ref } from "vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { useStore } from "vuex";
import { computed } from "vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { USER_STORE } from "@/store/modules/user";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
// import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
export default {
  components: {
    CommonDialog,
    CommonLoader,
    // GeneralDialog
  },
  props: {
    phaseDetails: { type: Object, default: null },
  },

  setup(props: any, ctx: any) {
    const store = useStore();
    const isNewPropertyAdded = ref(false);
    const imageUrl = ref("");
    const imageValue = ref();
    const isClearImage = ref("true");
    const attachmentFormRef = ref(null) as any;
    const validation = ref({
      property: [
        (v: string | Array<number>) => {
          return !!v || "please selected property";
        },
        (v: string | Array<number>) => {
          return !!v.length || "please selected property";
        },
      ],
      // task: [(v: string) => !!v || "please selected task"],
    });

    const errorMessage = ref("");
    const uploadedImages = ref(null) as any;
    const attachmentDataList = ref([]) as any;
    const cloneAttachmentDataList = ref([]) as any;
    const isLoadingUploadAttachments = ref(false);
    const isLoading = ref(false);

    const projectDetails = computed(
      () => store.getters[`${JOBS_STORE}/getJobDetails`]
    );
    const projectProperties = computed(() =>
      projectDetails.value?.projectProperties.map((data: any) => data.property)
    );
    const onClickToggleAttachmentModal = () => {
      if (isLoadingUploadAttachments.value) return;
      ctx.emit("onClose");
      isNewPropertyAdded.value = false;
    };
    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      errorMessage.value = "";
      let isValidFile = false;
      const fileTypeMap: { [key: string]: string } = {
        "image/jpeg": "Image",
        "image/png": "Image",
        "image/gif": "Image",
        "image/tiff": "TIFF Image",
        "image/jfif": "JIFF Image",
        "video/mp4": "Video",
        "application/pdf": "PDF",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          "Excel File",
        "application/vnd.ms-excel": "Excel File",
      };

      if (fileInput.files && fileInput.files.length > 0) {
        Array.from(fileInput.files).forEach((file: any) => {
          if (
            file.type.includes("image") &&
            !file.name.toLowerCase().endsWith(".jfif")
          ) {
            if (file.size <= 200 * 1024 * 1024) {
              // Image size limit: 200MB
              imageUrl.value = URL.createObjectURL(file);
              uploadedImages.value = {
                url: imageUrl.value,
                name: file.name,
                imageData: file,
              };
              isValidFile = true;
            } else {
              errorMessage.value = "Image size exceeds the limit (200MB).";
            }
          } else if (file.name.toLowerCase().endsWith(".jfif")) {
            errorMessage.value = "Jfif media type is not supported";
          } else if (file.type.includes("video")) {
            if (file.size <= 20 * 1024 * 1024) {
              uploadedImages.value = {
                type: "video",
                name: file.name,
                imageData: file,
              };
              isValidFile = true;
            } else {
              errorMessage.value = "Video size exceeds the limit (20MB).";
            }
          } else if (file.type === "application/pdf") {
            if (file.size <= 10 * 1024 * 1024) {
              uploadedImages.value = {
                type: "pdf",
                name: file.name,
                imageData: file,
              };
              isValidFile = true;
            } else {
              errorMessage.value = "PDF size exceeds the limit (10MB).";
            }
          } else {
            const fileType = fileTypeMap[file.type] || file.type.split("/")[1];
            errorMessage.value = `Unsupported ${fileType} type. Please upload an image, video, or PDF.`;
          }
          return;
        });
      } else {
        console.error("Error: No file selected.");
      }
      if (isValidFile) {
        addPropertyImage();
      }
    };

    const removeImageFromAttachments = (index: number, attachmentId: any) => {
      attachmentDataList.value.splice(index, 1);
      cloneAttachmentDataList.value[index].isDeleted = true;
    };
    const addPropertyImage = () => {
      attachmentDataList.value.push({
        uploadedImages: { ...uploadedImages.value },
        propertyId: null,
        taskId: null,
      });
      uploadedImages.value = null;
      isNewPropertyAdded.value = true;
      clearImageUrl();
    };
    const onClickSaveButtonAttachmentsModal = async () => {
      try {
        if (attachmentDataList.value.length) {
          const isFormValid = await attachmentFormRef.value?.validate();
          if (!isFormValid.valid) return;
        }

        isLoadingUploadAttachments.value = true;

        //new attachment add functionality
        const newAttachmentDataList = attachmentDataList.value.filter(
          (attachment: any) => attachment?.uploadedImages.imageData
        );
        const filterAttachmentDataList = attachmentDataList.value.filter(
          (attachment: any) => !attachment?.uploadedImages.imageData
        );
        let newCreatedAttachment = [];
        if (newAttachmentDataList.length) {
          const formData = new FormData();
          newAttachmentDataList.forEach((attachment: any) => {
            if (attachment.uploadedImages.imageData) {
              formData.append(`files`, attachment.uploadedImages.imageData);
            }
          });

          const uploadMultipleImage = await store.dispatch(
            `${USER_STORE}/uploadMultipleImage`,
            formData
          );

          newCreatedAttachment = uploadMultipleImage.map(
            (item: any, index: number) => ({
              attachment: item.attachment,
              originalName: item.originalName,
              properties: newAttachmentDataList[index].propertyId.map(
                (id: any) => ({ propertyId: id, isDeleted: false })
              ),
              taskId: newAttachmentDataList[index]?.taskId,
              isDeleted: false,
            })
          );
        }

        const finalDataAttachment: any = [];
        const projectDefaultPropertyIds = projectProperties.value.map(
          (property: any) => property?.id
        );

        cloneAttachmentDataList.value.forEach((attachment: any, index: any) => {
          let attachmentData;
          if (attachment.isDeleted) {
            attachmentData = {
              id: attachment?.uploadedImages?.attachmentId,
              attachment: attachment?.uploadedImages?.attachment,
              originalName: attachment?.uploadedImages?.name,
              taskId: attachment.taskId,
              properties: projectAttachmentData.value[
                index
              ].proofOfWorkProperties.map((property: any) => ({
                id: property?.id,
                propertyId: property?.property?.id,
                isDeleted: true,
              })),
              isDeleted: attachment.isDeleted,
            };
          } else {
            const propertyIds: any = [];

            const changeAttachmentData = filterAttachmentDataList.find(
              (data: any) =>
                data.uploadedImages.attachmentId ===
                attachment?.uploadedImages?.attachmentId
            );
            projectDefaultPropertyIds.forEach((propertyId: number) => {
              if (
                attachment.propertyId.includes(propertyId) &&
                changeAttachmentData.propertyId.includes(propertyId)
              ) {
                propertyIds.push({
                  id: projectAttachmentData.value[
                    index
                  ].proofOfWorkProperties.find(
                    (property: any) => property.property.id === propertyId
                  )?.id,
                  propertyId: propertyId,
                  isDeleted: false,
                });
              } else if (
                !attachment.propertyId.includes(propertyId) &&
                changeAttachmentData.propertyId.includes(propertyId)
              ) {
                propertyIds.push({
                  propertyId: propertyId,
                  isDeleted: false,
                });
              } else if (
                attachment.propertyId.includes(propertyId) &&
                !changeAttachmentData.propertyId.includes(propertyId)
              ) {
                propertyIds.push({
                  id: projectAttachmentData.value[
                    index
                  ].proofOfWorkProperties.find(
                    (property: any) => property.property.id === propertyId
                  )?.id,
                  propertyId: propertyId,
                  isDeleted: true,
                });
              }
            });

            attachmentData = {
              id: attachment?.uploadedImages?.attachmentId,
              attachment: attachment?.uploadedImages?.attachment,
              originalName: attachment?.uploadedImages?.name,
              properties: propertyIds || [],
              taskId: changeAttachmentData.taskId,
            };
          }
          finalDataAttachment.push(attachmentData);
        });

        if (newCreatedAttachment.length) {
          const createResponse =
            await tradeEstimateService.createEstimatePhaseTaskProofOfWork({
              projectId: projectDetails.value.id,
              phaseId: props.phaseDetails.id,
              attachments: newCreatedAttachment,
            });
        }
        if (finalDataAttachment.length) {
          const UpdateResponse =
            await tradeEstimateService.updateEstimatePhaseTaskProofOfWork({
              projectId: projectDetails.value.id,
              phaseId: props.phaseDetails.id,
              attachments: finalDataAttachment,
            });
        }
        isLoadingUploadAttachments.value = false;
        onClickToggleAttachmentModal();
      } catch (error: any) {
        isLoading.value = false;
        console.log("error ", error);
      }
    };
    const projectAttachmentData = ref();
    const setProjectData = async () => {
      try {
        isLoading.value = true;
        const projectAttachment: any =
          await tradeEstimateService.getEstimatePhaseTaskProofOfWork(
            props.phaseDetails.id
          );
        projectAttachmentData.value = projectAttachment;
        attachmentDataList.value = await Promise.all(
          projectAttachment.map(async (propertyItems: any) => {
            return {
              uploadedImages: {
                url: await getImageUrl(propertyItems?.attachment),
                name: propertyItems?.originalName,
                attachment: propertyItems?.attachment,
                attachmentId: propertyItems?.id,
              },
              propertyId: propertyItems.proofOfWorkProperties.map(
                (property: any) => property.property.id
              ),

              taskId: propertyItems?.task?.id,
            };
          })
        );
        cloneAttachmentDataList.value = JSON.parse(
          JSON.stringify(attachmentDataList.value)
        );
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const getImageUrl = async (profileImage: string) => {
      try {
        const imageUrl = getImageApiUrl(profileImage, true);
        const encoded: any = await $axios.get(imageUrl);
        return encoded.publicUrl;
      } catch (error) {
        return null;
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
      uploadedImages.value = null;
      imageValue.value = null;
    };
    onMounted(() => {
      setProjectData();
    });
    return {
      onClickToggleAttachmentModal,
      onFileChange,
      uploadedImages,
      errorMessage,
      imageUrl,
      addPropertyImage,
      attachmentDataList,
      projectProperties,
      isLoadingUploadAttachments,
      onClickSaveButtonAttachmentsModal,
      removeImageFromAttachments,
      validation,
      attachmentFormRef,
      isLoading,
      clearImageUrl,
      isClearImage,
      imageValue,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/project/styles/projectReview.scss";

.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  margin: 30px 0 0;
}

.modal_body {
  padding: 24px;
  max-height: 900px;
  .close__icon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }
  .modal_title {
    @include fluidFont(20, 20, 1.4);
    font-weight: 400;
    color: rgba($blueDark, 1);
  }
  .upload__area {
    .uploade-wrapper {
      margin: 16px 0 0;
      width: 100%;
      max-width: 100%;
      border: 1px dashed rgba($bordercolor, 1);
      border-radius: 8px;
      position: relative;
      background-color: rgba($backgroundcolor, 1);
      .upload_file {
        margin: 0;
        :deep(.v-input__control) {
          .v-field {
            .v-field__field {
              .v-label {
                margin: -10px 0 0;
              }
            }
          }
        }
      }
      .upload_icon {
        position: absolute;
        left: 25%;
        top: 50%;
        transform: translateY(-50%);
        @include respond(s720) {
          left: 50%;
          top: 50%;
          transform: translateX(-50%);
          transform: translate(-50%, -40px);
        }
      }
    }
  }
  .image__upload__wrapper {
    width: 100%;
    margin: 32px 0 0;
    .blank_project {
      text-align: center;
      p {
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
      }
    }
    h5 {
      @include fluidFont(20, 20, 1.4);
      font-weight: 400;
      color: rgba($blueDark, 1);
      margin-bottom: 16px;
    }
    .uploaded_details_wrapper {
      max-height: calc(100dvh - 550px);
      overflow: auto;
      .show_uploaded_details {
        border-top: 1px solid rgba($PrimaryBlueLight, 1);
        padding: 24px 0;
        display: flex;
        gap: 16px;
        align-items: flex-start;
        .v-responsive {
          border-radius: 8px;
          overflow: hidden;
        }
        .prop_right__block {
          max-width: calc(100% - 156px);
          width: 100%;
          gap: 20px;
          display: flex;
          flex-direction: column;
          .prop_img_details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
              background-color: transparent;
            }
            h5 {
              margin: 0;
              @include fluidFont(12, 12, 1.2);
              font-weight: 700;
              color: rgba($blueDark, 1);
            }
            .v-btn {
              padding: 0;
              min-width: 30px;
              min-height: 30px;
              border-radius: 100%;
            }
          }
          .upload__list {
            display: flex;
            gap: 16px;
            align-items: center;
            flex-direction: column;
            .input__select {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .footer-buttons {
    padding: 23px 26px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    @include respond(s720) {
      padding: 15px;
    }
    .button {
      min-width: 140px;
      @include respond(s720) {
        width: calc(50% - 4px);
      }
      .v-btn__content {
        text-transform: capitalize;
      }
    }
  }
}
</style>
