<template>
  <div class="profile__card" :class="{ card_border: isShowBorder }">
    <div class="profile__img" v-if="imageUrl">
      <img :src="imageUrl" alt="" />
    </div>
    <div v-else class="profile__img">
      {{ getLogoChart }}
    </div>

    <div class="profile__card__content">
      <h6 v-if="name">{{ name }}</h6>
      <p v-if="address"><v-icon>mdi-map-marker</v-icon>{{ address }}</p>
      <p v-if="isShowMemberJoinDate">
        Member since <strong> January 2023</strong>
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  imageUrl: String,
  name: String,
  address: String,
  isRounded: {
    type: Boolean,
    default: true,
  },
  isShowMemberJoinDate: {
    type: Boolean,
    default: false,
  },
  isShowBorder: {
    type: Boolean,
    default: false,
  },
});

const getLogoChart = computed(() => getInitials(props.name));

const getInitials = (fullName) => {
  if (fullName !== "" && fullName !== null && fullName !== undefined) {
    const nameParts = fullName.split(" ").filter((part) => part !== "");

    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    } else if (nameParts.length >= 2) {
      const firstInitial = nameParts[0][0].toUpperCase();
      const lastInitial = nameParts[nameParts.length - 1][0].toUpperCase();
      return firstInitial + lastInitial;
    }
  }

  return "IK";
};
</script>

<script>
export default {};
</script>

<style lang="scss" scoped>
.profile__card {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  width: calc(100% - 48px);
  max-width: 100%;
  .profile__img {
    background-color: $skyBlue;
    color: rgba($white, 1);
    width: 48px;
    height: 48px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .profile__card__content {
    text-align: left;
    h6 {
      @include fluidFont(20, 20, 25px);
      font-weight: 700;
      color: rgba($blueDark, 1);
    }
    p {
      @include fluidFont(12, 12, 22px);
      font-weight: 400;
      letter-spacing: 0.25px;
      color: rgba($blueDark, 1);
    }
    .v-icon {
      font-size: 14px;
      color: rgba($blueDark, 1);
    }
  }
}
.card_border {
  border: 1px solid rgba(223, 223, 223, 1);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
}
</style>
