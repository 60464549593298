import { FilterValuesDto, NWGCustomerNoteDto } from "../models/nwg-job";
import $axios from "../utils/axios-api-config";

export default {
  getJobList(filterValues: FilterValuesDto) {
    return $axios.get("/nwg-job", {
      params: {
        page: filterValues.page,
        limit: filterValues.limit,
        status: filterValues.status,
        search: filterValues.search,
        sortBy: filterValues.sortBy,
        sortOrder: filterValues.sortOrder,
      },
    });
  },

  getJobById(jobId: number) {
    return $axios.get(`/nwg-job/${jobId}`);
  },

  getBatchById(batchId: number) {
    return $axios.get(`/nwg-job/batch/${batchId}`);
  },

  createCustomerNote(jobId: number, payload: NWGCustomerNoteDto) {
    return $axios.post(`/nwg-job/${jobId}/customer-note`, payload);
  },

  completedJob(jobId: number) {
    return $axios.put(`/nwg-job/${jobId}/completed`);
  },

  assignEngineerIntoNWGJob(batchId: number, payload: { engineerId: number }) {
    return $axios.put(`/nwg-job/${batchId}/assign-engineer`, payload);
  },
  getAllBatches() {
    return $axios.get(`/nwg-job/batches`);
  },
};
