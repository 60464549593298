<template>
  <div
    class="dashboard_calendar_wrapper tw-overflow-hidden tw-rounded-[16px] md:!tw-pb-16 md:!tw-box-border tw-border tw-border-[#dfdfdf] tw-border-solid"
  >
    <!-- <v-btn
      icon="mdi-plus"
      size="small"
      color="#4B4BFF"
      class="button button-purple dashboard_button"
    ></v-btn> -->
    <div class="dashboard_calendar_header">
      <div class="date_selector" id="select-date">
        {{ selectedDate }}
      </div>
    </div>
    <ScheduleXCalendar
      :calendar-app="calendarApp"
      event-modal="modal"
      class="dashboard_calendar sm:!tw-max-h-[unset]"
      :class="`dashboard_calendar_grid_line_day ${className}`"
    >
      <template #timeGridEvent="{ calendarEvent }">
        <div class="custom-time-grid-event">
          <div class="event_title">
            <h4>NWG Batch - {{ calendarEvent?.data?.id }}</h4>
            <div class="event_time">
              <img src="@/assets/icons/watch.svg" alt="" />
              <span
                >{{ calendarEvent.data?.startTime }} -
                {{ calendarEvent.data?.endTime }}</span
              >
            </div>
          </div>
          <UserDetailCard
            :name="calendarEvent.data?.contractor?.name"
            :address="
              getContractorAddressDetail(
                calendarEvent.data?.contractor?.user?.userBusinessDetails
              )?.postCode || ''
            "
          />
          <div class="events_list" v-if="calendarEvent.data?.jobs?.length">
            <div
              v-for="item in calendarEvent.data?.jobs"
              :key="item.id"
              class="event_items"
            >
              <div class="event_content">
                <h3>customer: {{ item?.customerName }}</h3>
                <p>
                  <v-icon icon="mdi-map-marker" color="#faa500" /> address:
                  {{ item?.property?.postcode }}
                </p>
              </div>
              <v-btn
                append-icon="mdi-chevron-right"
                @click="onClickViewJob(item.id)"
                variant="text"
              >
                view job
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </ScheduleXCalendar>
  </div>
</template>

<script setup>
import { ScheduleXCalendar } from "@schedule-x/vue";
import { createCalendar, viewDay } from "@schedule-x/calendar";
import "@schedule-x/theme-default/dist/index.css";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import { createCurrentTimePlugin } from "@schedule-x/current-time";
import { createScrollControllerPlugin } from "@schedule-x/scroll-controller";
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { createCalendarControlsPlugin } from "@schedule-x/calendar-controls";
import moment from "moment";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import "@schedule-x/theme-default/dist/index.css";
import { useRouter } from "vue-router";
import { NWG_JOBS_STORE } from "@/store/modules/nwg-job";
import { NWG_JOB_DETAIL_ROUTE } from "@/modules/nwg-job/routes";
import UserDetailCard from "@/core/components/common/UserDetailCard.vue";

const props = defineProps({
  className: String,
});

const eventsServicePlugin = createEventsServicePlugin();
const calendarControls = createCalendarControlsPlugin();
const store = useStore();
const router = useRouter();
const selectedDate = ref();

const getScrollTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

const scrollController = createScrollControllerPlugin({
  initialScroll: getScrollTime(),
});

const calendarApp = createCalendar({
  locale: "en-US",
  views: [viewDay],
  plugins: [
    eventsServicePlugin,
    createCurrentTimePlugin(),
    calendarControls,
    scrollController,
  ],

  defaultView: viewDay.name,
  events: [],
  callbacks: {
    onRangeUpdate(range) {
      const startDate = range.start.split(" ")[0];
      const endDate = range.end.split(" ")[0];
      const startMonth = new Date(range.start).getMonth() + 1;
      const endMonth = new Date(range.end).getMonth() + 1;
      if (startDate == endDate) {
        const dayViewDate = formatDate(startDate, "fullDate");
        selectedDate.value = moment(dayViewDate).format("ddd, Do MMM");
      } else if (
        formatDate(startDate, "date") !== formatDate(endDate, "date") &&
        startMonth == endMonth
      ) {
        const weekViewDate = formatDateRange(startDate, endDate);
        selectedDate.value = moment(weekViewDate).format("ddd, Do MMM");
      } else {
        const monthViewDate = formatDate(startDate, "month");
        selectedDate.value = moment(monthViewDate).format("ddd, Do MMM");
      }
    },
  },
});

createCurrentTimePlugin({
  fullWeekWidth: true,
});

const formatDateRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const formatDate = (date) => {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const formattedDay = day;
    const formattedMonth = months[month - 1];

    return `${formattedDay} ${formattedMonth}`;
  };

  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);
  const year = start.getUTCFullYear();

  return `${formattedStart} - ${formattedEnd} ${year}`;
};

const formatDate = (dateString, format) => {
  const date = new Date(dateString);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  switch (format) {
    case "fullDayDate": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      const dayOfWeek = date.getUTCDay();
      return `${day} ${monthsOfYear[month]} ${year}, ${daysOfWeek[dayOfWeek]}`;
    }
    case "fullDate": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      return `${day} ${monthsOfYear[month]}, ${year}`;
    }
    case "day-date-month": {
      const dayOfWeek = date.getUTCDay();
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      return `${daysOfWeek[dayOfWeek]} , ${day} ${month}`;
    }
    case "date": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    }
    case "yyyy-mm-dd": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${year}-${formattedMonth}-${formattedDay}`;
    }
    case "yyyy-mm-dd+1": {
      const day = date.getUTCDate() + 1;
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${year}-${formattedMonth}-${formattedDay}`;
    }
    case "month": {
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      return `${monthsOfYear[month]} ${year}`;
    }
    case "month-date": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const nextDay = day + 1;
      const monthName = monthsOfYear[month];
      return `${monthName} ${day} - ${monthName} ${nextDay}`;
    }
    default: {
      throw new Error("Invalid format type");
    }
  }
};

const batchList = computed(
  () => store.getters[`${NWG_JOBS_STORE}/getBatchList`]
);

const assignTimeSlots = (batches) => {
  let dateTimeMap = {};

  batches.forEach((batch) => {
    let { assignedDate, assignedTimeOfDay } = batch;
    if (!dateTimeMap[assignedDate]) {
      dateTimeMap[assignedDate] = { amCount: 0, pmCount: 0 };
    }

    let startTime, endTime;
    if (assignedTimeOfDay === "am") {
      if (dateTimeMap[assignedDate].amCount % 2 === 0) {
        startTime = "01:00";
        endTime = "06:00";
      } else {
        startTime = "06:10";
        endTime = "11:00";
      }
      dateTimeMap[assignedDate].amCount++;
    } else if (assignedTimeOfDay === "pm") {
      if (dateTimeMap[assignedDate].pmCount % 2 === 0) {
        startTime = "12:00";
        endTime = "17:00";
      } else {
        startTime = "17:10";
        endTime = "23:00";
      }
      dateTimeMap[assignedDate].pmCount++;
    }

    batch.startTime = startTime;
    batch.endTime = endTime;
  });

  return batches;
};

const setCalendarEvent = async () => {
  if (!batchList.value?.length) return;

  const modifiedBatch = assignTimeSlots(batchList.value);
  const batchEvent = modifiedBatch.map((batch) => {
    const assingDate = batch?.assignedDate || moment().format("YYYY-MM-DD");

    return {
      id: 4,
      title: "test event first",
      start: `${assingDate} ${batch.startTime}`,
      end: `${assingDate} ${batch.endTime}`,
      description: "test event first description",
      calendarId: "yellow",
      data: batch,
    };
  });

  calendarApp.events.set([]);
  if (batchEvent.length) {
    calendarApp.events.set(batchEvent);
  }
};

const onClickViewJob = (jobId) => {
  router.push({ name: NWG_JOB_DETAIL_ROUTE, params: { id: jobId } });
};
const getContractorAddressDetail = (userBusinessDetails) => {
  if (userBusinessDetails?.type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
    return {
      type: "Limited Company",
      postCode: userBusinessDetails?.businessPostcode,
      adress: userBusinessDetails?.businessAddress,
    };
  }
  return {
    type: "Solo Trader",
    postCode: userBusinessDetails?.residencyPostcode,
    adress: userBusinessDetails?.residencyAddressLine,
  };
};
onBeforeMount(async () => {
  const date = new Date();
  selectedDate.value = moment(date).format("ddd, Do MMM");
  if (!batchList.value?.length) {
    await store.dispatch(`${NWG_JOBS_STORE}/AllBatchList`);
  }
  await setCalendarEvent();
});
</script>

<style lang="scss">
@import "@/modules/dashboard/styles/DashboardCalenderComponent.scss";

.input_field {
  label {
    @include fluidFont(12, 12, 2);
    font-weight: 600;
    color: rgba($blueDark, 1);
    letter-spacing: 0.15px;
  }
  .v-input {
    margin: 0;
    .v-input__control {
      .v-field {
        border: 1px solid rgba($blueDark, 0.2);
        border-radius: 8px;
        .v-field__field {
          .v-field__input {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
          }
        }
      }
    }
  }
}
#select-date {
  pointer-events: none;
  margin-top: -12px;
}
.sx__date-input {
  margin-left: 20px;
  height: 30px;
  opacity: 0;
}

.custom-outer-grid {
  height: 4.16667%;
  width: auto !important;

  .custom-inner-grid {
    position: relative;
    height: 100%;
    top: 13.5417%;
    left: 0%;
    background-color: #fff7ea;
    color: #000;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 4px solid #ffa71a;
    width: auto;
    padding: 4px;
    position: absolute;
    border-radius: 4px;
    font-size: 0.75rem;

    .custom-event-title {
      font-weight: 600;
      top: 13.5417%;
      height: 9.375%;
      left: 0%;
      width: 100%;
      background-color: #fff7ea;
      color: #000;
      border-top: 0px;
      border-right: 0px;
      border-bottom: 0px;
    }

    .custom-event-time {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-top: 0.75rem;
    }
  }
}
.custom-modal {
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.custom-time-grid-event {
  background-color: rgba(246, 235, 251, 1);
  border-left: 2px solid rgba(163, 56, 214, 1);
  padding: 24px 16px;
  height: 100%;
  overflow: auto;
  .event_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      @include fluidFont(18, 18, 20px);
      font-weight: 600;
      color: rgba($blueDark, 1);
      text-decoration: underline;
    }
    .event_time {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .profile__card {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    background-color: rgba($white, 0.3);
  }
  .events_list {
    margin: 26px 0 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    .event_items {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
      border-bottom: 1px solid rgba($bordercolor, 1);
      .event_content {
        h3 {
          @include fluidFont(14, 14, 18px);
          font-weight: 700;
          color: rgba($blueDark, 1);
          margin: 0 0 4px;
        }
        p {
          @include fluidFont(12, 12, 22px);
          font-weight: 500;
          color: rgba($blueDark, 1);
        }
      }
      .v-btn {
        @include fluidFont(12, 12, 15.06px);
        font-weight: 500;
        color: rgba($orange, 1);
        text-transform: capitalize;
        padding: 0;
        letter-spacing: 0.15px;
      }
    }
  }
}
</style>
