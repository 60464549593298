/**
 * Calendar route page
 */
import CalendarRoute from "@/modules/nwg-calendar/pages/CalendarOverviewPage.vue";

export const NWG_CALENDAR_ROUTE = "nwg-calendar";

export default [
  {
    path: "/nwg/calendar",
    name: NWG_CALENDAR_ROUTE,
    component: CalendarRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "NWG Calendar",
    },
  },
];
