<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="640px"
    max-width="100%"
    height="auto"
    persistent
    :className="['add_customer_note_modal']"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal_close" @click="onClickCloseButton">
          <v-icon icon="mdi-close" />
        </div>
        <div class="modal-container">
          <v-form ref="formRef" :rules="formValidationRules">
            <div class="modal-body">
              <h4>Customer Notes</h4>
              <UserDetailCard
                :name="jobDetail?.customerName"
                :address="jobDetail?.property?.postcode"
              />
              <!-- evidence image upload -->

              <div class="modal-date-picker input__content">
                <label>Select Date</label>
                <v-text-field
                  class="input_field"
                  variant="outlined"
                  v-model="formData.date"
                  type="date"
                  :rules="formValidationRules.date"
                  required
                  :min="new Date().toISOString().substr(0, 10)"
                ></v-text-field>
              </div>
              <div class="modal-statement input__content">
                <label>Notes </label>
                <v-textarea
                  class="input_field"
                  placeholder="Type your Notes here"
                  variant="solo"
                  rows="5"
                  autogrow
                  v-model="formData.note"
                  :rules="formValidationRules.customerNotes"
                />
              </div>
              <div v-if="isJobCompleted" class="modal-statement input__content">
                <label>Nwg Notes</label>
                <v-textarea
                  class="input_field"
                  placeholder="Type your Notes here"
                  variant="solo"
                  rows="5"
                  bg-color="#f8fbfd"
                  autogrow
                  v-model="formData.nwgNote"
                  :rules="formValidationRules.customerNotes"
                />
              </div>

              <div class="upload-wrapper input__content">
                <label>Attachments</label>
                <v-file-input
                  label="Drag and drop images & files, or Browse"
                  class="upload_file custom-file-upload"
                  variant="solo-filled"
                  multiple
                  v-model="selectedFile"
                  :rules="formValidationRules.attachments"
                  :class="{
                    'added-img': selectedFile && selectedFile?.length,
                  }"
                  @change="onFileChange"
                  prepend-icon="mdi-file-image-box"
                  accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
                  :clearable="false"
                >
                </v-file-input>
                <div class="upload__icon">
                  <img src="@/assets/icons/upload-image.svg" alt="" />
                </div>
              </div>
            </div>
            <div v-if="attachmentList?.length" class="attachment-list">
              <div
                v-for="(file, index) in attachmentList"
                :key="index"
                class="attachment-item"
              >
                <div class="img__icon">
                  <ImageIcon />
                  <Text variant="p" textWeight="400">{{ file.name }}</Text>
                </div>
                <v-icon
                  icon="mdi-close"
                  @click="removeAttachment(index)"
                ></v-icon>
              </div>
            </div>
          </v-form>
          <div class="checkbox_items">
            <v-checkbox
              v-if="!isJobCompleted"
              label="Mark as completed"
              class="form_input"
              v-model="isSelectCompleted"
            ></v-checkbox>
            <v-checkbox
              :disabled="!isJobCompleted"
              label="Revisit required?"
              class="form_input"
              v-model="isSelectRevisitCheck"
            ></v-checkbox>
            <div
              class="modal-statement input__content"
              v-if="isSelectCompleted"
            >
              <label>cost : </label>
              <v-text-field
                v-model="formData.CostValue"
                class="input_field"
                variant="variant"
                type="number"
                :rules="[
                  (v) => !!v || 'This field is required',
                  (v) => !isNaN(v) || 'Must be a number',
                  (v) => v >= 0 || 'No negative numbers',
                ]"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal-footer">
        <v-btn
          class="button button-orange"
          @click="onSubmitNote"
          :loading="isLoading"
        >
          Submit Notes
        </v-btn>
      </div>
    </template>
  </CommonDialog>
</template>

<script setup>
import { computed, ref } from "vue";
import ImageIcon from "@/core/components/icons/ImageIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { NWG_JOBS_STORE } from "@/store/modules/nwg-job";
import UserDetailCard from "@/core/components/common/UserDetailCard.vue";
const props = defineProps(["isJobCompleted"]);
const emits = defineEmits(["on-close", "on-submit"]);
const store = useStore();

const selectedFile = ref([]);
const formData = ref({
  date: null,
  note: null,
  nwgNote: null,
  CostValue: null,
});
const isLoading = ref(false);
const isSelectCompleted = ref(false);
const isSelectRevisitCheck = ref(false);
const formRef = ref(null);
const attachmentList = ref([]);

const formValidationRules = ref({
  date: [(v) => !!v || "Date is required"],
  customerNotes: [(v) => !!v || "Enter your Notes required"],
  attachments: [(v) => v.length > 0 || "At least one attachment is required"],
});

const onClickCloseButton = () => {
  emits("on-close");
};

const onFileChange = (event) => {
  const fileInput = event.target;
  if (fileInput.files && fileInput.files.length > 0) {
    Array.from(fileInput.files).forEach((file) => {
      if (file.type.includes("image")) {
        const imageUrl = URL.createObjectURL(file);
        const uploadedImages = {
          url: imageUrl,
          name: file.name,
          imageData: file,
        };
        if (!attachmentList.value.some((doc) => doc.name === file.name)) {
          attachmentList.value.push(uploadedImages);
        }
      }
    });
  }
  event.target.value = "";
};
const uploadAttachment = async () => {
  try {
    const formData = new FormData();
    attachmentList.value.forEach((attachment) => {
      if (attachment?.imageData) {
        formData.append(`files`, attachment.imageData);
      }
    });
    const uploadMultipleImage = formData.has("files")
      ? await store.dispatch(`${USER_STORE}/uploadMultipleImage`, formData)
      : [];

    const newCreatedAttachment = uploadMultipleImage.map((item) => ({
      attachment: item.attachment,
      originalName: item.originalName,
    }));

    return newCreatedAttachment;
  } catch (error) {
    return [];
  }
};
const removeAttachment = (index) => {
  attachmentList.value.splice(index, 1);
};
const jobDetail = computed(
  () => store.getters[`${NWG_JOBS_STORE}/getJobDetails`]
);
const onSubmitNote = async () => {
  try {
    const isFormValid = await formRef.value.validate();
    if (!isFormValid.valid) return;
    isLoading.value = true;
    const attachments = await uploadAttachment();
    const payload = {
      note: formData.value.note,
      date: formData.value.date,
      attachments,
    };
    if (formData.value.CostValue) {
      payload["cost"] = formData.value?.CostValue;
    }
    if (formData.value.nwgNote) {
      payload["nwgNote"] = formData.value.nwgNote;
    }
    if (isSelectRevisitCheck.value) {
      payload["revisitRequired"] = true;
    }
    await store.dispatch(`${NWG_JOBS_STORE}/createCustomerNote`, {
      jobId: jobDetail.value.id,
      data: payload,
    });
    if (isSelectCompleted.value || props.isJobCompleted) {
      await store.dispatch(
        `${NWG_JOBS_STORE}/makeCompleteJob`,
        jobDetail.value.id
      );
      await store.dispatch(
        `${NWG_JOBS_STORE}/setJobDetails`,
        jobDetail.value.id
      );
    }
    emits("on-submit");
  } catch (error) {
    console.log("error");
  } finally {
    isLoading.value = false;
  }
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  .modal_close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  .modal-container {
    .v-form {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba($bordercolor, 1);
      .modal-body {
        h4 {
          @include fluidFont(18, 18, 24px);
          font-weight: 400;
          color: rgba($blueDark, 1);
          margin-bottom: 16px;
        }

        .upload_file {
          width: 100% !important;
          margin: 0 !important;
          background-color: #fff !important;
          border-radius: 6px !important;
          height: 100px;
          border: 1px dashed rgba(0, 0, 0, 0.4);
          :deep(.v-input__control) {
            .v-field {
              position: relative;
              .v-field__field {
                .v-label {
                  @include fluidFont(12, 12, 15.06px);
                  color: rgba($blueDark, 0.5);
                  font-weight: 400;
                  margin: 0;
                  max-width: 160px;
                  text-overflow: unset;
                  overflow: visible;
                  white-space: normal;
                  text-align: center;
                }
              }
              .v-field__clearable {
                background-color: transparent;
                color: rgba($blueDark, 0.5);
                border-radius: 0;
              }
            }
          }
          :deep(.v-input__details) {
            position: absolute;
            bottom: -25px;
          }
        }

        .upload__icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .attachment-list {
        margin-top: 1rem;
        max-height: 110px;
        overflow: auto;
        .attachment-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .img__icon {
            display: flex;
            gap: 8px;
            align-items: center;
            span {
              @include fluidFont(12, 12, 28px);
              font-weight: 400;
              color: rgba($blueDark, 1);
            }
          }
          .v-icon {
            font-size: 10px;
          }
        }

        .remove-icon {
          color: #ff4d4f;
        }
      }
    }
    .checkbox_items {
      margin: 16px 0;
      .v-checkbox {
        margin: 0 0 16px;
        :deep(.v-input__control) {
          .v-selection-control {
            min-height: auto;
            .v-selection-control__wrapper {
              width: auto;
              height: auto;
              margin-right: 8px;
              .v-selection-control__input {
                width: auto;
                height: auto;
                .v-icon {
                  opacity: 1;
                  font-size: 16px;
                }
                input[type="checkbox"]:checked {
                  color: rgba($orange, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  background: #fff url(@/assets/icons/calendar-modal-icon.svg) 97% 50% no-repeat;
}
.modal-footer {
  // display: flex;
  // gap: 16px;
  // align-items: center;
  // justify-content: flex-end;
  // margin-top: 40px;
  padding-top: 24px;
  border-top: 1px solid rgba(197, 210, 242, 1);
  .button {
    width: 100%;
    max-width: 100%;
  }
}

.input_field {
  :deep(.v-input__control) {
    .v-field {
      border: 1px solid rgba($blueDark, 0.2);
      border-radius: 8px;
      padding: 0 0;
      box-shadow: none;
      .v-field__field {
        .v-field__input {
          background-color: transparent;
          border: 0;
          border-radius: 0;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          min-height: 40px;
          top: 0;
          padding: 12px 16px;
          display: block;
          mask-image: none;
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}
.input__content {
  margin-top: 16px;
  position: relative;
  &:first-child {
    margin-top: 0;
  }
  label {
    @include fluidFont(12, 12, 24px);
    font-weight: 400;
    color: rgba($blueDark, 1);
    letter-spacing: 0.15px;
    display: inline-block;
    margin-bottom: 8px;
  }
}
</style>
