<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['property-service-plan-modal']"
    width="590px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header md:!tw-mb-4">
          <v-btn variant="plain" icon="mdi-close" @click="closeModal"></v-btn>
        </div>
        <div class="property-service-plan-modal__content">
          <h4 class="md:!tw-leading-[35px] md:!tw-mb-4">
            Does Your Property have a heat pump?
          </h4>
          <p>
            Get your annual service, from <strong>£30.00</strong> per month.
          </p>
          <div class="property-service-plan-modal__pay tw:!tw-mt-4">
            <p>You’ll Pay,</p>
            <p><strong>£30.00</strong></p>
          </div>
        </div>

        <div class="property-service-plan-modal__footer">
          <Button
            variant="accent"
            label="PAY NOW!"
            @click="onSubscribeHeatPumpSubscription"
            :isLoading="loading"
            class="button"
          />
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { USER_STORE } from "@/store/modules/user";
import { ElMessage } from "element-plus";
import { ref, defineEmits, computed } from "vue";
import { useStore } from "vuex";
const emits = defineEmits(["on-close"]);

const loading = ref(false);
const store = useStore();
const closeModal = () => {
  emits("on-close");
};
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const onSubscribeHeatPumpSubscription = async () => {
  try {
    loading.value = true;
    await store.dispatch(`${PROPERTY_STORE}/getHeatPumpSubscription`, {
      userId: user.value.id,
      propertyId: propertyDetails.value.id,
    });
    await store.dispatch(
      `${PROPERTY_STORE}/fetchPropertyDetails`,
      propertyDetails.value.id
    );
    ElMessage.success("You have subscribe to Heat pump subscription plan");
  } catch (error) {
    if (error.response.status === 402) {
      ElMessage.error("Please upgrade your workstation !");
    } else {
      ElMessage.error(
        "something went wrong in subscription of heat pump service plan."
      );
    }
  } finally {
    loading.value = false;
    closeModal();
  }
};
</script>
