<template>
  <CommonDialog
    HeaderTittle="Pay Money Into Your Escrow Wallet"
    title="Select your bank"
    :className="['escrow_pay_money_wallet']"
    width="510px"
    :onClickBackButton="onClickBackButton"
  >
    <template v-slot:body>
      <CommonLoader :loading="loading" class="escrow-loader-container" />
      <div v-if="!loading" class="wallet_summary">
        <v-form
          ref="form"
          v-model="formValid"
          class="tw-max-w-full tw-mt-6 md:tw-mt-4"
          @submit.prevent="onConfirm"
        >
          <div class="bank__select">
            <!-- <Text variant="span" textWeight="400" class="tw-mb-2">
              Please select reason of rejection
            </Text> -->
            <v-autocomplete
              variant="underlined"
              v-model="selectedBank"
              :items="filterBankList"
              item-title="name"
              item-value="id"
              placeholder="Type or select by clicking the arrow"
              :rules="[(v) => !!v || 'Bank  is required']"
              required
              class="custom_selector"
              :menu-props="{ contentClass: 'text_selector' }"
            ></v-autocomplete>
          </div>
          <!-- phases -->

          <div class="amount_details" v-if="!isIbgPolicy">
            <div
              v-for="phase in selectedPhases"
              :key="phase?.id"
              class="tw-flex tw-items-center tw-justify-between tw-my-2"
            >
              <p
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                {{ phaseEstimateType(phase) }}
              </p>
              <strong
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{
                  phase?.isPolicy
                    ? formattedCurrency(phase?.totalAmount)
                    : totalPhaseAmount(
                        phase?.phaseType,
                        phase?.subTotal,
                        phase?.vat,
                        phase?.isOld,
                        phase
                      )
                }}</strong
              >
            </div>
            <v-divider :thickness="1"></v-divider>

            <div class="tw-flex tw-items-center tw-justify-between tw-my-2">
              <p
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                Subtotal (include Grant)
              </p>
              <strong
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{ formattedCurrency(subTotalAmount) }}</strong
              >
            </div>
            <div class="tw-flex tw-items-center tw-justify-between tw-my-2">
              <p
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                vat
              </p>
              <strong
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{ formattedCurrency(vatTotal) }}</strong
              >
            </div>
            <div class="tw-flex tw-items-center tw-justify-between tw-my-2">
              <p
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                {{
                  estimateDetails?.grantName
                    ? estimateDetails?.grantName
                    : "Grant "
                }}
              </p>
              <strong
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{
                  formattedCurrency(estimateDetails?.grantAmount, true)
                }}</strong
              >
            </div>
            <v-divider :thickness="1"></v-divider>
            <div class="tw-flex tw-items-center tw-justify-between tw-my-2">
              <p
                class="tw-text-lg tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                Total
              </p>
              <strong
                class="tw-text-lg tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{ formattedCurrency(subTotalAmount + vatTotal) }}</strong
              >
            </div>
          </div>
          <div class="amount_details">
            <div class="tw-flex tw-items-center tw-justify-between tw-my-2">
              <p
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0C0F4A]"
              >
                Total IBG Policy Cost
              </p>
              <strong
                class="tw-text-xs tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#0C0F4A]"
                >{{ ibgPolicyTotal }}</strong
              >
            </div>
          </div>

          <div class="confirm_wrapper tw-mt-8">
            <div
              v-if="!isIbgPolicy"
              class="confirm_wrapper_issue tw-flex tw-items-center tw-gap-2 md:tw-flex-col md:tw-items-start"
            >
              <p
                class="tw-text-sm tw-tracking-[0.25px] tw-leading-[22px] tw-font-medium tw-text-[#0c0f4a80]"
              >
                Can’t Find Your Bank?
              </p>
              <span
                @click="onRedirectEWallet"
                class="tw-text-sm tw-tracking-[0.25px] tw-leading-[22px] tw-font-bold tw-text-[#3366FF] tw-cursor-pointer"
                >Transfer Manually
                <v-icon icon="mdi-chevron-right" class="tw-text-[#3366FF]" />
              </span>
            </div>
            <Button
              variant="accent"
              type="submit"
              label="Confirm"
              class="tw-mt-8"
            />
          </div>
        </v-form>
      </div>
    </template>
    <template #footer v-if="!loading">
      <p
        class="tw-mt-6 tw-text-[10px] tw-tracking-[0.25px] tw-leading-[18px] tw-font-medium tw-text-[#0c0f4a80] tw-text-center"
      >
        By agreeing and continuing you consent to us requesting funds from your
        selected bank and sharing your account details with them.
        <span class="tw-text-[#0c0f4a]">Terms & conditions</span> apply. You
        will be redirect to your bank app for payment.
      </p>
    </template>
  </CommonDialog>
</template>

<script lang="ts" setup>
import {
  computed,
  ComputedRef,
  onMounted,
  ref,
  defineProps,
  defineEmits,
} from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import CommonDialog from "@/core/components/CommonDialog.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import CurrencyFilter from "@/filters/currency.filter";
import { EscrowStatusEnum, EstimatePhaseType } from "@/core/enums/estimateEnum";
import { ProjectPhaseType } from "@/core/enums/ProjectsEnum";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import Button from "@/core/components/ui/general/Button.vue";
const emits = defineEmits(["on-close", "on-redirect-EWallet", "on-confirm"]);
const formValid = ref(false);
const props = defineProps({
  selectedPhases: {
    type: Array,
    default: () => [],
  },
  subTotalAmount: {
    type: Number,
  },
  vatTotal: {
    type: Number,
  },
  projectDetails: {
    type: Object,
  },
  estimateDetails: {
    type: Object,
  },
  isIbgPolicy: {
    type: Boolean,
    default: false,
  },
  ibgPolicyTotal: {
    type: String,
    default: "0",
  },
  ibgPolicyData: {
    type: Object,
    default: null,
  },
});
const bankList = ref([]);

const phaseEstimateType = (phase: any) => {
  return phase.isPolicy
    ? `Policy-${phase?.policyNumber}`
    : phase?.phaseType === ProjectPhaseType.MATERIAL
    ? "Materials"
    : phase?.name;
};
const formattedCurrency = (value: any, flag = false) => {
  const formattedAmt = CurrencyFilter.formatToCurrency(value);
  if (flag) return `-${formattedAmt}`;
  return formattedAmt;
};
const selectedBank = ref(null);
const loading = ref(false);
const onClickBackButton = () => {
  emits("on-close");
};

const onConfirm = async () => {
  if (formValid.value) {
    if (!props.isIbgPolicy) {
      const payload = {
        aspspId: selectedBank.value,
        projectId: props.projectDetails?.id,
      };
      const projectPhaseDeposits = props.selectedPhases
        ?.filter((phase) => !phase.isPolicy)
        .map((phase) => {
          return { projectPhaseId: phase?.id };
        });

      if (projectPhaseDeposits?.length) {
        payload["projectPhaseDeposits"] = projectPhaseDeposits;
      }
      const projectPolicyDeposits = props.selectedPhases
        ?.filter((phase) => phase.isPolicy)
        .map((phase) => {
          return { policyId: phase?.id };
        });
      if (projectPolicyDeposits?.length) {
        payload["projectPolicyDeposits"] = projectPolicyDeposits;
      }
      emits("on-confirm", payload);
    } else {
      const payload = {
        aspspId: selectedBank.value,
        projectId: props.projectDetails?.id,
        estimateIbgPhaseId: props.ibgPolicyData.id,
      };
      emits("on-confirm", payload);
    }
  }
};
const onRedirectEWallet = () => {
  emits("on-redirect-EWallet");
};
const filterBankList = computed(() => {
  return bankList.value.filter((bank) => {
    return bank.capabilities.every((data: any) => data.status === "ENABLED");
  });
});
const totalPhaseTasks = computed(
  () =>
    props.estimateDetails?.estimationPhase.filter(
      (phase: any) => phase.phaseType === EstimatePhaseType.LABOUR
    ).length
);
const SERVICE_CHARGE = 0.03;
const PHASE_FIRST_CHARGE = 5;
const totalPhaseAmount = (
  phaseType: EstimatePhaseType,
  amount: number,
  vat: number,
  isFirstPhase: boolean,
  phaseDetails: any
) => {
  let sum = 0;
  // let vatSum = amount * vat;
  let phaseCost = amount;
  const isUpdateChangePhaseAmount =
    [EscrowStatusEnum.IN_PROGRESS, EscrowStatusEnum.IN_PROGRESS_POKED].includes(
      phaseDetails?.escrowStatus?.id
    ) && parseFloat(phaseDetails?.updatedSubTotal) > 0;
  if (isUpdateChangePhaseAmount) {
    phaseCost = parseFloat(phaseDetails?.updatedSubTotal);
  }
  let serviceCost = phaseCost * SERVICE_CHARGE;
  sum += serviceCost + phaseCost;
  if (phaseDetails?.isOld) {
    sum += PHASE_FIRST_CHARGE;
  }

  if (phaseType === EstimatePhaseType.LABOUR && totalPhaseTasks) {
    const grantAmount: number = parseFloat(props.estimateDetails?.grantAmount);
    const totalTasks: number = parseFloat(totalPhaseTasks.value);
    const grantDiscountTotal = grantAmount / totalTasks;
    const finalAmount = sum - grantDiscountTotal;
    sum = Math.floor(finalAmount * 100) / 100;
  }
  return formattedCurrency(sum);
};
const onIbgPolicyConfirm = () => {
  if (formValid.value) {
    // const projectPhaseDeposits = props.selectedPhases.map((phase) => {
    //   return { projectPhaseId: phase?.id };
    // });

    const payload = {
      aspspId: selectedBank.value,
      projectId: props.projectDetails?.id,
      // projectPhaseDeposits,
    };

    emits("on-confirm", payload);
  }
};
onMounted(async () => {
  try {
    loading.value = true;
    bankList.value = await tradeEstimateService.getEscrowBankProviders();
  } catch (error) {
    console.log(error);
    onClickBackButton();
  } finally {
    loading.value = false;
  }
});
</script>

<style lang="scss" scoped>
.escrow-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
