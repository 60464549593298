<template>
  <TradePassportCardSlot
    class="trade-passport-clients"
    title="Clients & Businesses I’ve worked with"
    buttonLabel="+Add"
    :hideFooter="true"
    emptyStateImage="insurance-empty-state"
    :isEmpty="!tradePassportClientsAndBusinesses.length"
    :numberOfRemainingItem="tradePassportClientsAndBusinesses.length"
    :isCustomActionHeader="
      tradePassportClientsAndBusinesses &&
      tradePassportClientsAndBusinesses.length
        ? true
        : false
    "
    :emptyStateLabel="
      isUserProprtyOwner
        ? 'Clients & Businesses will appear here'
        : 'Your Clients & Businesses I’ve worked with details will appear here'
    "
    @on-handle-click="onRouteToClientOverview"
  >
    <template #custom-action-header>
      <div class="tw-flex tw-gap-3 tw-items-center">
        <Button
          label="+Add"
          variant="secondary"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          v-if="!isUserProprtyOwner"
          @click="onRouteToClientOverview"
        />
        <BlueCircularPencilIcon
          v-if="!isUserProprtyOwner"
          @click="onRouteToClientOverview"
        />
      </div>
    </template>
    <template #content>
      <div class="clients-grid tw-w-full">
        <!-- client item -->
        <div
          v-for="(item, index) in tradePassportClientsAndBusinesses"
          :key="index"
          class="tw-group tw-cursor-pointer tw-min-w-[95px] tw-h-[95px] rmd:!tw-min-w-[120px] rmd:!tw-h-[120px] tw-rounded-[11.16px] tw-overflow-hidden tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-relative"
        >
          <!-- Overlay (Initially hidden, shows on hover) -->
          <div
            @click="onOpenDeleteModal(item?.id)"
            class="tw-absolute tw-w-full tw-h-full tw-bg-[rgba(0,0,0,0.54)] tw-flex tw-items-center tw-justify-center tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity tw-duration-300"
          >
            <GrayCircularTrashIcon />
          </div>

          <!-- Image -->
          <img
            :src="item?.imageUrl"
            class="tw-w-full tw-h-full tw-object-cover"
          />
        </div>
      </div>
    </template>
  </TradePassportCardSlot>

  <ConfirmDeleteClientsBusinessModal
    v-if="showConfirmDeleteMoal"
    :id="selectedId"
    @on-close="onCloseDeleteModal"
    @on-success="onSuccessDelete"
  />
</template>
<script setup>
import { computed, ref, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import TradePassportCardSlot from "../slots/TradePassportCardSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { TRADE_PASSPOSRT_DETAILS_ROUTE } from "../../routes";
import GrayCircularTrashIcon from "@/core/components/icons/GrayCircularTrashIcon.vue";
import ConfirmDeleteClientsBusinessModal from "../modals/ConfirmDeleteClientsBusinessModal.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";

const store = useStore();
const router = useRouter();
const internalInstance = getCurrentInstance();

const loading = ref(false);

const showConfirmDeleteMoal = ref(false);
const selectedId = ref(null);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const tradePassportClientsAndBusinesses = computed(() => {
  return store.getters[`${USER_STORE}/userWorkstationClientsBusinesses`];
});

const initialize = async () => {
  try {
    loading.value = true;
    if (user.value?.id) {
      await store.dispatch(
        `${USER_STORE}/getAllTradePassportclientsBusinesses`,
        user.value?.id
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
    console.log(
      tradePassportClientsAndBusinesses.value,
      "tradePassportClientsAndBusinesses"
    );
  }
};

const onOpenDeleteModal = (id) => {
  selectedId.value = id;
  showConfirmDeleteMoal.value = true;
};

const onCloseDeleteModal = () => {
  showConfirmDeleteMoal.value = false;
};

const onSuccessDelete = async () => {
  selectedId.value = null;
  await initialize();
  onCloseDeleteModal();
  // displayToast here
  displayToastMessage(internalInstance, "Deleted Successfully", "success");
};

const onRouteToClientOverview = () => {
  console.log("entered here");
  router.push({
    name: TRADE_PASSPOSRT_DETAILS_ROUTE,
    params: { type: "clients-business" },
  });
};

onBeforeMount(() => {
  initialize();
});
</script>
<style lang="scss" scoped>
.clients-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  }
}
</style>
