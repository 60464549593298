<template>
  <div
    class="tradepassport-card-slot tw-w-full tw-bg-[#F8FBFD] tw-rounded-lg tw-border-solid tw-border-[#DFDFDF] tw-border-[1px] tw-p-4 tw-box-border tw-flex tw-flex-col tw-justify-between"
  >
    <!-- header -->
    <div
      class="tw-w-full tw-flex tw-items-center tw-gap-4 tw-justify-between tw-pb-4 tw-box-border tw-border-b-dashed-custom"
    >
      <Text
        variant="h5"
        class="rmd:!tw-text-[22px]"
        :class="{ '!tw-text-[16px]': !isTitleStrongMobile }"
        textAlign="left"
        >{{ title }}</Text
      >
      <Button
        v-if="!isCustomActionHeader && !hideActionHeader && !isUserProprtyOwner"
        variant="accent"
        :label="buttonLabel"
        class="!tw-w-auto md:!tw-hidden"
        :isCapitalize="true"
        @click="onHandleClick"
      />
      <div
        v-if="isCustomActionHeader && !hideActionHeader"
        class="tw-w-auto tw-flex tw-items-center tw-gap-3"
      >
        <slot name="custom-action-header"></slot>
      </div>
    </div>

    <!-- content empty state-->
    <div
      v-if="!isCustomEmptyState && isEmpty"
      class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center tw-py-4 tw-box-border"
    >
      <div class="tw-w-[117px] tw-h-[100px]">
        <img
          class="tw-w-full tw-h-full tw-object-contain"
          :src="require(`@/assets/images/${emptyStateImage}.png`)"
          alt=""
        />
      </div>
      <Text variant="p" textColor="rgba(12, 15, 74, 0.7)" textWeight="500">{{
        emptyStateLabel
      }}</Text>
      <Button
        v-if="!isCustomActionHeader && !hideActionHeader && !isUserProprtyOwner"
        variant="accent"
        :label="buttonLabel"
        class="!tw-w-auto tw-flex rmd:!tw-hidden"
        :isCapitalize="true"
        @click="onHandleClick"
      />
    </div>
    <div
      v-if="isCustomEmptyState && isEmpty"
      class="tw-w-full tw-h-full tw-pt-4 tw-box-border"
    >
      <slot name="custom-empty-state"></slot>
    </div>

    <!-- main content slot -->
    <div v-if="!isEmpty" class="tw-w-full tw-h-full tw-pt-4 tw-box-border">
      <slot name="content"></slot>
    </div>

    <!-- footer -->
    <div
      v-if="!hideFooter"
      class="tw-w-full tw-flex tw-items-center tw-justify-between tw-pt-4 tw-box-border tw-border-t-solid-custom"
    >
      <Text
        variant="h6"
        textColor="#3366FF"
        class="tw-cursor-pointer"
        @click="onHandleFooterClick"
        >View all ({{ numberOfRemainingItem }})</Text
      >
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
const props = defineProps({
  title: {
    type: String,
    default: "title",
  },
  isTitleStrongMobile: {
    type: Boolean,
    default: false,
  },
  buttonLabel: {
    type: String,
    default: "action here",
  },
  isCustomActionHeader: {
    type: Boolean,
    default: false,
  },
  hideActionHeader: {
    type: Boolean,
    default: false,
  },
  isEmpty: {
    type: Boolean,
    default: true,
  },
  emptyStateImage: {
    type: String,
    default: "description-empty",
  },
  emptyStateLabel: {
    type: String,
    default: "No Data Available",
  },
  isCustomEmptyState: {
    type: Boolean,
    default: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  numberOfRemainingItem: {
    type: Number,
    default: 0,
  },
});
const store = useStore();
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const emits = defineEmits(["on-handle-click", "on-handle-footer-click"]);

const onHandleFooterClick = () => {
  emits("on-handle-footer-click");
};

const onHandleClick = () => {
  emits("on-handle-click");
};
</script>
<style lang="scss" scoped></style>
