export enum NWGJobStatus {
  NOT_STARTED = 0,
  ACTIVE = 1,
  ESTIMATES_RECEIVED = 2,
  IN_PROGRESS = 3,
  COMPLETED = 4,
  CANCELLED = 5,
}

export enum NWGStatusEnum {
  OPEN = "open",
  COMPLETED = "completed",
  "CANCELLED" = "cancelled",
}
