<template>
  <section
    class="estimate-form__wrapper tw-px-6 tw-py-4 estimate-body tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4"
  >
    <el-form
      class="tw-flex tw-flex-col tw-gap-4 tw-w-full"
      :model="estimateForm.projectStages[phaseIndex]"
    >
      <div class="tw-flex tw-gap-4 tw-items-center tw-justify-between">
        <div
          class="tw-flex tw-gap-4 tw-items-center tw-w-full tw-max-w-[50%] md:tw-max-w-full"
        >
          <el-form-item class="tw-w-full tw-max-w-[80px] md:tw-max-w-[30%]">
            <NoBgSelect
              v-model="estimateForm.projectStages[phaseIndex].stageType"
              @change="onChangeStageType"
              class="tw-w-full"
            >
              <template #option>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </template>
            </NoBgSelect>
          </el-form-item>
          <el-form-item
            class="tw-w-[80%] md:tw-w-[70%]"
            :class="{ 'is-error': !!errors.name }"
          >
            <NoBgInput
              v-model="estimateForm.projectStages[phaseIndex].name"
              placeholder="Phase Name e.g Preparation"
              @change="updateLabourPhaseEstimation"
            />
          </el-form-item>
        </div>
      </div>
      <div
        class="tw-flex tw-gap-12 tw-items-center tw-w-full md:tw-flex-col md:tw-gap-4 md:tw-items-start"
      >
        <div
          class="tw-flex tw-gap-12 tw-items-center tw-w-[40%] tw-max-w-full md:tw-w-full md:tw-gap-5"
        >
          <el-form-item
            class="tw-w-[50%]"
            :class="{ 'is-error': !!errors.startDate }"
          >
            <NoBgDatePicker
              v-model="estimateForm.projectStages[phaseIndex].startDate"
              placeholder="Phase start date"
              class="tw-w-[150px] !tw-min-w-[150px]"
              :startDate="formData.suggestedStartDate"
              @change="updateLabourPhaseEstimation"
            />
          </el-form-item>
          <el-form-item
            class="tw-w-[50%]"
            :class="{ 'is-error': !!errors.totalDuration }"
          >
            <div class="tw-flex tw-flex-row tw-w-full">
              <NoBgInput
                type="number"
                placeholder="Duration"
                min="0"
                v-model="estimateForm.projectStages[phaseIndex].totalDuration"
                @input="updateLabourPhaseEstimation"
              />
              <NoBgSelect
                v-model="estimateForm.projectStages[phaseIndex].durationType"
                class="tw-max-w-[55px]"
                @change="updateLabourPhaseEstimation"
              >
                <template #option>
                  <el-option
                    v-for="item in durationOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </template>
              </NoBgSelect>
            </div>
          </el-form-item>
        </div>
        <div
          class="tw-flex tw-gap-12 tw-items-center tw-w-[40%] tw-max-w-full md:tw-w-full md:tw-gap-5"
        >
          <el-form-item
            class="tw-w-[50%] right_block"
            :class="{ 'is-error': !!errors.subTotal }"
          >
            <div class="tw-flex tw-items-center tw-w-full tw-gap-2">
              <Text variant="p">£</Text>
              <NoBgInput
                :class="{ errormsg: validCost }"
                @keypress="
                  numChecker(
                    $event,
                    estimateForm.projectStages[phaseIndex].subTotal
                  )
                "
                v-model="estimateForm.projectStages[phaseIndex].subTotal"
                @input="handleSubTotal($event)"
                :formatter="
                  (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                :parser="(value) => value.replace(/^0+(?=\d)|\$|,/g, '')"
                placeholder="Phase subtotal"
                :disabled="isAddNewTask"
              />
            </div>
          </el-form-item>
          <el-form-item class="tw-w-[50%]">
            <div class="tw-flex tw-flex-row tw-w-full">
              <NoBgInput
                :readonly="true"
                placeholder="VAT"
                v-model="state.vatComputation"
                @change="updateLabourPhaseEstimation"
              />
              <NoBgSelect
                v-model="estimateForm.projectStages[phaseIndex].vat"
                class="tw-max-w-[55px]"
                @change="handlePhaseVAT"
              >
                <template #option>
                  <el-option
                    v-for="item in vatOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </template>
              </NoBgSelect>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-justify-start">
        <div class="tw-flex tw-gap-3 tw-items-center">
          <Text
            variant="span"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            whiteSpace="nowrap"
            >Total phase cost:</Text
          >
          <LabelWithValue
            class="tw-min-w-[120px]"
            :justifyStart="true"
            :data="formattedCurrency(totalPhaseEstimateCost)"
            label=""
          />
        </div>
      </div>
    </el-form>
    <v-divider></v-divider>
    <div
      class="tw-flex tw-flex-col tw-gap-4 tw-w-full tw-border-dashed tw-border-b tw-border-0 tw-border-[#8687A5] tw-pb-4"
    >
      <div
        class="task__header tw-flex tw-gap-2 tw-items-center tw-justify-start"
      >
        <Text variant="span" whiteSpace="nowrap">Detailed Phase Tasks</Text>
        <InfoIcon />
      </div>

      <SandBoxTaskForm
        v-for="(task, index) in estimateForm.projectStages[phaseIndex]
          .projectStageTasks"
        :taskIndex="index"
        :key="index"
        :phaseIndex="phaseIndex"
        :task="task"
      />
    </div>
    <div class="tw-w-full tw-flex tw-justify-start">
      <Button
        variant="custom"
        :isCapitalize="true"
        :label="taskActionButtonLabel"
        class="!tw-w-[auto] !tw-p-0"
        :ripple="false"
        @click="addTask"
        fontSize="12px"
        fontWeight="400"
        letterSpacing="0"
        activeBgColor="#FFF"
        activeFontColor="#0C0F4A"
        width="auto"
      >
        <template #prefix>
          <PlusFilledYellowIcon />
        </template>
      </Button>
    </div>
  </section>
  <ConfirmPhaseTotal
    v-if="isShowConfirmModal"
    @on-close="onCloseModal"
    @on-confirm="onConfirmModal"
  />
</template>
<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PlusFilledYellowIcon from "@/core/components/icons/PlusFilledYellowIcon.vue";
import NoBgInput from "@/core/components/ui/inputs/NoBgInput.vue";
import NoBgSelect from "@/core/components/ui/inputs/NoBgSelect.vue";
import NoBgDatePicker from "@/core/components/ui/inputs/NoBgDatePicker.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import SandBoxTaskForm from "@/modules/jobs/components/estimates/form/SandBoxTaskForm.vue";
import estimate from "@/core/constants/estimate";
import { isNumber } from "@/core/helpers/number.helper";
import CurrencyFilter from "@/filters/currency.filter";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { getRandomColor } from "@/core/utils/common";
import moment from "moment";
import { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";
import ConfirmPhaseTotal from "@/modules/jobs/components/modal/ConfirmPhaseTotal.vue";

const store = useStore();

const emits = defineEmits(["on-update-phase-Estimation"]);

const props = defineProps({
  phaseIndex: Number,
});

const MAX_LIMIT = 99999999;
const options = [
  {
    value: estimate.LABOUR,
    label: "Labour",
  },
  {
    value: estimate.MATERIAL,
    label: "Materials",
  },
];

const durationOptions = [
  {
    value: estimate.DAYS,
    label: "Days",
  },
  {
    value: estimate.WEEKS,
    label: "Weeks",
  },
];

const vatOptions = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 0.05,
    label: "5%",
  },
  {
    value: 0.2,
    label: "20%",
  },
];

const state = reactive({
  vatComputation: null,
});

const isShowConfirmModal = ref(false);
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const taskActionButtonLabel = computed(() => {
  return estimateForm.value.projectStages[props.phaseIndex].projectStageTasks
    .length
    ? "Add another task"
    : "Add task";
});

const isAddNewTask = computed(() => {
  return estimateForm.value.projectStages[props.phaseIndex].projectStageTasks
    .length
    ? true
    : false;
});
const numChecker = (event, value) => {
  return isNumber(event, value);
};

const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const totalPhaseEstimateCost = computed(() => {
  const phase = estimateForm.value.projectStages[props.phaseIndex];
  const newValue = phase.vat
    ? parseFloat(phase.subTotal) + parseFloat(phase.subTotal) * phase.vat
    : phase.subTotal;
  return newValue;
});

const handlePhaseVAT = () => {
  updateLabourPhaseEstimation();
};
const addTask = async () => {
  const tasks =
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks;
  if (!tasks.length) {
    isShowConfirmModal.value = true;
    return;
  }

  tasks.push({
    id: null,
    name: "",
    amount: "0",
    amountToDisplay: "",
    duration: "0",
    durationType: 1,
    total: 0,
    startDate: null,
    endDate: null,
    note: "",
    taskCost: "0",
    estimationPhaseTasksProperties: [],
  });

  updateLabourPhaseEstimation();
};

const onDeletePhase = async (id) => {
  if (id) {
    try {
      await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationPhase`, {
        estimateId: id,
      });
    } catch (error) {
      console.log();
    }
  }
};
const calculateEndDate = (durationType, totalDuration, startDate) => {
  let duration = parseInt(totalDuration);
  if (duration < 1 || !startDate) return;
  const endDate = moment(new Date(startDate))
    .add(totalDuration, durationType === 1 ? "days" : "weeks")
    .format("YYYY-MM-DD");

  estimateForm.value.projectStages[props.phaseIndex].endDate = endDate;
};
const updateLabourPhaseEstimation = async () => {
  const { name, durationType, startDate, totalDuration, subTotal } =
    estimateForm.value.projectStages[props.phaseIndex];

  if (name) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      field: "name",
    });
  }

  if (startDate) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      field: "startDate",
    });
  }
  // if (!formData.value.suggestedStartDate && startDate) {
  //   ElMessage.error("Please First Select Proposed Project Start Date");
  // }

  if (totalDuration) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      field: "totalDuration",
    });
  }
  if (subTotal) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      field: "subTotal",
    });
  }
  calculateEndDate(durationType, totalDuration, startDate);
};
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const onChangeStageType = async (value) => {
  const optionType = value == "labour" ? "labour" : "materials";
  const projectStage = {
    id: null,
    name: "",
    stageType: optionType,
    totalDuration: 0,
    durationType: 1,
    stageCost: 0,
    projectStageTasks: [],
    requiredDeposit: false,
    vat: 0,
    subTotal: "0",
    subTotalDisplay: "",
    note: "",
    startDate: null,
    phaseOrder: estimateForm.value.projectStages[props.phaseIndex].phaseOrder,
    endDate: null,
    estimationPhaseProperties: [],
    color: getRandomColor(),
    attachments: [],
    user: { id: user.value?.id },
    workStation: { id: activeUserWorkstation.value?.id },
  };

  onDeletePhase(estimateForm.value.projectStages[props.phaseIndex]?.id);
  await store.dispatch(`${JOB_TEMPLATE_STORE}/setProjectStage`, {
    phaseIndex: props.phaseIndex,
    defaultProjectStageFormValue: projectStage,
  });
};
const handleSubTotal = (value) => {
  if (value) {
    if (!validCost.value) {
      estimateForm.value.projectStages[props.phaseIndex].subTotal = value;
    }
  } else {
    estimateForm.value.projectStages[props.phaseIndex].subTotal = "0";
  }
};

const validCost = computed(() => {
  const cost = estimateForm.value.projectStages[props.phaseIndex].subTotal;
  if (cost > MAX_LIMIT) {
    return true;
  }
  return false;
});

const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const errors = computed(() => {
  if (
    !store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`][
      props.phaseIndex
    ]
  ) {
    return store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
  }
  return store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`][
    props.phaseIndex
  ];
});

const onCloseModal = () => {
  isShowConfirmModal.value = false;
};
const onConfirmModal = async () => {
  const tasks =
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks;
  estimateForm.value.projectStages[props.phaseIndex].subTotal = "0";
  await store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimatePhaseCost`, {
    phaseIndex: props.phaseIndex,
    newValue: 0,
  });

  tasks.push({
    id: null,
    name: "",
    amount: "0",
    amountToDisplay: "",
    duration: "0",
    durationType: 1,
    total: 0,
    startDate: null,
    endDate: null,
    note: "",
    taskCost: "0",
    estimationPhaseTasksProperties: [],
  });

  updateLabourPhaseEstimation();

  onCloseModal();
};
onMounted(() => {
  estimateForm.value.projectStages[props.phaseIndex].phaseOrder =
    props.phaseIndex;

  // isShowConfirmModal.value = isAddNewTask.value ? false : true;
});
</script>
<style lang="scss" scoped>
.el-form-item {
  margin: 0;
}
:deep(.el-input__wrapper) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
  .el-input__prefix-inner {
    .el-icon {
      svg {
        color: #ffa500;
      }
    }
  }
}

.estimate-form__wrapper {
  :deep(.el-form) {
    .el-form-item {
      .el-form-item__content {
        border-bottom: 1px solid rgba(12, 15, 74, 0.2);
        .el-select {
          .el-select__wrapper {
            .el-select__selection {
              .el-select__selected-item {
                span {
                  @include fluidFont(12, 12, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                }
              }
            }
            .el-select__suffix {
              .el-icon.el-select__caret {
                color: rgba($orange, 1);
              }
            }
          }
        }
        .el-input {
          .el-input__wrapper {
            input {
              @include fluidFont(12, 12, 1.2);
              font-weight: 500;
              color: rgba($blueDark, 1);
            }
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 500;
              color: rgba($blueDark, 1);
            }
          }
        }
      }
    }
  }
}
.errormsg {
  border: 1px solid red;
}

.is-error input,
.is-error select {
  border-color: red;
}
.right_block {
  :deep(.el-form-item__content) {
    .is-disabled {
      .el-input__wrapper {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}
.task__header {
  padding-bottom: 16px;
  border-bottom: 1px dashed rgba(134, 135, 165, 0.5);
}
</style>
