<template>
  <Card class="tw-py-5 tw-px-4 tw-max-w-full">
    <template #content>
      <main class="tw-flex tw-flex-col tw-gap-5 tw-h-full">
        <!-- Header -->
        <section
          class="card_title tw-flex tw-items-center tw-justify-between lg:tw-flex-col lg:tw-gap-4 2xl:tw-items-start"
        >
          <div
            class="tw-flex tw-gap-4 tw-items-center 2xl:tw-flex-col 2xl:tw-items-start"
          >
            <Text variant="h5" whiteSpace="nowrap">Your Estimate Summary</Text>
            <EstimateBadge
              :badge="estimateBadge"
              class="lg:tw-hidden"
              bgColor="#6B5F4B"
            />
          </div>
          <div
            class="tw-flex tw-gap-4 tw-items-center lg:tw-justify-between lg:tw-items-start lg:tw-w-full"
          >
            <!-- <EstimateBadge
              :badge="estimateBadge"
              class="tw-hidden lg:tw-block"
              bgColor="#6B5F4B"
            /> -->
            <Button
              variant="custom"
              :label="isShowChart ? 'PROJECT DETAILS' : 'PROJECT TIMELINE'"
              height="30px"
              borderColor="#FFA500"
              activeBgColor="#FFF"
              activeFontColor="#FFA500"
              width="auto"
              fontSize="12px"
              @click="toggleChartComponent"
            >
              <template #prefix>
                <TimelineIcon />
              </template>
            </Button>
          </div>
        </section>

        <!-- Estimate Timeline -->
        <GanttChart
          v-if="isShowChart && getTradeEstimateDetails"
          :estimateData="getTradeEstimateDetails"
          :isEditable="false"
        />
        <!-- Estimate Summary -->
        <section
          class="estimate__content tw-flex tw-flex-col tw-gap-4 tw-h-full tw-justify-between tw-pb-4"
          v-if="!isShowChart && getTradeEstimateDetails"
        >
          <div class="tw-flex tw-flex-col tw-gap-4 tw-h-full">
            <EstimateSummaryData
              :selectedEstimate="getTradeEstimateDetails"
              :isReadOnly="true"
              v-if="!isClientRaiseEstimate"
            />
            <v-divider v-if="!isClientRaiseEstimate" :thickness="2"></v-divider>
            <v-expansion-panels>
              <main
                v-if="
                  getTradeEstimateDetails?.estimationIBGData?.length &&
                  getTradeEstimateDetails?.estimationIBGData[0]
                    ?.estimationIbgPolicies?.length
                "
                class="tw-w-full tw-p-[10px] tw-border-[1px] tw-border-solid tw-border-[#dfdfdf] tw-rounded-lg tw-flex tw-items-center tw-justify-between tw-max-w-full tw-w-[calc(100%_-_35px)] md:tw-flex-col md:tw-items-start md:tw-w-full"
              >
                <PhaseChip
                  phaseType="Insurance Backed Gurantee Policy"
                  phaseNumber=""
                />
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-pr-2 tw-flex-wrap"
                >
                  <div
                    class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
                  >
                    <Text variant="span" lineHeight="16px">{{
                      getTradeEstimateDetails?.estimationIBGData[0]
                        ?.estimationIbgPolicies?.length || 0
                    }}</Text>
                    <Text
                      lineHeight="16px"
                      variant="span"
                      textWeight="400"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >Policy</Text
                    >
                  </div>
                  <div
                    class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
                  >
                    <Text variant="span" lineHeight="16px">{{
                      currencyFilter.formatToCurrency(ibgPolicyTotal)
                    }}</Text>
                  </div>
                </div>
              </main>
              <template
                v-if="
                  getTradeEstimateDetails?.estimationIBGData?.length &&
                  getTradeEstimateDetails?.estimationIBGData[0]
                    ?.estimationIbgPolicies?.length
                "
              >
                <template
                  v-for="(policy, index) in getTradeEstimateDetails
                    ?.estimationIBGData[0]?.estimationIbgPolicies"
                  :key="policy.id"
                >
                  <PhaseReceivedItem
                    v-for="(phase, subindex) in policy.estimationPhase"
                    :key="subindex"
                    :isPolicyPhase="true"
                    :phase="phase"
                    :policyIndex="index + 1"
                    :phaseIndex="subindex"
                    :grantEstimateDetails="grantEstimateDetails"
                  />
                </template>
              </template>
              <PhaseReceivedItem
                v-for="(
                  phase, index
                ) in getTradeEstimateDetails.estimationPhase"
                :key="index"
                :isPolicyPhase="false"
                :phase="phase"
                :phaseIndex="index"
                :grantEstimateDetails="grantEstimateDetails"
              />
            </v-expansion-panels>
          </div>
          <section
            v-if="!isClientRaiseEstimate"
            class="tw-flex tw-flex-col tw-gap-4"
          >
            <v-divider :thickness="2"></v-divider>
            <EstimateReceivedTotal
              :totalSubTotal="totalSubTotal"
              :totalVat="totalVat"
              :ibgPolicyTotal="ibgPolicyTotal"
              :serviceCharges="serviceCharges"
              :grantName="getTradeEstimateDetails?.grantName"
              :grantAmount="getTradeEstimateDetails?.grantAmount"
            />
          </section>
        </section>
      </main>
    </template>
  </Card>
</template>

<script setup>
import Card from "@/core/components/ui/general/Card.vue";
import Button from "@/core/components/ui/general/Button";
import Text from "@/core/components/ui/general/Text";
import TimelineIcon from "@/core/components/icons/TimelineIcon";
import InitialEstimateChip from "@/core/uni-components/Chips/InitialEstimateChip.vue";
import EstimateSummaryData from "@/modules/jobs/components/estimates/EstimateSummaryData.vue";
import EstimateReceivedTotal from "@/modules/project/components/common/EstimateReceivedTotal";
import PhaseReceivedItem from "@/modules/project/components/common/PhaseReceivedItem";
import { computed } from "vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { useStore } from "vuex";
import { ref, watch } from "vue";
import GanttChart from "@/modules/jobs/components/estimates/GanttChart.vue";
import EstimateBadge from "@/core/uni-components/EstimateBadge.vue";
import {
  EstimatePhaseType,
  EstimateTypeForDeposit,
} from "@/core/enums/estimateEnum";
import currencyFilter from "@/filters/currency.filter";
import PhaseChip from "@/core/uni-components/Chips/PhaseChip.vue";
const isShowChart = ref(false);
const props = defineProps([
  "selectedEstimate",
  "isClientRaiseEstimate",
  "isShowGanttChart",
]);
const store = useStore();
const emits = defineEmits(["onToggleChart"]);
const getTradeEstimateDetails = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/getTradeEstimateDetails`]
);
const totalPhaseTasks = computed(
  () =>
    getTradeEstimateDetails.value.estimationPhase.filter(
      (phase) => phase.phaseType === EstimatePhaseType.LABOUR
    ).length
);

const grantEstimateDetails = computed(() => {
  const grantAmount = getTradeEstimateDetails.value.grantAmount;

  return {
    id: getTradeEstimateDetails.value.id,
    grantType: getTradeEstimateDetails.value.grantType,
    grantName: getTradeEstimateDetails.value.grantName,
    grantAmount: getTradeEstimateDetails.value.grantAmount,
    lockState: getTradeEstimateDetails.value.lockState,
    suggestedStartDate: getTradeEstimateDetails.value.suggestedStartDate,
    agreeTermsConsumerDisclosure:
      getTradeEstimateDetails.value.agreeTermsConsumerDisclosure,
    depositType: getTradeEstimateDetails.value.depositType,
    totalEstimateCost: getTradeEstimateDetails.value.totalEstimateCost,

    grantDiscountTotal: parseFloat(grantAmount / totalPhaseTasks.value).toFixed(
      2
    ),
  };
});

const toggleChartComponent = () => {
  isShowChart.value = !isShowChart.value;
  emits("onToggleChart", isShowChart.value);
};

const ibgPolicyTotal = computed(() => {
  return (
    getTradeEstimateDetails.value?.estimationIBGData[0]?.estimationIbgPolicies?.reduce(
      (sum, phase) => sum + (parseFloat(phase?.totalPolicyCost) || 0),
      0
    ) || 0
  );
});

const ibgPolicyPhasesTotal = computed(() => {
  return (
    getTradeEstimateDetails.value?.estimationIBGData[0]?.estimationIbgPolicies
      ?.flatMap((phase) => phase?.estimationPhase || [])
      ?.reduce((sum, item) => sum + (parseFloat(item.subTotal) || 0), 0) || 0
  );
});

const totalSubTotal = computed(() => {
  const sum = getTradeEstimateDetails.value.estimationPhase.reduce(
    (sum, phase) => {
      const roundedSubTotal = parseFloat(phase.subTotal);
      return sum + roundedSubTotal;
    },
    0
  );

  return Math.round((sum + ibgPolicyPhasesTotal.value) * 100) / 100;
});

const totalVat = computed(() => {
  let estimatePhasesVat = getTradeEstimateDetails.value.estimationPhase.reduce(
    (sum, phase, index) => {
      const roundedTotalVat = parseFloat(phase.subTotal * phase.vat);
      return sum + roundedTotalVat;
    },
    0
  );

  let ibgPhasesVat =
    getTradeEstimateDetails.value?.estimationIBGData[0]?.estimationIbgPolicies
      ?.flatMap((phase) => phase?.estimationPhase || [])
      ?.reduce(
        (sum, item) =>
          sum + (parseFloat(item.subTotal) || 0) * (parseFloat(item.vat) || 0),
        0
      ) || 0;

  return Math.round((estimatePhasesVat + ibgPhasesVat) * 100) / 100;
});

const serviceCharges = computed(() => {
  // 5 pound
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03; // per phase 3 % charge
  let totalWithServiceFee =
    getTradeEstimateDetails.value.estimationPhase.reduce(
      (sum, phase, index) => {
        const roundedTotalWithServiceFee = parseFloat(
          phase.subTotal * serviceFeePercentage
        );
        return sum + roundedTotalWithServiceFee;
      },
      0
    );

  let ibgServiceCharge =
    getTradeEstimateDetails.value?.estimationIBGData[0]?.estimationIbgPolicies
      ?.flatMap((phase) => phase?.estimationPhase || [])
      ?.reduce((sum, item) => {
        const subTotal = parseFloat(item.subTotal) || 0;
        return sum + subTotal * serviceFeePercentage;
      }, 0) || 0;

  totalWithServiceFee += initialProjectCharge + ibgServiceCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});

const estimateBadge = computed(() => {
  if (
    props.selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Initial_Estimate
  )
    return "Initial Estimate";
  else if (
    props.selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Fixed_Cost_Estimation
  )
    return "Fixed Cost Estimation";
  else if (
    props.selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Variable_Cost_Estimation
  )
    return "Variable Cost Estimation";
  else return "";
});
watch(
  () => props.isShowGanttChart,
  (value) => {
    isShowChart.value = value;
  }
);
</script>

<style lang="scss" scoped>
.v-expansion-panels {
  gap: 16px;
}
:deep(.v-expansion-panels):not(.v-expansion-panels--variant-accordion)
  > :first-child:not(:last-child):not(.v-expansion-panel--active):not(
    .v-expansion-panel--before-active
  ) {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  box-shadow: 0px 0px 8px 0px #0c0f4a14 !important;
}

.phase__accordion {
  .phase-received-item {
    border: 1px solid rgba($bordercolor, 1);
    :deep(.v-expansion-panel__shadow) {
      display: none;
    }
    .v-expansion-panel-title {
      box-shadow: none !important;
      padding: 8px;
      min-height: auto;
    }
  }
  :deep(.v-expansion-panel-text) {
    .v-expansion-panel-text__wrapper {
      padding: 12px 8px;
      main {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        .phase-received-item__task-item {
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: flex-start;
        }
      }
    }
  }
}
</style>
