<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="600px"
    max-width="100%"
    :className="['setting_up_preview_modal']"
  >
    <template v-slot:body>
      <div class="modal_content">
        <v-icon
          icon="mdi-close"
          class="close_icon"
          @click="onClickCloseButton"
        />
        <h4 class="r2xl:!tw-max-w-[61%]">
          Wow, 🤩<br />
          that was quick! 🥳
        </h4>
        <img src="../../../../assets/images/verify-ws.png" alt="" />
        <p>
          Looks like you have completed all necessary steps for your<br />
          workstation setup. If you are happy with all the details you<br />
          have provided then, submit your workstation for verification
        </p>

        <div
          class="tw-grid tw-gap-4 tw-grid-cols-2 tw-w-full s540:!tw-flex s540:!tw-flex-col-reverse"
        >
          <Button
            @click="onClickCloseButton"
            variant="secondary"
            label="Cancel"
          ></Button>
          <Button
            @click="onClickSubmitVerification"
            variant="accent"
            label="verify WORKSTATION"
          ></Button>
        </div>

        <!-- <v-btn
          variant="elevated"
          class="button button-orange w-full"
          @click="onClickSubmitVerification"
        >
          verify WORKSTATION
        </v-btn>
        <v-btn variant="plain" class="blue-text" color="blue">cancel</v-btn> -->
        <!-- <div class="header">
          <v-btn
            variant="text"
            icon="mdi-close"
            @click="onClickCloseButton"
          ></v-btn>
        </div>
        <div class="modal_content_main">
          <div class="title">Submit Your Workstation for Verification</div>
          <div class="sub-title">
            Once all of the required sections have been completed and you are
            happy with the details provided, don’t forget to click on the
            <p>“SUMBIT FOR VERIFICATION”</p>
            button, so our team of experts can review the information and get
            you going on iknowa platform. Although, we try our best to do this
            on a maximum speed, this process may take up to 72h
          </div>
          <div class="title_img">
            <img
              src="@/assets/images/workstation-for-verification-preview.png"
            />
          </div>
        </div>
        <div class="modal_footer_button">
          <v-btn
            variant="elevated"
            class="button button-orange w-full"
            @click="onClickSubmitVerification"
          >
            SUMBIT FOR VERIFICATION
          </v-btn>
        </div> -->
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import Button from "@/core/components/ui/general/Button.vue";

export default {
  components: {
    CommonDialog,
    Button,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const onClickSubmitVerification = async () => {
      try {
        if (activeUserWorkstation.value.subscription === null) {
          store.commit(`${WORKSTATION}/setIsSubmitWsForVerification`, true);
        } else {
          const data = {
            userId: user.value?.id,
            userWorkstationId: activeUserWorkstation.value?.id,
            formData: {
              verificationStatus:
                UserVerificationStatusEnum.PENDING_VERIFICATION,
            },
          };
          await store.dispatch(
            `${WORKSTATION}/updateUserWorkstationStatus`,
            data
          );
          store.commit(
            `${WORKSTATION}/setIsWsDirectlySubmittedForVerification`,
            true
          );
        }
      } catch (error) {
        console.log();
      } finally {
        ctx.emit("onClickCloseTab");
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    return {
      onClickCloseButton,
      onClickSubmitVerification,
    };
  },
};
</script>
