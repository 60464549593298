<template>
  <!-- <GeneralDialog
    @on-close="onCloseModal"
    class="tw-w-[448px]"
    :headerTitle="file?.originalName"
    width="620px"
  >
    <template #body>
      <div class="header-title">Your Account is Currently Frozen</div>
      <div class="welcome__image">
        <img src="../../../../assets/images/restricted-area-2.png" alt="" />
      </div>
      <div class="header-sub-title">
        It appears that your workstation has been verified but your user
        insurance has expired. Please contact support or renew your insurance to
        regain access.
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button variant="secondary" label="Cancel" @click="onCloseModal" />
      </div>
    </template>
  </GeneralDialog> -->
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="465px"
    max-width="100%"
    height="auto"
    :className="['restricted__modal_box']"
    v-if="!isShowInsuranceModal"
  >
    <template v-slot:body>
      <div
        class="dashboard-welcome__close !tw-top-8 !tw-right-8"
        @click="onCloseModal"
      >
        <v-icon icon="mdi-close"></v-icon>
      </div>
      <div class="header-title">
        Hey, <b>{{ user?.firstName }}.</b> Your Account Frozen - due to
        insurance.
      </div>

      <div class="header-sub-title">
        Unfortunately your insurance cover has expired. Please update this, as
        soon as possible and re-verify to un-freeze your account.
      </div>
      <div class="welcome__image">
        <img src="../../../../assets//images/restricted-area-3.png" alt="" />
      </div>
    </template>
    <template v-slot:footer>
      <Button
        variant="accent"
        label="Update Insurance"
        @click="onClickAddNewInsurance"
        width="auto"
        class="button"
    /></template>
  </CommonDialog>
  <!-- <InsuranceModel
    v-if="isShowInsuranceModal"
    @onClickCloseTab="onClickCloseModel"
  /> -->
  <AddInsuranceModal
    v-if="isShowInsuranceModal"
    @onClickCloseTab="onClickCloseModel"
  />
</template>

<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import AddInsuranceModal from "@/modules/insurance/components/AddInsuranceModal.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "../../../../store/modules/user/index";

const store = useStore();
const isShowInsuranceModal = ref(false);

const emits = defineEmits(["on-close"]);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const onCloseModal = () => {
  emits("on-close");
};

const onClickAddNewInsurance = () => {
  isShowInsuranceModal.value = true;
};
const onClickCloseModel = () => {
  isShowInsuranceModal.value = false;
  onCloseModal();
};
</script>

<style scoped>
/* Optional: Additional custom styling */
</style>
