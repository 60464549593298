<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['money_out_e_wallet_modal']"
    width="570px"
    height="auto"
    v-if="!isShowTransactionSuccessfulModal"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>Money out your e-wallet</h4>
          <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
        </div>
        <div class="modal_form">
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Name</label>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.name"
              v-model="userInformation.name"
              variant="outlined"
              placeholder="Enter Name"
            ></v-text-field>
          </div>
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Account Number</label>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.accountNumber"
              v-model="userInformation.accountNumber"
              variant="outlined"
              placeholder="Enter Account Number"
            ></v-text-field>
          </div>
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Sort code</label>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.sortCode"
              v-model="userInformation.sortCode"
              variant="outlined"
              placeholder="Enter Sort code"
            ></v-text-field>
          </div>
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Reference</label>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.reference"
              v-model="userInformation.reference"
              variant="outlined"
              placeholder="Enter Reference"
            ></v-text-field>
          </div>
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Enter Money Amount</label>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.amount"
              v-model="formattedHourlyRate"
              @blur="formatHourlyRate"
              variant="outlined"
              placeholder="1000.00"
              ><div>£</div></v-text-field
            >
            <p>
              Available balance
              <span class="text-orange">£{{ userAvailableBalance }}</span>
            </p>
          </div>
        </div>
        <div class="notes__wrapper tw-pb-4">
          <p>
            By agreeing and continuing you consent to us requesting funds from
            your selected bank and sharing your account details with them.
            <span>Terms & conditions apply.</span> You will be redirected to
            your bank app for payment.
          </p>
        </div>
        <!-- <v-btn
          @click="onClickSaveButton"
          :disabled="isSaveButtonDisable"
          class="button button-orange"
          >Agree & Continue</v-btn
        > -->
        <Button
          variant="accent"
          label="Agree & Continue"
          @click="onClickSaveButton"
          :disabled="isSaveButtonDisable"
        />
      </div>
    </template>
  </CommonDialog>
  <TransactionSuccessfulModal
    v-if="isShowTransactionSuccessfulModal"
    :balance="userInformation?.amount"
    :accountNumber="userInformation?.accountNumber"
    @closeMoneyOutSuccessfullyModal="closeMoneyOutSuccessfullyModal"
  />
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import UserService from "@/core/services/user.service";
import { useStore } from "vuex";
import { computed, ref, onBeforeMount } from "vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import TransactionSuccessfulModal from "@/modules/e-wallet/components/TransactionSuccessfulModalComponent.vue";
import { E_WALLET_STORE } from "@/store/modules/e-wallet";
import Button from "@/core/components/ui/general/Button.vue";

export default {
  components: {
    CommonDialog,
    TransactionSuccessfulModal,
    Button,
  },
  props: {
    availableBalance: {
      type: String,
    },
  },
  emits: ["closeMoneyOutModal"],
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userInformation = ref({
      name: null,
      accountNumber: null,
      sortCode: null,
      reference: null,
      amount: null,
    }) as any;

    const userAvailableBalance = ref();

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const isShowTransactionSuccessfulModal = ref(false);

    const closeMoneyOutSuccessfullyModal = () => {
      isShowTransactionSuccessfulModal.value = false;
      ctx.emit("closeMoneyOutModal");
    };

    const isSaveButtonDisable = computed(
      () =>
        !(
          userInformation.value?.name &&
          userInformation.value?.accountNumber &&
          userInformation.value?.sortCode &&
          userInformation.value?.reference &&
          userInformation.value?.amount &&
          parseFloat(userInformation.value?.amount) <=
            userAvailableBalance.value
        )
    );

    const validation = ref({
      name: [(v: string) => !!v || "Name is required"],
      accountNumber: [
        (v: string) => {
          if (!v) {
            return "Account Number is required";
          }
          if (!/^\d{8}$/.test(v)) {
            return "Please enter a valid 8-digit account number.";
          }
          return (
            /^[0-9]+(\.[0-9]{1,2})?$/.test(v) || "Please enter a valid number."
          );
        },
      ],
      sortCode: [
        (v: string) => {
          if (!v) {
            return "Code is required";
          }
          if (!/^\d{6}$/.test(v)) {
            return "Please enter a valid 6-digit account number.";
          }
          return (
            /^[0-9]+(\.[0-9]{1,2})?$/.test(v) || "Please enter a valid number."
          );
        },
      ],
      reference: [
        (v: string) => {
          if (!v) return "Reference is required";
          if (v.length < 6) return "Reference must be at least 6 letters long";
          if (v.length > 16)
            return "Reference must be no more than 16 letters long";
          return true;
        },
      ],
      amount: [
        (v: string) => {
          if (!v) {
            return "";
          }
          if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(v)) {
            return "Please enter a valid number.";
          }
          if (parseFloat(v) > userAvailableBalance.value) {
            return "Balance is not available.";
          }
          return true;
        },
      ],
    });

    const formattedHourlyRate = ref(
      userInformation.value.amount
        ? parseFloat(userInformation.value.amount).toFixed(2)
        : ""
    );

    const formatHourlyRate = () => {
      formattedHourlyRate.value = parseFloat(formattedHourlyRate.value).toFixed(
        2
      );
      userInformation.value.amount = formattedHourlyRate.value;
    };

    const onClickSaveButton = async () => {
      try {
        const userInformationValue = {
          ...userInformation.value,
        };
        userInformationValue.amount = Number(
          parseFloat(userInformationValue.amount).toFixed(2)
        );

        const response = await store.dispatch(
          `${E_WALLET_STORE}/moneyOutInformation`,
          userInformationValue
        );
        await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
        isShowTransactionSuccessfulModal.value = true;
      } catch (err) {
        console.log();
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("closeMoneyOutModal");
    };

    onBeforeMount(async () => {
      userAvailableBalance.value = parseFloat(props?.availableBalance);
    });

    return {
      user,
      onClickSaveButton,
      userInformation,
      onClickCloseButton,
      validation,
      isSaveButtonDisable,
      formatHourlyRate,
      formattedHourlyRate,
      isShowTransactionSuccessfulModal,
      closeMoneyOutSuccessfullyModal,
      userAvailableBalance,
    };
  },
};
</script>
