<template>
  <main class="md:!tw-p-4 md:!tw-overflow-y-auto md:!tw-h-full">
    <div class="rllg:tw-max-w-[705px] tw-px-4 tw-w-full tw-mx-auto md:!tw-px-0">
      <div
        class="tw-text-left rsm:tw-text-center tw-mb-8 rsm:tw-mb-14 tw-grid tw-gap-3 rsm:tw-gap-10"
      >
        <Text variant="h4" class="!leading-normal rsm:!tw-text-[32px]"
          >Welcome
          <Text
            v-if="userName"
            variant="h4"
            class="!leading-normal rsm:!tw-text-[32px]"
            :isCapitalize="true"
            >,&nbsp;{{ userName }}</Text
          >
          👋</Text
        >
        <Text
          variant="h4"
          textWeight="500"
          class="!leading-normal rsm:!tw-text-[32px]"
          >{{ header }}</Text
        >
      </div>

      <div
        class="tw-max-w-[650px] tw-w-full tw-mx-auto tw-grid rsm:tw-grid-cols-2 tw-gap-4 rsm:tw-gap-12"
        @keydown.enter="onClickContinue"
        tabindex="0"
      >
        <div
          v-for="option of selectionOptions"
          :key="option?.id"
          @click="onSelectRole(option.value)"
          :class="{
            'tw-border-[#3366FF] tw-border-solid tw-border-[3px]':
              selectedRole === option.value,
          }"
          class="onboarding-card tw-w-full tw-bg-white tw-min-h-[236px] rsm:tw-min-h-[300px] tw-rounded-[16px] tw-text-center tw-bg-transparent tw-p-8 rsm:py-10 rsm:tw-px-6 tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-6 tw-cursor-pointer"
        >
          <component
            :is="option.component"
            class="tw-max-w-[200px] tw-h-[100px] rsm:tw-h-[120px]"
          />
          <div class="tw-flex tw-flex-col tw-gap-[.4rem]">
            <Text variant="h6" class="rsm:!tw-text-[20px]" textAlign="center"
              >{{ option.title }}
            </Text>
            <Text
              variant="span"
              textWeight="500"
              class="rllg:!tw-text-[14px]"
              textColor="rgba(12, 15, 74, 0.5)"
              lineHeight="16px"
              >{{ option.description }}
            </Text>
          </div>
        </div>
      </div>
      <div
        class="tw-w-full tw-flex tw-items-center tw-justify-center tw-mt-5 rsm:tw-mt-8"
      >
        <Button
          class="rsm:!tw-w-auto rsm:!tw-min-w-[400px]"
          label="continue"
          variant="accent"
          :disabled="!selectedRole"
          @click="onClickContinue"
        ></Button>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { RolesEnum } from "@/core/enums/RolesEnum";

const props = defineProps({
  userName: { type: String },
  header: { type: String },
  selectionOptions: {
    type: Array<{
      id: number;
      component: any;
      title: string;
      description: string;
      value: RolesEnum | BusinessDetailTypeEnum;
    }>,
  },
});
const emits = defineEmits(["onContinue"]);
const selectedRole: any = ref("");
const onSelectRole = (role: RolesEnum | BusinessDetailTypeEnum) => {
  if (selectedRole.value === role) {
    selectedRole.value = "";
  } else {
    selectedRole.value = role;
  }
};

const onClickContinue = () => {
  emits("onContinue", selectedRole.value);
};
</script>
<style lang="scss" scoped>
.onboarding-card {
  box-shadow: 0px 0px 42px 0px rgba(0, 0, 0, 0.08);
}
</style>
