<template>
  <div v-if="isLoading">
    <Loader :show="isLoading" class="tw-z-10" />
  </div>
  <div
    @click="onRouteToTradePassport"
    class="tw-flex tw-items-center tw-gap-[.1rem] tw-cursor-pointer tw-pb-4 tw-w-full"
  >
    <v-icon icon="mdi-chevron-left"></v-icon>
    <Text variant="p" textWeight="600">Back</Text>
  </div>
  <div
    v-if="!isLoading && tradePassportDbs?.length"
    class="tw-max-w-[1300px] tw-w-full md:!tw-pb-[4rem] md:!tw-box-border"
  >
    <div
      class="tw-w-full tw-rounded-lg tw-bg-[#F8FBFD] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-3"
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-pb-3 tw-box-border tw-gap-4 tw-border-b-dashed-custom"
      >
        <div class="tw-flex tw-flex-col tw-gap-2 tw-justify-start">
          <Text
            variant="h6"
            class="rmd:!tw-text-[22px]"
            lineHeight="18px"
            textAlign="left"
            >DBS (Police Check)</Text
          >
          <Text
            variant="p"
            textWeight="600"
            textColor="rgba(12, 15, 74, 0.8)"
            lineHeight="18px"
            textAlign="left"
            class="md:!tw-hidden"
            >{{ tradePassportDbs?.length }} Results</Text
          >
        </div>
        <Button
          v-if="!isUserProprtyOwner"
          variant="secondary"
          label="+Add"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          @click="onOpenDbsModal('add')"
        />
      </div>
      <div class="tw-flex tw-flex-col tw-w-full">
        <!-- items loop here -->
        <div
          v-for="(dbs, index) in tradePassportDbs"
          :key="index"
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-4 tw-box-border"
          :class="{
            'tw-border-b-dashed-custom': index !== tradePassportDbs.length - 1,
            '!tw-pt-0': index === 0,
          }"
        >
          <div class="tw-flex tw-items-start tw-gap-3 tw-w-full md:!tw-gap-2">
            <div
              class="tw-min-w-[68px] tw-w-[68px] tw-h-[68px] tw-rounded-[4.85px] tw-bg-[rgba(12,15,74,0.04)] tw-border-solid tw-border-[#DFDFDF] tw-border-[1px] tw-overflow-hidden md:!tw-min-w-[55px] md:!tw-w-[55px] md:!tw-h-[55px]"
            >
              <iframe
                v-if="dbs?.imageUrl?.toLowerCase().endsWith('.pdf')"
                :src="
                  'https://docs.google.com/gview?url=' +
                  dbs?.imageUrl +
                  '&embedded=true'
                "
                class="tw-w-full tw-h-full tw-object-cover"
              ></iframe>
              <img
                v-else
                :src="dbs?.imageUrl"
                class="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
            <div
              class="tw-w-full tw-flex tw-items-start tw-justify-between tw-gap-1"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-[.1rem] tw-items-start"
              >
                <div class="tw-flex tw-items-center tw-gap-2">
                  <Text
                    variant="h6"
                    textAlign="left"
                    lineHeight="18px"
                    class="tw-w-[70%] md:!tw-text-[.8rem]"
                    >DBS Document {{ index + 1 }}</Text
                  >
                  <PendingChip
                    class="tw-w-[auto]"
                    label="Pending Verification"
                    v-if="
                      dbs?.userVerificationStatus ===
                      UserVerificationStatusEnum.PENDING_VERIFICATION
                    "
                  />

                  <AcceptChip
                    class="tw-w-[auto]"
                    label="Accepted"
                    v-if="
                      dbs?.userVerificationStatus ===
                        UserVerificationStatusEnum.VERIFIED &&
                      !isUserProprtyOwner
                    "
                  />
                  <AcceptChip
                    class="tw-w-[auto]"
                    label="Verified"
                    v-if="
                      dbs?.userVerificationStatus ===
                        UserVerificationStatusEnum.VERIFIED &&
                      isUserProprtyOwner
                    "
                  />
                  <RejectChip
                    class="tw-w-[auto]"
                    label="Rejected"
                    v-if="
                      dbs?.userVerificationStatus ===
                      UserVerificationStatusEnum.REJECTED
                    "
                  />
                </div>
                <Text
                  variant="p"
                  textColor="rgba(12, 15, 74, 0.8)"
                  lineHeight="18px"
                  class="md:!tw-text-[.7rem]"
                  v-if="dbs?.metadata?.issueDate"
                >
                  Issue Date:
                  {{
                    appFilters.formatToDate(
                      dbs?.metadata?.issueDate,
                      "DD MMMM YYYY"
                    )
                  }}</Text
                >
              </div>
              <div
                class="tw-flex tw-items-center tw-gap-2 md:!tw-gap-1"
                v-if="getUserWorkStationFieldAccess"
              >
                <BlueCircularPencilIcon
                  @click="onOpenDbsModal('edit', dbs)"
                  class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
                  v-if="!isUserProprtyOwner"
                />
                <GrayCircularTrashIcon
                  class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
                  @click="onOpenDeleteModal(dbs.id)"
                  v-if="!isUserProprtyOwner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="!isLoading && !tradePassportDbs?.length"
    class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto tw-w-full"
  >
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img md:!tw-w-[50%]">
        <img
          :src="require('../../../assets/images/trade_insurance_empty.png')"
          alt=""
          class="tw-w-full"
        />
      </div>

      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any DBS Check yet!</h4>
        <p>
          You haven't added any DBS Check details yet. Upload your DBS Check to
          build trust and meet industry standards."
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any DBS Check yet!</h4>
        <p>haven't added any DBS Check details to this profile yet</p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          @click="onOpenDbsModal('add')"
          v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
          class="button button-purple"
          width="100%"
          >Add New DBS Check</v-btn
        >
      </div>
    </div>
  </div>

  <!-- MODAL -->
  <AddOrEditDbsCheckModal
    v-if="showAddorEditDbsModal"
    @on-close="onCloseDbsModal"
    @on-success="initialize"
    :dbsModalType="dbsModalType"
    :selectedDbsData="selectedDbsData"
  />

  <ConfirmDeleteDbsModal
    v-if="showConfirmDeleteMoal"
    :id="selectedId"
    @on-close="onCloseDeleteModal"
    @on-success="onSuccessDelete"
  />
</template>
<script setup>
import { computed, onMounted, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { USER_STORE } from "@/store/modules/user";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { TRADE_PASSPOSRT_ROUTE } from "../routes";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import GrayCircularTrashIcon from "@/core/components/icons/GrayCircularTrashIcon.vue";
import AddTradePassportClientModal from "./modals/AddTradePassportClientModal.vue";
import Loader from "@/core/components/common/Loader.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import ConfirmDeleteDbsModal from "./modals/ConfirmDeleteDbsModal.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import appFilters from "@/filters";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import AddOrEditDbsCheckModal from "./modals/AddOrEditDbsCheckModal.vue";

const store = useStore();
const router = useRouter();
const internalInstance = getCurrentInstance();

const showAddorEditDbsModal = ref(false);
const isLoading = ref(false);
const showConfirmDeleteMoal = ref(false);
const selectedId = ref(null);
const dbsModalType = ref("");
const selectedDbsData = ref(null);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const tradePassportDbs = computed(() => {
  return store.getters[`${USER_STORE}/userWorkstationDbs`];
});

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);
const contractorTradePassportWorkstationId = computed(
  () =>
    store.getters[
      `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
    ]
);
const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const getUserWorkStationFieldAccess = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
);

const onOpenDbsModal = (type, dbs = null) => {
  if (dbs) {
    selectedDbsData.value = dbs;
  }
  dbsModalType.value = type;
  showAddorEditDbsModal.value = true;
};

const onCloseDbsModal = () => {
  selectedDbsData.value = null;
  showAddorEditDbsModal.value = false;
};

const onOpenDeleteModal = (id) => {
  selectedId.value = id;
  showConfirmDeleteMoal.value = true;
};

const onCloseDeleteModal = () => {
  showConfirmDeleteMoal.value = false;
};

const onSuccessDelete = async () => {
  selectedId.value = null;
  await initialize();
  onCloseDeleteModal();
  // displayToast here
  displayToastMessage(internalInstance, "Deleted Successfully", "success");
};

const onRouteToTradePassport = async () => {
  await router.push({ name: TRADE_PASSPOSRT_ROUTE });
};

const initialize = async () => {
  try {
    isLoading.value = true;
    if (user.value?.id) {
      await store.dispatch(
        `${USER_STORE}/getAllTradePassportDbs`,
        user.value?.id
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
    console.log(tradePassportDbs.value, "tradePassportDbs");
  }
};

onBeforeMount(async () => {
  await initialize();
});
</script>
<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
</style>
