<template>
  <svg
    width="68"
    height="62"
    viewBox="0 0 68 62"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1357 21.8115C25.1357 24.3477 23.0475 26.4038 20.4716 26.4038C17.8953 26.4038 15.8071 24.3477 15.8071 21.8115C15.8071 19.2748 17.8953 17.2188 20.4716 17.2188C23.0475 17.2188 25.1357 19.2748 25.1357 21.8115Z"
      fill="#fff"
    />
    <path
      d="M54.9899 10.3359H13.0107C9.15328 10.3359 6.01416 13.4268 6.01416 17.2248V44.7803C6.01416 44.9089 6.04662 45.0306 6.05387 45.1593C6.02099 45.4347 6.03509 45.7126 6.13758 45.9746C6.71365 49.2032 9.56837 51.6693 13.0107 51.6693H54.9899C58.8472 51.6693 61.9864 48.5783 61.9864 44.7803V17.2248C61.9864 13.4268 58.8472 10.3359 54.9899 10.3359ZM13.0107 14.9287H54.9899C56.2747 14.9287 57.3219 15.9596 57.3219 17.2248V35.4552L45.0548 23.3766C43.4596 21.8058 40.8639 21.8058 39.2712 23.3766L28.17 34.3069L25.2311 31.4139C23.6361 29.8429 21.0402 29.8429 19.4474 31.4139L10.6787 40.0476V17.2248C10.6787 15.9596 11.7257 14.9287 13.0107 14.9287Z"
      fill="##fff"
    />
  </svg>
</template>
<script>
export default {
  name: "AttachmentUploadIcon",
};
</script>
