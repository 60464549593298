<template>
  <div
    class="tw-w-auto tw-py-1 tw-px-3 tw-box-border tw-flex tw-items-center tw-gap-2 tw-rounded-lg tw-border-[2px] tw-border-[#FFA500] tw-border-solid"
  >
    <img src="@/assets/icons/unverified-icon.svg" />
    <Text variant="xsmall" whiteSpace="nowrap">{{ props.label }}</Text>
  </div>
</template>
<script setup>
import Text from "../ui/general/Text.vue";

const props = defineProps({
  label: {
    type: String,
    default: "Unverified",
  },
});
</script>
<style lang="scss" scoped></style>
