<template>
  <v-expansion-panel
    :class="[
      getEscrowStatus.className,
      { modified: highlightChanges && isPhaseNew(phaseDetails?.id) },
      { modified: isDeletedPhase },
    ]"
  >
    <v-expansion-panel-title :color="`${phaseDetails?.color}1A`">
      <div
        v-if="phaseDetails?.name"
        :style="{ color: phaseDetails?.color || 'blue' }"
        class="estimate_summery_item_title"
        :class="{
          modified:
            highlightChanges && isPhaseFieldModified(phaseDetails.id, 'name'),
        }"
      >
        {{
          phaseDetails?.phaseType === EstimatePhaseType.MATERIALS
            ? "Materials"
            : phaseDetails?.name
        }}
      </div>
      <div
        v-else
        :style="{ color: phaseDetails?.color || 'blue' }"
        class="estimate_summery_item_title"
      >
        {{ "Materials" }}
      </div>
      <div class="estimate_summery_item_header_right">
        <div class="estimate_summery_status tw-flex tw-items-center tw-gap-1">
          <v-icon
            v-if="getEscrowStatus.status === status.COMPLETED"
            icon="mdi-check-circle"
            color="#40AE42"
          >
          </v-icon>
          <img
            v-if="getEscrowStatus.status === status.IN_PROGRESS"
            src="@/assets/images/inprogress.svg"
            alt="InProgress"
            width="18"
            height="18"
          />
          <img
            v-if="getEscrowStatus.status === status.NOT_STARTED"
            src="@/assets/images/clock.svg"
            alt="Clock"
            width="18"
            height="18"
          />
          <p>{{ getEscrowStatus.label }}</p>
        </div>
      </div>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <div class="estimate_summery_item_body">
        <div class="estimate_summery_item_info_list">
          <div class="estimate_summery_item_info_item">
            <div class="estimate_summery_item_info_label">Tasks</div>
            <div class="estimate_summery_item_info_text">
              {{ phaseDetails?.projectStageTasks?.length }}
            </div>
          </div>
          <div class="estimate_summery_item_info_item" v-if="phaseDetails.name">
            <div class="estimate_summery_item_info_label">Days</div>
            <div
              class="estimate_summery_item_info_text"
              :class="{
                modified:
                  highlightChanges &&
                  isPhaseFieldModified(phaseDetails.id, 'totalDuration'),
              }"
            >
              {{ phaseDetails?.totalDuration }}
            </div>
          </div>
          <div class="estimate_summery_item_info_item">
            <div class="estimate_summery_item_info_label">Phase Cost</div>
            <div
              class="estimate_summery_item_info_text"
              :class="{
                modified:
                  highlightChanges &&
                  isPhaseFieldModified(phaseDetails.id, 'subTotal'),
              }"
            >
              {{ phaseDetails?.subTotal }}
            </div>
          </div>
        </div>

        <!-- <div class="estimate_summery_tag display-mobile">
          <p>Paid</p>
        </div> -->

        <div
          class="estimate_summery_notes_list"
          v-if="phaseDetails?.projectStageTasks?.length"
        >
          <div
            class="estimate_summery_notes"
            v-for="phaseTask in phaseDetails.projectStageTasks"
            :key="phaseTask.id"
            :class="{
              modified:
                highlightChanges &&
                isPhaseTaskNew(phaseDetails.id, phaseTask.id),
            }"
          >
            <div class="estimate_summery_notes_header">
              <div class="estimate_summery_notes_header_left">
                <div>
                  <p
                    :class="{
                      modified:
                        highlightChanges &&
                        isPhaseTaskFieldModified(
                          phaseDetails.id,
                          phaseTask.id,
                          'name'
                        ),
                    }"
                  >
                    {{ phaseTask?.name }}
                  </p>
                </div>
                <!-- <div class="estimate_summery_notes_author">
                  <div class="task_images">
                    <div class="task_images_list">
                      <img
                        src="@/assets/images/estimate-summery-person.png"
                        alt=""
                      />
                    </div>
                    <div class="task_images_list">
                      <img src="@/assets/images/sample-avatar.jpg" alt="" />
                    </div>
                    <div class="task_images_list">
                      <img src="@/assets/images/sample-avatar-2.png" alt="" />
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="estimate_summery_notes_header_right">
                <div class="estimate_summery_notes_info">
                  <div class="estimate_summery_notes_info_item">
                    <p
                      :class="{
                        modified:
                          highlightChanges &&
                          isPhaseTaskFieldModified(
                            phaseDetails.id,
                            phaseTask.id,
                            'duration'
                          ),
                      }"
                    >
                      <strong>{{ phaseTask?.duration }}</strong>
                      Days
                    </p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <p
                      :class="{
                        modified:
                          highlightChanges &&
                          isPhaseTaskFieldModified(
                            phaseDetails.id,
                            phaseTask.id,
                            'taskCost'
                          ),
                      }"
                    >
                      <strong>
                        {{ getCostInFormate(phaseTask?.taskCost) }}</strong
                      >
                      Unit Cost
                    </p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <p
                      :class="{
                        modified:
                          highlightChanges &&
                          (isPhaseTaskFieldModified(
                            phaseDetails.id,
                            phaseTask.id,
                            'taskCost'
                          ) ||
                            isPhaseTaskFieldModified(
                              phaseDetails.id,
                              phaseTask.id,
                              'materialUnit'
                            )),
                      }"
                    >
                      <strong>{{
                        totalSubTaskAmount(
                          phaseTask?.taskCost *
                            (phaseDetails.phaseType === EstimatePhaseType.LABOUR
                              ? 1
                              : phaseTask?.materialUnit || 1)
                        )
                      }}</strong>
                      Task Cost
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="estimate_summery_notes_body">
              <p>Description</p>
            </div> -->
          </div>
        </div>
        <!-- For detleted SubTAsk -->
        <div
          class="estimate_summery_notes_list"
          v-if="
            getDeletedPhaseTasks(phaseDetails?.phaseUniqueId)?.length &&
            !isDeletedPhase
          "
        >
          <div
            class="estimate_summery_notes !tw-bg-red-500"
            v-for="phaseTask in getDeletedPhaseTasks(
              phaseDetails?.phaseUniqueId
            )"
            :key="phaseTask.id"
          >
            <div class="estimate_summery_notes_header">
              <div class="estimate_summery_notes_header_left">
                <div>
                  <p
                    :class="{
                      modified:
                        highlightChanges &&
                        isPhaseTaskFieldModified(
                          phaseDetails.id,
                          phaseTask.id,
                          'name'
                        ),
                    }"
                  >
                    {{ phaseTask?.name }}
                  </p>
                </div>
                <!-- <div class="estimate_summery_notes_author">
                  <div class="task_images">
                    <div class="task_images_list">
                      <img
                        src="@/assets/images/estimate-summery-person.png"
                        alt=""
                      />
                    </div>
                    <div class="task_images_list">
                      <img src="@/assets/images/sample-avatar.jpg" alt="" />
                    </div>
                    <div class="task_images_list">
                      <img src="@/assets/images/sample-avatar-2.png" alt="" />
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="estimate_summery_notes_header_right">
                <div class="estimate_summery_notes_info">
                  <div class="estimate_summery_notes_info_item">
                    <p
                      :class="{
                        modified:
                          highlightChanges &&
                          isPhaseTaskFieldModified(
                            phaseDetails.id,
                            phaseTask.id,
                            'duration'
                          ),
                      }"
                    >
                      <strong>{{ phaseTask?.duration }}</strong>
                      Days
                    </p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <p
                      :class="{
                        modified:
                          highlightChanges &&
                          isPhaseTaskFieldModified(
                            phaseDetails.id,
                            phaseTask.id,
                            'taskCost'
                          ),
                      }"
                    >
                      <strong>
                        {{ getCostInFormate(phaseTask?.taskCost) }}</strong
                      >
                      Unit Cost
                    </p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <p
                      :class="{
                        modified:
                          highlightChanges &&
                          (isPhaseTaskFieldModified(
                            phaseDetails.id,
                            phaseTask.id,
                            'taskCost'
                          ) ||
                            isPhaseTaskFieldModified(
                              phaseDetails.id,
                              phaseTask.id,
                              'materialUnit'
                            )),
                      }"
                    >
                      <strong>{{
                        totalSubTaskAmount(
                          phaseTask?.taskCost *
                            (phaseDetails.phaseType === EstimatePhaseType.LABOUR
                              ? 1
                              : phaseTask?.materialUnit || 1)
                        )
                      }}</strong>
                      Task Cost
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="estimate_summery_notes_body">
              <p>Description</p>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div
        v-if="getEscrowStatus.status !== status.COMPLETED"
        class="estimate_summery_item_footer"
      >
        <div class="estimate_summery_item_controls">
          <div
            v-if="
              phaseDetails?.projectStageTasks?.length &&
              phaseDetails.phaseType !== EstimatePhaseType.MATERIALS &&
              isUserHasEstimateEditAccess
            "
            class="estimate_summery_item_control tw-cursor-pointer"
          >
            <img
              src="@/assets/images/sticky-note.svg"
              alt="Notes"
              width="18"
              height="18"
            />
            <span>Phase notes</span>
          </div>
          <div
            v-if="
              phaseDetails.phaseType !== EstimatePhaseType.MATERIALS &&
              isUserHasEstimateEditAccess
            "
            class="estimate_summery_item_control tw-cursor-pointer"
          >
            <img
              src="@/assets/images/image.svg"
              alt="Image"
              width="18"
              height="18"
            />
            <span>Proof of work</span>
          </div>
          <div class="estimate_summery_item_control">
            <img
              src="@/assets/images/person.svg"
              alt="Image"
              width="18"
              height="18"
            />
            <span>Members</span>
          </div>
        </div>
        <div class="estimate_summery_item_author">
          <img
            src="@/assets/images/estimate-summery-person.png"
            alt="Notes"
            width="18"
            height="18"
          />
        </div>
      </div> -->
    </v-expansion-panel-text>
  </v-expansion-panel>
  <!-- <AddPhaseNoteModal
    v-if="isShowPhaseNoteModal"
    :phaseDetails="phaseDetails"
    @onCloseModal="toggleAddPhaseNoteModal"
  /> -->
  <!-- <PhaseProofOfWorkModal
    v-if="isShowPhaseProofOfWorkModal"
    :phaseDetails="phaseDetails"
    @onClose="togglePhaseProofOfWorkModal"
  /> -->
  <ProjectRateModal
    v-if="isShowReviewModal"
    :type="ProjectRatingTypeEnum.TRADER_OWNER"
    :ratingData="projectRatingData"
    @on-close="toggleRatingModal"
    :projectId="projectDetails?.id"
    :estimatePhaseDetails="phaseDetails"
    reviewType="phase"
  />
</template>
<script setup>
import { computed, getCurrentInstance } from "vue";
import moment from "moment";
import currencyFilter from "@/filters/currency.filter";
import AddPhaseNoteModal from "@/modules/jobs/components/modal/AddPhaseNoteModal.vue";
import PhaseProofOfWorkModal from "@/modules/jobs/components/modal/PhaseProofOfWorkModal.vue";
import { ref } from "vue";
import { onMounted } from "vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { useStore } from "vuex";
import { EscrowStatusEnum, EstimatePhaseType } from "@/core/enums/estimateEnum";
import { JOBS_STORE } from "@/store/modules/jobs";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { displayToastMessage, parseJSON } from "@/core/utils/common";
import ProjectRateModal from "@/core/components/modals/ProjectRateModal.vue";
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";
import projectRatingService from "@/core/services/project-rating.service";

const props = defineProps({
  phaseDetails: Object,
  existingEstimatePhases: Object,
  modifiedEstimationPhases: Object,
  highlightChanges: Boolean,
  isDeletedPhase: {
    type: Boolean,
    default: false,
  },
});

const phaseCompletedDetail = ref();

const store = useStore();
const status = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Stage Complete",
};
const escrowStatus = [
  {
    status: "Not Started",
    className: "",
    label: "not started",
    paymentStatus: "Deposit Payment",
  },
  {
    status: "In Progress",
    className: "in-progress",
    label: "in progress",
    paymentStatus: "Request Payment",
  },
  {
    status: "Stage Complete",
    className: "paid-item",
    label: "completed",
    paymentStatus: "paid",
  },
];
const escrowAccount = ref();
const isShowPhaseNoteModal = ref(false);
const isShowPhaseProofOfWorkModal = ref(false);
const getEscrowStatus = computed(
  () =>
    escrowStatus.find(
      (escrow) => escrow.status === escrowAccount.value?.name
    ) || escrowStatus[1]
);
const isDisableCheckBox = computed(
  () =>
    ![EscrowStatusEnum.IN_PROGRESS, EscrowStatusEnum.CANCELLED].includes(
      escrowAccount.value?.id
    ) || !isUserHasEstimateEditAccess.value
);
const internalInstance = getCurrentInstance();

const projectDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const isUserHasEstimateEditAccess = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isUserHasEstimateEditAccess`]
);

const isDisablePaymentButton = computed(
  () =>
    (!phaseCompletedDetail.value?.isCompletedPhase &&
      getEscrowStatus.value.status === status.IN_PROGRESS) ||
    !isUserHasEstimateEditAccess.value ||
    getEscrowStatus.value.status === status.COMPLETED
);

const getCostInFormate = (costValue) => {
  return currencyFilter.formatToCurrency(costValue);
};
const totalSubTaskAmount = (amount) => {
  const SERVICE_CHARGE = 0.03;
  const PHASE_FIRST_CHARGE = 5;
  let sum = 0;

  sum += amount + amount * props.phaseDetails.vat;
  sum += sum * SERVICE_CHARGE;

  return currencyFilter.formatToCurrency(sum);
};
const totalPhaseAmount = (amount, vat, isFirstPhase) => {
  const SERVICE_CHARGE = 0.03;
  const PHASE_FIRST_CHARGE = 5;
  let sum = 0;

  sum += amount + amount * vat;
  sum += sum * SERVICE_CHARGE;
  if (isFirstPhase) {
    sum += PHASE_FIRST_CHARGE;
  }
  return currencyFilter.formatToCurrency(sum);
};
const getTotalDays = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), "days") || 0;
};

const toggleAddPhaseNoteModal = (value) => {
  if (projectDetails.value?.isHistoricProject) return;
  isShowPhaseNoteModal.value = !isShowPhaseNoteModal.value;
};
const togglePhaseProofOfWorkModal = (status) => {
  if (projectDetails.value?.isHistoricProject) return;
  isShowPhaseProofOfWorkModal.value = !isShowPhaseProofOfWorkModal.value;
};
const onRequestPayment = async (escrowStatusId) => {
  try {
    const res = await tradeEstimateService.updateProjectEstimatePhaseStatus(
      props.phaseDetails?.id,
      {
        escrowStatusId,
      }
    );
    escrowAccount.value = res?.escrowStatus;
  } catch (error) {
    console.log("onRequestDeposit error", error);
  }
};

const onClickPaymentStatusButton = async () => {
  if (getEscrowStatus.value.status === status.NOT_STARTED) {
    await onRequestPayment(EscrowStatusEnum.NOT_STARTED_POKED);
    displayToastMessage(
      internalInstance,
      "Deposit Payment request has been sent",
      "success"
    );
  } else if (getEscrowStatus.value.status === status.IN_PROGRESS) {
    const projectAttachment =
      await tradeEstimateService.getEstimatePhaseTaskProofOfWork(
        props.phaseDetails.id
      );
    if (
      !projectAttachment.length &&
      props.phaseDetails.phaseType !== EstimatePhaseType.MATERIALS
    ) {
      displayToastMessage(
        internalInstance,
        "Please upload proof of work to release the payment.",
        "info"
      );
      setTimeout(() => {
        togglePhaseProofOfWorkModal();
      }, 1500);

      return;
    }
    await onRequestPayment(EscrowStatusEnum.IN_PROGRESS_POKED);
    displayToastMessage(
      internalInstance,
      "Request Payment request has been sent",
      "success"
    );
  }
};
const getTradeEstimateDataWithEscrowStatus = computed(
  () =>
    store.getters[
      `${TRADE_ESTIMATE_STORE}/getTradeEstimateDataWithEscrowStatus`
    ]
);
const updatePhaseCompletionStatus = async (data) => {
  try {
    const res = await tradeEstimateService.updatePhaseCompletionStatus({
      estimateId: getTradeEstimateDataWithEscrowStatus.value?.id,
      phaseId: props.phaseDetails?.id,
      data,
    });
  } catch (error) {
    console.log("error", error);
  }
};
const onChangeCheckBox = (type, index) => {
  if (!isUserHasEstimateEditAccess.value) return;
  let updatedData = null;
  if (type === "phaseTask") {
    phaseCompletedDetail.value.isCompletedPhase =
      !phaseCompletedDetail.value.isCompletedPhase;
    if (phaseCompletedDetail.value.isCompletedPhase) {
      phaseCompletedDetail.value.subTask.forEach(
        (task) => (task.isCompleted = true)
      );
      updatedData = {
        phaseStatus: true,
        phaseTask: phaseCompletedDetail.value.subTask.map((task) => ({
          phaseTaskId: task?.id,
          taskStatus: true,
        })),
      };
    } else {
      phaseCompletedDetail.value.subTask.forEach(
        (task) => (task.isCompleted = false)
      );
      updatedData = {
        phaseStatus: false,
        phaseTask: phaseCompletedDetail.value.subTask.map((task) => ({
          phaseTaskId: task?.id,
          taskStatus: false,
        })),
      };
    }
  } else {
    phaseCompletedDetail.value.subTask[index].isCompleted =
      !phaseCompletedDetail.value.subTask[index].isCompleted;
    if (phaseCompletedDetail.value.subTask.every((task) => task.isCompleted)) {
      phaseCompletedDetail.value.isCompletedPhase = true;
      updatedData = {
        phaseStatus: true,
        phaseTask: [
          {
            phaseTaskId: phaseCompletedDetail.value.subTask[index]?.id,
            taskStatus: phaseCompletedDetail.value.subTask[index].isCompleted,
          },
        ],
      };
    } else {
      phaseCompletedDetail.value.isCompletedPhase = false;
      updatedData = {
        phaseStatus: false,
        phaseTask: [
          {
            phaseTaskId: phaseCompletedDetail.value.subTask[index]?.id,
            taskStatus: phaseCompletedDetail.value.subTask[index].isCompleted,
          },
        ],
      };
    }
  }
  updatePhaseCompletionStatus(updatedData);
};

const projectRatingData = ref();
const isShowReviewModal = ref(false);
const toggleRatingModal = (ratingData) => {
  isShowReviewModal.value = !isShowReviewModal.value;
  if (ratingData) {
    projectRatingData.value = ratingData;
  }
};

const isShowProjectRatingButton = computed(
  () =>
    projectRatingData.value &&
    !parseJSON(projectRatingData.value.ratingStatusList)?.length &&
    escrowAccount.value?.id == EscrowStatusEnum.STAGE_COMPLETE
);

const checkIsUserRatedProject = async () => {
  try {
    projectRatingData.value = await projectRatingService.getProjectPhaseRating(
      props.phaseDetails?.id
    );
    if (!projectRatingData.value) {
      toggleRatingModal(null);
    }
  } catch (error) {
    console.log("get project rating", error);
  }
};
const isPhaseNew = (phaseId) => {
  return !props.existingEstimatePhases.some((phase) => phase.id === phaseId);
};

const isPhaseTaskNew = (phaseId, taskId) => {
  const existingPhase = props.existingEstimatePhases.find(
    (phase) => phase.id === phaseId
  );
  if (!existingPhase) return true;

  return !existingPhase?.projectStageTasks.some((task) => task.id === taskId);
};
const isPhaseTaskDeleted = (phaseId, taskId) => {
  const existingPhase = props?.existingEstimatePhases?.find(
    (phase) => phase.id === phaseId
  );
  if (!existingPhase) return false;

  const modifiedPhase = props?.modifiedEstimationPhases?.find(
    (phase) => phase.id === phaseId
  );
  if (!modifiedPhase) return false;

  return (
    existingPhase?.projectStageTasks.some((task) => task.id === taskId) &&
    !modifiedPhase?.projectStageTasks.some((task) => task.id === taskId)
  );
};
const getDeletedPhaseTasks = (phaseId) => {
  const existingPhase = props?.existingEstimatePhases?.find(
    (phase) => phase.phaseUniqueId === phaseId
  );
  if (!existingPhase) return [];

  const modifiedPhase = props?.modifiedEstimationPhases?.find(
    (phase) => phase.phaseUniqueId === phaseId
  );
  if (!modifiedPhase) return existingPhase?.projectStageTasks || [];

  return existingPhase?.projectStageTasks?.filter(
    (task) =>
      !modifiedPhase?.projectStageTasks?.some(
        (modifiedTask) => modifiedTask.id === task.id
      )
  );
};

const isPhaseFieldModified = (phaseId, field) => {
  const existingPhase = props.existingEstimatePhases.find(
    (phase) => phase.id === phaseId
  );
  const modifiedPhase = props.modifiedEstimationPhases.find(
    (phase) => phase.id === phaseId
  );

  if (existingPhase && modifiedPhase) {
    return existingPhase[field] !== modifiedPhase[field];
  }
  return false;
};

const isPhaseTaskFieldModified = (phaseId, taskId, field) => {
  const existingPhase = props.existingEstimatePhases.find(
    (phase) => phase.id === phaseId
  );
  const modifiedPhase = props.modifiedEstimationPhases.find(
    (phase) => phase.id === phaseId
  );

  if (existingPhase && modifiedPhase) {
    const existingTask = existingPhase?.projectStageTasks.find(
      (task) => task.id === taskId
    );
    const modifiedTask = modifiedPhase?.projectStageTasks.find(
      (task) => task.id === taskId
    );

    if (existingTask && modifiedTask) {
      if (field === "taskCost" || field === "materialUnit")
        return parseInt(existingTask[field]) !== parseInt(modifiedTask[field]);
      return existingTask[field] !== modifiedTask[field];
    }
  }
  return false;
};
onMounted(async () => {
  if (props.phaseDetails?.escrowStatus?.id == EscrowStatusEnum.STAGE_COMPLETE) {
    await checkIsUserRatedProject();
  }

  phaseCompletedDetail.value = {
    isCompletedPhase: props.phaseDetails?.phaseCompletionStatus,
    subTask: props.phaseDetails?.projectStageTasks?.map((task) => ({
      isCompleted: task?.taskCompletionStatus,
      id: task?.id,
    })),
  };
  escrowAccount.value = props.phaseDetails?.escrowStatus || {
    id: 1,
    name: "Not Started",
  };
});
</script>

<style lang="scss" scoped>
.task_images {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 4;

  .task_images_list {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: -5px;

    &:last-child {
      margin-right: 0;
    }

    $a: 1;

    @for $i from 1 through 5 {
      &:nth-child(3n + #{$i}) {
        z-index: -$a;
        $a: $a + 1;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:first-child {
    margin-left: 0;
  }
}

.estimate_summery {
  display: grid;
  grid-template-columns: 388px 1fr;
  grid-gap: 16px;
  align-items: flex-start;
  text-align: left;
  color: $blueDark;

  @media (max-width: 1439px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    grid-gap: 0;
    gap: 16px;
  }

  &_left {
    width: 100%;

    .estimate_card_title {
      margin-bottom: 22px;
    }
  }

  &_amount {
    font-weight: 700;
    @include fluidFont(38, 38, 25px);
    letter-spacing: 0.25px;

    &_label {
      @include fluidFont(14, 14, 18px);
      font-weight: 500;
      letter-spacing: 1px;
      color: rgba($blueDark, 0.5);
      margin-top: 18px;
    }
  }

  &_header {
    box-shadow: none;
    font-weight: 600;
    margin-bottom: 30px;

    @include respond(s720) {
      display: none;
    }

    h2 {
      font-weight: 600;
      @include fluidFont(25, 25, 25px);
      letter-spacing: 0.64px;
    }

    &_info {
      display: flex;
      align-items: center;
      gap: 42px;
      flex-wrap: wrap;
      margin-top: 15px;
    }

    &_label {
      display: grid;
      grid-template-columns: 18px 1fr;
      grid-gap: 5px;
      font-weight: 700;
      @include fluidFont(14, 14, 1);
      color: $info;
      flex-shrink: 0;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    &_id {
      @include fluidFont(14, 14, 1);

      span {
        color: rgba($blueDark, 0.5);
      }
    }
  }

  &_list {
    &_title {
      margin-bottom: 24px;

      h3 {
        @include fluidFont(18, 20, 22px);
        letter-spacing: 0.25px;
      }
    }

    &_inner {
      padding: 16px;

      @include respond(s720) {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }

  &_info_list {
    padding-bottom: 12px;
    border-bottom: 1px solid #c5d2f2;
    margin-bottom: 30px;

    @include respond(s720) {
      padding: 16px 14px;
      background-color: $white;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      border: 0;
      border-radius: 6px;
      margin-bottom: 22px;
    }

    .estimate_card_title {
      font-weight: 700;
      margin-bottom: 12px;

      @include respond(s720) {
        margin-bottom: 0;
      }
    }

    &_inner {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap: 8px;

      @include respond(s720) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    &_box {
      background-color: #f3f3f6;
      border-radius: 8px;
      padding: 8px;
      min-height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include fluidFont(10, 10, 12px);
      letter-spacing: 0.25px;
      color: rgba($blueDark, 0.7);

      &_title {
        @include fluidFont(12, 12, 25px);
        color: $blueDark;
        font-weight: 700;
      }
    }
  }

  &_item {
    &_header {
      &_left {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
      }

      &_right {
        position: relative;
        flex: auto;
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: auto;
        justify-content: flex-end;
      }
    }

    &_title {
      @include fluidFont(14, 10, 17px);
      font-weight: 700;

      .paid-item & {
        color: #eb8c49;
      }

      .in-progress & {
        color: #d84f50;
      }
    }

    &_body {
      padding: 20px 24px;

      @include respond(s720) {
        padding: 28px 20px;
      }
    }

    &_info_list {
      display: flex;
      align-items: flex-start;
      gap: 32px 84px;
      letter-spacing: 0.25px;
      flex-wrap: wrap;

      @include respond(s720) {
        gap: 32px 62px;
      }
    }

    &_info_item {
      position: relative;

      @include respond(s720) {
        display: flex;
        flex-direction: column-reverse;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          right: -54px;
          top: 0;
          height: 100%;
          width: 1px;
          background-color: rgba($blueDark, 0.1);

          @include respond(s720) {
            right: -30px;
          }
        }
      }
    }

    &_info_label {
      @include fluidFont(10, 12, 1);
      color: rgba($blueDark, 0.8);
      margin-bottom: 2px;
    }

    &_info_text {
      @include fluidFont(16, 16, 25px);
      font-weight: 700;
    }

    &_footer {
      background-color: rgba($blueDark, 0.1);
      padding: 10px;
      @include fluidFont(12, 10, 12px);
      color: #6c6d8e;
      border-radius: 0 0 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      @include respond(s720) {
        border-radius: 0 0 10px 10px;
      }
    }

    &_controls {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;

      @include respond(s720) {
        gap: 10px;
      }
    }

    &_control {
      position: relative;
      display: flex;
      align-items: center;
      gap: 12px;

      @include respond(s720) {
        gap: 8px;
      }

      img {
        width: auto;
        height: auto;
        max-height: 18px;
        object-fit: contain;
        flex-shrink: 0;

        @include respond(s720) {
          max-height: 14px;
        }
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          right: -15px;
          top: 0;
          height: 100%;
          width: 1px;
          background-color: rgba($blueDark, 0.2);

          @include respond(s720) {
            right: -4px;
          }
        }
      }
    }

    &_author {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  &_status {
    display: flex;
    align-items: center;
    gap: 6px;
    @include fluidFont(10, 10, 1);
    text-transform: uppercase;
    color: rgba($blueDark, 0.5);
    background-color: rgba($blueDark, 0.05);
    border-radius: 5px;
    min-height: 20px;
    padding: 2px 10px;
    font-weight: 600;

    .in-progress & {
      background-color: rgba($blueDark, 0.05);
      color: $blueDark;
    }

    .paid-item & {
      background-color: rgba($PrimaryGreen, 0.1);
      color: $PrimaryGreen;
    }

    .mdi-check-circle {
      flex-shrink: 0;
      font-size: 16px;
    }

    img {
      flex-shrink: 0;
      max-width: 12px;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .v-btn {
    min-height: 32px;
    border-radius: 5px;
    color: $white;
    background-color: #ffa500;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 1;
    &:disabled {
      background-color: #e7e7ed;
      color: rgba($blueDark, 0.54);
    }

    @include respond(s720) {
      margin-top: 20px;
    }

    .in-progress & {
      background-color: #e7e7ed;
      color: rgba($blueDark, 0.54);
    }

    .paid-item & {
      background-color: rgba($PrimaryGreen, 0.1);
      color: $PrimaryGreen;
    }
  }

  .v-expansion-panel {
    border-radius: 8px;

    @include respond(s720) {
      border-radius: 10px;
    }
    &::after {
      display: none !important;
    }

    &-title {
      min-height: 56px;
      display: flex;
      align-items: center;
      gap: 34px;
      justify-content: space-between;
      padding: 12px 24px;

      @include respond(s720) {
        gap: 10px;
        min-height: 46px;
      }
    }
  }

  &_notes {
    background-color: rgba(#f3f3f6, 0.6);
    border-radius: 6px;
    padding: 10px;

    &_list {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid rgba(#c5d2f2, 0.3);
      display: grid;
      grid-gap: 10px;

      @include respond(s720) {
        border-top: 0;
        padding-top: 0;
        margin-top: 12px;
      }
    }

    &_title {
      background-color: rgba(#4a92e5, 0.1);
      @include fluidFont(12, 10, 1);
      border-radius: 5px;
      min-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 600;
      color: #4a92e5;
      padding: 4px 10px;
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      @include respond(s720) {
        flex-direction: column;
        gap: 14px;
        align-items: flex-start;
      }

      &_left {
        display: flex;
        align-items: center;
        gap: 6px;
        flex: auto;

        @include respond(s720) {
          width: 100%;
        }
      }

      &_right {
        display: flex;
        align-items: center;
        gap: 13px;
      }
    }

    &_author {
      margin-left: auto;
    }

    &_body {
      @include fluidFont(12, 12, 12px);
      padding-top: 18px;
      font-weight: 500;

      @include respond(s720) {
        padding-top: 14px;
      }
    }

    &_info {
      display: flex;
      align-items: center;
      gap: 26px;

      &_item {
        position: relative;
        @include fluidFont(10, 10, 12px);
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.25px;

        &:not(:last-child) {
          &::after {
            content: "";
            position: absolute;
            right: -14px;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: rgba($blueDark, 0.1);
          }
        }
      }
    }
  }

  .display-mobile {
    display: none;

    @include respond(s720) {
      display: flex;
    }
  }

  .hide-mobile {
    @include respond(s720) {
      display: none;
    }
  }

  .checkbox {
    .mdi-check-circle {
      font-size: 18px;
    }
  }

  --v-selection-control-size: 16px !important;
}

.estimate_tooltip {
  :deep(.v-overlay__content) {
    background-color: rgba($blueDark, 0.88);
    @include fluidFont(10, 10, 1);
    letter-spacing: 0.25px;
  }
}

:deep {
  .v-selection-control--density-default {
    --v-input-control-height: 16px;
    --v-selection-control-size: 16px !important;
  }

  .v-expansion-panel__shadow {
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
  }

  .v-expansion-panel-title__overlay {
    background-color: rgba($info, 0.1);
    opacity: 1;

    .paid-item & {
      background-color: #fdf4ed;
    }

    .in-progress & {
      background-color: rgba(#d84f50, 0.1);
    }
  }

  .v-expansion-panel-text__wrapper {
    padding: 0;
  }
}

.modified {
  background-color: #fff3cd; /* Yellow background for modified fields */
  padding: 0.25rem;
  border-radius: 4px;
}
.deleted {
  background-color: #f6b5b5; /* Gray background for deleted fields */
  padding: 0.25rem;
  border-radius: 4px;
}
</style>
