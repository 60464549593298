<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_172_65787)">
      <path
        d="M6.54007 12.3926V8.89351H9.33932V12.3926C9.33932 12.7775 9.65424 13.0924 10.0391 13.0924H12.1386C12.5235 13.0924 12.8384 12.7775 12.8384 12.3926V7.49389H14.0281C14.35 7.49389 14.5039 7.09499 14.259 6.88505L8.40857 1.61546C8.14264 1.37753 7.73675 1.37753 7.47082 1.61546L1.62039 6.88505C1.38246 7.09499 1.52942 7.49389 1.85133 7.49389H3.04101V12.3926C3.04101 12.7775 3.35593 13.0924 3.74083 13.0924H5.84026C6.22516 13.0924 6.54007 12.7775 6.54007 12.3926Z"
        fill="white"
      />
      <path
        d="M11.8645 2.2448H10.0372L12.1366 4.34424V2.54403C12.1366 2.37878 12.0148 2.2448 11.8645 2.2448Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_65787">
        <rect
          width="13.5294"
          height="13.5294"
          fill="white"
          transform="translate(0.5 0.00170898)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "PropertyHomeIcon",
};
</script>
