<template>
  <GeneralDialog
    class="tw-max-w-[600px] service-agreement-modal"
    :isShowCloseButton="false"
    @on-close="onCloseModal"
    :headerTitle="modalHeader.title"
    :headerDescription="dateNow"
    descriptionColor="#FAA100"
    :isTitleStrong="true"
    width="650px"
  >
    <template #body>
      <div class="service-agreement-modal__content flex-column jc-c ai-c">
        <el-scrollbar>
          <div class="service-agreement-modal__scrollbar-content flex-column">
            <div class="content tw-flex tw-flex-col tw-gap-8">
              <el-row class="project-info">
                <el-col :span="24" class="tw-flex tw-gap-1 tw-items-center">
                  <p
                    class="tw-text-sm tw-font-semibold tw-text-[#0C0F4A] tw-opacity-50"
                  >
                    Issue Name:
                  </p>
                  <p class="tw-text-sm tw-font-semibold tw-text-[#0C0F4A]">
                    Pipe Leak
                  </p>
                </el-col>
                <el-col
                  :span="24"
                  class="tw-flex tw-gap-1 tw-items-center tw-mt-1"
                >
                  <p
                    class="tw-text-sm tw-font-semibold tw-text-[#0C0F4A] tw-opacity-50"
                  >
                    Project ID:
                  </p>
                  <p class="tw-text-sm tw-font-semibold tw-text-[#0C0F4A]">
                    7Rt9x2Lp4QzF6sW8
                  </p>
                </el-col>
              </el-row>
              <el-row class="terms tw-flex tw-flex-col tw-gap-4">
                <p
                  class="tw-text-sm tw-font-semibold tw-text-[#0C0F4A] tw-opacity-50 tw-leading-[28px]"
                >
                  This agreement (&#8220;<strong>Terms of Service</strong
                  >&#8221;) is entered into by and between Klein's
                  Retrofits&#8220;<strong>The Contractor(s)</strong>&#8221;) of
                  and Keano (&#8220;<strong>The Client</strong>&#8221;) of
                  SW109SA (&#8220;<strong>The Project Address</strong>&#8221;).
                </p>
              </el-row>
              <el-row class="terms tw-flex tw-flex-col tw-gap-2">
                <p
                  class="tw-text-sm tw-font-bold tw-text-[#0C0F4A] tw-leading-[28px]"
                >
                  The terms of this agreement:
                </p>
                <ul class="list">
                  <li
                    class="tw-text-sm tw-font-semibold tw-text-[#0C0F4A] tw-opacity-50 tw-leading-[28px]"
                  >
                    <strong>DESCRIPTION OF SERVICES.</strong>
                    is responsible for delivering services (&#8220;<strong
                      >The Scope of Works</strong
                    >&#8221;) described and submitted in the accepted quote on
                    the iknowa platform for the project . The contractor(s) will
                    take full responsibility to ensure VAT is inclusive on
                    services (where applicable) in all quotes before final
                    submission
                  </li>
                </ul>
              </el-row>
              <el-row>
                <el-col>
                  <p
                    class="tw-text-base tw-font-semibold tw-text-[#0C0F4A] tw-leading-[28px]"
                  >
                    Digital Signature
                  </p>
                </el-col>
                <v-text-field
                  disabled="disable"
                  variant="outlined"
                  class="sign_wrapper"
                  >Keano Chang
                </v-text-field>
              </el-row>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-auto tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          class="reusable-button"
          variant="secondary"
          :isActive="!loading"
          label="cancel"
          @click="onCloseModal"
        />
        <Button
          class="button-primary"
          label="Accept"
          variant="accent"
          @click="onConfirm"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { ref, computed } from "vue";
import moment from "moment";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import EOHService from "@/core/services/emergency-out-of-hours.service";

const props = defineProps(["createdEvent", "selectedEventId"]);
const emits = defineEmits(["on-confirm", "on-cancel", "loading", "on-close"]);

const modalHeader = {
  title: "Service Agreement",
};

const readAgreement = ref(false);

const dateNow = computed(() => moment(new Date()).format("Do MMM YYYY"));

const onCloseModal = () => {
  emits("on-close");
};

const onConfirm = async () => {
  if (!props.selectedEventId) {
    await EOHService.createNewEOHEvent(props.createdEvent);
  } else {
    await EOHService.updateParticularEOHEvents(
      props.selectedEventId,
      props.createdEvent
    );
  }
  onCloseModal();
};

const onCancel = () => {
  emits("on-cancel");
};
</script>

<style lang="scss" scoped>
.sign_wrapper {
  :deep(.v-input__control) {
    .v-field {
      .v-field__field {
        .v-field__input {
          padding: 0 16px;
          min-height: 42px;
          background-color: rgba($blueDark, 0.05);
          border-radius: 50px;
          font-family: "Darlington Demo";
          @include fluidFont(24, 24, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}
.project-info {
  p {
    @include fluidFont(14, 14, 1.3);
    font-weight: 600;
    color: rgba($blueDark, 0.5);
    span {
      color: rgba($blueDark, 1);
    }
  }
}
</style>
