<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :onClickBackButton="onClickBackButton"
    max-width="342px"
    height="auto"
    :className="['job_confirm_modal']"
  >
    <template v-slot:body>
      <div class="modal_body">
        <div class="modal_confirm_icon">
          <img src="@/assets/icons/yellow-success-icon.svg" alt="" />
        </div>
        <div class="modal_confirm_text">
          <h3>{{ title }}</h3>
          <UserDetailCard
            v-if="isShowUserDetails"
            :name="jobDetail?.customerName"
            :address="jobDetail?.property?.postcode"
            :isShowBorder="true"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <v-btn @click="onClickContinue" size="large" class="button button-orange"
        >Continue</v-btn
      >
    </template>
  </CommonDialog>
</template>

<script setup>
import UserDetailCard from "@/core/components/common/UserDetailCard.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { NWG_JOBS_STORE } from "@/store/modules/nwg-job";
import { computed } from "vue";
import { useStore } from "vuex";
const props = defineProps(["title", "isShowUserDetails"]);
const emits = defineEmits(["on-close", "on-continue"]);
const store = useStore();
const jobDetail = computed(
  () => store.getters[`${NWG_JOBS_STORE}/getJobDetails`]
);
const onClickContinue = () => {
  emits("on-continue");
};
</script>
<style scoped lang="scss">
.modal_body {
  text-align: center;
  .modal_confirm_icon {
    width: 68px;
    height: 68px;
    border-radius: 100%;
    margin: 0 auto;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .modal_confirm_text {
    h3 {
      @include fluidFont(24, 24, 30px);
      font-weight: 700;
      color: rgba($blueDark, 1);
      margin-top: 16px;
    }
    .profile__card {
      margin: 40px 0 0;
    }
  }
}
.button {
  margin: 40px 0 0;
  width: 100%;
  max-width: 100%;
}
// .confirm_wrapper {
//   h3 {
//     @include fluidFont(24, 24, 0);
//     font-weight: 700;
//     color: rgba($blueDark, 1);
//     margin-top: 16px;
//     line-height: 30px;
//   }
//   img {
//     width: 68px;
//     height: 68px;
//     object-fit: cover;
//   }
//   :deep(.profile__card) {
//     margin-top: 40px;
//   }
// }
// .button {
//   margin-top: 40px;
// }
</style>
