import { FilterValuesDto, NWGCustomerNoteDto } from "@/core/models/nwg-job";
import NwgjobsService from "@/core/services/nwg-job.service";
import { setDirectStoragePathToImage } from "@/core/utils/common";
import { Commit } from "vuex";

export default {
  async setJobs({ commit }: { commit: Commit }, filterValue: FilterValuesDto) {
    try {
      const response = await NwgjobsService.getJobList(filterValue);
      commit("setJobList", response);
      return response;
    } catch (error) {
      commit("setJobList", []);
    }
  },

  async setJobDetails({ commit }: { commit: Commit }, jobId: number) {
    try {
      const response: any = await NwgjobsService.getJobById(jobId);
      if (response?.attachments.length) {
        for (const attachment of response.attachments) {
          attachment.imageURL = setDirectStoragePathToImage(
            attachment.attachment
          );
        }
      }
      commit("setJobDetails", response);

      return response;
    } catch (error) {
      commit("setJobDetails", null);
      return null;
    }
  },

  async setBatchDetails({ commit }: { commit: Commit }, batchId: number) {
    try {
      const response: any = await NwgjobsService.getBatchById(batchId);
      commit("setBatchDetails", response);
      return response;
    } catch (error) {
      commit("setBatchDetails", null);
      return null;
    }
  },

  async createCustomerNote(
    { commit }: { commit: Commit },
    payload: { jobId: number; data: NWGCustomerNoteDto }
  ) {
    const response: any = await NwgjobsService.createCustomerNote(
      payload.jobId,
      payload.data
    );
    return response;
  },

  async assignEngineerIntoBatch(
    { commit }: { commit: Commit },
    payload: { batchId: number; data: { engineerId: number } }
  ) {
    const response: any = await NwgjobsService.assignEngineerIntoNWGJob(
      payload.batchId,
      payload.data
    );
    return response;
  },

  async makeCompleteJob({ commit }: { commit: Commit }, jobId: number) {
    const response: any = await NwgjobsService.completedJob(jobId);
    return response;
  },

  async AllBatchList({ commit }: { commit: Commit }) {
    try {
      const response: any = await NwgjobsService.getAllBatches();
      commit("setBatchList", response);
      return response;
    } catch (error) {
      commit("setBatchList", null);
      return null;
    }
  },
};
