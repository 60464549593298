<template>
  <GeneralDialog @on-close="onCloseModal" headerTitle="Energy Efficient Rating">
    <template #body>
      <div
        class="tw-w-full tw-border-t-dashed-custom tw-flex tw-flex-col tw-gap-3 tw-px-3 tw-py-4 rmd:tw-hidden"
      >
        <div class="tw-flex-row-between">
          <Text variant="p">Potential rating</Text>
          <template v-for="(ranking, index) in rankingChart" :key="index">
            <div
              v-if="potentialRating === ranking.rating"
              :style="{ backgroundColor: ranking.bgColor }"
              class="score tw-flex tw-min-w-[44px] tw-max-w-[44px] tw-min-h-[37px] tw-max-h-[37px] tw-rounded-md tw-items-center tw-justify-center"
            >
              <Text
                variant="h6"
                :textColor="ranking?.textColor ? ranking?.textColor : '#FFF'"
                >{{ potentialEfficiencyScore }}</Text
              >
            </div>
          </template>
        </div>
        <div class="tw-flex-row-between">
          <Text variant="p">Current rating</Text>
          <template v-for="(ranking, index) in rankingChart" :key="index">
            <div
              v-if="currentRating === ranking.rating"
              :style="{ backgroundColor: ranking.bgColor }"
              class="score tw-flex tw-min-w-[44px] tw-max-w-[44px] tw-min-h-[37px] tw-max-h-[37px] tw-rounded-md tw-items-center tw-justify-center tw-bg-slate-400"
            >
              <Text variant="h6" textColor="#FFF">{{
                currentEfficiencyScore
              }}</Text>
            </div>
          </template>
        </div>
      </div>
      <div
        class="tw-w-full tw-overflow-hidden rmd:tw-w-[630px] rmd:tw-h-[450px] tw-border-t-dashed-custom tw-border-b-dashed-custom tw-flex rmd:tw-overflow-x-hidden"
      >
        <div
          class="tw-px-0 tw-w-full tw-h-full tw-py-4 rmd:tw-pr-6 rmd:tw-pl-2 tw-box-border tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-4"
        >
          <Text variant="span" class="rsm:!tw-text-[18px]" textWeight="600"
            >Very energy efficient - Low running costs</Text
          >
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <RatingItemCard
              v-for="(ranking, index) in rankingChart"
              :key="index"
              :bgColor="ranking.bgColor"
              :isIllegalToRent="ranking?.isIllegalToRent"
              :rating="ranking.rating"
              :score="ranking.score"
              :textColor="ranking?.textColor"
              :width="ranking?.width"
            />
          </div>
          <Text variant="span" class="rsm:!tw-text-[18px]" textWeight="600"
            >Not energy efficient - High running costs</Text
          >
        </div>
        <div
          class="tw-hidden tw-min-w-[100px] tw-max-w-[110px] tw-h-full tw-py-4 tw-px-3 tw-box-border tw-border-l-dashed-custom rmd:tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-4"
        >
          <Text variant="p">Current</Text>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <RateChip
              :class="{ 'tw-invisible': currentRating !== ranking.rating }"
              v-for="(ranking, index) in rankingChart"
              :key="index"
              :bgColor="ranking.bgColor"
              :score="currentEfficiencyScore"
              :textColor="ranking?.textColor"
            />
          </div>
        </div>
        <div
          class="tw-hidden tw-min-w-[100px] tw-max-w-[110px] tw-h-full tw-py-4 tw-px-3 tw-box-border tw-border-l-dashed-custom rmd:tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-4"
        >
          <Text variant="p">Potential</Text>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <RateChip
              :class="{ 'tw-invisible': potentialRating !== ranking.rating }"
              v-for="(ranking, index) in rankingChart"
              :key="index"
              :bgColor="ranking.bgColor"
              :score="potentialEfficiencyScore"
              :textColor="ranking?.textColor"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-grid tw-grid-cols-2 tw-w-full rmd:tw-flex tw-justify-end tw-items-center tw-gap-3"
      >
        <Button
          class="!tw-w-full rmd:!tw-w-[208px]"
          variant="secondary"
          :disabled="state.loading"
          :isLoading="state.loading"
          label="close"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          class="!tw-w-full rmd:!tw-w-[208px]"
          :disabled="state.loading"
          :isLoading="state.loading"
          label="done"
          variant="accent"
          :isActive="true"
          @click="onCloseModal"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import RatingItemCard from "@/modules/properties/components/Cards/RatingItemCard.vue";
import RateChip from "@/modules/properties/components/Cards/RateChip.vue";
import { PROPERTY_STORE } from "@/store/modules/property";

const emits = defineEmits(["on-close", "on-success", "on-delete"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const currentEfficiencyScore = ref("49");
const potentialEfficiencyScore = ref("89");
const currentRating = ref("E");
const potentialRating = ref("B");

const showPotentialInMobile = ref(false);
const rankingChart = ref([
  {
    score: "92+",
    rating: "A",
    bgColor: "#007F3D",
    width: "100px",
    widthSubtract: "70%",
  },
  {
    score: "81-91",
    rating: "B",
    bgColor: "#2C9F29",
    width: "150px",
    widthSubtract: "65%",
  },
  {
    score: "69-80",
    rating: "C",
    bgColor: "#9DCB3C",
    width: "200px",
    widthSubtract: "55%",
  },
  {
    score: "55-68",
    rating: "D",
    bgColor: "#FFF200",
    textColor: "#0C0F4A",
    width: "250px",
    widthSubtract: "50%",
  },
  {
    score: "39-54",
    rating: "E",
    bgColor: "#F7AF1D",
    width: "300px",
    widthSubtract: "45%",
  },
  {
    score: "21-38",
    rating: "F",
    bgColor: "#ED6823",
    isIllegalToRent: true,
    width: "350px",
    widthSubtract: "40%",
  },
  {
    score: "1-20",
    rating: "G",
    bgColor: "#E31D23",
    isIllegalToRent: true,
    widthSubtract: "0%",
  },
]);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const onCloseModal = () => {
  emits("on-close");
};

const setRanks = () => {
  const details = propertyDetails.value;

  const data = details?.localData;
  if (!data) return;

  if (data.epcDomestic) {
    currentEfficiencyScore.value =
      data.epcDomestic?.energy?.current_energy_efficiency;
    potentialEfficiencyScore.value =
      data.epcDomestic?.energy?.potential_energy_efficiency;
    currentRating.value = data.epcDomestic?.energy?.current_energy_rating;
    potentialRating.value = data.epcDomestic?.energy?.potential_energy_rating;
  } else if (data.epcDisplay) {
    currentEfficiencyScore.value =
      data.epcDisplay?.energy?.current_energy_efficiency;
    potentialEfficiencyScore.value =
      data.epcDisplay?.energy?.potential_energy_efficiency;
    currentRating.value = data.epcDisplay?.energy?.current_energy_rating;
    potentialRating.value = data.epcDisplay?.energy?.potential_energy_rating;
  } else if (data.epcNonDomestic) {
    currentEfficiencyScore.value =
      data.epcNonDomestic?.energy?.current_energy_efficiency;
    potentialEfficiencyScore.value =
      data.epcNonDomestic?.energy?.potential_energy_efficiency;
    currentRating.value = data.epcNonDomestic?.energy?.current_energy_rating;
    potentialRating.value =
      data.epcNonDomestic?.energy?.potential_energy_rating;
  }
};

onMounted(() => {
  setRanks();
});
</script>
