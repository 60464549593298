<template>
  <GeneralDialog
    width="475px"
    class="contract_assign_modal"
    @on-close="onCloseModal"
    headerTitle="Notes"
  >
    <template #body>
      <v-form :rules="validation" ref="noteFormRef">
        <v-select
          v-model="selectedPhaseTaskId"
          :items="getTaskList"
          class="input_field"
          bg-color="#fff"
          :rules="validation.select"
          variant="outlined"
          placeholder="select task"
          item-value="id"
          item-title="name"
        >
        </v-select>
        <div class="message_field">
          <label>Note</label>
          <v-textarea
            label=""
            v-model="phaseNote"
            :rules="validation.note"
            variant="outlined"
            class="input_field"
          ></v-textarea>
        </div>
      </v-form>
    </template>
    <template #footer>
      <div class="footer_btns">
        <v-btn
          class="button button-purple-border !tw-capitalize"
          @click="onCloseModal"
          >Cancel</v-btn
        >
        <v-btn
          class="button button-purple !tw-capitalize"
          @click="onSaveTaskNote"
          :isActive="true"
          :loading="Loading"
          >Save</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { computed, onBeforeMount, ref } from "vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { useStore } from "vuex";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
export default {
  components: {
    GeneralDialog,
  },
  props: {
    phaseDetails: { type: Object, default: null },
    isEstimateNote: { type: Boolean, default: false },
    EditDetails: { type: Object, default: null },
  },

  setup(props: any, ctx: any) {
    const selectedPhaseTaskId = ref(null) as any;
    const phaseNote = ref(null) as any;
    const noteFormRef = ref(null) as any;
    const Loading = ref(false);
    const store = useStore();
    const onCloseModal = () => {
      ctx.emit("onCloseModal");
    };
    const validation = ref({
      select: [(v: string) => !!v || "please select task"],
      note: [(v: string) => !!v || "please write note"],
    });
    onBeforeMount(() => {
      if (props.EditDetails) {
        selectedPhaseTaskId.value =
          props.EditDetails?.task?.id || props.EditDetails?.phase?.id || null;
        phaseNote.value = props.EditDetails?.note || "";
      }
    });
    const getTaskList = computed(() => {
      if (props.isEstimateNote) {
        return [
          ...(props.phaseDetails?.name ? [props.phaseDetails] : []),
          ...props.phaseDetails?.projectStageTasks,
        ];
      } else {
        return props.phaseDetails.estimationPhaseTasks;
      }
    });
    const onSaveTaskNote = async () => {
      const isFormValid = await noteFormRef.value.validate();
      if (!isFormValid.valid) return;
      Loading.value = true;
      try {
        if (props.EditDetails?.id) {
          // Update existing note
          await tradeEstimateService.updateEstimatePhaseTaskNote({
            phaseId: props.phaseDetails.id,
            taskId:
              selectedPhaseTaskId.value === props.phaseDetails.id
                ? null
                : selectedPhaseTaskId.value,
            noteId: props.EditDetails?.id,
            note: phaseNote.value,
          });
          await store.dispatch(
            `${JOB_TEMPLATE_STORE}/setEstimatePhaseNotes`,
            props.phaseDetails?.id
          );
          ctx.emit("on-update-note");
        } else {
          if (props.isEstimateNote) {
            await tradeEstimateService.createEstimatePhaseTaskNote({
              phaseId: props.phaseDetails.id,
              taskId:
                selectedPhaseTaskId.value === props.phaseDetails.id
                  ? null
                  : selectedPhaseTaskId.value,
              note: phaseNote.value,
              isEstimateNote: true,
            });
            await store.dispatch(
              `${JOB_TEMPLATE_STORE}/setEstimatePhaseNotes`,
              props.phaseDetails?.id
            );
            ctx.emit("on-save");
          } else {
            await tradeEstimateService.createEstimatePhaseTaskNote({
              phaseId: props.phaseDetails.id,
              taskId: selectedPhaseTaskId.value,
              note: phaseNote.value,
              isEstimateNote: false,
            });
            await store.dispatch(
              `${JOB_TEMPLATE_STORE}/setEstimatePhaseNotes`,
              props.phaseDetails?.id
            );
            ctx.emit("on-save");
          }
        }
        ctx.emit("onCloseModal", true);
      } catch (error) {
        console.log("add task note error", error);
      } finally {
        Loading.value = false;
      }
    };
    return {
      onCloseModal,
      selectedPhaseTaskId,
      phaseNote,
      validation,
      noteFormRef,
      onSaveTaskNote,
      getTaskList,
      Loading,
    };
  },
};
</script>
<style lang="scss" scoped>
.input_field {
  :deep(.v-input__control) {
    .v-field {
      padding: 0;
      border: 1px solid rgba($bordercolor, 1);
      border-radius: 8px;
      .v-field__field {
        .v-field__input {
          min-height: 32px;
          padding: 4px 16px;
          -webkit-mask-image: none;
          mask-image: none;
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}
.message_field {
  margin-top: 16px;
  label {
    @include fluidFont(14, 14, 1.2);
    font-weight: 500;
    color: rgba($blueDark, 1);
  }
}
.footer_btns {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  .button {
    width: auto;
  }
}
</style>
