<template>
  <GeneralDialog @on-close="onCloseModal" class="tw-w-[448px] tw-max-w-full">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full"
        >
          <TrashIcon
            fillOpacity="1"
            fill="#F45454"
            class="tw-w-[27px] tw-h-[35px]"
          />
          <Text variant="h4" textAlign="center">Delete DBS Check?</Text>
          <Text variant="p" textWeight="400" textAlign="center"
            >Are you sure you want to delete this DBS Check?</Text
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-justify-end tw-items-center tw-gap-3 tw-pt-4 tw-border-t-dashed-custom"
      >
        <Button
          variant="secondary"
          :disabled="state.loading"
          label="cancel"
          class="!tw-w-auto"
          @click="onCloseModal"
        />
        <Button
          variant="accent"
          :isLoading="state.loading"
          label="Delete"
          :isActive="true"
          class="!tw-w-auto"
          @click="onDelete"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";

const emits = defineEmits(["on-close", "on-success", "on-delete"]);
// const props = defineProps(["type", "label"]);
const props = defineProps({
  id: {
    type: Number,
  },
  label: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const onCloseModal = () => {
  emits("on-close");
};

const onDelete = async () => {
  try {
    if (props.id) {
      state.loading = true;
      const payload = {
        userId: user.value?.id,
        id: props.id,
      };

      console.log(payload, "payload delete");
      await store.dispatch(`${USER_STORE}/deleteTradePassportDbsById`, payload);

      emits("on-success");
    } else {
      onCloseModal();
      return;
    }
  } catch (error) {
    console.error(error);
  } finally {
    state.loading = false;
  }
};
</script>
