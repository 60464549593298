<template>
  <div class="calendar_event_modal shadow">
    <div class="todo_list">
      <div class="todo_list__title">
        <h6>{{ ` Batch -${batchData.id}` }}</h6>
        <p>
          {{ `${batchData.startTime} - ${batchData.endTime}` }}
        </p>
      </div>
      <UserDetailCard
        :name="batchData?.contractor?.name"
        :address="
          getContractorAddressDetail(
            batchData?.contractor?.user?.userBusinessDetails
          )?.postCode || ''
        "
      />
      <div class="events_list" v-if="batchData.jobs?.length">
        <div v-for="item in batchData?.jobs" :key="item.id" class="event_items">
          <div class="event_content">
            <h3>Customer: {{ item?.customerName }}</h3>
            <p>
              <v-icon icon="mdi-map-marker" color="#0c0f4a" /> address:
              {{ item?.property?.postcode }}
            </p>
          </div>
          <v-btn
            class="button"
            append-icon="mdi-chevron-right"
            @click="onClickViewJob(item.id)"
            variant="text"
          >
            view job
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import moment from "moment";
import { defineProps, computed } from "vue";

import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
const props = defineProps({ batchData: Object });
import { useRouter } from "vue-router";
import { NWG_JOB_DETAIL_ROUTE } from "@/modules/nwg-job/routes";
import UserDetailCard from "@/core/components/common/UserDetailCard.vue";
const router = useRouter();
const getMonthData = (date) => {
  const formateData = moment(date, "YYYY-MM-DD");
  return formateData.format("MMM D");
};
const getPhaseProgressData = (phase) => {
  if (!phase?.estimationPhaseTasks?.length) {
    return phase?.phaseCompletionStatus ? 100 : 0;
  } else {
    const completedSubTask = phase?.estimationPhaseTasks.filter(
      (task) => task.taskCompletionStatus
    );
    return (completedSubTask.length * 100) / phase.estimationPhaseTasks.length;
  }
};

const getProjectProgress = computed(() => {
  if (!props?.batchData?.estimationPhase?.length) return "0%";
  const totalPhaseCompletion = props.batchData.estimationPhase.reduce(
    (total, phase) => {
      return (total += getPhaseProgressData(phase));
    },
    0
  );
  return `${(
    totalPhaseCompletion / props.batchData?.estimationPhase?.length
  )?.toFixed(2)}%`;
});

const getContractorAddressDetail = (userBusinessDetails) => {
  if (userBusinessDetails?.type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
    return {
      type: "Limited Company",
      postCode: userBusinessDetails?.businessPostcode,
      adress: userBusinessDetails?.businessAddress,
    };
  }
  return {
    type: "Solo Trader",
    postCode: userBusinessDetails?.residencyPostcode,
    adress: userBusinessDetails?.residencyAddressLine,
  };
};
const onClickViewJob = (jobId) => {
  router.push({ name: NWG_JOB_DETAIL_ROUTE, params: { id: jobId } });
};
</script>

<style lang="scss" scoped>
.calendar_event_modal.shadow {
  text-align: left;
  position: relative;
  width: 240px;
  box-shadow: 0px 4px 20px 0px #2a2a2a1a;
  background-color: rgba($white, 1);
  border-radius: 8px;
  .todo_list {
    padding: 15px;
    //  border-bottom: 1px solid rgba($blueDark, 0.2);
    .todo_list__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dotted rgba(12, 15, 74, 0.2);
      padding-bottom: 14px;
      h6 {
        @include fluidFont(14, 14, 18px);
        font-weight: 600;
        color: rgba($blueDark, 1);
      }
      p {
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
        margin: 0;
      }
    }
    :deep(.profile__card) {
      border: 1px solid rgb(223, 223, 223);
      padding: 16px;
      border-radius: 8px;
      display: flex;
      gap: 8px;
      align-items: flex-start;
      margin-top: 16px;
      width: 100%;
      max-width: 100%;
      .profile__img {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: green;
      }
      .profile__card__content {
        text-align: left;
        width: calc(100% - 24px);
        max-width: 100%;
        h6 {
          @include fluidFont(14, 14, 18px);
          color: rgba($blueDark, 1);
          font-weight: 700;
        }
        p {
          @include fluidFont(12, 12, 16px);
          color: rgba($blueDark, 1);
          font-weight: 500;
        }
      }
    }
  }
  .events_list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0 0;
    max-height: 190px;
    overflow: auto;
    .event_items {
      border-bottom: 1px solid rgba($bordercolor, 1);
      padding: 0 0 8px;
      .event_content {
        h3 {
          @include fluidFont(14, 14, 18px);
          color: rgba($blueDark, 1);
          font-weight: 500;
        }
        p {
          @include fluidFont(12, 12, 16px);
          color: rgba($blueDark, 1);
          font-weight: 500;
          .v-icon {
            font-size: 14px;
            color: rgba($blueDark, 1);
          }
        }
      }
      .button {
        min-height: auto;
        height: auto;
        padding: 0;
        @include fluidFont(8, 8, 12px);
        font-weight: 400;
        letter-spacing: normal;
        color: rgba($orange, 1);
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px 0 0;
      }
    }
  }
}
// .member__card__users {
//   padding: 15px;
//   .member__card__users__img {
//     display: flex;
//     align-items: center;
//     width: 100%;
//     max-width: 100%;
//     li {
//       width: 32px;
//       height: 32px;
//       border-radius: 100%;
//       overflow: hidden;
//       margin-left: -20px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       background-color: #e6e6e6;
//       &:first-child {
//         margin: 0;
//       }
//       img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//       }
//       span {
//         @include fluidFont(12, 12, 1.2);
//         font-weight: 400;
//         color: rgba($blueDark, 1);
//       }
//     }
//   }
// }
// .calendar_time {
//   @include fluidFont(12, 12, 1.2);
//   font-weight: 400;
//   color: rgba($PrimaryPurple, 1);
//   margin-top: 15px;
//   display: inline-block;
//   margin-right: 20px;
// }
// .calendar_event_modal_footer {
//   display: inline-flex;

//   .calendar_edit_wrapper {
//     display: flex;
//     gap: 8px;
//     align-items: center;
//     .edit_modal {
//       width: 24px;
//       height: 24px;
//       border-radius: 100%;
//       background-color: rgba($blueDark, 0.1);
//     }
//     .delete_modal {
//       width: 24px;
//       height: 24px;
//       border-radius: 100%;
//       background-color: rgba($PrimaryRed, 0.1);
//     }
//   }
// }
</style>
