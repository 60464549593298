<template>
  <div class="tw-flex tw-items-center tw-gap-4">
    <AddressItem
      :showAddAction="true"
      :phaseIndex="phaseIndex"
      @on-save-project-properties="saveProperties"
    />
  </div>
</template>
<script setup>
import AddressItem from "@/modules/project/components/common/AddressItem.vue";

const props = defineProps({
  phaseIndex: Number,
});
const emits = defineEmits([" on-save-phase-properties"]);
const saveProperties = (payload) => {
  emits("on-save-phase-properties", payload);
};
</script>
<style lang="scss" scoped></style>
