import {
  ProjectJobStatusEnum,
  ProjectPhaseType,
} from "@/core/enums/ProjectsEnum";
import jobTemplateService from "@/core/services/job-template.service";
import ProjectEstimationService from "@/core/services/project-estimation.service";
import {
  filterSubContracts,
  getSubContractorPhases,
  getParentContractorPhases,
  getRandomColor,
  getTotalDuration,
  getCurrentConTractorPhases,
  getImageStringToImageURL,
} from "@/core/utils/common";
import { Commit, Dispatch } from "vuex";
import workstation, { WORKSTATION } from "../workstation";
import { USER_STORE } from "../user";
import estimationService from "@/core/services/estimation.service";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import {
  AgreeTermsConsumerDisclosure,
  ContractorRequestStatusEnum,
  EstimatePhaseType,
} from "@/core/enums/estimateEnum";
import estimate from "@/core/constants/estimate";
import { PROJECT_STORE } from "../project";
import { JOBS_STORE } from "../jobs";
import { JOB_TEMPLATE_STORE } from ".";

export default {
  getMyJobTemplates({ commit }: { commit: Commit }, payload: any) {
    return jobTemplateService
      .getMyJobTemplates()
      .then((response) => {
        commit("setJobsTemplates", response?.data);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getJobTemplate(context: any, id: number) {
    return jobTemplateService
      .getJobTemplate(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async createMyJobTemplate(context: any, formData: any) {
    try {
      await jobTemplateService.createMyJobTemplate(formData);
    } catch (error) {
      console.log(error);
    }
  },

  async updateMyJobTemplate(context: any, options: any) {
    const { id, formData } = options;
    try {
      return await jobTemplateService.updateMyJobTemplate(id, formData);
    } catch (error) {
      console.log(error);
    }
  },

  async deleteMyJobTemplate(context: any, id: number) {
    try {
      return await jobTemplateService.deleteMyJobTemplate(id);
    } catch (error) {
      console.log("error ", error);
    }
  },
  setEstimateForm({ commit, rootGetters }: any, value: any) {
    commit("setEstimateForm", value);

    const generateUniqueId = () => {
      return `phase_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
    };

    const updatedValue = {
      ...value,
      projectStages: value.projectStages.map((stage: any) => ({
        ...stage,
        phaseUniqueId: stage.id || stage.phaseUniqueId || generateUniqueId(),
      })),
    };

    commit("setDefaultEstimateFormDetails", updatedValue);
  },

  setSelectedPhaseIndex({ commit }: any, value: any) {
    commit("setSelectedPhaseIndex", value);
  },

  setTemplateName({ commit }: any, value: any) {
    commit("setTemplateName", value);
  },

  setSelectedJobTemplate({ commit }: any, value: any) {
    commit("setSelectedJobTemplate", value);
  },

  addProjectStage(
    {
      commit,
      state,
      dispatch,
      rootGetters,
    }: {
      commit: Commit;
      state: any;
      dispatch: any;
      rootGetters: any;
    },
    phaseTypeId: EstimatePhaseType
  ) {
    const generateUniqueId = () => {
      return `phase_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
    };
    const newProjectStage = {
      id: null,
      name: "",
      stageType:
        phaseTypeId === EstimatePhaseType.MATERIALS
          ? estimate.MATERIAL
          : estimate.LABOUR,
      totalDuration: 0,
      durationType: 1,
      stageCost: 0,
      projectStageTasks:
        phaseTypeId === EstimatePhaseType.MATERIALS
          ? [
              {
                id: null,
                name: "",
                taskCost: 0,
                amountToDisplay: "",
                duration: null,
                durationType: 1,
                total: 0,
                startDate: null,
                endDate: null,
                note: "",
                materialUnit: "0",
              },
            ]
          : [],
      isNew: true,
      isUsePhaseTotal: null,
      requiredDeposit: false,
      vat: 0,
      subTotal: "0",
      subTotalDisplay: "",
      note: "",
      startDate: state.formData?.suggestedStartDate || null,
      color: getRandomColor(),
      phaseOrder: 0,
      attachments: [],
      phaseUniqueId: generateUniqueId(),

      user: {
        id: rootGetters[`${USER_STORE}/user`]?.id,
        firstName: rootGetters[`${USER_STORE}/user`]?.firstName,
        lastName: rootGetters[`${USER_STORE}/user`]?.lastName,
      },
      workStation: {
        id: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id,
        name: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.name,
        profileImage:
          rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.profileImage,
      },
      grantAmount: 0,
      isPercentage: false,
      isGranted: false,
      assignPhaseWorkStation: null,
    };
    commit("addProjectStageMutation", newProjectStage);
  },
  addIBGProjectPhase(
    {
      commit,
      state,
      dispatch,
      rootGetters,
    }: {
      commit: Commit;
      state: any;
      dispatch: any;
      rootGetters: any;
    },

    payload: { phaseTypeId: EstimatePhaseType; policyIndex: number }
  ) {
    if (!payload.phaseTypeId) return;
    const generateUniqueId = () => {
      return `phase_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
    };
    const newProjectStage = {
      id: null,
      name: "",
      stageType:
        payload.phaseTypeId === EstimatePhaseType.LABOUR
          ? estimate.LABOUR
          : estimate.MATERIAL,
      totalDuration: 0,
      durationType: 1,
      stageCost: 0,
      projectStageTasks:
        payload.phaseTypeId === EstimatePhaseType.MATERIALS
          ? [
              {
                id: null,
                name: "",
                taskCost: 0,
                amountToDisplay: "",
                duration: null,
                durationType: 1,
                total: 0,
                startDate: null,
                endDate: null,
                note: "",
                materialUnit: "0",
              },
            ]
          : [],
      isNew: true,
      isUsePhaseTotal: null,
      requiredDeposit: false,
      vat: 0,
      subTotal: "0",
      subTotalDisplay: "",
      note: "",
      startDate: state.formData?.suggestedStartDate || null,
      color: getRandomColor(),
      phaseOrder: 0,
      attachments: [],
      phaseUniqueId: generateUniqueId(),

      user: {
        id: rootGetters[`${USER_STORE}/user`]?.id,
        firstName: rootGetters[`${USER_STORE}/user`]?.firstName,
        lastName: rootGetters[`${USER_STORE}/user`]?.lastName,
      },
      workStation: {
        id: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id,
        name: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.name,
        profileImage:
          rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.profileImage,
      },
      grantAmount: 0,
      isPercentage: false,
      isGranted: false,
      assignPhaseWorkStation: null,
    };
    if (!state?.estimateForm?.estimationIBGData) {
      dispatch("initializeIBGPolicy");
    }
    commit("addIBGProjectPhase", {
      data: newProjectStage,
      policyIndex: payload.policyIndex,
    });
  },
  initializeIBGPolicy({
    commit,
    rootGetters,
  }: {
    commit: Commit;
    rootGetters: any;
  }) {
    const estimationIBGData = {
      id: null,
      total: 0,
      user: {
        id: rootGetters[`${USER_STORE}/user`]?.id,
        firstName: rootGetters[`${USER_STORE}/user`]?.firstName,
        lastName: rootGetters[`${USER_STORE}/user`]?.lastName,
      },
      workStation: {
        id: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id,
        name: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.name,
        profileImage:
          rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.profileImage,
      },
      estimationIbgPolicies: [
        {
          id: null,
          estimationIGBPhaseId: null,
          policyType: null,
          propertyType: null,
          propertyAddress: null,
          numberOfPolicy: 0,
          totalContractValue: 0,
          totalPolicyCost: 0,
          totalServiceText: 0,
          user: {
            id: rootGetters[`${USER_STORE}/user`]?.id,
            firstName: rootGetters[`${USER_STORE}/user`]?.firstName,
            lastName: rootGetters[`${USER_STORE}/user`]?.lastName,
          },
          workStation: {
            id: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id,
            name: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.name,
            profileImage:
              rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.profileImage,
          },
          phases: [],
          property: null,
        },
      ],
    };
    commit("initializeIBGPolicy", estimationIBGData);
  },

  setShowPhaseUploadAttachmentModal({ commit }: any, value: any) {
    commit("setShowPhaseUploadAttachmentModal", value);
  },

  setShowTaskUploadAttachmentModal({ commit }: any, value: any) {
    commit("setShowTaskUploadAttachmentModal", value);
  },

  setShowCreateTemplateConfirmationModal({ commit }: any, value: any) {
    commit("setShowCreateTemplateConfirmationModal", value);
  },

  setShowUseTemplateModal({ commit }: any, value: any) {
    commit("setShowUseTemplateModal", value);
  },

  setShowSubmitEstimateConfirmationModal({ commit }: any, value: any) {
    commit("setShowSubmitEstimateConfirmationModal", value);
  },

  setIsOnEstimateForm({ commit }: any, value: any) {
    commit("setIsOnEstimateForm", value);
  },

  setConfirmDeleteModal({ commit }: any, value: any) {
    commit("setConfirmDeleteModal", value);
  },

  setIsFormReady({ commit }: any, value: any) {
    commit("setIsFormReady", value);
  },

  setConfirmLoadTemplateModal({ commit }: any, value: any) {
    commit("setConfirmLoadTemplateModal", value);
  },

  setSelectedTemplateId({ commit }: any, value: any) {
    commit("setSelectedTemplateId", value);
  },

  setFormData({ commit }: any, value: any) {
    commit("setFormData", value);
  },

  setSelectDepositModal({ commit }: any, value: any) {
    commit("setSelectDepositModal", value);
  },

  setIsTemplateCreate({ commit }: any, value: any) {
    commit("setIsTemplateCreate", value);
  },

  setIsEstimateLoadedModified({ commit }: any, value: any) {
    commit("setIsEstimateLoadedModified", value);
  },
  setIsEstimateSubmit({ commit }: any, value: any) {
    commit("setIsEstimateSubmit", value);
  },

  setHasLoadedTemplate({ commit }: any, value: any) {
    commit("setHasLoadedTemplate", value);
  },

  setLoadedEstimate({ commit }: any, value: any) {
    commit("setLoadedEstimate", value);
  },

  setIsFormReadOnly({ commit }: any, value: any) {
    commit("setIsFormReadOnly", value);
  },

  setLockTemplateModifiedChecker({ commit }: any, value: any) {
    commit("setLockTemplateModifiedChecker", value);
  },

  setSelectedAssignMembers({ commit }: any, value: any) {
    commit("setSelectedAssignMembers", value);
  },

  setAssignMembersModal({ commit }: any, value: any) {
    commit("setAssignMembersModal", value);
  },

  setAssignMembers({ commit }: any, value: any) {
    commit("setAssignMembers", value);
  },

  setMemberList({ commit }: any, value: any) {
    commit("setMemberList", value);
  },

  setShowUpdateEstimateConfirmationModal({ commit }: any, value: any) {
    commit("setShowUpdateEstimateConfirmationModal", value);
  },
  setSuggestedStartDateIntially({ commit }: any) {
    commit("setSuggestedStartDateIntially");
  },

  async dragUpdateProjectStages({ commit }: any, updatedPhasess: any) {
    commit("dragUpdateProjectStages", updatedPhasess);
  },

  async submitProjectEstimate(
    {
      commit,
      state,
      dispatch,
    }: { commit: Commit; state: any; dispatch: Dispatch },
    payload: any
  ) {
    const response = (await ProjectEstimationService.createProjectEstimation(
      payload
    )) as any;

    if (response) {
      const formData = {
        ...state.formData,
        id: response.id,
      };
      dispatch("setFormData", formData);

      const estimateForm = {
        projectStages: [],
      } as any;
      if (
        response?.estimationPhaseData &&
        response?.estimationPhaseData.length
      ) {
        response?.estimationPhaseData.map((estimate: any, index: number) => {
          let estimateIndex = state.estimateForm.projectStages.findIndex(
            (data: any) => data.id === estimate.id
          );
          if (estimateIndex === -1) {
            estimateIndex = index;
          }

          const updateEstimateRecord = {
            ...state.estimateForm.projectStages[estimateIndex],
            id: estimate?.id,
            // projectStageTasks: [],
            estimationPhaseProperties:
              estimate?.estimationPhaseProperties &&
              estimate?.estimationPhaseProperties?.length
                ? estimate.estimationPhaseProperties.map((entry: any) => {
                    return {
                      id: entry.id || null,
                      propertyId: entry.property.id,
                    };
                  })
                : [],
          };

          if (
            estimate.estimationPhaseTasks &&
            estimate.estimationPhaseTasks.length
          ) {
            updateEstimateRecord.projectStageTasks =
              estimate.estimationPhaseTasks.map((task: any, index: number) => {
                let estimateTaskIndex = state.estimateForm.projectStages[
                  estimateIndex
                ].projectStageTasks.findIndex(
                  (data: any) => data.id === task.id
                );

                if (estimateTaskIndex === -1) {
                  estimateTaskIndex = index;
                }

                const phaseTaskRecord = {
                  ...state.estimateForm.projectStages[estimateIndex]
                    .projectStageTasks[index],
                  id: task?.id,
                  estimationPhaseTasksProperties:
                    task.estimationPhaseTasksProperties &&
                    task.estimationPhaseTasksProperties.length
                      ? task.estimationPhaseTasksProperties.map(
                          (entry: any) => {
                            return {
                              id: entry.id || null,
                              propertyId: entry.property.id,
                            };
                          }
                        )
                      : [],
                };

                return phaseTaskRecord;
                // updateEstimateRecord.projectStageTasks.push(phaseTaskRecord);
              });
            const currentTaskLength =
              state.estimateForm.projectStages[estimateIndex].projectStageTasks
                .length;
            if (
              currentTaskLength > updateEstimateRecord.projectStageTasks.length
            ) {
              updateEstimateRecord.projectStageTasks = [
                ...updateEstimateRecord.projectStageTasks,
                ...state.estimateForm.projectStages[
                  estimateIndex
                ].projectStageTasks.slice(
                  updateEstimateRecord.projectStageTasks.length
                ),
              ];
            }
          }

          estimateForm.projectStages.push(updateEstimateRecord);
        });
      }

      if (response.estimationIBGData) {
        const updateEstimateIBGData = {
          ...state.estimateForm.estimationIBGData,
          id: response.estimationIBGData.id,
        };

        updateEstimateIBGData.estimationIbgPolicies =
          response?.estimationIBGData?.estimationIbgPolicies.map(
            (policy: any, pIndex: number) => {
              let policyIndex =
                state.estimateForm?.estimationIBGData?.estimationIbgPolicies?.findIndex(
                  (data: any) => {
                    data.id === policy.id;
                  }
                );
              if (policyIndex === -1) {
                policyIndex = pIndex;
              }

              const policyData = {
                ...state.estimateForm?.estimationIBGData?.estimationIbgPolicies[
                  policyIndex
                ],
                property: policy?.property,
                phases: [],
                id: policy.id,
              };

              if (policy.estimationPhase && policy.estimationPhase.length) {
                policy.estimationPhase.map((estimate: any, index: number) => {
                  let estimateIndex =
                    state.estimateForm.estimationIBGData.estimationIbgPolicies[
                      policyIndex
                    ].phases.findIndex((data: any) => data.id === estimate.id);
                  if (estimateIndex === -1) {
                    estimateIndex = index;
                  }

                  const updateEstimateRecord = {
                    ...state.estimateForm.estimationIBGData
                      .estimationIbgPolicies[policyIndex].phases[estimateIndex],
                    id: estimate?.id,
                    // projectStageTasks: [],
                    estimationPhaseProperties:
                      estimate?.estimationPhaseProperties &&
                      estimate?.estimationPhaseProperties?.length
                        ? estimate.estimationPhaseProperties.map(
                            (entry: any) => {
                              return {
                                id: entry.id || null,
                                propertyId: entry.property.id,
                              };
                            }
                          )
                        : [],
                  };

                  if (
                    estimate.estimationPhaseTasks &&
                    estimate.estimationPhaseTasks.length
                  ) {
                    updateEstimateRecord.projectStageTasks =
                      estimate.estimationPhaseTasks.map(
                        (task: any, index: number) => {
                          let estimateTaskIndex =
                            state.estimateForm.estimationIBGData.estimationIbgPolicies[
                              policyIndex
                            ].phases[estimateIndex].projectStageTasks.findIndex(
                              (data: any) => data.id === task.id
                            );

                          if (estimateTaskIndex === -1) {
                            estimateTaskIndex = index;
                          }

                          const phaseTaskRecord = {
                            ...state.estimateForm.estimationIBGData
                              .estimationIbgPolicies[policyIndex].phases[
                              estimateIndex
                            ].projectStageTasks[estimateTaskIndex],
                            id: task?.id,
                            estimationPhaseTasksProperties:
                              task.estimationPhaseTasksProperties &&
                              task.estimationPhaseTasksProperties.length
                                ? task.estimationPhaseTasksProperties.map(
                                    (entry: any) => {
                                      return {
                                        id: entry.id || null,
                                        propertyId: entry.property.id,
                                      };
                                    }
                                  )
                                : [],
                          };

                          return phaseTaskRecord;
                          // updateEstimateRecord.projectStageTasks.push(phaseTaskRecord);
                        }
                      );
                    const currentTaskLength =
                      state.estimateForm.estimationIBGData
                        .estimationIbgPolicies[policyIndex].phases[
                        estimateIndex
                      ].projectStageTasks.length;
                    if (
                      currentTaskLength >
                      updateEstimateRecord.projectStageTasks.length
                    ) {
                      updateEstimateRecord.projectStageTasks = [
                        ...updateEstimateRecord.projectStageTasks,
                        ...state.estimateForm.estimationIBGData.estimationIbgPolicies[
                          policyIndex
                        ].phases[estimateIndex].projectStageTasks.slice(
                          updateEstimateRecord.projectStageTasks.length
                        ),
                      ];
                    }
                  }

                  policyData.phases.push(updateEstimateRecord);
                });
              }
              return policyData;
            }
          );
        estimateForm["estimationIBGData"] = updateEstimateIBGData;
      }

      dispatch("setEstimateForm", estimateForm);
    }
    commit("setHoldEstimateAddTaskApi", false);
    return null;
  },
  async removeEstimationPhase({ commit }: { commit: Commit }, payload: any) {
    const { estimateId } = payload;
    if (estimateId) {
      return await ProjectEstimationService.deleteEstimationTask(estimateId);
    } else {
      return null;
    }
  },
  async removeEstimationPhaseTask(
    { commit }: { commit: Commit },
    payload: any
  ) {
    const { estimatePhaseTaskId } = payload;
    return await ProjectEstimationService.deleteEstimationPhaseTask(
      estimatePhaseTaskId
    );
  },

  async removeEstimationProperty({ commit }: { commit: Commit }, payload: any) {
    const { estimatePropertyId } = payload;
    return await ProjectEstimationService.deleteEstimationProperty(
      estimatePropertyId
    );
  },
  async deleteEstimationAttachedProperty(
    { commit }: { commit: Commit },
    estimateAttachedPropertyRecordId: number
  ) {
    if (!estimateAttachedPropertyRecordId) return;
    return await ProjectEstimationService.deleteEstimationAttachedProperty(
      estimateAttachedPropertyRecordId
    );
  },
  setEstimatePhaseCost({ commit }: { commit: Commit }, payload: any) {
    const { phaseIndex, newValue } = payload;
    commit("setEstimatePhaseCost", { phaseIndex, newValue });
  },

  async getProjectEstimateByProjectId(
    {
      commit,
      rootGetters,
      dispatch,
    }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    payload: {
      projectId: number;
      filterStatus: boolean;
      isChangeRequestRoute?: boolean;
    }
  ) {
    const response =
      (await ProjectEstimationService.getProjectEstimateByProjectId(
        payload.projectId,
        payload.filterStatus
      )) as any;
    if (response.length) {
      // response[0]?.agreeTermsConsumerDisclosure ===
      // AgreeTermsConsumerDisclosure.REJECTED ||
      if (
        response[0]?.agreeTermsConsumerDisclosure ===
        AgreeTermsConsumerDisclosure.NOT_ADDED
      ) {
        dispatch("setLockTemplateModifiedChecker", false);
      } else dispatch("setLockTemplateModifiedChecker", true);

      dispatch("setFormData", {
        suggestedStartDate: response[0]?.suggestedStartDate,
        grantType: response[0]?.grantType,
        grantName: response[0]?.grantName,
        grantAmount: response[0]?.grantAmount,
        id: response[0]?.id,
      });

      commit("setInvitedContractorsList", response[0]?.inviteContractors);
      commit("setTotalPhases", response[0]?.estimationPhase);
      commit("setEstimateResponse", response[0]);

      const estimateForm = {
        projectStages: [],
      } as any;

      const currentUserWorkStationId =
        rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id;

      const subContractorPhases = getSubContractorPhases(
        currentUserWorkStationId,
        response[0]?.inviteContractors,
        response[0]?.estimationPhase
      );
      const parentContractorPhases = getParentContractorPhases(
        currentUserWorkStationId,
        response[0]?.inviteContractors,
        response[0]?.estimationPhase
      );
      const currentConTractorPhases = getCurrentConTractorPhases(
        currentUserWorkStationId,
        response[0]?.estimationPhase
      );

      commit("setSubContractorsPhases", subContractorPhases);
      commit("setparentContractorPhases", parentContractorPhases);

      const finalArray = [
        ...subContractorPhases,
        ...parentContractorPhases,
        ...currentConTractorPhases,
      ];
      const estimationPhase = finalArray;

      estimationPhase.forEach((phase: any) => {
        const estimatePhaseRecord = {
          id: phase?.id,
          stageType:
            phase.phaseType === ProjectPhaseType.LABOUR
              ? "labour"
              : "materials",
          subTotal: phase?.subTotal,
          phaseOrder: phase?.phaseOrder,
          phaseStatus: phase?.phaseStatus,
          name: phase?.name,
          durationType: phase?.durationType,
          startDate: phase?.phaseStartDate,
          totalDuration: getTotalDuration(
            phase?.phaseStartDate,
            phase?.phaseEndDate,
            phase?.durationType
          ),
          vat: phase?.vat,
          projectStageTasks: [] as object[],
          color: phase?.color,
          estimationPhaseProperties: [] as any,
          assignPhaseWorkStation: phase?.assignPhaseWorkStation,
          user: {
            id: phase?.user.id,
            firstName: phase?.user.firstName,
            lastName: phase?.user.lastName,
          },
          workStation: {
            id: phase?.workStation?.id,
            name: phase?.workStation?.name,
            profileImage: phase?.workStation?.profileImage,
          },
          attachments: phase?.estimatePhaseAttachment,
          acceptedBy: phase?.acceptedBy,
          grantAmount: phase?.grantAmount,
          isPercentage: phase?.isPercentage,
          isGranted: phase?.isGranted,
          escrowStatus: phase?.escrowStatus,
          updatedSubTotal: phase?.updatedSubTotal,
          updatedVat: phase?.updatedVat,
          phasePaidTotal: phase?.phasePaidTotal,
          assignTeamMembers: phase?.assignTeamMembers || [],
        };
        phase?.estimationPhaseTasks.forEach((subTask: any) => {
          const estimatePhaseSubTaskRecords = {
            id: subTask?.id,
            name: subTask?.name,
            taskCost: subTask?.taskCost && subTask?.taskCost.toString(),
            durationType: subTask?.durationType,
            duration: getTotalDuration(
              subTask?.phaseTaskStartDate,
              subTask?.phaseTaskEndDate,
              subTask?.durationType
            ),
            materialUnit: subTask?.materialUnit,
            estimationPhaseTasksProperties: [] as any,
            assignedPhaseTo: subTask?.assignedPhaseTo,
          };
          subTask.estimationPhaseTasksProperties.forEach((property: any) => {
            estimatePhaseSubTaskRecords?.estimationPhaseTasksProperties.push({
              id: property.id,
              propertyId: property.property.id,
            });
          });
          estimatePhaseRecord.projectStageTasks.push(
            estimatePhaseSubTaskRecords
          );
        });
        estimateForm.projectStages.push(estimatePhaseRecord);
        phase.estimationPhaseProperties.forEach((property: any) => {
          estimatePhaseRecord?.estimationPhaseProperties?.push({
            id: property.id,
            propertyId: property.property.id,
          });
        });
      });

      const currentProjectDetails = rootGetters[`${JOBS_STORE}/getJobDetails`];

      const getUpdateChangeRequest =
        rootGetters[`${JOB_TEMPLATE_STORE}/getUpdateChangeRequest`].data;

      if (
        [ProjectJobStatusEnum.IN_PROGRESS].includes(
          currentProjectDetails?.projectJobStatus?.id
        ) &&
        getUpdateChangeRequest &&
        payload?.isChangeRequestRoute
      ) {
        const updatedEstimateForm = getUpdateChangeRequest?.updatedEstimate
          ? JSON.parse(getUpdateChangeRequest?.updatedEstimate)
          : {
              projectStages: [],
            };
        dispatch("setEstimateForm", updatedEstimateForm);
      } else {
        dispatch("setEstimateForm", estimateForm);
      }
    } else {
      dispatch("resetFormAndData");
    }
    if (response[0]?.estimationIBGData?.length) {
      dispatch("setIBGData", response[0]?.estimationIBGData[0]);
    }

    return response;
  },

  async getProjectEstimateByEstimateId(
    {
      commit,
      dispatch,
      rootGetters,
    }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    estimationId: number
  ) {
    const response =
      (await ProjectEstimationService.getProjectEstimateByEstimationId(
        estimationId
      )) as any;

    if (response) {
      // response.agreeTermsConsumerDisclosure ===
      //   AgreeTermsConsumerDisclosure.REJECTED ||
      if (
        response?.agreeTermsConsumerDisclosure ===
        AgreeTermsConsumerDisclosure.NOT_ADDED
      ) {
        dispatch("setLockTemplateModifiedChecker", false);
      } else dispatch("setLockTemplateModifiedChecker", true);

      commit("setInvitedContractorsList", response?.inviteContractors);
      commit("setTotalPhases", response?.estimationPhase);
      commit("setEstimateResponse", response);

      dispatch("setFormData", {
        suggestedStartDate: response?.suggestedStartDate,
        grantType: response?.grantType,
        grantName: response?.grantName,
        grantAmount: response?.grantAmount,
        project: response?.project,
        id: response?.id,
      });

      const estimateForm = {
        projectStages: [],
      } as any;

      const currentUserWorkStationId =
        rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id;

      const subContractorPhases = getSubContractorPhases(
        currentUserWorkStationId,
        response?.inviteContractors,
        response?.estimationPhase
      );
      const parentContractorPhases = getParentContractorPhases(
        currentUserWorkStationId,
        response?.inviteContractors,
        response?.estimationPhase
      );
      const currentConTractorPhases = getCurrentConTractorPhases(
        currentUserWorkStationId,
        response?.estimationPhase
      );
      commit("setSubContractorsPhases", subContractorPhases);
      commit("setparentContractorPhases", parentContractorPhases);

      const finalArray = [
        ...subContractorPhases,
        ...parentContractorPhases,
        ...currentConTractorPhases,
      ];
      const estimationPhase = finalArray;

      estimationPhase.forEach((phase: any) => {
        const estimatePhaseRecord = {
          id: phase?.id,
          stageType:
            phase.phaseType === ProjectPhaseType.LABOUR
              ? "labour"
              : "materials",
          subTotal: phase?.subTotal,
          phaseOrder: phase?.phaseOrder,
          name: phase?.name,
          durationType: phase?.durationType,
          startDate: phase?.phaseStartDate,
          totalDuration: getTotalDuration(
            phase?.phaseStartDate,
            phase?.phaseEndDate,
            phase?.durationType
          ),
          vat: phase?.vat,
          user: {
            id: phase?.user.id,
            firstName: phase?.user.firstName,
            lastName: phase?.user.lastName,
          },
          workStation: {
            id: phase?.workStation?.id,
            name: phase?.workStation?.name,
            profileImage: phase?.workStation?.profileImage,
          },
          projectStageTasks: [] as object[],
          properties: [],
          color: phase?.color,
          assignPhaseWorkStation: phase?.assignPhaseWorkStation,
          phaseStatus: phase?.phaseStatus,
          acceptedBy: phase?.acceptedBy,
          grantAmount: phase?.grantAmount,
          isPercentage: phase?.isPercentage,
          isGranted: phase?.isGranted,
          escrowStatus: phase?.escrowStatus,
          ibgCheckList: phase?.ibgCheckList,
          updatedSubTotal: phase?.updatedSubTotal,
          updatedVat: phase?.updatedVat,
          phasePaidTotal: phase?.phasePaidTotal,
        };
        phase?.estimationPhaseTasks.forEach((subTask: any) => {
          const estimatePhaseSubTaskRecords = {
            id: subTask?.id,
            name: subTask?.name,
            taskCost: subTask?.taskCost,
            durationType: subTask?.durationType,
            materialUnit: subTask?.materialUnit,
            duration: getTotalDuration(
              subTask?.phaseTaskStartDate,
              subTask?.phaseTaskEndDate,
              subTask?.durationType
            ),
            estimationPhaseTasksProperties: [] as any,
            assignedPhaseTo: subTask?.assignedPhaseTo,
          };
          subTask.estimationPhaseTasksProperties.forEach((property: any) => {
            estimatePhaseSubTaskRecords?.estimationPhaseTasksProperties.push({
              propertyId: property.property.id,
            });
          });
          estimatePhaseRecord.projectStageTasks.push(
            estimatePhaseSubTaskRecords
          );
        });
        estimateForm.projectStages.push(estimatePhaseRecord);
      });

      dispatch("setEstimateForm", estimateForm);
    } else {
      dispatch("resetFormAndData");
    }
    if (response[0]?.estimationIBGData?.length) {
      dispatch("setIBGData", response[0]?.estimationIBGData[0]);
    }
    return response;
  },
  async getProjectInvitation(
    {
      commit,
      dispatch,
      rootGetters,
    }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    payload: any
  ) {
    try {
      const response = (await estimationService.getProjectInvitation(
        payload
      )) as any;
      if (response.length) {
        const filterRes = response.filter(
          (invite: any) =>
            invite.requestStatus !== ContractorRequestStatusEnum.REJECTED
        );
        if (filterRes.length) {
          commit("setSubContractor", true);
        } else {
          commit("setSubContractor", false);
        }

        commit("setProjectEstimationInvitation", response);
      } else {
        commit("setSubContractor", false);
        dispatch("resetFormAndData");
      }
      return response;
    } catch (error) {
      console.log("error", error);
      return [];
    }
  },
  setEstimatePhaseSubTotal({ commit }: { commit: Commit }, payload: any) {
    commit("setEstimatePhaseSubTotal", payload);
  },
  setEstimatePhaseDate({ commit }: { commit: Commit }, payload: any) {
    commit("setEstimatePhaseDate", payload);
  },
  setEstimatePhase({ commit }: { commit: Commit }, payload: any) {
    commit("setEstimatePhase", payload);
  },
  setPhaseTaskProperties({ commit }: { commit: Commit }, payload: any) {
    commit("setPhaseTaskProperties", payload);
  },
  setPhaseProperties({ commit }: { commit: Commit }, payload: any) {
    commit("setPhaseProperties", payload);
  },

  setPhaseAttachments({ commit }: { commit: Commit }, payload: any) {
    commit("setPhaseAttachments", payload);
  },

  resetFormAndData({ commit, getters }: any) {
    commit("setEstimateForm", getters.defaultEstimateForm);
    const formData = {
      suggestedStartDate: null,
      grantType: null,
      grantName: "",
      grantAmount: 0,
      project: null,
      id: null,
    };
    commit("setFormData", formData);
    commit("clearAllErrors", true);
    commit("setInvitedContractorsList", []);
    commit("setTotalPhases", []);
  },
  setProjectStage({ commit }: { commit: Commit }, payload: any) {
    commit("setProjectStage", payload);
  },
  async setSiteRequest({ commit }: { commit: Commit }, payload: any) {
    const { projectEstimateId, projectId, formData } = payload;
    const response = await tradeEstimateService.visitSiteRequest(
      projectEstimateId,
      projectId,
      formData
    );
    return response;
  },

  async setSiteRequestWithoutEstimate(
    { commit }: { commit: Commit },
    payload: any
  ) {
    const { projectId, formData } = payload;
    const response = await tradeEstimateService.visitSiteRequestWithoutEstimate(
      projectId,
      formData
    );
    return response;
  },

  async getSiteRequestAll({ commit }: { commit: Commit }, payload: any) {
    const { projectId, status, wsID } = payload;
    const response = await tradeEstimateService.getSiteRequest(
      projectId,
      status,
      wsID
    );
    return response;
  },
  async updateSiteRequest({ commit }: { commit: Commit }, payload: any) {
    const { siteVisitId, formData } = payload;
    const response = await tradeEstimateService.updateSiteRequest(
      siteVisitId,
      formData
    );
    return response;
  },

  setSubmitProjectEstimation({ commit }: any, value: any) {
    commit("setSubmitProjectEstimation", value);
  },

  validateForm(
    { state, commit, rootGetters }: any,
    payload: { isDIYProjectType: false }
  ) {
    const isValid = { flag: true, errorMessage: "" };

    if (!state.formData?.suggestedStartDate) {
      commit("setFormError", {
        field: "startDate",
        value: true,
      });
      isValid.flag = false;
      isValid.errorMessage = "Project Estimate Start Date required";
      return isValid;
    } else {
      commit("setFormError", {
        field: "startDate",
        value: false,
      });
    }

    if (state?.estimateForm?.estimationIBGData) {
      const ibgRule = [
        { field: "policyType", message: "Policy type is required" },
        {
          field: "totalContractValue",
          message: "totalContractValue is required",
        },
        { field: "propertyType", message: "propertyType is required" },
        // { field: "propertyAddress", message: "propertyAddress is required" },
        // { field: "totalPolicyCost", message: "totalPolicyCost is required" },
      ];

      for (
        let policyIndex = 0;
        policyIndex <
        state?.estimateForm?.estimationIBGData?.estimationIbgPolicies.length;
        policyIndex++
      ) {
        const policy =
          state?.estimateForm?.estimationIBGData?.estimationIbgPolicies[
            policyIndex
          ];
        for (let i = 0; i < ibgRule.length; i++) {
          const { field, message } = ibgRule[i];
          if (!policy[field]) {
            commit("setError", { phaseIndex: policyIndex, field, message });
            isValid.errorMessage = message;
            isValid.flag = false;
            return isValid;
          }
        }
        if (!policy.property?.id) {
          const message = `Oops! Please make sure to attach a property to policy ${
            policyIndex + 1
          } before proceeding.`;
          commit("setError", { phaseIndex: policyIndex, message });
          isValid.errorMessage = message;
          isValid.flag = false;
          return isValid;
        }
        if (policy?.phases?.length) {
          for (
            let phaseIndex = 0;
            phaseIndex < policy?.phases?.length;
            phaseIndex++
          ) {
            const phase = policy.phases[phaseIndex];
            if (
              phase?.workStation?.id ===
              rootGetters[`${WORKSTATION}/activeUserWorkstation`].id
            ) {
              const validationRules = [
                { field: "name", message: "description is required" },
                { field: "name", message: "description is too long" },
                // { field: "startDate", message: "Start date is required" },
                {
                  field: "totalDuration",
                  message: "Total duration is required",
                },
                { field: "subTotal", message: "Subtotal is required" },
              ];

              //phase details validation
              for (let i = 0; i < validationRules.length; i++) {
                const { field, message } = validationRules[i];

                if (field === "subTotal" || field === "totalDuration") {
                  phase[field] = parseFloat(phase[field]);
                }
                if (phase.stageType === estimate.LABOUR) {
                  if (!phase[field]) {
                    if (field === "name" && !phase[field]) {
                      commit("setIBGError", {
                        policyIndex,
                        phaseIndex,
                        field,
                        message,
                      });
                      isValid.errorMessage = message;
                      isValid.flag = false;
                      return isValid;
                    } else {
                      if (
                        field === "subTotal" &&
                        phase.projectStageTasks.length
                      ) {
                        continue;
                      } else {
                        commit("setIBGError", {
                          policyIndex,
                          phaseIndex,
                          field,
                          message,
                        });
                        isValid.errorMessage = message;
                        isValid.flag = false;
                        return isValid;
                      }
                    }
                  } else {
                    if (field === "name" && phase[field].length >= 255) {
                      commit("setIBGError", {
                        policyIndex,
                        phaseIndex,
                        field,
                        message,
                      });

                      isValid.flag = false;
                      isValid.errorMessage = message;
                      return isValid;
                    } else {
                      commit("setIBGError", {
                        policyIndex,
                        phaseIndex,
                        field,
                        message: "",
                      });
                    }
                  }
                } else if (phase.stageType === estimate.MATERIAL) {
                  if (
                    field === "name" ||
                    field === "startDate" ||
                    field === "totalDuration"
                  ) {
                    continue;
                  }

                  if (field === "subTotal") {
                    if (!phase[field]) {
                      if (phase.projectStageTasks.length) {
                        continue;
                      }
                      commit("setIBGError", {
                        policyIndex,
                        phaseIndex,
                        field,
                        message,
                      });
                      isValid.flag = false;
                      isValid.errorMessage = message;
                      return isValid;
                    }
                  } else {
                    commit("setIBGError", {
                      policyIndex,
                      phaseIndex,
                      field,
                      message: message,
                    });
                  }
                }
              }

              //phase subtask details validation

              for (
                let subTaskIndex = 0;
                subTaskIndex < phase.projectStageTasks.length;
                subTaskIndex++
              ) {
                const subTask = phase.projectStageTasks[subTaskIndex];

                const subTaskValidationRules = [
                  {
                    field: "name",
                    message: "Policy Subtask description is required",
                  },
                  {
                    field: "name",
                    message: "Policy Subtask description is too long",
                  },
                  {
                    field: "materialUnit",
                    message: "Policy Material unit is required",
                  },
                  {
                    field: "duration",
                    message: "Policy Duration is required",
                  },
                  {
                    field: "taskCost",
                    message: "Policy Subtask const is required",
                  },
                ];

                // Iterate through the validation rules for the subtask
                for (let j = 0; j < subTaskValidationRules.length; j++) {
                  const { field, message } = subTaskValidationRules[j];

                  if (
                    field === "taskCost" ||
                    field === "duration" ||
                    field === "materialUnit"
                  ) {
                    subTask[field] = parseFloat(
                      subTask[field] ? subTask[field] : 0
                    );
                  }
                  if (phase.stageType === estimate.LABOUR) {
                    if (
                      !["materialUnit", "duration"].includes(field) &&
                      !subTask[field]
                    ) {
                      if (field === "name" && !subTask[field]) {
                        commit("setIBGError", {
                          policyIndex,
                          phaseIndex,
                          subTaskIndex,
                          field,
                          message,
                        });
                        isValid.errorMessage = message;
                      } else {
                        commit("setIBGError", {
                          policyIndex,
                          phaseIndex,
                          subTaskIndex,
                          field,
                          message,
                        });
                        isValid.errorMessage = message;
                      }

                      isValid.flag = false;
                      return isValid;
                    } else {
                      if (field === "name" && subTask[field].length >= 255) {
                        commit("setIBGError", {
                          policyIndex,
                          phaseIndex,
                          subTaskIndex,
                          field,
                          message,
                        });

                        isValid.flag = false;
                        isValid.errorMessage = message;
                        return isValid;
                      } else {
                        commit("setIBGError", {
                          policyIndex,
                          phaseIndex,
                          subTaskIndex,
                          field,
                          message: "",
                        });
                      }
                    }
                    if (field === "duration") {
                      if (subTask[field]) {
                        let mainTaskDays = 0;
                        if (phase.durationType === estimate.WEEKS) {
                          mainTaskDays = phase.totalDuration * 7;
                        } else {
                          mainTaskDays = phase.totalDuration;
                        }
                        if (parseFloat(subTask[field]) > mainTaskDays) {
                          subTask[field] = 0;
                          commit("setIBGError", {
                            policyIndex,
                            phaseIndex,
                            subTaskIndex,
                            field,
                            message:
                              "Your task durations are too long. Please keep the total within the specified limit.",
                          });
                          isValid.flag = false;
                          isValid.errorMessage =
                            "Your task durations are too long. Please keep the total within the specified limit.";
                          return isValid;
                        } else {
                          commit("setIBGError", {
                            policyIndex,
                            phaseIndex,
                            subTaskIndex,
                            field,
                            message: "",
                          });
                        }
                      } else {
                        commit("setIBGError", {
                          policyIndex,
                          phaseIndex,
                          subTaskIndex,
                          field,
                          message,
                        });

                        isValid.flag = false;
                        isValid.errorMessage = message;

                        return isValid;
                      }
                    }
                  } else if (phase.stageType === estimate.MATERIAL) {
                    if (!subTask[field] && field !== "duration") {
                      commit("setIBGError", {
                        policyIndex,
                        phaseIndex,
                        subTaskIndex,
                        field,
                        message,
                      });
                      isValid.errorMessage = message;
                      isValid.flag = false;
                      return isValid;
                    } else {
                      commit("setIBGError", {
                        policyIndex,
                        phaseIndex,
                        subTaskIndex,
                        field,
                        message: "",
                      });
                    }
                  }
                }
              }
            }
          }

          if (policy?.phases?.length === 1) {
            const ibgPhaseIndex = 0;
            const phase = policy.phases[ibgPhaseIndex];
            if (
              phase?.stageType === "labour" &&
              !phase?.projectStageTasks?.length
            ) {
              const message = `At least one Labour - Phase - ${
                ibgPhaseIndex + 1
              }  Task is required for the IBG-Policy-${policyIndex + 1} 
               `;
              commit("setError", { phaseIndex: policyIndex, message });
              isValid.errorMessage = message;
              isValid.flag = false;
              return isValid;
            }
          }
        } else {
          const message = `Please make sure to attach at least one Policy Phase required for IBG - Policy - ${
            policyIndex + 1
          }`;
          commit("setError", { phaseIndex: policyIndex, message });
          isValid.errorMessage = message;
          isValid.flag = false;
          return isValid;
        }
      }
    }

    if (!state.formData?.totalCost && payload?.isDIYProjectType) {
      commit("setFormError", {
        field: "totalCost",
        value: true,
      });

      isValid.flag = false;
      isValid.errorMessage = "totalCost required";
      return isValid;
    }
    const grantAmount = state.formData?.grantAmount;
    const totalLabourTask = state.estimateForm.projectStages.filter(
      (phase: any) => phase.stageType === "labour"
    ).length;

    for (
      let phaseIndex = 0;
      phaseIndex < state.estimateForm.projectStages.length;
      phaseIndex++
    ) {
      const stage = state.estimateForm.projectStages[phaseIndex];

      // Check if the active user workstation matches the phase's workstation
      if (
        (stage?.assignPhaseWorkStation?.id &&
          stage?.assignPhaseWorkStation?.id ===
            rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id) ||
        (!stage?.assignPhaseWorkStation?.id &&
          stage?.workStation?.id ===
            rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id)
      ) {
        // Set validation rules based on project type
        const validationRules = payload?.isDIYProjectType
          ? [
              { field: "name", message: "description is required" },
              { field: "name", message: "description is too long" },
            ]
          : [
              { field: "name", message: "description is required" },
              { field: "name", message: "description is too long" },
              { field: "startDate", message: "Start date is required" },
              { field: "totalDuration", message: "Total duration is required" },
              { field: "subTotal", message: "Subtotal is required" },
            ];

        // Iterate through validation rules for the stage
        for (let i = 0; i < validationRules.length; i++) {
          const { field, message } = validationRules[i];

          if (field === "subTotal" || field === "totalDuration")
            stage[field] = parseFloat(stage[field]);
          if (stage.stageType === "labour") {
            if (!stage[field]) {
              if (field === "name" && !stage[field]) {
                commit("setError", {
                  phaseIndex,
                  field,
                  message: "description is required",
                });
                isValid.errorMessage = "description is required";
                isValid.flag = false;
                return isValid;
              } else {
                //subTotal phases
                if (field === "subTotal" && stage.projectStageTasks.length) {
                  continue;
                } else {
                  commit("setError", { phaseIndex, field, message });
                  isValid.errorMessage = message;
                  isValid.flag = false;
                  return isValid;
                }
              }
            } else {
              if (field === "name" && stage[field].length >= 255) {
                commit("setError", {
                  phaseIndex,
                  field,
                  message,
                });

                isValid.flag = false;
                isValid.errorMessage = "description is too long";
                return isValid;
              } else {
                commit("setError", { phaseIndex, field, message: "" });
              }

              if (field === "subTotal" && grantAmount) {
                //grant Amount Fix
                const phaseSubTotal = parseFloat(stage.subTotal);
                const remainingAmount =
                  phaseSubTotal - grantAmount / totalLabourTask;

                if (remainingAmount < 0) {
                  // isValid.flag = false;
                  // isValid.errorMessage = "Grant Amount is not valid";
                  commit("setFormError", {
                    field: "subTotalValidError",
                    value: true,
                  });

                  commit("setError", {
                    phaseIndex,
                    field,
                    message: `The calculated amount for phase '${
                      stage?.name || "Unnamed Phase"
                    }' is negative.`,
                  });

                  isValid.flag = false;
                  isValid.errorMessage = `The calculated amount for phase '${
                    stage?.name || "Unnamed Phase"
                  }' is negative.`;
                  return isValid;
                } else {
                  commit("setError", {
                    phaseIndex,
                    field,
                    message: "",
                  });
                }
              }
            }
          } else {
            if (
              field === "name" ||
              field === "startDate" ||
              field === "totalDuration"
            ) {
              continue;
            }

            if (field === "subTotal") {
              if (!stage[field]) {
                if (stage.projectStageTasks.length) {
                  continue;
                }
                commit("setError", { phaseIndex, field, message });
                isValid.flag = false;
                isValid.errorMessage = message;
                return isValid;
              }
            } else {
              commit("setError", { phaseIndex, field, message: message });
            }
          }
        }

        for (
          let subTaskIndex = 0;
          subTaskIndex < stage.projectStageTasks.length;
          subTaskIndex++
        ) {
          const subTask = stage.projectStageTasks[subTaskIndex];

          const subTaskValidationRules = payload?.isDIYProjectType
            ? [
                { field: "name", message: "Subtask description is required" },
                { field: "name", message: "Subtask description is too long" },
              ]
            : [
                { field: "name", message: "Subtask description is required" },
                { field: "name", message: "Subtask description is too long" },
                { field: "taskCost", message: "Subtask const is required" },
                { field: "duration", message: "Duration is required" },
                { field: "materialUnit", message: "Material unit is required" },
              ];

          // Iterate through the validation rules for the subtask
          for (let j = 0; j < subTaskValidationRules.length; j++) {
            const { field, message } = subTaskValidationRules[j];

            if (
              field === "taskCost" ||
              field === "duration" ||
              field === "materialUnit"
            )
              subTask[field] = parseFloat(subTask[field] ? subTask[field] : 0);
            if (stage.stageType === "labour") {
              if (
                !["materialUnit", "duration"].includes(field) &&
                !subTask[field]
              ) {
                if (field === "name" && !subTask[field]) {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message: "Subtask description is required",
                  });
                  isValid.errorMessage = "Subtask description is required";
                } else {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message,
                  });
                  isValid.errorMessage = message;
                }

                isValid.flag = false;
                return isValid;
              } else {
                if (field === "name" && subTask[field].length >= 255) {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message,
                  });

                  isValid.flag = false;
                  isValid.errorMessage = message;
                  return isValid;
                } else {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message: "",
                  });
                }
              }
              if (field === "duration") {
                if (subTask[field]) {
                  let mainTaskDays = 0;
                  if (stage.durationType === estimate.WEEKS) {
                    mainTaskDays = stage.totalDuration * 7;
                  } else {
                    mainTaskDays = stage.totalDuration;
                  }
                  if (parseFloat(subTask[field]) > mainTaskDays) {
                    commit("setError", {
                      phaseIndex,
                      subTaskIndex,
                      field,
                      message:
                        "Your task durations are too long. Please keep the total within the specified limit.",
                    });
                    isValid.flag = false;
                    isValid.errorMessage =
                      "Your task durations are too long. Please keep the total within the specified limit.";
                    return isValid;
                  } else {
                    commit("setError", {
                      phaseIndex,
                      subTaskIndex,
                      field,
                      message: "",
                    });
                  }
                } else {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message,
                  });

                  isValid.flag = false;
                  isValid.errorMessage = message;

                  return isValid;
                }
              }
            } else {
              if (!subTask[field] && field !== "duration") {
                commit("setError", {
                  phaseIndex,
                  subTaskIndex,
                  field,
                  message,
                });
                isValid.errorMessage = message;
                isValid.flag = false;
                return isValid;
              } else {
                commit("setError", {
                  phaseIndex,
                  subTaskIndex,
                  field,
                  message: "",
                });
              }
            }
          }
        }
      }
    }

    return isValid;
  },
  clearErrorField({ commit }: any, { phaseIndex, subTaskIndex, field }: any) {
    commit("clearErrors", { phaseIndex, subTaskIndex, field });
  },
  clearIBGErrorField(
    { commit }: any,
    { policyIndex, phaseIndex, subTaskIndex, field }: any
  ) {
    commit("clearIBGErrors", { policyIndex, phaseIndex, subTaskIndex, field });
  },
  setError({ commit }: any, { phaseIndex, subTaskIndex, field, message }: any) {
    commit("setError", { phaseIndex, subTaskIndex, field, message });
  },

  setFormError({ commit }: any, { field, value }: any) {
    commit("setFormError", { field, value });
  },
  async updateEstimatePhase({ commit }: { commit: Commit }, payload: any) {
    const { estimateId, phaseId, status } = payload;
    try {
      await ProjectEstimationService.updateEstimatePhase(estimateId, phaseId, {
        status,
      });
    } catch (error) {
      console.log();
    }
  },

  resetErrorFields({ commit }: { commit: Commit }) {
    commit("clearAllErrors", true);
  },

  async setEstimatePhaseNotes({ commit }: { commit: Commit }, phaseId: number) {
    try {
      if (!phaseId) return [];
      const response: any = await tradeEstimateService.getEstimatePhaseTaskNote(
        phaseId
      );
      for (const note of response) {
        note.user["profileImageUrl"] = await getImageStringToImageURL(
          note?.user?.userPublicProfile?.profileImage
        );
      }
      commit("setEstimatePhaseNotes", { phaseId, data: response });
      return response;
    } catch (error) {
      return [];
    }
  },
  createEstimatePhaseNoteLocally(
    { commit, state }: any,
    { phaseId, newNote }: any
  ) {
    const updatedEstimatePhaseNotes = JSON.parse(
      JSON.stringify(state.updatedEstimatePhaseNote)
    );
    if (!updatedEstimatePhaseNotes[phaseId]) {
      updatedEstimatePhaseNotes[phaseId] = [];
    }
    updatedEstimatePhaseNotes[phaseId].push(newNote);

    commit("createEstimatePhaseNotesDataLocally", updatedEstimatePhaseNotes);
  },
  async setUpdatedEstimatePhaseNotes(
    { commit, rootGetters }: { commit: Commit; rootGetters: any },
    payload: {
      phaseId: number;
      isChangeRequestRoute: boolean;
    }
  ) {
    try {
      if (!payload.phaseId) return [];
      const response: any = await tradeEstimateService.getEstimatePhaseTaskNote(
        payload.phaseId
      );
      for (const note of response) {
        note.user["profileImageUrl"] = await getImageStringToImageURL(
          note?.user?.userPublicProfile?.profileImage
        );
      }

      // We are checking whether is it Chnage Request Flow
      // const currentProjectDetails = rootGetters[`${JOBS_STORE}/getJobDetails`];

      // const getUpdateChangeRequest =
      //   rootGetters[`${JOB_TEMPLATE_STORE}/getUpdateChangeRequest`].data;
      // if (
      //   [ProjectJobStatusEnum.IN_PROGRESS].includes(
      //     currentProjectDetails?.projectJobStatus.id
      //   ) &&
      //   getUpdateChangeRequest &&
      //   payload?.isChangeRequestRoute &&
      //   getUpdateChangeRequest?.updatedEstimate?.phaseNotes
      // ) {
      //   commit("setUpdatedEstimatePhaseNotes", {
      //     phaseId: payload.phaseId,
      //     data: getUpdateChangeRequest?.updatedEstimate?.phaseNotes,
      //   });
      // } else {
      //   commit("setUpdatedEstimatePhaseNotes", {
      //     phaseId: payload.phaseId,
      //     data: response,
      //   });
      // }
      // commit("setUpdatedEstimatePhaseNotes", {
      //   phaseId: payload.phaseId,
      //   data: response,
      // });

      if (response)
        commit("setUpdatedEstimatePhaseNotes", {
          phaseId: payload?.phaseId,
          data: response,
        });
      return response;
    } catch (error) {
      return [];
    }
  },
  async updateEstimatePhaseNoteLocally({ commit, state }: any, payload: any) {
    const { phaseId, noteId, updatedNote, taskId } = payload;

    const updatedEstimatePhaseNotes = structuredClone(
      state.updatedEstimatePhaseNote
    );

    if (updatedEstimatePhaseNotes[phaseId]) {
      const phaseNotes = [...updatedEstimatePhaseNotes[phaseId]];
      const noteIndex = phaseNotes.findIndex((note: any) => note.id === noteId);

      if (noteIndex !== -1) {
        phaseNotes[noteIndex] = {
          ...phaseNotes[noteIndex],
          note: updatedNote,
          taskId,
          dateUpdated: new Date().toISOString(),
        };

        updatedEstimatePhaseNotes[phaseId] = phaseNotes;
        commit("setUpdatedEstimatePhaseNotesGlobal", updatedEstimatePhaseNotes);
      } else {
        console.error(`Note with ID ${noteId} not found in phase ${phaseId}`);
      }
    } else {
      console.error(`Phase with ID ${phaseId} not found`);
    }
  },

  deleteEstimatePhaseNoteLocally({ commit, state }: any, payload: any) {
    const { phaseId, noteId } = payload;

    const updatedEstimatePhaseNotes = JSON.parse(
      JSON.stringify(state.updatedEstimatePhaseNote)
    );

    if (updatedEstimatePhaseNotes[phaseId]) {
      const phaseNotes = updatedEstimatePhaseNotes[phaseId];
      const note = phaseNotes.find((note: any) => note.id === noteId);

      if (note) {
        note.isDelete = true;
        commit("setUpdatedEstimatePhaseNotesGlobal", updatedEstimatePhaseNotes);
      }
    }
  },
  async clearJobTemplateResponse({ commit, rootState }: any) {
    commit("clearJobTemplateResponse", rootState);
  },

  async updateEstimateRequest({ commit, rootState }: any, formData: any) {
    try {
      const estimateId = formData.estimateId;
      await tradeEstimateService.updateEstimateRequest(
        estimateId,
        formData.payload
      );
    } catch (error) {
      console.log(error);
    }
  },
  async setDefaultEstimateFormDetails(
    { commit, rootState }: any,
    estimateId: number
  ) {
    const response = await tradeEstimateService.getDefaultEstimateDetails(
      estimateId
    );
    if (response) {
      commit("setDefaultEstimateFormDetails", response);
    }
  },
  async getChangeEstimateRequest(
    { commit, rootState }: any,
    estimateId: number
  ) {
    try {
      const response = await tradeEstimateService.getDefaultEstimateDetails(
        estimateId
      );
      commit("setChangeJobRequest", response);
    } catch (error) {
      console.log(error);
    }
  },
  async setIBGData(
    {
      commit,
      dispatch,
      rootGetters,
    }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    estimationIBGPDetail: any
  ) {
    const estimationIBGData = {
      id: estimationIBGPDetail.id,
      total: estimationIBGPDetail.total,
      totalServiceText: estimationIBGPDetail?.totalServiceText,
      user: {
        id: rootGetters[`${USER_STORE}/user`]?.id,
        firstName: rootGetters[`${USER_STORE}/user`]?.firstName,
        lastName: rootGetters[`${USER_STORE}/user`]?.lastName,
      },
      workStation: {
        id: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id,
        name: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.name,
        profileImage:
          rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.profileImage,
      },
      estimationIbgPolicies: [],
    };
    estimationIBGData.estimationIbgPolicies =
      estimationIBGPDetail.estimationIbgPolicies.map((policy: any) => {
        const policyData: any = {
          id: policy.id,
          estimationIGBPhaseId: estimationIBGPDetail.id,
          policyType: policy.policyType,
          propertyType: policy.propertyType,
          propertyAddress: policy.propertyAddress,
          numberOfPolicy: policy.numberOfPolicy,
          totalContractValue: policy.totalContractValue,
          totalPolicyCost: policy.totalPolicyCost,
          property: policy.property,

          phases: [],
        };
        policy.estimationPhase.forEach((phase: any) => {
          const estimatePhaseRecord = {
            id: phase?.id,
            stageType:
              phase.phaseType === ProjectPhaseType.LABOUR
                ? estimate.LABOUR
                : estimate.MATERIAL,
            subTotal: phase?.subTotal,
            phaseOrder: phase?.phaseOrder,
            phaseStatus: phase?.phaseStatus,
            name: phase?.name,
            durationType: phase?.durationType,
            startDate: phase?.phaseStartDate,
            totalDuration: getTotalDuration(
              phase?.phaseStartDate,
              phase?.phaseEndDate,
              phase?.durationType
            ),
            vat: phase?.vat,
            projectStageTasks: [] as object[],
            color: phase?.color,
            estimationPhaseProperties: [] as any,
            assignPhaseWorkStation: phase?.assignPhaseWorkStation,
            user: {
              id: phase?.user.id,
              firstName: phase?.user.firstName,
              lastName: phase?.user.lastName,
            },
            workStation: {
              id: phase?.workStation?.id,
              name: phase?.workStation?.name,
              profileImage: phase?.workStation?.profileImage,
            },
            attachments: phase?.estimatePhaseAttachment,
            acceptedBy: phase?.acceptedBy,
            grantAmount: phase?.grantAmount,
            isPercentage: phase?.isPercentage,
            isGranted: phase?.isGranted,
            escrowStatus: phase?.escrowStatus,
            ibgCheckList: phase?.ibgCheckList,
            updatedSubTotal: phase?.updatedSubTotal,
            updatedVat: phase?.updatedVat,
            phasePaidTotal: phase?.phasePaidTotal,
            assignTeamMembers: phase?.assignTeamMembers || [],
          };
          phase?.estimationPhaseTasks.forEach((subTask: any) => {
            const estimatePhaseSubTaskRecords = {
              id: subTask?.id,
              name: subTask?.name,
              taskCost: subTask?.taskCost && subTask?.taskCost.toString(),
              durationType: subTask?.durationType,
              duration: getTotalDuration(
                subTask?.phaseTaskStartDate,
                subTask?.phaseTaskEndDate,
                subTask?.durationType
              ),
              materialUnit: subTask?.materialUnit,
              estimationPhaseTasksProperties: [] as any,
              assignedPhaseTo: subTask?.assignedPhaseTo,
            };
            subTask.estimationPhaseTasksProperties.forEach((property: any) => {
              estimatePhaseSubTaskRecords?.estimationPhaseTasksProperties.push({
                id: property.id,
                propertyId: property.property.id,
              });
            });
            estimatePhaseRecord.projectStageTasks.push(
              estimatePhaseSubTaskRecords
            );
          });
          phase.estimationPhaseProperties.forEach((property: any) => {
            estimatePhaseRecord?.estimationPhaseProperties?.push({
              id: property.id,
              propertyId: property.property.id,
            });
          });
          policyData.phases.push(estimatePhaseRecord);
        });

        return policyData;
      });
    commit("initializeIBGPolicy", estimationIBGData);
  },
  async removeIBGPolicy({ commit }: { commit: Commit }, id: any) {
    if (id) {
      return await ProjectEstimationService.deleteIBGPolicy(id);
    } else {
      return null;
    }
  },
  async removeIBGPolicyPhase({ commit }: { commit: Commit }, id: any) {
    if (id) {
      return await ProjectEstimationService.deleteIBGPolicyPhase(id);
    } else {
      return null;
    }
  },
  async insuranceBackendGuaranteeRequest(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      if (payload) {
        return await ProjectEstimationService.insuranceBackendGuaranteeRequest(
          payload
        );
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },
  async updatePolicyProperty({ commit }: { commit: Commit }, payload: any) {
    try {
      if (payload) {
        return await ProjectEstimationService.updatePolicyProperty(payload);
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },

  async getIbgPolicyList({ commit }: { commit: Commit }, payload: any) {
    try {
      const response = await ProjectEstimationService.getIbgPolicyList(payload);
      commit("setIbgPolicyList", response);
      return response;
    } catch (error: any) {
      throw new error(error);
    }
  },
  async getPublicProjectEstimateByProjectId(
    {
      commit,
      rootGetters,
      dispatch,
    }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    payload: {
      projectId: number;
      filterStatus: boolean;
      isChangeRequestRoute?: boolean;
      activeUserWorkstation: any;
    }
  ) {
    const response =
      (await ProjectEstimationService.getPublicProjectEstimateByProjectId(
        payload
      )) as any;
    if (response.length) {
      // response[0]?.agreeTermsConsumerDisclosure ===
      // AgreeTermsConsumerDisclosure.REJECTED ||
      if (
        response[0]?.agreeTermsConsumerDisclosure ===
        AgreeTermsConsumerDisclosure.NOT_ADDED
      ) {
        dispatch("setLockTemplateModifiedChecker", false);
      } else dispatch("setLockTemplateModifiedChecker", true);

      dispatch("setFormData", {
        suggestedStartDate: response[0]?.suggestedStartDate,
        grantType: response[0]?.grantType,
        grantName: response[0]?.grantName,
        grantAmount: response[0]?.grantAmount,
        id: response[0]?.id,
      });

      commit("setInvitedContractorsList", response[0]?.inviteContractors);
      commit("setTotalPhases", response[0]?.estimationPhase);
      commit("setEstimateResponse", response[0]);

      const estimateForm = {
        projectStages: [],
      } as any;

      const currentUserWorkStationId =
        rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id;

      const subContractorPhases = getSubContractorPhases(
        currentUserWorkStationId,
        response[0]?.inviteContractors,
        response[0]?.estimationPhase
      );
      const parentContractorPhases = getParentContractorPhases(
        currentUserWorkStationId,
        response[0]?.inviteContractors,
        response[0]?.estimationPhase
      );
      const currentConTractorPhases = getCurrentConTractorPhases(
        currentUserWorkStationId,
        response[0]?.estimationPhase
      );

      commit("setSubContractorsPhases", subContractorPhases);
      commit("setparentContractorPhases", parentContractorPhases);

      const finalArray = [
        ...subContractorPhases,
        ...parentContractorPhases,
        ...currentConTractorPhases,
      ];
      const estimationPhase = finalArray;

      estimationPhase.forEach((phase: any) => {
        const estimatePhaseRecord = {
          id: phase?.id,
          stageType:
            phase.phaseType === ProjectPhaseType.LABOUR
              ? "labour"
              : "materials",
          subTotal: phase?.subTotal,
          phaseOrder: phase?.phaseOrder,
          phaseStatus: phase?.phaseStatus,
          name: phase?.name,
          durationType: phase?.durationType,
          startDate: phase?.phaseStartDate,
          totalDuration: getTotalDuration(
            phase?.phaseStartDate,
            phase?.phaseEndDate,
            phase?.durationType
          ),
          vat: phase?.vat,
          projectStageTasks: [] as object[],
          color: phase?.color,
          estimationPhaseProperties: [] as any,
          assignPhaseWorkStation: phase?.assignPhaseWorkStation,
          user: {
            id: phase?.user.id,
            firstName: phase?.user.firstName,
            lastName: phase?.user.lastName,
          },
          workStation: {
            id: phase?.workStation?.id,
            name: phase?.workStation?.name,
            profileImage: phase?.workStation?.profileImage,
          },
          attachments: phase?.estimatePhaseAttachment,
          acceptedBy: phase?.acceptedBy,
          grantAmount: phase?.grantAmount,
          isPercentage: phase?.isPercentage,
          isGranted: phase?.isGranted,
          escrowStatus: phase?.escrowStatus,
          ibgCheckList: phase?.ibgCheckList,
          updatedSubTotal: phase?.updatedSubTotal,
          updatedVat: phase?.updatedVat,
          phasePaidTotal: phase?.phasePaidTotal,
          assignTeamMembers: phase?.assignTeamMembers || [],
        };
        phase?.estimationPhaseTasks.forEach((subTask: any) => {
          const estimatePhaseSubTaskRecords = {
            id: subTask?.id,
            name: subTask?.name,
            taskCost: subTask?.taskCost && subTask?.taskCost.toString(),
            durationType: subTask?.durationType,
            duration: getTotalDuration(
              subTask?.phaseTaskStartDate,
              subTask?.phaseTaskEndDate,
              subTask?.durationType
            ),
            materialUnit: subTask?.materialUnit,
            estimationPhaseTasksProperties: [] as any,
            assignedPhaseTo: subTask?.assignedPhaseTo,
          };
          subTask.estimationPhaseTasksProperties.forEach((property: any) => {
            estimatePhaseSubTaskRecords?.estimationPhaseTasksProperties.push({
              id: property.id,
              propertyId: property.property.id,
            });
          });
          estimatePhaseRecord.projectStageTasks.push(
            estimatePhaseSubTaskRecords
          );
        });
        estimateForm.projectStages.push(estimatePhaseRecord);
        phase.estimationPhaseProperties.forEach((property: any) => {
          estimatePhaseRecord?.estimationPhaseProperties?.push({
            id: property.id,
            propertyId: property.property.id,
          });
        });
      });

      const currentProjectDetails = rootGetters[`${JOBS_STORE}/getJobDetails`];

      const getUpdateChangeRequest =
        rootGetters[`${JOB_TEMPLATE_STORE}/getUpdateChangeRequest`].data;

      if (
        [ProjectJobStatusEnum.IN_PROGRESS].includes(
          currentProjectDetails?.projectJobStatus?.id
        ) &&
        getUpdateChangeRequest &&
        payload?.isChangeRequestRoute
      ) {
        const updatedEstimateForm = getUpdateChangeRequest?.updatedEstimate
          ? JSON.parse(getUpdateChangeRequest?.updatedEstimate)
          : {
              projectStages: [],
            };
        dispatch("setEstimateForm", updatedEstimateForm);
      } else {
        dispatch("setEstimateForm", estimateForm);
      }
    } else {
      dispatch("resetFormAndData");
    }
    if (response[0]?.estimationIBGData?.length) {
      dispatch("setIBGData", response[0]?.estimationIBGData[0]);
    }

    return response;
  },
};
