<template>
  <div class="property_widget_main tw-min-h-[355px] !tw-rounded-[16px]">
    <div class="property_widget_title">
      <h4>Your Properties ({{ totalcount || "0" }})</h4>
      <ToolTip
        description="A list of properties you own or manage, where you can oversee ongoing projects, manage contractors, and track progress."
      />
    </div>
    <div v-if="properties?.length" class="property_widget tw-min-h-[300px]">
      <div
        class="property_widget_list tw-mt-3"
        v-for="property in properties.slice(0, 2)"
        :key="property?.id"
        @click="onClickPropertyView(property?.id)"
      >
        <div class="property_widget_list_left">
          <div
            class="tw-min-w-[80px] tw-min-h-[80px] tw-w-[80px] tw-h-[80px] tw-max-h-[80px] tw-rounded-lg tw-overflow-hidden"
          >
            <!-- <LeafletMap
            :defaultViewZoom="17"
            :isDraggable="false"
            :showControls="false"
            :data="[
              {
                latitude: '51.538938',
                longitude: '0.720477',
                name: property?.address,
                postcode: property?.postcode,
                description: '',
              },
            ]"
            :latlang="[51.538938, 0.720477]"
          /> -->
            <LeafletMap
              v-if="!property?.propertyImage"
              :defaultViewZoom="17"
              :isDraggable="false"
              :showControls="false"
              :data="[]"
              :latlang="getLatLng(property?.addressData)"
            />
            <img
              v-else
              class="tw-w-full tw-h-full"
              :src="property?.publicUrl"
            />
          </div>
          <div class="property_widget_list_text">
            <p class="property_widget_list_type">
              {{ property?.propertyType }}
            </p>
            <h3 class="property_widget_list_name">{{ property?.address }}</h3>
            <p class="property_widget_list_postcode">
              <v-icon icon="mdi-map-marker" />{{ property?.postcode }}
            </p>
          </div>
        </div>
        <div class="property_widget_list_right">
          <img
            src="@/assets/images/trades.svg"
            alt="Trades Icon"
            width="16"
            height="16"
          />
          <p>{{ property?.propertyCategory }}</p>
        </div>
      </div>
      <div
        class="more_property_text"
        v-if="(totalcount || 0) - 2 > 0"
        @click="onClickManageProperty"
      >
        +{{ totalcount - 2 }}More Properties
      </div>
      <div
        v-if="
          activeUserWorkstation?.subscription &&
          activeUserWorkstation?.subStatus ===
            UserWorkstationSubStatusEnum.SUCCEEDED
        "
        class="property_widget_manage_property"
      >
        <v-btn variant="text" @click="onClickManageProperty">
          Manage Properties <v-icon icon="mdi-chevron-right" />
        </v-btn>
      </div>
      <div
        class="property_widget_premium_main"
        v-if="
          !activeUserWorkstation?.subscription ||
          activeUserWorkstation?.subStatus !==
            UserWorkstationSubStatusEnum.SUCCEEDED
        "
      >
        <div class="property_widget_premium_icon">
          <img src="../../../../assets/icons/premium-plan.svg" />
        </div>
        <h3 class="property_widget_premium_title">Upgrade to Premium Plan</h3>
        <p class="property_widget_premium_des">
          to manage additional properties, increase document storage capacity,
          and boost file size limits.
        </p>
        <v-btn class="button button-purple w-auto" @click="onClickUpgradePlan">
          Upgrade Plan
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3"
    >
      <section
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3"
      >
        <img :src="require(`@/assets/images/property_empty.png`)" />
        <Text variant="p" textWeight="600" textColor="rgba(12, 15, 74, 0.5)"
          >Your added properties will appear here</Text
        >
        <Button
          variant="accent"
          label="Add Property"
          class="!tw-w-auto"
          @click="onClickAddProperty"
        />
      </section>
    </div>
  </div>
  <!-- <UpgradePremiumWorkstationModal
    v-if="isShowUpgradePremiumModal"
    @closeUpgradePremiumModal="closeUpgradePremiumModal"
  /> -->
  <PropertyOwnerSubscriptionModal
    v-if="isShowUpgradePremiumModal"
    @closeSubscriptionModal="closeUpgradePremiumModal"
    @closeSelectWorkstationTypeModal="closeUpgradePremiumModal"
  />
</template>
<script lang="ts" setup>
import { LatLngExpression } from "leaflet";
import { ref, onBeforeMount, computed } from "vue";
// import UpgradePremiumWorkstationModal from "@/modules/workstation/propertyowner/pages/UpgradePremiumWorkstationModal.vue";
import PropertyOwnerSubscriptionModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerSubscriptionModal.vue";

import LeafletMap from "@/core/components/map/LeafletMap.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import { useRouter } from "vue-router";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ToolTip from "@/core/components/common/ToolTip.vue";
import { parseJSON } from "@/core/utils/common";
import { ADD_PROPERTY_ROUTE } from "@/modules/properties/routes";
import { PROPERTY_TYPE } from "@/modules/properties/constants";
import { UserWorkstationSubStatusEnum } from "@/core/enums";

const router = useRouter();
const isShowUpgradePremiumModal = ref(false);
const store = useStore();
const onClickUpgradePlan = () => {
  isShowUpgradePremiumModal.value = true;
};
const closeUpgradePremiumModal = () => {
  isShowUpgradePremiumModal.value = false;
};
const properties = computed(
  () => store.getters[`${PROPERTY_STORE}/properties`]
);

const totalcount = computed(
  () => store.getters[`${PROPERTY_STORE}/totalcount`]
);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const onClickPropertyView = async (id: number) => {
  await router.push({
    name: "propertyDetails",
    params: { propertyId: id },
  });
};

const onClickManageProperty = () => {
  router.push("/properties");
};
const onClickAddProperty = () => {
  router.push({
    name: ADD_PROPERTY_ROUTE,
    params: { type: PROPERTY_TYPE },
  });
};
const getLatLng = (addressData: string): LatLngExpression | undefined => {
  if (!addressData) return undefined;

  try {
    const unParsedData = parseJSON(addressData);
    if (!unParsedData) return undefined;
    const latlng: LatLngExpression = [
      unParsedData.LAT ? unParsedData.LAT : unParsedData.lat,
      unParsedData.LNG ? unParsedData.LNG : unParsedData.lng,
    ];
    return latlng;
  } catch (error) {
    console.error("Invalid address data:", error);
    return undefined;
  }
};

onBeforeMount(() => {
  const checkSubscriptionModal = localStorage.getItem(
    "isShowPOSubScriptionModal"
  );
  if (checkSubscriptionModal) {
    console.log("object");
    isShowUpgradePremiumModal.value = true;
  }
});
</script>
<style lang="scss" scoped>
.property_widget_main {
  background-color: rgba($white, 1);
  border-radius: 8px;
  width: 100%;
  padding: 16px 24px;
  border: 1px solid rgba($bordercolor, 1);
  // box-shadow: 0px 0px 8px 0px #0c0f4a14;
  .property_widget_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 16px;
    h4 {
      @include fluidFont(16, 16, 1.4);
      font-weight: 700;
      color: rgba($blueDark, 1);
    }
    .info_icon {
      @include fluidFont(16, 16, 1);
    }
  }
  .property_widget {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .property_widget_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px dashed #8687a5;
      cursor: pointer;
      @include respond(s1024) {
        flex-direction: column;
        align-items: flex-start;
      }
      &:nth-child(2) {
        border-bottom: none;
      }
      .property_widget_list_left {
        display: flex;
        align-items: center;
        gap: 12px;
        .property_widget_img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 8px;
        }
        .property_widget_list_text {
          display: flex;
          flex-direction: column;
          .property_widget_list_type {
            @include fluidFont(8, 8, 1);
            font-weight: 700;
            color: rgba(#48597b, 1);
            margin-bottom: 4px;
          }
          .property_widget_list_name {
            @include fluidFont(14, 14, 1.2);
            font-weight: 700;
            color: rgba($blueDark, 1);
            margin-bottom: 4px;
          }
          .property_widget_list_postcode {
            color: rgba($blueDark, 0.5);
            @include fluidFont(8, 8, 2.2);
            font-weight: 400;
            margin-bottom: 4px;
            .v-icon {
              @include fluidFont(10, 10, 1);
              margin-top: -2px;
            }
          }
        }
      }
      .property_widget_list_right {
        display: flex;
        align-items: center;
        gap: 4px;
        p {
          color: rgba($info, 1);
          @include fluidFont(12, 12, 2);
          font-weight: 700;
          letter-spacing: 0.15px;
          .v-icon {
            @include fluidFont(16, 16, 1);
          }
        }
      }
    }
    .more_property_text {
      @include fluidFont(12, 12, 1.3);
      font-weight: 400;
      color: rgba($blueDark, 0.5);
      letter-spacing: 0.4px;
      margin-bottom: 8px;
    }
    .property_widget_manage_property {
      padding: 16px 0 0;
      border-top: 1px solid rgba($PrimaryBlueLight, 1);
      color: rgba($SecondaryBrightBlue, 1);
      .v-btn {
        margin-left: auto;
        display: flex;
        padding: 0;
      }
    }
    .property_widget_premium_main {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 30px 0 10px;
      max-width: 380px;
      margin: 0 auto;
      .property_widget_premium_icon {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .property_widget_premium_title {
        @include fluidFont(14, 14, 1.3);
        font-weight: 700;
        color: rgba($blueDark, 1);
        text-transform: capitalize;
      }
      .property_widget_premium_des {
        @include fluidFont(14, 14, 1.3);
        font-weight: 600;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.4px;
        text-align: center;
      }
      .button {
        :deep(.v-btn__content) {
          font-weight: 700;
          letter-spacing: 1.25px;
        }
      }
    }
  }
}
</style>
