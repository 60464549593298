import {
  UserRolesEnum,
  UserVerificationStatusEnum,
  UserWorkstationMemberRoleEnum,
} from "@/core/enums/RolesEnum";
import { WorkStation } from "@/core/models/workstation";
import state from "../authentication/state";
import { USER_STORE } from "../user";

export default {
  getWsModal: (state: WorkStation) => state.ws_modal,
  getWorkStationModelState: (state: WorkStation) =>
    state.ws_modal.isShowWorkStationModal,
  isFromCourse: (state: WorkStation) => state.ws_modal.isFromCourse,
  getSoleTraderWorkStationState: (state: WorkStation) =>
    state.ws_modal.isShowSoleTraderWorkStation,
  getLimitedCompanyWorkStationState: (state: WorkStation) =>
    state.ws_modal.isShowLimitedCompanyWorkStation,
  getActiveSoleTraderWorkStationModal: (state: WorkStation) =>
    state.ws_modal.activeSoleTraderWorkStationModal,
  getLimitedCompanyWorkStationModal: (state: WorkStation) =>
    state.ws_modal.activeLimitedCompanyWorkStationModal,
  activeUserWorkstation: (state: WorkStation) => {
    return state.activeUserWorkstation;
  },
  getUserWorkstations: (state: WorkStation) => state.userWorkstations,
  getPropertyOwnerUserWorkstations: (state: WorkStation) =>
    state.userWorkstations,
  getUserWorkstationMembers: (state: WorkStation) =>
    state.userWorkstationMembers,
  getPropertyOwnerWorkStationModelState: (state: WorkStation) =>
    state.isShowCreatePropertyOwnerWorkStationModal,
  isUserWorkStationVerified: (state: WorkStation) =>
    state.activeUserWorkstation?.verificationStatus ===
    UserVerificationStatusEnum.VERIFIED,
  getHomeownersWorkStationState: (state: WorkStation) =>
    state.isShowHomeownersWorkStation,
  getHomeownersWorkStationModal: (state: WorkStation) =>
    state.activeHomeownersWorkStationModal,
  getLandlordsWorkStationState: (state: WorkStation) =>
    state.isShowLandlordsWorkStation,
  getLandlordsWorkStationModal: (state: WorkStation) =>
    state.activeLandlordsWorkStationModal,
  getOccupiersWorkStationState: (state: WorkStation) =>
    state.isShowOccupiersWorkStation,
  getOccupiersWorkStationModal: (state: WorkStation) =>
    state.activeOccupiersWorkStationModal,
  getHomeownersWelcomeState: (state: WorkStation) =>
    state.isShowHomeownersWelcome,
  getHomeownersWelcomeModal: (state: WorkStation) =>
    state.activeHomeownersWelcomeModal,
  getWorkStationSiltVerificationStatus: (state: WorkStation) =>
    state.WorkStationSiltVerificationStatus,
  getUserOnlineWorkStations: (state: WorkStation) =>
    state.allOnlineWorkStations,
  loadingWorkstation: (state: any) => state.loadingWorkstation,
  getAssignedWorkStationData: (state: WorkStation) =>
    state.assignWorkStationData,
  getUserWorkStationFieldAccess: (
    state: WorkStation,
    getter: any,
    rootState: any,
    rootGetters: any
  ) => {
    if (
      rootGetters[`${USER_STORE}/user`]?.role?.name ===
      UserRolesEnum.PROPERTY_OWNER
    ) {
      return false;
    }
    return state.activeUserWorkstation?.role
      ? state.activeUserWorkstation?.role ===
          UserWorkstationMemberRoleEnum.ADMIN ||
        state.activeUserWorkstation?.role ===
          UserWorkstationMemberRoleEnum.EDITOR
        ? true
        : false
      : true;
  },
  isUserWorkStationAdmin: (state: WorkStation) => {
    return state.activeUserWorkstation?.role ===
      UserWorkstationMemberRoleEnum.ADMIN
      ? true
      : false;
  },
  isshowKycModel: (state: WorkStation) => {
    return state.isShowKycModel;
  },
  getIsSubmitWsForVerification: (state: any) => {
    return state.isSubmitWsForVerification;
  },
  getIsWsDirectlySubmittedForVerification: (state: any) => {
    return state.isWsDirectlySubmittedForVerification;
  },
};
