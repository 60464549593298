<template>
  <GeneralDialog
    @on-close="onClickBackButton"
    maxWidth="100%"
    :headerTitle="isEditMode ? 'Edit Insurance' : 'Add Insurance'"
    headerDescription="Please enter your qualification details; note that proof will be required before engaging in any projects on iKnowa."
    width="570px"
    :isTitleStrong="false"
    :paddingLarge="false"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-w-full"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full"
        >
          <div class="form__wrapper tw-w-full md:!tw-h-full">
            <v-form
              @submit.prevent
              :rules="validation"
              ref="insuranceFormRef"
              class="tw-gap-4 tw-w-full"
            >
              <!-- Insurance Type -->
              <div
                class="modal_input_wrapper !tw-m-0 tw-flex tw-flex-col tw-w-full tw-gap-4"
              >
                <div class="modal_input !tw-m-0 tw-gap-1 tw-flex tw-flex-col">
                  <Text variant="span" textWeight="400">Insurance Type</Text>
                  <div class="custom_dropdown">
                    <v-select
                      :items="insurancesList"
                      item-title="name"
                      item-value="id"
                      v-model="insuranceForm.type"
                      class="custom_selector"
                      density="compact"
                      placeholder="e.g Plumbing & Heating"
                      :center-affix="true"
                      :single-line="true"
                      :menu-props="{ contentClass: 'text__selector' }"
                      variant="outlined"
                    ></v-select>
                    <v-text-field
                      v-if="insuranceForm.type === getOthersId"
                      density="compact"
                      :rules="validation.typeText"
                      placeholder="E.g. Public liability"
                      variant="outlined"
                      v-model="customType"
                      class="c-input rounded-full sm:mt-4 mt-2 mb-2 w-full sm:w-80"
                    />
                  </div>
                </div>

                <!-- Coverage -->
                <div
                  class="modal_input_start !tw-m-0 tw-gap-1 tw-flex tw-flex-col w-full"
                >
                  <Text variant="span" textWeight="400">Coverage £</Text>
                  <v-text-field
                    class="input_field"
                    density="compact"
                    :rules="validation.coverage"
                    v-model="formattedCoverage"
                    variant="outlined"
                    placeholder="E.g. £5,000,000"
                    @blur="formatCoverage"
                  >
                    <div>£</div>
                  </v-text-field>
                </div>

                <!-- Expiry Date -->
                <div
                  class="modal_input !tw-m-0 tw-gap-1 tw-flex tw-flex-col w-full"
                >
                  <Text variant="span" textWeight="400">Expiry date</Text>
                  <DatePicker
                    class="date_wrapper"
                    v-model="insuranceForm.expiryDate"
                    :popover="true"
                    :min-date="todayDate"
                  >
                    <template #default="{ togglePopover, inputValue }">
                      <div
                        class="calendar__select"
                        @click="() => togglePopover()"
                      >
                        <v-text-field
                          :value="inputValue"
                          variant="outlined"
                          placeholder="Choose Date"
                          class="input_field"
                          color="#0C0F4A"
                        >
                          <img
                            class="tw-cursor-pointer"
                            src="../../../assets/icons/personal-calendar.svg"
                            alt=""
                          />
                        </v-text-field>
                      </div>
                    </template>
                  </DatePicker>
                </div>

                <!-- File Upload -->
                <!-- <div class="modal_input !tw-m-0 w-full">
                  <v-file-input
                    class="c-file !tw-m-0 w-full sm:w-80"
                    :label="
                      insuranceForm.attachments[0]?.originalName
                        ? insuranceForm.attachments[0]?.originalName
                        : `Upload`
                    "
                    prepend-icon="mdi-upload"
                    variant="solo"
                    flat
                    @click:clear="clearAttachments"
                    @change="onFileChange"
                    :error-messages="attachmentError"
                  ></v-file-input>
                </div> -->

                <div
                  class="modal_input !tw-m-0 tw-gap-0 tw-flex tw-flex-col w-full"
                >
                  <Text variant="span" textWeight="400">Documents*</Text>
                  <div
                    class="tw-mt-2 tw-mb-4 tw-w-full tw-h-auto tw-flex tw-flex-col tw-gap-4 tw-p-3 tw-box-border tw-border-[1px] tw-border-[#DFDFDF] tw-border-solid tw-rounded-[8px] tw-bg-[#F8FBFD]"
                  >
                    <div class="upload__section md:!tw-h-[100px]">
                      <div
                        class="uploade-wrapper custom-file-upload !tw-h-[97px] md:!tw-h-full !tw-w-full !tw-mt-4 !tw-rounded-lg tw-border-[rgba(0,0,0,0.4)] tw-border-[1px] tw-border-dashed !tw-mb-4"
                      >
                        <v-file-input
                          class="upload_file !tw-mb-0 !tw-bg-white"
                          label="Upload images & files, or Browse"
                          variant="solo-filled"
                          v-model="insuranceForm.attachments"
                          :rules="validation.attachments"
                          :class="{
                            'added-img':
                              insuranceForm.attachments &&
                              insuranceForm.attachments?.length,
                          }"
                          @change="onFileChange"
                          prepend-icon="mdi-file-image-box"
                        >
                        </v-file-input>
                      </div>
                    </div>
                    <div class="attachment-list !tw-mt-0">
                      <div
                        v-for="(file, index) in insuranceForm.attachments"
                        :key="index"
                        class="attachment-item !tw-bg-none !tw-p-0 !tw-border-none"
                      >
                        <div class="tw-flex tw-items-center tw-gap-2">
                          <PdfIcon
                            class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                            v-if="file.mime === 'application/pdf'"
                          />
                          <ImageIcon
                            class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                            v-else
                          />
                          <Text variant="p" textWeight="400">{{
                            file.originalName
                          }}</Text>
                          <!-- <Text variant="p" textWeight="400">{{ file }}</Text> -->
                        </div>
                        <v-icon
                          class="cursor-pointer !tw-text-[16px]"
                          icon="mdi-close"
                          @click="removeAttachmentFile"
                        ></v-icon>
                      </div>
                    </div>
                  </div>

                  <!-- File Upload Progress -->
                  <div v-if="isFileUploading" class="w-full sm:w-80">
                    <v-progress-linear
                      :model-value="uploadProgress"
                      :height="10"
                      :width="5"
                      striped
                      rounded
                      color="#0C0F4A"
                    ></v-progress-linear>
                    {{ uploadProgress }} %<span>&nbsp; Uploading file...</span>

                    <!-- <v-list
                    v-if="insuranceForm.attachments.length && !isFileUploading"
                  >
                    <v-list-item
                      v-for="attachment in insuranceForm.attachments"
                      :key="attachment.id"
                      :title="attachment.originalName"
                    >
                      <template v-slot:append>
                        <v-btn
                          color="grey-lighten-1"
                          icon="mdi-close-circle-outline"
                          variant="text"
                          @click="removeAttachmentFile"
                        ></v-btn>
                      </template>
                    </v-list-item>
                  </v-list> -->
                  </div>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <!-- <div
        class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3"
      >
        <v-btn
          class="button disabled !tw-w-full sm:w-80"
          @click="onClickInsuranceNext"
          :class="{ 'button-orange': !isSaveButtonDisable }"
          :disabled="isSaveButtonDisable"
        >
          {{ isEditMode ? "Update" : "Confirm" }}
          <div v-if="isInsuranceFormSubmit">
            <v-progress-circular indeterminate :size="20"></v-progress-circular>
          </div>
        </v-btn>
      </div> -->

      <div
        class="tw-w-full tw-flex tw-gap-3 tw-items-center tw-justify-end tw-pt-4 tw-border-t-dashed-custom"
      >
        <Button
          variant="secondary"
          label="Cancel"
          :isCapitalize="true"
          @click="onClickBackButton"
          :disabled="isInsuranceFormSubmit"
          class="!tw-w-auto"
        ></Button>
        <Button
          variant="accent"
          :label="isEditMode ? 'Update' : 'Save'"
          :isCapitalize="true"
          :isLoading="isInsuranceFormSubmit"
          :disabled="isSaveButtonDisable"
          @click="onClickInsuranceNext"
          class="!tw-w-auto"
        ></Button>
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts">
import { computed, onMounted, ref, onBeforeUnmount } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import ItemListCard from "@/core/components/ItemListCard.vue";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import { useStore } from "vuex";
import { convertToDecimal } from "@/core/helpers/number.helper";
import { USER_STORE } from "@/store/modules/user";
import { DatePicker } from "v-calendar";
import { WORKSTATION } from "@/store/modules/workstation";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PdfIcon from "@/core/components/icons/PdfIcon.vue";
import ImageIcon from "@/core/components/icons/ImageIcon.vue";

export default {
  components: { DatePicker, Text, GeneralDialog, Button, PdfIcon, ImageIcon },
  props: {
    isOuterEditInsurance: {
      type: Boolean,
      default: false,
    },
    userInsuranceData: {
      type: Object,
    },
  },
  setup(props: any, ctx: any) {
    const todayDate = new Date().toISOString().split("T")[0];
    const isShowInsuranceForm = ref(true);
    const isFileUploading = ref(false);
    const uploadProgress = ref(0);
    const store = useStore();
    const insuranceFormRef = ref(null) as any;
    const imageData = ref("");
    const attachmentError = ref("");
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isEditMode = ref(false);
    const isInsuranceFormSubmit = ref(false);
    const insuranceForm = ref({
      type: null as any,
      expiryDate: new Date(),
      coverage: "" as any,
      attachments: [] as Array<any>,
    });
    const formattedCoverage = ref("");
    const dataRange = ref(new Date());
    const customType = ref("");
    const insuranceEditObject = ref() as any;

    const modelDetails = ref({
      id: 1,
      tab: "insurance Detail",
      name: "insuranceDetail",
      header: "Insurance Details",
      title: "Let’s confirm your insurances",
      description:
        "In order to register yourself as a Sole Trader, please confirm your insurances.",
    });

    const isDropdownOpen = ref(false);

    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const toggleDropdownOutside = () => {
      if (isDropdownOpen.value) {
        isDropdownOpen.value = false;
      }
    };

    const validation = ref({
      type: [(v: string) => !!v || "Select an insurance type"],
      typeText: [
        (v: string) => !!v || "Provide an insurance type",
        (v: string) => isNaN(Number(v)) || "Must be a character",
      ],
      expiryDate: [
        (v: string) => !!v || "Provide an expiry date",
        (v: string) =>
          new Date(v) >= new Date(todayDate) || "Date cannot be in the past",
      ],
      coverage: [
        (v: string) => !!v || "Provide the coverage",
        (v: string) =>
          !isNaN(Number(v.replace(/,/g, ""))) || "Must be a number",
      ],
      attachments: [(v: any[]) => v.length > 0 || "Attachment is required"],
    });

    const formatNumber = (value: string) => {
      if (!value) return "";
      let number = value.toString().replace(/\D/g, "");
      return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatCoverage = () => {
      const numericValue =
        parseFloat(formattedCoverage.value.replace(/,/g, "")) || "";
      insuranceForm.value.coverage = numericValue;
      const formattedValue = numericValue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      formattedCoverage.value = formattedValue;
    };

    const formatSum = (sum: any) => {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(sum);
    };

    const insurancesList = computed(
      () => store.getters[`${INSURANCE_STORE}/insurances`]
    );

    const getOthersId = computed(() => {
      if (!insurancesList.value) {
        return null;
      }

      const foundItem = insurancesList.value.find(
        (item: any) => item.template === "Other"
      );
      return foundItem ? foundItem.id : null;
    });

    const userInsurances = computed(
      () => store.getters[`${USER_STORE}/userInsurances`]
    );

    const onFileChange = async (event: any) => {
      try {
        isFileUploading.value = true;
        attachmentError.value = "";
        uploadProgress.value = 0;
        const fileInput = event.target;
        if (fileInput.files && fileInput.files.length > 0) {
          const file = fileInput.files[0];
          imageData.value = file;
          const formData = new FormData();
          formData.append("file", imageData.value);

          const progressInterval = setInterval(() => {
            if (uploadProgress.value < 100) {
              uploadProgress.value += 10;
            }
          }, 120);

          const uploadImg = await store.dispatch(
            `${USER_STORE}/uploadProfileImage`,
            formData
          );
          if (uploadImg) {
            clearInterval(progressInterval);
            uploadProgress.value = 100;
          }

          insuranceForm.value.attachments = [uploadImg];
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        attachmentError.value = "Error uploading file.";
      } finally {
        isFileUploading.value = false;
      }
    };

    const onClickBackButton = () => {
      return ctx.emit("onClickCloseTab");
    };
    const normalizeSaveRequest = (insurance: any) => {
      const { type, expiryDate, coverage, attachments } = insurance;
      let selectedInsurance = insurancesList.value.find(
        (insurance: any) => insurance.name === type
      );
      if (!selectedInsurance) {
        selectedInsurance = insurancesList.value.find(
          (insurance: any) => insurance.name === "Other"
        );
      }
      const data = {
        insuranceId: selectedInsurance?.id,
        userId: user.value.id,
        metadata: {
          sum: coverage,
          insuranceName:
            selectedInsurance.name === "Other" ? type : selectedInsurance.name,
          expiryDate,
        },
        attachments,
      };

      return data;
    };
    const denormalizeSaveRequest = (data: any) => {
      const { id, metadata, attachment, insurance } = data;
      const { sum, expiryDate } = metadata;

      const insuranceData = {
        type: metadata.insuranceName || insurance.name,
        expiryDate,
        coverage: sum,
        attachments: attachment,
      };

      return insuranceData;
    };

    const onClickInsuranceNext = async () => {
      try {
        const isFormValid = await insuranceFormRef.value.validate();
        if (!insuranceForm.value.attachments.length) {
          return (attachmentError.value =
            "You need to upload your insurance file.");
        }
        if (!isFormValid.valid || isInsuranceFormSubmit.value) return;
        isInsuranceFormSubmit.value = true;
        insuranceForm.value.coverage = convertToDecimal(
          insuranceForm.value.coverage
        );

        if (insuranceForm.value.type === getOthersId.value) {
          insuranceForm.value.type = customType.value || "";
        } else {
          let getInsuranceName = insurancesList.value.find(
            (item: any) =>
              item.id === insuranceForm.value.type ||
              item.name === insuranceForm.value.type
          );
          insuranceForm.value.type = getInsuranceName.name;
        }

        if (isEditMode.value) {
          const insuranceData = normalizeSaveRequest(insuranceForm.value);

          const editInsuranceData = {
            userId: user.value.id,
            userInsuranceId: insuranceEditObject.value.id,
            insurance: {
              insuranceId: insuranceData.insuranceId,
              metadata: insuranceData.metadata,
              userVerificationStatus:
                insuranceEditObject.value.userVerificationStatus,
              attachment: insuranceData.attachments[0],
            },
          };

          await store.dispatch(
            `${USER_STORE}/updateUserInsurance`,
            editInsuranceData
          );
        } else {
          const insuranceData = normalizeSaveRequest(insuranceForm.value);
          await store.dispatch(`${USER_STORE}/addNewInsurance`, insuranceData);
          // await store.dispatch(
          //   `${WORKSTATION}/getUserWorkstations`,
          //   user.value.id
          // );
          await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
        }
        if (props.isOuterEditInsurance) {
          return onClickBackButton();
        }
        isShowInsuranceForm.value = false;
        isInsuranceFormSubmit.value = false;
      } catch (error) {
        console.log("Add insurance error", error);
        isInsuranceFormSubmit.value = false;
      } finally {
        onConfirmInsurance();
      }
    };
    const onClickAddAnotherInsurance = () => {
      isShowInsuranceForm.value = true;
      insuranceForm.value = {
        type: null,
        expiryDate: new Date(),
        coverage: "",
        attachments: [] as Array<any>,
      };
      isEditMode.value = false;
    };
    const onConfirmInsurance = () => {
      isShowInsuranceForm.value = true;
      return ctx.emit("onClickCloseTab");
    };
    const onDeleteInsurance = async (insuranceId: string) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserInsurance`, {
          userId: user.value.id,
          userInsuranceId: insuranceId,
        });
      } catch (error) {
        console.log("error", error);
      }
    };
    const onEditInsurance = async (insurance: object) => {
      const insuranceData = denormalizeSaveRequest(insurance);
      insuranceEditObject.value = insurance;
      insuranceForm.value = insuranceData;
      formattedCoverage.value = formatNumber(insuranceData.coverage);
      isShowInsuranceForm.value = true;
      isEditMode.value = true;
    };

    const removeAttachmentFile = (fileId: any) => {
      insuranceForm.value.attachments = [];
    };

    const isSaveButtonDisable = computed(
      () =>
        !(
          insuranceForm.value.attachments.length &&
          insuranceForm.value.coverage &&
          insuranceForm.value.expiryDate &&
          insuranceForm.value.type
        )
    );

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    onMounted(async () => {
      try {
        if (props.isOuterEditInsurance) {
          onEditInsurance(props.userInsuranceData);
        }
        if (!insurancesList.value.length) {
          await store.dispatch(`${INSURANCE_STORE}/getInsurances`);
        }
      } catch (error) {
        console.log("setUserInsurances :error", error);
      } finally {
        if (userInsurances.value && userInsurances.value.length) {
          if (
            props.userInsuranceData === undefined ||
            props.userInsuranceData === null
          ) {
            isShowInsuranceForm.value = false;
          } else {
            isShowInsuranceForm.value = true;
          }
        } else {
          isShowInsuranceForm.value = true;
        }
      }
    });

    const clearAttachments = () => {
      insuranceForm.value.attachments = [];
    };
    onBeforeUnmount(() => {
      ctx.emit("clearUserInsuranceData");
    });

    return {
      insuranceForm,
      modelDetails,
      validation,
      insuranceFormRef,
      onFileChange,
      attachmentError,
      userInsurances,
      onClickBackButton,
      isShowInsuranceForm,
      insurancesList,
      onClickInsuranceNext,
      onClickAddAnotherInsurance,
      onConfirmInsurance,
      onDeleteInsurance,
      onEditInsurance,
      isEditMode,
      removeAttachmentFile,
      isSaveButtonDisable,
      isFileUploading,
      isInsuranceFormSubmit,
      todayDate,
      formatSum,
      formattedCoverage,
      dataRange,
      isDropdownOpen,
      toggleDropdown,
      toggleDropdownOutside,
      formatDate,
      formatCoverage,
      uploadProgress,
      getOthersId,
      customType,
      clearAttachments,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/insurance/styles/InsuranceModel.scss";
.square-input .v-field__input {
  border-radius: 0 !important; /* Removes rounded corners */
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}

.custom-file-upload {
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: #f8fbfd;
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}

.upload_file {
  :deep(.v-label) {
    margin-top: 0 !important;
  }
}

.upload_file {
  :deep(.v-field__input) {
    display: none;
  }
  :deep(.v-field__clearable) {
    display: none;
  }
}
</style>
