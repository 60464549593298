<template>
  <commonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="500px"
    max-width="100%"
    height="auto"
    persistent
    :className="['assign_engineer_modal']"
  >
    <template v-slot:body>
      <div class="modal_body">
        <div class="title">
          <h4>Assign Engineer</h4>
          <div class="modal_close" @click="onCloseModal">
            <v-icon icon="mdi-close" />
          </div>
        </div>
        <div class="modal_sub_header">
          <span>Select an engineer to assign to this batch of jobs.</span>

          <div class="search_wrapper">
            <v-text-field
              v-model="searchQuery"
              class="search_field"
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              placeholder="Search Engineer Names"
            ></v-text-field>
          </div>

          <p>
            <strong> {{ filteredEngineers?.length }}</strong> Results |
            <strong>{{ selectedEngineer ? 1 : 0 }}</strong> Selected
          </p>
        </div>

        <div v-if="filteredEngineers?.length" class="engineer_card_wrapper">
          <div
            v-for="engineer in filteredEngineers"
            :key="engineer.id"
            @click="toggleSelection(engineer)"
            class="engineer_card_wrapper_items"
          >
            <EngineerDetailCard
              :isSelected="selectedEngineer?.id === engineer.id"
              :name="`${engineer?.toUser?.firstName} ${engineer?.toUser?.lastName}`"
              :profileImage="engineer?.toUser?.profileImage"
              :postCode="
                getEngineerPostcode(engineer?.toUser?.userBusinessDetails)
              "
            />
          </div>
        </div>
        <div v-else>
          <p>No Result Found</p>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal-footer">
        <v-btn
          class="button button-purple-border"
          @click="onCloseModal"
          :disabled="isLoading"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="onClickAssign"
          class="button button-orange"
          :loading="isLoading"
          :disabled="!selectedEngineer"
        >
          ASSIGN
        </v-btn>
      </div>
    </template>
  </commonDialog>
</template>

<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { computed, onBeforeMount, ref } from "vue";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { setDirectStoragePathToImage } from "@/core/utils/common";
import { NWG_JOBS_STORE } from "@/store/modules/nwg-job";
import EngineerDetailCard from "../EngineerDetailCard.vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
const store = useStore();
const emits = defineEmits(["on-close"]);
const isLoading = ref(false);
const selectedEngineer = ref();
const searchQuery = ref("");

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const batchDetail = computed(
  () => store.getters[`${NWG_JOBS_STORE}/getBatchDetails`]
);
const getMemberList = computed(
  () => store.getters[`${MEMBER_STORE}/getMemberList`]
);

const filteredEngineers = computed(() => {
  return teamMemberList.value?.filter((engineer) =>
    engineer?.toUser?.firstName
      ?.toLowerCase()
      .includes(searchQuery.value?.toLowerCase())
  );
});

const toggleSelection = (engineer) => {
  selectedEngineer.value = engineer;
};

const getEngineerPostcode = (userBusinessDetails) => {
  if (!userBusinessDetails) return;
  if (userBusinessDetails.type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
    return userBusinessDetails?.businessPostcode;
  }
  return userBusinessDetails?.residencyPostcode;
};

const onCloseModal = () => {
  emits("on-close");
};

const teamMemberList = computed(() => {
  return (
    getMemberList.value
      ?.filter(
        (member) =>
          member?.invitationStatus === "accepted" && !member?.isWorkStationAdmin
      )
      ?.map((member) => {
        return {
          ...member,
          toUser: {
            ...member?.toUser,
            profileImage: setDirectStoragePathToImage(
              member?.toUser.userPublicProfile.profileImage
            ),
          },
        };
      }) || []
  );
});

const setEngineerUser = async () => {
  try {
    await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
      userId: user.value?.id,
    });
  } catch (error) {
    console.error("Error fetching team members:", error);
  }
};
const onClickAssign = async () => {
  try {
    if (!selectedEngineer.value) return;
    isLoading.value = true;
    await store.dispatch(`${NWG_JOBS_STORE}/assignEngineerIntoBatch`, {
      batchId: batchDetail.value.id,
      data: { engineerId: selectedEngineer.value?.toUserId },
    });
    await store.dispatch(
      `${NWG_JOBS_STORE}/setBatchDetails`,
      batchDetail.value.id
    );
  } catch (error) {
    console.log("error", error);
  } finally {
    isLoading.value = false;
    onCloseModal();
  }
};
onBeforeMount(async () => {
  await setEngineerUser();
});
</script>

<style lang="scss" scoped>
.modal_body {
  .title {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba($bordercolor, 1);
    h4 {
      @include fluidFont(18, 18, 24px);
      font-weight: 400;
      color: rgba($blueDark, 1);
    }
    .modal_close {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
    }
  }
  .modal_sub_header {
    padding: 24px 0;
    border-bottom: 1px solid rgba($bordercolor, 1);
    span {
      @include fluidFont(16, 16, 24px);
      font-weight: 400;
      color: rgba($blueDark, 1);
      margin: 0 0 24px;
    }
    .search_wrapper {
      margin: 24px 0 0;
      .search_field {
        max-width: 300px;
        width: 100%;
        :deep(.v-input__control) {
          .v-field {
            padding: 10px 16px;
            --v-input-control-height: auto;
            background-color: rgba($blueDark, 0.04);
            border-radius: 100px;
            .v-field__field {
              .v-field__input {
                padding: 0;
                @include fluidFont(12, 12, 22px);
                font-weight: 400;
                color: rgba(142, 142, 147, 1);
              }
            }
            .v-field__outline {
              display: none;
            }
          }
        }
      }
    }
    p {
      @include fluidFont(12, 12, 16px);
      font-weight: 400;
      color: rgba($blueDark, 0.5);
      letter-spacing: 0.4px;
      margin: 16px 0 0;
    }
  }
  .engineer_card_wrapper {
    margin: 24px 0 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;
    max-height: 485px;
    overflow: auto;
    .engineer_card_wrapper_items {
      width: 100%;
      max-width: 412px;
    }
  }
}
.modal-footer {
  margin: 24px 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
}
</style>
