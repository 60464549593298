<template>
  <div class="profile__wrapper_main md:!tw-max-h-[unset]">
    <div class="profile__wrapper password__wrapper">
      <div class="upload-wrapper profile_img">
        <img
          v-if="userImage"
          :src="userImage"
          width="150"
          height="150"
          alt="userImage"
        />
        <UserProfileLogo v-else />
      </div>

      <div class="personal__details__input">
        <v-form @submit.prevent class="" ref="ruleFormRef">
          <div class="form-field">
            <h5 class="title">Manage Password</h5>
            <div class="form__wrapper">
              <div class="form_input">
                <label for="newPassword" class="">New password</label>
                <v-text-field
                  density="compact"
                  bg-color="rgba(12, 15, 74, 0.0509803922)"
                  variant="outlined"
                  class="c-input rounded-full input_field password__input"
                  rounded
                  outlined="false"
                  v-model="password"
                  :rules="validation.password"
                  placeholder="Enter password"
                  :append-inner-icon="
                    isShowPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append-inner="() => (isShowPassword = !isShowPassword)"
                  :type="isShowPassword ? 'text' : 'password'"
                ></v-text-field>
              </div>
              <div class="form_input">
                <label for="reEnterPass" class="">Confirm Password</label>
                <v-text-field
                  bg-color="rgba(12, 15, 74, 0.0509803922)"
                  density="compact"
                  variant="outlined"
                  class="c-input rounded-full input_field password__input"
                  rounded
                  outlined="false"
                  v-model="confirmPassword"
                  :rules="validation.confirmPassword"
                  placeholder="Re-enter password"
                  :append-inner-icon="
                    isShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append-inner="
                    () => (isShowConfirmPassword = !isShowConfirmPassword)
                  "
                  :type="isShowConfirmPassword ? 'text' : 'password'"
                ></v-text-field>
              </div>
            </div>
            <v-btn
              class="button button-orange"
              type="submit"
              block
              :loading="loading"
              @click.enter="onClickSubmitButton"
              >Update password</v-btn
            >
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onBeforeMount, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { CRYPTO_SECRET_KEY } from "@/core/constants";
import CryptoJS from "crypto-js";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { deleteAllCookies } from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import { LOGIN_ROUTE } from "@/modules/login/routes";
import { useRouter } from "vue-router";

export default {
  components: {
    UserProfileLogo,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const userImage = ref("");
    const loading = ref(false);
    const internalInstance = getCurrentInstance();
    const password = ref("");
    const confirmPassword = ref("");
    const isShowPassword = ref(false);
    const isShowConfirmPassword = ref(false);
    const ruleFormRef = ref(null) as any;
    userImage.value = store.getters[`${USER_STORE}/userImage`];
    const user = ref(null) as any;
    onBeforeMount(async () => {
      user.value = store.getters[`${USER_STORE}/user`];
    });
    const validation = ref({
      password: [
        (v: string) => !!v || "Password is required",
        (v: string) =>
          // eslint-disable-next-line
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/.test(
            v
          ) ||
          "Password must contain at least 8 characters including at least 1 letter and 1 number",
      ],
      confirmPassword: [
        (v: string) => !!v || "Confirm Password is required",
        (v: string) =>
          v === password.value || "Confirm Password does not match",
      ],
    });

    const onClickSubmitButton = async () => {
      try {
        loading.value = true;
        const isFormValid = await ruleFormRef.value.validate();
        if (!isFormValid.valid) return;
        const encryptPassword = CryptoJS.AES.encrypt(
          password.value ?? "",
          CRYPTO_SECRET_KEY ?? ""
        ).toString();

        await store.dispatch(`${AUTHENTICATION_STORE}/changePassword`, {
          email: user.value.email,
          password: encryptPassword,
          userId: user.value.id,
        });
        displayToast("Password Details Updated Successfully.", "success");

        await store.dispatch(`${AUTHENTICATION_STORE}/logOut`, {
          userId: user.value.id,
        });

        deleteAllCookies();
        store.commit(`${USER_STORE}/resetStore`);
        store.commit(`${WORKSTATION}/setActiveUserWorkstation`, null);
        window.localStorage.clear();
        router.push({ name: LOGIN_ROUTE });
      } catch (error) {
        console.log("Failed to change password", error);
      } finally {
        loading.value = false;
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    return {
      userImage,
      password,
      confirmPassword,
      ruleFormRef,
      onClickSubmitButton,
      validation,
      isShowConfirmPassword,
      isShowPassword,
      loading,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/profile/styles/profile.scss";
</style>
