/**
 * Courses route page
 */
import JobsRoute from "@/modules/jobs/pages/JobPage.vue";

export const JOBS_ROUTE = "jobs";
export const JOBS_ROUTE_WITH_TYPE = "jobs-type";
export const JOB_DETAILS_ROUTE = "jobDetails";
export const CREATE_NEW_ESTIMATE = "create-new-estimate";
export const JOBS_WITH_SUCCESS_ESTIMATE_SENT_ROUTE =
  "jobs-success-estimate-sent";

export default [
  {
    path: "/jobs",
    name: JOBS_ROUTE,
    component: JobsRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Jobs",
    },
  },
  {
    path: "/jobs",
    name: JOBS_WITH_SUCCESS_ESTIMATE_SENT_ROUTE,
    component: JobsRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Jobs",
    },
  },
  {
    path: "/jobs-type/:type",
    name: JOBS_ROUTE_WITH_TYPE,
    component: JobsRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Jobs",
    },
  },
  {
    path: "/job-details/:refId",
    name: JOB_DETAILS_ROUTE,
    component: () => import("@/modules/jobs/pages/JobDetailsPage.vue"),
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Jobs",
    },
  },
  {
    path: "/create-estimate",
    name: CREATE_NEW_ESTIMATE,
    component: () => import("@/modules/jobs/pages/CreateNewEstimatePage.vue"),
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Jobs",
    },
  },
];
