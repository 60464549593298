<template>
  <v-card link class="property-card" :class="{ selected: isSelected }">
    <div class="property-details">
      <div class="property-location">
        <div class="pincode">
          <v-icon size="9px" color="#0C0F4A80" icon="mdi-map-marker" />
          <p>{{ property?.postcode }}</p>
        </div>

        <CommercialChip
          v-if="property?.propertyCategory === PropertyCategoryEnum.COMMERCIAL"
        />
        <ResidentialChip v-else />
      </div>
      <div class="divider" />
      <div v-if="property?.nickname" class="property-name">
        <v-icon size="10px" color="white" icon="mdi-home" />
        <h6 class="property-title">{{ property?.nickname }}</h6>
      </div>
      <div class="property-info">
        <p>Maisonette</p>
        <p>2 bedroom</p>
        <p>69 sqm</p>
      </div>
      <Button
        @click="OnclickVisitProperty"
        variant="tertiary"
        class="button-transparent blue-color"
        label="Visit Property"
      >
        <template #postfix
          ><v-icon icon="mdi-chevron-right" color="#4B4BFF"></v-icon
        ></template>
      </Button>
    </div>
    <img
      class="property-image"
      src="@/assets/images/property-dummy-image.png"
    />
  </v-card>
</template>

<script setup>
import Button from "@/core/components/ui/general/Button.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import ResidentialChip from "@/core/uni-components/Chips/ResidentialChip.vue";
import { PropertyCategoryEnum } from "@/core/enums/ProjectsEnum";
const props = defineProps(["property", "isSelected"]);
const emits = defineEmits(["visitProperty"]);
const OnclickVisitProperty = () => {
  emits("visitProperty");
};
</script>
<script>
export default {};
</script>
<style lang="scss" scoped>
.property-card {
  background-color: rgba($cardBorder, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  box-shadow: none;
  border: 2px solid transparent;
  border-radius: 6px;

  &.selected {
    border-color: rgba($SecondaryBrightBlue, 1);
  }

  .property-details {
    flex-grow: 1;
    padding-right: 20px;

    .property-info {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 6px;

      p {
        @include fluidFont(9, 9, 16px);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.19px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 70%;
          background-color: rgba($blueDark, 0.5);
          right: -5px;
          top: 2px;
        }
        &:last-child::before {
          content: none;
        }
      }
    }

    .property-location {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
      .pincode {
        display: flex;
        gap: 2px;
        align-items: center;
        .v-icon {
          @include fluidFont(9, 9, 16px);
          color: rgba($blueDark, 0.5);
        }
        p {
          @include fluidFont(9, 9, 16px);
          font-weight: 400;
          color: rgba($blueDark, 0.5);
          letter-spacing: 0.19px;
        }
      }
      .chip-state {
        gap: 0 !important;
        :deep(svg) {
          width: 10px;
          height: 10px;
        }
        :deep(span) {
          @include fluidFont(8, 8, 15px);
        }
      }
    }

    .property-name {
      display: flex;
      align-items: center;
      gap: 8px;
      .v-icon {
        width: 16px !important;
        height: 16px !important;
        border-radius: 100%;
        background-color: rgba($blueDark, 1);
      }
      h6 {
        @include fluidFont(12, 12, 15px);
        font-weight: 700;
        color: rgba($blueDark, 1);
        text-transform: uppercase;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #c5d2f2;
      margin: 6px 0;
      opacity: 24%;
    }

    .property-title {
      font-weight: bold;
    }
  }

  .property-image {
    border-radius: 8px;
    max-width: 92px;
    max-height: 82px;
  }

  .blue-color {
    @include fluidFont(12, 12, 1);
    color: rgba($SecondaryBrightBlue, 1);
    font-weight: 400;
    padding-top: 8px;
    text-align: center;
    max-height: auto !important;
    min-height: auto !important;
    height: auto !important;
  }
}
</style>
