<template>
  <div
    class="back-button tw-bg-white tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-[42px]"
    :class="{ '!tw-bg-transparent !tw-px-0 !tw-shadow-none': isBgTransparent }"
  >
    <v-btn
      prepend-icon="mdi-chevron-left"
      variant="plain"
      @click="onHandleBack"
    >
      <Text variant="span">
        {{ label }}
      </Text>
    </v-btn>
  </div>
</template>

<script lang="ts" setup>
import Text from "@/core/components/ui/general/Text.vue";
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  isBgTransparent: Boolean,
});
const emits = defineEmits(["on-back"]);
const onHandleBack = () => {
  emits("on-back");
};
</script>

<script lang="ts">
export default {
  name: "BackButton",
};
</script>

<style lang="scss" scoped>
.back-button {
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
}
</style>
