export default {
  tradeEstimate: [],
  tradeEstimateDetail: null,
  tradeEstimateWithEscrowStatus: null,
  projectEstimateStatus: null,
  contractorTradePassportDetail: null,
  contractorTradePassportWorkstationId: null,
  isOpenTradePassportasViewMode: false,
  estimateAttachmentList: [],
};
