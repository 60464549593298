<template>
  <GeneralDialog :isShowCloseButton="false" class="tw-max-w-[450px]">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <!-- <img src="@/assets/icons/delete-solid.svg" alt="" /> -->
        <Text variant="h4" textAlign="center" lineHeight="28px">
          {{ message }}
        </Text>
        <Text
          v-if="description"
          variant="p"
          textAlign="center"
          :textWeight="300"
        >
          {{ description }}
        </Text>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          variant="secondary"
          :disabled="isApiLoading"
          label="Cancel"
          @click="onCloseModal"
        />
        <Button
          class="button button-purple"
          label="Confirm"
          :disabled="isApiLoading"
          :loading="isApiLoading"
          @click="onConfirmModal"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { ref } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
const emits = defineEmits(["on-close", "on-confirm"]);
const isApiLoading = ref(false);

const props = defineProps({
  message: {
    type: String,
    default: "Are you sure you would like to delete this Task?",
  },
  description: {
    type: String,
    default: null,
  },
  Loadingvalue: {
    type: Boolean,
    default: false,
  },
});
const onCloseModal = () => {
  if (!isApiLoading.value) {
    emits("on-close");
  }
};
const onConfirmModal = async () => {
  isApiLoading.value = true;
  await new Promise((resolve) => setTimeout(resolve, 1000));
  emits("on-confirm");
  isApiLoading.value = false;
};
</script>
