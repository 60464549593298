<template>
  <main class="estimate_change_tracker">
    <div class="estimate_change_tracker__list">
      <div class="estimate_change_tracker__list__item">
        <!-- Left Section (Existing Phases) -->
        <div class="left__block">
          <div class="estimate__changes__card">
            <h5>Existing</h5>
            <div class="accordion_content">
              <v-expansion-panels
                v-if="existingEstimatePhases?.length"
                multiple
                v-model="existingModel"
              >
                <EstimateUpdatePhaseModal
                  v-for="phase in existingEstimatePhases"
                  :phaseDetails="phase"
                  :key="phase.id"
                  :highlightChanges="false"
                />
              </v-expansion-panels>
            </div>
          </div>
        </div>

        <!-- Right Section (Changes) -->
        <div class="right__block">
          <div class="estimate__changes__card">
            <h5>Changes</h5>
            <div class="accordion_content">
              <v-expansion-panels
                v-if="modifiedEstimationPhases.length"
                multiple
                v-model="modifiedModel"
              >
                <EstimateUpdatePhaseModal
                  v-for="phase in modifiedEstimationPhases"
                  :existingEstimatePhases="existingEstimatePhases"
                  :modifiedEstimationPhases="modifiedEstimationPhases"
                  :phaseDetails="phase"
                  :key="phase.id"
                  :highlightChanges="true"
                />
              </v-expansion-panels>
              <v-expansion-panels v-if="deletedPhases?.length" multiple>
                <EstimateUpdatePhaseModal
                  :isDeletedPhase="true"
                  v-for="phase in deletedPhases"
                  :existingEstimatePhases="existingEstimatePhases"
                  :modifiedEstimationPhases="modifiedEstimationPhases"
                  :phaseDetails="phase"
                  :key="phase?.id"
                  :highlightChanges="false"
                />
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__buttons">
      <v-btn
        v-if="isTradeSide"
        @click="onCancelEstimate"
        class="button button-purple-border"
        :disabled="loading"
      >
        Cancel
      </v-btn>

      <v-btn
        v-if="isTradeSide"
        @click="onProceedToSubmit"
        class="button button-purple"
        :loading="loading"
      >
        Submit Estimate
      </v-btn>
    </div>
  </main>
</template>

<script setup lang="ts">
import { computed, reactive, onBeforeMount, watch, ref } from "vue";
import { useStore } from "vuex";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import EstimateUpdatePhaseModal from "@/modules/jobs/components/estimates/EstimateUpdatePhaseModal.vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { displayToastMessage } from "@/core/utils/common";
import { getCurrentInstance } from "vue";
import moment from "moment";

const store = useStore();
const props = defineProps({
  isTradeSide: {
    type: Boolean,
    default: false,
  },
  modifiedEstimationPhases: {
    type: Array,
  },
  existingEstimatePhases: {
    type: Array,
  },
});
const emits = defineEmits(["on-back"]);
const getDefaultEstimateDetails = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getDefaultEstimateDetails`]
);
const loading = ref(false);
const internalInstance = getCurrentInstance();
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const estimatePhaseNote = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getEstimatePhaseNotes`]
);

const dummyestimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getDummyEstimateForm`]
);

const newEstimatePhaseNote = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getUpdatedEstimatePhaseNotes`]
);
const deletedPhases = computed(() => {
  return props?.existingEstimatePhases?.filter((existingPhase: any) => {
    return !props?.modifiedEstimationPhases?.some(
      (modifiedPhase: any) => modifiedPhase?.id === existingPhase?.id
    );
  });
});

const newPhaseAttachment = (id: any) =>
  store.getters[`${JOB_TEMPLATE_STORE}/getphaseAttachmentsLocally`](id);

const projectEstimatePayload = (estimateForm: any, OldOrNew: boolean) => {
  let estimationPhaseData = [] as any;
  if (estimateForm) {
    estimateForm.projectStages.map((estimate: any, index: number) => {
      const endDate = estimate.totalDuration
        ? moment(estimate.startDate)
            .add(estimate.totalDuration, "days")
            .format("YYYY-MM-DD")
        : null;
      const estimateRecord = {
        id: estimate.id,
        name: estimate.name,
        phaseType: estimate.stageType === "labour" ? 1 : 2,
        durationType: estimate.durationType,
        phaseStartDate: estimate.startDate,
        phaseEndDate: endDate,
        vat: estimate.vat,
        subTotal: !estimate.subTotal
          ? "0"
          : typeof estimate.subTotal === "number"
          ? estimate.subTotal.toString()
          : estimate.subTotal,
        phaseOrder: estimate.phaseOrder,
        estimationPhaseTasks: [],
        estimationPhaseNotes: [],
        PhaseAttachments: [],
        estimationPhaseProperties: estimate.estimationPhaseProperties,
        color: estimate.color,
        user: estimate?.user,
        workStation: estimate?.workStation,
        grantAmount: estimate?.grantAmount,
        isPercentage: estimate?.isPercentage,
        isGranted: estimate?.isGranted,
        phaseUniqueId: estimate?.phaseUniqueId,
        assignTeamMembers: estimate?.assignTeamMembers,
      };

      if (OldOrNew) {
        let arr = estimatePhaseNote.value;
        if (estimatePhaseNote.value.length > 0) {
          for (const note of arr[estimate.id]) {
            if (note.task === null) {
              const estimatePhaseNote = {
                id: note.id,
                note: note.note,
                phaseId: estimate.phaseUniqueId,
                user: note.user.id,
                userWorkstation: note.userWorkstation.id,
                taskId: note.task,
                isEstimateNote: note.isEstimateNote,
              };
              estimateRecord?.estimationPhaseNotes.push(estimatePhaseNote);
            }
          }
        }
        if (estimate.attachments) {
          estimateRecord.PhaseAttachments.push(estimate.attachments);
        }
      } else if (!OldOrNew) {
        let arr2 = newEstimatePhaseNote.value;
        if (newEstimatePhaseNote.value.length > 0) {
          for (const note of arr2[estimate.phaseUniqueId]) {
            if (note.task === null) {
              const estimatePhaseNote = {
                id: note.id,
                note: note.note,
                phaseId: estimate.phaseUniqueId,
                user: note.user.id,
                userWorkstation: note.userWorkstation.id,
                taskId: note.task,
                isEstimateNote: note.isEstimateNote,
              };
              estimateRecord?.estimationPhaseNotes.push(estimatePhaseNote);
            }
          }
        }

        if (estimate.phaseUniqueId) {
          estimateRecord?.PhaseAttachments.push(
            newPhaseAttachment(estimate.phaseUniqueId)
          );
        }
        // estimateRecord.grantAmount =
        //   dummyestimateForm.value.projectStages[index].grantAmount;
        estimateRecord.grantAmount = estimate.grantAmount;
      }

      if (estimate?.projectStageTasks && estimate?.projectStageTasks?.length) {
        estimate?.projectStageTasks.map((task: any) => {
          const estimateTask = {
            id: task.isNew ? null : task.id,
            name: task.name,
            phaseTaskStartDate: task.startDate,
            phaseTaskEndDate: task.endDate,
            durationType: task.durationType,
            taskCost: !task.taskCost
              ? "0"
              : typeof task.taskCost === "number"
              ? task.taskCost.toString()
              : task.taskCost,
            estimationPhaseTasksProperties: task.estimationPhaseTasksProperties,
            materialUnit: task.materialUnit?.toString(),
            taskUniqueId: task.id,
            uniqueKey: task.isNew ? task.id : null,
          } as any;

          estimateRecord?.estimationPhaseTasks.push(estimateTask);
        });
      }
      estimationPhaseData.push(estimateRecord);
    });
  }

  return estimationPhaseData;
};
const existingModel = computed(() =>
  props.existingEstimatePhases?.map((_, index) => index)
);
const modifiedModel = computed(() =>
  props.modifiedEstimationPhases?.map((_, index) => index)
);

const getUpdatedEstimatePhaseNotes = computed(() => {
  return store.getters[`${JOB_TEMPLATE_STORE}/getUpdatedEstimatePhaseNotes`];
});
const submitUpdatedEstimate = async () => {
  try {
    loading.value = true;
    const initialEstimateData =
      getDefaultEstimateDetails.value?.projectStages.map((stage: any) => {
        return {
          ...stage,
          user: {
            id: stage.user.id,
          },
          workStation: {
            id: stage.workStation.id,
          },
        };
      });

    if (!formData.value?.id) {
      return;
    }

    const newEstimate = JSON.stringify({
      ...estimateForm.value,
      phaseNotes: getUpdatedEstimatePhaseNotes.value,
      formdata: formData.value,
    });
    const oldEstimate = JSON.stringify({
      projectStages: initialEstimateData,
    });

    const oldDbEstimate = JSON.stringify(
      projectEstimatePayload(
        {
          projectStages: initialEstimateData,
        },
        true
      )
    );

    const updatedDbEstimate = JSON.stringify(
      projectEstimatePayload(estimateForm.value, false)
    );

    const payloadData = {
      estimateId: formData.value.id,
      payload: {
        oldEstimate: oldEstimate,
        updatedEstimate: newEstimate,
        oldDbEstimate: oldDbEstimate,
        updatedDbEstimate: updatedDbEstimate,
      },
    };

    await store.dispatch(
      `${JOB_TEMPLATE_STORE}/updateEstimateRequest`,
      payloadData
    );
    displayToastMessage(
      internalInstance,
      "Change request Saved Estimate",
      "success"
    );

    onCancelEstimate();
  } catch (error: any) {
    console.log("error is the keyt", error);
    if (error.response) {
      if (error.response.status) {
        const errorMessage =
          error.response.data.message || "An error occurred. Please try again.";
        displayToastMessage(internalInstance, errorMessage, "error");
      } else {
        // Handle other status codes or errors
        displayToastMessage(
          internalInstance,
          `Error: ${error.response.statusText}`,
          "error"
        );
      }
    } else {
      displayToastMessage(
        internalInstance,
        "The request took too long and timed out. Please try again later.",
        "error"
      );
    }
  } finally {
    loading.value = false;
    // store.commit(`${JOB_TEMPLATE_STORE}/resetphasenote`);
  }
};
const onProceedToSubmit = () => {
  submitUpdatedEstimate();
};
const onCancelEstimate = () => {
  emits("on-back");
};
</script>
<style lang="scss" scoped>
.estimate_change_tracker {
  margin-top: 32px;
  height: calc(100dvh - 360px);
  overflow: auto;
  .estimate_change_tracker__list {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    flex-direction: column;
    .estimate_change_tracker__list__item {
      padding: 16px;
      border: 1px solid rgba($bordercolor, 1);
      border-radius: 8px;
      background-color: rgba($backgroundcolor, 1);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 32px;
      width: 100%;
      max-width: 100%;
      @include respond(md) {
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
        border: none;
        box-shadow: 0px 0px 8px 0px #0c0f4a14;
      }

      .estimate__changes__card {
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        h5 {
          @include fluidFont(18, 18, 32px);
          font-weight: 700;
          color: rgba($blueDark, 1);
        }
        .accordion_content {
          width: 100%;
          max-width: 100%;
          :deep(.v-expansion-panels) {
            gap: 16px;
            .v-expansion-panel {
              border-radius: 8px;
              padding: 0;
              box-shadow: none;
              &:after {
                display: none;
              }
              .v-expansion-panel-title {
                padding: 13px 24px;
                min-height: unset;
                @include respond(s720) {
                  padding: 8px 18px;
                }
                .estimate_summery_item_title {
                  @include fluidFont(14, 10, 19.57px);
                  font-weight: 700;
                }
                .modifield {
                  background-color: #fff3cd;
                  padding: 0 10px;
                  border-radius: 4px;
                }
                .estimate_summery_item_header_right {
                  position: relative;
                  flex: auto;
                  display: flex;
                  align-items: center;
                  gap: 30px;
                  margin-left: auto;
                  justify-content: flex-end;
                  .estimate_summery_status {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    @include fluidFont(10, 10, 1);
                    text-transform: uppercase;
                    color: rgba(12, 15, 74, 0.5);
                    background-color: rgba(12, 15, 74, 0.05);
                    border-radius: 5px;
                    min-height: 20px;
                    padding: 2px 10px;
                    font-weight: 600;
                    img {
                      flex-shrink: 0;
                      max-width: 12px;
                      width: 100%;
                      height: auto;
                      object-fit: contain;
                    }
                  }
                }
                .v-expansion-panel-title__icon {
                  display: none;
                }
              }
              .v-expansion-panel__shadow {
                display: none;
              }
              .v-expansion-panel--active {
                border-bottom-right-radius: none;
                border-bottom-left-radius: none;
              }
              .v-expansion-panel-text {
                .v-expansion-panel-text__wrapper {
                  padding: 0;

                  .estimate_summery_item_body {
                    padding: 24px 16px;

                    @include respond(s720) {
                      padding: 16px;
                    }

                    .estimate_summery_item_info_list {
                      display: flex;
                      align-items: flex-start;
                      gap: 32px 84px;
                      letter-spacing: 0.25px;
                      flex-wrap: wrap;

                      @include respond(s720) {
                        gap: 20px;
                      }

                      .estimate_summery_item_info_item {
                        @include respond(s720) {
                          display: flex;
                          flex-direction: column-reverse;
                          gap: 16px;
                          min-width: 50px;
                        }

                        &:not(:last-child) {
                          &::after {
                            content: "";
                            position: absolute;
                            right: -54px;
                            top: 0;
                            height: 100%;
                            width: 1px;
                            background-color: rgba($blueDark, 0.1);

                            @include respond(s720) {
                              right: 0;
                            }
                          }
                        }

                        position: relative;

                        .estimate_summery_item_info_label {
                          @include fluidFont(12, 10, 1);
                          color: rgba(12, 15, 74, 0.8);
                          margin-bottom: 2px;
                        }

                        .estimate_summery_item_info_text {
                          @include fluidFont(16, 16, 25px);
                          font-weight: 700;
                        }
                      }
                    }

                    .estimate_summery_notes_list {
                      margin-top: 20px;
                      padding-top: 20px;
                      border-top: 1px solid rgba(197, 210, 242, 0.3);
                      display: grid;
                      grid-gap: 10px;

                      .estimate_summery_notes {
                        .estimate_summery_notes_header {
                          display: flex;
                          align-items: center;
                          gap: 6px;
                          flex: auto;

                          .estimate_summery_notes_header_left {
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            flex: auto;

                            .v-checkbox {
                              .v-input__control {
                                .v-selection-control {
                                  .v-selection-control__wrapper {
                                    width: auto;
                                    height: auto;

                                    .v-selection-control__input {
                                      width: auto;
                                      height: auto;

                                      .v-icon {
                                        @include fluidFont(16, 16, 1);
                                      }
                                    }
                                  }
                                }
                              }
                            }

                            p {
                              @include fluidFont(14, 10, 17.57px);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                            }
                          }

                          .estimate_summery_notes_header_right {
                            display: flex;
                            align-items: center;
                            gap: 13px;

                            .estimate_summery_notes_info {
                              display: flex;
                              align-items: center;
                              gap: 26px;

                              @include respond(s720) {
                                gap: 8px;
                              }

                              .estimate_summery_notes_info_item {
                                position: relative;
                                @include fluidFont(10, 10, 12px);
                                color: rgba($blueDark, 0.5);
                                letter-spacing: 0.25px;

                                &:not(:last-child) {
                                  &::after {
                                    content: "";
                                    position: absolute;
                                    right: -14px;
                                    top: 0;
                                    height: 100%;
                                    width: 1px;
                                    background-color: rgba(12, 15, 74, 0.1);

                                    @include respond(s720) {
                                      right: -5px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .estimate_summery_item_footer {
              background-color: rgba($blueDark, 0.1);
              padding: 10px;
              @include fluidFont(12, 10, 12px);
              color: #6c6d8e;
              border-radius: 0 0 8px 8px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 30px;

              @include respond(s720) {
                border-radius: 0 0 10px 10px;
                justify-content: center;
              }

              .estimate_summery_item_controls {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: nowrap;
                gap: 30px;

                @include respond(s720) {
                  gap: 10px;
                }

                .estimate_summery_item_control {
                  position: relative;
                  display: flex;
                  align-items: center;
                  gap: 12px;
                  cursor: pointer;

                  @include respond(s720) {
                    gap: 4px;
                  }

                  .estimate_summery_item_control
                    :deep(:not, :last-child)::after {
                    content: "";
                    position: absolute;
                    right: -15px;
                    top: 0;
                    height: 100%;
                    width: 1px;
                    background-color: rgba(12, 15, 74, 0.2);
                  }

                  img {
                    width: auto;
                    height: auto;
                    max-height: 18px;
                    object-fit: contain;
                    flex-shrink: 0;

                    @include respond(s720) {
                      max-height: 14px;
                    }
                  }

                  span {
                    white-space: nowrap;
                  }
                }
              }

              .estimate_summery_item_author {
                width: 18px;
                height: 18px;
                border-radius: 100%;
                position: relative;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modified {
  background-color: #fff3cd;
  padding: 0;
  border-radius: 4px;
}

.footer__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  margin-top: 42px;

  .button {
    text-transform: capitalize;
    letter-spacing: normal;
  }
}
</style>
