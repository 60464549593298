<template>
  <section class="tw-flex tw-flex-col tw-gap-6">
    <div class="tw-flex tw-flex-col tw-gap-2" v-if="isProperty">
      <LabelWithValue
        v-if="isGovernmentEstimate"
        :noBg="true"
        :allFontBold="true"
        :isHighlightChange="isHighlightChange"
        :label="formData.grantName ? formData.grantName : 'Personal'"
        :data="formatNumber(totalGrant, true)"
      />
      <v-divider></v-divider>
      <LabelWithValue
        :noBg="true"
        :allFontBold="true"
        label="Total Cost"
        :data="formatNumber(totalGovernment)"
      />
    </div>
    <div v-else class="tw-flex tw-flex-col tw-gap-2">
      <LabelWithValue
        :noBg="true"
        label="Your Total Earnings"
        :data="formatNumber(totalSubTotal)"
      />
      <div
        class="tw-p-2 tw-bg-white tw-rounded-lg tw-border tw-border-lightGrey tw-border-solid"
        v-if="isGovernmentEstimate"
      >
        <LabelWithValue
          v-if="isGovernmentEstimate"
          :noBg="true"
          label="Grants & Discounts"
          :data="formatNumber(totalGrant, true)"
        >
          <template #postfix>
            <v-tooltip
              max-width="242px"
              activator="parent"
              location="top"
              class="estimate_tooltip tw-cursor-pointer"
            >
              Grants & Discounts
            </v-tooltip>

            <v-icon
              icon="mdi-information-slab-circle-outline mdi  !tw-text-[16px]"
              color="#80829F"
            ></v-icon>
          </template>
        </LabelWithValue>
        <!-- <div
          class="chip_wrapper tw-mt-2 tw-flex tw-gap-2 tw-flex-wrap tw-justify-start tw-items-center"
        >
          <div
            class="tw-border tw-border-orange tw-border-solid tw-rounded tw-bg-orange/[0.06] tw-text-[8px] tw-leading-3 tw-font-semibold tw-py-1 tw-px-2 tw-max-w-[max-content] tw-text-orange"
          >
            Boiler Upgrade Scheme: -£7,500
          </div>
          <div
            class="tw-border tw-border-orange tw-border-solid tw-rounded tw-bg-orange/[0.06] tw-text-[8px] tw-leading-3 tw-font-semibold tw-py-1 tw-px-2 tw-max-w-[max-content] tw-text-orange"
          >
            Personal discount: -£50,000
          </div>
          <div
            class="tw-border tw-border-orange tw-border-solid tw-rounded tw-bg-orange/[0.06] tw-text-[8px] tw-leading-3 tw-font-semibold tw-py-1 tw-px-2 tw-max-w-[max-content] tw-text-orange"
          >
            Limited time offer: -£1,500
          </div>
        </div> -->
      </div>

      <LabelWithValue :noBg="true" label="VAT" :data="formatNumber(totalVat)" />
      <LabelWithValue
        :noBg="true"
        label="iknowa Service Fee"
        :data="formatNumber(serviceCharges)"
      >
        <template #postfix>
          <v-tooltip
            max-width="242px"
            activator="parent"
            location="top"
            class="estimate_tooltip tw-cursor-pointer"
          >
            Each phase has a 3% fee on the total estimate. The first phase
            includes a base fee of €5, plus 3% of the estimate.
          </v-tooltip>

          <v-icon
            icon="mdi-information-slab-circle-outline mdi  !tw-text-[16px]"
            color="#80829F"
          ></v-icon>
        </template>
      </LabelWithValue>

      <v-divider></v-divider>
      <LabelWithValue
        :noBg="true"
        :allFontBold="true"
        label="PhaseSubTotal"
        :data="formatNumber(customerTotal - insuranceBackedTotal || 0)"
      />
      <LabelWithValue
        :noBg="true"
        v-if="estimateForm?.estimationIBGData"
        :allFontBold="true"
        label="Insurance Backed Guarantee"
        :data="formatNumber(insuranceBackedTotal || 0)"
      >
        <template #postfix>
          <v-tooltip
            max-width="242px"
            activator="parent"
            location="top"
            class="estimate_tooltip tw-cursor-pointer"
          >
            Insurance Backed Guarantee
          </v-tooltip>

          <v-icon
            icon="mdi-information-slab-circle-outline mdi  !tw-text-[16px]"
            color="#80829F"
          ></v-icon>
        </template>
      </LabelWithValue>
      <LabelWithValue
        :noBg="true"
        :allFontBold="true"
        label="Customer Pays"
        :data="formatNumber(customerTotal || 0)"
      >
        <template #postfix>
          <v-tooltip
            max-width="242px"
            activator="parent"
            location="top"
            class="estimate_tooltip tw-cursor-pointer"
          >
            Customer Pays
          </v-tooltip>

          <v-icon
            icon="mdi-information-slab-circle-outline mdi  !tw-text-[16px]"
            color="#80829F"
          ></v-icon>
        </template>
      </LabelWithValue>
    </div>

    <div v-if="isShowSubmitBtn">
      <div class="tw-grid tw-grid-cols-3 tw-gap-4" v-if="isChangeRequest">
        <Button
          variant="secondary"
          class="tw-col-span-3"
          label="Preview Estimate"
          @click="onRedirectToSchedule"
        >
        </Button>
      </div>
      <div class="" v-else>
        <Button
          variant="accent"
          :isCapitalize="true"
          v-if="!isProperty"
          class="tw-col-span-3"
          :label="submitLabel"
          @click="onRouteToSchedule"
          :disabled="isDisabled"
        >
          <template
            v-if="
              (isLockTemplateModifiedChecker || isEstimateSubmit) && !isProperty
            "
            v-slot:postfix
          >
            <img src="@/assets/icons/lock.svg" alt="" />
          </template>
        </Button>
        <!-- v-if="isProperty" -->
        <v-btn
          v-if="!isDisabled"
          @click="onClearEstimate"
          class="button button_plain"
          variant="text"
          >Clear estimate</v-btn
        >
      </div>
    </div>
  </section>
</template>
<script setup>
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import { computed } from "vue";
import { GrantTypeEnum } from "@/core/enums/estimateEnum";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";
import { ref } from "vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { PropertyWorkflowTypeEnum } from "@/core/enums/PropertiesEnum";
import CurrencyFilter from "@/filters/currency.filter";
const emits = defineEmits([
  "on-submit",
  "on-route-to-schedule",
  "on-update-Estimation",
  "on-preview-estimate",
]);
const store = useStore();
const props = defineProps({
  totalSubTotal: {
    type: Number,
  },
  totalVat: {
    type: Number,
  },
  serviceCharges: {
    type: Number,
  },
  isProperty: Boolean,
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },
  isChangeRequest: {
    type: Boolean,
    default: false,
  },
  isHighlightChange: {
    type: Boolean,
    default: false,
  },
  isShowSubmitBtn: {
    type: Boolean,
    default: true,
  },
  insuranceBackedTotal: {
    type: Number,
    default: 0,
  },
});
const customerTotal = computed(() => {
  const total =
    props.totalSubTotal +
    props.totalVat +
    props.insuranceBackedTotal +
    props.serviceCharges -
    totalGrant.value;

  return props.isDIYProjectType
    ? formData.value?.totalCost || 0
    : Math.round(parseFloat(total) * 100) / 100;
});
const workFlowType = computed(
  () => store.getters[`${JOBS_STORE}/getWorkFlowType`]
);
const submitLabel = computed(() => {
  if (
    workFlowType.value &&
    workFlowType.value === PropertyWorkflowTypeEnum.RAISE_CLIENT
  ) {
    return "submit project";
  }
  if (isLockTemplateModifiedChecker.value || isEstimateSubmit.value) {
    return "Submit Estimate";
  } else {
    return "Take me to schedule";
  }
});
const isUserHasEstimateEditAccess = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isUserHasEstimateEditAccess`]
);
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const isAddAnyPhases = computed(
  () =>
    !!(
      estimateForm.value?.estimationIBGData?.estimationIbgPolicies?.length ||
      estimateForm.value?.projectStages?.length
    )
);
const isDisabled = computed(
  () =>
    isLockTemplateModifiedChecker.value ||
    isEstimateSubmit.value ||
    currentJobDetails.value?.isHistoricProject ||
    !isUserHasEstimateEditAccess.value ||
    !isAddAnyPhases.value
);
const onSubmitEstimate = async () => {
  if (totalGovernment.value <= 0) {
    ElMessage.error("total estimation value not be negative.");
  } else {
    emits("on-submit");
  }
};

const onRouteToSchedule = async () => {
  emits("on-route-to-schedule");
};
const onRedirectToSchedule = () => {
  emits("on-preview-estimate");
};

const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);
const isLockTemplateModifiedChecker = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/lockTemplateModifiedChecker`]
);
const isGovernmentEstimate = computed(() => {
  return formData.value.grantType;
});

const totalGovernment = computed(() => {
  let value = customerTotal.value - parseFloat(formData.value.grantAmount);

  if (value < 0) {
    value = 0;
  }
  return value.toFixed(2);
});

const totalGrant = computed(() => {
  let value = Number(formData.value.grantAmount) || 0;

  if (value < 0) {
    value = 0;
  }
  return value;
});
const isEstimateSubmit = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isEstimateSubmit`]
);

const formatNumber = (value, flag = false) => {
  if (!value) return "£0";

  if (flag) return "-" + CurrencyFilter.formatToCurrency(value);
  return CurrencyFilter.formatToCurrency(value);
};
const customerPaysLabel = ref("Customer pays");
const totalLabel = ref("SubTotal");

const onClearEstimate = async () => {
  // store.commit(`${JOB_TEMPLATE_STORE}/clearAllEstimateData`);

  if (estimateForm.value.projectStages?.length) {
    for (const estimate of estimateForm.value.projectStages) {
      await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationPhase`, {
        estimateId: estimate.id,
      });
    }
    estimateForm.value.projectStages = [];
  }
  store.dispatch(`${JOB_TEMPLATE_STORE}/addProjectStage`);
  emits("on-update-Estimation");
};
</script>
<script>
export default {
  name: "EstimateTotal",
};
</script>
<style lang="scss" scoped>
// .button-text {
//   color: #4f55f0 !important;
// }

.button_text {
  min-width: auto;
  min-height: auto;
  border: none;
  @include fluidFont(14, 14, 1.2);
  color: rgba($brightBlue, 1);
  background-color: transparent;
  padding: 0;
  letter-spacing: normal;
  text-transform: capitalize;
  font-weight: bold;
  &:hover {
    background-color: transparent;
    .v-btn__overlay {
      opacity: 0;
    }
  }
}

.button_plain {
  width: 100%;
  max-width: 100%;
  color: #4f55f0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.25px;
  line-height: 16px;
  opacity: 1;
}
</style>
