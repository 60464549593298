<template>
  <CommonDialog
    class="invite_contractor_modal"
    :onClickBackButton="onCloseModal"
    :isShowBackButton="true"
    maxWidth="100%"
    width="620px"
    :isShowHeader="false"
    :isTitleStrong="false"
    ><template #body>
      <div class="modal_body">
        <div class="title">
          <h4>Invite Contractors</h4>
          <p>
            Cras porttitor pretium nunc, varius volutpat enim scelerisque
            hendrerit. Aenean in ligula at lorem varius venenatis.
          </p>
        </div>
        <div class="modal_content">
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            placeholder="Search Contractor"
            variant="outlined"
            v-model="filterObject.search"
            class="input_field tw-w-full tw-h-[40px] tw-rounded-[8px]"
          ></v-text-field>
          <CommonLoader :loading="loading" class="common_loader" />
          <span
            v-if="!loading && !filteredContractors.length"
            class="empty_workstation tw-inline-block tw-text-darkBlue/50 tw-text-base tw-my-2"
          >
            No trade network available at the moment.
          </span>
          <div
            v-if="filteredContractors.length"
            class="tw-flex tw-flex-col tw-gap-1.5 tw-my-6"
          >
            <div
              v-for="inviteContractor in filteredContractors"
              :key="inviteContractor.id"
              class="members_wrapper tw-border-solid tw-border-[0.5px] tw-flex tw-flex-wrap tw-border-[#DFDFDF] tw-justify-between tw-rounded-[8px] tw-py-2 tw-px-2.5"
              @click="
                onClickNetworkContractorCard(
                  inviteContractor?.receiverWorkstation?.id,
                  inviteContractor?.receiverWorkstation?.user?.id
                )
              "
            >
              <div class="tw-flex tw-flex-wrap tw-gap-2">
                <div
                  class="tw-h-[30px] tw-w-[30px] tw-rounded-[8px] tw-overflow-hidden"
                >
                  <img
                    v-if="
                      inviteContractor?.receiverWorkstation?.profileImageUrl
                    "
                    class="tw-w-full tw-h-full tw-object-cover"
                    :src="
                      inviteContractor?.receiverWorkstation?.profileImageUrl
                    "
                    alt="profileImage"
                  />
                  <div v-else class="left_block__profile_img profile_img">
                    {{
                      getWSNameLogoChar(
                        inviteContractor?.receiverWorkstation?.name
                      )
                    }}
                  </div>
                </div>
                <p
                  class="tw-text-base tw-font-medium tw-leading-2 tw-text-darkBlue"
                >
                  {{ inviteContractor?.receiverWorkstation?.name }}
                </p>
              </div>
              <div>
                <v-icon
                  class="tw-text-[#3366FF]"
                  v-if="
                    isSelectedNetworkContractor(
                      inviteContractor?.receiverWorkstation?.id
                    )
                  "
                  icon="mdi-check"
                ></v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-pt-6 tw-box-border tw-border-t-dashed-custom tw-gap-4"
      >
        <Button
          class="!tw-w-auto"
          variant="secondary"
          label="cancel"
          @click="onCloseModal"
        />
        <Button
          class="!tw-w-auto"
          variant="accent"
          label="Invite"
          :isActive="selectedContractorsId?.length"
          @click="onClickSendInvitation"
        />
      </section>
    </template>
  </CommonDialog>
</template>

<script lang="ts" setup>
import { computed, watch, onBeforeMount, ref } from "vue";
import Button from "@/core/components/ui/general/Button.vue";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import CommonLoader from "@/core/components/CommonLoader.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { permissionType } from "@/core/enums/estimateEnum";
import estimationService from "@/core/services/estimation.service";
import { JOBS_STORE } from "@/store/modules/jobs";

const props = defineProps(["selectedContractorList"]);
const emits = defineEmits(["on-close", "on-send-invitation"]);

const store = useStore();
const selectedContractorsId = ref<number[]>(
  props.selectedContractorList
) as any;

const filterObject = ref({
  search: "",
}) as any;

const loading = ref(false);

const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const getUserNetworkConnectedList: any = computed(() => {
  const invited = [] as any;
  const tradeNetwork =
    store.getters[`${TRADE_NETWORK}/getUserNetworkConnectedList`];
  store.getters[`${JOB_TEMPLATE_STORE}/invitedContractorsList`]?.map(
    (el: any) => {
      invited.push(el.fromContractorId);
      invited.push(el.toContractorId);
    }
  );

  return tradeNetwork.filter(
    (el: any) => !invited.includes(el.receiverWorkstation.id)
  );
});

const filteredContractors = computed(() => {
  return getUserNetworkConnectedList.value;
});

const onClickNetworkContractorCard = (contractorId: number, userId: number) => {
  if (isSelectedNetworkContractor(contractorId)) {
    selectedContractorsId.value = selectedContractorsId.value?.filter(
      (contractor: any) => contractor.toContractorId !== contractorId
    );
  } else {
    selectedContractorsId.value.push({
      toContractorId: contractorId,
      toUserId: userId,
    });
  }
};

const onClickSendInvitation = async () => {
  try {
    const inviteContractors = selectedContractorsId.value.map(
      (selectedConnector: any) => ({
        toContractorId: selectedConnector.toContractorId,
        toUserId: selectedConnector.toUserId,
        permissionType: permissionType[0].id,
      })
    );

    const response = await estimationService.inviteContractors(
      activeUserWorkstation.value.id,
      {
        inviteContractors,
        projectId: currentJobDetails.value?.id,
        description: "",
        projectEstimateId: formData.value.id,
      }
    );
    emits("on-send-invitation");
  } catch (error) {
    console.error("error", error);
  }
};

const onCloseModal = () => {
  emits("on-close");
};

const isSelectedNetworkContractor = (contractorId: number) =>
  selectedContractorsId.value.find(
    (contractor: any) => contractor.toContractorId === contractorId
  );

watch(
  () => filterObject.value,
  async (newValue) => {
    try {
      const params = Object.fromEntries(
        Object.entries(newValue)?.filter((value: any) => {
          return value[1] === 0 ? true : value[1];
        })
      );
      await store.dispatch(`${TRADE_NETWORK}/setUserNetworkConnections`, {
        activeUserWorkstationId: activeUserWorkstation.value?.id,
        params,
      });
    } catch (error) {
      console.error("error :>> ", error);
    }
  },
  { deep: true }
);

const getWSNameLogoChar = (WSName: string) => {
  if (WSName) {
    const initials = WSName.split(" ")
      .map((word) => word.charAt(0))
      .join("");
    return initials;
  }
  return "";
};

onBeforeMount(async () => {
  try {
    loading.value = true;
    if (!getUserNetworkConnectedList.value.length)
      await store.dispatch(`${TRADE_NETWORK}/setUserNetworkConnections`, {
        activeUserWorkstationId: activeUserWorkstation.value?.id,
      });
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});
</script>

<style lang="scss" scoped>
.modal_body {
  .title {
    h4 {
      @include fluidFont(20, 20, 1.3);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.15px;
      margin: 0 0 8px;
    }
    p {
      @include fluidFont(12, 12, 1.3);
      font-weight: 400;
      color: rgba($blueDark, 0.5);
      letter-spacing: 0.25px;
    }
  }
  .modal_content {
    margin: 26px 0 0;
    h6 {
      @include fluidFont(16, 16, 1.3);
      font-weight: 500;
      color: rgba($blueDark, 1);
      letter-spacing: 0.25px;
    }
  }
}
.input_field {
  margin: 0;
  :deep(.v-input__control) {
    .v-field {
      border: 1px solid rgba($blueDark, 0.2);
      border-radius: 8px;
      .v-field__field {
        .v-field__input {
          background-color: transparent;
          border: 0;
          border-radius: 0;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          min-height: 42px;
          padding: 0 16px;
          top: 0;
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}

.empty_workstation {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
