<template>
  <CommonDialog
    v-model="openEditModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['member_event_edit_modal']"
    width="441px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>{{ modalType === "create" ? "Create Event" : "Edit Event" }}</h4>
          <v-btn variant="plain" icon="mdi-close" @click="closeModal"></v-btn>
        </div>
        <div class="modal_form">
          <v-form :rules="validation" ref="eventFormRef">
            <div class="form_wrapper">
              <div class="list_block__item">
                <div class="event_field">
                  <label>Event Name</label>
                  <v-text-field
                    class="input_field"
                    placeholder="Enter event name"
                    v-model="eventData.name"
                    :rules="validation.name"
                    variant="outlined"
                  ></v-text-field>
                </div>
                <div class="event_field">
                  <label>Location</label>
                  <v-text-field
                    class="input_field"
                    :rules="validation.location"
                    placeholder="Enter location"
                    v-model="eventData.location"
                    variant="outlined"
                  ></v-text-field>
                </div>
                <div class="date_time_selector">
                  <div class="date_selector">
                    <label>Date</label>
                    <DatePicker
                      :rules="validation.startDate"
                      v-model="eventData.startDate"
                      :min-date="new Date()"
                      :popover="true"
                    >
                      <template #default="{ togglePopover, inputValue }">
                        <div
                          class="calendar__select"
                          @click="() => togglePopover()"
                        >
                          <v-text-field
                            :value="inputValue"
                            variant="outlined"
                            placeholder="Choose Date"
                            class="date__start"
                            color="#0C0F4A"
                          >
                            <img
                              src="../../../assets/icons/personal-calendar.svg"
                              alt=""
                            />
                          </v-text-field>
                        </div>
                      </template>
                    </DatePicker>
                  </div>

                  <div class="time_selector">
                    <label>Choose time</label>
                    <div class="time_selector_wrapper">
                      <div class="custom_time_selector">
                        <v-text-field
                          density="compact"
                          placeholder="HH:MM"
                          variant="outlined"
                          v-model="eventData.startTime"
                          :rules="validation.startTime"
                          class="v-text-custom selectprop_dropdown"
                          :menu-props="{ contentClass: 'text__selector' }"
                          append-inner-icon="mdi-clock-time-four"
                          @click:appendInner="toggleTimeList('start')"
                          @input="onChangeStartDate"
                        >
                        </v-text-field>

                        <ul
                          v-if="isShowStartDateList"
                          class="custom_dropdown__menu"
                        >
                          <li
                            class="custom_dropdown__item"
                            v-for="(time, index) in getStartTimesList"
                            :key="index"
                            @click="onClickTimeList('start', time)"
                          >
                            <label for=""> {{ time }}</label>
                          </li>
                        </ul>
                      </div>
                      <span>-</span>
                      <div class="custom_time_selector">
                        <v-text-field
                          density="compact"
                          placeholder="HH:MM"
                          variant="outlined"
                          v-model="eventData.endTime"
                          :rules="validation.endTime"
                          class="v-text-custom selectprop_dropdown"
                          :menu-props="{ contentClass: 'text__selector' }"
                          append-inner-icon="mdi-clock-time-four"
                          @click:appendInner="toggleTimeList('end')"
                        >
                        </v-text-field>

                        <ul
                          @ClickOutside="toggleTimeList('end')"
                          v-if="isShowEndDateList"
                          class="custom_dropdown__menu"
                        >
                          <li
                            class="custom_dropdown__item"
                            v-for="(time, index) in getEndTimesList"
                            :key="index"
                            @click="onClickTimeList('end', time)"
                          >
                            <label for=""> {{ time }}</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="desc__field">
                  <label>Description</label>
                  <v-textarea
                    :rules="validation.description"
                    placeholder="Write description.."
                    v-model="eventData.description"
                    variant="outlined"
                    rows="6"
                  ></v-textarea>
                </div>
                <div class="event_field">
                  <label>Invite Members</label>
                  <v-autocomplete
                    v-model="eventData.invitePeople"
                    :rules="validation.invitePeople"
                    :items="teamMemberList"
                    class="input_field input_dropdown"
                    color="blue-grey-lighten-2"
                    item-title="email"
                    item-value="email"
                    label="Select team member"
                    chips
                    closable-chips
                    multiple
                    :menu-props="{ contentClass: 'invite_member_dropdown' }"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                        v-bind="props"
                        :prepend-avatar="item.raw.avatar"
                        :text="item.raw.name"
                      ></v-chip>
                    </template>

                    <template v-slot:item="{ props, item }">
                      <v-list-item v-bind="props">
                        <div class="members_text">
                          <h6>
                            {{ item.raw.userName ? item.raw.userName : "Name" }}
                          </h6>
                          <p>{{ item.raw.email }}</p>
                        </div>
                        <div class="members__img">
                          <img
                            v-if="item.raw.profileImage"
                            :src="item.raw.profileImage"
                          />
                          <UserProfileLogo
                            v-else
                            :userName="item.raw?.userName"
                          />
                        </div>
                        <v-checkbox
                          disabled
                          v-model="eventData.invitePeople"
                          :value="item.raw.email"
                          color="indigo-darken-3"
                        ></v-checkbox
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
            </div>
            <div
              class="modal_form_footer !tw-mt-8 tw-pt-4 tw-border-t-dashed-custom !tw-justify-end"
            >
              <!-- <v-btn @click="closeModal" class="button button-purple-border"
                >Cancel</v-btn
              >
              <v-btn class="button button-orange" @click="onUpdateEventData">{{
                modalType === "create" ? "Create" : "Update"
              }}</v-btn> -->

              <Button
                label="Cancel"
                @click="closeModal"
                variant="secondary"
                class="!tw-w-auto"
              />
              <Button
                :label="modalType === 'create' ? 'Create' : 'Update'"
                @click="onUpdateEventData"
                variant="accent"
                class="!tw-w-auto"
              />
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { getImageStringToImageURL } from "@/core/utils/common";
import { CALENDAR_STORE } from "@/store/modules/calendar";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { USER_STORE } from "@/store/modules/user";
import moment from "moment";
import { DatePicker } from "v-calendar";
import { ref, defineProps, computed, onMounted } from "vue";
import { useStore } from "vuex";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import Button from "@/core/components/ui/general/Button.vue";

const props = defineProps({
  event: {
    type: Object,
    default: () => ({
      name: "",
      startDate: new Date(),
      endDate: "",
      location: "",
      startTime: "",
      endTime: "",
      description: "",
      invitePeople: [],
    }),
  },

  modalType: {
    type: String,
    default: "create",
  },
});
const emits = defineEmits(["closeModal", "action"]);
const openEditModal = ref(true);
const isShowStartDateList = ref(false);
const isShowEndDateList = ref(false);
const teamMemberList = ref();
const store = useStore();
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const eventData = ref(props.event);
const eventFormRef = ref(null);
const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm)$/i;
const validation = ref({
  name: [
    (v) => !!v || "Name is required.",
    (v) => v.length <= 255 || "Name is too long",
  ],
  location: [
    // (v) => !!v || "Location is required.",
    (v) => v.length <= 50 || "Location is too long",
  ],
  startDate: [
    (v) => !!v || "Start date is required.",
    (v) =>
      isDateAfterCurrentDate(v) ||
      "Date must be  greater than or equal to today's date",
  ],
  startTime: [
    (v) => !!v || "Start time is required.",
    (v) => timeRegex.test(v) || "Invalid time format. Please use HH:MM am/pm.",
    (v) =>
      isTimeAfterCurrentTime(v) || "Time must Be greater than current time.",
  ],
  endTime: [
    (v) => !!v || "End time is required.",
    (v) => timeRegex.test(v) || "Invalid time format. Please use HH:MM am/pm.",
    (v) => validateEndTime(v) || "Time must be less than start time.",
  ],
  description: [
    // (v) => !!v || "Description is required.",
    (v) => v.length <= 255 || "Description is too long",
  ],
  invitePeople: [(v) => v.length || "Please selected invite people."],
});
const isTimeAfterCurrentTime = (givenTime) => {
  const now = moment();
  if (now.isSame(moment(eventData.value.startDate), "day")) {
    const givenMoment = moment(givenTime, "hh:mm A");
    return givenMoment.isAfter(now);
  } else {
    return true;
  }
};
const isDateAfterCurrentDate = (givenDate) => {
  const today = moment().startOf("day");
  const givenMoment = moment(givenDate, "YYYY-MM-DD");
  return givenMoment.isSameOrAfter(today);
};

const validateEndTime = (endTime) => {
  const start = moment(eventData.value.startTime, "hh:mm A");
  const end = moment(endTime, "hh:mm A");
  return end.isAfter(start) && start.isSame(end, "day");
};
const setTeamMember = async () => {
  try {
    const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
      userId: user.value?.id,
    });
    for (const member of response) {
      if ("toUser" in member) {
        member["profileImage"] = await getImageStringToImageURL(
          member?.toUser?.userPublicProfile?.profileImage
        );
      } else {
        member["profileImage"] = await getImageStringToImageURL(
          member?.userWorkstation?.profileImage
        );
      }
    }

    teamMemberList.value = response;
    if (props.modalType === "create") {
      eventData.value.invitePeople.push(response[0].email);
    }
  } catch (err) {
    console.log();
  }
};
const populateTimes = ref([]);
const getEndTimesList = computed(() =>
  populateTimes.value.filter(
    (time, index) =>
      index > populateTimes.value.indexOf(eventData.value.startTime)
  )
);
const getStartTimesList = computed(() => {
  const currentTime = moment();
  return populateTimes.value.filter((time, index) => {
    if (currentTime.isSame(moment(eventData.value.startDate), "day")) {
      return isTimeAfterCurrentTime(time);
    }
    return true;
  });
});
const setPopulateTimes = () => {
  populateTimes.value = [];
  const date = new Date("12/12/2000 12:00:00 AM");
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j = j + 15) {
      date.setHours(i);
      date.setMinutes(j);
      populateTimes.value.push(
        date.toLocaleTimeString("en-Us", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );
    }
  }
  populateTimes.value.push("11:59 PM");
};

const updateToTime = () => {
  if (
    eventData.value.endTime &&
    populateTimes.value.indexOf(eventData.value.endTime) <=
      populateTimes.value.indexOf(eventData.value.startTime)
  ) {
    eventData.value.endTime = null;
  }
};
const closeModal = () => emits("closeModal");
const onUpdateEventData = async () => {
  try {
    const isFormValid = await eventFormRef.value.validate();
    if (!isFormValid.valid) return;
    eventData.value.endDate = eventData.value.startDate;
    if (props.modalType === "create") {
      const newevent = await store.dispatch(
        `${CALENDAR_STORE}/createPersonalEvent`,
        {
          ...eventData.value,
          userId: user.value?.id,
        }
      );
      emits("action", newevent);
    } else {
      emits("action", eventData.value);
    }
  } catch (error) {
    console.log("error", error);
  }
};
const onClickTimeList = (type, value) => {
  if (type === "start") {
    isShowStartDateList.value = false;
    eventData.value.startTime = value;
    onChangeStartDate();
  } else if (type === "end") {
    isShowEndDateList.value = false;
    eventData.value.endTime = value;
  }
};
const toggleTimeList = (type) => {
  if (type === "start") {
    isShowStartDateList.value = !isShowStartDateList.value;
  } else if (type === "end") {
    isShowEndDateList.value = !isShowEndDateList.value;
  }
};
const onChangeStartDate = () => {
  if (!validateEndTime(eventData.value.endTime)) {
    eventData.value.endTime = null;
  }
};
onMounted(() => {
  setPopulateTimes();
  setTeamMember();
});
</script>
<style scoped></style>
