<template>
  <div class="table_wrapper">
    <!-- Filter and Search Bar -->
    <div class="table_wrapper__header">
      <div class="left_block">
        <h5>NWG Jobs</h5>
        <v-chip variant="outlined" class="header__chip"
          >{{ formFilter.totalCount }} Jobs</v-chip
        >
      </div>
    </div>
    <div class="job-filter-wrapper">
      <div class="left_block">
        <v-btn
          variant="outlined"
          @click="onClickStatusButton(null)"
          class="view_all__list"
          :class="{ active: !formFilter.status }"
          >View all</v-btn
        >
        <v-btn
          variant="outlined"
          @click="onClickStatusButton(NWGStatusEnum.OPEN)"
          class="open__list"
          :class="{ active: NWGStatusEnum.OPEN === formFilter.status }"
          >Open</v-btn
        >
        <v-btn
          variant="outlined"
          @click="onClickStatusButton(NWGStatusEnum.COMPLETED)"
          class="completed__list"
          :class="{ active: NWGStatusEnum.COMPLETED === formFilter.status }"
          >Completed</v-btn
        >
      </div>
      <div class="right_block">
        <div class="members__search">
          <v-text-field
            class="jobs__search input_field"
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            single-line
            flat
            @input="onSeachValueUpdate"
            placeholder="Search..."
          ></v-text-field>
        </div>

        <el-button class="filter-button">
          <img src="@/assets/icons/sort.svg" alt="" />Filter
        </el-button>
      </div>
    </div>

    <!-- Table -->
    <el-table
      class="table_content__wrapper"
      :data="jobDataList"
      v-loading="loading"
      style="width: 100%"
      @cell-click="handleCellClick"
    >
      <el-table-column type="selection" width="48" />

      <el-table-column prop="id" width="128">
        <template #header>
          <span>NWG Job #</span>
          <img
            :class="{
              sort_arrow_rotate_up: sortingField.id.sortOrder === 'ASC',
            }"
            @click="onclickSortingJoblist(sortingField.id)"
            src="@/assets/icons/down-arrow.svg"
          />
        </template>
      </el-table-column>

      <el-table-column prop="batch" width="176">
        <template #header>
          <span>iknowa Batch #</span>
          <img
            :class="{
              sort_arrow_rotate_up: sortingField.batch.sortOrder === 'ASC',
            }"
            @click="onclickSortingJoblist(sortingField.batch)"
            src="@/assets/icons/down-arrow.svg"
          />
        </template>
        <template #default="scope">
          {{ scope.row?.batch?.id }}
        </template>
      </el-table-column>

      <el-table-column prop="dateCreated" width="176">
        <template #header>
          <span>Date Created</span>
          <img
            :class="{
              sort_arrow_rotate_up:
                sortingField.dateCreated.sortOrder === 'ASC',
            }"
            @click="onclickSortingJoblist(sortingField.dateCreated)"
            src="@/assets/icons/down-arrow.svg"
          />
        </template>
        <template #default="scope">
          {{
            formateDate(scope.row.dateCreated, "MMM D, YYYY") ||
            scope.row.dateCreated
          }}
        </template>
      </el-table-column>

      <el-table-column prop="status" width="176">
        <template #default="scope">
          <NwgJobStatusChip :status="scope.row.status" />
        </template>
        <template #header>
          <span>Status</span>
          <img
            :class="{
              sort_arrow_rotate_up: sortingField.status.sortOrder === 'ASC',
            }"
            @click="onclickSortingJoblist(sortingField.status)"
            src="@/assets/icons/down-arrow.svg"
          />
        </template>
      </el-table-column>

      <el-table-column prop="customerName" width="232">
        <template #header>
          <span>Customer</span>
          <img
            :class="{
              sort_arrow_rotate_up:
                sortingField.customerName.sortOrder === 'ASC',
            }"
            @click="onclickSortingJoblist(sortingField.customerName)"
            src="@/assets/icons/down-arrow.svg"
          />
        </template>
      </el-table-column>

      <el-table-column prop="contractor" width="220">
        <template #header>
          <span>Contractor</span>
          <img
            :class="{
              sort_arrow_rotate_up: sortingField.contractor.sortOrder === 'ASC',
            }"
            @click="onclickSortingJoblist(sortingField.contractor)"
            src="@/assets/icons/down-arrow.svg"
          />
        </template>
        <template #default="scope">
          {{ scope.row?.batch?.contractor?.name }}
        </template>
      </el-table-column>
    </el-table>

    <!-- Pagination -->

    <v-pagination
      @update:modelValue="onPaginationAction"
      v-model="formFilter.page"
      :length="totalPageCount"
      :total-visible="6"
      class="pagination desktop_wrapper"
    >
      <template v-slot:prev>
        <v-btn
          @click="onPaginationAction('previos')"
          prepend-icon="mdi-arrow-left"
          variant="outlined"
          >Previous</v-btn
        >
      </template>
      <template v-slot:next>
        <v-btn
          @click="onPaginationAction('next')"
          append-icon="mdi-arrow-right"
          variant="outlined"
          >Next</v-btn
        >
      </template>
    </v-pagination>

    <div class="mobile__wrapper pagination">
      <v-btn
        @click="onPaginationAction('previos')"
        prepend-icon="mdi-arrow-left"
        variant="outlined"
      />
      <p class="pagination__count">
        Page {{ formFilter.page }} of {{ totalPageCount }}
      </p>
      <v-btn
        @click="onPaginationAction('next')"
        append-icon="mdi-arrow-right"
        variant="outlined"
      />
    </div>
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { NWG_BATCH_DETAIL_ROUTE, NWG_JOB_DETAIL_ROUTE } from "../routes";
import { NWGStatusEnum } from "@/core/enums/nwgJobEnum";
import { NWG_JOBS_STORE } from "@/store/modules/nwg-job";
import NwgJobStatusChip from "@/core/uni-components/Chips/NwgJobStatusChip.vue";
import { formateDate } from "@/core/utils/common";
const store = useStore();
const router = useRouter();
const loading = ref(false);
const jobDataList = ref([]);
const sortingField = ref({
  id: { sortBy: "id", sortOrder: "ASC" },
  dateCreated: { sortBy: "dateCreated", sortOrder: "DESC" },
  status: { sortBy: "status", sortOrder: "DESC" },
  customerName: { sortBy: "customerName", sortOrder: "DESC" },
  batch: { sortBy: "batchId", sortOrder: "DESC" },
  contractor: { sortBy: "contractorName", sortOrder: "DESC" },
});
const formFilter = ref({
  page: 1,
  limit: 10,
  totalCount: 0,
  status: null,
  search: null,
  sortBy: null,
  sortOrder: null,
});

const totalPageCount = computed(() =>
  Math.ceil(formFilter.value.totalCount / formFilter.value.limit)
);

const onSeachValueUpdate = debounce((event) => {
  if (formFilter.value.search !== event.target.value) {
    formFilter.value.search = event.target.value || null;
    formFilter.value.page = 1;
    setJoblist();
  }
}, 500);

const onPaginationAction = (action) => {
  if (action === "next" && formFilter.value.page < totalPageCount.value) {
    formFilter.value.page++;
  } else if (action === "previos" && formFilter.value.page > 1) {
    formFilter.value.page--;
  } else if (typeof action === "number") {
    formFilter.value.page = action;
  }

  setJoblist();
};

const onclickSortingJoblist = (sortingField) => {
  if (
    [
      "id",
      "dateCreated",
      "status",
      "customerName",
      "batchId",
      "contractorName",
    ].includes(sortingField.sortBy)
  ) {
    sortingField.sortOrder = sortingField.sortOrder === "ASC" ? "DESC" : "ASC";
    formFilter.value.sortBy = sortingField.sortBy;
    formFilter.value.sortOrder = sortingField.sortOrder;
    formFilter.value.page = 1;
    setJoblist();
  }
};
const onClickStatusButton = (status) => {
  if (
    [
      NWGStatusEnum.OPEN,
      NWGStatusEnum.COMPLETED,
      NWGStatusEnum.CANCELLED,
      null,
    ]?.includes(status) &&
    formFilter.value.status !== status
  ) {
    formFilter.value.status = status;
    formFilter.value.page = 1;
    setJoblist();
  }
};
const setJoblist = async () => {
  try {
    loading.value = true;
    const payload = JSON.parse(JSON.stringify(formFilter.value));
    delete payload.totalCount;
    Object.keys(payload).forEach(
      (key) => payload[key] === null && delete payload[key]
    );
    const jobListRes = await store.dispatch(
      `${NWG_JOBS_STORE}/setJobs`,
      payload
    );
    jobDataList.value = jobListRes.jobs;
    formFilter.value.totalCount = jobListRes.pagination.totalCount;
  } catch (error) {
    console.log("job list error", error);
  } finally {
    loading.value = false;
  }
};

const handleCellClick = (row, column) => {
  const clickedField = column.property;
  if (!clickedField) return;
  if (clickedField === "batch" && row?.batch?.id) {
    router.push({ name: NWG_BATCH_DETAIL_ROUTE, params: { id: row.batch.id } });
  } else if (row.id) {
    router.push({ name: NWG_JOB_DETAIL_ROUTE, params: { id: row.id } });
  }
};

onMounted(() => {
  setJoblist();
});
</script>
<script>
export default {};
</script>

<style scoped lang="scss">
.table_wrapper {
  border: 1px solid rgba(228, 231, 236, 1);
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  @include respond(s720) {
    margin: 32px 0 0;
  }
  .table_wrapper__header {
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(md) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    .left_block {
      display: flex;
      gap: 6px;
      align-items: center;
      h5 {
        @include fluidFont(18, 18, 28px);
        font-weight: 600;
        color: #101828;
      }
      .header__chip {
        background-color: rgba(240, 249, 255, 1);
        color: rgba(2, 106, 162, 1);
        border-color: rgba(185, 230, 254, 1);
        padding: 1px 7px;
        height: unset;
      }
    }
  }
  .job-filter-wrapper {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(228, 231, 236, 1);
    border-top: 1px solid rgba(228, 231, 236, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(md) {
      flex-direction: column-reverse;
      gap: 16px;
      align-items: flex-start;
    }
    .left_block {
      .v-btn {
        @include fluidFont(14, 14, 20px);
        font-weight: 600;
        color: rgba(24, 34, 48, 1);
        padding: 9px 16px;
        height: unset;
        text-transform: capitalize;
        letter-spacing: normal;
        border: 1px solid rgba(208, 213, 221, 1);
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        }
        &:nth-child(2) {
          border-radius: 0;
        }
      }
      .active {
        background-color: rgba(249, 250, 251, 1);
      }
    }
    .right_block {
      display: flex;
      gap: 12px;
      align-items: center;
      @include respond(s720) {
        width: 100%;
        max-width: 100%;
      }
      .jobs__search {
        margin: 0;
        max-width: 100%;
        width: 200px;
        :deep(.v-input__control) {
          .v-field {
            border: 1px solid rgba($blueDark, 0.2);
            border-radius: 8px;
            padding: 0 16px;
            .v-field__field {
              .v-field__input {
                background-color: transparent;
                border: 0;
                border-radius: 0;
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                min-height: 38px;
                top: 0;
                padding: 0;
              }
            }
            .v-field__outline {
              display: none;
            }
          }
        }
      }

      .filter-button {
        @include fluidFont(14, 14, 20px);
        font-weight: 600;
        color: rgba(24, 34, 48, 1);
        padding: 9px 21px;
        height: unset;
        border-radius: 8px;
        &:hover {
          background-color: transparent;
          border-color: rgba($bordercolor, 1);
        }
      }
    }
  }
  .table_content__wrapper {
    :deep(.el-table__header-wrapper) {
      .el-table__header {
        width: 100% !important;
        max-width: 100%;
        thead {
          tr {
            th {
              background-color: rgba(249, 250, 251, 1);
              .cell {
                display: flex;
                gap: 4px;
                align-items: center;
                span {
                  @include fluidFont(12, 12, 18px);
                  font-weight: 500;
                  color: rgba(71, 84, 103, 1);
                  white-space: nowrap;
                }
              }
            }
            .el-table__cell {
              border-right: none;
              padding: 10px 0;
            }
          }
        }
      }
    }
    :deep(.el-table__body-wrapper) {
      .el-scrollbar {
        .el-scrollbar__wrap {
          .el-scrollbar__view {
            width: 100%;
            max-width: 100%;
            .el-table__body {
              width: 100% !important;
              max-width: 100%;
              tbody {
                .el-table__row {
                  cursor: pointer;
                  .el-table__cell {
                    padding: 12px 0;
                    @include fluidFont(14, 14, 20px);
                    font-weight: 500;
                    color: rgba(16, 24, 40, 1);
                    .cell {
                      .el-link--danger {
                        color: rgba(71, 84, 103, 1);
                        @include fluidFont(14, 14, 20px);
                        font-weight: 600;
                        text-decoration: none;
                        &:hover {
                          &:after {
                            border: none;
                          }
                        }
                      }
                      .el-link--primary {
                        color: rgba($orange, 1);
                        @include fluidFont(14, 14, 20px);
                        font-weight: 600;
                        text-decoration: none;
                        &:hover {
                          &:after {
                            border: none;
                          }
                        }
                      }
                    }
                    &:last-child {
                      .cell {
                        display: flex;
                        gap: 12px;
                        align-items: center;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  :deep(.pagination) {
    padding: 14px;
    .v-pagination__list {
      position: relative;
      .v-pagination__item {
        margin: 0;
        .v-btn {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background-color: transparent;
          @include fluidFont(14, 14, 20px);
          font-weight: 500;
          color: rgba(24, 34, 48, 1);
          .v-btn__overlay {
            display: none;
          }
        }
      }
      .v-pagination__item--is-active {
        background-color: rgba(249, 250, 251, 1);
        border-radius: 8px;
      }
      .v-pagination__prev {
        position: absolute;
        left: 0;
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        .v-btn {
          height: auto;
          padding: 8px 14px;
          border-radius: 8px;
          border: 1px solid rgba(208, 213, 221, 1);
          @include fluidFont(14, 14, 20px);
          font-weight: 600;
          text-transform: capitalize;
          letter-spacing: normal;
        }
      }
      .v-pagination__next {
        position: absolute;
        right: 0;
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        .v-btn {
          height: auto;
          padding: 8px 14px;
          border-radius: 8px;
          border: 1px solid rgba(208, 213, 221, 1);
          @include fluidFont(14, 14, 20px);
          font-weight: 600;
          text-transform: capitalize;
          letter-spacing: normal;
        }
      }
    }
  }
  .sort_arrow_rotate_up {
    transform: rotate(180deg);
  }
}
:deep(.desktop_wrapper) {
  @include respond(md) {
    display: none;
  }
}
.mobile__wrapper {
  display: none;
  @include respond(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pagination__count {
    @include fluidFont(14, 14, 20px);
    font-weight: 500;
    color: rgba(52, 64, 84, 1);
  }
  .v-btn {
    height: auto;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid rgba(208, 213, 221, 1);
    :deep(.v-btn__prepend) {
      margin: 0;
    }
    :deep(.v-btn__append) {
      margin: 0;
    }
  }
}
</style>
