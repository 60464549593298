<template>
  <GeneralDialog
    class="add_grant_modal"
    :headerTitle="modalHeader.title"
    :showBackButton="!!formData.grantType"
    @on-close="onCloseModal"
    @on-Back="onClickBack"
    width="675px"
    max-width="100%"
  >
    <template #body>
      <main
        class="tw-max-w-full tw-min-w-[200px] tw-w-[570px] tw-rounded-[20px]"
      >
        <section
          class="tw-flex tw-flex-col tw-w-full tw-gap-6 tw-pb-6 tw-border-b tw-border-dashed tw-border-[#C5D2F2] tw-border-0"
          v-if="!formData.grantType"
        >
          <Card
            @click="onSelectGrantType(GRANT)"
            class="grant_type !tw-py-4 !tw-px-6 tw-box-border tw-cursor-pointer !tw-shadow-[0px 0px 8px 0px #0C0F4A14]"
            :class="{
              'is-active': state.grantType === GRANT,
            }"
          >
            <template #content>
              <div class="tw-flex tw-items-start tw-gap-2">
                <div
                  class="tw-w-[40px] tw-h-[40px] tw-min-w-[40px] tw-overflow-hidden"
                >
                  <img
                    :src="require('@/assets/icons/blue-exclaimation.svg')"
                    alt=""
                  />
                </div>
                <div class="tw-flex tw-flex-col">
                  <Text variant="h5" lineHeight="18px">Grant</Text>
                  <Text
                    class="tw-mt-1"
                    variant="p"
                    textWeight="500"
                    lineHeight="18px"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >Grants are provided by the government, energy supplier or
                    other external organization</Text
                  >
                </div>
              </div>
            </template>
          </Card>
          <Card
            @click="onSelectGrantType(DEPOSIT)"
            class="grant_type !tw-py-4 !tw-px-6 tw-box-border tw-cursor-pointer"
            :class="{
              'is-active': state.grantType === DEPOSIT,
            }"
          >
            <template #content>
              <div class="tw-flex tw-items-start tw-gap-2">
                <div
                  class="tw-w-[40px] tw-h-[40px] tw-min-w-[40px] tw-overflow-hidden"
                >
                  <LightBulbFilledIcon />
                </div>
                <div class="tw-flex tw-flex-col">
                  <Text variant="h5" lineHeight="18px">Personal Discount</Text>
                  <Text
                    variant="p"
                    class="tw-mt-1"
                    textWeight="500"
                    lineHeight="18px"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >Add Personal Discount</Text
                  >
                </div>
              </div>
            </template>
          </Card>
        </section>

        <section
          v-else
          class="tw-flex tw-flex-col tw-w-full tw-gap-6 tw-pb-6 tw-border-b tw-border-dashed tw-border-[#C5D2F2] tw-border-0"
        >
          <v-form
            @submit.prevent
            ref="grantFormRef"
            class="tw-flex tw-gap-3 tw-items-start tw-relative tw-flex-col"
            :rules="Phase_Validation"
          >
            <!-- v-model="grantValid" -->
            <div class="left_block">
              <div
                class="form_content tw-w-full tw-flex tw-gap-2 sm:tw-flex-col"
                v-if="formData.grantType === 1"
              >
                <div class="form_content_col">
                  <label>Select Phase</label>
                  <v-select
                    :rules="[(v) => !!v || 'Select Phase is required']"
                    class="custom-select input_field"
                    v-model="selectedPhase"
                    placeholder="Phase "
                    item-title="name"
                    item-value="value"
                    variant="outlined"
                    :items="estimatePhaseList"
                    :menu-props="{ contentClass: 'c_select__dropdown' }"
                    @update:modelValue="handleGrantUpdate"
                  >
                  </v-select>
                </div>

                <div class="form_content_col">
                  <label>Select Grant Assigning</label>
                  <v-menu
                    transition="scale-transition"
                    :close-on-content-click="false"
                    v-model="isMenuOpen"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        variant="text"
                        class="custom-select input_field"
                        :disabled="!selectedPhase"
                      >
                        {{
                          percentageValue === null && amountValue === null
                            ? `Select Grant Assigning`
                            : selectedOption === "percentage"
                            ? `Percentage: ${percentageValue}%`
                            : `Amount: £${amountValue}`
                        }}
                      </v-btn>
                    </template>

                    <v-form
                      @submit.prevent
                      ref="abcformRef"
                      v-model="grantValid"
                      class="tw-w-full"
                      :rules="grantValidation"
                    >
                      <div class="dropdown-content">
                        <div class="content">
                          <p>Select Grant Assigning</p>

                          <v-radio-group
                            v-model="selectedOption"
                            class="radio-group"
                            @change="handleRadioChange"
                          >
                            <v-radio
                              label="Percentage"
                              value="percentage"
                              class="radio-button"
                              color="#4B4BFF"
                            ></v-radio>
                            <div>
                              <!-- class="input_field" -->
                              <v-text-field
                                variant="outlined"
                                type="number"
                                v-model="percentageValue"
                                :disabled="selectedOption !== 'percentage'"
                                :min="0"
                                :max="100"
                                single-line
                                flat
                                :rules="
                                  selectedOption === 'percentage'
                                    ? grantValidation.percentageValidation
                                    : []
                                "
                              ></v-text-field>
                            </div>
                            <v-radio
                              label="Amount"
                              value="amount"
                              class="radio-button"
                              color="#4B4BFF"
                            ></v-radio>
                            <div>
                              <!-- class="custom_input_field" -->
                              <v-text-field
                                variant="outlined"
                                type="number"
                                v-model="amountValue"
                                :disabled="selectedOption !== 'amount'"
                                :rules="
                                  selectedOption === 'amount'
                                    ? grantValidation.grantAmount
                                    : []
                                "
                              ></v-text-field>
                            </div>
                          </v-radio-group>
                        </div>
                      </div>
                    </v-form>
                  </v-menu>
                </div>
              </div>

              <div
                class="form_content tw-w-full tw-flex tw-flex-col tw-gap-2 mobile_form_content"
                v-if="formData.grantType == 2"
              >
                <v-form
                  @submit.prevent
                  :rules="validation"
                  ref="personalDiscountFormRef"
                  class="tw-flex tw-gap-3 tw-items-start tw-relative tw-flex-col"
                >
                  <Text variant="span" textWeight="400"> Grant Name </Text>
                  <v-text-field
                    placeholder="eg. NERC"
                    variant="outlined"
                    v-model="data.grantName"
                    :rules="validation.grantName"
                    class="c-input add_input"
                  ></v-text-field>

                  <Text variant="span" textWeight="400"> Grant Amount </Text>
                  <v-text-field
                    label="£"
                    variant="outlined"
                    class="c-input add_input_amount add_input"
                    v-model="formattedAmount"
                    :rules="validation.grantAmount"
                    @input="formatGrantAmount"
                  ></v-text-field>
                </v-form>
              </div>
              <div v-if="errorMessage_typeform" style="color: brown">
                {{ errorMessage_typeform }}
              </div>
            </div>
          </v-form>
        </section>
      </main>
    </template>

    <template #footer>
      <div
        class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-end tw-ml-auto tw-w-full tw-max-w-full"
      >
        <Button
          variant="accent"
          label="Next"
          @click="onNext"
          v-if="!formData.grantType"
          width="auto"
          class="tw-w-auto"
          :disabled="isDisableButton"
        />
        <div
          class="tw-flex tw-gap-3 tw-items-center tw-justify-end tw-w-full tw-max-w-full"
          v-else
        >
          <Button
            variant="accent"
            :label="buttonLabel"
            @click="onSave"
            width="auto"
            class="grant-save-btn"
          />
          <v-btn class="button button-purple-border" @click="onCloseModal"
            >cancel</v-btn
          >
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { reactive, getCurrentInstance } from "vue";

import Card from "@/core/components/ui/general/Card.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import LightBulbFilledIcon from "@/core/components/icons/LightBulbFilledIcon.vue";
import ExclamationFilledIcon from "@/core/components/icons/ExclamationFilledIcon.vue";
import { useStore } from "vuex";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { computed } from "vue";
import { ref } from "vue";
import currencyFilter from "@/filters/currency.filter";
import { isNumber } from "@/core/helpers/number.helper";
import { onMounted } from "vue";
import { displayToastMessage } from "@/core/utils/common";

const SELECT = "select";
const GRANT = "grant";
const DEPOSIT = "deposit";
const store = useStore();
const emits = defineEmits(["on-close", "on-save-project-estimation"]);
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const props = defineProps({
  totalSubTotal: {
    type: Number,
  },
  totalVat: {
    type: Number,
  },
  serviceCharges: {
    type: Number,
  },
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },
  // projectEstimate: {
  //   type: Object,
  //   default: null,
  // },
});
const isDisableButton = ref(false);
const selectedPhase = ref(null);
const isMenuOpen = ref(false);
let percentageValue = ref(null);
let amountValue = ref(null);
const grantValid = ref(false);
const errorMessage_typeform = ref(null);
const onClickBack = () => {
  isDisableButton.value = true;
  formData.value.grantType = null;
  state.grantType = "";
  modalHeader.title = "Add A Grant";
};
const modalHeader = {
  title: "Add A Grant",
};

const state = reactive({
  screenType: SELECT,
  grantType: "",
});

const grantPercentages = computed(() => {
  return [
    {
      name: "20%",
      value: 0.2,
    },
    {
      name: "10%",
      value: 0.1,
    },
    {
      name: "5%",
      value: 0.05,
    },
  ];
});
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const selectedOption = ref("percentage");

const handleGrantUpdate = () => {
  if (!selectedPhase.value) return; // if no phase is selected, do nothing
  const phaseTotal = selectedPhase.value.subTotal;
  if (selectedOption.value === "percentage") {
    data.value.grantAmount = phaseTotal * (percentageValue.value / 100);
  } else {
    data.value.grantAmount = amountValue.value;
  }
};

const handleRadioChange = () => {
  if (selectedOption.value === "amount") {
    percentageValue.value = "";
  } else if (selectedOption.value === "percentage") {
    amountValue.value = "";
  }
  handleGrantUpdate();
};

const onSelectGrantType = (type) => {
  state.grantType = type;
  isDisableButton.value = false;
};

const estimatePhaseList = computed(() => {
  const phases = estimateForm.value.projectStages.map((phase, index) => {
    return {
      name:
        phase.stageType === "labour"
          ? phase.name
            ? phase.name
            : `Phase ${index + 1}`
          : "Material",
      value: { ...phase, isAll: false },
    };
  });

  phases.unshift({
    name: "All",
    value: {
      id: null,
      subTotal: 0,
      isAll: true,
    },
  });
  return phases;
});

const onNext = () => {
  if (state.grantType === DEPOSIT) {
    formData.value.grantType = 2;
    modalHeader.title = "Personal Discount";
    formData.value.grantName = "";
  } else {
    modalHeader.title = "Grant";
    formData.value.grantType = 1;
  }
};
const buttonLabel = computed(() => {
  return formData.value.grantType === 1
    ? "Grant Save"
    : "Personal Discount Save";
});

const internalInstance = getCurrentInstance();

const onSave = async () => {
  if (formData.value.grantType === 1) {
    handleGrantUpdate();
    const isPercentage = selectedOption.value === "percentage" ? true : false;
    const formattedGrant = data.value.grantAmount
      ? Math.round(data.value.grantAmount * 100) / 100
      : 0;

    const isgrantvalid = await grantFormRef.value.validate();
    if (!isgrantvalid.valid) return;

    if (grantValid.value === false) {
      if (selectedOption.value === "percentage") {
        errorMessage_typeform.value =
          "percentage value must be between 0 and 100";
        return;
      } else if (selectedOption.value === "amount") {
        errorMessage_typeform.value =
          "provide correct grant amount for following phase";
        return;
      }
    }

    if (!selectedPhase.value?.isAll) {
      const updatedPhase = estimateForm.value.projectStages.find(
        (phase) => phase.id === selectedPhase.value.id
      );
      updatedPhase.isPercentage = isPercentage;
      updatedPhase.grantAmount = formattedGrant;
      updatedPhase.isGranted = true;
    } else {
      if (isPercentage) {
        estimateForm.value.projectStages = estimateForm.value.projectStages.map(
          (phase) => {
            console.log(phase.subTotal * (percentageValue.value / 100));
            return {
              ...phase,
              isGranted: true,
              isPercentage: isPercentage,
              grantAmount: phase.subTotal * (percentageValue.value / 100),
            };
          }
        );
      } else {
        estimateForm.value.projectStages = estimateForm.value.projectStages.map(
          (phase) => {
            return {
              ...phase,
              isGranted: true,
              isPercentage: isPercentage,
              grantAmount: formattedGrant,
            };
          }
        );
      }
    }

    const totalGrantAmount = estimateForm.value.projectStages.reduce(
      (acc, phase) => acc + phase.grantAmount,
      0
    );
    formData.value.grantAmount = totalGrantAmount;
  } else {
    const isDiscountValid = await personalDiscountFormRef.value.validate();
    if (!isDiscountValid.valid) return;
    if (!data.value.personalAmount) return;
    formData.value.grantName = data.value.grantName;
    formData.value.grantAmount = data.value.personalAmount;
  }
  emits("on-save-project-estimation");
  errorMessage_typeform.value = "";
  displayToastMessage(internalInstance, `Grant Information saved`, "success");
  onCloseModal();
};

const onCloseModal = () => {
  emits("on-close");
};
const customerTotal = computed(() => {
  const total = props.totalSubTotal + props.totalVat + props.serviceCharges;
  let formattedAmount = props.isDIYProjectType
    ? formData.value?.totalCost || 0
    : Math.round(parseFloat(total) * 100) / 100;

  if (formattedAmount < 0) {
    formattedAmount = 0;
  }
  return formattedAmount;
});

const validation = ref({
  grantName: [
    (v) => !!v || "Provide Grant Name",
    (v) => v.length <= 255 || "Grant name too long",
  ],
  grantAmount: [
    (v) => !!v || "Provide Grant Amount",
    (v) => !isNaN(Number(v.replace(/,/g, ""))) || "Must be a valid number",
    (v) => {
      const amount = Number(v.replace(/,/g, ""));
      return (
        amount <= Number(customerTotal.value) ||
        `${
          state.grantType === DEPOSIT
            ? "Personal Discount cannot exceed the customer's payment"
            : "Grant cannot exceed the customer's payment"
        }`
      );
    },
  ],
});

const Phase_Validation = ref([
  (value) => {
    !!value || "This field is required.";
  },
]);

const grantValidation = ref({
  percentageValidation: [
    (v) =>
      (v >= 0 && v <= 100 && !!v) || "Please enter a value between 0 and 100",
    (v) => {
      if (!v) return true;

      if (selectedPhase.value) {
        const convertedValue = (Number(v) / 100) * selectedPhase.value.subTotal;
        return (
          convertedValue <= Number(customerTotal.value) ||
          `Converted value (${convertedValue}) cannot exceed customer total (${customerTotal.value}).`
        );
      }
    },
  ],
  grantAmount: [
    (v) => !!v || "Provide Grant Amount",
    (v) => !isNaN(Number(v.replace(/,/g, ""))) || "Must be a valid number",
    (v) => {
      const amount = Number(v.replace(/,/g, ""));
      if (selectedPhase?.value?.isAll) {
        if (
          !estimateForm.value.projectStages.every(
            (phase) => phase.subTotal > amount
          )
        ) {
          return "Grant amount exceeds one or more phase amounts";
        }
      } else {
        const PhaseAmount = estimateForm.value.projectStages.find(
          (phase) => phase.id === selectedPhase.value.id
        );
        if (amount >= PhaseAmount.subTotal) {
          return `Grant cannot exceed phase amount`;
        }
      }
    },
  ],
});

// Reactive validation rules based on the selected option
// const selectedVa 9

const phaseTypeValidation = ref({});
const formatNumber = (value) => {
  if (!value) return "";
  let number = value.toString().replace(/\D/g, "");
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const formattedAmount = ref("");
const personalDiscountFormRef = ref(null);
const grantFormRef = ref(null);
const abcformRef = ref(null);

const data = ref({
  grantName: "",
  grantAmount: 0,
  personalAmount: 0,
});

const formatGrantAmount = (event) => {
  const value = event.target.value.replace(/,/g, "");
  data.value.personalAmount = value;
  formattedAmount.value = formatNumber(value);
};

onMounted(() => {
  if (formData.value.grantType === 1) {
    data.value.grantAmount = formData.value.grantAmount;
  } else {
    data.value.grantName = formData.value.grantName;
    data.value.personalAmount = formData.value.grantName
      ? formData.value.grantAmount
      : 0;
  }
  formattedAmount.value = formatNumber(formData.value.grantAmount);
});
</script>
<script>
export default {
  name: "AddGrantModal",
};
</script>
<style lang="scss" scoped>
.is-active {
  border: 2px solid #faa500 !important;
}
.grant_type {
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
}
.add_input {
  margin: 0;
  width: 100%;
  max-width: 100%;
  :deep(.v-input__control) {
    .v-field {
      .v-field__field {
        border: 1px solid rgba($buttonText, 0.4);
        border-radius: 8px;
        .v-field__input {
          background-color: transparent;
          min-height: 40px;
          padding: 12px 16px;
        }
      }
    }
  }
}
.v-field--active .v-label.v-field-label {
  visibility: visible;
}

.add_input_amount {
  :deep(.v-input__control) {
    .v-field--active {
      .v-field__field {
        .v-label.v-field-label {
          visibility: visible;
          font-weight: 700;
          color: rgba($blueDark, 1);
        }
        .v-field__input {
          padding: 12px 30px;
        }
      }
    }
    .v-field {
      .v-field__field {
        .v-label.v-field-label {
          font-weight: 700;
          color: rgba($blueDark, 1);
          opacity: 1;
        }
      }
    }
  }
}

.add_grant_modal {
  .general-dialog {
    main {
      width: 100%;
      max-width: 100%;
      .v-form {
        padding: 10px;
        background-color: rgba($backgroundcolor, 1);
        border-radius: 6px;
        @include respond(s720) {
          background-color: transparent;
          border-radius: 0;
          padding: 0;
        }
        .form_content {
          .form_content_col {
            width: 100%;
            label {
              @include fluidFont(12, 12, 2);
              font-weight: 600;
              letter-spacing: 0.25px;
              color: rgba($blueDark, 1);
              margin-bottom: 4px;
            }
            .v-btn {
              border: 1px solid #dfdfdf;
              border-radius: 8px;
              height: 43px !important;
              background-color: rgba($white, 1);
              @include fluidFont(12, 12, 16px);
              font-weight: 400;
              color: rgba($blueDark, 1);
              text-transform: capitalize;
              letter-spacing: 0.4px;
              width: 100%;
              max-width: 100%;
              justify-content: flex-start;
            }
            .input_field {
              :deep(.v-input__control) {
                .v-field {
                  border: 1px solid rgba($blueDark, 0.2);
                  border-radius: 8px;
                  padding: 0;
                  background-color: rgba($white, 1);
                  .v-field__field {
                    .v-field__input {
                      background-color: transparent;
                      min-height: 40px;
                      padding: 0 16px;
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                      letter-spacing: 0.4px;
                      opacity: 1;
                      input {
                        top: 50%;
                        transform: translateY(-50%);
                      }
                    }
                  }
                  .v-field__outline {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.select__input {
  display: flex;
  gap: 0;
  align-items: center;
  .input-form-field {
    width: 50%;
    max-width: 100%;
    position: relative;
    span {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    .custom_input_field {
      width: 100%;
      max-width: 100%;
      :deep(.v-input__control) {
        .v-field {
          border: 1px solid rgba($blueDark, 0.2);
          border-radius: 8px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          padding: 0;
          border-right: 0;
          .v-field__field {
            .v-field__input {
              background-color: transparent;
              min-height: 40px;
              padding: 0 16px;
              input {
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
          .v-field__outline {
            display: none;
          }
        }
      }
    }
  }
  .custom-select {
    width: 50%;
    max-width: 100%;
    :deep(.v-input__control) {
      .v-field {
        border: 1px solid rgba($blueDark, 0.2);
        border-radius: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0;
        .v-field__field {
          .v-field__input {
            background-color: transparent;
            min-height: 40px;
            padding: 0 16px;
            input {
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .v-field__outline {
          display: none;
        }
      }
    }
  }
}
.delete_field {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 40px;
  @include respond(s720) {
    top: 120px;
  }
}
.left_block {
  width: 100%;
  max-width: 100%;
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-column-gap: 12px;
  // grid-row-gap: 12px;
  // @include respond(s720) {
  //   grid-template-columns: repeat(1, 1fr);
  //   width: 100%;
  //   padding: 10px;
  //   background-color: #f8fbfd;
  //   border-radius: 6px;
  // }
}
.mobile_form_content {
  @include respond(s720) {
    width: calc(100% - 50px);
    max-width: 100%;
  }
}
.grant-save-btn {
  width: auto !important;
}

.v-btn:hover > .v-btn__overlay {
  opacity: 0;
}

.v-btn > :deep(.v-btn__overlay) {
  opacity: 0 !important;
}
::v-deep .dropdown-content .content {
  .v-input {
    .v-input__details {
      display: block !important;
    }
  }
}
</style>
