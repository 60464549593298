<template>
  <div
    class="tw-w-auto tw-py-1 tw-px-2 tw-box-border tw-flex tw-items-center tw-gap-2 tw-rounded-lg tw-border-[1.3px] tw-border-[#FF2400] tw-border-dashed"
  >
    <img
      src="@/assets/icons/id-not-verified.svg"
      class="tw-w-6 tw-h-6 tw-filter tw-invert-[18%] tw-sepia-[83%] tw-saturate-[7470%] tw-hue-rotate-[359deg] tw-brightness-[97%] tw-contrast-[119%]"
    />
    <Text
      variant="xsmall"
      class="rmd:!tw-text-[12px]"
      textColor="#FF2400"
      whiteSpace="nowrap"
      >ID Not Verified</Text
    >
    <v-icon
      icon="mdi-chevron-right"
      v-if="!isUserProprtyOwner"
      textColor="#FF2400"
      class="!tw-text-[12px]"
    ></v-icon>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import Text from "../ui/general/Text.vue";
import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const store = useStore();
const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);
</script>
<style lang="scss" scoped></style>
