<template>
  <GeneralDialog @on-close="onCloseModal" maxWidth="370px">
    <template #body>
      <div class="tw-flex tw-flex-col tw-gap-3 tw-w-full tw-max-w-[404px]">
        <div
          class="tw-h-[104px] tw-w-full tw-bg-[#D9D9D9] tw-rounded-lg tw-flex tw-items-center tw-justify-center"
        >
          <img :src="require('@/assets/images/estimation-invitation.png')" />
        </div>
        <Text variant="p">Your estimate invitation has been sent</Text>
        <Text
          v-if="inviteUserEmail"
          variant="span"
          textColor="rgba(12, 15, 74, 0.5)"
          textWeight="400"
        >
          <p>We’ve sent your invitations to.</p>
          <span>{{ inviteUserEmail }}</span></Text
        >

        <Text
          v-else
          variant="span"
          textColor="rgba(12, 15, 74, 0.5)"
          textWeight="400"
          >Your estimate invitation has been sent to the subcontractor. You’ll
          receive confirmation once they respond. Thank you for keeping the
          estimate on track!</Text
        >
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2">
        <Button variant="secondary" label="Close" @click="onCloseModal" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";

const emits = defineEmits(["on-close"]);
const props = defineProps({
  inviteUserEmail: {
    type: String,
    default: null,
  },
});

const onCloseModal = () => {
  emits("on-close");
};
</script>
<script>
export default {
  name: "InviteConfirmationModal",
};
</script>
<style lang="scss" scoped></style>
