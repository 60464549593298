<template>
  <GeneralDialog headerTitle="Share" @on-close="onCloseModal">
    <template #body>
      <section
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-min-w-[450px]"
      >
        <v-form
          :rules="validation"
          ref="emailFormRef"
          @submit.prevent
          class="tw-w-full"
        >
          <div class="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-w-full">
            <v-text-field
              type="email"
              density="compact"
              v-model="form.email"
              :rules="validation.email"
              variant="outlined"
              class="c-input"
              placeholder="Email address"
            ></v-text-field>
            <v-select
              v-if="isShowWorkStationList"
              placeholder="Select WorkStation"
              v-model="form.workStation"
              :rules="validation.workStation"
              :items="getWorkStation"
              item-title="text"
              item-value="value"
              variant="outlined"
              class="c-select"
            ></v-select>
            <Button
              variant="text"
              label="Add"
              class="!tw-w-auto !tw-text-[#FAA500]"
              @click="onAddEmail"
            >
              <template #prefix
                ><AvatarPlusIcon fill="#FAA500" fillOpacity="1"
              /></template>
            </Button>
          </div>
        </v-form>
        <div
          class="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-w-full tw-min-h-[300px] tw-max-h-[300px] tw-overflow-hidden"
        >
          <Text variant="h6">People with access</Text>
          <div
            class="tw-w-full tw-h-[300px] tw-overflow-y-auto tw-flex tw-flex-col tw-gap-4 tw-rounded-md"
          >
            <!-- email item -->
            <div
              class="tw-w-full tw-flex tw-items-center tw-justify-between"
              v-for="(item, index) in state.addedEmails"
              :key="index"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <ProfileThumbnail size="md" />
                <div class="tw-flex tw-flex-col tw-items-start">
                  <Text variant="p" lineHeight="18px" :isCapitalize="true">{{
                    item.name
                  }}</Text>
                  <Text variant="p" textWeight="400">{{ item.email }}</Text>
                </div>
              </div>
              <ChipState
                v-if="item.isOwner"
                class="!tw-w-[110px] !tw-h-[40px]"
                label="Owner"
                bgColor="rgba(128, 130, 159, 0.1)"
              ></ChipState>
              <v-select
                v-else
                class="custom-select-input !tw-max-w-[110px]"
                variant="outlined"
                density="compact"
                label="Access"
                v-model="form.role"
                :rules="validation.role"
                :items="roleItems"
                item-title="text"
                item-value="value"
              ></v-select>
            </div>
          </div>
        </div>
      </section>
    </template>
    <template #footer>
      <section class="tw-w-full tw-grid tw-items-center tw-gap-3">
        <Button
          :disabled="!isFormValid"
          label="done"
          variant="accent"
          :isActive="true"
          @click="onClickShareDocument"
        />
      </section>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive } from "vue";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import AvatarPlusIcon from "@/core/components/icons/AvatarPlusIcon.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import { useStore } from "vuex";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";

const emits = defineEmits(["on-close"]);
const props = defineProps(["selectedDocument", "isPropertyDocument"]);

const state = reactive({
  addedEmails: [],
});

const form = reactive({
  email: "",
  workStation: "",
  role: "",
});

const roleItems = ref([
  {
    text: "Viewer",
    value: 2,
  },
  {
    text: "Editor",
    value: 3,
  },
]);

const isShowWorkStationList = ref(false);
const getWorkStation = ref([]);

const store = useStore();
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const emailFormRef = ref(null);
const validation = ref({
  email: [
    (v) => !!v || "Email is required",
    (v) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Invalid email. Try again.",
  ],
  workStation: [(v) => !!v || "WorkStation is required"],
  role: [(v) => !!v || "Role is required"],
});

const isFormValid = computed(() => {
  const emailValid = validation.value.email.every(
    (validate) => validate(form.email) === true
  );
  const workStationValid = validation.value.workStation.every(
    (validate) => validate(form.workStation) === true
  );
  const roleValid = validation.value.role.every(
    (validate) => validate(form.role) === true
  );
  return emailValid && workStationValid && roleValid;
});

// const isEmailExist = computed(() => {
// return state.addedEmails.find((added) => added.email === form.email);
// });

const onAddEmail = async (e) => {
  e.preventDefault();
  const isFormValid = await emailFormRef.value.validate();

  if (isFormValid.valid) {
    try {
      const params = {
        name: form.email,
        userId: user.value?.id,
      };
      let response = await store.dispatch(
        `${DOCUMENTS_STORE}/getWorkStationList`,
        params
      );
      if (response) {
        isShowWorkStationList.value = true;
        const workStations = response.map((workStation) => ({
          value: workStation.id,
          text:
            workStation.name === "Default"
              ? `${workStation.user.firstName} ${workStation.user.lastName}`
              : workStation.name,
        }));
        getWorkStation.value.push(...workStations);
        state.addedEmails.push({
          name: "sample name",
          email: form.email,
          isOwner: false,
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  }
};

const onClickShareDocument = async () => {
  const { selectedDocument } = props;
  const shareFile = {
    toWorkstationId: form.workStation,
    userDocumentId: selectedDocument.id,
    permissionId: form.role,
    isDir: selectedDocument.isDir ? 1 : 0,
  };
  await store.dispatch(`${DOCUMENTS_STORE}/shareDocument`, shareFile);
  emits("on-close");
};

const onCloseModal = () => {
  emits("on-close");
};
</script>
<style lang="scss" scoped>
.custom-select-input {
  @apply tw-shadow-none tw-ring-0 tw-text-[12px] tw-font-[400] tw-text-[#0C0F4A];

  //
  :deep(.v-field) {
    @apply tw-shadow-none tw-text-xs tw-text-center tw-bg-white;
  }
  :deep(.v-field__input) {
    @apply tw-text-xs tw-pt-3;
  }
}
.v-input {
  margin: 0;
  width: 100%;
  max-width: 100%;
  :deep(.v-input__control) {
    .v-field {
      border: 1px solid rgba($blueDark, 0.2);
      border-radius: 8px;
      padding: 0;
      background-color: transparent;
      .v-field__field {
        // .v-label {
        //   @include fluidFont(12, 12, 2);
        //   font-weight: 500;
        //   letter-spacing: 0.15px;
        //   color: rgba($blueDark, 1);
        //   visibility: visible;
        // }
        .v-field__input {
          background-color: transparent;
          border-radius: 0;
          @include fluidFont(12, 12, 2);
          font-weight: 500;
          letter-spacing: 0.15px;
          color: rgba($blueDark, 1);
          padding: 8px 16px;
          min-height: 40px;
          .v-select__selection {
            .v-select__selection-text {
              @include fluidFont(12, 12, 2);
              font-weight: 500;
              letter-spacing: 0.15px;
              color: rgba($blueDark, 1);
            }
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}
</style>
