<template>
  <v-dialog
    width="auto"
    v-model="isShowModel"
    class="modal_box action-modal"
    persistent
  >
    <div class="modal_wrapper">
      <div
        v-if="isShowBackBtn"
        class="previous_btn isShowSoleTraderWorkStation"
      >
        <v-btn
          variant="text"
          aria-label="Refresh"
          class="back_btn"
          prepend-icon="mdi-chevron-left"
          @click="onClickBackButton"
          >back</v-btn
        >
      </div>

      <main class="main">
        <div>
          <div class="action-icon">
            <KycSuccess v-if="currentModelStatus.name === 'success'" />
            <KycPending v-if="currentModelStatus.name === 'pending'" />
            <KycUnsuccess v-if="currentModelStatus.name === 'unsuccess'" />
          </div>
          <h2 class="modal-title">{{ currentModelStatus.label }}</h2>
          <p class="modal-desc" v-html="currentModelStatus.description"></p>
          <Button
            label="FINISH"
            variant="accent"
            class="button w-full"
            @click="onContinue"
          />
        </div>
      </main>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
/**/
</style>

<script>
import { computed } from "vue";
import KycPending from "./icons/KycPending.vue";
import KycSuccess from "./icons/KycSuccess.vue";
import KycUnsuccess from "./icons/KycUnsuccess.vue";
import Button from "./ui/general/Button.vue";

export default {
  components: {
    KycSuccess,
    KycPending,
    KycUnsuccess,
    Button,
  },
  props: {
    kycStatus: {
      type: String,
    },
    onClickBackButton: {
      type: Function,
    },
    onContinue: {
      type: Function,
    },
    isShowBackBtn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isShowModel = true;
    const kycModalStatus = [
      {
        name: "pending",
        status: ["PENDING", "RUNNING"],
        label: "KYC Verification Pending",
        description: `Your KYC verification is pending. Once the verification is complete, you’ll be able to
access your full dashboard.`,
      },
      {
        name: "success",
        status: ["SUCCESS"],
        label: "KYC Verification Successful",
        description: `Your KYC verification has been successful. You’ll have access to the full dashboard soon.`,
      },
      {
        name: "unsuccess",
        status: ["ERROR", "VERIFICATION_ERROR"],
        label: "KYC Verification Unsuccessful",
        description: `Your KYC verification was unsuccessful. You may
try again later or verify with another ID.`,
      },
      {
        name: "success",
        status: ["KYBINFO"],
        label: "KYB Email Sent!",
        description: `Please check your inbox and verify your business!<br>
            You will need to complete this to verify your Workstation.<br><br>
            Ensure you have a form of ID (identification) to hand, especially if you’re verifying yourself for the first time.`,
      },
    ];
    const currentModelStatus = computed(() =>
      kycModalStatus.find((data) => data.status.includes(props.kycStatus))
    );
    return {
      isShowModel,
      currentModelStatus,
    };
  },
};
</script>
