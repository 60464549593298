<template>
  <v-btn
    class="reusable-button tw-w-auto"
    :class="[
      buttonClass,
      { '!tw-capitalize': isCapitalize },
      { 'is-disabled': !isActive },
      {
        '!tw-min-h-[unset] !tw-max-h-[unset] !tw-h-[26px] !tw-p-4 !tw-rounded-[5.85px]':
          compact,
      },
    ]"
    :disabled="!isActive || isLoading"
    :loading="isLoading"
    variant="flat"
    :ripple="ripple"
  >
    <div
      class="tw-w-full tw-flex tw-h-full tw-flex-row tw-gap-2 tw-items-center tw-justify-center tw-tracking-normal"
    >
      <slot name="prefix"></slot>
      <Text
        v-if="!compact"
        class="!tw-capitalize md:!tw-text-[14px]"
        variant="h6"
        :textColor="isActive ? 'activeFontColor' : 'rgba(12, 15, 74, 0.4)'"
        >{{ label }}</Text
      >
      <Text
        v-else:
        class="!tw-capitalize md:!tw-text-[12px]"
        variant="p"
        :textColor="isActive ? 'activeFontColor' : 'rgba(12, 15, 74, 0.4)'"
        >{{ label }}</Text
      >
      <slot name="postfix"></slot>
    </div>
  </v-btn>
</template>
<script setup>
import { computed } from "vue";
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  variant: {
    type: String,
    default: "primary",
    // variants: primary, primary-outline, secondary, tertiary, accent, text, custom,
    // light-purple-outline, purple-outline, purple
  },
  label: String,
  isActive: {
    type: Boolean,
    default: true,
  },
  isLoading: Boolean,
  activeBgColor: {
    type: String,
    default: "#FAA500",
  },
  activeFontColor: {
    type: String,
    default: "#FFF",
  },
  fontSize: {
    type: String,
    default: "14px",
  },
  opacity: {
    type: String,
    default: "1",
  },
  height: {
    type: String,
    default: "42px",
  },
  width: {
    type: String,
    default: "100%",
  },
  borderRadius: {
    type: String,
    default: "8px",
  },
  border: {
    type: String,
    default: "1px solid",
  },
  borderColor: {
    type: String,
    default: "transparent",
  },
  ripple: {
    type: Boolean,
    default: true,
  },
  isCapitalize: {
    type: Boolean,
    default: false,
  },
  letterSpacing: {
    type: String,
    default: "0.4px",
  },
  fontWeight: {
    type: String,
    default: "400",
  },
  compact: {
    type: Boolean,
    default: false,
  },
  isUnderline: {
    type: Boolean,
    default: false,
  },
});

const buttonClass = computed(() => `button-${props.variant}`);
</script>
<script>
export default {
  name: "Button",
};
</script>
<style lang="scss" scoped>
:deep(.v-btn__content) {
  height: 100%;
}
.reusable-button {
  @apply tw-h-[42px] tw-max-h-[42px] tw-min-h-[42px] tw-px-5 tw-box-border tw-rounded-lg tw-flex tw-flex-col tw-items-center tw-justify-center
    tw-text-sm tw-font-bold tw-font-mullish tw-m-0 tw-py-2;
}

.reusable-button.v-btn--disabled,
.reusable-button.is-disabled {
  @apply tw-text-[rgba(12,15,74,0.5)] hover:tw-bg-[rgba(12,15,74,0.04)] tw-cursor-not-allowed;
  // background-color: rgba(12, 15, 74, 0.04) !important;
  cursor: no-drop !important;
  border: none !important;
}

.button-primary {
  @apply tw-bg-orange tw-border-none tw-border-0 tw-text-white tw-w-full;
  &:hover {
    background-color: $blueDark;
    color: $orange;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.button-primary-outline {
  @apply tw-bg-white tw-border-[1px] tw-border-orange tw-text-orange tw-w-full tw-border-solid;
  &:hover {
    background-color: #ffa500;
    color: $white;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.button-secondary {
  @apply tw-bg-white tw-border-[1px] tw-border-[#4F55F0] tw-text-accentBlue tw-w-full tw-border-solid;
  &:hover {
    background-color: $PrimaryPurple;
    color: $white;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.button-secondary-reverse {
  @apply tw-bg-white tw-border-[1px] tw-border-orange tw-text-orange tw-w-full;
  &:hover {
    background-color: $orange;
    color: $white;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.button-tertiary {
  @apply tw-bg-[rgba(12,15,74,0.04)] tw-text-[rgba(12,15,74,0.5)] hover:tw-bg-[rgba(12,15,74,0.04)]
    tw-border-none tw-border-0 tw-w-full;
}

.button-accent {
  @apply tw-bg-[#3366FF] tw-border-none tw-border-0 tw-text-white tw-w-full;
  &:hover {
    background-color: $white;
    color: $PrimaryPurple;
    box-shadow: none;
    border: 1px solid $PrimaryPurple !important;
  }
  &:focus {
    box-shadow: none;
  }
}

.button-purple-outline {
  @apply tw-bg-[#7070FF] tw-border-[3px] tw-border-[#DBDBFF] tw-border-solid tw-text-white tw-w-full tw-capitalize;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    background-color: $white;
    color: #7070ff;
    box-shadow: none;
    border: 3px solid #dbdbff !important;
  }
  &:focus {
    box-shadow: none;
  }
}

.button-light-purple-outline {
  @apply tw-bg-[#DBDBFF] tw-border-[2px] tw-border-[#3366FF] tw-border-solid tw-text-[#2F54EB] tw-w-full tw-capitalize;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    background-color: #3366ff;
    color: $white;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.button-purple {
  @apply tw-bg-[#7070FF] tw-border-none tw-border-0 tw-text-white tw-w-full;
  &:hover {
    background-color: $white;
    color: $PrimaryPurple;
    box-shadow: none;
    border: 1px solid $PrimaryPurple !important;
  }
  &:focus {
    box-shadow: none;
  }
}

.button-text {
  @apply tw-bg-transparent tw-text-[#0C0F4A]
    tw-border-none tw-border-0 tw-w-full;
}

.button-custom {
  width: v-bind("props.width") !important;
  height: v-bind("props.height") !important;
  max-height: v-bind("props.height") !important;
  min-height: v-bind("props.height") !important;
  background: v-bind("props.activeBgColor");
  color: v-bind("props.activeFontColor");
  border-color: v-bind("props.borderColor") !important;
  border-radius: v-bind("props.borderRadius");
  border: v-bind("props.border");
  font-size: v-bind("props.fontSize");
  font-weight: v-bind("props.fontWeight");
  letter-spacing: v-bind("props.letterSpacing");
  opacity: v-bind("props.Opacity");
}
:deep(.v-btn):hover > .v-btn__overlay {
  opacity: 0;
}

.button-transparent {
  text-transform: capitalize !important;
  font-size: 0.75rem !important;
  letter-spacing: normal !important;
  font-weight: normal !important;
  color: rgba($blueDark, 1);
  background-color: transparent !important;
  width: auto !important;
}
.back__button {
  height: unset;
  min-height: auto;
  @include fluidFont(12, 12, 1.2);
  font-weight: 600;
  color: rgba($blueDark, 1);
}
</style>
