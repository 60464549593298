import { UserWorkstationMemberRoleEnum } from "@/core/enums/RolesEnum";
import { USER_STORE } from "../user";
import { WORKSTATION } from "../workstation";
import { includes } from "lodash";
import { EstimatePhaseAssignMemberStatusEnum } from "@/core/enums/estimateEnum";

export default {
  estimateForm: (state: { estimateForm: any }) => state.estimateForm,
  defaultEstimateForm: (state: { defaultEstimateForm: any }) =>
    state.defaultEstimateForm,
  defaultFormData: (state: { defaultFormData: any }) => state.defaultFormData,
  selectedPhaseIndex: (state: { selectedPhaseIndex: any }) =>
    state.selectedPhaseIndex,
  templateName: (state: { templateName: any }) => state.templateName,
  showPhaseUploadAttachmentModal: (state: {
    showPhaseUploadAttachmentModal: any;
  }) => state.showPhaseUploadAttachmentModal,
  showTaskUploadAttachmentModal: (state: {
    showTaskUploadAttachmentModal: any;
  }) => state.showTaskUploadAttachmentModal,
  showCreateTemplateConfirmationModal: (state: {
    showCreateTemplateConfirmationModal: any;
  }) => state.showCreateTemplateConfirmationModal,
  showUseTemplateModal: (state: { showUseTemplateModal: any }) =>
    state.showUseTemplateModal,
  showSubmitEstimateConfirmationModal: (state: {
    showSubmitEstimateConfirmationModal: any;
  }) => state.showSubmitEstimateConfirmationModal,

  selectedJobTemplate: (state: { selectedJobTemplate: any }) =>
    state.selectedJobTemplate,
  isOnEstimateForm: (state: { isOnEstimateForm: any }) =>
    state.isOnEstimateForm,
  confirmDeleteModal: (state: { confirmDeleteModal: any }) =>
    state.confirmDeleteModal,
  isFormReady: (state: { isFormReady: any }) => state.isFormReady,

  defaultProjectStageForm: (state: { defaultProjectStageForm: any }) =>
    state.defaultProjectStageForm,
  confirmLoadTemplateModal: (state: { confirmLoadTemplateModal: any }) =>
    state.confirmLoadTemplateModal,
  selectedTemplateId: (state: { selectedTemplateId: any }) =>
    state.selectedTemplateId,
  formData: (state: { formData: any }) => state.formData,
  selectDepositModal: (state: { selectDepositModal: any }) =>
    state.selectDepositModal,
  isTemplateCreate: (state: { isTemplateCreate: any }) =>
    state.isTemplateCreate,

  isEstimateLoadedModified: (state: { isEstimateLoadedModified: any }) =>
    state.isEstimateLoadedModified,
  isEstimateSubmit: (state: { isEstimateSubmit: any }) =>
    state.isEstimateSubmit,
  hasLoadedTemplate: (state: { hasLoadedTemplate: any }) =>
    state.hasLoadedTemplate,
  loadedEstimate: (state: { loadedEstimate: any }) => state.loadedEstimate,
  isFormReadOnly: (state: { isFormReadOnly: any }) => state.isFormReadOnly,
  lockTemplateModifiedChecker: (state: { lockTemplateModifiedChecker: any }) =>
    state.lockTemplateModifiedChecker,
  selectedAssignMembers: (state: { selectedAssignMembers: any }) =>
    state.selectedAssignMembers,
  assignMembersModal: (state: { assignMembersModal: any }) =>
    state.assignMembersModal,
  assignMembers: (state: { assignMembers: any }) => state.assignMembers,
  invitedContractorsList: (state: { invitedContractorsList: any }) =>
    state.invitedContractorsList,
  getIsSubContractor: (state: { isSubContractor: any }) =>
    state.isSubContractor,
  showUpdateEstimateConfirmationModal: (state: {
    showUpdateEstimateConfirmationModal: any;
  }) => state.showUpdateEstimateConfirmationModal,
  getProjectEstimationInvitation: (state: {
    projectEstimationInvitation: any;
  }) => state.projectEstimationInvitation,

  getIsSubmitProjectEstimation: (state: {
    isSubmitProjectEstimation: boolean;
  }) => state.isSubmitProjectEstimation,

  getProjectEstimateErrors: (state: { errors: any }) => state.errors,
  getProjectEstimateIBGErrors: (state: { ibgErrors: any }) => state.ibgErrors,
  getFromErrors: (state: { isFormErrors: any }) => state.isFormErrors,
  getsubContractorsPhases: (state: any) => state.subContractorsPhases,
  getparentContractorPhases: (state: any) => state.parentContractorPhases,
  getIsEstimateSubmitted: (
    state: any,
    data: any,
    { workstation, jobTemplate }: any
  ) => {
    if (
      jobTemplate?.estimateResponse &&
      Object.keys(jobTemplate?.estimateResponse)?.length
    ) {
      const myCon = state.invitedContractorsList.find(
        (el: any) =>
          el?.toContractorId == workstation?.activeUserWorkstation?.id
      );
      if (
        workstation?.activeUserWorkstation?.id ==
        jobTemplate?.estimateResponse?.workStation?.id
      ) {
        if (
          [1, 2, 5, 6, 7].includes(
            jobTemplate?.estimateResponse?.agreeTermsConsumerDisclosure
          )
        ) {
          return true;
        }
      } else {
        return myCon?.agreeTermsConsumerDisclosure !== 4 || false;
      }
    } else {
      return false;
    }
  },
  IsFinalEstimateSubmitted: (state: any, data: any, { jobTemplate }: any) => {
    if (
      jobTemplate?.estimateResponse &&
      Object.keys(jobTemplate?.estimateResponse).length
    ) {
      return [1, 2].includes(
        jobTemplate?.estimateResponse?.agreeTermsConsumerDisclosure
      );
    } else {
      return false;
    }
  },
  getTotalPhases: (state: any) => state.totalPhases,
  getEstimateResponse(state: any) {
    return state.estimateResponse;
  },
  getJobTemplates: (state: any) => state.jobTemplates,
  getEstimatePhaseNotes: (state: any) => state.estimatePhaseNote,
  getUpdatedEstimatePhaseNotes: (state: any) => state.updatedEstimatePhaseNote,
  isUserHasEstimateEditAccess: (
    state: any,
    getter: any,
    rootState: any,
    rootGetters: any
  ) => {
    const user = rootGetters[`${USER_STORE}/user`];
    const activeUserWorkstation =
      rootGetters[`${WORKSTATION}/activeUserWorkstation`];
    const assignTeamMembers = state.estimateResponse?.assignTeamMembers || [];
    const isUserInvitedInEstimate = assignTeamMembers.some(
      (member: any) =>
        member?.status === EstimatePhaseAssignMemberStatusEnum.ACCEPTED &&
        member?.userWorkstationMember?.toUserId === user.id
    );
    const isUserCreatorOfWS = user?.id === activeUserWorkstation?.user?.id;

    const isUserHasEditPermission = [
      UserWorkstationMemberRoleEnum.ADMIN,
      UserWorkstationMemberRoleEnum.EDITOR,
    ].includes(activeUserWorkstation?.role);

    if (isUserCreatorOfWS) return true;
    return isUserHasEditPermission && isUserInvitedInEstimate;
  },
  getHoldEstimateAddTaskApi: (state: any) => state.holdEstimateAddTaskApi,
  getUpdateChangeRequest: (state: any) => state.changeEstimateRequest,
  getDefaultEstimateDetails: (state: any) => state.defaultEstimateDetails,
  getphaseAttachmentsLocally: (state: any) => (data: any) => {
    return state.phaseAttachmentLocally[data];
  },
  getDummyEstimateForm: (state: any) => state.dummyEstimateForm,
  getIbgPolicyList: (state: { ibgPolicyList: any }) => state.ibgPolicyList,
};
