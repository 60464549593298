<template>
  <commonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="570px"
    max-width="100%"
    height="auto"
    persistent
    :className="['new_workstation_subscription_modal']"
  >
    <template v-slot:body>
      <div class="modal_body">
        <v-icon @click="oncloseModal" icon="mdi-close"></v-icon>
        <h5 v-if="isProperty">UPGRADE TO PREMIUM</h5>
        <h5 v-else>Select Your Workstation Membership Plan</h5>
        <p v-if="isProperty">Unlock Your Property Superpower Today</p>
        <p v-else>
          Grow your business - Get quality jobs, manage your operations, and
          take advantage of subsidised up-skilling courses.
        </p>
        <div class="membership__list">
          <ul class="check-list" v-if="isProperty">
            <li class="check-list__item">
              List & Manage<strong>Unlimited Properties</strong>
            </li>
            <li class="check-list__item">
              Access Verified & Local <strong>Trade Contractors</strong>
            </li>
            <li class="check-list__item">
              Raise & Manage <strong>Any Type of Projects</strong>
            </li>
          </ul>
          <ul class="check-list" v-else>
            <li class="check-list__item">
              Access Subsidised <strong> Up-skilling</strong> Courses
            </li>
            <li class="check-list__item">
              Find & Estimate on <strong>New Jobs</strong>
            </li>
            <li class="check-list__item">
              Collaborate With <strong>Your Team</strong> or
              <strong>Other Trades</strong>
            </li>
          </ul>
        </div>
        <div class="free_trial_card" v-if="isTrialValid">
          <h6>Enjoy Free* membership until 1st July 2025</h6>
          <p>
            On 1st July 2025, your free membership will automatically transition
            to your selected plan, unless you opt to cancel.
          </p>
          <p>
            <strong>Please note:</strong> Payment card registration is required.
          </p>
        </div>
        <div class="plan-cards">
          <v-card
            class="plan-card"
            :class="{ selected: selectedPlan === PaymentPlanType.YEARLY }"
            @click="onClickPlanCard(PaymentPlanType.YEARLY)"
          >
            <div class="plan-card__tags">
              <div class="plan-card__tag-item">YEARLY</div>
              <div class="plan-card__tag-item-discount">
                Most Cost Effective
              </div>
            </div>
            <div v-if="isTrialValid" class="plan-card__price">
              <p><span>£0.00</span>/until 30th June 2025</p>
              <p>On 1st of July 2025 you'll be charged £95.88 + VAT</p>
            </div>
            <div v-else class="plan-card__price">
              <p><span>£7.99</span> per month/ £95.90 today</p>
            </div>
          </v-card>
          <v-card
            class="plan-card"
            @click="onClickPlanCard(PaymentPlanType.MONTHLY)"
            :class="{ selected: selectedPlan === PaymentPlanType.MONTHLY }"
          >
            <div class="plan-card__tags">
              <span class="plan-card__tag-item">MONTHLY</span>
            </div>

            <div v-if="isTrialValid" class="plan-card__price">
              <p><span>£0.00</span> / until 30th June 2025</p>
              <p>On 1st of July 2025 you'll be charged £9.99 + VAT</p>
            </div>
            <div v-else class="plan-card__price">
              <p><span>£9.99</span> per month</p>
            </div>
          </v-card>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="footer">
        <v-btn
          class="button button-purple-border"
          :disabled="isLoading"
          @click="oncloseModal"
          >Cancel</v-btn
        >
        <v-btn
          class="button button-purple"
          :loading="paymentRedirectLoading"
          :disabled="isLoading || !selectedPlan"
          @click="onClickRegisterPayment"
          >Register Payment Card</v-btn
        >
      </div>
      <div class="sub__footer">
        <span>Cancel Anytime</span> <span> | </span>
        <a @click="onClickTermsAndCondition" class="cursor-pointer"
          >Terms & Conditions</a
        >
      </div>
    </template>
  </commonDialog>
</template>

<script setup lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { computed, ref } from "vue";
import { cloneObject } from "@/core/utils/common";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import $axios from "@/core/utils/axios-api-config";
import {
  PaymentPlanType,
  BusinessDetailTypeEnum,
  WorkstationAccountTypeEnum,
  UserWorkstationVerificationType,
  UserVerificationStatusEnum,
  PropertyOwnerWorkstationType,
} from "@/core/enums/RolesEnum";

import {
  VUE_APP_STRIPE_PUBLISHABLE_KEY,
  VUE_APP_SILT_KYC_COMPANY_APP_ID,
} from "@/config";

const emits = defineEmits([
  "onClickPaymentPlan",
  "closeModal",
  "onClickTermsAndCondition",
]);

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  isProperty: {
    type: Boolean,
    default: false,
  },
});

const selectedPlan = ref(null);
const planType = ref(PaymentPlanType.YEARLY) as any;
const stripe = ref(null);
const paymentRedirectLoading = ref(false);
const sessionId = ref(null);
const redirectUrl = ref("");
const customer = ref("") as any;
const store = useStore();

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const isTrialValid = computed(() => {
  const TRIAL_END_DATE = new Date("2025-06-30");
  const currentDate = new Date();
  const timeDifference = TRIAL_END_DATE.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference > 0;
});

const oncloseModal = () => {
  if (!props.isLoading) emits("closeModal");
};
const onClickTermsAndCondition = () => {
  if (!props.isLoading) emits("onClickTermsAndCondition");
};
const onClickPlanCard = (plan: any) => {
  selectedPlan.value = plan;
};

const initializeStripePayment = async (
  isTrial: boolean,
  oneTimePayment: boolean,
  price: number
) => {
  stripe.value = initializeStripe();
  paymentRedirectLoading.value = true;
  if (stripe.value) {
    await onCheckout(isTrial, oneTimePayment, price);
  }
  paymentRedirectLoading.value = false;
};
const onCheckout = async (
  isTrial: boolean,
  oneTimePayment: boolean,
  price: number
) => {
  await createSession(isTrial, oneTimePayment, price);
  if (sessionId.value) {
    (stripe.value as any)
      .redirectToCheckout({
        sessionId: sessionId.value,
      })
      .then((result: any) => {
        if (result.error) {
          console.log();
        }
      });
  }
};
const createSession = async (
  isTrial = false,
  oneTimePayment = false,
  price: number
) => {
  const user = cloneObject(store.getters[`${USER_STORE}/user`]);
  const { id: userId, userStripeCustomer, email } = user;

  const productName = "tradesperson-profile";

  const entity = JSON.stringify({
    id: userId,
    name: "tradesperson-profile",
  });

  if (!userStripeCustomer?.length) {
    await $axios
      .post(`/payment/${userId}/customer`, {
        email,
        entity,
      })
      .then(async (customerResponse) => {
        await store.dispatch(`${USER_STORE}/saveUserStripePayment`, [
          customerResponse,
        ]);
        customer.value = customerResponse;
      })
      .catch((e) => {
        console.log();
      });
  } else {
    const [stripeCustomer] = userStripeCustomer;
    customer.value = stripeCustomer;
  }

  const host = `${window.location.protocol}//${window.location.host}`;
  redirectUrl.value = `${host}/dashboard`;

  const { customerId } = customer.value;
  const totalPrice = price;

  if (customerId) {
    await $axios
      .post(`/payment/${userId}/session`, {
        customerId,
        price: totalPrice,
        redirectUrl: redirectUrl.value,
        productName,
        metadata: JSON.stringify({
          userWorkstationId: activeUserWorkstation.value?.id,
          name: activeUserWorkstation.value?.name,
          companyName: activeUserWorkstation.value?.name,
          productName,
          userId,
          businessType: BusinessDetailTypeEnum.PROPERTY_OWNER,
          planType: planType.value,
          workstationAccountType: WorkstationAccountTypeEnum.HOMEOWNER,
          capacity: "100MB",
          totalMember: 1,
        }),
        isTrial,
        oneTimePayment,
      })
      .then(async (sessionResponse: any) => {
        if (sessionResponse) {
          sessionId.value = sessionResponse?.id;
          localStorage.setItem("isShowPOSubScriptionModal", "true");
        }
      })
      .catch((error) => {
        console.log();
      });
  }
};
const initializeStripe = () => {
  let stripe = null;

  if ("Stripe" in window) {
    const { Stripe } = window as any;

    // eslint-disable-next-line no-new
    stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
  }

  return stripe;
};

const onClickRegisterPayment = () => {
  if (!selectedPlan.value) return;
  // if (selectedPlan.value) emits("onClickPaymentPlan", selectedPlan.value);

  planType.value = selectedPlan.value;
  if (planType.value === PaymentPlanType.YEARLY) {
    initializeStripePayment(false, false, 95.9);
  } else if (planType.value === PaymentPlanType.MONTHLY) {
    initializeStripePayment(false, false, 9.99);
  }
};
</script>

<style lang="scss" scoped>
.new_workstation_subscription_modal {
  .modal_body {
    position: relative;
    h5 {
      @include fluidFont(20, 20, 24px);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.15px;
      margin-bottom: 8px;
    }
    p {
      @include fluidFont(12, 12, 15px);
      font-weight: 400;
      color: rgba($blueDark, 0.5);
      letter-spacing: 0.25px;
    }
    .v-icon {
      @include fluidFont(16, 16, 1);
      position: absolute;
      right: 16px;
      top: 0;
      cursor: pointer;
    }
    .membership__list {
      margin-top: 16px;
      .check-list {
        margin-bottom: 1rem;
        gap: 0.3rem 0;
        display: flex;
        flex-direction: column;
        list-style: none;
        @include respond(md) {
          margin-bottom: 1.5rem;
        }
        &__item {
          font-weight: 500;
          font-size: 14px;
          color: $blueDark;
          position: relative;
          padding-left: 32px;
          line-height: 1.5rem;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 100%;
            background-color: $skyBlue;
          }
          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            left: 7px;
            top: 8px;
            width: 10px;
            height: 6px;
            border-right: 0;
            border-top: 0;
            border-bottom: 2px solid white;
            border-left: 2px solid white;
            transform: rotate(-48deg);
          }
        }
      }
    }
    .free_trial_card {
      background-color: rgba($blueDark, 0.05);
      border: 2px solid rgba($blueDark, 0.1);
      padding: 16px;
      border-radius: 8px;
      h6 {
        @include fluidFont(16, 16, 20px);
        font-weight: 700;
        color: rgba(51, 102, 255, 1);
        letter-spacing: 0.35px;
        margin-bottom: 10px;
      }
      p {
        @include fluidFont(14, 14, 18px);
        font-weight: 400;
        color: rgba($blueDark, 1);
        letter-spacing: 0.35px;
        margin-bottom: 16px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .plan-cards {
      .plan-card {
        margin-top: 16px;
        padding: 24px 16px;
        border-radius: 16px;
        border: 1px solid rgba($blueDark, 0.2);
        box-shadow: none;
        .plan-card__tags {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .plan-card__tag-item {
            width: max-content;
            border: 1px solid rgba(51, 102, 255, 1);
            @include fluidFont(14, 14, 16px);
            font-weight: 700;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            border-radius: 6px;
            text-transform: capitalize;
            padding: 4px 8px;
          }
          .plan-card__tag-item-discount {
            width: max-content;
            border: 1px solid rgba(51, 102, 255, 1);
            @include fluidFont(14, 14, 16px);
            font-weight: 400;
            color: rgba(51, 102, 255, 1);
            letter-spacing: 0.15px;
            border-radius: 6px;
            text-transform: capitalize;
            padding: 4px 8px;
            background-color: rgba($white, 1);
          }
        }
        .plan-card__price {
          margin-top: 16px;

          p {
            @include fluidFont(14, 14, 16px);
            font-weight: 400;
            color: rgba($blueDark, 1);
            padding-block: 12px;
            border-bottom: 1px dashed rgba(134, 135, 165, 1);
            span {
              @include fluidFont(28, 28, 30px);
              font-weight: 700;
            }
            &:last-child {
              border: none;
              padding-bottom: 0;
            }
          }
        }
      }
      .selected {
        border: 2px solid rgba(51, 102, 255, 1);
        background-color: rgba(51, 102, 255, 0.1);
      }
    }
  }
  .footer {
    margin-top: 16px;
    padding: 24px 0;
    border-top: 1px dashed rgba(134, 135, 165, 1);
    border-bottom: 1px dashed rgba(134, 135, 165, 1);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    .button {
      text-transform: capitalize;
      letter-spacing: normal;
    }
  }
  .sub__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    gap: 2px;
    span {
      @include fluidFont(13, 13, 16px);
      font-weight: 400;
      color: rgba($blueDark, 0.54);
    }
    a {
      @include fluidFont(13, 13, 16px);
      font-weight: 400;
      color: rgba(51, 102, 255, 1);
      text-decoration: underline;
    }
  }
}
</style>
