<template>
  <div class="job_detail_layout">
    <JobDetailsComponent />
  </div>
</template>

<script setup>
import JobDetailsComponent from "../components/JobDetailsComponent.vue";
</script>
<script>
export default {};
</script>

<style lang="scss" scoped>
.job_detail_layout {
  text-align: left;
  display: flex;
  gap: 56px;
  @include respond(s1024) {
    flex-direction: column-reverse;
  }
}
</style>
