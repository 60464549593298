<template>
  <div class="view_job__content">
    <Button
      label="Back to Jobs"
      @click="onClickBackToJobs"
      variant="tertiary"
      class="button-transparent back__button"
      ><template #prefix
        ><v-icon
          icon="mdi-chevron-left"
          size="24"
          color="#0c0f4a"
        ></v-icon></template
    ></Button>
    <div class="view_job__form">
      <div class="view_job__item">
        <h5>NWG Leak</h5>
        <div class="job_header">
          <ResidentialChip
            v-if="
              jobDetail?.property?.propertyCategory ===
              PropertyCategoryEnum.RESIDENTIAL
            "
          />
          <CommercialChip
            v-if="
              jobDetail?.property?.propertyCategory ===
              PropertyCategoryEnum.COMMERCIAL
            "
          />
          <div class="project_id">
            <div class="left__block">
              <p>Project ID:</p>
            </div>
            <div class="right__block"><p>#DWS2G436366</p></div>
          </div>
        </div>
      </div>
      <div class="view_job__item">
        <h5>Project Notes</h5>
        <div
          class="view_notes__wrapper"
          v-if="jobDetail?.description"
          v-html="jobDetail?.description"
        ></div>
      </div>
      <div class="view_job__item view_required__wrapper">
        <h5>Required Services</h5>
        <div class="service_list" v-if="serviceGroupByCategory?.length">
          <div
            class="service_list_item"
            v-for="skill in serviceGroupByCategory"
            :key="skill.id"
          >
            <v-icon>
              <img
                v-if="skill?.iconName"
                :src="
                  require('@/assets/category-icons/' + skill?.iconName + '.svg')
                "
            /></v-icon>
            <div class="service_info">
              <h6>{{ skill?.tpLabel }}</h6>
              <p>{{ skill?.subCategoryMergeString }}</p>
            </div>
          </div>
        </div>
        <div v-else class="empty_service">
          <p>service Not available</p>
        </div>
      </div>
      <div class="view_job__item">
        <h5>Location</h5>
        <div class="map_wrapper">
          <NWGPropertyMapComponent
            v-if="jobDetail?.property"
            className="custom_map"
            :properties="[jobDetail.property]"
            :isSingleProperty="true"
          />
        </div>
      </div>
      <div class="view_job__item">
        <div class="view_job__images__upload">
          <label>Attachments</label>

          <div class="attachment_upload">
            <div v-if="jobDetail?.attachments?.length" class="uploaded__images">
              <div
                class="display__upload__images"
                v-for="(attachment, index) in jobDetail.attachments"
                :key="index"
              >
                <img
                  v-if="attachment?.imageURL"
                  :src="attachment?.imageURL"
                  alt=""
                />
              </div>
            </div>
            <div class="upload__imgs__footer">
              <p class="no-dox" v-if="!jobDetail.attachments?.length">
                No images or documents added
              </p>
              <p v-if="jobDetail.attachments?.length" class="no-dox">
                {{ jobDetail.attachments.length }} attachments added
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <JobOverViewSidePanel />
</template>

<script setup>
import Button from "@/core/components/ui/general/Button.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import ResidentialChip from "@/core/uni-components/Chips/ResidentialChip.vue";
import JobOverViewSidePanel from "./JobOverViewSidePanel.vue";
import NWGPropertyMapComponent from "@/core/components/map/NWGPropertyMapComponent.vue";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { NWG_JOBS_STORE } from "@/store/modules/nwg-job";
import { PropertyCategoryEnum } from "@/core/enums/PropertiesEnum";
import { NWG_JOB_ROUTE } from "../routes";

const props = defineProps({
  status: {
    type: String,
  },
});

const router = useRouter();
const store = useStore();

const serviceGroupByCategory = computed(
  () =>
    Object.values(
      jobDetail.value?.services?.reduce((acc, item) => {
        const category = item?.subCategory?.category;
        if (!category) return acc;
        if (!acc[category.id]) {
          acc[category.id] = {
            id: category.id,
            poLabel: category.poLabel,
            tpLabel: category.tpLabel,
            iconName: category.iconName,
            subCategory: [],
            subCategoryMergeString: "",
          };
        }
        acc[category.id].subCategory.push({
          id: item.subCategory.id,
          label: item.subCategory.label,
        });
        acc[category.id].subCategoryMergeString = acc[category.id].subCategory
          .map((sub) => sub.label)
          .join(", ");
        return acc;
      }, {})
    ) || []
);
const jobDetail = computed(
  () => store.getters[`${NWG_JOBS_STORE}/getJobDetails`]
);
const onClickBackToJobs = () => {
  router.push({ name: NWG_JOB_ROUTE });
};
</script>
<script>
export default {};
</script>
<style lang="scss" scoped>
.back__button {
  height: unset;
  min-height: auto;
  @include fluidFont(12, 12, 1.2);
  font-weight: 600;
  color: rgba($blueDark, 1);
}

.view_job__content {
  width: 100%;
  max-width: calc(100% - 406px);
  @include respond(s1024) {
    width: 100%;
    max-width: 100%;
  }
  .view_job__form {
    margin-top: 24px;
    text-align: left;
    width: 100%;
    max-width: 100%;
    .view_job__item {
      padding: 16px 0;
      border-bottom: 1px dashed rgba(134, 135, 165, 1);
      position: relative;
      &:first-child {
        padding-top: 0;
        margin-top: 24px;
      }
      &:last-child {
        border: none;
      }
      h5 {
        @include fluidFont(16, 16, 24px);
        font-weight: 700;
        color: rgba($blueDark, 1);
        margin-bottom: 10px;
      }
      p {
        @include fluidFont(12, 12, 15.06px);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.4px;
      }
      :deep(.view_notes__wrapper) {
        min-height: 50px;
        p {
          @include fluidFont(12, 12, 15.06px);
          font-weight: 400;
          color: rgba($blueDark, 0.5);
          letter-spacing: 0.4px;
        }
      }
    }
    .view_required__wrapper {
      h5 {
        padding: 16px 0;
        border-bottom: 1px dashed rgba(134, 135, 165, 1);
      }
      .service_list {
        margin-top: 24px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(3, 1fr);
        @include respond(xl) {
          grid-template-columns: repeat(2, 1fr);
        }
        @include respond(sm) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    .job_header {
      display: flex;
      gap: 16px;
      .project_id {
        display: flex;
        gap: 8px;
        align-items: center;
        .left__block {
          p {
            @include fluidFont(12, 12, 25px);
            font-weight: 600;
            color: rgba($blueDark, 0.5);
            letter-spacing: 0.25px;
          }
        }
        .right__block {
          p {
            @include fluidFont(12, 12, 25px);
            font-weight: 600;
            color: rgba($blueDark, 1);
            letter-spacing: 0.25px;
            text-align: right;
          }
        }
      }
    }
    .view_job__images__upload {
      margin-top: 16px;
      label {
        @include fluidFont(14, 14, 20px);
        font-weight: 500;
        color: rgba($blueDark, 1);
        margin-bottom: 6px;
        display: inline-block;
      }
      .attachment_upload {
        border: 1px solid rgba($bordercolor, 1);
        border-radius: 8px;
        padding: 16px;
        .uploaded__images {
          display: flex;
          gap: 8px;
          align-items: flex-start;
          flex-wrap: wrap;
          .display__upload__images {
            width: calc(20% - 7px);
            max-width: 100%;
            border-radius: 8px;
            height: 156px;
            overflow: hidden;
            @include respond(md) {
              width: calc(33.33% - 6px);
            }
            @include respond(s720) {
              width: calc(50% - 4px);
            }
            img {
              width: 100%;
              max-width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .upload__imgs__footer {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          @include respond(sm) {
            flex-direction: column;
            align-items: flex-start;
          }
          .add__images__button {
            height: unset;
            min-height: unset;
            padding: 0;
            margin: 0;
          }
          p {
            @include fluidFont(12, 12, 16px);
            font-weight: 600;
            color: rgba($blueDark, 0.5);
          }
        }
      }
      p {
        color: #f45454;
        @include fluidFont(14, 14, 20px);
        font-weight: 500;
      }
    }
    .service_list {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .service_list_item {
        display: flex;
        gap: 12px;
        align-items: center;
        background-color: rgba($white, 1);

        border-radius: 8px;
        padding: 4px 8px;
        box-shadow: 0px 0px 8px 0px #0c0f4a14;

        .v-icon {
          width: 32px;
          height: 32px;
          border-radius: 100%;
          overflow: hidden;
          background-color: rgba($blueDark, 1);
          img {
            filter: invert(99%) sepia(0%) saturate(3735%) hue-rotate(87deg)
              brightness(119%) contrast(100%);
            width: 15px;
          }
        }
        .service_info {
          display: flex;
          flex-direction: column;
          width: calc(100% - 32px);
          max-width: 100%;
          h6 {
            @include fluidFont(16, 16, 24px);
            font-weight: 700;
            color: rgba($blueDark, 1);
            letter-spacing: 0.15px;
          }
          p {
            @include fluidFont(12, 12, 16px);
            font-weight: 500;
            letter-spacing: 0.4px;
            color: rgba($blueDark, 0.5);
            max-width: 185px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
    .empty_service {
      p {
        @include fluidFont(12, 12, 16px);
        font-weight: 500;
        letter-spacing: 0.4px;
        color: rgba($blueDark, 0.5);
      }
    }
  }
  .map_wrapper {
    height: 580px;
    .custom_map {
      border-radius: 8px;
    }
  }
}
.attachment_upload:has(.uploaded__images) .upload__imgs__footer {
  margin-top: 10px;
}
</style>
