<template>
  <GeneralDialog @on-close="onCloseModal" headerTitle="Add New Insurance">
    <template #body>
      <div
        class="tw-w-full rmd:tw-w-[540px] tw-h-[570px] tw-flex tw-flex-col tw-gap-4 tw-overflow-x-hidden"
      >
        <div class="tw-flex tw-flex-col tw-gap-3">
          <Text variant="span" textWeight="400">Insurance Type</Text>
          <InputSelect
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="Select Insurance Type"
            v-model="form.insuranceType"
            item-title="text"
            item-value="value"
            :items="InsuranceTypes"
          />
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-3"
          v-if="form.insuranceType === 1"
        >
          <Text variant="span" textWeight="400">Property Insurance Type</Text>
          <InputSelect
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="Select Insurance Type"
            item-title="text"
            item-value="value"
            :items="PropertyInsuranceTypes"
            v-model="form.propertyInsuranceType"
          />
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-3"
          v-if="form.insuranceType === 3"
        >
          <Text variant="span" textWeight="400">Project Insurance Type</Text>
          <InputSelect
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="Select Insurance Type"
            v-model="form.propertyInsuranceType"
            item-title="text"
            item-value="value"
            :items="ProjectInsuranceTypes"
          />
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-3"
          v-if="form.insuranceType === 2"
        >
          <Text variant="span" textWeight="400">Warranty Insurance Type</Text>
          <InputSelect
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="Select Insurance Type"
            v-model="form.propertyInsuranceType"
            item-title="text"
            item-value="value"
            :items="WarrantyInsuranceTypes"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-3">
          <Text variant="span" textWeight="400">Insurance Premium Cover</Text>
          <InputField
            placeholder="£17,000"
            v-model="formattedCoverage"
            @blur="formatCoverage"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-3">
          <Text variant="span" textWeight="400">Issued Date</Text>
          <DatePicker v-model="form.issuedDate" :popover="true">
            <template #default="{ togglePopover, inputValue }">
              <div class="calendar__select" @click="() => togglePopover()">
                <v-text-field
                  :value="inputValue"
                  variant="outlined"
                  density="compact"
                  placeholder="Choose Date"
                  color="#0C0F4A"
                  ><img src="@/assets/icons/personal-calendar.svg" alt="" />
                </v-text-field>
              </div>
            </template>
          </DatePicker>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-3">
          <Text variant="span" textWeight="400">Expiry Date</Text>
          <DatePicker v-model="form.expiryDate" :popover="true">
            <template #default="{ togglePopover, inputValue }">
              <div class="calendar__select" @click="() => togglePopover()">
                <v-text-field
                  :value="inputValue"
                  variant="outlined"
                  density="compact"
                  placeholder="Choose Date"
                  color="#0C0F4A"
                  ><img src="@/assets/icons/personal-calendar.svg" alt="" />
                </v-text-field>
              </div>
            </template>
          </DatePicker>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-3">
          <Text variant="span" textWeight="400">Upload Document</Text>
          <div class="tw-w-full">
            <UploadForm
              class="!tw-w-full"
              listType="picture"
              :record="form"
              :allowMultiple="false"
              :attachmentSources="[]"
              :useCustomUploadIcon="false"
              :useDragTrigger="true"
              @on-progress-upload="onProgressUpload"
              @on-upload-success="updateFileOnSuccess"
              @on-handle-error="onHandleError"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-border-t-dashed-custom tw-pt-4 tw-w-full tw-flex tw-justify-end tw-items-center tw-gap-3"
      >
        <Button
          class="!tw-w-auto"
          variant="secondary"
          :disabled="state.loading"
          label="close"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          class="!tw-w-auto"
          variant="accent"
          :disabled="state.loading || !isFormValid"
          :isLoading="state.loading"
          label="save"
          :isActive="true"
          @click="onCreatePropertyInsurance"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import { DatePicker } from "v-calendar";
import UploadForm from "@/core/components/forms/Upload.vue";
import CurrencyFilter from "@/filters/currency.filter";
import { PROPERTY_STORE } from "@/store/modules/property";

const todayDate = new Date().toISOString().split("T")[0];

const emits = defineEmits(["on-close", "on-success"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
  dateInput: "",
});

const form = reactive({
  issuedDate: new Date(),
  expiryDate: new Date(),
  premiumCover: "",
  propertyInsuranceType: "",
  insuranceType: "",
  attachments: [],

  disableButton: false,
  isReadyToSubmit: false,
  formValue: {},
  isSubmitting: false,
  isUploading: false,
  isLoading: false,
});
const formattedCoverage = ref("");

const propertyId = computed(() => route?.params?.propertyId);

const isFormValid = computed(() => {
  if (!form.insuranceType) return false;

  if (form.insuranceType === 1 && !form.propertyInsuranceType) return false;
  if (form.insuranceType === 2 && !form.propertyInsuranceType) return false;
  if (form.insuranceType === 3 && !form.propertyInsuranceType) return false;

  if (
    !form.premiumCover ||
    !form.issuedDate ||
    !form.expiryDate ||
    form.attachments.length === 0
  ) {
    return false;
  }
  return true;
});

const InsuranceTypes = computed(() => {
  return [
    {
      text: "Property Insurance",
      value: 1,
    },
    {
      text: "Warranties",
      value: 2,
    },
    {
      text: "Project Insurance",
      value: 3,
    },
  ];
});

const PropertyInsuranceTypes = computed(() => {
  return [
    {
      text: "Building Instance",
      value: 1,
    },
    {
      text: "Contents Insurance",
      value: 2,
    },
  ];
});

const ProjectInsuranceTypes = computed(() => {
  return [
    {
      text: "Insurance Backed Guarantee",
      value: 1,
    },
    {
      text: "Building Works Insurance",
      value: 2,
    },
  ];
});

const WarrantyInsuranceTypes = computed(() => {
  return [
    {
      text: "Driveway Installation",
      value: 1,
    },
    {
      text: "Air Source Heat Pump Installation",
      value: 2,
    },
  ];
});

const onCloseModal = () => {
  emits("on-close");
};

const onProgressUpload = () => {
  form.isUploading = true;
  form.isLoading = true;
};

const updateFileOnSuccess = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const onHandleError = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const formatCoverage = () => {
  const numericValue = parseFloat(formattedCoverage.value.replace(/,/g, ""));
  form.premiumCover = numericValue;
  // const formattedValue = numericValue.toLocaleString("en-US", {
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // });
  // formattedCoverage.value = formattedValue;
  formattedCoverage.value = CurrencyFilter.formatToCurrency(numericValue);
};

const onCreatePropertyInsurance = async () => {
  if (!propertyId.value) {
    console.log("Property ID is not available");
    return;
  }

  const propertyIdParse = parseInt(propertyId.value, 10);
  let attachmentsModified = [];
  if (form.attachments && form.attachments.length) {
    attachmentsModified = form.attachments.map((attachment) => ({
      ...attachment,
      src: attachment.publicUrl,
    }));
  }

  try {
    state.loading = true;
    let formData = null;

    if (form.insuranceType === 1) {
      formData = {
        insuranceType: form.insuranceType.toString(),
        propertyInsuranceType: form.propertyInsuranceType.toString(),
        coverAmount: parseInt(form.premiumCover, 10),
        expiryDate: form.expiryDate,
        dateIssued: form.issuedDate,
        attachments: attachmentsModified,
      };
    } else if (form.insuranceType === 2) {
      formData = {
        insuranceType: form.insuranceType.toString(),
        warrantyInsuranceType: form.propertyInsuranceType.toString(),
        coverAmount: parseInt(form.premiumCover, 10),
        expiryDate: form.expiryDate,
        dateIssued: form.issuedDate,
        attachments: attachmentsModified,
      };
    } else if (form.insuranceType === 3) {
      formData = {
        insuranceType: form.insuranceType.toString(),
        projectInsuranceType: form.propertyInsuranceType.toString(),
        coverAmount: parseInt(form.premiumCover, 10),
        expiryDate: form.expiryDate,
        dateIssued: form.issuedDate,
        attachments: attachmentsModified,
      };
    } else {
      console.log("Invalid insurance type:", form.insuranceType);
    }

    if (formData) {
      const payload = {
        formData,
        propertyId: propertyIdParse,
      };

      const response = await store.dispatch(
        `${PROPERTY_STORE}/createPropertyInsurance`,
        payload
      );
    }
  } catch (error) {
    console.error("Error creating property insurance:", error);
  } finally {
    state.loading = false;
    emits("on-success");
  }
};
</script>
