<template>
  <div v-if="isLoading">
    <Loader :show="isLoading" class="tw-z-10" />
  </div>
  <div
    @click="onRouteToTradePassport"
    class="tw-flex tw-items-center tw-gap-[.1rem] tw-cursor-pointer tw-pb-4 tw-w-full"
  >
    <v-icon icon="mdi-chevron-left"></v-icon>
    <Text variant="p" textWeight="600">Back</Text>
  </div>
  <div
    v-if="!isLoading && tradePassportClientsAndBusinesses?.length"
    class="tw-max-w-[1300px] tw-w-full md:!tw-pb-[4rem] md:!tw-box-border"
  >
    <div
      class="tw-w-full tw-rounded-lg tw-bg-[#F8FBFD] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-3"
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-pb-3 tw-box-border tw-gap-4 tw-border-b-dashed-custom"
      >
        <div class="tw-flex tw-flex-col tw-gap-2 tw-justify-start">
          <Text
            variant="h6"
            class="rmd:!tw-text-[22px]"
            lineHeight="18px"
            textAlign="left"
            >{{
              isUserProprtyOwner
                ? "Clients & Businesses"
                : "Clients & Businesses I’ve worked with"
            }}</Text
          >
          <Text
            variant="p"
            textWeight="600"
            textColor="rgba(12, 15, 74, 0.8)"
            lineHeight="18px"
            textAlign="left"
            class="md:!tw-hidden"
            >{{ tradePassportClientsAndBusinesses?.length }} Results</Text
          >
        </div>
        <Button
          v-if="!isUserProprtyOwner"
          variant="secondary"
          label="+Add"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          @click="onOpenClientModal"
        />
      </div>
      <div class="clients-grid tw-w-full">
        <!-- client item -->
        <div
          v-for="(item, index) in tradePassportClientsAndBusinesses"
          :key="index"
          class="tw-group tw-cursor-pointer tw-min-w-[95px] tw-h-[95px] rmd:!tw-min-w-[120px] rmd:!tw-h-[120px] tw-rounded-[11.16px] tw-overflow-hidden tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-relative"
        >
          <!-- Overlay (Initially hidden, shows on hover) -->
          <div
            @click="onOpenDeleteModal(item?.id)"
            class="tw-absolute tw-w-full tw-h-full tw-bg-[rgba(0,0,0,0.54)] tw-flex tw-items-center tw-justify-center tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity tw-duration-300"
          >
            <GrayCircularTrashIcon />
          </div>

          <!-- Image -->
          <img
            :src="item?.imageUrl"
            class="tw-w-full tw-h-full tw-object-cover"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="!isLoading && !tradePassportClientsAndBusinesses?.length"
    class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto tw-w-full"
  >
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img md:!tw-w-[50%]">
        <img
          :src="require('../../../assets/images/trade_insurance_empty.png')"
          alt=""
          class="tw-w-full"
        />
      </div>

      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any Clients And Businesses yet!</h4>
        <p>
          You haven't added any Clients And Businesses details yet. Upload your
          Clients And Businesses to build trust and meet industry standards."
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any Clients And Businesses yet!</h4>
        <p>
          haven't added any Clients And Businesses details to this profile yet
        </p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          @click="onOpenClientModal"
          v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
          class="button button-purple"
          width="100%"
          >Add New Clients And Businesses</v-btn
        >
      </div>
    </div>
  </div>

  <!-- MODAL -->
  <AddTradePassportClientModal
    v-if="showClientModal"
    @on-close="onCloseClientModal"
    @on-success="initialize"
  />

  <ConfirmDeleteClientsBusinessModal
    v-if="showConfirmDeleteMoal"
    :id="selectedId"
    @on-close="onCloseDeleteModal"
    @on-success="onSuccessDelete"
  />
</template>
<script setup>
import { computed, onMounted, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { USER_STORE } from "@/store/modules/user";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { TRADE_PASSPOSRT_ROUTE } from "../routes";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import GrayCircularTrashIcon from "@/core/components/icons/GrayCircularTrashIcon.vue";
import AddTradePassportClientModal from "./modals/AddTradePassportClientModal.vue";
import Loader from "@/core/components/common/Loader.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import ConfirmDeleteClientsBusinessModal from "./modals/ConfirmDeleteClientsBusinessModal.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";

const store = useStore();
const router = useRouter();
const internalInstance = getCurrentInstance();

const showClientModal = ref(false);
const isLoading = ref(false);
const showConfirmDeleteMoal = ref(false);
const selectedId = ref(null);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const tradePassportClientsAndBusinesses = computed(() => {
  return store.getters[`${USER_STORE}/userWorkstationClientsBusinesses`];
});

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);
const contractorTradePassportWorkstationId = computed(
  () =>
    store.getters[
      `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
    ]
);
const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const getUserWorkStationFieldAccess = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
);

const onOpenClientModal = () => {
  showClientModal.value = true;
};

const onCloseClientModal = () => {
  showClientModal.value = false;
};

const onOpenDeleteModal = (id) => {
  selectedId.value = id;
  showConfirmDeleteMoal.value = true;
};

const onCloseDeleteModal = () => {
  showConfirmDeleteMoal.value = false;
};

const onSuccessDelete = async () => {
  selectedId.value = null;
  await initialize();
  onCloseDeleteModal();
  // displayToast here
  displayToastMessage(internalInstance, "Deleted Successfully", "success");
};

const onRouteToTradePassport = async () => {
  await router.push({ name: TRADE_PASSPOSRT_ROUTE });
};

const initialize = async () => {
  try {
    isLoading.value = true;
    if (user.value?.id) {
      await store.dispatch(
        `${USER_STORE}/getAllTradePassportclientsBusinesses`,
        user.value?.id
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

onBeforeMount(async () => {
  await initialize();
});
</script>
<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
.clients-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  }
}
</style>
