<template>
  <Card
    class="!tw-min-h-[unset] !tw-box-border !tw-bg-transparent !tw-shadow-none"
  >
    <template #content>
      <section class="tw-h-full tw-flex tw-flex-col tw-gap-4 !tw-box-border">
        <div
          class="tw-flex tw-justify-between tw-items-center tw-gap-4 md:tw-flex-col md:tw-items-start"
        >
          <InputField
            prepend-inner-icon="mdi-magnify"
            class="tw-max-w-[412px] md:tw-max-w-full md:tw-w-full"
            placeholder="Search"
            v-model="filters.keywords"
            @input="handleSearch"
          />
          <div
            class="tw-flex tw-gap-4 tw-items-center md:tw-w-full md:tw-justify-between"
          >
            <ChipState
              class="tw-cursor-pointer"
              label="Filters"
              textColor="#FFA500"
              ><template #prefix><FilterCircleYellowIcon /></template
            ></ChipState>
            <div class="sort__wrapper">
              <v-menu
                :persistent="true"
                :close-on-content-click="false"
                class="desktop_sort_menu"
              >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" variant="plain">
                    Sort By
                    <span>
                      <img :src="require('@/assets/icons/sort.svg')" alt="" />
                    </span>
                  </v-btn>
                </template>
                <v-radio-group>
                  <v-radio
                    label="Most Recent"
                    value="ASC"
                    color="indigo"
                  ></v-radio>
                  <v-radio label="Oldest" value="DESC" color="indigo"></v-radio>
                </v-radio-group>
              </v-menu>
            </div>
            <!-- <v-menu>
              <template v-slot:activator="{ props }">
                <Button
                  v-bind="props"
                  variant="text"
                  label="Sort by"
                  class="!tw-w-[auto]"
                  :ripple="false"
                >
                  <template #prefix>
                    <SortIcon />
                  </template>
                </Button>
              </template>
              <div>sort menu</div>
            </v-menu> -->
          </div>
        </div>
        <div
          class="tw-grid tw-grid-cols-3 tw-items-center tw-gap-4 md:tw-grid-cols-1"
        >
          <InputSelect
            prepend-inner-icon="mdi-shield-check"
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="All"
            :items="[
              'California',
              'Colorado',
              'Florida',
              'Georgia',
              'Texas',
              'Wyoming',
            ]"
          />
          <InputSelect
            prepend-inner-icon="mdi-shield-check"
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="All"
            :items="[
              'California',
              'Colorado',
              'Florida',
              'Georgia',
              'Texas',
              'Wyoming',
            ]"
          />
          <InputSelect
            prepend-inner-icon="mdi-account-hard-hat"
            class="!tw-w-full !tw-max-w-[unset]"
            placeholder="Commercial/Residential"
            :items="[
              'California',
              'Colorado',
              'Florida',
              'Georgia',
              'Texas',
              'Wyoming',
            ]"
          />
        </div>
      </section>
    </template>
  </Card>
</template>
<script setup>
import { reactive } from "vue";

import Button from "@/core/components/ui/general/Button.vue";
import Card from "@/core/components/ui/general/Card.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import SortIcon from "@/core/components/icons/SortIcon.vue";
import FilterCircleYellowIcon from "@/core/components/icons/FilterCircleYellowIcon.vue";
import { debounce } from "lodash";
const emit = defineEmits(["show-map"]);

const filters = reactive({
  keywords: "",
});

const handleSearch = debounce(() => {
  emit("search", filters.keywords);
}, 300);
</script>
<style lang="scss" scoped>
.sort__wrapper {
  .v-btn {
    padding: 0;
    height: auto;
    opacity: 1;
    :deep(.v-btn__content) {
      text-transform: capitalize;
      display: flex;
      gap: 10px;
      align-items: center;
      @include fluidFont(14, 14, 1.2);
      font-weight: 600;
      color: rgba($blueDark, 1);
      margin: 0 10px;
      // @include respond(s720) {
      //   margin-left: -50px;
      // }
      span {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: rgba($blueDark, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    :deep(.v-ripple__container) {
      display: none !important;
    }
  }
}
.v-menu {
  :deep(.v-overlay__content) {
    background-color: rgba($white, 1);
    min-width: 185px !important;
    border-radius: 16px;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    padding: 16px;
    // top: 520px !important;
    // left: auto !important;
    // right: 40px !important;
    @include respond(xlg) {
      // right: 10px !important;
    }

    &::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 20px;
      background-image: url(@/assets/icons/triangle.svg);
      right: 10px;
      top: -10px;
    }
    .v-input {
      .v-input__control {
        .v-selection-control-group {
          gap: 10px;
          .v-selection-control {
            gap: 8px;
            .v-selection-control__wrapper {
              width: auto;
              height: auto;
              .v-selection-control__input {
                width: auto;
                height: auto;
              }
            }
            .v-label {
              @include fluidFont(14, 14, 1.3);
              font-weight: 600;
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// @media (max-width: 767px) {
//   .v-menu :deep(.v-overlay__content) {
//     top: 370px !important;
//     left: 30px !important;
//     right: auto !important;
//   }
// }
</style>
