<template>
  <div
    class="tw-border-lightGrey tw-bg-white tw-rounded-lg tw-border-solid tw-border tw-p-4 tw-flex tw-gap-4 tw-flex-col tw-justify-start"
  >
    <div class="task-title tw-flex tw-items-center tw-justify-between gap-3">
      <div class="tw-text-sm tw-font-bold tw-text-darkBlue tw-leading-5">
        Item {{ itemIndex + 1 }}
        <v-icon icon="mdi-information" color="#0C0F4A8a" size="xs" />
      </div>
    </div>
    <div class="tw-flex tw-items-center tw-gap-4 tw-justify-between">
      <div class="tw-max-w-full tw-w-full">
        <TextField
          :class="{ 'is-error': !!subTaskErrors.name }"
          label="Material Description"
          v-model="materialTaskForm.name"
          placeholder="Material Description"
          @change="updateMaterialPhaseTask"
          :disabled="isDisableField"
        />
      </div>
      <div
        v-if="
          !isDisableField &&
          estimateForm.projectStages[phaseIndex].projectStageTasks.length > 1
        "
        class="tw-flex tw-items-center gap-2"
      >
        <span
          @click="toggleShowDeleteModal"
          class="tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-border-lightGrey tw-rounded-full tw-w-8 tw-h-8"
          ><TrashIcon
        /></span>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-4 tw-gap-4 estimate_schedule">
      <div class="tw-col-span-1">
        <TextField
          :class="{ 'is-error': !!subTaskErrors.materialUnit }"
          :disabled="isDisableField"
          class="input__field"
          v-model.number="materialTaskForm.materialUnit"
          @input="handleMaterialUnit"
          placeholder="Unit"
          label="Unit"
        />
      </div>
      <div class="tw-col-span-1">
        <TextField
          :class="{ 'is-error': !!subTaskErrors.taskCost }"
          class="input__field"
          :disabled="isDisableField"
          @input="handleSubTotalTask($event)"
          @keypress="numChecker($event, materialTaskForm.taskCost)"
          v-model="materialTaskForm.taskCost"
          placeholder="£ 0.00"
          label="Unit Price (£)"
        />
      </div>

      <div class="tw-col-span-1">
        <TextField
          disabled
          label="Total item cost"
          :value="formattedCurrency(totalItemCost)"
        />
      </div>
    </div>
  </div>
  <SelectMaterialAssignPhaseModal
    v-if="isShowAssignPhaseModal"
    :assignPhases="assignPhases"
    :selectedAssignPhase="assignPhaseMaterial"
    @on-close="toggleAssignPhaseModal"
    @on-save="onSaveAssignPhase"
  />

  <ConfirmPhaseTaskDeleteModal
    v-if="isShowDeleteModal"
    @on-close="toggleShowDeleteModal"
    @on-confirm="onDeleteMaterialItem"
  />
</template>
<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import { isNumber } from "@/core/helpers/number.helper";
import CurrencyFilter from "@/filters/currency.filter";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import SelectMaterialAssignPhaseModal from "@/modules/jobs/components/modal/SelectMaterialAssignPhaseModal.vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import ConfirmPhaseTaskDeleteModal from "@/modules/jobs/components/modal/ConfirmPhaseTaskDeleteModal.vue";
import { displayToastMessage } from "@/core/utils/common";
import { getCurrentInstance } from "@vue/runtime-core";
import TextField from "@/core/components/common/TextField.vue";

const props = defineProps(["phaseIndex", "itemIndex", "item"]);
const emits = defineEmits(["on-update-material-task", "on-click-task-note"]);
const durationOptions = [
  {
    value: 1,
    label: "Days",
  },
  {
    value: 2,
    label: "Weeks",
  },
];
const MAX_LIMIT = 99999999;
const internalInstance = getCurrentInstance();
const store = useStore();
const isShowAssignPhaseModal = ref(false);
const assignPhaseMaterial = ref([]);
const isShowDeleteModal = ref(false);

const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const estimateMaterialForm = computed(
  () => estimateForm.value.projectStages[props.phaseIndex]
);

const materialTaskForm = computed(
  () => estimateMaterialForm.value.projectStageTasks[props.itemIndex]
);

const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);

const isLockTemplateModifiedChecker = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/lockTemplateModifiedChecker`]
);

const isEstimateSubmit = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isEstimateSubmit`]
);

const isDisableField = computed(
  () =>
    isFormReadOnly.value ||
    isEstimateSubmit.value ||
    isLockTemplateModifiedChecker.value
);

const validCost = computed(() => materialTaskForm.value.taskCost > MAX_LIMIT);

const toggleShowDeleteModal = () => {
  if (isEstimateSubmit.value || isLockTemplateModifiedChecker.value) return;
  isShowDeleteModal.value = !isShowDeleteModal.value;
};
const numChecker = (event, value) => {
  return isNumber(event, value);
};

const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const onDeleteMaterialItem = async () => {
  if (!isFormReadOnly.value) {
    const estimatePhaseTask = estimateMaterialForm.value.projectStageTasks.find(
      (data, idx) => idx === props.itemIndex
    );
    const taskSubTotal = estimatePhaseTask.taskCost;
    estimateMaterialForm.value.projectStageTasks =
      estimateForm.value.projectStages[
        props.phaseIndex
      ].projectStageTasks.filter((x, xi) => xi !== props.itemIndex);
    try {
      await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationPhaseTask`, {
        estimatePhaseTaskId: estimatePhaseTask.id,
      });

      if (!estimateMaterialForm.value.projectStageTasks.length) {
        estimateMaterialForm.value.subTotal = 0;
      } else {
        estimateMaterialForm.value.subTotal =
          estimateMaterialForm.value.subTotal - taskSubTotal;
      }
    } catch (error) {
      console.log();
    } finally {
      toggleShowDeleteModal();
    }
  }
};
const handleSubTotalTask = (event) => {
  const value = event.target.value;
  if (!value) {
    materialTaskForm.value.taskCost = 0;
  } else {
    if (!validCost.value) updateMaterialPhaseTask();
  }
};
const handleMaterialUnit = (event) => {
  const value = event?.target?.value;
  if (!value) {
    materialTaskForm.value.materialUnit = 0;
  } else {
    updateMaterialPhaseTask();
  }
};
const totalItemCost = computed(() => {
  const totalCostPerUnit =
    materialTaskForm.value.taskCost * materialTaskForm.value.materialUnit;

  materialTaskForm.value.total = totalCostPerUnit;

  let currentTotal = estimateMaterialForm.value.projectStageTasks.reduce(
    (sum, task, index) => {
      return sum + (parseFloat(task.total) || 0);
    },
    0
  );
  store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimatePhaseSubTotal`, {
    phaseIndex: props.phaseIndex,
    newValue: currentTotal,
  });

  return totalCostPerUnit;
});
const updateMaterialPhaseTask = () => {
  const { name, materialUnit, taskCost } = materialTaskForm.value;
  if (taskCost) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.itemIndex,

      field: "taskCost",
    });
  }
  if (materialUnit) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.itemIndex,
      field: "materialUnit",
    });
  }
  if (name) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.itemIndex,
      field: "name",
    });
  }
  emits("on-update-material-task");
};

const subTaskErrors = computed(() => {
  const errors =
    store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
  if (props.itemIndex !== undefined) {
    return errors[props.phaseIndex]?.subErrors?.[props.itemIndex] || {};
  }
  return {};
});

const onSaveAssignPhase = async (payload, phase) => {
  try {
    const isAlready = assignPhaseMaterial.value?.id === phase?.id;

    if (!isAlready) {
      assignPhaseMaterial.value = phase;

      await tradeEstimateService.assignPhaseToTaskInEstimate(
        materialTaskForm.value.id,
        payload
      );
    }
  } catch (error) {
    console.log("error", error);
    displayToastMessage(
      internalInstance,
      "same phase cannot assigned",
      "error"
    );
  } finally {
    isShowAssignPhaseModal.value = false;
  }
};

const toggleAssignPhaseModal = () => {
  if (!(isEstimateSubmit.value || isLockTemplateModifiedChecker.value)) {
    isShowAssignPhaseModal.value = !isShowAssignPhaseModal.value;
  }
};
const assignPhases = computed(() => {
  return estimateForm.value.projectStages.filter(
    (phase) => phase.stageType === "labour"
  );
});
const onClickTaskNote = (value) => {
  emits("on-click-task-note", value, true);
};
onMounted(() => {
  if (estimateForm.value) {
    const isExistPhase = materialTaskForm.value.assignedPhaseTo;

    if (isExistPhase) {
      assignPhaseMaterial.value = isExistPhase;
    } else assignPhaseMaterial.value = null;
  }
});
</script>
<style lang="scss" scoped>
.el-form-item {
  margin: 0;
}
:deep(.el-input__wrapper) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
}
.is-error input,
.is-error select {
  border-color: red;
}
.button-custom {
  width: auto !important;
  color: rgba($blueDark, 1);
  @include fluidFont(12, 12, 1.2);
  background-color: transparent;
}
.material__name {
  position: relative;
  :deep(.el-popper) {
    width: 100% !important;
    max-width: 100%;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 0px #0c0f4a29;

    .attachment-list__menu {
      width: 100%;
      max-width: 100%;
      .attachment-list__menu-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .v-icon {
          margin-left: auto;
        }
      }
      .attachment-list__menu-inner {
        .attachment-list__menu-group-input {
          padding: 10px;
          background-color: rgba($backgroundGrey, 1);
          border-radius: 4px;
          text-align: left;
          p {
            @include fluidFont(12, 12, 1.3);
            font-weight: 600;
            color: rgba($blueDark, 1);
            margin-bottom: 7px;
          }
          .attachment-list__menu-group-input-wrapper {
            border: 1px solid rgba($buttonText, 0.4);
            border-radius: 6px;
            padding: 4px 4px 4px 6px;
            display: flex;
            align-items: center;
            background-color: #fff;

            .el-input__inner {
              height: 20px;
            }

            .button {
              min-height: 22px;
              height: auto;
              @include fluidFont(8, 8, 1.2);
              font-weight: 700;
              border: 1px solid;
              padding: 6px 12px;
              border-radius: 6px;
            }
          }
        }

        .attachment-list__menu-group-list {
          text-align: left;
          border: 1px solid #80829f66;
          border-radius: 4px;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          .attachment-list__menu-group-list-item {
            padding: 6px 12px;
            display: flex;
            gap: 8px;
            align-items: center;
            &:nth-child(even) {
              background-color: rgba($blueDark, 0.04);
            }
            p {
              @include fluidFont(12, 12, 1.3);
              font-weight: 500;
              color: rgba($blueDark, 1);
            }
          }
        }
      }
    }
  }
}
</style>
