<template>
  <GeneralDialog
    @on-close="onCloseModal"
    headerTitle="Energy Efficiency Rating"
    class="energy_efficency__modal"
  >
    <template #body>
      <div
        class="content tw-w-[540px] tw-h-[570px] tw-flex tw-flex-col tw-gap-4 tw-overflow-x-hidden"
      >
        <div class="tw-flex tw-flex-col tw-gap-3 form_wrapper">
          <Text variant="span" textWeight="400">Correct Value</Text>
          <v-text-field
            class="input_field"
            placeholder="75  |  C"
            v-model="correctValue"
            variant="outlined"
          ></v-text-field>
        </div>

        <div
          class="tw-flex tw-flex-col tw-gap-3 tw-p-4 tw-box-border tw-bg-[#F1F6FB] tw-rounded-lg"
        >
          <div
            class="tw-flex tw-items-center tw-gap-3 tw-p-[10px] tw-box-border tw-bg-white tw-rounded-lg"
          >
            <InfoIconOutline />
            <Text variant="span" textWeight="400"
              >Please add some text or upload a file to continue.</Text
            >
          </div>
          <div class="tw-flex tw-flex-col tw-gap-3">
            <Text variant="span" textWeight="400">Proof Of Document</Text>
            <!-- Document upload here -->
            <div class="uploade-wrapper">
              <v-file-input
                :class="{ 'added-img': imageUrl }"
                class="upload_file"
                variant="solo-filled"
                v-model="groupAvatar"
                accept="image/png, image/jpeg, image/jpg"
                label="Drag and drop documents, or Browse"
                @change="onFileChange"
                @click:clear="clearImageUrl"
                multiple
              >
                <v-icon
                  icon="mdi mdi-file-image-box"
                  class="label-img"
                ></v-icon>
              </v-file-input>
              <v-img
                class="uploaded-img"
                :width="200"
                aspect-ratio="16/9"
                cover
                :src="imageUrl"
              ></v-img>
            </div>
            <div
              class="document-item-wrapper"
              v-if="proofOfDocuments.length > 0"
            >
              <div
                v-for="item in proofOfDocuments"
                :key="item.id"
                class="document-item"
              >
                <img
                  :src="`https://storage.googleapis.com/idgc-b10/${item?.attachment}`"
                  :alt="item?.originalName"
                  class="document-image"
                />
                <v-icon class="remove-icon" @click="removeDocument(item.id)">
                  mdi-close
                </v-icon>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-3">
            <Text variant="span" textWeight="400">Notes</Text>
            <v-textarea
              row-height="25"
              rows="4"
              v-model="noteData"
              variant="outlined"
              auto-grow
              shaped
            ></v-textarea>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-border-t-dashed-custom tw-pt-5 tw-w-full tw-flex tw-justify-end tw-items-center tw-gap-3"
      >
        <Button
          class="!tw-w-auto"
          variant="secondary"
          :disabled="state.loading"
          :isLoading="state.loading"
          label="close"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          class="!tw-w-auto"
          :disabled="isSaveDisable"
          :isLoading="state.loading"
          label="save"
          variant="accent"
          :isActive="true"
          @click="onSaveEnergyRatingDetailsModal"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InfoIconOutline from "@/core/components/icons/InfoIconOutline.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { USER_STORE } from "@/store/modules/user";

const emits = defineEmits(["on-close", "on-success"]);

const props = defineProps({
  epcDataListByCategoryId: {
    type: Object,
    required: true,
  },
});

const epcDataListByCategoryId = ref();
const store = useStore();
const route = useRoute();

const removedDocuments = ref(new Set());

const imageUrl = ref("");
let imageData = ref("");
const noteData = ref("");
const correctValue = ref("");
const proofOfDocuments = ref([]);

const isSaveDisable = ref(false);

const state = reactive({
  loading: false,
});

const onFileChange = async (event) => {
  const fileInput = event.target;
  if (fileInput.files && fileInput.files.length > 0) {
    const file = fileInput.files[0];

    imageUrl.value = URL.createObjectURL(file);
    imageData.value = file;
    const formData = new FormData();
    formData.append("file", imageData.value);
    try {
      isSaveDisable.value = true;
      const uploadImg = await store.dispatch(
        `${USER_STORE}/uploadProfileImage`,
        formData
      );
      imageData.value = uploadImg;
    } catch (error) {
      console.log(error, "error in fetching property details");
    } finally {
      isSaveDisable.value = false;
    }
  } else {
    console.error("Error: No file selected.");
  }
};
const clearImageUrl = () => {
  imageUrl.value = "";
};

const onCloseModal = () => {
  emits("on-close");
};

const removeDocument = (id) => {
  removedDocuments.value.add(id);
  proofOfDocuments.value = proofOfDocuments.value.filter(
    (doc) => doc.id !== id
  );
};

const onSaveEnergyRatingDetailsModal = async () => {
  const updatedData = {
    propertyEpcDataId: epcDataListByCategoryId.value.id,
  };

  if (correctValue.value) {
    updatedData.value = correctValue.value;
  }

  if (noteData.value) {
    updatedData.noteData = { note: noteData.value };
  }

  let existingAttachments =
    epcDataListByCategoryId.value.propertyEpcProofOfDocuments
      .filter((doc) => !removedDocuments.value.has(doc.id))
      .map(({ dateCreated, dateUpdated, ...doc }) => doc);

  if (imageData.value) {
    existingAttachments.push({
      id: imageData.value.id,
      attachment: imageData.value.attachment,
      originalName: imageData.value.originalName,
      size: imageData.value.size,
      mime: imageData.value.mime,
    });
  }

  if (existingAttachments.length > 0) {
    updatedData.attachments = existingAttachments;
  }

  try {
    const response = await store.dispatch(
      `${PROPERTY_STORE}/updateEfficiencyRating`,
      updatedData
    );
  } catch (error) {
    console.log(error, "error in fetching property details");
  } finally {
    emits("on-close");
  }
};

onMounted(async () => {
  if (props.epcDataListByCategoryId) {
    try {
      epcDataListByCategoryId.value = await store.dispatch(
        `${PROPERTY_STORE}/fetchEPCDataListByCategoryId`,
        props.epcDataListByCategoryId.id
      );
      correctValue.value = epcDataListByCategoryId.value.value || "";
      noteData.value =
        epcDataListByCategoryId.value.propertyEpcNotes[0]?.note || "";
      proofOfDocuments.value =
        epcDataListByCategoryId.value.propertyEpcProofOfDocuments || [];
    } catch (error) {
      console.log(error, "error in fetching property details");
    }
  }
});
</script>
<style scoped lang="scss">
.document-item {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.tw-w-\[540px\] {
  @media (max-width: 767px) {
    width: 100% !important;
  }
}

:deep(.uploade-wrapper) {
  width: 100%;
  height: 78px;
  margin: 0;
  background-color: #fff;

  @media (max-width: 767px) {
    height: 100px;
  }

  .upload_file {
    background-color: transparent;
    border: 1px dashed #00000066;
    border-radius: 6px;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: 25px !important;
      font-weight: 400 !important;
      color: $blueDark !important;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 12px;
      z-index: -1;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
        bottom: 16px !important;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 18px;
        background: url("@/assets/icons/file-upload-icon.svg") no-repeat center;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }
}

.document-item {
  width: 100%;
  aspect-ratio: 1/1;

  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .mdi-close {
    font-size: 16px;
    background-color: #faa500;
    border-radius: 100%;
    top: 1px;
    right: 1px;
    color: #fff;
  }

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 15px;

    @media (max-width: 767px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
</style>
