<template>
  <TradePassportCardSlot
    class="trade-passport-about"
    title="About"
    buttonLabel="+Add About"
    :hideFooter="true"
    :emptyStateLabel="
      isUserProprtyOwner
        ? 'Description will appear here'
        : 'Your Description will appear here'
    "
    :isEmpty="!props.userDescription"
    :isCustomActionHeader="props.userDescription"
    @on-handle-click="onHandleClick"
  >
    <template #custom-action-header>
      <BlueCircularPencilIcon
        v-if="!isUserProprtyOwner"
        @click="onHandleClick"
      />
    </template>
    <template #content>
      <div class="tw-w-full">
        <Text
          class="text-about"
          variant="p"
          textColor="rgba(12, 15, 74, 0.8)"
          textAlign="left"
          v-html="props.userDescription"
        >
        </Text>
      </div>
    </template>
  </TradePassportCardSlot>
</template>
<script setup>
import { onMounted, computed } from "vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { USER_STORE } from "@/store/modules/user";
import TradePassportCardSlot from "../slots/TradePassportCardSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import { useStore } from "vuex";
import { UserRolesEnum } from "@/core/enums/RolesEnum";

const props = defineProps(["userDescription"]);
const emits = defineEmits(["on-handle-click"]);
const store = useStore();

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);
const onHandleClick = () => {
  emits("on-handle-click");
};
onMounted(() => {
  console.log(props.userDescription, "user description");
});
</script>
<style lang="scss" scoped>
.text-about {
  p {
    color: rgba(12, 15, 74, 0.8);
  }
}
</style>
