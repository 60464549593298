<template>
  <Card class="!tw-min-h-[unset] shadow-none">
    <template #content>
      <main
        class="estimate-form tw-flex tw-flex-col tw-justify-between tw-h-full tw-w-full"
      >
        <!-- Labour From -->
        <HistoricalLabourForm
          :phaseIndex="phaseIndex"
          v-if="isLabour"
          :isDIYProjectType="isDIYProjectType"
        />

        <HistoricalMaterialForm
          :phaseIndex="phaseIndex"
          v-else
          @on-update-phase-Estimation="updatePhaseEstimation"
          :isDIYProjectType="isDIYProjectType"
        />

        <section
          class="estimate-form__wrapper tw-bg-[rgba(12,15,74,0.1)] tw-w-full tw-h-[36px] tw-flex tw-items-center tw-justify-center tw-gap-[2rem] tw-py-2 tw-box-border tw-min-h-[36px]"
        >
          <div class="tw-flex tw-gap-2">
            <ClipIcon class="cursor-pointer" @click="toggleAttachmentModal" />
          </div>

          <v-divider vertical="true" v-if="!hideDeleteAction"></v-divider>
          <TrashIcon
            v-if="!hideDeleteAction"
            class="cursor-pointer"
            @click="onDeletePhase"
          />

          <EstimatePhaseAttachments
            v-if="isShowAttachmentModal"
            :phaseIndex="phaseIndex"
            :estimatePhaseId="
              estimateForm?.projectStages[props?.phaseIndex]?.id
            "
            :projectEstimateId="formData?.id"
            @on-close="toggleAttachmentModal"
          />
        </section>
      </main>
    </template>
  </Card>
</template>
<script setup>
import { computed, reactive, onMounted, ref } from "vue";
import { useStore } from "vuex";

import Card from "@/core/components/ui/general/Card.vue";
import HistoricalLabourForm from "@/modules/jobs/components/estimates/form/HistoricalLabourForm.vue";
import HistoricalMaterialForm from "@/modules/jobs/components/estimates/form/HistoricalMaterialForm.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import ClipIcon from "@/core/components/icons/ClipIcon.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import EstimatePhaseAttachments from "../modal/EstimatePhaseAttachments.vue";
const store = useStore();

const emits = defineEmits(["on-delete-phase", "on-update-Estimation"]);

const props = defineProps({
  phaseBgColor: {
    type: String,
    default: "rgba(74, 146, 229, 0.16)",
  },
  phaseTitleColor: {
    type: String,
    default: "#4A92E5",
  },
  phaseIndex: Number,
  formProp: Object,
  isPhaseAssignedToMe: {
    type: Boolean,
  },
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },
});

const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const isLabour = computed(() => {
  return props.formProp?.stageType === "labour";
});

const onDeletePhase = async () => {
  try {
    const estimate = estimateForm.value.projectStages.find(
      (data, index) => index === props.phaseIndex
    );
    estimateForm.value.projectStages = estimateForm.value.projectStages.filter(
      (x, i) => i !== props.phaseIndex
    );
    await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationPhase`, {
      estimateId: estimate.id,
    });
  } catch (error) {
    console.log();
  }
};

const updatePhaseEstimation = () => {
  emits("on-update-Estimation", props.phaseIndex);
};

const isShowAttachmentModal = ref(false);
const toggleAttachmentModal = () => {
  if (!formData.value.id) {
    emits("on-update-Estimation");
  }
  isShowAttachmentModal.value = !isShowAttachmentModal.value;
};
const hideDeleteAction = computed(
  () => estimateForm.value.projectStages.length === 1
);
</script>
<script>
export default {
  name: "HistoricalEstimateFormCard",
};
</script>
<style lang="scss" scoped>
.estimate-form {
  &__wrapper.estimate-header {
    background-color: v-bind("props.phaseBgColor");
  }
  &__phase-item {
    border-bottom: 2px solid #5db9e7;
  }
}
.error-message {
  @include fluidFont(12, 12, 1.2);
  color: rgba($blueDark, 1);
  font-weight: 500;
}
</style>
