<template>
  <v-select
    :="$attrs"
    class="custom-select"
    density="compact"
    variant="outlined"
    :no-data-text="props.no_data_text || 'No data available'"
    :menu-props="{ contentClass: 'custom__text__selector' }"
  ></v-select>
</template>
<script lang="ts" setup>
import { defineOptions } from "vue";
const props = defineProps({ no_data_text: { required: false, type: String } });
defineOptions({
  inheritAttrs: false,
});
</script>
<style lang="scss" scoped>
.custom-select {
  @apply tw-shadow-none tw-ring-0 tw-text-[12px] tw-font-[400] tw-text-[#0C0F4A] tw-w-[400px];

  :deep(.v-field) {
    @apply tw-shadow-none  tw-text-center tw-bg-white tw-rounded-lg tw-text-[14px];
  }

  :deep(.v-field__input) {
    @apply tw-text-[14px] tw-pt-3;
  }

  :deep(.v-select__content) {
    top: 0 !important;
  }
}
</style>

<!-- This is for the select overlay -->
<style>
.v-overlay__content.v-select__content {
  @apply tw-bg-white tw-p-0;
}
</style>
