<template>
  <div>
    <CommonDialog
      :isShowHeader="false"
      :isShowBackButton="false"
      width="655px"
      max-width="100%"
      :className="['unlock_platform__modal']"
    >
      <template v-slot:body>
        <div class="modal__content__wrapper">
          <div class="modal_close" @click="onClickCloseButton">
            <v-icon icon="mdi-close"></v-icon>
          </div>
          <div class="platfotm_image">
            <img src="../../../assets/images/unlock-platform.png" alt="" />
          </div>
          <div class="modal_content">
            <h5>Unlock the Full Platform for FREE*</h5>
            <p>
              Unlock all areas of iknowa platform, FREE* until 1st July 2025. On
              1st July 2025, your free membership will automatically roll onto
              one of the plans below that you select, unless you opt to cancel.
            </p>
            <p>
              <strong> Please note:</strong> Payment card registration is
              required.
            </p>
          </div>
          <div class="modal__footer">
            <v-btn
              class="button button-purple-border"
              @click="onClickCloseButton"
              >Cancel</v-btn
            >
            <v-btn class="button button-purple" @click="onClickContinueButton"
              >Unlock For Free Now
            </v-btn>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>

<script setup lang="ts">
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useStore } from "vuex";
const store = useStore();

const emit = defineEmits(["close", "onClickUnlockFreeAccess"]);

const onClickCloseButton = (event: any) => {
  emit("close");
};
const onClickContinueButton = () => {
  emit("onClickUnlockFreeAccess");
};
</script>

<style lang="scss" scoped>
.modal__content__wrapper {
  position: relative;
  .modal_close {
    text-align: right;
  }
  .platfotm_image {
    width: 280px;
    height: 280px;
    max-width: 100%;
    margin: 0 auto;
  }
  .modal_content {
    text-align: center;
    max-width: 515px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 36px;
    h5 {
      @include fluidFont(34, 34, 42px);
      font-weight: 600;
      color: rgba($blueDark, 1);
    }
    p {
      margin-top: 36px;
      @include fluidFont(16, 16, 22px);
      font-weight: 500;
      color: rgba($blueDark, 1);
      letter-spacing: 0.25px;
      &:last-child {
        margin-top: 16px;
      }
    }
  }
  .modal__footer {
    padding-top: 24px;
    border-top: 1px dashed rgba(134, 135, 165, 1);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    .button {
      text-transform: capitalize;
    }
  }
}
</style>
