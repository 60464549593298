<template>
  <WizardModal
    v-if="!isShowKYBInfoModal && !isShowIDCheckModal"
    :hideFooter="true"
    :hideBackButton="true"
    :singleData="getModalData"
    :hideTitleAndDescription="true"
    @on-close="onClickCloseTab"
    width="968px"
    height="1000px"
  >
    <template #content>
      <div
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between tw-gap-4 tw-py-8 tw-box-border llg:!tw-py-2"
      >
        <div
          class="tw-w-full tw-flex tw-items-start tw-gap-3"
          v-for="(step, index) in steps"
          :key="index"
        >
          <div
            class="tw-flex tw-items-center tw-flex-col tw-justify-start tw-gap-2 tw-mr-2"
          >
            <Text variant="h6" whiteSpace="nowrap" class="llg:!tw-text-[.8rem]"
              >Step {{ step.step }}</Text
            >
            <div
              v-if="index !== steps.length - 1"
              class="tw-min-w-[2px] tw-w-[2px] tw-h-[112px] tw-bg-[rgba(12,15,74,.20)]"
            ></div>
          </div>
          <div
            class="tw-w-full tw-flex tw-items-start tw-gap-3 llg:!tw-flex-col"
          >
            <div
              class="tw-min-w-[146px] tw-w-[146px] tw-h-[130px] tw-overflow-hidden llg:!tw-min-w-[100px] llg:!tw-w-[100px] llg:!tw-h-[70px]"
            >
              <img
                :src="require(`@/assets/images/kyc-steps/${step.image}.png`)"
                class="tw-w-full tw-h-full tw-object-contain"
              />
            </div>
            <div class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-2">
              <Text variant="h3" textWeight="400" class="llg:!tw-text-[20px]">{{
                step.title
              }}</Text>
              <Text
                variant="h6"
                textWeight="400"
                class="llg:!tw-text-[12px] llg:!tw-leading-[18px]"
                >{{ step.description }}</Text
              >
            </div>
          </div>
        </div>

        <v-btn
          class="button button-purple"
          v-if="typeOfVerification === 'KYC'"
          :isLoading="isLoading"
          @click="onClickKycTypeIdVerification"
          >Start ID verification</v-btn
        >

        <v-btn
          class="button button-purple"
          v-if="typeOfVerification === 'KYB'"
          :isLoading="isLoading"
          @click="onClickIdVerification"
          >Start ID verification</v-btn
        >
      </div>
    </template>
  </WizardModal>
  <KYCStatusModal
    v-if="isShowKYBInfoModal"
    :onContinue="onClickCloseTab"
    kycStatus="KYBINFO"
  />
  <CommonDialog
    v-if="isShowIDCheckModal"
    :className="['property_owner_id_Check_modal']"
    :onClickBackButton="onClickCloseTab"
    HeaderTittle="KYB Verification"
    title="We need to verify your identity"
    width="650px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="description">
          <p>We will require your passport or driver’s license.</p>
          <p>
            Verified ID is required to ensure trust security and compliance on
            Iknowa.
          </p>
        </div>
        <div class="content">
          <div class="your-img-wrap">
            <img
              class="open-logo"
              :src="require('@/assets/icons/id-check.svg')"
            />
          </div>

          <!-- <v-btn
            :loading="isLoading"
            v-if="selectedType === 'Individual' || isKYCUnverified"
            class="btn_link !tw-w-full"
            @click="onClickKycTypeIdVerification"
            >Start ID verification
          </v-btn> -->

          <a
            :loading="isLoading"
            class="btn_link"
            @click.prevent="onClickIdVerification"
            >Start ID verification</a
          >
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script lang="ts">
import WizardModal from "@/core/components/modals/WizardModal.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { computed, ref, onMounted } from "vue";
import {
  VUE_APP_SILT_KYB_API_TOKEN,
  VUE_APP_SILT_KYB_COMPANY_APP_ID,
} from "@/config/index";

import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import $axios from "@/core/utils/axios-api-config";
import KYCStatusModal from "@/core/components/KYCStatusModal.vue";
import { VUE_APP_SILT_KYC_COMPANY_APP_ID } from "@/config";
import Text from "@/core/components/ui/general/Text.vue";

export default {
  components: { KYCStatusModal, WizardModal, Text, CommonDialog },
  props: {
    onClickCloseTab: {
      type: Function,
    },
    isShowKyb: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const isShowIDCheckModal = ref(false);
    const kycData = {
      tab: "ID Check",
      name: "idCheck",
      header: "ID Verification (KYC)",
      title: "We need to verify your identity",
      description:
        "We will require your passport or driver’s license.Verified ID is required to ensure trust security and compliance on iknowa",
    };
    const kybData = {
      tab: "ID Check",
      name: "idCheck",
      header: "ID Verification (KYB)",
      title: "We need to verify your identity",
      description:
        "We will require your passport or driver’s license.Verified ID is required to ensure trust security and compliance on iknowa",
    };

    const steps = [
      {
        step: 1,
        title: "Scan the QR Code",
        description:
          "Use your device to scan the QR code displayed on the screen.",
        image: "kyc-step-1-img",
      },
      {
        step: 2,
        title: "Choose a Sign-in Method",
        description:
          "Select one of the available options: Google, Apple ID, or Continue as a Guest.",
        image: "kyc-step-2-img",
      },
      {
        step: 3,
        title: "Select Your Country",
        description: "Choose the country you are from.",
        image: "kyc-step-3-img",
      },
      {
        step: 4,
        title: "Scan Your ID",
        description:
          "Use your device’s camera to scan the selected ID document.",
        image: "kyc-step-4-img",
      },
    ];

    const getModalData = computed(() =>
      typeOfVerification.value === "KYB" ? kybData : kycData
    );
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isShowKYBInfoModal = ref(false);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const typeOfVerification = computed(() =>
      user.value?.userBusinessDetails?.type ===
      BusinessDetailTypeEnum.LIMITED_COMPANY
        ? "KYB"
        : "KYC"
    );
    const siltRedirectUrl = computed(() => {
      const host = `${window.location.protocol}//${window.location.host}`;
      const redirectUrl = `${host}/dashboard`;
      return `https://signup.getsilt.com/?company_app_id=${VUE_APP_SILT_KYC_COMPANY_APP_ID}&hide_redirect_button=true&customer_user_id=${user.value?.id}-${activeUserWorkstation.value?.id}&redirect_url=${redirectUrl}&meta={"isUserVerification": true}`;
    });

    const isLoading = ref(false);
    const onClickIdVerification = async () => {
      try {
        isLoading.value = true;
        const response: any = await store.dispatch(
          `${WORKSTATION}/sendUserKYBProcessMail`,
          {
            email: user.value.email,
          }
        );
        response["custom_user_workstation_id"] =
          activeUserWorkstation.value?.id;
        response["custom_user_id"] = user.value.id;
        await store.dispatch(
          `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
          response
        );
        await $axios.post(`/silt/verification/kyb`, response);

        isShowKYBInfoModal.value = true;
      } catch (error: any) {
        if (error?.response?.status === 400 && error?.response?.data?.message) {
          isShowKYBInfoModal.value = true;
        }
      } finally {
        isLoading.value = false;
      }
    };
    const closeKYBModal = () => {
      isShowKYBInfoModal.value = false;
    };

    const onClickKycTypeIdVerification = async () => {
      try {
        isLoading.value = true;
        const currentDomain = window.location.origin;
        const payload = {
          callback: `${currentDomain}/dashboard`,
          vendor_data: `${user.value.id}-${activeUserWorkstation.value.id}`,
        };

        const response: any = await store.dispatch(
          `${WORKSTATION}/sendUserKYCProcessMail`,
          payload
        );
        const redirectUrl = response?.url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
          isLoading.value = false;
        } else {
          isLoading.value = false;
        }
      } catch (err) {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      if (props.isShowKyb) {
        isShowIDCheckModal.value = true;
      } else {
        isShowIDCheckModal.value = false;
      }
    });
    return {
      getModalData,
      siltRedirectUrl,
      typeOfVerification,
      onClickIdVerification,
      isShowKYBInfoModal,
      closeKYBModal,
      isLoading,
      onClickKycTypeIdVerification,
      steps,
      isShowIDCheckModal,
    };
  },
};
</script>

<style scoped>
.sole-trader-data-wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* align-items: center; */
}
.your-img-wrap {
  height: 250px;
  width: 250px;
  margin: 0 auto 16px;
  @include respond(sm) {
    width: auto;
    height: 250px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.btn_link {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.7;
  display: flex;
  width: auto;
  box-shadow: none;
  border-radius: 0.5rem;
  letter-spacing: 0;
  min-height: 42px;
  text-decoration: none;
  border: 1px solid #4f55f0;
  align-items: center;
  justify-content: center;
  color: #4f55f0;
  margin-top: 16px;
  cursor: pointer;
}

.btn_link:hover {
  background-color: #4f55f0;
  color: #fff;
}
.back_btn {
  width: auto;
  justify-content: flex-start;
  color: #fff;
  max-width: fit-content;
  font-weight: 700;
  opacity: 1;
}
</style>
