<template>
  <div
    class="tw-border-lightGrey tw-bg-white tw-rounded-lg tw-border-solid tw-border tw-p-4 tw-flex tw-gap-4 tw-flex-col tw-justify-start"
  >
    <div class="task-title tw-flex tw-items-center tw-justify-between gap-3">
      <div class="tw-text-sm tw-font-bold tw-text-darkBlue tw-leading-5">
        Task {{ taskIndex + 1 }}
        <v-icon icon="mdi-information" color="#0C0F4A8a" size="xs" />
      </div>
      <div v-if="!isDisableField" class="tw-flex tw-items-center gap-2">
        <span
          @click="toggleShowDeleteModal"
          class="tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-border-lightGrey tw-rounded-full tw-w-6 tw-h-6"
          ><TrashIcon
        /></span>
        <v-icon icon="mdi-chevron-up" />
      </div>
    </div>
    <div class="">
      <TextField
        label="Task Description"
        v-model="labourTaskForm.name"
        :disabled="isDisableField"
        :error="!!subTaskErrors.name"
        @change="updateLabourPhaseTask"
        placeholder="Type task description here..."
      />
    </div>
    <div
      class="tw-grid tw-grid-cols-3 tw-gap-4 estimate_schedule tw-border-0 tw-border-b tw-border-dashed tw-border-[#8687A5] tw-pb-4"
    >
      <div class="tw-col-span-1">
        <TextField
          label="Duration"
          v-model="labourTaskForm.duration"
          :disabled="isDisableField"
          :error="!!subTaskErrors.duration"
          @input="handleTotalDuration($event)"
          suffix="days"
          type="number"
          placeholder="0 Days"
        />
      </div>

      <div class="tw-col-span-1">
        <TextField
          label="Task Total"
          v-model="labourTaskForm.taskCost"
          :disabled="isDisableField"
          :error="!!subTaskErrors.taskCost"
          @keypress="numChecker($event, labourTaskForm.taskCost)"
          @input="handleSubTotalTask($event)"
          prefix="£"
          placeholder="£ 0.00"
        />
      </div>
    </div>
    <!-- <div class="tw-flex tw-items-center tw-justify-between">
      <div
        class="estimate_chip tw-flex tw-items-center tw-justify-start tw-gap-3"
      >
        <div
          class="tw-bg-darkBlue tw-min-w-6 tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-rounded-full"
        >
          <NotePenIcon />
        </div>
        <a
          @click="
            onClickTaskNote(
              estimateForm.projectStages[phaseIndex].projectStageTasks[
                taskIndex
              ]
            )
          "
          class="tw-underline tw-text-darkBlue tw-font-bold tw-text-base tw-leading-6 tw-text-left"
          >Task Notes</a
        >
      </div>
    </div> -->
  </div>
  <ConfirmPhaseTaskDeleteModal
    v-if="isShowDeleteModal"
    @on-close="toggleShowDeleteModal"
    @on-confirm="onDeleteTask"
  />
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import NotePenIcon from "@/core/components/icons/NotePenIcon.vue";

import { isNumber } from "@/core/helpers/number.helper";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import estimate from "@/core/constants/estimate";
import moment from "moment";
import { ElMessage } from "element-plus";
import ConfirmPhaseTaskDeleteModal from "@/modules/jobs/components/modal/ConfirmPhaseTaskDeleteModal.vue";
import TextField from "@/core/components/common/TextField.vue";

const store = useStore();
const MAX_LIMIT = 99999999;
const emits = defineEmits(["on-update-labour-task", "on-click-task-note"]);
const durationOptions = [
  {
    value: 1,
    label: "Days",
  },
  {
    value: 2,
    label: "Weeks",
  },
];

const props = defineProps(["phaseIndex", "taskIndex", "task"]);
const isShowDeleteModal = ref(false);
const toggleShowDeleteModal = () => {
  if (isEstimateSubmit.value || isLockTemplateModifiedChecker.value) return;
  isShowDeleteModal.value = !isShowDeleteModal.value;
};
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const estimateLabourPhaseForm = computed(
  () => estimateForm.value.projectStages[props.phaseIndex]
);
const labourTaskForm = computed(
  () => estimateLabourPhaseForm.value.projectStageTasks[props.taskIndex]
);

const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);
const isLockTemplateModifiedChecker = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/lockTemplateModifiedChecker`]
);
const isEstimateSubmit = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isEstimateSubmit`]
);

const numChecker = (event, value) => {
  return isNumber(event, value);
};

const onDeleteTask = async () => {
  if (!isFormReadOnly.value) {
    try {
      if (labourTaskForm.value?.id) {
        await store.dispatch(
          `${JOB_TEMPLATE_STORE}/removeEstimationPhaseTask`,
          {
            estimatePhaseTaskId: labourTaskForm.value.id,
          }
        );

        estimateLabourPhaseForm.value.projectStageTasks =
          estimateLabourPhaseForm.value.projectStageTasks.filter(
            (x, xi) => xi !== props.taskIndex
          );
      }

      if (!estimateLabourPhaseForm.value.projectStageTasks.length) {
        estimateLabourPhaseForm.value.subTotal = 0;
      } else {
        estimateLabourPhaseForm.value.subTotal =
          parseFloat(estimateLabourPhaseForm.value.subTotal) -
          parseFloat(labourTaskForm.value.taskCost);
      }
    } catch (error) {
      console.log();
    } finally {
      toggleShowDeleteModal();
    }
  }
};

const calculateEndDate = (durationType, totalDuration, startDate) => {
  let duration = parseInt(totalDuration);
  if (duration < 1 || !startDate) return;

  const endDate = moment(new Date(startDate))
    .add(totalDuration, durationType === 1 ? "days" : "weeks")
    .format("YYYY-MM-DD");

  labourTaskForm.value.endDate = endDate;
};
const updateLabourPhaseTask = async () => {
  labourTaskForm.value.startDate = estimateLabourPhaseForm.value.startDate;

  const { name, duration, endDate, startDate, taskCost, durationType } =
    labourTaskForm.value;
  if (taskCost) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.taskIndex,
      field: "taskCost",
    });
  }
  if (duration) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.taskIndex,
      field: "duration",
    });
  }
  if (name) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.taskIndex,
      field: "name",
    });
  }

  calculateEndDate(durationType, duration, startDate);
  if (!durationCalculate.value) emits("on-update-labour-task");
};

const handleSubTotalTask = async (event) => {
  const value = event.target.value;
  if (!value) {
    labourTaskForm.value.taskCost = 0;
  }

  let currentTotal = estimateLabourPhaseForm.value.projectStageTasks.reduce(
    (sum, task, index) => {
      return sum + (parseFloat(task.taskCost) || 0);
    },
    0
  );

  store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimatePhaseSubTotal`, {
    phaseIndex: props.phaseIndex,
    newValue: currentTotal,
  });

  let errorStatus = true;
  if (!validCost.value) {
    updateLabourPhaseTask();
    errorStatus = false;
  } else {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/setFormError`, {
      field: "validationError",
      value: errorStatus,
    });
  }
};

const handleTotalDuration = async (event) => {
  const value = event.target.value;
  const totalDuration = estimateLabourPhaseForm.value.totalDuration;
  if (!value) {
    labourTaskForm.value.duration = "0";
  }

  if (+totalDuration < +value) {
    ElMessage({
      message:
        "Your task durations are too long. Please keep the total within the specified limit.",
      type: "error",
    });
  } else {
    updateLabourPhaseTask();
  }
};

const validCost = computed(() => labourTaskForm.value.taskCost > MAX_LIMIT);

const durationCalculate = computed(() => {
  let mainTaskDays = 0;
  let inputDuration = labourTaskForm.value.duration;
  if (estimateLabourPhaseForm.value.durationType === estimate.WEEKS) {
    mainTaskDays = estimateLabourPhaseForm.value.totalDuration * 7;
  } else {
    mainTaskDays = estimateLabourPhaseForm.value.totalDuration;
  }
  if (parseInt(inputDuration) > mainTaskDays) {
    return true;
  } else {
    return false;
  }
});

const subTaskErrors = computed(() => {
  const errors =
    store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
  if (props.taskIndex !== undefined) {
    return errors[props.phaseIndex]?.subErrors?.[props.taskIndex] || {};
  }
  return {};
});

const onClickTaskNote = (value) => {
  emits("on-click-task-note", value, true);
};

const isDisableField = computed(
  () => isEstimateSubmit.value || isLockTemplateModifiedChecker.value
);
</script>
<style lang="scss" scoped>
.el-form-item {
  margin: 0;
}
input {
  outline: none;
}
.errorInput {
  border: 1px solid red;
}
:deep(.el-input__wrapper) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
}
:deep(.v-btn:hover) > .v-btn__overlay {
  opacity: 0;
}
:deep(.el-select) {
  .el-select__selection {
    display: none;
  }

  .is-error input,
  .is-error select {
    border-color: red;
  }
}
.trash__icon {
  font-size: 12px;
}
</style>
