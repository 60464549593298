<template>
  <div class="dashboard">
    <NWGDashboard />
  </div>
</template>

<script lang="ts">
import NWGDashboard from "@/modules/dashboard/components/NWGDashboard.vue";
export default {
  components: {
    NWGDashboard,
  },
};
</script>
