<template>
  <div ref="mapRef" class="tw-w-full tw-h-full" :class="className"></div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";

import createGoogleMapsLoader from "@/core/utils/google-map-loader";
import { parseJSON } from "@/core/utils/common";

const emits = defineEmits(["onClickMarker"]);
const props = defineProps({
  properties: {
    type: Array,
    default: () => [],
  },
  className: {
    type: String,
  },
  selectedPropertyId: {
    type: Number,
    default: null,
  },
  isSingleProperty: { type: Boolean, default: false },
});
const markers = ref([]);
const selectPropertyId = ref(0);
const mapRef = ref(null);
const mapInstance = ref(null);
const propertyLoader = createGoogleMapsLoader();
const createMarkerIcon = (index, isActive = false) => {
  const fillColor = isActive ? "#C5D2F2" : "#1FB27A";
  const color = isActive ? "#4B4BFF" : "white";
  if (props.isSingleProperty) {
    return `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1.33008" y="1" width="22" height="22" rx="11" fill="white" stroke="#4F55F0" stroke-width="2"/>
<path d="M9.44336 10.3792C9.29984 10.5238 9.03122 10.4154 8.88414 10.3061C8.57957 10.2158 8.34955 10.4751 8.38731 10.7626C8.42001 11.0345 8.61688 11.0174 8.37481 11.2613L7.78426 11.8562C7.60313 12.0387 7.40801 11.9403 7.2271 11.7589L6.57224 11.102C6.39148 10.9206 6.22786 10.791 6.40872 10.6085L7.09674 9.91441C7.28931 9.72014 7.39984 9.92977 7.60338 9.91188C7.83238 9.91022 8.05886 9.62451 7.99324 9.40601C7.97261 9.37529 7.9555 9.34444 7.94294 9.31344L7.94179 9.31064C7.89885 9.20234 7.9765 9.02689 8.08049 8.92198C8.08049 8.92198 8.96645 8.05019 9.01052 8.00572C10.1607 6.84545 11.3965 7.23649 11.8353 7.38836C11.8352 7.56447 11.8681 7.61934 11.3964 7.56663C10.925 7.51394 9.49203 8.27284 10.1818 8.96262C10.2251 9.00596 10.5696 9.2413 10.0825 9.73199L9.44336 10.3792ZM13.0284 14.2804C13.3579 13.9227 13.6752 13.5534 14.0062 13.1988C14.8784 14.0438 15.867 14.9952 17.0391 16.1499C17.2636 16.371 16.8875 16.8682 16.7267 17.0284L16.7229 17.0322L16.719 17.0361C16.5581 17.1964 16.1273 17.5699 15.8713 17.3141L13.0284 14.2804ZM10.3893 9.73012C10.3893 9.73012 11.0336 10.3292 12.2741 11.5169C11.951 11.8145 11.6 12.125 11.2823 12.4035L9.55657 10.5577L10.3893 9.73012ZM18.2982 9.19888C18.5244 10.423 17.4719 11.4017 17.4719 11.4017C16.8449 12.0249 16.1843 12.095 15.4177 11.8065C14.9782 11.6402 14.5594 12.1052 14.5594 12.1052C14.5594 12.1052 10.5616 16.4996 10.2433 16.8159C9.92493 17.1324 9.70083 17.1703 9.43265 17.1693C9.16186 17.1661 8.88608 17.0222 8.68537 16.8227L8.68044 16.8178L8.67565 16.813C8.47509 16.6137 8.3277 16.337 8.32686 16.0703C8.32587 15.8037 8.40068 15.5351 8.67769 15.2598C8.95469 14.9844 13.4172 10.97 13.4172 10.97C13.4172 10.97 13.863 10.522 13.7128 10.1264C13.4177 9.34524 13.6771 8.66315 14.3017 8.04239C14.977 7.37114 15.5649 7.22078 16.3434 7.24164L16.5392 7.43623L15.4505 8.5375C15.3024 8.68477 15.1715 9.32331 15.4618 9.61186L15.8562 10.0039C16.2182 10.3636 16.8939 10.1956 17.0421 10.0483L18.1098 8.99727L18.3054 9.19171L18.2982 9.19888Z" fill="#4F55F0"/>
</svg>
`;
  } else {
    return `
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 36.5C28.665 36.5 36.5 28.665 36.5 19C36.5 9.33502 28.665 1.5 19 1.5C9.33502 1.5 1.5 9.33502 1.5 19C1.5 28.665 9.33502 36.5 19 36.5Z" fill="${fillColor}" stroke="${color}" stroke-width="3"/>
      <text x="20" y="25" font-size="16" text-anchor="middle" fill="${color}" font-weight="bold" font-family="Mulish, sans-serif">${
      index + 1
    }</text>
        </svg>
        `;
  }
};
const initializeMap = async () => {
  if (!window.google || !window.google.maps) {
    console.error("Google Maps API is not loaded");
    return;
  }

  const bounds = new google.maps.LatLngBounds();

  // Create the map
  mapInstance.value = await new google.maps.Map(mapRef.value, {
    center: { lat: 55.3781, lng: 3.436 },
    streetViewControl: false,
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  });

  // Add markers
  props.properties.forEach((property, index) => {
    if (property.addressData) {
      const addressData = parseJSON(property.addressData);
      const position = new google.maps.LatLng(addressData.lat, addressData.lng);

      const marker = new google.maps.Marker({
        position,
        map: mapInstance.value,
        icon: {
          url: `data:image/svg+xml;utf-8,${encodeURIComponent(
            createMarkerIcon(index, props.selectedPropertyId === property.id)
          )}`,
          scaledSize: new google.maps.Size(32, 32),
        },
        customInfo: {
          lat: addressData.lat || addressData.LAT,
          lng: addressData.lng || addressData.LNG,
          propertyId: property.id,
          index: index,
        },
      });
      marker.addListener("click", () => {
        highlightMarker(property.id);
        emits("onClickMarker", property.id);
      });
      markers.value.push(marker);
      bounds.extend(position);
    }
  });

  if (props.properties.length) {
    mapInstance.value.fitBounds(bounds, {
      top: 50,
      bottom: 50,
      left: 50,
      right: 50,
    });
    google.maps.event.addListenerOnce(
      mapInstance.value,
      "bounds_changed",
      () => {
        if (mapInstance.value.getZoom() > 15) mapInstance.value.setZoom(15);
      }
    );
  }
};

const highlightMarker = (propertyId) => {
  try {
    if (!propertyId) return;

    markers.value.forEach((m, i) => {
      m.setIcon({
        url: `data:image/svg+xml;utf-8,${encodeURIComponent(
          createMarkerIcon(i, false)
        )}`,
        scaledSize: new google.maps.Size(32, 32),
      });
    });

    const selectedMarker = markers.value.filter(
      (marker) => marker.customInfo.propertyId === propertyId
    );
    if (selectedMarker?.length) {
      selectedMarker.forEach((marker) => {
        marker.setIcon({
          url: `data:image/svg+xml;utf-8,${encodeURIComponent(
            createMarkerIcon(marker.customInfo.index, true)
          )}`,
          scaledSize: new google.maps.Size(32, 32),
        });
      });
      const position = selectedMarker[0].getPosition();
      if (position) {
        mapInstance.value.setCenter(position);
        mapInstance.value.setZoom(25);
      }
    }
    selectPropertyId.value = propertyId;
  } catch (error) {
    console.log("error :>> ", error);
  }
};

watch(
  () => props.selectedPropertyId,
  (newValue) => {
    if (newValue !== selectPropertyId.value) {
      highlightMarker(newValue);
    }
  }
);

onMounted(async () => {
  try {
    await propertyLoader.load();
    initializeMap();
  } catch (error) {
    console.error(
      "Failed to load Google Maps API or fetch user location",
      error
    );
  }
});
onUnmounted(() => {
  if (mapInstance.value) {
    google.maps.event.clearInstanceListeners(mapInstance.value);
    mapInstance.value = null;
  }
  markers.value = [];
});
</script>
