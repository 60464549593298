<template>
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.41671 4.16405L6.41671 11.2174C6.41671 12.6107 5.39671 13.8507 4.01004 13.984C2.42337 14.1374 1.08337 12.8907 1.08337 11.3307L1.08337 3.09071C1.08337 2.21738 1.71004 1.42405 2.57671 1.33738C3.57671 1.23738 4.41671 2.01738 4.41671 2.99738V9.99738C4.41671 10.364 4.11671 10.664 3.75004 10.664C3.38337 10.664 3.08337 10.364 3.08337 9.99738L3.08337 4.16405C3.08337 3.89071 2.85671 3.66405 2.58337 3.66405C2.31004 3.66405 2.08337 3.89071 2.08337 4.16405L2.08337 9.90405C2.08337 10.7774 2.71004 11.5707 3.57671 11.6574C4.57671 11.7574 5.41671 10.9774 5.41671 9.99738V3.11071C5.41671 1.71738 4.39671 0.477381 3.01004 0.344048C1.41671 0.190714 0.083374 1.43738 0.083374 2.99738L0.083374 11.1774C0.083374 13.0907 1.48337 14.804 3.39004 14.984C5.58337 15.184 7.41671 13.4774 7.41671 11.3307L7.41671 4.16405C7.41671 3.89071 7.19004 3.66405 6.91671 3.66405C6.64337 3.66405 6.41671 3.89071 6.41671 4.16405Z"
      fill="#fff"
    />
  </svg>
</template>
<script>
export default {
  name: "LoadIcon",
};
</script>
