<template>
  <GeneralDialog
    :headerTitle="`Rename ${isTypeFolder ? 'Folder' : 'File'}`"
    @on-close="onCloseModal"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <v-form
          @submit.prevent="onSave"
          :rules="validation"
          ref="folderNameFormRef"
          class="tw-w-full"
        >
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <Text variant="span" textWeight="400"
              >Rename {{ isTypeFolder ? "Folder" : "File" }}</Text
            >
            <v-text-field
              density="compact"
              :rules="validation.folderName"
              v-model="form.folderName"
              variant="outlined"
              class="tw-rounded-[8px]"
            ></v-text-field>
            <Text
              variant="span"
              textWeight="400"
              textColor="rgba(12, 15, 74, 0.5)"
              >You have {{ getRemainingCharacters }} characters remaining</Text
            >
          </div>
        </v-form>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          :disabled="state.loading"
          :isLoading="state.loading"
          label="Confirm"
          variant="accent"
          :isActive="isFormReady"
          @click="onSave"
        />
        <Button
          label="Cancel"
          variant="secondary"
          :disabled="state.loading"
          @click="onCloseModal"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import documentConstants from "@/modules/documents/contants";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";
import { PROPERTY_STORE } from "@/store/modules/property";

const emits = defineEmits(["on-close", "on-success"]);
const props = defineProps([
  "renameType",
  "selectedDocument",
  "isPropertyDocument",
]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const form = reactive({
  folderName: "",
});

const folderNameFormRef = ref(null);
const validation = ref({
  folderName: [
    (v) => !!v || "Folder name is required",
    (v) =>
      (!!v && v.length <= 50) || "Folder name must be less than 50 characters",
  ],
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const openedFolder = computed(
  () => store.getters[`${DOCUMENTS_STORE}/openedFolder`]
);
const fileList = computed(() => store.getters[`${DOCUMENTS_STORE}/fileList`]);
const folderList = computed(
  () => store.getters[`${DOCUMENTS_STORE}/folderList`]
);

const isTypeFolder = computed(
  () => props.renameType === documentConstants.FOLDERS
);

const getRemainingCharacters = computed(() => {
  const limit = 50;
  const remaining = limit - form.folderName.length;
  return remaining >= 0 ? remaining : 0;
});

const isFormReady = computed(() => {
  return form.folderName.length ? true : false;
});

const setFolderList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFolderList`, params);
};
const setFileList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFileList`, params);
};
const setDocumentList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setDocumentList`, params);
};

const getUserDocumentCalculatedSize = async (params) => {
  if (props.isPropertyDocument) {
    params.workStationId = propertyDetails.value?.userWorkstation?.id;
  }
  return await store.dispatch(
    `${DOCUMENTS_STORE}/getUserDocumentCalculatedSize`,
    params
  );
};

const getFolderList = async (options = {}) => {
  const { id: userId } = user.value;
  const params = { userId };
  const { parentId } = options;
  const hasParentParam = parentId || null;
  let folders = folderList.value;
  let files = fileList.value;
  const documentPromises = [];

  if (Object.keys(options).length) {
    params.options = options;
  }

  if (props.isPropertyDocument) {
    params.formData.workStationId = propertyDetails.value?.userWorkstation?.id;
    params.userId = propertyDetails.value?.propertyCreator?.id;
  }
  try {
    const userDocuments = await store.dispatch(
      `${DOCUMENTS_STORE}/getUserDocuments`,
      params
    );
    if (isTypeFolder.value) {
      folders = userDocuments.filter(
        (userDocument) =>
          userDocument.isDir === true &&
          userDocument.parentId === hasParentParam
      );

      folders.forEach((folderItem) => {
        documentPromises.push(
          getUserDocumentCalculatedSize({
            userId,
            userDocumentId: folderItem.id,
          })
        );
      });

      // Wait for all promises to resolve
      await Promise.all(documentPromises);

      if (documentPromises.length) {
        const documentFolderSizes = await Promise.all(documentPromises);
        folders = folders.map((item) => {
          const findFolder = documentFolderSizes.find((x) => x.id === item.id);

          return { ...item, ...findFolder };
        });
      }
    } else {
      files = userDocuments.filter(
        (userDocument) =>
          !userDocument.isDir && userDocument.parentId === hasParentParam
      );
      files = files.map((file) => {
        return { ...file, name: file.originalName, attachment: file.name };
      });
    }
  } catch (error) {
    const formError = "Error getting document list. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    setFolderList(folders);
    setFileList(files);
    setDocumentList([...folders, ...files]);
  }
};
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const onSave = async () => {
  state.loading = true;
  const isFormValid = await folderNameFormRef.value.validate();
  if (!isFormValid.valid) return;
  const { id, parentId, isDir } = props.selectedDocument;
  const { id: userId } = user.value;
  let formData = { name: form.folderName };

  if (!isDir) {
    formData = {
      originalName: form.folderName,
    };
  }

  const params = {
    userId,
    id,
    formData,
  };
  if (props.isPropertyDocument) {
    params.formData.workStationId = propertyDetails.value?.userWorkstation?.id;
  }
  let docParams = {};

  if (parentId) {
    docParams.parentId = parentId;
  }

  try {
    await store.dispatch(`${DOCUMENTS_STORE}/updateUserDocument`, params);
    await getFolderList(docParams);
  } catch (error) {
    const formError = "Error updating document. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    state.loading = false;
    form.folderName = "";
    onCloseModal();
    emits("on-success");
  }
};

const onCloseModal = () => {
  emits("on-close");
};

onMounted(() => {
  form.folderName = props.selectedDocument.name;
});
</script>
