<template>
  <TradePassportCardSlot
    class="trade-passport-dbs"
    title="DBS (Police Check)"
    buttonLabel="+Add DBS Check"
    :hideFooter="tradePassportDbs && tradePassportDbs.length <= 3"
    emptyStateImage="specialisms-empty"
    :emptyStateLabel="
      isUserProprtyOwner
        ? 'DBS Check will appear here'
        : 'Your DBS Check will appear here'
    "
    :isEmpty="!tradePassportDbs.length"
    :numberOfRemainingItem="tradePassportDbs.length"
    :isCustomActionHeader="
      tradePassportDbs && tradePassportDbs.length ? true : false
    "
    @on-handle-footer-click="onRouteToDbsOverview"
    @on-handle-click="onRouteToDbsOverview"
  >
    <template #custom-action-header>
      <div class="tw-flex tw-gap-3 tw-items-center">
        <Button
          label="+Add"
          variant="secondary"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          v-if="!isUserProprtyOwner"
          @click="onRouteToDbsOverview"
        />
        <BlueCircularPencilIcon
          v-if="!isUserProprtyOwner"
          @click="onRouteToDbsOverview"
        />
      </div>
    </template>
    <template #content>
      <div class="tw-w-full">
        <div
          v-for="(dbs, index) in tradePassportDbs.slice(0, 3)"
          :key="index"
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-4 tw-box-border"
          :class="{
            'tw-border-b-dashed-custom':
              index !== tradePassportDbs.slice(0, 3).length - 1,
            '!tw-pt-0': index === 0,
          }"
        >
          <div class="tw-flex tw-items-start tw-gap-3 tw-w-full">
            <div
              class="tw-min-w-[68px] tw-w-[68px] tw-h-[68px] tw-rounded-[4.85px] tw-bg-[rgba(12,15,74,0.04)] tw-border-solid tw-border-[#DFDFDF] tw-border-[1px] tw-overflow-hidden"
            >
              <iframe
                v-if="dbs?.imageUrl?.toLowerCase().endsWith('.pdf')"
                :src="
                  'https://docs.google.com/gview?url=' +
                  dbs?.imageUrl +
                  '&embedded=true'
                "
                class="tw-w-full tw-h-full tw-object-cover"
              ></iframe>
              <img
                v-else
                :src="dbs?.imageUrl"
                class="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
            <div
              class="tw-w-full tw-flex tw-flex-col tw-gap-[.1rem] tw-items-start"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <Text
                  variant="h6"
                  lineHeight="20px"
                  textAlign="left"
                  class="tw-w-[70%] md:!tw-text-[.9rem]"
                  >DBS Document {{ index + 1 }}</Text
                >
                <PendingChip
                  class="tw-w-[auto]"
                  label="Pending Verification"
                  v-if="
                    dbs?.userVerificationStatus ===
                    UserVerificationStatusEnum.PENDING_VERIFICATION
                  "
                />

                <AcceptChip
                  class="tw-w-[auto]"
                  label="Accepted"
                  v-if="
                    dbs?.userVerificationStatus ===
                      UserVerificationStatusEnum.VERIFIED && !isUserProprtyOwner
                  "
                />
                <AcceptChip
                  class="tw-w-[auto]"
                  label="Verified"
                  v-if="
                    dbs?.userVerificationStatus ===
                      UserVerificationStatusEnum.VERIFIED && isUserProprtyOwner
                  "
                />
                <RejectChip
                  class="tw-w-[auto]"
                  label="Rejected"
                  v-if="
                    dbs?.userVerificationStatus ===
                    UserVerificationStatusEnum.REJECTED
                  "
                />
              </div>
              <Text
                variant="p"
                textColor="rgba(12, 15, 74, 0.8)"
                lineHeight="18px"
                class="md:!tw-text-[.7rem]"
                v-if="dbs?.metadata?.issueDate"
              >
                Issue Date:
                {{
                  appFilters.formatToDate(
                    dbs?.metadata?.issueDate,
                    "DD MMMM YYYY"
                  )
                }}</Text
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </TradePassportCardSlot>
</template>
<script setup>
import { computed, ref, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import TradePassportCardSlot from "../slots/TradePassportCardSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";

import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { TRADE_PASSPOSRT_DETAILS_ROUTE } from "../../routes";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import appFilters from "@/filters";

const store = useStore();
const router = useRouter();
const internalInstance = getCurrentInstance();

const loading = ref(false);

const showConfirmDeleteMoal = ref(false);
const selectedId = ref(null);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const tradePassportDbs = computed(() => {
  return store.getters[`${USER_STORE}/userWorkstationDbs`];
});

const onOpenDeleteModal = (id) => {
  selectedId.value = id;
  showConfirmDeleteMoal.value = true;
};

const onCloseDeleteModal = () => {
  showConfirmDeleteMoal.value = false;
};

const onSuccessDelete = async () => {
  selectedId.value = null;
  await initialize();
  onCloseDeleteModal();
  // displayToast here
  displayToastMessage(internalInstance, "Deleted Successfully", "success");
};

const initialize = async () => {
  try {
    loading.value = true;
    if (user.value?.id) {
      await store.dispatch(
        `${USER_STORE}/getAllTradePassportDbs`,
        user.value?.id
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
    console.log(tradePassportDbs.value, "tradePassportDbs");
  }
};

const onRouteToDbsOverview = () => {
  router.push({
    name: TRADE_PASSPOSRT_DETAILS_ROUTE,
    params: { type: "dbs" },
  });
};

onBeforeMount(() => {
  initialize();
});
</script>
<style lang="scss" scoped></style>
