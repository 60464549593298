<template>
  <CommonDialog
    HeaderTittle="Is an iknowa course 100% for you?"
    title="You're one small step away from unleashing your green building superpowers!"
    :className="['enrollment__wrapper']"
    v-if="isShowPaymentDialog"
    :onClickBackButton="onClickBackButton"
  >
    <template v-slot:body>
      <div class="modal_card_content tw-mt-4 tw-flex tw-flex-col tw-gap-4">
        <div class="login_wrapper_points tw-flex tw-flex-col tw-gap-2">
          <div class="form-container-list !tw-flex !tw-items-center !tw-gap-2">
            <InfoBlueIcon />
            <Text variant="h6" textWeight="600">Attendance</Text>
          </div>
          <Text
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            alineHeight="20px"
            >Committing to all 10 course days.</Text
          >
          <v-checkbox v-model="modelOption1">
            <template v-slot:label>
              <Text variant="p" textWeight="600"
                >I accept & agree to the above</Text
              >
            </template>
          </v-checkbox>
        </div>
        <div class="login_wrapper_points tw-flex tw-flex-col tw-gap-2">
          <div class="form-container-list !tw-flex !tw-items-center !tw-gap-2">
            <InfoBlueIcon />
            <Text variant="h6" textWeight="600">Passing the Exam</Text>
          </div>
          <Text
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            alineHeight="20px"
            >Just like any hero needs to show up for training, so do you!
            Attending all ten course sessions is key to unlocking your full
            potential!</Text
          >
          <v-checkbox v-model="modelOption2">
            <template v-slot:label>
              <Text variant="p" textWeight="600"
                >I accept & agree to the above</Text
              >
            </template>
          </v-checkbox>
        </div>
        <div class="login_wrapper_points tw-flex tw-flex-col tw-gap-2">
          <div class="form-container-list !tw-flex !tw-items-center !tw-gap-2">
            <InfoBlueIcon />
            <Text variant="h6" textWeight="600">Level up</Text>
          </div>
          <Text
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            alineHeight="20px"
            >Take your learning to the next level by completing three projects
            on iknowa. It's a win-win – you gain experience and get to showcase
            your skills to clients!</Text
          >
          <v-checkbox v-model="modelOption3">
            <template v-slot:label>
              <Text variant="p" textWeight="600"
                >I accept & agree to the above</Text
              >
            </template>
          </v-checkbox>
        </div>
        <div
          class="card__buttons tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-w-1/2 md:tw-w-full"
        >
          <v-btn
            class="button button-orange"
            width="auto"
            size="large"
            block
            :loading="loading"
            :disabled="!modelOption1 || !modelOption2 || !modelOption3"
            @click="onClickAcceptAndEnroll"
          >
            accept & enroll
          </v-btn>

          <div class="yellow-text">
            Read Full Terms & Conditions
            <v-icon icon="mdi-chevron-right"></v-icon>
          </div>
        </div>
      </div>
    </template>
  </CommonDialog>
  <div class="calendar__wrapper tab__area__wrapper" v-if="isShowCalenderView">
    <div class="blue-text" @click="onClickBackCoursesButton">
      <v-icon icon="mdi-chevron-left"></v-icon>Back to Courses
    </div>
    <div class="tab__area__blocks">
      <div class="tab__area__left__block">
        <h4 class="tab__area__title">Select your preferred course date</h4>
        <Calendar
          ref="calendarRef"
          title-position="left"
          class="custom__calendar"
          :masks="masks"
          color="sky-blue"
          trim-weeks
          transparent
          :attributes="calendarAttributes"
          v-model="selectedDate"
          expanded
          :min-date="currentMonthStartDate"
        >
          <template v-slot:day-content="{ day, attributes }">
            <div class="custom__calender__date">
              <span class="day-label" :class="{ 'today-date': day.isToday }">{{
                day.day
              }}</span>
              <div class="">
                <ul>
                  <li>
                    <div class="event__list">
                      <div
                        v-for="attr in attributes"
                        :key="attr.id"
                        class="custom__calender__event__day !tw-h-auto !tw-p-0 !tw-overflow-visible tw-flex tw-flex-col tw-justify-center tw-gap-3 tw-relative group"
                        :class="{ 'is-active': courseScheduleId === attr.id }"
                        @click="onClickSelectCourse(attr)"
                      >
                        <div
                          class="calendar__event tw-h-[60px] tw-overflow-auto tw-p-[5px] tw-relative"
                        >
                          <div
                            class="calendar__event__title tw-flex tw-items-center tw-gap-1"
                          >
                            <v-icon
                              v-if="attr.course?.name"
                              icon="mdi-record"
                            ></v-icon>
                            {{ attr?.course?.name }}
                          </div>
                          <Text
                            class="tw-text-[10px]"
                            textColor="rgba(12, 15, 74, 0.5)"
                            >spot(s) available:
                            {{
                              attr?.allotedSpot < attr?.spotLimit
                                ? attr?.spotLimit - attr?.allotedSpot
                                : "Full"
                            }}</Text
                          >
                        </div>

                        <v-menu
                          :persistent="false"
                          :close-on-content-click="false"
                          scroll-strategy="close"
                          :open-on-hover="isDesktop"
                          location="end"
                        >
                          <div class="mod-popup__content">
                            <div class="mod-popup__content-inner">
                              <div class="mod-popup__content-heading">
                                <p>Course Details</p>
                              </div>
                              <div class="mod-popup__content-title">
                                <p>{{ attr?.course?.name }}</p>
                              </div>
                              <div class="mod-popup__content-info">
                                <div class="mod-popup__content-info-item">
                                  <img
                                    src="../../../assets/icons/popup-calender-clock.svg"
                                    alt=""
                                  />
                                  <p>{{ extractTime(attr.startDate) }}</p>
                                </div>
                                <div class="mod-popup__content-info-item">
                                  <img
                                    src="../../../assets/icons/popup-calender-calender.svg"
                                    alt=""
                                  />

                                  <ul>
                                    <li
                                      v-for="(week, i) in sortedWeeks(
                                        attr.courseScheduleWeeks
                                      )"
                                      :key="week.id"
                                    >
                                      Week:{{ i + 1 }}
                                      {{ formatDate(week?.weekStartDate) }}
                                      -
                                      {{ formatDate(week?.weekEndDate) }}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="mod-popup__content-location">
                                <p>Location</p>
                                <div class="mod-popup__content-location-text">
                                  <p>
                                    {{ attr?.location?.lookUpAddress }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-menu>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </Calendar>
      </div>

      <div class="tab__area__right__block">
        <v-card class="tab__area__right__block__card">
          <div class="content">
            <div>
              <h4 class="title">Upcoming Courses</h4>
              <p class="description">
                Please select the best suited course <strong>date</strong> &
                <strong>location</strong>.
              </p>
            </div>

            <div v-if="!isShowAddressList" class="location__name__wrapper">
              <div class="heading">
                <h4 class="title">Location</h4>
              </div>

              <v-select
                v-if="selectedAddress.length"
                :items="selectedAddress"
                item-title="address"
                item-value="id"
                v-model="selectedItem"
                density="compact"
                variant="outlined"
                :menu-props="{ contentClass: 'text__selector' }"
                @update:model-value="onSelectAddress"
                class="input_field_selector"
                return-object
              >
                <template v-slot:selection="{ item }">
                  <div class="location__city">
                    <div class="tab__area__list__icon">
                      <img
                        class="open-logo tw-min-w-[18px] tw-max-w-[18px] tw-min-h-[20px] tw-max-h-[20px]"
                        src="../../../assets/icons/yellow-location-icon.svg"
                        alt=""
                      />
                    </div>
                    <p>{{ item.props.title }}</p>
                  </div>
                </template>
              </v-select>

              <!-- <template v-if="selectedAddress">
                <div class="location__wrapper">
                  <div
                    class="location__city tw-cursor-pointer"
                    v-for="(item, index) in selectedAddress"
                    :key="index"
                    @click="onClickSelectCourse(item)"
                    :class="{ active: courseScheduleId == item?.id }"
                  >
                    <div class="tab__area__list__icon">
                      <img
                        class="open-logo tw-min-w-[18px] tw-max-w-[18px] tw-min-h-[20px] tw-max-h-[20px]"
                        src="../../../assets/icons/yellow-location-icon.svg"
                      />
                    </div>
                    <p>
                      {{ item.address }}
                    </p>
                  </div>
                </div>
              </template> -->
              <div v-if="!selectedAddress.length" class="location__city">
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/yellow-location-icon.svg"
                    alt=""
                  />
                </div>
                <p>No selected address filter</p>
              </div>
            </div>
            <div
              v-if="isShowAddressList"
              class="location__name__wrapper tw-flex tw-flex-col tw-gap-4"
            >
              <div class="heading">
                <h4 class="title">Locations</h4>
                <!-- <h6 @click="saveAddressChanges" class="yellow-text">Filter</h6> -->
              </div>
              <div class="tw-flex tw-flex-col tw-gap-2">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  class="c-input rounded-full !tw-mt-0 !tw-mb-0"
                  v-model="selectedAddress"
                  disabled
                ></v-text-field>
                <div class="postcode__wrapper !tw-h-[300px]">
                  <ul class="address__postcodes !tw-gap-0">
                    <li
                      v-for="(item, index) in addressListFromCourse"
                      :key="index"
                    >
                      <v-checkbox v-model="selectedAddress" :value="item">
                        <template v-slot:label>
                          <Text
                            variant="xsmall"
                            textWeight="600"
                            lineHeight="20px"
                            >{{ item.address }}</Text
                          >
                        </template>
                      </v-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="tab__area__candidate__card">
            <!-- canditates list -->
            <div class="candidate_list__card">
              <div class="card_header">
                <h6>Candidates</h6>
              </div>
              <div class="candidate_list__card__content">
                <div
                  class="candidate_list__card__user"
                  v-for="(candidate, index) in getUpdatedMembers"
                  :key="index"
                >
                  <div class="candidate_list__item">
                    <v-checkbox
                      v-model="assignedMembers"
                      @change="handleChange(candidate)"
                      color="#4B4BFF"
                      :value="candidate?.toUser?.id"
                    />
                  </div>
                  <div class="candidate_list__profile">
                    <div class="profile__img">
                      <UserProfileLogo
                        :userName="
                          candidate?.userName || candidate?.email?.split('@')[0]
                        "
                      />
                    </div>
                    <p>
                      {{
                        candidate?.toUserId
                          ? `${candidate?.toUser?.firstName} ${candidate?.toUser?.lastName}`
                          : candidate?.email?.split("@")[0]
                      }}
                    </p>
                  </div>
                  <div class="action_wrapper">
                    <div
                      class="candidate_chip"
                      v-if="candidate?.isWorkStationAdmin"
                    >
                      Admin
                    </div>
                    <div class="candidate_chip" v-else>User</div>
                  </div>
                </div>
              </div>
              <!-- <div class="candidate_list__card__user">
                  <div class="candidate_list__item">
                    <v-checkbox color="indigo" value="indigo" />
                  </div>
                  <div class="candidate_list__profile">
                    <div class="profile__img">
                      <img
                        src="@/assets/images/profile-dashboard-empty-image.png"
                      />
                    </div>
                    <p>Micheal Sparks</p>
                  </div>
                  <div class="action_wrapper">
                    <div class="candidate_chip">User</div>
                    <div class="candidate__delete">
                      <img src="@/assets/icons/trash-icon.svg" />
                    </div>
                  </div>
                </div>
                <div class="candidate_list__card__user">
                  <div class="candidate_list__item">
                    <v-checkbox color="indigo" value="indigo" />
                  </div>
                  <div class="candidate_list__profile">
                    <div class="profile__img">
                      <img
                        src="@/assets/images/profile-dashboard-empty-image.png"
                      />
                    </div>
                    <p>Toby Sparks</p>
                  </div>
                  <div class="action_wrapper">
                    <div class="candidate_chip">User</div>
                    <div class="candidate__delete">
                      <img src="@/assets/icons/trash-icon.svg" />
                    </div>
                  </div>
                </div> -->
            </div>
          </div>

          <div class="tab__area__card" v-if="enrollCourseName">
            <h6 class="title">
              {{ enrollCourseName ? enrollCourseName : courseDetails?.name }}
            </h6>
            <span class="yellow-text"
              >Starting date:
              {{
                enrollCourseStartDate
                  ? formattedStartDate(enrollCourseStartDate)
                  : formattedStartDate(
                      courseDetails?.courseSchedules[0]?.startDate
                    )
              }}</span
            >
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text variant="span" textWeight="600"
                >Spots available:
                <Text variant="span" textColor="#FAA500">{{
                  spotsRemaining ? spotsRemaining : "Full"
                }}</Text></Text
              >

              <Text variant="span" textWeight="600"
                >Location:
                {{
                  enrollCourseAddress
                    ? enrollCourseAddress
                    : courseDetails?.courseSchedules[0]?.address
                }}</Text
              >
            </div>
          </div>

          <v-checkbox
            v-model="option1"
            class="tab__area__checkbox"
            color="#4B4BFF"
            value="#4B4BFF"
          >
            <template v-slot:label>
              <p>Add me to the live queue.</p>
              <v-icon icon="mdi-information" />
            </template>
          </v-checkbox>

          <!-- <v-checkbox
            v-model="option2"
            class="tab__area__checkbox remove-padding"
          >
            <template v-slot:label>
              <p>
                I have read and agree with the
                <span class="yellow-text remove-padding"
                  >Terms of Service & Privacy Policy</span
                >
              </p>
            </template>
          </v-checkbox> -->
          <v-btn
            class="button button-purple"
            @click="onClickConfirmMyEnrollment"
            :disabled="isEnrollDisable"
            :loading="enrollLoading"
            >confirm enrollment
          </v-btn>
        </v-card>
      </div>
    </div>
  </div>
  <CoursesListView v-if="false" />

  <CourseEnrollModal
    v-if="isShowCourseEnrollModal"
    :courseDetails="courseDetails"
    @onContinue="onClickPaymentSuccessContinue"
  />
</template>

<script lang="ts">
import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from "@/config";
import { Calendar } from "v-calendar";
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  getCurrentInstance,
} from "vue";
import "v-calendar/style.css";
import { useStore } from "vuex";
import $axios from "@/core/utils/axios-api-config";
import { USER_STORE } from "@/store/modules/user";
import {
  cloneObject,
  displayToastMessage,
  parseJSON,
} from "@/core/utils/common";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import { useRouter } from "vue-router";
import InfoBlueIcon from "@/core/components/icons/InfoBlueIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CoursesListView from "@/modules/courses/components/CoursesListView.vue";
import moment from "moment";
import { WORKSTATION } from "@/store/modules/workstation";
import CourseEnrollModal from "@/core/components/CourseEnrollModal.vue";

import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import coursesService from "@/core/services/courses.service";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";

export default defineComponent({
  name: "CalenderView",

  components: {
    Calendar,
    CommonDialog,
    InfoBlueIcon,
    Text,
    CoursesListView,

    UserProfileLogo,
    CourseEnrollModal,
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const date = new Date();
    const calendarRef = ref(null) as any;
    const store = useStore();
    const router = useRouter();
    const month = new Date().getMonth();
    const selectedDate = ref(moment().format("YYYY-MM-DD"));
    const year = new Date().getFullYear();
    const courseSchedules = ref([]);
    const isShowPaymentDialog = ref(false);
    const enrollLoading = ref(false);
    const isShowCalenderView = ref(true);
    const isShowEnrollmentDialog = ref(
      localStorage.getItem("isShowEnrollmentDialog") === "true"
    );
    const courseScheduleId = ref();
    const enrollCourseStartDate = ref("");
    const enrollCourseName = ref();
    const currentCourseWithMember = ref(null) as any;
    const loading = ref(false);
    const masks = {
      weekdays: "WWW",
    };
    const option1 = ref(false);
    const option2 = ref(false);
    const isShowAddressList = ref(false);
    const addressListFromCourse = ref([]) as any;
    const selectedAddressIndex = ref(null);
    const selectedCourseSchedule = ref(null) as any;
    const selectedAddress = ref([]) as any;
    const assignedMembers = ref([]) as any;
    const isShowAddSpots = ref(false);
    const spotPrice = ref(29.97);
    const vat = ref(0.2);

    const onClickBackCoursesButton = () => {
      router.push("/courses");
    };
    const modelOption1 = ref(false);
    const modelOption2 = ref(false);
    const modelOption3 = ref(false);
    const stripe = ref(null);
    const sessionId = ref(null);
    const redirectUrl = ref("");
    const customer = ref("") as any;
    const enrollCourseAddress = ref("");
    const enrollCourseSpotLimit = ref("");
    const spotsRemaining = ref(null) as any;
    const calendarAttributes = ref([]) as any;
    const isShowReserveSpotModal = ref(false);
    const isShowPaymentSuccessModal = ref(false);
    const isShowEligibilityModal = ref(false);
    const isShowCourseEnrollModal = ref(false);
    const confirmLoading = ref(false);

    const toggleCourseEnrollModal = () => {
      isShowCourseEnrollModal.value = !isShowCourseEnrollModal.value;
    };

    const eligibility = ref(null);

    const paymentStatus = ref(null) as any;

    const coursesWithMembers = ref([]) as any;
    // const filterMembers = ref([]) as any;

    const selectedCourse = ref(null) as any;
    const selectedItem = ref(null) as any;
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const courseDetails = computed(
      () => store.getters[`${COURSES_STORE}/courseDetails`]
    );
    const getUpdatedMembers = computed(() => {
      const updatedMembers =
        store.getters[`${COURSES_STORE}/getupdatedMembers`];

      if (
        activeUserWorkstation?.value?.user?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.SOLETRADER
      ) {
        return updatedMembers.filter((member: any) => {
          return member?.isWorkStationAdmin;
        });
      }
      return updatedMembers;
    });
    const courseOptions = computed(() => {
      return courseDetails.value.courseSchedules.map((course: any) => ({
        id: course.id,
        name: course.name,
      }));
    });

    const spotsAmount = computed(() => {
      // Sum all spots in selectedDates
      const totalSpots = selectedDates.value.reduce(
        (sum: number, item: any) => {
          return sum + item.spots;
        },
        0
      );

      // Multiply the sum by 29.97
      return totalSpots * spotPrice.value;
    });

    const vatAmount = computed(() => {
      return spotsAmount.value * vat.value;
    });
    // Function to update selected course
    const onCourseSelect = (courseId: any) => {
      selectedCourse.value = courseDetails.value.courseSchedules.find(
        (course: any) => course.id === courseId
      );
    };

    const getCourseSchedule = async () => {
      try {
        let response = await store.dispatch(
          `${COURSES_STORE}/getCourseSchedule`,
          {
            name: courseDetails.value?.name,
          }
        );

        const currentDate = moment().format("YYYY-MM-DD");
        const filteredData = response.data.filter((course: any) => {
          const enrollEnd = moment(course.enrollmentEndDate, "YYYY-MM-DD");

          // Check if the current date is between or equal to the enrollment start and end dates
          return moment(currentDate).isSameOrBefore(enrollEnd);
        });
        courseSchedules.value = filteredData;
        // courseSchedules.value = response.data;
        addressListFromCourse.value = courseSchedules.value

          .filter((item: any) => item.address !== null)
          .map((item: any) => {
            return {
              address: item.address,
              id: item.id,
            };
          });
        selectedAddress.value = addressListFromCourse.value;
        if (courseSchedules.value) {
          calendarAttributes.value = await getCalenderAttribute();
        }
      } catch (error) {
        console.log("error:", error);
      }
    };

    const fetchLearnersCount = async (scheduleId: any) => {
      try {
        const response = await store.dispatch(
          `${COURSES_STORE}/getCourseScheduleById`,
          {
            courseScheduleId: scheduleId,
          }
        );
        const { course } = response;

        const { userCourses } = course;

        if (userCourses.length) {
          let users = [];
          userCourses.forEach((userCourse: any) => {
            const { userCourseSchedules, user } = userCourse;

            if (userCourseSchedules.length) {
              const item = userCourseSchedules.find(
                (userCourseSchedule: any) => {
                  return userCourseSchedule?.courseSchedule?.id === scheduleId;
                }
              );
              if (item) {
                users.push(user);
              }
            }
          });
          return users.length;
        }

        return 0;
      } catch (error) {
        console.error(
          `Failed to fetch learners count for course ${scheduleId}:`,
          error
        );
        return 0; // Default to 0 if there's an error
      }
    };

    const getSpotRemaining = async (scheduleId: any, spotLimit: any) => {
      const enrolledLearnersCount = await fetchLearnersCount(scheduleId);
      let spots: any;
      if (enrolledLearnersCount >= spotLimit) {
        spots = 0;
      } else {
        spots = spotLimit - enrolledLearnersCount;
      }
      return spots;
    };
    const getcourseScheduleWeeks = async (scheduleId: any) => {
      const response = await store.dispatch(
        `${COURSES_STORE}/getCourseScheduleById`,
        {
          courseScheduleId: scheduleId,
        }
      );

      const { courseScheduleWeeks } = response;
      return courseScheduleWeeks;
    };

    const getCalenderAttribute = async () => {
      if (selectedAddress.value.length) {
        const selectedIds = selectedAddress.value.map((item: any) => item.id);

        const filteredCourseSchedulesByAddress = courseSchedules.value.filter(
          (schedule: any) => selectedIds.includes(schedule.id)
        );

        const results = await Promise.all(
          filteredCourseSchedulesByAddress.map(async (course: any) => ({
            ...course,
            spotsRemaining: await getSpotRemaining(course.id, course.spotLimit),
            courseScheduleWeeks: await getcourseScheduleWeeks(course.id),
            dates: new Date(course?.startDate),
          }))
        );

        return results;
      }

      const results = await Promise.all(
        courseSchedules.value.map(async (course: any) => ({
          ...course,
          spotsRemaining: await getSpotRemaining(course.id, course.spotLimit),
          dates: new Date(course?.startDate),
        }))
      );

      return results;
    };
    const onClickConfirmMyEnrollment = async () => {
      try {
        confirmLoading.value = true;
        const response = await store.dispatch(
          `${COURSES_STORE}/checkCourseAlreadyEnrolled`,
          {
            userId: user.value?.id,
            scheduleId: courseScheduleId.value,
          }
        );

        if (!response?.isAlreadyEnrolled) {
          await createCourses();
          displayToast("Enrollment successful!", "success");
        } else {
          displayToast("You are already enrolled.", "error");
        }
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        confirmLoading.value = false;
      }
    };
    const onToggleReserveSpotModal = () => {
      isShowReserveSpotModal.value = !isShowReserveSpotModal.value;
    };
    const selectedDates = computed(
      () => store.getters[`${COURSES_STORE}/selectedDates`]
    );

    const onReserveSpotContinue = () => {
      onToggleReserveSpotModal();
      isShowPaymentDialog.value = true;
    };
    const onClickOpenPaymentDialog = async () => {
      const response = await store.dispatch(
        `${COURSES_STORE}/checkCourseAlreadyEnrolled`,
        {
          userId: user.value?.id,
          scheduleId: courseScheduleId.value,
        }
      );
      if (!response?.isAlreadyEnrolled) {
        isShowPaymentDialog.value = !isShowPaymentDialog.value;
      } else {
        displayToast("You are already enrolled.", "error");
      }
    };

    const createCourses = async () => {
      try {
        paymentStatus.value = null;
        enrollLoading.value = true;

        const selectedDatesData = [selectedCourseSchedule.value];
        const courseReserveData = selectedDatesData.map((data: any) => {
          return {
            courseId: parseInt(courseDetails.value?.id, 10),
            courseScheduleId: data.id,
            selectedSpotCount: assignedMembers.value.length, //no of members
          };
        });

        const request = {
          userId: user.value.id,
          courseReserveData,
        };
        const reserveSpots = await store.dispatch(
          `${COURSES_STORE}/reserveSpot`,
          request
        );

        const userCourseIds = reserveSpots.map((spot: any) => {
          return spot.id;
        });

        const parsedSubscription = parseJSON(
          activeUserWorkstation?.value?.subscription
        );

        const planType = parsedSubscription?.planType
          ? parsedSubscription?.planType
          : parseInt(parsedSubscription?.metadata.planType, 10);
        const payload = {
          userCourseIds,
          workStationId: activeUserWorkstation.value.id,
          planType,
        };

        const response = await store.dispatch(
          `${COURSES_STORE}/courseUpgradeSubscription`,
          {
            userId: user.value.id,
            payload,
          }
        );
        paymentStatus.value = "paid";
        await courseAssignMembers();
      } catch (error: any) {
        if (error.response) {
          if (error.response.status) {
            const errorMessage =
              error.response.data.message || "course enrolled error";
            displayToastMessage(internalInstance, errorMessage, "error");
          } else {
            // Handle other status codes or errors
            displayToastMessage(
              internalInstance,
              `Error: ${error.response.statusText}`,
              "error"
            );
          }
        } else {
          displayToastMessage(
            internalInstance,
            "course enrolled error.",
            "error"
          );
        }

        paymentStatus.value = "failed";
      } finally {
        enrollLoading.value = false;
      }
    };

    const courseAssignMembers = async () => {
      try {
        const userCourseId = courseDetails.value.userCourses[0]?.id;
        await store.dispatch(`${COURSES_STORE}/assignUserCourseToMember`, {
          userId: user.value?.id,
          userCourseId: userCourseId,
          memberIds: assignedMembers.value,
        });
        toggleCourseEnrollModal();
      } catch (error: any) {
        if (error.response) {
          if (error.response.status) {
            const errorMessage =
              error.response.data.message || "course assign member error";
            displayToastMessage(internalInstance, errorMessage, "error");
          } else {
            // Handle other status codes or errors
            displayToastMessage(
              internalInstance,
              `Error: ${error.response.statusText}`,
              "error"
            );
          }
        } else {
          displayToastMessage(
            internalInstance,
            "course assign member error",
            "error"
          );
        }
      }
    };

    const togglePaymentSuccessModal = () => {
      isShowPaymentSuccessModal.value = !isShowPaymentSuccessModal.value;
    };
    const onClickPaymentSuccessContinue = () => {
      toggleCourseEnrollModal();
      onClickRedirectToEnrollModal();
    };

    const onClickAcceptAndEnroll = async () => {
      isShowCalenderView.value = false;
      isShowPaymentDialog.value = false;
      const parsedSubscription = parseJSON(
        activeUserWorkstation?.value?.subscription
      );
      if (!parsedSubscription) {
        isShowEnrollmentDialog.value = true;
        return displayToastMessage(
          internalInstance,
          `Please upgrade your workstation`,
          "info"
        );
      }
      await createCourses();
    };
    const onClickRedirectToEnrollModal = () => {
      isShowCalenderView.value = false;
      isShowPaymentDialog.value = false;
      isShowEnrollmentDialog.value = true;
      localStorage.setItem("isShowEnrollmentDialog", "true");
    };

    const initializeStripe = () => {
      let stripe = null;

      if ("Stripe" in window) {
        const { Stripe } = window as any;

        // eslint-disable-next-line no-new
        stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
      }

      return stripe;
    };

    const initializeStripePayment = async (
      isTrial: boolean,
      oneTimePayment: boolean
    ) => {
      loading.value = true;
      stripe.value = initializeStripe();
      if (stripe.value) {
        await onCheckout(isTrial, oneTimePayment);
      }
    };

    const onCheckout = async (isTrial: boolean, oneTimePayment: boolean) => {
      await createSession(isTrial, oneTimePayment);

      if (sessionId.value) {
        (stripe.value as any)
          .redirectToCheckout({
            sessionId: sessionId.value,
          })
          .then((result: any) => {
            if (result.error) {
              console.log("Error: ", result.error);
            }
          });
      }
    };

    const isDesktop = computed(() => {
      return window.innerWidth > 1024;
    });

    const createSession = async (isTrial = false, oneTimePayment = false) => {
      const user = cloneObject(store.getters[`${USER_STORE}/user`]);
      const { id: userId, userStripeCustomer, email } = user;

      const productName = enrollCourseName.value
        ? enrollCourseName.value
        : courseDetails.value?.name;

      const entity = JSON.stringify({
        id: userId,
        name: enrollCourseName.value,
      });

      if (!userStripeCustomer?.length) {
        await $axios
          .post(`/payment/${userId}/customer`, {
            email,
            entity,
          })
          .then(async (customerResponse) => {
            await store.dispatch(`${USER_STORE}/saveUserStripePayment`, [
              customerResponse,
            ]);
            isShowEnrollmentDialog.value = true;
            customer.value = customerResponse;
            loading.value = false;
          })
          .catch((e) => {
            console.log("Error: ", e.message);
          });
      } else {
        const [stripeCustomer] = userStripeCustomer;
        customer.value = stripeCustomer;
      }

      const host = `${window.location.protocol}//${window.location.host}`;
      isShowEnrollmentDialog.value = true;
      redirectUrl.value = `${host}/courses`;

      const { customerId } = customer.value;
      const totalPrice = 9.99;

      if (customerId) {
        await $axios
          .post(`/payment/${userId}/session`, {
            customerId,
            price: totalPrice,
            redirectUrl: redirectUrl.value,
            productName,
            metadata: JSON.stringify({
              productName,
              userId,
            }),
            isTrial,
            oneTimePayment,
          })
          .then(async (sessionResponse: any) => {
            if (sessionResponse) {
              sessionId.value = sessionResponse.id;
              localStorage.setItem("isShowEnrollmentDialog", "true");
              // createCourses();
            }
          });
      }
    };

    // Compute the current month start date
    const currentMonthStartDate = computed(() => {
      const date = new Date();
      date.setDate(1);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const startDate = `${year}-${month}-${day}`;
      return startDate;
    });

    const sortedWeeks = (
      weeks: { id: number; weekStartDate: string; weekEndDate: string }[]
    ): { id: number; weekStartDate: string; weekEndDate: string }[] => {
      return weeks.sort((a, b) => {
        const startA = new Date(a.weekStartDate).getTime();
        const startB = new Date(b.weekStartDate).getTime();
        return startA - startB; // Returns a number
      });
    };

    const formatDate = (dateString: string): string => {
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const extractTime = (dateTimeString: string): string => {
      const date = new Date(dateTimeString);
      const options: Intl.DateTimeFormatOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return date.toLocaleTimeString(undefined, options);
    };
    const formattedStartDate = (value: any) => {
      const startDate = new Date(value);
      const day = startDate.getDate();
      const month = startDate.toLocaleString("default", { month: "short" });
      const year = startDate.getFullYear();
      return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    };

    function getOrdinalSuffix(day: number) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const isEnrollDisable = computed(() => {
      return !(
        assignedMembers.value.length > 0 &&
        spotsRemaining.value &&
        (courseScheduleId.value || enrollCourseName.value)
      );
    });

    const onClickBackButton = () => {
      onToggleReserveSpotModal();
      isShowPaymentDialog.value = false;
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickSelectCourse = async (attr: any) => {
      // if (attr?.spotsRemaining === 0) {
      //   displayToast("Available spots are full.", "success");
      // }
      selectedItem.value = attr;
      const calenderAtt = calendarAttributes.value.find(
        (item: any) => item.id === attr.id
      );
      if (calenderAtt?.spotLimit - calenderAtt?.allotedSpot <= 0) {
        return displayToast("All spots are currently booked.", "error");
      }
      let newDate = moment(calenderAtt?.startDate).format("YYYY-MM-DD");
      await calendarRef.value.move(newDate);
      spotsRemaining.value = await getSpotRemaining(
        calenderAtt?.id,
        calenderAtt?.spotLimit
      );
      selectedCourseSchedule.value = calenderAtt;

      const currentselected: any = coursesWithMembers.value?.find(
        (course: any) => {
          return course.id === selectedCourseSchedule.value.id;
        }
      );
      currentCourseWithMember.value = currentselected;
      courseScheduleId.value = calenderAtt?.id;
      enrollCourseStartDate.value = calenderAtt?.startDate;
      enrollCourseName.value = calenderAtt?.course?.name;
      enrollCourseAddress.value = calenderAtt?.address;
      enrollCourseSpotLimit.value = calenderAtt?.spotLimit;
    };

    const changeAddress = () => {
      isShowAddressList.value = true;
      addressListFromCourse.value = courseSchedules.value

        .filter((item: any) => item.address !== null)
        .map((item: any) => {
          return {
            address: item.address,
            id: item.id,
          };
        });
    };

    const saveAddressChanges = async () => {
      calendarAttributes.value = await getCalenderAttribute();
      isShowAddressList.value = false;
    };
    const handleChange = async (member: any) => {
      const updatedSChedule = coursesWithMembers.value.find((cwm: any) => {
        return currentCourseWithMember.value?.id == cwm.id;
      });
      const existingIndex = coursesWithMembers.value.findIndex(
        (cwm: any) => cwm.id === currentCourseWithMember.value.id
      );

      if (existingIndex !== -1) {
        coursesWithMembers.value[existingIndex] = updatedSChedule;
      }
    };
    const onSelectAddress = (item: any) => {
      onClickSelectCourse(item);
    };
    const onCloseEligibilityForAssignCourseModal = () => {
      eligibility.value = null;
      isShowEligibilityModal.value = false;
    };

    const onReserveSpotSubmit = async () => {
      try {
        // await store.dispatch(
        //   `${COURSES_STORE}/setSelectedDates`,
        //   selectedDates.value
        // );
        await store.dispatch(
          `${COURSES_STORE}/setTotalReserveSpotAmount`,
          spotsAmount.value + vatAmount.value
        );
        const currentDate = new Date();
        await store.dispatch(
          `${COURSES_STORE}/setSpotReservedDateStarted`,
          currentDate
        );
      } catch (error) {
        console.log("error :>> ", error);
      }
    };
    onBeforeMount(async () => {
      await getCourseSchedule();
      calendarAttributes.value = await getCalenderAttribute();

      coursesWithMembers.value = calendarAttributes.value.map(
        (course: any) => ({
          ...course,
          members: getUpdatedMembers.value.map((member: any) => ({
            ...member,
            isSelected: false,
          })),
        })
      );
    });

    return {
      date,

      selectedItem,
      selectedCourse,
      masks,
      getCalenderAttribute,
      onClickOpenPaymentDialog,
      isShowPaymentDialog,
      modelOption1,
      modelOption2,
      modelOption3,
      onClickBackCoursesButton,
      option1,
      option2,
      initializeStripePayment,
      isShowEnrollmentDialog,
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      loading,
      courseDetails,
      getUpdatedMembers,
      courseOptions,
      onCourseSelect,
      formattedStartDate,
      user,
      isEnrollDisable,
      onClickBackButton,
      enrollLoading,
      onClickSelectCourse,
      enrollCourseStartDate,
      enrollCourseName,
      currentCourseWithMember,
      courseScheduleId,
      enrollCourseAddress,
      changeAddress,
      isShowAddressList,
      saveAddressChanges,
      addressListFromCourse,
      selectedAddressIndex,
      enrollCourseSpotLimit,
      selectedAddress,
      onClickRedirectToEnrollModal,
      isShowCalenderView,
      spotsRemaining,
      fetchLearnersCount,
      getSpotRemaining,
      calendarAttributes,
      selectedDate,
      calendarRef,
      currentMonthStartDate,
      isShowReserveSpotModal,
      onToggleReserveSpotModal,
      onReserveSpotContinue,
      onClickConfirmMyEnrollment,
      selectedCourseSchedule,
      isShowPaymentSuccessModal,
      onClickPaymentSuccessContinue,
      onClickAcceptAndEnroll,
      paymentStatus,
      isDesktop,
      sortedWeeks,
      formatDate,
      extractTime,
      coursesWithMembers,
      handleChange,
      onSelectAddress,
      assignedMembers,
      onCloseEligibilityForAssignCourseModal,
      eligibility,
      isShowEligibilityModal,
      selectedDates,
      isShowCourseEnrollModal,
      toggleCourseEnrollModal,
      confirmLoading,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "@/modules/courses/styles/courses.scss";

.tab__area__candidate__card {
  border: 1px solid rgba($bordercolor, 1);
  border-radius: 8px;
  background-color: rgba(241, 246, 251, 1);
  padding: 16px;
  margin-top: 10px;
  .candidate_list__card {
    .card_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px dashed #8687a5;
      h6 {
        @include fluidFont(14, 14, 17.57px);
        font-weight: 500;
        color: rgba($blueDark, 1);
      }
      .button {
        margin: 0 !important;
        padding: 5px 7px !important;
        height: auto !important;
        min-height: auto !important;
        text-transform: capitalize !important;
        letter-spacing: normal !important;
        :deep(.v-btn__content) {
          @include fluidFont(10, 10, 13px);
        }
      }
    }
    .candidate_list__card__content {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .candidate_list__card__user {
        display: flex;
        gap: 10px;
        align-items: center;

        .candidate_list__profile {
          display: flex;
          gap: 10px;
          align-items: center;
          .profile__img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          p {
            @include fluidFont(14, 14, 17.57px);
            font-weight: 400;
            color: rgba($blueDark, 1);
          }
        }
        .action_wrapper {
          display: flex;
          gap: 10px;
          align-items: center;
          margin-left: auto;
          .candidate_chip {
            padding: 5px 7px;
            border: 1px solid rgba($bordercolor, 1);
            border-radius: 5px;
            @include fluidFont(10, 10, 11.7px);
            font-weight: 600;
            color: rgba($blueDark, 0.4);
          }
          .candidate__delete {
            width: 16px;
            height: 16px;
            background-color: rgba($buttonText, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            img {
              filter: invert(97%) sepia(96%) saturate(21%) hue-rotate(293deg)
                brightness(104%) contrast(100%);
              width: 6px;
              height: 6px;
            }
          }
        }
      }
    }
  }
}

.input_field_selector {
  margin: 0;
  :deep(.v-input__control) {
    .v-field {
      border: 1px solid rgba($blueDark, 0.2);
      border-radius: 8px;
      .v-field__field {
        .v-field__input {
          background-color: transparent;
          border: 0;
          border-radius: 0;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          min-height: 42px;
          padding: 0 16px;
          top: 0;
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}
.course_name {
  padding: 16px;
  border: 1px solid rgba($bordercolor, 1);
  border-radius: 8px;
  margin-top: 24px;
  h6 {
    @include fluidFont(14, 14, 17.57px);
    font-weight: 600;
    color: rgba($blueDark, 1);
  }
  p {
    @include fluidFont(12, 12, 17.57px);
    font-weight: 600;
    color: rgba($orange, 1);
    margin-top: 12px;
  }
  span {
    @include fluidFont(12, 12, 17.57px);
    font-weight: 600;
    color: rgba($blueDark, 1);
  }
}
.button-purple {
  width: 100%;
  max-width: 100%;
}

.v-checkbox {
  :deep(.v-input__control) {
    .v-selection-control {
      min-height: auto;
      .v-selection-control__wrapper {
        height: auto;
        width: auto;
        .v-selection-control__input {
          width: auto;
          height: auto;
          .v-icon {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.tab__area__checkbox {
  margin-top: 24px;
  padding: 0;
}
</style>
