<template>
  <Button
    variant="custom"
    label="invite contractor to estimate"
    height="40px"
    borderColor="#3366FF"
    activeBgColor="#FFF"
    activeFontColor="#3366FF"
    fontSize="14px"
    fontWeight="700"
    width="auto"
    :isCapitalize="true"
    :disabled="
      (isLockTemplateModifiedChecker &&
        !currentJobDetails?.isHistoricProject) ||
      !isUserHasEstimateEditAccess ||
      !isAddAnyPhases
    "
  >
    <template #prefix>
      <TradeAvatarWithPlusYellow />
    </template>
  </Button>
</template>
<script setup>
import Button from "@/core/components/ui/general/Button.vue";
import TradeAvatarWithPlusYellow from "@/core/components/icons/TradeAvatarWithPlusYellow.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { computed } from "vue";
import { useStore } from "vuex";
import { JOBS_STORE } from "@/store/modules/jobs";
const store = useStore();
const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const isAddAnyPhases = computed(
  () =>
    !!(
      estimateForm.value?.estimationIBGData?.estimationIbgPolicies?.length ||
      estimateForm.value?.projectStages?.length
    )
);

const isLockTemplateModifiedChecker = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsEstimateSubmitted`]
);
const isUserHasEstimateEditAccess = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isUserHasEstimateEditAccess`]
);
</script>
<script>
export default {
  name: "AddContractorButton",
};
</script>
