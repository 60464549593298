import JobRoute from "@/modules/nwg-job/pages/jobPage.vue";
import JobDetailRoute from "@/modules/nwg-job/pages/JobDetailPage.vue";
import BatchDetailRoute from "@/modules/nwg-job/pages/BatchDetailPage.vue";
import store from "@/store";

import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { NWG_JOBS_STORE } from "@/store/modules/nwg-job";

export const NWG_JOB_ROUTE = "nwg-jobs";
export const NWG_JOB_DETAIL_ROUTE = "nwg-job-detail";
export const NWG_BATCH_DETAIL_ROUTE = "nwg-batch-detail";

export default [
  {
    path: "/nwg/jobs",
    name: NWG_JOB_ROUTE,
    component: JobRoute,
    meta: {
      requiresAuth: true,
      title: "NWG Jobs",
    },
  },
  {
    path: "/nwg/job/:id",
    name: NWG_JOB_DETAIL_ROUTE,
    component: JobDetailRoute,
    meta: {
      requiresAuth: true,
      title: "NWG Jobs",
    },
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      try {
        const jobId = to.params.id as string;

        if (!/^\d+$/.test(jobId)) {
          return next({ name: NWG_JOB_ROUTE });
        }

        const job = await store.dispatch(
          `${NWG_JOBS_STORE}/setJobDetails`,
          jobId
        );
        if (!job) {
          return next({ name: NWG_JOB_ROUTE });
        }

        return next();
      } catch (error) {
        return next({ name: NWG_JOB_ROUTE });
      }
    },
  },
  {
    path: "/nwg/batch/:id",
    name: NWG_BATCH_DETAIL_ROUTE,
    component: BatchDetailRoute,
    meta: {
      requiresAuth: true,
      title: "NWG Jobs",
    },
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      try {
        const batchId = to.params.id as string;

        if (!/^\d+$/.test(batchId)) {
          return next({ name: NWG_JOB_ROUTE });
        }

        const batchDetail = await store.dispatch(
          `${NWG_JOBS_STORE}/setBatchDetails`,
          batchId
        );
        if (!batchDetail) {
          return next({ name: NWG_JOB_ROUTE });
        }

        return next();
      } catch (error) {
        return next({ name: NWG_JOB_ROUTE });
      }
    },
  },
];
