<template>
  <GeneralDialog
    @on-close="onClickBackButton"
    maxWidth="100%"
    headerTitle="Add Education & Certifications"
    headerDescription="Please enter your qualification details; note that proof will be required before engaging in any projects on iKnowa."
    width="570px"
    :isTitleStrong="false"
    :paddingLarge="false"
  >
    <template #body>
      <!-- <p class="note__wrapper">
        *Please note. we will require proof before engaging in any projects on
        iknowa.
      </p> -->
      <div
        class="sole-trader-data-wrapper md:!tw-h-full tw-border-t-solid-custom"
      >
        <div class="form__wrapper !tw-w-full !tw-max-w-[unset] md:!tw-m-0">
          <v-form
            @submit.prevent
            :rules="validation"
            ref="certificationFormRef"
            class="md:!tw-h-full"
            v-model="isFormValid"
          >
            <div class="modal_input_wrapper tw-flex tw-flex-col tw-gap-4">
              <div
                class="modal_input !tw-m-0 tw-flex tw-flex-col tw-gap-2 tw-items-start"
              >
                <Text variant="span" textWeight="400">Title</Text>
                <InputField
                  class="!tw-w-full custom-selector"
                  v-model="certificateName"
                  :rules="validation.certificateId"
                  :append-inner-icon="
                    !isShowSelectCertificates
                      ? 'mdi-chevron-down'
                      : 'mdi-chevron-up'
                  "
                  @click="onToggleShowSelectCertificate"
                  placeholder="E.g, Plumbing & Heating"
                ></InputField>
                <div
                  v-if="isShowSelectCertificates"
                  class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-3 tw-p-2 tw-overflow-y-auto"
                >
                  <div
                    class="selection-card tw-w-full tw-bg-[#FFF] tw-rounded-lg tw-px-4 tw-box-border tw-flex tw-items-center tw-gap-2"
                  >
                    <v-checkbox
                      v-model="qualificationData.certificateId"
                      color="primary"
                      label="I Do Not Have Any Qualifications"
                      :value="10"
                      hide-details
                      @change="hasInteracted = true"
                    ></v-checkbox>
                  </div>
                  <CollapsibleWidgetSlot title="Electrical" :isExpand="true">
                    <template #content>
                      <section
                        class="kyp-score tw-grid tw-w-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
                      >
                        <v-radio-group
                          v-model="qualificationData.certificateId"
                          column
                        >
                          <v-radio
                            v-for="(
                              item, index
                            ) in certificateDummyDataElectrical"
                            :key="index"
                            color="primary"
                            :label="item.name"
                            :value="item.id"
                          ></v-radio>
                        </v-radio-group>
                      </section>
                    </template>
                  </CollapsibleWidgetSlot>
                  <CollapsibleWidgetSlot
                    title="Plumbing & Heating"
                    :isExpand="false"
                  >
                    <template #content>
                      <section
                        class="kyp-score tw-grid tw-w-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
                      >
                        <v-radio-group
                          v-model="qualificationData.certificateId"
                          column
                        >
                          <v-radio
                            v-for="(
                              item, index
                            ) in certificateDummyDataPlumbing"
                            :key="index"
                            color="primary"
                            :label="item.name"
                            :value="item.id"
                          ></v-radio>
                        </v-radio-group>
                      </section>
                    </template>
                  </CollapsibleWidgetSlot>
                </div>
                <!-- <v-select
                  :items="certificateList"
                  item-title="name"
                  item-value="id"
                  placeholder="e.g Plumbing & Heating"
                  :rules="validation.certificateId"
                  v-model="qualificationData.certificateId"
                  variant="outlined"
                  class="c-select mb-2"
                  :menu-props="{ contentClass: 'tags_dropdown' }"
                ></v-select> -->

                <!-- <v-text-field
                  v-if="qualificationData.certificateId === getOthersId"
                  density="compact"
                  :rules="validation.typeText"
                  placeholder="E.g. Public liability"
                  variant="outlined"
                  v-model="customType"
                  class="c-input rounded-full tw-flex-row-reverse mt-4 mb-2"
                >
                </v-text-field> -->
                <InputField
                  v-if="qualificationData.certificateId === getOthersId"
                  :rules="validation.typeText"
                  class="!tw-w-full"
                  v-model="customType"
                  placeholder="E.g. Public liability"
                ></InputField>
              </div>
              <!-- <template> -->
              <div
                class="modal_input !tw-m-0 tw-flex tw-flex-col tw-gap-2 tw-items-start"
              >
                <Text variant="span" textWeight="400"
                  >Membership number
                  <Text
                    variant="p"
                    textWeight="600"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >(optional)</Text
                  ></Text
                >

                <!-- <v-text-field
                  density="compact"
                  placeholder="eg. 12345678"
                  variant="outlined"
                  v-model="qualificationData.registrationNumber"
                  class="c-input rounded-full !tw-m-0"
                ></v-text-field> -->
                <InputField
                  class="!tw-w-full"
                  v-model="qualificationData.registrationNumber"
                  placeholder="eg. 12345678"
                ></InputField>
              </div>
              <div
                class="modal_input !tw-m-0 tw-flex tw-flex-col tw-gap-2 tw-items-start"
              >
                <Text variant="span" textWeight="400"
                  >URL
                  <Text
                    variant="p"
                    textWeight="600"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >(optional)</Text
                  ></Text
                >

                <!-- <v-text-field
                  density="compact"
                  placeholder="Add Credential URL"
                  variant="outlined"
                  v-model="qualificationData.url"
                  class="c-input rounded-full !tw-m-0"
                ></v-text-field> -->
                <InputField
                  type="url"
                  class="!tw-w-full"
                  :rules="validation.url"
                  v-model="qualificationData.url"
                  placeholder="https://exampleurl.com/gas-safe-certificate.pdf"
                ></InputField>
              </div>
              <!-- <div class="modal_input !tw-m-0" v-if="!isFileInputDisabled">
                <Text variant="span" textWeight="400">Evidence</Text>

                <v-file-input
                  class="c-file !tw-m-0"
                  :label="
                    qualificationData.attachments[0]?.originalName
                      ? qualificationData.attachments[0]?.originalName
                      : `Upload`
                  "
                  prepend-icon="mdi-upload"
                  variant="solo"
                  flat
                  @click:clear="clearAttachments"
                  @change="onFileChange"
                  :error-messages="attachmentError"
                ></v-file-input>
              </div> -->

              <!-- <div v-if="isFileUploading">
                <v-progress-linear
                  :model-value="uploadProgress"
                  :height="10"
                  :width="5"
                  striped
                  rounded
                  color="#0C0F4A"
                >
                </v-progress-linear>
                {{ uploadProgress }} %<span>&nbsp; Uploading file...</span>
              </div> -->

              <div
                v-if="!isFileInputDisabled"
                class="modal_input !tw-m-0 tw-gap-0 tw-flex tw-flex-col w-full"
              >
                <Text variant="span" textWeight="400">Evidence*</Text>
                <div
                  class="tw-mt-2 tw-mb-4 tw-w-full tw-h-auto tw-flex tw-flex-col tw-gap-4 tw-p-3 tw-box-border tw-border-[1px] tw-border-[#DFDFDF] tw-border-solid tw-rounded-[8px] tw-bg-[#F8FBFD]"
                >
                  <div class="upload__section md:!tw-h-[100px]">
                    <div
                      class="uploade-wrapper custom-file-upload !tw-h-[97px] md:!tw-h-full !tw-w-full !tw-mt-4 !tw-rounded-lg tw-border-[rgba(0,0,0,0.4)] tw-border-[1px] tw-border-dashed !tw-mb-4"
                    >
                      <v-file-input
                        class="upload_file !tw-mb-0 !tw-bg-white"
                        label="Upload images & files, or Browse"
                        variant="solo-filled"
                        v-model="qualificationData.attachments"
                        :rules="validation.attachments"
                        :class="{
                          'added-img':
                            qualificationData.attachments &&
                            qualificationData.attachments?.length,
                        }"
                        @change="onFileChange"
                        prepend-icon="mdi-file-image-box"
                      >
                      </v-file-input>
                    </div>
                  </div>
                  <div class="attachment-list !tw-mt-0">
                    <div
                      v-for="(file, index) in qualificationData.attachments"
                      :key="index"
                      class="attachment-item !tw-bg-none !tw-p-0 !tw-border-none"
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <PdfIcon
                          class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                          v-if="file.mime === 'application/pdf'"
                        />
                        <ImageIcon
                          class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                          v-else
                        />
                        <Text variant="p" textWeight="400">{{
                          file.originalName
                        }}</Text>
                        <!-- <Text variant="p" textWeight="400">{{ file }}</Text> -->
                      </div>
                      <v-icon
                        class="cursor-pointer !tw-text-[16px]"
                        icon="mdi-close"
                        @click="clearAttachments"
                      ></v-icon>
                    </div>
                  </div>
                </div>

                <!-- File Upload Progress -->
                <div v-if="isFileUploading" class="w-full sm:w-80">
                  <v-progress-linear
                    :model-value="uploadProgress"
                    :height="10"
                    :width="5"
                    striped
                    rounded
                    color="#0C0F4A"
                  ></v-progress-linear>
                  {{ uploadProgress }} %<span>&nbsp; Uploading file...</span>
                </div>
              </div>

              <div
                class="add-more-data !tw-mt-0"
                v-if="userCertificates.length"
              >
                <Text
                  variant="h6"
                  textWeight="600"
                  textColor="rgba(12, 15, 74, 0.4)"
                  whiteSpace="nowrap"
                >
                  Nothing more to add?
                  <Text
                    class="!tw-cursor-pointer"
                    variant="h6"
                    textWeight="600"
                    textColor="#3366FF"
                    whiteSpace="nowrap"
                    @click="onClickQualificationContinue"
                    >Cancel<v-icon icon="mdi-chevron-right"></v-icon>
                  </Text>
                </Text>
              </div>
              <!-- </template> -->
            </div>
            <!-- <v-btn
              class="disable button"
              :class="[
                isSaveButtonDisable ? 'disable' : 'button-orange',
                isFileInputDisabled
                  ? 'without-upload-input'
                  : 'add-update-button',
              ]"
              :loading="isQualificationSubmitting"
              @click="onClickAddQualification"
              :disabled="isSaveButtonDisable"
              >{{
                isEditCertificate ? "update qualification" : "add qualification"
              }}</v-btn
            > -->
          </v-form>
        </div>
        <!-- <div v-else>
          <div class="insurances-list">
            <div class="insurances-list__innner !tw-m-0">
              <ItemListCard
                v-for="certificate in userCertificates"
                :key="certificate.id"
                :onClickDelete="() => deleteCertificate(certificate.id)"
                :isShowLogo="true"
                :onClickEdit="() => editCertificate(certificate)"
              >
                <template v-slot:listItemLogo>
                  <ServiceIconWrapper iconName="tools" />
                </template>
                <template v-slot:listItemCardTitle>{{
                  certificate.metadata.name
                    ? certificate.metadata.name
                    : certificate.certificate.name
                }}</template>
                <template v-slot:listItemCardLabel>{{
                  certificate?.metadata?.registrationNumber
                }}</template>
                <template v-slot:listItemCardText
                  >{{ certificate?.metadata?.url }}
                  <div v-if="certificate?.attachment.length">
                    {{ certificate?.attachment[0]?.originalName }}
                  </div></template
                >
              </ItemListCard>
            </div>
            <v-btn
              class="button button-purple-border w-100 !tw-m-0"
              @click="onClickAddAnotherQualification"
              >add another qualification</v-btn
            >
          </div>
          <v-btn
            class="button button-orange w-100"
            @click="onClickQualificationContinue"
            >Continue</v-btn
          >
        </div> -->
      </div>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-gap-3 tw-items-center tw-justify-end tw-pt-4 tw-border-t-dashed-custom"
      >
        <Button
          variant="secondary"
          label="Cancel"
          :isCapitalize="true"
          @click="onClickBackButton"
          :disabled="isQualificationSubmitting"
          class="!tw-w-auto"
        ></Button>
        <Button
          variant="accent"
          :label="
            isEditCertificate ? 'update qualification' : 'add qualification'
          "
          :isCapitalize="true"
          :loading="isQualificationSubmitting"
          @click="onClickAddQualification"
          :disabled="isSaveButtonDisable"
          class="!tw-w-auto"
        ></Button>
      </div>
    </template>
  </GeneralDialog>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import ItemListCard from "@/core/components/ItemListCard.vue";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import ServiceIconWrapper from "@/core/components/common/ServiceIconWrapper.vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { CERTIFICATE_STORE } from "@/store/modules/certificates";
import { onBeforeMount } from "vue";
import { watch } from "vue";
import { onMounted, onBeforeUnmount } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import PdfIcon from "@/core/components/icons/PdfIcon.vue";
import ImageIcon from "@/core/components/icons/ImageIcon.vue";

const emit = defineEmits(["onClickCloseTab", "clearUserCertificateData"]);
const props = defineProps({
  userCertificateData: { type: Object, default: null },
});
const isFileInputDisabled = ref(false);
const isShowQualificationForm = ref(true);
const isQualificationSubmitting = ref(false);
const store = useStore();
const uploadProgress = ref(0);
const modelDetails = ref({
  id: 2,
  tab: "Certifications",
  name: "certifications",
  header: "Certifications",
  title: "Do you have any certifications?",
  description: `Please enter your qualification details.`,
});
const isFileUploading = ref(false);
const attachmentError = ref("");
const imageData = ref("");
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const qualificationData = ref({
  certificateId: null,
  registrationNumber: null,
  url: null,
  attachments: [],
}) as any;
const certificationFormRef = ref(null) as any;
const hasInteracted = ref(false);
const validation = ref({
  certificateId: [
    (v: any) =>
      hasInteracted.value ? !!v || "Select one certification" : true,
  ],
  typeText: [
    (v: string) => !!v || "Provide a certification type",
    (v: string) => isNaN(Number(v)) || "Must be a character",
  ],
  url: [
    (v: any) =>
      !v ||
      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(v) ||
      "Please provide a valid certificate URL.",
  ],
  attachments: [(v: any[]) => v.length > 0 || "Attachment is required"],
});

const isFormValid = ref(false);
const customType = ref("");

const isShowSelectCertificates = ref(false);
const certificateName = ref("");

// not applicabble or don't have any = 10
// other = 9

const certificateDummyDataElectrical = ref([
  {
    name: "18th Edition (Issued within the last 5 years)",
    id: 18,
  },
  {
    name: "NVQ Level 3 Electrical Installation & Maintenance",
    id: 19,
  },
  {
    name: "ECS Gold Card -  Electrical Installation",
    id: 20,
  },
  {
    name: "Part 1 & 2 Electrical Installation (Issued before 1994)",
    id: 21,
  },
]);

const certificateDummyDataPlumbing = ref([
  {
    name: "Gas Safe (CENWAT)",
    id: 11,
  },
  {
    name: "OFTEC (OFT10-105E or OFT15-108W), MCS (Heat Pump or Solar Thermal Hot Water) or HETAS (H004) registration",
    id: 12,
  },
  {
    name: "N/SVQ Level 2/3 in Plumbing or equivalent earlier certification that provides evidence of competence",
    id: 13,
  },
  {
    name: "N/SVQ Level 2/3 in Heating and Ventilating (Domestic Installation) or equivalent earlier certification that provides evidence of competence",
    id: 14,
  },
  {
    name: "N/SVQ Level 2/3 in Heating and Ventilating (Industrial and Commercial Installation) or equivalent earlier certification that provides evidence of competence",
    id: 15,
  },
  {
    name: "N/SVQ Level 2/3 in Oil-Fired Technical Services or equivalent earlier certification that provides evidence of competence",
    id: 16,
  },
  {
    name: "N/SVQ Level 2/3 in Gas Installation and Maintenance or equivalent earlier certification that provides evidence of competence",
    id: 17,
  },
  {
    name: "NICEIC",
    id: 2,
  },
  {
    name: "Trust Mark",
    id: 3,
  },
  {
    name: "Federation of Master Builders",
    id: 4,
  },
  {
    name: "ECA",
    id: 5,
  },
  {
    name: "NAPIT",
    id: 6,
  },
  {
    name: "FENSA",
    id: 7,
  },
  {
    name: "NFRC",
    id: 8,
  },
]);

const onToggleShowSelectCertificate = () => {
  // if searching always show the selected dates ui
  if (!qualificationData.value.certificateId && certificateName.value !== "") {
    isShowSelectCertificates.value = true;
    return;
  }
  // if navigating back to selectDates from add spots screen
  // if (!isShowSelectCertificates.value && qualificationData.value) {
  //   const newSelectedDates = [];
  //   // Iterate through calendarAttributes
  //   calendarAttributes.value.forEach((item) => {
  //     // Check if the selectedDates contains an item with the same ID as in calendarAttributes
  //     const isSelected = selectedDates.value.find(
  //       (selectedItem) => selectedItem.id === item.id
  //     );

  //     // If it's selected, pre-check the checkbox by pushing it to newSelectedDates
  //     if (isSelected) {
  //       newSelectedDates.push(item);
  //     }
  //   });
  //   // set the newSelectedDates to selectedDates
  //   selectedDates.value = newSelectedDates;
  // }
  isShowSelectCertificates.value = !isShowSelectCertificates.value;

  // make sure add spots section is close if select dates is open
  // if (isShowSelectCertificates.value) {
  //   isShowAddSpots.value = false;
  // }
};

const onClickBackButton = () => {
  return emit("onClickCloseTab");
};
const userCertificates = computed(
  () => store.getters[`${USER_STORE}/userCertificates`]
);

const isSaveButtonDisable = computed(
  () =>
    !isFormValid.value ||
    (!(
      qualificationData.value.attachments.length &&
      qualificationData.value.certificateId
    ) &&
      qualificationData.value.certificateId !== 10)
);

const onClickAddQualification = async () => {
  try {
    const isFormValid = await certificationFormRef.value.validate();
    isQualificationSubmitting.value = true;
    if (
      !qualificationData.value.url &&
      !qualificationData.value.attachments.length &&
      qualificationData.value.certificateId !== 10
    ) {
      attachmentError.value =
        "Add credential URL or upload evidence to continue";
      return;
    }
    if (!isFormValid.valid) return;
    let certificateData;
    interface Metadata {
      url: string;
      registrationNumber: string;
      name?: string; // Optional property
    }

    if (isEditCertificate.value) {
      certificateData = {
        certificateId: qualificationData.value.certificateId,
        metadata: {
          url: qualificationData.value.url,
          registrationNumber: qualificationData.value.registrationNumber,
        } as Metadata,
        attachment: qualificationData.value.attachments[0],
      };

      if (qualificationData.value.certificateId === getOthersId.value) {
        certificateData.metadata.name = customType.value || "";
      }
      await store.dispatch(`${USER_STORE}/updateUserCertificate`, {
        userId: user.value?.id,
        id: userCertificateModalData.value?.id,
        certificate: certificateData,
      });
    } else {
      certificateData = {
        userId: user.value.id,
        certificateId: qualificationData.value.certificateId,
        metadata: {
          url: qualificationData.value.url,
          registrationNumber: qualificationData.value.registrationNumber,
        } as Metadata,
        attachments: qualificationData.value.attachments,
      };

      if (qualificationData.value.certificateId === getOthersId.value) {
        certificateData.metadata.name = customType.value || "";
      }
      await store.dispatch(`${USER_STORE}/addNewCertificate`, certificateData);
    }
    isShowQualificationForm.value = false;
  } catch (error) {
    console.log();
  } finally {
    isQualificationSubmitting.value = false;
    await store.dispatch(`${USER_STORE}/setUserCertificates`, user.value?.id);
    await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
    onClickQualificationContinue();
  }
};

const deleteCertificate = async (certificateId: number) => {
  try {
    await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
      userId: user?.value?.id,
      userCertificateId: certificateId,
    });
    if (!userCertificates.value.length) {
      isShowQualificationForm.value = true;
    }
  } catch (error) {
    console.log();
  }
};
const onClickCancelButton = () => {
  isShowQualificationForm.value = false;
};

const isEditCertificate = computed(() => {
  return userCertificateModalData.value ? true : false;
});
const editCertificate = (certificate: any) => {
  qualificationData.value.certificateId = certificate?.certificate?.id;
  qualificationData.value.registrationNumber =
    certificate?.metadata?.registrationNumber;
  qualificationData.value.url = certificate?.metadata?.url;
  qualificationData.value.attachments = certificate?.attachment;
  isShowQualificationForm.value = true;
  userCertificateModalData.value = certificate;
};

const onClickAddAnotherQualification = () => {
  qualificationData.value = {
    certificateId: null,
    registrationNumber: null,
    url: null,
    attachments: [],
  };
  userCertificateModalData.value = false;
  isShowQualificationForm.value = true;
};
const onClickQualificationContinue = () => {
  return emit("onClickCloseTab");
};

const certificateList = computed(
  () => store.getters[`${CERTIFICATE_STORE}/certificates`]
);

const getOthersId = computed(() => {
  if (!certificateList.value) {
    return null;
  }
  const foundItem = certificateList.value.find(
    (item: any) => item.template === "Other"
  );
  return foundItem ? foundItem.id : null;
});

const onFileChange = async (event: any) => {
  try {
    isFileUploading.value = true;
    attachmentError.value = "";
    uploadProgress.value = 0;
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];

      imageData.value = file;
      const formData = new FormData();
      formData.append("file", imageData.value);

      const progressInterval = setInterval(() => {
        if (uploadProgress.value < 100) {
          uploadProgress.value += 10;
        }
      }, 120);

      const uploadImg = await store.dispatch(
        `${USER_STORE}/uploadProfileImage`,
        formData
      );
      if (uploadImg) {
        clearInterval(progressInterval);
        uploadProgress.value = 100;
      }

      qualificationData.value.attachments = [uploadImg];
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    attachmentError.value = "Error uploading file.";
  } finally {
    isFileUploading.value = false;
  }
};

const getCertificateNameById = async (id: number) => {
  // Combine both arrays for a unified search
  const allCertificates = [
    ...certificateDummyDataElectrical.value,
    ...certificateDummyDataPlumbing.value,
  ];

  // Find the object with the matching id
  const certificate = allCertificates.find((cert) => cert.id === id);

  // Return the name if found, otherwise return a default message
  return certificate ? certificate.name : "Certificate not found";
};

watch(
  () => qualificationData.value.certificateId,
  async () => {
    if (qualificationData.value.certificateId) {
      if (qualificationData.value.certificateId === 10) {
        certificateName.value = "I Do Not Have Any Qualifications";
        return;
      }
      certificateName.value = await getCertificateNameById(
        qualificationData.value.certificateId
      );
      console.log(certificateName.value, "certificateName");
    } else {
      certificateName.value = "";
    }
  },
  { deep: true }
);

watch(
  () => qualificationData.value.url,
  () => {
    attachmentError.value = "";
  }
);

watch(
  () => certificateName.value,
  (value) => {
    if (value == "I Do Not Have Any Qualifications") {
      isFileInputDisabled.value = true;
    } else {
      isFileInputDisabled.value = false;
    }
  },
  { deep: true, immediate: true }
);
onBeforeMount(async () => {
  await store.dispatch(`${CERTIFICATE_STORE}/getAllCertificates`);
  if (userCertificateModalData.value) {
    const userCertifiCateId = certificateList.value?.find(
      (certificate: any) =>
        certificate.name === userCertificateModalData.value?.certificate?.name
    );
    qualificationData.value.certificateId = userCertifiCateId?.id;
    qualificationData.value.url = userCertificateModalData.value?.metadata?.url;
    qualificationData.value.registrationNumber =
      userCertificateModalData.value?.metadata?.registrationNumber;
    qualificationData.value.attachments =
      userCertificateModalData.value?.attachment;
  }
});
const userCertificateModalData = ref(null) as any;
onMounted(() => {
  userCertificateModalData.value = props.userCertificateData;
  // if (userCertificates.value && userCertificates.value.length) {
  //   isShowQualificationForm.value = false;
  //   if (
  //     props.userCertificateData === undefined ||
  //     props.userCertificateData === null
  //   ) {
  //     isShowQualificationForm.value = false;
  //   } else {
  //     isShowQualificationForm.value = true;
  //   }
  // } else {
  //   isShowQualificationForm.value = true;
  // }
  console.log(certificateList.value, "certificateList");
});

const clearAttachments = () => {
  qualificationData.value.attachments = [];
};
onBeforeUnmount(() => {
  emit("clearUserCertificateData");
});
</script>

<style lang="scss" scoped>
@import "@/modules/certification/styles/CertificationModel.scss";
.selection-card {
  box-shadow: 0px 0px 6px 0px rgba(12, 15, 74, 0.1);
}

:deep(.v-selection-control) {
  .v-label {
    font-weight: 700;
  }
}

:deep(.v-selection-control-group) {
  gap: 1rem;
}

:deep(.cursor-pointer) {
  cursor: pointer;
}

.custom-selector {
  :deep(.v-input__control) {
    .v-field {
      .v-field__field {
        input {
          cursor: pointer;
        }
      }
    }
  }
}
.c-file {
  :deep(.v-input__control) {
    .v-field {
      .v-field__clearable {
        .v-icon {
          font-size: 135%;
          width: 65px;
        }
      }
    }
  }
}
.without-upload-input {
  width: 100%;
  margin-top: 135px;
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}

.custom-file-upload {
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: #f8fbfd;
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}

.upload_file {
  :deep(.v-label) {
    margin-top: 0 !important;
  }
}

.upload_file {
  :deep(.v-field__input) {
    display: none;
  }
  :deep(.v-field__clearable) {
    display: none;
  }
}
</style>
