<template>
  <el-input
    class="no-bg-input"
    :placeholder="placeholder"
    :class="{
      sm: props.size === 'sm',
      md: props.size === 'md',
      lg: props.size === 'lg',
    }"
  >
  </el-input>
</template>

<script setup>
const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "sm", // md, lg
  },
});
</script>

<script>
export default {
  name: "NoBgInput",
};
</script>

<style lang="scss" scoped>
:deep(.el-input__wrapper) {
  @apply tw-bg-transparent tw-border-0 tw-shadow-none tw-rounded-none tw-p-0
      tw-min-h-[2.625rem] tw-max-h-[2.625rem] tw-h-full w-full tw-border-b-[1px] tw-border-[rgba(12,15,74,0.2)];
}
.el-input.is-disabled .el-input__wrapper {
  background-color: transparent;
  box-shadow: none;
}

.no-bg-input.sm {
  :deep(.el-input__wrapper) {
    .el-input__inner {
      font-size: 16px;
    }
  }
}
.no-bg-input.md {
  :deep(.el-input__wrapper) {
    .el-input__inner {
      font-size: 20px;
    }
  }
}
.no-bg-input.lg {
  :deep(.el-input__wrapper) {
    .el-input__inner {
      font-size: 24px;
    }
  }
}
</style>
