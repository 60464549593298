<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="590px"
    max-width="100%"
    :className="['setting_up_preview_modal']"
  >
    <template v-slot:body>
      <div class="modal_content">
        <v-icon
          @click="onClickCloseModel"
          icon="mdi-close"
          class="close_icon"
        />
        <h4 class="r2xl:!tw-max-w-[61%]">
          Here we go! Let’s get your workstation set up
        </h4>
        <img src="../../../../assets/images/add-business-add.png" alt="" />
        <p>Please, add your business address</p>
        <div
          class="tw-grid tw-gap-4 tw-grid-cols-2 tw-w-full s540:!tw-flex s540:!tw-flex-col-reverse"
        >
          <Button
            @click="onClickCloseModel"
            variant="secondary"
            label="Cancel"
          ></Button>
          <Button
            @click="onClickAddBusinessAddress"
            label="Add Business Address"
            variant="accent"
          ></Button>
        </div>
        <!-- <v-btn
          variant="elevated"
          class="button button-orange w-full"
          @click="onClickAddBusinessAddress"
        >
          Add Business Address
        </v-btn>
        <v-btn
          @click="onClickCloseModel"
          variant="plain"
          class="blue-text"
          color="blue"
          >cancel</v-btn
        > -->
        <!-- <div class="header">
          <v-btn
            variant="text"
            icon="mdi-close"
            @click="onClickCloseButton"
          ></v-btn>
        </div>
        <div
          class="modal_content_main md:!tw-p-0 md:!tw-flex md:!tw-flex-col md:!tw-gap-4"
        >
          <h4>Here we go! Let’s get your workstation set up</h4>
          <div class="title">Add Business Address</div>
          <div class="sub-title md:!tw-m-0">
            Business address is used to build trust and legitimacy for your
            business and also to send you jobs based on the range of which you
            would like to receive work in.
          </div>
          <div class="title_img md:!tw-m-0">
            <img src="@/assets/images/add-business-address-preview.png" />
          </div>
        </div>
        <div class="modal_footer_button">
          <v-btn
            variant="elevated"
            class="button button-orange w-full"
            @click="onClickAddBusinessAddress"
          >
            Add Business Address
          </v-btn>
        </div> -->
      </div>
    </template>
  </CommonDialog>
  <AddAddressModal
    v-if="openedModel === modelName.ADDRESSMODAL"
    @on-close="onClickCloseModel"
    @on-close-success="onClickCloseSuccessModal"
  />
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import AddAddressModal from "@/modules/dashboard/components/Modals/AddAddressModal.vue";
import Button from "@/core/components/ui/general/Button.vue";

export default {
  components: {
    CommonDialog,
    AddAddressModal,
    Button,
  },
  emits: ["onClickCloseTab", "onClickCloseSuccessTab"],
  setup(props: any, ctx: any) {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const modelName = {
      ADDRESSMODAL: "addressModal",
    };
    const openedModel = ref("");

    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    const isUserAddBusinessAddress = computed(() => {
      if (
        user.value?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
      ) {
        return user.value.userBusinessDetails.businessPostcode;
      } else {
        return user.value.userBusinessDetails.residencyPostcode;
      }
    });

    const onClickAddBusinessAddress = () => {
      if (isUserAddBusinessAddress.value) {
        console.log("isUserAddBusinessAddress");
      } else {
        openedModel.value = "addressModal";
      }
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      ctx.emit("onClickCloseTab");
    };

    const onClickCloseSuccessModal = async () => {
      openedModel.value = "";
      ctx.emit("onClickCloseSuccessTab");
    };

    return {
      onClickCloseButton,
      onClickAddBusinessAddress,
      isUserAddBusinessAddress,
      modelName,
      openedModel,
      onClickCloseModel,
      onClickCloseSuccessModal,
    };
  },
};
</script>
