<template>
  <div>
    <main class="dashboard-wrapper tw-pb-16 tw-box-border">
      <!-- LOADER -->
      <div
        v-if="isWorkstationLoading"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full"
      >
        <Loader :show="isWorkstationLoading" />
      </div>

      <div v-else class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <section class="tw-flex tw-flex-col tw-gap-6 tw-w-full tw-h-full">
          <div
            class="tw-w-full tw-h-full tw-grid tw-grid-cols-1 tw-gap-6 tw-box-border rlg:tw-grid-cols-2"
          >
            <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6">
              <!-- <CreateNewJobWidget /> -->
              <!-- <ProjectsOverviewWidget /> -->
              <TeamWidget
                className="!tw-min-h-[275px]"
                :propMembers="members"
                @get-members="getTeamMember"
              />
            </div>
            <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6">
              <NwgCalendarEventWidget className="property_calendar" />
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { setDirectStoragePathToImage } from "@/core/utils/common";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import ProjectsOverviewWidget from "@/modules/dashboard/components/Widgets/ProjectsOverviewWidget.vue";
import TeamWidget from "@/modules/dashboard/components/Widgets/TeamWidget.vue";
import NwgCalendarEventWidget from "@/modules/dashboard/components/Widgets/NwgCalendarEventWidget.vue";
import CreateNewJobWidget from "./Widgets/CreateNewJobWidget.vue";
import Loader from "@/core/components/common/Loader.vue";

const store = useStore();
const teamMemberLoading = ref(false);
const members = ref([]) as any;

const isWorkstationLoading = computed(
  () => store.getters[`${WORKSTATION}/loadingWorkstation`]
);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getTeamMember = async () => {
  try {
    teamMemberLoading.value = true;
    const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
      userId: user.value?.id,
    });

    members.value = await Promise.all(
      response.map(async (member: any) => {
        if (member?.toUser?.userPublicProfile) {
          member.userProfileImageUrl = setDirectStoragePathToImage(
            member?.toUser?.userPublicProfile?.profileImage
          );
        } else {
          member.userProfileImageUrl = null;
        }
        return member;
      })
    );
  } catch (err) {
    console.log();
  } finally {
    teamMemberLoading.value = false;
  }
};

onBeforeMount(async () => {
  getTeamMember();
});
</script>
