<template>
  <TradePassportCardSlot
    class="trade-passport-insurance"
    title="Insurance"
    buttonLabel="+Add Insurance"
    :hideFooter="props.userInsurances && props.userInsurances.length <= 3"
    emptyStateImage="insurance-empty-state"
    :emptyStateLabel="
      isUserProprtyOwner
        ? 'Insurance details will appear here'
        : 'Your Insurance details will appear here'
    "
    :isEmpty="!props.userInsurances.length"
    :numberOfRemainingItem="props.userInsurances.length"
    :isCustomActionHeader="
      props.userInsurances && props.userInsurances.length ? true : false
    "
    @on-handle-footer-click="onRouteToInsuranceOverview"
    @on-handle-click="onRouteToInsuranceOverview"
  >
    <template #custom-action-header>
      <div class="tw-flex tw-gap-3 tw-items-center">
        <Button
          label="+Add"
          variant="secondary"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          v-if="!isUserProprtyOwner"
          @click="onRouteToInsuranceOverview"
        />
        <BlueCircularPencilIcon
          v-if="!isUserProprtyOwner"
          @click="onRouteToInsuranceOverview"
        />
      </div>
    </template>
    <template #content>
      <div class="tw-w-full">
        <div
          v-for="(insurance, index) in props.userInsurances.slice(0, 3)"
          :key="index"
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-4 tw-box-border"
          :class="{
            'tw-border-b-dashed-custom':
              index !== props.userInsurances.slice(0, 3).length - 1,
            '!tw-pt-0': index === 0,
          }"
        >
          <div class="tw-flex tw-items-start tw-gap-3 tw-w-full">
            <div
              class="tw-min-w-[68px] tw-w-[68px] tw-h-[68px] tw-rounded-[4.85px] tw-bg-[rgba(12,15,74,0.04)] tw-border-solid tw-border-[#DFDFDF] tw-border-[1px] tw-overflow-hidden"
            >
              <img
                src="@/assets/images/tradepassport-empty-thumbnail.png"
                class="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
            <div
              class="tw-w-full tw-flex tw-flex-col tw-gap-[.1rem] tw-items-start"
            >
              <div class="tw-flex tw-items-start tw-gap-2">
                <Text
                  variant="h6"
                  lineHeight="18px"
                  textAlign="left"
                  class="md:!tw-text-[.9rem]"
                  >{{
                    insurance?.metadata?.insuranceName ||
                    insurance?.insurance?.name
                  }}</Text
                >
                <PendingChip
                  class="tw-w-[auto]"
                  label="Pending Verification"
                  v-if="
                    insurance?.userVerificationStatus ===
                      UserVerificationStatusEnum.PENDING_VERIFICATION &&
                    !isExpiredDate(insurance?.metadata?.expiryDate)
                  "
                />

                <AcceptChip
                  class="tw-w-[auto]"
                  label="Accepted"
                  v-if="
                    insurance?.userVerificationStatus ===
                      UserVerificationStatusEnum.VERIFIED &&
                    !isExpiredDate(insurance?.metadata?.expiryDate) &&
                    !isUserProprtyOwner
                  "
                />
                <AcceptChip
                  class="tw-w-[auto]"
                  label="Verified"
                  v-if="
                    insurance?.userVerificationStatus ===
                      UserVerificationStatusEnum.VERIFIED &&
                    !isExpiredDate(insurance?.metadata?.expiryDate) &&
                    isUserProprtyOwner
                  "
                />
                <RejectChip
                  class="tw-w-[auto]"
                  label="Rejected"
                  v-if="
                    insurance?.userVerificationStatus ===
                      UserVerificationStatusEnum.REJECTED &&
                    !isExpiredDate(insurance?.metadata?.expiryDate)
                  "
                />
                <RejectChip
                  class="tw-w-[auto]"
                  label="Expired"
                  v-if="isExpiredDate(insurance?.metadata?.expiryDate)"
                />
              </div>
              <Text
                variant="p"
                textColor="rgba(12, 15, 74, 0.8)"
                lineHeight="18px"
                textAlign="left"
                class="md:!tw-text-[.7rem]"
              >
                Coverage:
                {{ appFilters.formatToCurrency(insurance?.metadata?.sum) }}
              </Text>
              <Text
                variant="p"
                textColor="rgba(12, 15, 74, 0.8)"
                lineHeight="18px"
                textAlign="left"
                class="md:!tw-text-[.7rem]"
              >
                Expiry:
                {{ appFilters.formatToDate(insurance?.metadata?.expiryDate) }}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </template>
  </TradePassportCardSlot>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import TradePassportCardSlot from "../slots/TradePassportCardSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import {
  getImageStringToImageURL,
  isExpiredDate,
  setDirectStoragePathToImage,
} from "@/core/utils/common";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import appFilters from "@/filters";
import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { TRADE_PASSPOSRT_DETAILS_ROUTE } from "../../routes";

const store = useStore();
const router = useRouter();

const props = defineProps(["userInsurances"]);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const onRouteToInsuranceOverview = () => {
  router.push({
    name: TRADE_PASSPOSRT_DETAILS_ROUTE,
    params: { type: "insurance" },
  });
};
</script>
<style lang="scss" scoped></style>
