<template>
  <TradePassportCardSlot
    class="trade-passport-summary rlg:!tw-min-w-[416px] rlg:!tw-w-[416px] rlg:!tw-h-[auto]"
    title="Trade Passport"
    :isTitleStrongMobile="true"
    :isCustomActionHeader="true"
    :hideFooter="true"
    :isCustomEmptyState="true"
  >
    <template #custom-action-header>
      <Button
        variant="purple-outline"
        :label="getDynamicRateLabel"
        v-if="!isUserProprtyOwner"
        @click="onAddRate"
      />
    </template>
    <template #custom-empty-state>
      <div class="tw-flex tw-flex-col tw-w-full tw-gap-4">
        <!-- Qr section -->
        <section class="tw-w-full tw-flex tw-items-center tw-justify-between">
          <VerifiedBadge
            label="Verified"
            v-if="
              activeUserWorkstation?.verificationStatus ===
              UserVerificationStatusEnum.VERIFIED
            "
          />
          <UnverifiedBadge v-else />
          <Text
            v-if="getLinkData?.url"
            @click="onOpenQr"
            variant="h6"
            textWeight="600"
            class="!tw-underline tw-cursor-pointer"
            >View QR Code</Text
          >
        </section>

        <!-- profile section -->
        <section
          class="tw-w-full tw-hidden tw-min-h-[282px] tw-relative tw-bg-white tw-border-[1px] tw-border-solid tw-border-[#DFDFDF] tw-rounded-[10px] tw-overflow-hidden rlg:!tw-inline"
        >
          <!-- banner -->
          <div
            class="tw-min-h-[158px] tw-w-full tw-p-4 tw-box-border tw-bg-[#EEF1F3] tw-overflow-hidden tw-border-b-[1px] tw-border-b-[#DFDFDF] tw-border-b-solid tw-relative"
          >
            <div class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-right-0">
              <img
                v-if="!props.userWorkStationBanner"
                src="@/assets/images/trade-banner-mobile.png"
                class="tw-w-full tw-h-full tw-object-cover"
              />
              <img
                v-if="props.userWorkStationBanner"
                :src="props.userWorkStationBanner"
                class="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
          </div>
          <!-- profile details -->
          <div
            class="tw-min-h-[182px] tw-bg-transparent tw-w-full tw-p-4 tw-box-border tw-flex tw-gap-4 tw-items-end tw-absolute tw-bottom-0"
          >
            <div
              class="tw-bg-white tw-w-full tw-rounded-lg tw-border-solid tw-border-[#DFDFDF] tw-border-[1px] tw-p-4 tw-box-border tw-flex tw-items-start tw-gap-2"
            >
              <ProfileThumbnail
                v-if="
                  props.contractorworkStationImage || props.userWorkStationImage
                "
                :isRounded="false"
                :desktopFontSizeBig="true"
                size="xlg"
                mobilSize="lg"
                :isOnline="true"
                :src="
                  isUserProprtyOwner
                    ? props.contractorworkStationImage
                    : props.userWorkStationImage
                "
              />
              <div
                v-else
                class="tw-min-w-[96px] tw-h-[96px] tw-overflow-hidden tw-rounded-[10.67px]"
              >
                <UserProfileLogo
                  :isRounded="false"
                  :desktopFontSizeBig="true"
                />
              </div>
              <div class="tw-w-full tw-h-full tw-flex tw-box-border">
                <div
                  class="tw-w-full tw-flex tw-items-start tw-justify-between"
                >
                  <div class="tw-flex tw-flex-col tw-gap-2 tw-items-start">
                    <Text
                      variant="h6"
                      textWeight="400"
                      lineHeight="18px"
                      class="rlg:!tw-text-[20px]"
                      textAlign="left"
                      :isCapitalize="true"
                      >{{ activeUserWorkstation?.name }}</Text
                    >
                    <div class="view-rating-box">
                      <v-rating
                        active-color="orange"
                        v-model="getAverageOfAllProjectReview"
                        color="rgba(12, 15, 74, 0.26)"
                        empty-icon="mdi-star"
                        readonly
                      ></v-rating>
                      <Text
                        variant="xsmall"
                        textWeight="600"
                        lineHeight="18px"
                        class="rmd:!tw-text-[12px]"
                        whiteSpace="nowrap"
                        >{{ getAverageOfAllProjectReview }} out of 5</Text
                      >
                    </div>
                    <!-- service badge here -->
                    <div
                      class="tw-w-full tw-flex tw-gap-2 tw-items-center"
                      v-if="userServices && userServices.length"
                    >
                      <template v-for="(skill, index) in userServices">
                        <div
                          class="service-badge"
                          v-if="index < 1"
                          :key="index"
                        >
                          <div
                            class="tw-min-w-[16px] tw-w-[16px] tw-h-[16px] tw-rounded-full tw-bg-[#0C0F4A] tw-flex tw-items-center tw-justify-center"
                          >
                            <img
                              :src="
                                require(`@/assets/category-icons/${skill.iconName}.svg`)
                              "
                              class="tw-w-[8px] tw-min-w-[8px] tw-h-[10px] tw-object-contain white-svg"
                            />
                          </div>
                          <Text variant="span">{{ skill.label }}</Text>
                        </div>
                      </template>

                      <div
                        class="service-badge"
                        v-if="userServices?.length > 1"
                      >
                        <Text variant="span"
                          >{{ userServices?.length - 1 }}+</Text
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- jobs completed and connection section -->
        <section
          class="tw-w-full tw-grid tw-gap-4 tw-grid-cols-2 tw-items-center"
        >
          <div
            class="tw-w-full tw-rounded-[10px] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-bg-white tw-h-[82px] tw-flex tw-flex-col tw-gap-0 tw-items-center tw-justify-center"
          >
            <Text textWeight="500" variant="h4">0</Text>
            <Text
              textWeight="500"
              variant="span"
              textColor="rgba(12, 15, 74, 0.7)"
              >Jobs Completed</Text
            >
          </div>
          <div
            class="tw-w-full tw-rounded-[10px] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-bg-white tw-h-[82px] tw-flex tw-flex-col tw-gap-0 tw-items-center tw-justify-center"
          >
            <Text textWeight="500" variant="h4">{{
              activeUserWorkstation?.userWorkstationMembers.length || 0
            }}</Text>
            <Text
              textWeight="500"
              variant="span"
              textColor="rgba(12, 15, 74, 0.7)"
              >Connections</Text
            >
          </div>
        </section>

        <!-- trade network link section -->
        <section
          class="tw-w-full tw-rounded-[10px] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-bg-white tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-6"
        >
          <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
              <img
                src="@/assets/category-icons/building.svg"
                class="tw-w-[12px] tw-min-w-[12px] tw-h-[16px]"
              />
              <Text
                variant="h6"
                textAlign="left"
                lineHeight="18px"
                class="md:!tw-text-[.8rem]"
                >Trade Network</Text
              >
            </div>
            <Text
              variant="p"
              textWeight="600"
              textColor="#3366FF"
              whiteSpace="nowrap"
              class="tw-cursor-not-allowed"
              >+Link to Trade Network</Text
            >
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <Text
              variant="p"
              textWeight="500"
              textColor="rgba(12, 15, 74, 0.7)"
              lineHeight="18px"
              textAlign="left"
              >Connect with me via iknowa trade network for future collaboration
              and working together.</Text
            >

            <div class="tw-pt-3 tw-box-border tw-border-t-dashed-custom">
              <Button
                variant="purple-outline"
                label="Connect"
                :isActive="false"
              />
            </div>
          </div>
        </section>

        <!-- rates sections -->
        <section
          v-if="activeUserWorkstation?.dayRate?.minRate"
          class="tw-w-full tw-rounded-[10px] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-bg-white tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-6"
        >
          <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
              <img
                src="@/assets/category-icons/building.svg"
                class="tw-w-[12px] tw-min-w-[12px] tw-h-[16px]"
              />
              <Text variant="h6">Regular Day Rate</Text>
            </div>

            <BlueCircularPencilIcon
              class="tw-cursor-pointer"
              v-if="!isUserProprtyOwner"
              @click="onAddRate"
            />
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <div class="tw-w-full tw-flex tw-items-start tw-flex-col tw-gap-2">
              <Text
                variant="p"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.7)"
                lineHeight="18px"
                textAlign="left"
                >Regular Day fee for the services. Hourly rates will vary based
                on the job complexity.</Text
              >
              <Text variant="h6">{{
                CurrencyFilter.formatToCurrency(
                  activeUserWorkstation?.dayRate?.minRate
                )
              }}</Text>
            </div>

            <div
              v-if="isUserProprtyOwner"
              class="tw-pt-3 tw-box-border tw-border-t-dashed-custom"
            >
              <Button
                variant="purple-outline"
                label="Hire Me"
                :isActive="true"
                :isCapitalize="true"
                v-if="!isUserProprtyOwner"
              />
            </div>
          </div>
        </section>

        <section
          v-if="activeUserWorkstation?.hourlyRate?.minRate"
          class="tw-w-full tw-rounded-[10px] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-bg-white tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-6"
        >
          <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
              <img
                src="@/assets/category-icons/building.svg"
                class="tw-w-[12px] tw-min-w-[12px] tw-h-[16px]"
              />
              <Text variant="h6">Regular Hourly Rate</Text>
            </div>
            <BlueCircularPencilIcon
              class="tw-cursor-pointer"
              @click="onAddRate"
              v-if="!isUserProprtyOwner"
            />
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <div class="tw-w-full tw-flex tw-items-start tw-flex-col tw-gap-2">
              <Text
                variant="p"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.7)"
                lineHeight="18px"
                textAlign="left"
                >Regular Hourly fee for the services. Hourly rates will vary
                based on the job complexity.</Text
              >
              <Text variant="h6">{{
                CurrencyFilter.formatToCurrency(
                  activeUserWorkstation?.hourlyRate?.minRate
                )
              }}</Text>
            </div>

            <div
              v-if="isUserProprtyOwner"
              class="tw-pt-3 tw-box-border tw-border-t-dashed-custom"
            >
              <Button
                variant="purple-outline"
                label="Hire Me"
                :isActive="true"
                :isCapitalize="true"
                v-if="!isUserProprtyOwner"
              />
            </div>
          </div>
        </section>

        <section
          v-if="activeUserWorkstation?.emergencyCallOutFee"
          class="tw-w-full tw-rounded-[10px] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-bg-white tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-6"
        >
          <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
              <img
                src="@/assets/category-icons/building.svg"
                class="tw-w-[12px] tw-min-w-[12px] tw-h-[16px]"
              />
              <Text variant="h6">Emergency call-out</Text>
            </div>
            <BlueCircularPencilIcon
              class="tw-cursor-pointer"
              @click="onAddRate"
              v-if="!isUserProprtyOwner"
            />
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <div class="tw-w-full tw-flex tw-items-start tw-flex-col tw-gap-2">
              <Text
                variant="p"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.7)"
                lineHeight="18px"
                textAlign="left"
                >Emergency call-out fee for the services. Hourly rates will vary
                based on the job complexity.</Text
              >
              <Text variant="h6">{{
                CurrencyFilter.formatToCurrency(
                  activeUserWorkstation?.emergencyCallOutFee
                )
              }}</Text>
            </div>

            <div
              v-if="isUserProprtyOwner"
              class="tw-pt-3 tw-box-border tw-border-t-dashed-custom"
            >
              <Button
                variant="purple-outline"
                label="Hire Me"
                :isActive="true"
                :isCapitalize="true"
                v-if="!isUserProprtyOwner"
              />
            </div>
          </div>
        </section>

        <section
          v-if="activeUserWorkstation?.emergencyCallOutRate"
          class="tw-w-full tw-rounded-[10px] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-bg-white tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-6"
        >
          <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
              <img
                src="@/assets/category-icons/building.svg"
                class="tw-w-[12px] tw-min-w-[12px] tw-h-[16px]"
              />
              <Text variant="h6">Emergency call-out Hourly Rate</Text>
            </div>
            <BlueCircularPencilIcon
              class="tw-cursor-pointer"
              @click="onAddRate"
              v-if="!isUserProprtyOwner"
            />
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <div class="tw-w-full tw-flex tw-items-start tw-flex-col tw-gap-2">
              <Text
                variant="p"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.7)"
                lineHeight="18px"
                textAlign="left"
                >Emergency call-out fee for the services Hourly rate. Hourly
                rates will vary based on the job complexity.</Text
              >
              <Text variant="h6">{{
                CurrencyFilter.formatToCurrency(
                  activeUserWorkstation?.emergencyCallOutRate
                )
              }}</Text>
            </div>

            <div
              v-if="isUserProprtyOwner"
              class="tw-pt-3 tw-box-border tw-border-t-dashed-custom"
            >
              <Button
                variant="purple-outline"
                label="Hire Me"
                :isActive="true"
                :isCapitalize="true"
                v-if="!isUserProprtyOwner"
              />
            </div>
          </div>
        </section>

        <section
          v-if="activeUserWorkstation?.videoConsulationFee"
          class="tw-w-full tw-rounded-[10px] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-bg-white tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-6"
        >
          <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
              <img
                src="@/assets/category-icons/building.svg"
                class="tw-w-[12px] tw-min-w-[12px] tw-h-[16px]"
              />
              <Text variant="h6">Video Consultation Rate</Text>
            </div>
            <BlueCircularPencilIcon
              class="tw-cursor-pointer"
              @click="onAddRate"
              v-if="!isUserProprtyOwner"
            />
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <div class="tw-w-full tw-flex tw-items-start tw-flex-col tw-gap-2">
              <Text
                variant="p"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.7)"
                lineHeight="18px"
                textAlign="left"
                >Video Consultation fee. Hourly rates will vary based on the job
                complexity.</Text
              >
              <Text variant="h6">{{
                CurrencyFilter.formatToCurrency(
                  activeUserWorkstation?.videoConsulationFee
                )
              }}</Text>
            </div>

            <div
              v-if="isUserProprtyOwner"
              class="tw-pt-3 tw-box-border tw-border-t-dashed-custom"
            >
              <Button
                variant="purple-outline"
                label="Hire Me"
                :isActive="true"
                :isCapitalize="true"
                v-if="!isUserProprtyOwner"
              />
            </div>
          </div>
        </section>

        <section
          v-if="activeUserWorkstation?.inpersonVideoFees"
          class="tw-w-full tw-rounded-[10px] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-bg-white tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-6"
        >
          <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
              <img
                src="@/assets/category-icons/building.svg"
                class="tw-w-[12px] tw-min-w-[12px] tw-h-[16px]"
              />
              <Text variant="h6">Video Consultation Rate</Text>
            </div>
            <BlueCircularPencilIcon
              class="tw-cursor-pointer"
              @click="onAddRate"
            />
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
            <div class="tw-w-full tw-flex tw-items-start tw-flex-col tw-gap-2">
              <Text
                variant="p"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.7)"
                lineHeight="18px"
                textAlign="left"
                >In-Person Consultation fee. Hourly rates will vary based on the
                job complexity.</Text
              >
              <Text variant="h6">{{
                CurrencyFilter.formatToCurrency(
                  activeUserWorkstation?.inpersonVideoFees
                )
              }}</Text>
            </div>

            <div
              v-if="isUserProprtyOwner"
              class="tw-pt-3 tw-box-border tw-border-t-dashed-custom"
            >
              <Button
                variant="purple-outline"
                label="Hire Me"
                :isActive="true"
                :isCapitalize="true"
                v-if="!isUserProprtyOwner"
              />
            </div>
          </div>
        </section>

        <!-- hire me section here -->
      </div>
    </template>
  </TradePassportCardSlot>
</template>
<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import TradePassportCardSlot from "../slots/TradePassportCardSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import UnverifiedBadge from "@/core/components/common/UnverifiedBadge.vue";
import VerifiedBadge from "@/core/components/common/VerifiedBadge.vue";
import IdNotVerifiedBadge from "@/core/components/common/IdNotVerifiedBadge.vue";
import { USER_STORE } from "@/store/modules/user";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { WORKSTATION } from "@/store/modules/workstation";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { getCombineSameProjectReview } from "@/core/utils/common";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import { reviewRatingState } from "@/core/models/project-rating";
import { TRADE_PASSPOSRT_ROUTE } from "../../routes";
import {
  BusinessDetailTypeEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";
import CurrencyFilter from "@/filters/currency.filter";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";

const emits = defineEmits(["on-add-rates"]);
const props = defineProps([
  "contractorworkStationImage",
  "userWorkStationImage",
  "userServices",
  "userWorkStationBanner",
]);

const store = useStore();
const router = useRouter();

const isLoading = ref(false);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const activeUserWorkstation = computed(() => {
  if (isUserProprtyOwner.value) {
    return store.getters[
      `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`
    ];
  } else {
    return store.getters[`${WORKSTATION}/activeUserWorkstation`];
  }
});

const getDynamicRateLabel = computed(() => {
  const workstation = activeUserWorkstation.value;
  if (!workstation) return "Add Rates";

  const requiredFields = [
    "inpersonVideoFees",
    "videoConsulationFee",
    "emergencyCallOutRate",
    "emergencyCallOutFee",
    "dayRate",
    "hourlyRate",
  ];

  const isTrue = requiredFields.every((field) => workstation[field]);

  return isTrue ? "Edit Rates" : " + Add Rates";
});

const getAllProjectReviewList = computed<
  reviewRatingState["allProjectReviewList"]
>(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);

const combineSameProjectReview = computed(() =>
  getCombineSameProjectReview(getAllProjectReviewList.value)
);
const getAverageOfAllProjectReview = computed(() => {
  const totalAvg = combineSameProjectReview.value.reduce(
    (totalRating: number, review: any) => {
      totalRating += review.totalAvg || 0;
      return totalRating;
    },
    0
  );
  return totalAvg
    ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) / 100
    : totalAvg;
});

const userBusinessType = computed(() => user.value?.userBusinessDetails?.type);

const WorkStationSiltVerificationStatus = computed(
  () => store.getters[`${WORKSTATION}/getWorkStationSiltVerificationStatus`]
);

const userWorkStationStatus = computed(
  () =>
    store.getters[`${WORKSTATION}/activeUserWorkstation`]?.verificationStatus
);

const getLinkData = computed(() => {
  return activeUserWorkstation.value?.workstationLinkTree;
});

const onOpenQr = () => {
  window.open(getLinkData.value?.url, "_blank");
};

const onAddRate = () => {
  emits("on-add-rates");
};

const workstationPostcode = (type: string) => {
  if (activeUserWorkstation.value?.user) {
    return type === BusinessDetailTypeEnum.LIMITED_COMPANY
      ? activeUserWorkstation.value?.user?.userBusinessDetails?.businessPostcode
      : activeUserWorkstation.value?.user?.userBusinessDetails
          ?.residencyPostcode;
  } else {
    return "N/A";
  }
};

const workstationAddress = (type: string) => {
  if (activeUserWorkstation.value?.user) {
    let address;
    if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
      const businessAddress =
        activeUserWorkstation.value?.user?.userBusinessDetails?.businessAddress?.replace(
          activeUserWorkstation.value?.user?.userBusinessDetails
            ?.businessPostcode,
          ""
        );
      address = businessAddress?.replace(/,([^,]*)$/, ".$1");
    } else if (
      activeUserWorkstation.value?.user?.userBusinessDetails
        ?.residencyAddressLookup
    ) {
      const residencyAddressLookup =
        activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLookup?.replace(
          activeUserWorkstation.value?.user?.userBusinessDetails
            ?.residencyPostcode,
          ""
        );
      address = residencyAddressLookup?.replace(/,([^,]*)$/, ".$1");
    } else {
      address = `${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLine} ${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyTown}`;
    }
    return address;
  } else {
    return "N/A";
  }
};

const onClickViewRatings = () => {
  store.dispatch(`${REVIEW_RATING_STORE}/setTradePassportRedirect`, "Reviews");
  router.push({ name: TRADE_PASSPOSRT_ROUTE });
};

onMounted(async () => {
  try {
    isLoading.value = true;
    await store.dispatch(
      `${REVIEW_RATING_STORE}/getAllProjectReviewList`,
      ProjectRatingTypeEnum.TRADER_OWNER
    );
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
});
</script>
<style lang="scss" scoped>
.view-rating-box {
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  flex-wrap: wrap;

  .v-rating {
    :deep(.v-rating__wrapper) {
      .v-rating__item {
        .v-btn {
          width: auto;
          height: auto;
        }
      }
    }
  }
}

.service-badge {
  @apply tw-w-auto tw-py-[.3rem] tw-px-[.5rem] tw-box-border tw-border-solid tw-border-[#DFDFDF] tw-border-[2px] tw-rounded-lg tw-flex tw-items-center tw-gap-2;
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
}

.white-svg {
  filter: brightness(0) invert(1);
}
</style>
