import authentication from "./authentication";
import user from "./user";
import courses from "./courses";
import workstation from "./workstation";
import insurance from "./insurance";
import jobs from "./jobs";
import project from "./project";
import tradeNetwork from "./trade-network";
import certificates from "./certificates";
import chatMessage from "./chat-message";
import jobTemplate from "./job-template";
import projectQuotes from "./project-quotes";
import documents from "./documents";
import billing from "./billing";
import attachmentCache from "./attachment-cache";
import calendar from "./calendar";
import manageMember from "./manage-member";
import tradeEstimate from "./trade-estimate";
import workstationSetting from "./workstation-setting";
import property from "./property";
import preferredContractors from "./preferred-contractors";
import connections from "./connections";
import eWallet from "./e-wallet";
import geminiFlash from "./gemini-flash";
import auditLogs from "./audit-logs";
import reviewRating from "./review-rating";
import analytics from "./analytics";
import nwgJob from "./nwg-job";

export default {
  authentication,
  user,
  courses,
  workstation,
  insurance,
  jobs,
  project,
  tradeNetwork,
  certificates,
  chatMessage,
  jobTemplate,
  projectQuotes,
  documents,
  billing,
  attachmentCache,
  calendar,
  manageMember,
  tradeEstimate,
  workstationSetting,
  property,
  preferredContractors,
  connections,
  eWallet,
  geminiFlash,
  auditLogs,
  reviewRating,
  analytics,
  nwgJob,
};
