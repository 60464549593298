import estimate from "@/core/constants/estimate";
import { getRandomColor } from "@/core/utils/common";
import _ from "lodash";

export default {
  setEstimateForm(
    state: {
      estimateForm: { projectStages: any[] };
      defaultProjectStageForm: any;
    },
    value: any
  ) {
    if (value) {
      const generateUniqueId = () => {
        return `phase_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
      };

      const updatedProjectStages = value.projectStages.map((stage: any) => ({
        ...stage,
        phaseUniqueId: stage.id || stage.phaseUniqueId || generateUniqueId(),
      }));

      state.estimateForm = {
        ...JSON.parse(JSON.stringify(value)),
        projectStages: updatedProjectStages,
      };
    } else {
      state.estimateForm.projectStages = [
        JSON.parse(JSON.stringify(state.defaultProjectStageForm)),
      ];
    }
  },

  setDummyEstimateForm(
    state: {
      dummyEstimateForm: any;
    },
    value: any
  ) {
    if (value) {
      state.dummyEstimateForm = JSON.parse(JSON.stringify(value));
    }
  },

  setSelectedPhaseIndex(state: { selectedPhaseIndex: any }, value: any) {
    state.selectedPhaseIndex = value;
  },

  setTemplateName(state: { templateName: any }, value: any) {
    state.templateName = value;
  },

  addProjectStageMutation(
    state: { estimateForm: { projectStages: any[] } },
    newProjectStage: any
  ) {
    state.estimateForm.projectStages.push({
      ...newProjectStage,
      phaseOrder: state.estimateForm.projectStages.length,
    });
  },

  initializeIBGPolicy(
    state: { estimateForm: { estimationIBGData: any } },
    newProjectStage: any
  ) {
    state.estimateForm["estimationIBGData"] = newProjectStage;
  },
  addIBGProjectPhase(
    state: { estimateForm: { estimationIBGData: any } },
    payload: { data: any; policyIndex: number }
  ) {
    if (
      !state.estimateForm?.estimationIBGData?.estimationIbgPolicies[
        payload.policyIndex
      ]?.phases?.length
    ) {
      state.estimateForm.estimationIBGData.estimationIbgPolicies[
        payload.policyIndex
      ]["phases"] = [payload.data];
    } else {
      state.estimateForm.estimationIBGData.estimationIbgPolicies[
        payload.policyIndex
      ].phases.push(payload.data);
    }
  },
  setShowPhaseUploadAttachmentModal(
    state: { showPhaseUploadAttachmentModal: any },
    value: any
  ) {
    state.showPhaseUploadAttachmentModal = value;
  },

  setShowTaskUploadAttachmentModal(
    state: { showTaskUploadAttachmentModal: any },
    value: any
  ) {
    state.showTaskUploadAttachmentModal = value;
  },

  setShowCreateTemplateConfirmationModal(
    state: { showCreateTemplateConfirmationModal: any },
    value: any
  ) {
    state.showCreateTemplateConfirmationModal = value;
  },

  setShowUseTemplateModal(state: { showUseTemplateModal: any }, value: any) {
    state.showUseTemplateModal = value;
  },

  setShowSubmitEstimateConfirmationModal(
    state: { showSubmitEstimateConfirmationModal: any },
    value: any
  ) {
    state.showSubmitEstimateConfirmationModal = value;
  },

  setSelectedJobTemplate(state: { selectedJobTemplate: any }, value: any) {
    state.selectedJobTemplate = value;
  },

  setIsOnEstimateForm(state: { isOnEstimateForm: any }, value: any) {
    state.isOnEstimateForm = value;
  },

  setConfirmDeleteModal(state: { confirmDeleteModal: any }, value: any) {
    state.confirmDeleteModal = value;
  },

  setIsFormReady(state: { isFormReady: any }, value: any) {
    state.isFormReady = value;
  },

  setConfirmLoadTemplateModal(
    state: { confirmLoadTemplateModal: any },
    value: any
  ) {
    state.confirmLoadTemplateModal = value;
  },

  setSelectedTemplateId(state: { selectedTemplateId: any }, value: any) {
    state.selectedTemplateId = value;
  },

  setFormData(state: { formData: any }, value: any) {
    state.formData = value;
  },

  setSelectDepositModal(state: { selectDepositModal: any }, value: any) {
    state.selectDepositModal = value;
  },

  setIsTemplateCreate(state: { isTemplateCreate: any }, value: any) {
    state.isTemplateCreate = value;
  },

  setIsEstimateSubmit(state: { isEstimateSubmit: any }, value: any) {
    state.isEstimateSubmit = value;
  },

  setIsEstimateLoadedModified(
    state: { isEstimateLoadedModified: any },
    value: any
  ) {
    state.isEstimateLoadedModified = value;
  },

  setHasLoadedTemplate(state: { hasLoadedTemplate: any }, value: any) {
    state.hasLoadedTemplate = value;
  },

  setLoadedEstimate(state: { loadedEstimate: any }, value: any) {
    state.loadedEstimate = value;
  },

  setIsFormReadOnly(state: { isFormReadOnly: any }, value: any) {
    state.isFormReadOnly = value;
  },

  setLockTemplateModifiedChecker(
    state: { lockTemplateModifiedChecker: any },
    value: any
  ) {
    state.lockTemplateModifiedChecker = value;
  },

  setSelectedAssignMembers(state: { selectedAssignMembers: any }, value: any) {
    state.selectedAssignMembers = value;
  },

  setAssignMembersModal(state: { assignMembersModal: any }, value: any) {
    state.assignMembersModal = value;
  },

  setAssignMembers(state: { assignMembers: any }, value: any) {
    state.assignMembers = value;
  },

  setInvitedContractorsList(
    state: { invitedContractorsList: any },
    value: any
  ) {
    state.invitedContractorsList = value;
  },

  setSubContractorsPhases(state: any, value: any) {
    state.subContractorsPhases = value;
  },

  setparentContractorPhases(state: any, value: any) {
    state.parentContractorPhases = value;
  },

  setShowUpdateEstimateConfirmationModal(
    state: { showUpdateEstimateConfirmationModal: any },
    value: any
  ) {
    state.showUpdateEstimateConfirmationModal = value;
  },

  setTotalPhases(state: any, value: any) {
    state.totalPhases = value;
  },

  setEstimatePhaseCost(state: { estimateForm: any }, value: any) {
    const { phaseIndex, newValue } = value;
    state.estimateForm.projectStages[phaseIndex].stageCost =
      parseFloat(newValue);
  },

  setEstimatePhaseSubTotal(state: { estimateForm: any }, value: any) {
    const { phaseIndex, newValue } = value;
    state.estimateForm.projectStages[phaseIndex].subTotal = newValue.toString();
  },
  setEstimatePhaseDate(state: { estimateForm: any }, value: any) {
    state.estimateForm.projectStages = state.estimateForm.projectStages.map(
      (phase: any) => {
        return {
          ...phase,
          startDate:
            value && phase.startDate
              ? new Date(value) <= new Date(phase.startDate)
                ? phase.startDate
                : null
              : null,
        };
      }
    );
  },
  setEstimatePhase(state: { estimateForm: any }, value: any) {
    const { phaseIndex, field, newValue } = value;

    state.estimateForm.projectStages[phaseIndex][field] = newValue;
  },
  setPhaseTaskProperties(state: { estimateForm: any }, value: any) {
    const { phaseIndex, itemIndex, taskProperties } = value;

    state.estimateForm.projectStages[phaseIndex].projectStageTasks[
      itemIndex
    ].estimationPhaseTasksProperties = taskProperties;
  },

  setPhaseProperties(state: { estimateForm: any }, value: any) {
    const { phaseIndex, phaseProperties } = value;
    state.estimateForm.projectStages[phaseIndex].estimationPhaseProperties =
      phaseProperties;
  },
  setPhaseAttachments(state: { estimateForm: any }, value: any) {
    const { phaseIndex, attachments } = value;
    state.estimateForm.projectStages[phaseIndex].attachments = attachments;
  },

  setProjectStage(state: any, value: any) {
    const { phaseIndex, defaultProjectStageFormValue } = value;
    state.estimateForm.projectStages[phaseIndex] = defaultProjectStageFormValue;
  },
  setSubContractor(state: { isSubContractor: boolean }, value: any) {
    state.isSubContractor = value;
  },

  setProjectEstimationInvitation(
    state: { projectEstimationInvitation: any },
    value: any
  ) {
    state.projectEstimationInvitation = value;
  },
  setSubmitProjectEstimation(
    state: { isSubmitProjectEstimation: any },
    value: any
  ) {
    state.isSubmitProjectEstimation = value;
  },

  setError(
    state: any,
    {
      phaseIndex,
      subTaskIndex,
      field,
      message,
    }: {
      phaseIndex: number;
      subTaskIndex: number;
      field: string;
      message: string;
    }
  ) {
    if (!state.errors[phaseIndex]) {
      state.errors[phaseIndex] = { subErrors: {} };
    }
    if (typeof subTaskIndex === "undefined")
      state.errors[phaseIndex][field] = message;
    else {
      if (!state.errors[phaseIndex].subErrors[subTaskIndex]) {
        state.errors[phaseIndex].subErrors[subTaskIndex] = {};
      }
      state.errors[phaseIndex].subErrors[subTaskIndex][field] = message;
    }
  },
  clearErrors(state: any, { phaseIndex, subTaskIndex, field }: any) {
    if (typeof subTaskIndex === "undefined") {
      if (state.errors[phaseIndex]) {
        delete state.errors[phaseIndex][field];
      }
    } else {
      if (
        state.errors[phaseIndex] &&
        state.errors[phaseIndex].subErrors[subTaskIndex]
      ) {
        delete state.errors[phaseIndex].subErrors[subTaskIndex][field];
      }
    }
  },
  setIBGError(
    state: any,
    {
      policyIndex,
      phaseIndex,
      subTaskIndex,
      field,
      message,
    }: {
      policyIndex: number;
      phaseIndex: number;
      subTaskIndex: number;
      field: string;
      message: string;
    }
  ) {
    if (!state.ibgErrors[policyIndex]) {
      state.ibgErrors[policyIndex] = [{ subErrors: {} }];
    }
    if (!state.ibgErrors[policyIndex][phaseIndex]) {
      state.ibgErrors[policyIndex][phaseIndex] = { subErrors: {} };
    }
    if (typeof subTaskIndex === "undefined")
      state.ibgErrors[policyIndex][phaseIndex][field] = message;
    else {
      if (!state.ibgErrors[policyIndex][phaseIndex].subErrors[subTaskIndex]) {
        state.ibgErrors[policyIndex][phaseIndex].subErrors[subTaskIndex] = {};
      }
      state.ibgErrors[policyIndex][phaseIndex].subErrors[subTaskIndex][field] =
        message;
    }
  },

  clearIBGErrors(
    state: any,
    { policyIndex, phaseIndex, subTaskIndex, field }: any
  ) {
    if (typeof subTaskIndex === "undefined") {
      if (
        state.ibgErrors[policyIndex] &&
        state.ibgErrors[policyIndex][phaseIndex]
      ) {
        delete state.ibgErrors[policyIndex][phaseIndex][field];
      }
    } else {
      if (
        state.ibgErrors[policyIndex] &&
        state.ibgErrors[policyIndex][phaseIndex] &&
        state.ibgErrors[policyIndex][phaseIndex].subErrors[subTaskIndex]
      ) {
        delete state.ibgErrors[policyIndex][phaseIndex].subErrors[subTaskIndex][
          field
        ];
      }
    }
  },

  clearAllErrors(state: any) {
    state.errors = { subErrors: {} };
    state.isFormErrors = {
      startDate: false,
      validationError: false,
      projectStageDate: false,
    };
  },

  setFormError(state: { isFormErrors: any }, { field, value }: any) {
    state.isFormErrors[field] = value;
  },
  setEstimateResponse(state: any, value: any) {
    state.estimateResponse = value;
  },
  setJobsTemplates(state: any, payload: any) {
    state.jobTemplates = payload;
  },
  setEstimatePhaseNotes(state: any, payload: { phaseId: number; data: any }) {
    state.estimatePhaseNote[payload.phaseId] = payload.data;
  },
  setUpdatedEstimatePhaseNotes(
    state: any,
    payload: { phaseId: number; data: any }
  ) {
    state.updatedEstimatePhaseNote[payload.phaseId] = payload.data;
  },
  setUpdatedEstimatePhaseNotesGlobal(state: any, payload: any) {
    state.updatedEstimatePhaseNote = payload;
  },
  setPhaseTasks(state: any, payload: any) {
    state.estimateForm.projectStages[payload.phaseIndex].projectStageTasks =
      payload.projectStageTasks;
  },
  setHoldEstimateAddTaskApi(state: any, payload: any) {
    state.holdEstimateAddTaskApi = payload;
  },
  setSuggestedStartDateIntially(state: any) {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    state.formData.suggestedStartDate = formattedDate;
  },

  dragUpdateProjectStages(state: any, updatedPhasess: any) {
    state.estimateForm.projectStages = updatedPhasess;
  },
  clearAllEstimateData(state: any) {
    state.estimateForm = {
      projectStages: [
        {
          id: null,
          isNew: true,
          name: "",
          stageType: "labour",
          totalDuration: 0,
          durationType: 1,
          stageCost: 0,
          projectStageTasks: [],
          requiredDeposit: false,
          vat: 0,
          subTotal: "0",
          subTotalDisplay: "",
          note: "",
          startDate: null,
          phaseOrder: 0,
          phaseStatus: null,
          endDate: null,
          estimationPhaseProperties: [],
          color: getRandomColor(),
          attachments: [],
          user: null,
          workStation: null,
        },
      ],
    };
    state.formData = {
      suggestedStartDate: null,
      grantType: null,
      grantName: "",
      grantAmount: 0,
      project: null,
      id: null,
    };
  },
  clearJobTemplateResponse(state: any, rootState: any) {
    rootState.jobTemplate.estimateResponse = {};
  },
  setChangeJobRequest(state: any, values: any) {
    state.changeEstimateRequest.data = values;
    state.changeEstimateRequest.isUpdate = true;
  },
  setChangeJobRequestUpdate(state: any, values: any) {
    state.changeEstimateRequest.isUpdate = values;
  },
  resetChangeJobRequestUpdate(state: any, values: any) {
    state.changeEstimateRequest.isUpdate = false;
    state.changeEstimateRequest.data = null;
  },
  createEstimatePhaseNotesDataLocally(state: any, values: any) {
    state.updatedEstimatePhaseNote = values;
  },

  setDefaultEstimateFormDetails(state: any, values: any) {
    state.defaultEstimateDetails = _.cloneDeep(values);
  },

  setResetPhaseAttachmentLocally(state: any, values: any) {
    state.phaseAttachmentLocally = {};
  },

  setdeletephaseattachmentlocally(state: any, payload: any) {
    state.phaseAttachmentLocally[payload.phaseId] =
      state.phaseAttachmentLocally[payload.phaseId].filter(
        (el: any) => el.id !== payload.id
      );
  },
  setCreateLocalattachment(state: any, payload: any) {
    // Store attachments per `estimatePhaseId`

    if (!state.phaseAttachmentLocally[payload.estimatePhaseId]) {
      state.phaseAttachmentLocally[payload.estimatePhaseId] = [];
    }
    const attachment = payload.attachments[0];
    state.phaseAttachmentLocally[payload.estimatePhaseId].push({
      id: attachment?.id ? attachment.id : Date.now(),
      attachment: attachment.attachment,
      originalName: attachment.originalName,
      properties: attachment.properties.map((property: any) => ({
        propertyId: property.propertyId,
        isDeleted: property.isDeleted ?? false,
      })),
      taskId: attachment?.taskId || null,
      phaseId: payload.estimatePhaseId,
      isDeleted: attachment.isDeleted,
      isNew: attachment?.isNew,
    });
  },

  setupdateEstimatephaseattachmentLocally(state: any, payload: any) {
    if (!state.phaseAttachmentLocally) {
      state.phaseAttachmentLocally = {};
    }

    const existingAttachments =
      state.phaseAttachmentLocally[payload.estimatePhaseId];

    payload.attachments.forEach((newAttachment: any) => {
      const existingIndex = existingAttachments.findIndex(
        (attachment: any) => attachment.id === newAttachment.id
      );

      const updatedAttachment = {
        id: newAttachment?.id,
        attachment: newAttachment?.attachment,
        originalName: newAttachment?.originalName,
        properties: newAttachment?.properties.map((property: any) => ({
          propertyId: property?.propertyId,
          isDeleted: property?.isDeleted ?? false,
        })),
        taskId: newAttachment?.taskId || null,
        phaseId: payload?.estimatePhaseId,
        isDeleted: newAttachment?.isDeleted ?? false,
      };

      if (existingIndex !== -1) {
        existingAttachments[existingIndex] = updatedAttachment;
      } else {
        existingAttachments.push(updatedAttachment);
      }
    });
  },

  setAssignPhaseTeamMembers(state: any, payload: any) {
    const { phase, assignTeamMembers } = payload;
    const phaseStage = state.estimateForm.projectStages.find(
      (stage: any) => stage.id === phase.id
    );

    if (phaseStage) {
      phaseStage.assignTeamMembers = assignTeamMembers;
    }
  },
  setIbgPolicyList(state: any, payload: any) {
    state.ibgPolicyList = payload || [];
  },
};
