<template>
  <GeneralDialog
    headerTitle="Tutorial"
    class="tw-max-w-[450px]"
    @on-close="onCloseModal"
  >
    <template #body>
      <div>
        <img class="tw-w-full tw-h-full" :src="data[stepper].imgSrc" />
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <Text variant="h4" textAlign="center" lineHeight="28px">
            {{ data[stepper].title }}
          </Text>
          <Text
            class="tw-max-w-[80%] tw-mt-6"
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            textAlign="center"
          >
            {{ data[stepper].description }}
          </Text>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button variant="secondary" label="previous" @click="stepper--" />
        <Button
          variant="primary"
          :label="data.length === stepper ? 'Done' : 'Next'"
          @click="data.length === stepper ? onCloseModal() : stepper++"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/uni-components/Button.vue";
import Text from "@/core/uni-components/Text.vue";
import fixedCostEstimationImage from "@/core/uni-assets/images/fixed-cost-estimation.png";
import ganttChartImage from "@/core/uni-assets/images/gantt-chart.png";
import initialEstimationImage from "@/core/uni-assets/images/initial-estimation.png";
import requestSiteVisitImage from "@/core/uni-assets/images/request-site-visit.png";
import variableEstimationImage from "@/core/uni-assets/images/variable-estimation.png";
import { ref } from "vue";
const emits = defineEmits(["on-close"]);

const onCloseModal = () => {
  emits("on-close");
};

const stepper = ref(0);

const data = [
  {
    imgSrc: initialEstimationImage,
    title: "Initial Estimate",
    description: "",
  },
  {
    imgSrc: variableEstimationImage,
    title: "Variable Cost Estimate",
    description: "",
  },
  {
    imgSrc: fixedCostEstimationImage,
    title: "Fixed Cost Estimate",
    description: "",
  },
  {
    imgSrc: ganttChartImage,
    title: "Schedule",
    description: "",
  },
  {
    imgSrc: requestSiteVisitImage,
    title: "Site Visit",
    description: "",
  },
];
</script>
