<template>
  <div
    class="review-card !tw-bg-[#F8FBFD] !tw-border-solid !tw-border-[#DFDFDF] !tw-border-[1px]"
  >
    <div class="review-card__header">
      <div class="review-card__header-top">
        <div class="review-card__image">
          <img src="@/assets/images/review-card-1.png" alt="" />
        </div>
        <div class="review-card__header-content">
          <h2>{{ reviewData?.project?.name }}</h2>
        </div>
      </div>
      <div class="review-card__rating-wrapper">
        <div class="review-card__rating">
          <v-rating
            :model-value="reviewData.totalAvg"
            active-color="orange"
            color="rgba(12, 15, 74, 0.26)"
            empty-icon="mdi-star"
            readonly
          ></v-rating>
          <p>{{ reviewData.totalAvg }} out of 5</p>
        </div>

        <div class="review-card__notification-wrapper">
          <v-btn
            class="review-card__notification"
            height="44px"
            width="44px"
            variant="plain"
            @click="onClickReviewChat"
          >
            <img src="@/assets/icons/chat-dark.svg" alt="" />
          </v-btn>

          <div class="review-card__notification-badge">
            <p>{{ reviewData?.comments?.length || 0 }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="review-card__footer">
      <button
        type="button"
        class="review-card__toggle"
        @click="onClickViewDetail"
      >
        <span>View Details</span>
        <i class="mdi mdi-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";
const emits = defineEmits(["onViewDetail", "onViewComment"]);
const props = defineProps(["reviewData"]);

const onClickViewDetail = () => {
  emits("onViewDetail");
};
const onClickReviewChat = () => {
  emits("onViewComment");
};
</script>
<script>
export default {
  name: "ProjectReviewCard",
};
</script>

<style lang="scss" scoped>
.review-card {
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  color: $blueDark;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 20px;
  }

  &__header {
    margin-bottom: 22px;

    &-top {
      display: grid;
      grid-template-columns: 60px 1fr;
      grid-gap: 12px 16px;
      align-items: flex-start;

      @media (max-width: 767px) {
        grid-template-columns: 50px 1fr;
      }
    }

    h2 {
      @include fluidFont(20, 16, 25px);
      letter-spacing: 0.25px;
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 1;
    position: relative;
    border-radius: 100%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__rating {
    background-color: #f3f3f6;
    border-radius: 6px;
    padding: 10px 14px;
    @include fluidFont(16, 14, 20px);
    font-weight: 700;
    letter-spacing: 0.12px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-self: flex-start;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      grid-column: span 2;
      padding: 8px 10px;
    }

    :deep(.v-btn) {
      width: 20px;
      height: 20px;
      --v-btn-size: 12px;
    }

    &-wrapper {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  &__notification {
    opacity: 1;
    background: #f3f3f6;
    min-width: 40px;
    border-radius: 6px;

    @media (max-width: 767px) {
      min-width: 38px;
      height: 38px !important;
      width: 38px !important;
    }

    &-badge {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 20px;
      min-height: 20px;
      border-radius: 100%;
      color: #fff;
      background: rgba(255, 165, 0, 1);
      font-size: 12px;
      line-height: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      pointer-events: none;

      @media (max-width: 767px) {
        font-size: 10px;
        min-width: 18px;
        min-height: 18px;
        line-height: 18px;
      }
    }

    &-wrapper {
      position: relative;
      flex-shrink: 1;
      margin-left: auto;
    }
  }

  &__footer {
    padding-top: 12px;
    border-top: 0.66px dashed #8687a5;
    text-align: right;
    margin-top: auto;
  }

  &__toggle {
    @include fluidFont(14, 14, 24px);
    letter-spacing: 0.15px;
    color: #4b4bff;
    display: inline-flex;
    align-items: center;
    gap: 5px;

    .mdi {
      font-size: 24px;
    }
  }
}
</style>
