<template>
  <GeneralDialog
    class="dispute-evidence-modal !tw-gap-0 !tw-z-[2000]"
    @on-close="onClickBackButton"
    maxWidth="100%"
    width="588px"
    borderRadius="8px"
    :isShowCloseButton="true"
    :isShowHeader="true"
    :isTitleStrong="false"
    :headerTitle="
      props.experienceModalType === 'add' ? 'Add Experience' : 'Edit Experience'
    "
    headerDescription="Upload Experience Logo in PDF, JPG, or PNG format."
    headerDescriptionTextSize="span"
    headerDescriptionLineHeight="18px"
    gap="0"
  >
    <template #body>
      <div
        class="modal-overlay tw-mt-4 tw-pt-4 tw-box-border tw-border-t-solid-custom"
      >
        <div class="modal-container">
          <v-form ref="formRef" @submit.prevent :rules="validation">
            <div class="modal-body">
              <!-- evidence image upload -->
              <!-- <div class="modal-evidence-upload modal-content">
                      <label>Banner</label>
                    </div> -->
              <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-start tw-pb-4"
              >
                <div class="tw-flex tw-flex-col tw-gap-1 tw-items-start">
                  <Text variant="span" lineHeight="18px" textWeight="400"
                    >Company Name</Text
                  >
                </div>
                <InputField
                  class="!tw-w-full custom-selector"
                  v-model="companyName"
                  :rules="validation.companyName"
                  placeholder="Enter Company Name"
                ></InputField>
              </div>
              <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-start tw-pb-4"
              >
                <div class="tw-flex tw-flex-col tw-gap-1 tw-items-start">
                  <Text variant="span" lineHeight="18px" textWeight="400"
                    >Title</Text
                  >
                </div>
                <InputField
                  class="!tw-w-full custom-selector"
                  v-model="title"
                  :rules="validation.title"
                  placeholder="Enter Title"
                ></InputField>
              </div>
              <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-start tw-pb-4"
              >
                <div class="tw-flex tw-flex-col tw-gap-1 tw-items-start">
                  <Text variant="span" lineHeight="18px" textWeight="400"
                    >Start Date</Text
                  >
                </div>
                <el-date-picker
                  class="tw-min-h-[2.625rem] tw-max-h-[2.625rem] !tw-w-full"
                  type="date"
                  placeholder="Select Start Date"
                  value-format="DD-MM-YYYY"
                  :format="DATE_FORMAT"
                  v-model="startDate"
                  :rules="validation.startDate"
                />
              </div>
              <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-start tw-pb-4"
              >
                <div class="tw-flex tw-flex-col tw-gap-1 tw-items-start">
                  <Text variant="span" lineHeight="18px" textWeight="400"
                    >End Date</Text
                  >
                </div>
                <el-date-picker
                  class="tw-min-h-[2.625rem] tw-max-h-[2.625rem] !tw-w-full"
                  type="date"
                  placeholder="Select Start Date"
                  value-format="DD-MM-YYYY"
                  :format="DATE_FORMAT"
                  v-model="endDate"
                  :disabled="isPresent"
                />
              </div>
              <v-checkbox
                v-model="isPresent"
                color="primary"
                label="Currently Working Here"
              ></v-checkbox>

              <!-- ATTACHMENTS -->
              <!-- <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-0 tw-items-start"
              >
                <div class="tw-flex tw-flex-col tw-gap-0 tw-items-start">
                  <Text variant="span" lineHeight="18px" textWeight="400"
                    >Upload Company Logo</Text
                  >
                </div>
                <div
                  class="tw-mt-2 tw-mb-4 tw-w-full tw-h-auto tw-flex tw-flex-col tw-gap-4 tw-p-3 tw-box-border tw-border-[1px] tw-border-[#DFDFDF] tw-border-solid tw-rounded-[8px] tw-bg-[#F8FBFD]"
                >
                  <div class="upload__section md:!tw-h-[100px]">
                    <div
                      class="uploade-wrapper custom-file-upload !tw-h-[97px] md:!tw-h-full !tw-w-full !tw-mt-4 !tw-rounded-lg !tw-mb-4"
                    >
                      <v-file-input
                        class="upload_file !tw-mb-0 !tw-bg-white"
                        label="Upload images & files"
                        variant="solo-filled"
                        v-model="selectedAttachments"
                        :rules="validation.attachments"
                        accept=".jpg, .jpeg, .png"
                        :class="{
                          'added-img':
                            selectedAttachments && selectedAttachments?.length,
                        }"
                        @change="onFileChangeAttachments"
                        prepend-icon="mdi-file-image-box"
                      >
                      </v-file-input>
                    </div>
                  </div>
                  <div
                    class="attachment-list !tw-mt-0"
                    v-if="selectedAttachments && selectedAttachments?.length"
                  >
                    <div
                      v-for="(file, index) in selectedAttachments"
                      :key="index"
                      class="attachment-item !tw-bg-none !tw-p-0 !tw-border-none"
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <PdfIcon
                          class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                          v-if="file.type === 'application/pdf'"
                        />
                        <ImageIcon
                          class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                          v-else
                        />
                        <Text variant="p" textWeight="400">{{
                          file.name
                        }}</Text>
                      </div>
                      <v-icon
                        class="cursor-pointer !tw-text-[16px]"
                        icon="mdi-close"
                        @click="removeAttachment(index)"
                      ></v-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tw-w-full tw-flex tw-items-start tw-justify-between tw-pb-4 tw-gap-4"
              >
                <div
                  class="tw-flex tw-flex-col tw-items-start tw-gap-0 rmd:!tw-gap-1"
                >
                  <Text
                    variant="xsmall"
                    class="rmd:!tw-text-[14px]"
                    textColor="rgba(12, 15, 74, 0.5)"
                    textWeight="500"
                    lineHeight="18px"
                    >Supported Formats for Attachments: JPG, PNG
                  </Text>
                </div>
                <Text
                  variant="xsmall"
                  class="rmd:!tw-text-[14px]"
                  textColor="rgba(12, 15, 74, 0.5)"
                  textWeight="500"
                  lineHeight="18px"
                  >Maximum File Size: 2MB
                </Text>
              </div> -->
              <!-- File Upload Progress -->
              <!-- <div v-if="isFileUploading" class="w-full sm:w-80 tw-py-4">
                <v-progress-linear
                  :model-value="uploadProgress"
                  :height="10"
                  :width="5"
                  striped
                  rounded
                  color="#0C0F4A"
                ></v-progress-linear>
                {{ uploadProgress }} %<span>&nbsp; Uploading logo...</span>
              </div> -->
            </div>
            <div class="modal-footer">
              <v-btn
                class="button button-purple-border"
                @click="onClickBackButton"
                :disabled="isUploadImg"
              >
                Cancel
              </v-btn>
              <v-btn
                class="button button-purple"
                @click="onSubmit"
                :loading="isUploadImg"
              >
                {{
                  props.experienceModalType === "add"
                    ? "Submit Experience"
                    : "Update"
                }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import moment from "moment";
import { ref, computed, onMounted, watch } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
const store = useStore();
import PdfIcon from "@/core/components/icons/PdfIcon.vue";
import ImageIcon from "@/core/components/icons/ImageIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import InputField from "@/core/components/common/InputField.vue";
import { DATE_FORMAT } from "@/core/constants";
import { end } from "@popperjs/core";

const emits = defineEmits(["on-close", "on-submit", "on-success"]);
const props = defineProps(["experienceModalType", "selectedExperienceData"]);

const internalInstance = getCurrentInstance();
const formRef = ref<any>(null);
const isUploadImg = ref<boolean>(false);

const validation = ref({
  companyName: [(v: string) => !!v || "Enter Company Name"],
  title: [(v: string) => !!v || "Enter Title"],
  startDate: [(v: string) => !!v || "Enter Start Date"],
  //   attachments: [
  //     (v: any[]) => v.length > 0 || "At least one attachment is required",
  //   ],
});

const companyName = ref("");
const title = ref("");
const startDate = ref("");
const endDate = ref(null) as any;
const isPresent = ref(false);
const selectedAttachments = ref<any[]>([]);

const isFileUploading = ref(false);
const attachmentError = ref("");
const uploadProgress = ref(0);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const onFileChangeAttachments = async (event: any) => {
  try {
    isFileUploading.value = true;
    attachmentError.value = "";
    uploadProgress.value = 0;

    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const files = Array.from(fileInput.files);
      let progressInterval: any;

      // Add new files if they don't already exist
      files.forEach((file: any) => {
        if (!selectedAttachments.value.some((doc) => doc.name === file.name)) {
          selectedAttachments.value.push(file);
        }
      });

      // Ensure there's at least one file to upload before starting progress
      if (selectedAttachments.value.length) {
        console.log("Uploading started...");

        progressInterval = setInterval(() => {
          if (uploadProgress.value < 100) {
            uploadProgress.value += 10;
          } else {
            clearInterval(progressInterval);
          }
        }, 200);

        // Simulate async upload time
        await new Promise((resolve) => setTimeout(resolve, 2200));

        clearInterval(progressInterval);
        uploadProgress.value = 100;
      }

      event.target.value = "";
      console.log(selectedAttachments.value, "selectedAttachments.value");
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    attachmentError.value = "Error uploading file.";
  } finally {
    setTimeout(() => {
      isFileUploading.value = false;
    }, 500); // Delay hiding the loader to ensure UI updates properly
  }
};

const setEndDate = () => {
  console.log("run setEndDate");
  if (isPresent.value) {
    endDate.value = null;
  }
};

const formatIssueDate = (isoDate: any) => {
  if (isoDate === "" || isoDate === null) {
    return "";
  }
  return new Date(isoDate).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const getEndDate = () => {
  if (endDate.value !== null) {
    return moment(endDate.value, "YYYY-MM-DD").format("YYYY-MM-DD");
  }
  return endDate.value;
};

const onSubmit = async () => {
  const isFormValid = await formRef.value.validate();

  if (!isFormValid.valid && props.experienceModalType === "add") return;

  try {
    let payload = {} as any;
    let logo = "";
    let attachments = [] as any;

    if (
      (props.experienceModalType === "add" &&
        selectedAttachments.value.length) ||
      (props.experienceModalType === "edit" && selectedAttachments.value.length)
    ) {
      isUploadImg.value = true;
      // const attachments = [] as any;
      for (const attachment of selectedAttachments.value) {
        const formData = new FormData();
        formData.append("file", attachment);
        const uploadImg = await store.dispatch(
          `${USER_STORE}/uploadProfileImage`,
          formData
        );

        if (uploadImg?.publicUrl) {
          attachments.push(uploadImg);
        } else {
          displayToastMessage(
            internalInstance,
            "Failed to upload an attachment.",
            "error"
          );
        }
      }
      console.log(payload, "payload");
    }

    if (props.experienceModalType === "add") {
      payload = {
        companyName: companyName.value,
        title: title.value,
        isPresent: isPresent.value,
        startDate: moment(startDate.value, "YYYY-MM-DD").format("YYYY-MM-DD"),
        endDate: getEndDate(),
        attachments,
      };

      const request = {
        userId: user.value?.id,
        payload,
      };

      console.log(request, "request");

      const response = await store.dispatch(
        `${USER_STORE}/addTradePassportExperience`,
        request
      );

      console.log(response, "response");

      if (response) {
        emits("on-success");
      }
    }

    if (props.experienceModalType === "edit") {
      let attachment = {} as any;
      if (
        props.selectedExperienceData?.attachments &&
        props.selectedExperienceData?.attachments.length
      ) {
        attachment = {
          ...attachments[0],
          id: props.selectedExperienceData?.attachments[
            props.selectedExperienceData?.attachments.length - 1
          ].id,
        };
      }

      if (selectedAttachments.value.length) {
        payload = {
          experienceId: props.selectedExperienceData?.id,
          companyName: companyName.value,
          title: title.value,
          isPresent: isPresent.value,
          startDate: moment(startDate.value, "YYYY-MM-DD").format("YYYY-MM-DD"),
          endDate: getEndDate(),
          attachment,
        };
      } else {
        attachment = null;
        payload = {
          experienceId: props.selectedExperienceData?.id,
          companyName: companyName.value,
          title: title.value,
          isPresent: isPresent.value,
          startDate: moment(startDate.value, "YYYY-MM-DD").format("YYYY-MM-DD"),
          endDate: getEndDate(),
          attachment,
        };
      }

      const request = {
        userId: user.value?.id,
        id: props.selectedExperienceData?.id,
        payload,
      };

      console.log(request, "request");

      const response = await store.dispatch(
        `${USER_STORE}/updateTradePassportExperienceById`,
        request
      );

      console.log(response, "response");

      if (response) {
        emits("on-success");
      }
    }

    // store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);

    // console.log(response, "response");

    // emits("on-submit", { userMessageContent, disputeOffer });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status) {
        const errorMessage =
          error.response.data.message || "An error occurred. Please try again.";
        displayToastMessage(internalInstance, errorMessage, "error");
      } else {
        // Handle other status codes or errors
        displayToastMessage(
          internalInstance,
          `Error: ${error.response.statusText}`,
          "error"
        );
      }
    } else {
      displayToastMessage(
        internalInstance,
        "The request took too long and timed out. Please try again later.",
        "error"
      );
    }
  } finally {
    isUploadImg.value = false;
    companyName.value = "";
    title.value = "";
    startDate.value = "";
    endDate.value = null;
    isPresent.value = false;
    selectedAttachments.value = [];
    onClickBackButton();
  }
};

const removeAttachment = (index: number) => {
  selectedAttachments.value.splice(index, 1);
};

const onClickBackButton = () => {
  companyName.value = "";
  title.value = "";
  startDate.value = "";
  endDate.value = null;
  isPresent.value = false;
  selectedAttachments.value = [];
  return emits("on-close");
};

watch(
  () => isPresent.value,
  () => {
    setEndDate();
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  if (props.selectedExperienceData) {
    console.log(props.selectedExperienceData, "selectedExperienceData");
    companyName.value = props.selectedExperienceData?.companyName;
    title.value = props.selectedExperienceData?.title;
    startDate.value = formatIssueDate(props.selectedExperienceData?.startDate);
    endDate.value = formatIssueDate(props.selectedExperienceData?.endDate);
    isPresent.value = props.selectedExperienceData?.isPresent;
  }
});
</script>

<style lang="scss" scoped>
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}

.custom-file-upload {
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: #f8fbfd;
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}

.upload_file {
  :deep(.v-label) {
    margin-top: 0 !important;
  }
}

.upload_file {
  :deep(.v-field__input) {
    display: none;
  }
  :deep(.v-field__clearable) {
    display: none;
  }
}

:deep(.el-popper.is-pure) {
  z-index: 3000 !important;
}
</style>
