import { NWGJobState } from "@/core/models/nwg-job";

export default {
  setJobList(state: NWGJobState, payload: any) {
    state.jobList = payload;
  },
  setJobDetails(state: NWGJobState, payload: any) {
    state.jobDetails = payload;
  },
  setBatchDetails(state: NWGJobState, payload: any) {
    state.batchDetails = payload;
  },
  setBatchList(state: NWGJobState, payload: any) {
    state.batchList = payload;
  },
};
