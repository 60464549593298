<template>
  <v-dialog width="460px" v-model="isShowDeleteModal" persistent
    ><div class="delete_event_modal">
      <div class="delete_event_modal_close" @click="onClickCancelButton">
        <img
          src="@/assets/images/close.svg"
          alt="Close Icon"
          width="14"
          height="14"
        />
      </div>

      <div class="delete_event_modal_image">
        <img
          src="@/assets/images/trash-red.svg"
          alt="Trash Icon"
          width="100"
          height="100"
        />
      </div>
      <h2>Delete the Event?</h2>
      <div class="delete_event_modal_text">
        <p>Are you sure you want to delete this Out Of Hours Event</p>
      </div>
      <div class="delete_event_modal_footer">
        <Button
          class="button"
          label="CANCEL"
          variant="secondary"
          @click="onClickCancelButton"
        /><Button
          class="button"
          label="DELETE"
          variant="accent"
          @click="onClickDeleteButton"
        />
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { ref } from "vue";
import EOHService from "@/core/services/emergency-out-of-hours.service";
import Button from "@/core/components/ui/general/Button.vue";
export default {
  components: { Button },
  props: { selectedEventId: { type: Number, default: null } },
  setup(props: any, ctx: any) {
    const isShowDeleteModal = ref(true);

    const onClickCancelButton = () => {
      ctx.emit("cancelDeleteModal");
    };

    const onClickDeleteButton = async () => {
      await EOHService.deleteParticularEOHEvents(props.selectedEventId);
      ctx.emit("cancelDeleteModal");
    };

    return { isShowDeleteModal, onClickCancelButton, onClickDeleteButton };
  },
};
</script>

<style lang="scss" scoped>
.delete_event_modal {
  background-color: $white;
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
  padding: 30px 24px;
  border-radius: 8px;
  text-align: center;

  @include respond(s720) {
    padding: 24px 34px;
    box-shadow: 0px 0px 10px 0px #2a2a2a05;
  }

  &_close {
    width: 22px;
    height: 20px;
    display: none;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: -10px;

    @include respond(s720) {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  h2 {
    @include fluidFont(24, 24, 1);
    font-weight: 700;
    color: $blueDark;
    margin-bottom: 44px;

    @include respond(s720) {
      margin-bottom: 18px;
    }
  }

  &_text {
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
    @include fluidFont(14, 14, 20px);
    letter-spacing: 0.15px;
  }

  &_footer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 12px;
    margin-top: 40px;

    @include respond(s720) {
      margin-top: 18px;
    }
  }

  &_image {
    max-width: 40px;
    width: 100%;
    margin: 0 auto 14px;

    @include respond(s720) {
      max-width: 46px;
      margin-bottom: 18px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}
</style>
