import $axios from "../utils/axios-api-config";
import { InviteContractors } from "../models/preferred-contractors";
import { preferredContractorFilters } from "../models/jobs";

export default {
  getPreferredContractorsConnection(userId: number, params = {}) {
    return $axios.get(`preferred-contractors/${userId}/members`, { params });
  },
  inviteContractors(members: InviteContractors, userId: number) {
    return $axios.post(`/preferred-contractors/${userId}/members`, members);
  },
  getTradeUserConnection(userId: number) {
    return $axios.get(`preferred-contractors/${userId}/members/trade-user`);
  },
  acceptTradeUserConnection(
    userId: number,
    tradeUserConnectionId: number,
    key: any
  ) {
    return $axios.put(
      `preferred-contractors/${userId}/members/${tradeUserConnectionId}`,
      key
    );
  },
  acceptPreferredTradeUserConnection(
    userId: number,
    tradeUserConnectionId: number,
    data: any
  ) {
    return $axios.put(
      `preferred-contractors/${userId}/members/accept/${tradeUserConnectionId}`,
      data
    );
  },
  rejectTradeUserConnection(userId: number, tradeUserConnectionId: number) {
    return $axios.delete(
      `preferred-contractors/${userId}/members/${tradeUserConnectionId}`
    );
  },
  resendTradeUserConnection(
    userId: number,
    tradeUserConnectionId: number,
    key: any
  ) {
    return $axios.put(
      `preferred-contractors/${userId}/members/resent-invitation/${tradeUserConnectionId}`,
      key
    );
  },
  addPreferredContractorsCategory(userId: number, data: any) {
    return $axios.post(
      `preferred-contractors-categories/${userId}/create`,
      data
    );
  },
  getPreferredContractorsCategory(userId: number) {
    return $axios.get(`preferred-contractors-categories/${userId}`);
  },
  movePreferredContractorsCategory(
    userId: number,
    tradeUserConnectionId: number,
    data: any
  ) {
    return $axios.put(
      `preferred-contractors/${userId}/members/update-category/${tradeUserConnectionId}`,
      data
    );
  },

  fetchPreferredContractors(payload: preferredContractorFilters) {
    const { keywords = "", userId, ...filters } = payload;
    const urlParams = new URLSearchParams({ ...filters });
    const url = `/preferred-contractors/${userId}/members?${urlParams.toString()}${
      keywords.length > 0 ? `&keywords[]=${keywords}` : ""
    }`;
    return $axios.get(url);
  },

  rejectRequestTradeUserConnection(
    userId: number,
    tradeUserConnectionId: number
  ) {
    return $axios.delete(
      `preferred-contractors/${userId}/members/reject-request/${tradeUserConnectionId}`
    );
  },

  createUpdateClientRaiseEstimate(data: {
    id?: number;
    projectId: number;
    estimateId?: number;
    preferredContractorId?: number;
  }) {
    return $axios.post(`client-raise-estimate`, data);
  },

  checkClientEmail(userId: number, email: string) {
    return $axios.get(
      `preferred-contractors/${userId}/members/check-email/${email}`
    );
  },
  submitClientEstimate(
    userId: number,
    payload: {
      email: string;
      userName: string;
      projectId: number;
      estimateId: number;
      selectedWorkstationId?: number;
    }
  ) {
    return $axios.post(
      `preferred-contractors/${userId}/members/submit-estimate`,
      payload
    );
  },
  clientRaiseEstiamteHandle(
    userId: number,
    payload: {
      clientEstimateRaiseId: number;
      status: string;
    }
  ) {
    return $axios.post(
      `preferred-contractors/${userId}/members/estimate/accept-reject/`,
      payload
    );
  },
  getRaiseEstimateDetail(token: string) {
    return $axios.get(`preferred-contractors/estimate-detail/${token}`);
  },
  deleteRaiseEstimateDetails(id: number) {
    return $axios.delete(`preferred-contractors/estimate-detail/${id}`);
  },
};
