<template>
  <div class="trade_passport_wrapper">
    <v-tabs
      @update:modelValue="onTabChange"
      class="tab-list"
      align-tabs="center"
      stacked
      v-model="activeTab"
    >
      <v-tab
        v-for="item in tradeItems"
        :key="item.name"
        :value="item.name"
        :disabled="isItemDisable(item?.name)"
      >
        <v-icon :icon="item.icon" />
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <div v-if="isLoading && activeTab === 'Overview'">
      <Loader :show="isLoading" />
    </div>
    <div
      v-if="activeTab === 'Overview' && !isLoading"
      class="overview tw-pb-12 rlg:!tw-pb-16"
    >
      <!-- KYC/KYB NOTICE For Solo trader -->
      <div
        v-if="
          activeUserWorkstation?.verificationStatus ===
            UserVerificationStatusEnum.NOT_ADDED &&
          ((getVerificationStatus.statusClass === 'info' &&
            !isLoading &&
            activeUserWorkstation?.verificationStatus !==
              UserVerificationStatusEnum.VERIFIED &&
            accountStatuses?.forms?.insurance !== 0 &&
            accountStatuses?.forms?.certification !== 0 &&
            userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
            accountStatuses?.forms?.skills !== 0 &&
            getUserWorkStationFieldAccess) ||
            (activeUserWorkstation?.verificationStatus ===
              UserVerificationStatusEnum.VERIFIED &&
            userBusinessType === BusinessDetailTypeEnum.SOLETRADER
              ? activeUserWorkstation?.user?.userKYCVerificationStatus ===
                UserVerificationStatusEnum.NOT_ADDED
              : false))
        "
        class="tw-w-full tw-mb-3 tw-flex tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[rgba(255,165,0,0.2)] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
      >
        <NoticeIcon />

        <Text
          v-if="
            !activeUserWorkstation?.user?.userBusinessDetails
              ?.residencyPostcode &&
            userBusinessType === BusinessDetailTypeEnum.SOLETRADER
          "
          variant="span"
          textColor="#0C0F4A"
          textAlign="left"
          >Action Required: Add Business Address.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickAddBusinessAddressModal"
            >Manage Address</Text
          ></Text
        >
        <Text
          v-if="
            activeUserWorkstation?.user?.userKYCVerificationStatus ===
              UserVerificationStatusEnum.NOT_ADDED &&
            activeUserWorkstation?.user?.userBusinessDetails
              ?.residencyPostcode &&
            userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
            activeUserWorkstation?.verificationStatus !==
              UserVerificationStatusEnum.PENDING_VERIFICATION
          "
          variant="span"
          textColor="#FA4B00"
          textAlign="left"
          >Action Required: Complete ID Verification.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickIDVerificationModal"
            >Get Verified</Text
          ></Text
        >

        <Text
          v-if="
            (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
              ? activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode) &&
            (activeUserWorkstation?.user?.userKYCVerificationStatus ===
              UserVerificationStatusEnum.PENDING_VERIFICATION ||
              activeUserWorkstation?.user?.userKYCVerificationStatus ===
                UserVerificationStatusEnum.VERIFIED) &&
            !isUserProfileVerified
          "
          variant="span"
          textColor="#FA4B00"
          textAlign="left"
          >Action Required: Complete Your Trade Passport.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            >Manage Trade Passport</Text
          ></Text
        >
        <Text
          v-if="
            activeUserWorkstation?.verificationStatus ==
              UserVerificationStatusEnum.NOT_ADDED &&
            isUserProfileVerified &&
            activeUserWorkstation?.user?.userBusinessDetails
              ?.residencyPostcode &&
            activeUserWorkstation?.user?.userKYCVerificationStatus !==
              UserVerificationStatusEnum.NOT_ADDED
          "
          variant="span"
          textColor="#FA4B00"
          textAlign="left"
          >Action Required: Complete Workstation Verification.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickSubmitVerification"
            >Get Verified</Text
          ></Text
        >
        <Text
          v-if="
            activeUserWorkstation?.verificationStatus ==
              UserVerificationStatusEnum.PENDING_VERIFICATION &&
            isUserProfileVerified &&
            activeUserWorkstation?.user?.userBusinessDetails?.residencyPostcode
          "
          variant="span"
          textColor="#FA4B00"
          textAlign="left"
          >Action Required: Verification Pending.
        </Text>
      </div>

      <!-- KYC/KYB NOTICE For Limited Company -->
      <div
        v-if="
          activeUserWorkstation?.verificationStatus ===
            UserVerificationStatusEnum.NOT_ADDED &&
          ((getVerificationStatus.statusClass === 'info' &&
            !isLoading &&
            activeUserWorkstation?.verificationStatus !==
              UserVerificationStatusEnum.VERIFIED &&
            accountStatuses?.forms?.insurance !== 0 &&
            accountStatuses?.forms?.certification !== 0 &&
            userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
            accountStatuses?.forms?.skills !== 0 &&
            getUserWorkStationFieldAccess) ||
            (activeUserWorkstation?.verificationStatus ===
              UserVerificationStatusEnum.VERIFIED &&
              userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
              !WorkStationSiltVerificationStatus))
        "
        class="tw-w-full tw-mb-3 tw-flex tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[rgba(255,165,0,0.2)] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
      >
        <NoticeIcon />
        <Text
          v-if="
            !activeUserWorkstation?.user?.userBusinessDetails
              ?.businessPostcode &&
            userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
          "
          variant="span"
          textColor="#0C0F4A"
          textAlign="left"
          >Action Required: Add Business Address.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickAddBusinessAddressModal"
            >Manage Address</Text
          ></Text
        >

        <Text
          v-if="
            !WorkStationSiltVerificationStatus &&
            activeUserWorkstation?.user?.userBusinessDetails
              ?.businessPostcode &&
            userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
            userWorkStationStatus !==
              UserVerificationStatusEnum.PENDING_VERIFICATION
          "
          variant="span"
          textColor="#FA4B00"
          textAlign="left"
          >Action Required: Complete ID & Business Verification.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickIDVerificationModal"
            >Get Verified</Text
          ></Text
        >
        {{ WorkStationSiltVerificationStatus }}
        <Text
          v-if="
            (WorkStationSiltVerificationStatus &&
            userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
              ? activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode) &&
            (activeUserWorkstation?.user?.userKYCVerificationStatus ===
              UserVerificationStatusEnum.PENDING_VERIFICATION ||
              activeUserWorkstation?.user?.userKYCVerificationStatus ===
                UserVerificationStatusEnum.VERIFIED) &&
            !isUserProfileVerified
          "
          variant="span"
          textColor="#FA4B00"
          textAlign="left"
          >Action Required: Complete Your Trade Passport.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickManageTradePassportPreviewModal"
            >Manage Trade Passport</Text
          ></Text
        >
        <Text
          v-if="
            isUserVerifiedKYCAndKYB &&
            isUserProfileVerified &&
            (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
              ? activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode) &&
            userWorkStationStatus === UserVerificationStatusEnum.NOT_ADDED
          "
          variant="span"
          textColor="#FA4B00"
          textAlign="left"
          >Action Required: Complete Workstation Verification.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickSubmitVerification"
            >Get Verified</Text
          ></Text
        >
        <Text
          v-if="
            activeUserWorkstation?.verificationStatus ===
              UserVerificationStatusEnum.PENDING_VERIFICATION &&
            isUserProfileVerified &&
            (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
              ? activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode)
          "
          variant="span"
          textColor="#FA4B00"
          textAlign="left"
          >Action Required: Verification Pending.
        </Text>
      </div>

      <div
        class="btn-import-workshop"
        v-if="
          isUserProprtyOwner &&
          userAllWorkStation?.length > 2 &&
          getUserWorkStationFieldAccess
        "
      >
        <div class="back-btn" v-if="isUserProprtyOwner">
          <BackButton
            :label="
              isShowRates ? 'Back to Your Estimates' : 'Back To Dashboard'
            "
            @click="onClickBackEstimateButton"
          />
        </div>
      </div>

      <Card
        class="tw-p-5 !tw-max-h-[unset] !tw-mb-5"
        v-if="
          !isShowRates &&
          activeUserWorkstation?.verificationStatus ===
            UserVerificationStatusEnum.VERIFIED &&
          (getIsOpenTradePassportasViewMode
            ? true
            : activeUserWorkstation?.workstationLinkTree) &&
          shouldShowBanner
        "
      >
        <template #content>
          <WorkStationLinkTreeWidget
            :isViewMode="getIsOpenTradePassportasViewMode"
          />
        </template>
      </Card>

      <!-- NEW TRADEPASSPORT DESIGN DETAILS -->
      <div
        class="overview-content tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-start rlg:!tw-flex-row"
      >
        <!-- trade passport summarize left sidebar details -->
        <TradePassportProfileHeader
          class="tw-inline rlg:!tw-hidden"
          :userWorkStationImage="userWorkStationImage"
          :userWorkStationBanner="userWorkStationBanner"
          :contractorworkStationImage="contractorworkStationImage"
        />
        <TradePassportSummary
          :userWorkStationImage="userWorkStationImage"
          :userWorkStationBanner="userWorkStationBanner"
          :contractorworkStationImage="contractorworkStationImage"
          :userServices="filterUserSpecialisms"
          @on-add-rates="onClickUpdateUserRates"
        />
        <!-- trade passport main content details -->
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
          <TradePassportProfileHeader
            class="tw-hidden rlg:!tw-inline"
            :userWorkStationImage="userWorkStationImage"
            :userWorkStationBanner="userWorkStationBanner"
            :contractorworkStationImage="contractorworkStationImage"
            @route-to-reviews-tab="routeToReviewsTab"
          />
          <TradePassportAbout
            :userDescription="userDescriptionData"
            @on-handle-click="onEditDescription(userDescriptionData)"
          />
          <TradePassportServices
            :isLoading="specialismsLoading"
            :filterUserSpecialisms="filterUserSpecialisms"
          />
          <TradePassportInsurances :userInsurances="userInsurances" />
          <TradePassportCertificate :userCertificates="userCertificates" />
          <!-- <TradePassportProjects /> -->
          <TradePassportExperience />
          <TradePassportClients />
          <TradePassportDbsPoliceCheck />
          <TradePassportGalleryAttachments
            :fileList="fileList"
            :isLoading="state.galleryIsLoading"
            @route-to-gallery-tab="onClickManageGallery"
            @on-open-delete-confirmation-modal="
              onOpenDeleteConfirmationModal($event)
            "
            @on-open-photo-preview-modal="onOpenPhotoPreviewModal($event)"
            @on-open-video="onOpenVideo($event)"
          />
          <!-- <TradePassportAnalytics /> -->
        </div>
      </div>
      <!-- NEW TRADEPASSPORT DESIGN DETAILS ENDS HERE -->
    </div>
  </div>
  <v-alert
    v-if="state.alert"
    class="tw-absolute tw-bottom-[1rem] tw-right-0"
    transition="slide-y-transition"
    :title="state.alertConfig.title"
    :text="state.alertConfig.text"
    :type="state.alertType === 'success' ? state.alertType : 'error'"
  >
  </v-alert>

  <TradePassportCertification v-if="activeTab === 'Certifications'" />
  <TradePassportInsurance v-if="activeTab === 'Insurances'" />
  <TradePassportSpecialism
    v-if="activeTab === 'Services'"
    class="tw-relative"
  />
  <TradePassportGallery
    @onChangeGallery="onChangeGallery"
    v-if="activeTab === 'Gallery'"
  />
  <TradePassportJobs v-if="activeTab === 'Jobs'" />
  <TradePassportReviews v-if="activeTab === 'Reviews'" />
  <!-- <CertificationModal
    v-if="openedModel === modelName.CERTIFICATION"
    :userCertificateData="userCertificateData"
    @onClickCloseTab="onClickCloseModel"
  /> -->
  <CertificateEditModal
    v-if="openedModel === modelName.CERTIFICATION"
    @onSaveData="onClickUpdateCertificate"
    @onClickCloseTab="onClickCloseModel"
    :userCertificateData="userCertificateData"
  />
  <!-- <InsuranceModel
    v-if="openedModel === modelName.INSURANCE"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditInsurance="isOuterEditInsurance"
    :userInsuranceData="userInsuranceData"
  /> -->

  <AddInsuranceModal
    v-if="openedModel === modelName.INSURANCE"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditInsurance="isOuterEditInsurance"
    :userInsuranceData="userInsuranceData"
  />
  <DescriptionEditorComponent
    v-if="openedModel === modelName.DESCRIPTION"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditDescription="isOuterEditDescription"
    :userDescriptionData="userDescriptionData"
    @userDescriptionData="userDescription"
    :HeaderTittle="HeaderTittle"
  />
  <TaxAndVatModel
    v-if="openedModel === modelName.TAXANDVAT"
    @onClickCloseTab="onClickCloseModel"
    mode="Edit"
  />
  <EditWorkStationDetailsPopup
    :postCode="
      workstationPostcode(
        activeUserWorkstation?.user?.userBusinessDetails?.type
      )
    "
    :workstationAddressValue="
      workstationAddress(
        activeUserWorkstation?.user?.userBusinessDetails?.type
      ) !== 'null null'
        ? workstationAddress(
            activeUserWorkstation?.user?.userBusinessDetails?.type
          ) || undefined
        : undefined
    "
    v-if="isShowEditWorkStationModal"
    @closeEditWorkStationModal="closeEditWorkStationModal"
  />
  <AddUserRatesModal
    v-if="isShowUserRateModal"
    @closeUpdateUserRatesModal="closeUpdateUserRatesModal"
  />
  <AddUserExperienceModal
    v-if="isShowUserExperienceModal"
    @closeUpdateUserExperienceModal="closeUpdateUserExperienceModal"
  />
  <TeamMemberModal
    v-if="openedModel === modelName.TEAMMEMBERS"
    @onClickCloseTab="onClickCloseModel"
  />

  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    :type="confirmDeleteType"
    :label="confirmDeleteLabel"
    @on-close="onCloseConfirmDelete"
    @on-delete="deleteItem"
  ></ConfirmDeleteModal>
  <ImportWorkStationModal
    v-if="isShowImportWorkStation"
    @onCloseImportWorkstationModal="onCloseImportWorkstationModal"
  />
  <SelectTeamMemberModal
    v-if="isShowSelectTeamMember"
    @onCloseModal="onCloseSelectTeam"
    @onClickContinue="onClickContinueSelectTeamMember"
  />
  <AssignModal
    v-if="isShowAssignModal"
    @onCloseModal="onCloseAssignModal"
    @onContinueButtonClick="onContinueButtonClick"
  />
  <ConfirmAssignModal
    v-if="isShowConfirmAssignModal"
    @onCloseConfirmModal="onCloseConfirmAssignModal"
    @onclickNo="onClickNoConfirmAssignModal"
  />

  <DocumentDeleteConfirmationModal
    v-if="modals.deleteConfirmation"
    @on-close="onCloseDeleteConfirmationModal"
    :selectedDocument="state.selectedDocument"
    deleteType="files"
    :isGallery="true"
    @on-success="onSuccess('delete')"
    @on-success-gallery="
      getTradePassportGallery($event, true, state.selectedDocument?.id)
    "
  />

  <GalleryPhotoPreview
    v-if="modals.photoPreview"
    :file="state.selectedDocument"
    @on-close="onClosePhotoPreviewModal"
  />

  <FreezeInsuranceExpired
    v-if="
      userInsurenceExperiedStatus &&
      isShowFreezeModal &&
      isWorkStationVerified &&
      !isUserProprtyOwner
    "
    @on-close="onCloseFreezeModal"
  />
  <AddBusinessAddressPreviewModal
    v-if="openedModel === modelName.ADDBUSINESSADDRESSMODAL"
    @onClickCloseTab="onClickCloseModel"
    @onClickCloseSuccessTab="onClickCloseSuccessModal"
  />

  <CompleteIDVerificationPreviewModal
    v-if="openedModel === modelName.VERIFYPREVIEWMODAL"
    @onClickCloseTab="onClickCloseModel"
  />

  <TradePassportPreviewModal
    v-if="openedModel === modelName.TRADEPASSPORTPREVIEWMODAL"
    @onClickCloseTab="onClickCloseModel"
  />

  <WorkstationForVerificationPreviewModal
    v-if="openedModel === modelName.WORKSTATIONVERIFYPREVIEWMODAL"
    @onClickCloseTab="onClickCloseWsVerificationPreviewModel"
  />

  <WorkstationVerificationModal
    v-if="openedModel === modelName.VERIFYMODAL"
    :isShowKyb="userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY"
    :onClickCloseTab="onClickCloseModel"
  />
</template>

<script lang="ts">
import {
  PaymentPlanType,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { useRoute, useRouter } from "vue-router";
import { USER_STORE } from "@/store/modules/user";
import UserService from "@/core/services/user.service";
import { WORKSTATION } from "@/store/modules/workstation";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import { onMounted, watch, computed, ref, reactive, onUnmounted } from "vue";
import { useStore } from "vuex";
import TradePassportCertification from "../components/TradePassportCertification.vue";
import TradePassportInsurance from "../components/TradePassportInsurance.vue";
import TradePassportSpecialism from "../components/TradePassportSpecialism.vue";
import TradePassportJobs from "../components/TradePassportJobs.vue";
import TradePassportReviews from "../components/TradePassportReviews.vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
// import InsuranceModel from "@/modules/insurance/components/InsuranceModel.vue";
// import ItemListCard from "@/core/components/ItemListCard.vue";
import DescriptionEditorComponent from "@/modules/dashboard/components/DescriptionEditorComponent.vue";
import { COURSE_ROUTE } from "@/modules/courses/routes";
// import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import TaxAndVatModel from "@/modules/tax-and-vat/components/TaxAndVatModel.vue";
import {
  getImageStringToImageURL,
  isExpiredDate,
  setDirectStoragePathToImage,
} from "@/core/utils/common";
import EditWorkStationDetailsPopup from "@/modules/trade-passport/components/EditWorkStationDetailsPopup.vue";
import AddUserRatesModal from "@/modules/trade-passport/components/AddUserRatesModal.vue";
// import CertificationModal from "@/modules/certification/components/CertificationModel.vue";
import AddUserExperienceModal from "@/modules/trade-passport/components/AddUserExperienceModal.vue";
import TeamMemberModal from "@/modules/trade-passport/components/TeamMemberModal.vue";
// import SpecialismItem from "@/core/components/common/SpecialismItem.vue";
// import SpecialismItemTwo from "@/core/components/common/SpecialismItemTwo.vue";
// import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { MEMBER_STORE } from "@/store/modules/manage-member";
// import CommonChip from "@/modules/dashboard/components/Chips/CommonChip.vue";
// import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
// import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
// import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
// import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import ImportWorkStationModal from "@/modules/trade-passport/components/ImportWorkstationModal.vue";
import SelectTeamMemberModal from "@/modules/trade-passport/components/SelectTeamMemberModal.vue";
import AssignModal from "@/modules/trade-passport/components/AssignModal.vue";
import ConfirmAssignModal from "@/modules/trade-passport/components/ConfirmAssignModal.vue";
import Card from "@/core/components/ui/general/Card.vue";
// import ChipState from "@/core/components/common/ChipState.vue";
// import CheckGreenShield from "@/core/components/icons/CheckGreenShield.vue";
// import ErrorRedIcon from "@/core/components/icons/ErrorRedIcon.vue";
// import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import CurrencyFilter from "@/filters/currency.filter";
// import GreyItemCard from "@/modules/trade-passport/components/cards/GreyItemCard.vue";
// import PencilCircularBlueIcon from "@/core/components/icons/PencilCircularBlueIcon.vue";
// import TrashCircularGrayIcon from "@/core/components/icons/TrashCircularGrayIcon.vue";
// import ServiceIconWrapper from "@/core/components/common/ServiceIconWrapper.vue";
// import PdfIconGray from "@/core/components/icons/PdfIconGray.vue";
import TradePassportGallery from "@/modules/trade-passport/components/TradePassportGallery.vue";
import DocumentDeleteConfirmationModal from "@/modules/documents/components/modal/DocumentDeleteConfirmationModal.vue";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
// import TrashCircularWhiteIcon from "@/core/components/icons/TrashCircularWhiteIcon.vue";
// import $axios from "@/core/utils/axios-api-config";
// import { getImageApiUrl } from "@/core/utils/common";
import GalleryPhotoPreview from "@/modules/trade-passport/components/modals/GalleryPhotoPreview.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
// import ToolTip from "@/core/components/common/ToolTip.vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import BackButton from "@/core/components/common/BackButton.vue";
import insurancesService from "@/core/services/insurances.service";
import FreezeInsuranceExpired from "@/modules/trade-passport/components/modals/FreezeInsuranceExpired.vue";
import Loader from "@/core/components/common/Loader.vue";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import NoticeIcon from "@/core/components/icons/NoticeIcon.vue";
import { reviewRatingState } from "@/core/models/project-rating";
import { getCombineSameProjectReview } from "@/core/utils/common";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import WorkStationLinkTreeWidget from "@/modules/dashboard/components/Widgets/WorkStationLinkTreeWidget.vue";
import AddInsuranceModal from "@/modules/insurance/components/AddInsuranceModal.vue";
import CertificateEditModal from "@/modules/certification/components/CertificateEditModal.vue";
import WorkstationForVerificationPreviewModal from "@/modules/dashboard/components/Modals/WorkstationForVerificationPreviewModal.vue";
import TradePassportPreviewModal from "@/modules/dashboard/components/Modals/TradePassportPreviewModal.vue";
import CompleteIDVerificationPreviewModal from "@/modules/dashboard/components/Modals/CompleteIDVerificationPreviewModal.vue";
import AddBusinessAddressPreviewModal from "@/modules/dashboard/components/Modals/AddBusinessAddressPreviewModal.vue";
import WorkstationVerificationModal from "@/modules/workstation/components/WorkstationVerificationModal.vue";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import TradePassportSummary from "../components/widgets/TradePassportSummary.vue";
import TradePassportAbout from "../components/widgets/TradePassportAbout.vue";
import TradePassportServices from "../components/widgets/TradePassportServices.vue";
import TradePassportInsurances from "../components/widgets/TradePassportInsurances.vue";
import TradePassportCertificate from "../components/widgets/TradePassportCertificate.vue";
// import TradePassportProjects from "../components/widgets/TradePassportProjects.vue";
import TradePassportExperience from "../components/widgets/TradePassportExperience.vue";
import TradePassportClients from "../components/widgets/TradePassportClients.vue";
import TradePassportDbsPoliceCheck from "../components/widgets/TradePassportDbsPoliceCheck.vue";
import TradePassportGalleryAttachments from "../components/widgets/TradePassportGalleryAttachments.vue";
// import TradePassportAnalytics from "../components/widgets/TradePassportAnalytics.vue";
import TradePassportProfileHeader from "../components/widgets/TradePassportProfileHeader.vue";

export default {
  components: {
    // DashBoardWelcome,
    TradePassportCertification,
    TradePassportInsurance,
    TradePassportSpecialism,
    // InsuranceModel,
    // ItemListCard,
    DescriptionEditorComponent,
    // UserProfileLogo,
    TaxAndVatModel,
    EditWorkStationDetailsPopup,
    AddUserRatesModal,
    // CertificationModal,
    AddUserExperienceModal,
    TeamMemberModal,
    // SpecialismItem,
    // SpecialismItemTwo,
    // Button,
    Text,
    NoticeIcon,
    // CommonChip,
    // AcceptChip,
    // RejectChip,
    // PendingChip,
    // ProfileThumbnail,
    ConfirmDeleteModal,
    ImportWorkStationModal,
    SelectTeamMemberModal,
    AssignModal,
    ConfirmAssignModal,
    Card,
    // ChipState,
    // CheckGreenShield,
    // ErrorRedIcon,
    // Postcode,
    // GreyItemCard,
    // TrashCircularGrayIcon,
    // PencilCircularBlueIcon,
    // ServiceIconWrapper,
    // PdfIconGray,
    TradePassportGallery,
    DocumentDeleteConfirmationModal,
    // TrashCircularWhiteIcon,
    GalleryPhotoPreview,
    // ToolTip,
    BackButton,
    TradePassportJobs,
    TradePassportReviews,
    FreezeInsuranceExpired,
    Loader,
    WorkStationLinkTreeWidget,
    AddInsuranceModal,
    CertificateEditModal,
    //dashboard verification and setup modals
    WorkstationForVerificationPreviewModal,
    TradePassportPreviewModal,
    CompleteIDVerificationPreviewModal,
    AddBusinessAddressPreviewModal,
    WorkstationVerificationModal,

    TradePassportSummary,
    TradePassportAbout,
    // TradePassportAnalytics,
    TradePassportGalleryAttachments,
    // TradePassportDbsPoliceCheck,
    // TradePassportClients,
    // TradePassportExperience,
    // TradePassportProjects,
    TradePassportCertificate,
    TradePassportInsurances,
    TradePassportServices,
    TradePassportProfileHeader,

    TradePassportDbsPoliceCheck,
    TradePassportClients,
    TradePassportExperience,
    // TradePassportProjects,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const activeTab = ref("Overview");
    console.log("activeTab");
    const openedModel = ref("");
    const modelName = {
      INSURANCE: "insurance",
      DESCRIPTION: "description",
      TAXANDVAT: "tax&vat",
      CERTIFICATION: "certification",
      TEAMMEMBERS: "TeamMember",
      ADDBUSINESSADDRESSMODAL: "addBusinessAddress",
      VERIFYPREVIEWMODAL: "verifyPreviewModal",
      TRADEPASSPORTPREVIEWMODAL: "tradePassportPreview",
      WORKSTATIONVERIFYPREVIEWMODAL: "workstationVerifyPreview",
      VERIFYMODAL: "verifyModal",
    };
    const initializing = ref(false);
    const isShowWorkStatonLinkTreeStatus = ref(false);
    const insuranceLoading = ref(false);
    const specialismsLoading = ref(false);
    const certificateLoading = ref(false);
    const isOuterEditInsurance = ref(false);
    const userInsuranceData = ref(null) as any;
    const isOuterEditDescription = ref(false);
    const userDescriptionData = ref(null) as any;
    const HeaderTittle = ref("");
    const teamMemberList = ref();
    const modifiedMembers = ref([]) as any;
    const loadingImages = ref(false);

    const isShowAssignModal = ref(false);

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteLabel = ref("");
    const confirmDeleteType = ref("");
    const confirmDeleteId = ref(null) as any;
    const isAvailable = ref();
    const isLoading = ref(false);
    const confirmDeleteCertificateData = ref({
      type: "certificate",
      label: "You're about to delete your certification.",
    });
    const confirmDeleteInsuranceData = ref({
      type: "insurance",
      label: "You're about to delete your insurance.",
    });

    const isShowUserRateModal = ref(false);
    const isShowFreezeModal = ref(true);
    const isShowUserExperienceModal = ref(false);

    const userCertificateData = ref(null) as any;

    const nationalInsuranceNumber = ref("");
    const isShowPassword = ref(false);

    const isShowImportWorkStation = ref(false);

    const isShowSelectTeamMember = ref(false);

    const isShowConfirmAssignModal = ref(false);

    const confirmDeleteAssignedMembers = ref(null) as any;

    const isSameWorkstaion = ref(false);

    const fileList = ref([]) as any;

    const tradeItems = ref([
      { name: "Overview", icon: "mdi-home" },
      // { name: "Services", icon: "mdi-tools" },
      // { name: "Certifications", icon: "mdi-file-table" },
      // // { name: "Companies", icon: "mdi-text-box" },
      // { name: "Insurances", icon: "mdi-shield-home" },
      { name: "Jobs", icon: "mdi-account-tie" },
      { name: "Reviews", icon: "mdi-star" },
      { name: "Gallery", icon: "mdi-image-multiple-outline" },
      // { name: "Recommendations", icon: "mdi-star-circle-outline" },
    ]);

    const preOpenTabs = ref([
      "Overview",
      // "Services",
      // "Certifications",
      // "Insurances",
      "Gallery",
      "Jobs",
      "Reviews",
    ]);

    const modals = reactive({
      deleteConfirmation: false,
      photoPreview: false,
    });
    const state = reactive({
      selectedDocument: null as any,
      galleryIsLoading: false,

      alert: false,
      alertType: "",
      actionType: "",
      alertConfig: {
        text: "",
        title: "",
      },
    });
    const allUserConnection = ref([]);
    const shouldShowBanner = computed(() => {
      if (route.path === "/trade-passport") {
        return true;
      }

      if (
        route.path === "/trade-estimates/trade-passport" &&
        isShowWorkStatonLinkTreeStatus.value &&
        !isSameWorkstaion.value
      ) {
        return true;
      }

      return false;
    });

    const isShowWorkStationLinkTree = computed(() => {
      if (
        activeUserWorkstation.value.id ==
        contractorTradePassportWorkstationId.value
      ) {
        return false;
      }
      const isWorkstationIdPresent = allUserConnection.value.some(
        (connection: any) =>
          connection.receiverWorkstation?.id ===
          contractorTradePassportWorkstationId.value
      );
      return !isWorkstationIdPresent;
    });

    const userAllWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstations`]
    );
    const accountStatuses = computed(
      () => store.getters[`${USER_STORE}/accountStatuses`]
    );

    const userWorkStationStatusInsurance = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );

    const isWorkStationVerified = computed(() => {
      return (
        userWorkStationStatusInsurance.value ===
        UserVerificationStatusEnum.VERIFIED
      );
    });

    const getIsOpenTradePassportasViewMode = computed(
      () =>
        store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
    );
    const isShowRates = computed(
      () => user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER
    );

    const isUserProprtyOwner = computed(
      () =>
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
        getIsOpenTradePassportasViewMode.value
    );

    const isShowEditWorkStationModal = ref(false);
    const contractorworkStationImage = ref("");
    const userWorkStationImage = computed(() => {
      if (!isUserProprtyOwner.value) {
        return store.getters[`${USER_STORE}/userWorkStationImage`];
      } else {
        return "";
      }
    });
    const userWorkStationBanner = computed(() => {
      if (!isUserProprtyOwner.value) {
        return store.getters[`${USER_STORE}/userWorkStationBanner`];
      } else {
        return "";
      }
    });

    const activeUserWorkstation = computed(() => {
      if (isUserProprtyOwner.value) {
        return store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`
        ];
      } else {
        return store.getters[`${WORKSTATION}/activeUserWorkstation`];
      }
    });

    const getLinkData = computed(() => {
      return activeUserWorkstation.value?.workstationLinkTree;
    });

    const userInsurenceExperiedStatus = computed(
      () => store.getters[`${INSURANCE_STORE}/getIsInsuranceExpired`]
    );

    const userWorkStationStatus = computed(() => {
      if (isUserProprtyOwner.value) {
        return store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`
        ]?.verificationStatus;
      } else {
        return store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus;
      }
    });

    const isItemDisable = (itemName: string) => {
      if (preOpenTabs.value.includes(itemName)) {
        return false;
      } else if (
        userWorkStationStatus.value !== UserVerificationStatusEnum.VERIFIED
      ) {
        return true;
      } else {
        return false;
      }
    };
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userInsurances = computed(
      () => store.getters[`${USER_STORE}/userInsurances`]
    );
    const userBusinessType = computed(
      () => user.value?.userBusinessDetails?.type
    );
    const isUserVerifiedKYCAndKYB = computed(() =>
      userBusinessType.value === BusinessDetailTypeEnum.LIMITED_COMPANY
        ? WorkStationSiltVerificationStatus.value &&
          ["SUCCESS", "MANUAL_REVIEW"].includes(
            WorkStationSiltVerificationStatus.value?.status
          )
        : [
            UserVerificationStatusEnum.PENDING_VERIFICATION,
            UserVerificationStatusEnum.VERIFIED,
          ].includes(
            activeUserWorkstation.value?.user?.userKYCVerificationStatus
          )
    );
    const userCertificates = computed(
      () => store.getters[`${USER_STORE}/userCertificates`]
    );

    const userFullName = computed(() => {
      return `${user.value?.firstName} ${user.value?.lastName}`;
    });
    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );
    const isUserWorkStationAdmin = computed(
      () => store.getters[`${WORKSTATION}/isUserWorkStationAdmin`]
    );
    const filterUserSpecialisms = computed(() => {
      const ids = [] as any;
      const categoryList = [] as any;
      for (const item of userSpecialisms.value || []) {
        const { category, subCategories } = item as any;
        const categoryId = category.id;
        const label = category.poLabel ? category.tpLabel : "";
        const iconName = category.iconName;

        if (!ids.includes(categoryId)) {
          ids.push(categoryId);
          categoryList.push({ id: categoryId, label, iconName, subCategories });
        } else {
          categoryList
            .find((value: any) => value.id === categoryId)
            ?.subCategories.push(...subCategories);
        }
      }

      return categoryList || [];
    });

    const hasData = computed(() => {
      if (
        (activeUserWorkstation?.value?.user?.userBusinessDetails
          ?.nationalInsuranceNumber !== null ||
          activeUserWorkstation?.value?.user?.userBusinessDetails
            ?.nationalInsuranceNumber !== "") &&
        activeUserWorkstation?.value?.user?.userBusinessDetails?.ukVatNumber !==
          null &&
        activeUserWorkstation?.value?.user?.userBusinessDetails?.ukVatNumber !==
          ""
      ) {
        return true;
      }
      return false;
    });

    watch(
      () => user,
      (newUserDetails, old) => {
        nationalInsuranceNumber.value =
          user?.value.userBusinessDetails?.nationalInsuranceNumber;
      },
      { deep: true, immediate: true }
    );

    const updateAvailableStatus = async () => {
      try {
        if (activeUserWorkstation.value?.id) {
          await UserService.updateUserWorkstation(
            user.value?.id,
            activeUserWorkstation.value?.id,
            {
              isAvailable: isAvailable.value,
            }
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    watch(
      () => isAvailable.value,
      (newValue, old) => {
        if (!isUserProprtyOwner.value) {
          updateAvailableStatus();
        }
      },
      { deep: true, immediate: true }
    );

    const onCloseFreezeModal = () => {
      isShowFreezeModal.value = false;
    };
    const onOpenDeleteConfirmationModal = (document: any) => {
      state.selectedDocument = document;
      modals.deleteConfirmation = true;
    };

    const onCloseDeleteConfirmationModal = () => {
      state.selectedDocument = null;
      modals.deleteConfirmation = false;
    };

    const onOpenPhotoPreviewModal = (file: any) => {
      if (file?.fileType === "video/mp4") return;
      state.selectedDocument = file;
      modals.photoPreview = true;
    };

    const onClosePhotoPreviewModal = () => {
      state.selectedDocument = null;
      modals.photoPreview = false;
    };

    const onOpenVideo = (file: any) => {
      // Check if the file has an attachmentSource property
      if (file && file.attachmentSource) {
        // Open the video in a new tab
        window.open(file.attachmentSource, "_blank");
      } else {
        console.error("Invalid file or missing attachmentSource");
      }
    };

    const getTradePassportGallery = async (
      event = {} as any,
      isActionDelete = false,
      id = null as any
    ) => {
      state.galleryIsLoading = true;
      try {
        if (!isActionDelete) {
          const userId = user.value.id;
          if (isUserProprtyOwner.value) {
            fileList.value = await store.dispatch(
              `${DOCUMENTS_STORE}/getContractorTradePassportGallery`,
              {
                workStationId: contractorTradePassportWorkstationId.value,
                userId: user.value.id,
              }
            );
          } else {
            fileList.value = await store.dispatch(
              `${DOCUMENTS_STORE}/getTradePassportGallery`,
              userId
            );
          }

          fileList.value = fileList.value.map((file: any) => {
            return { ...file, name: file.originalName, attachment: file.name };
          });

          const attachmentPromises = fileList.value.map(
            async (currentFile: any) => {
              const { attachment } = currentFile;
              const imageUrl = setDirectStoragePathToImage(attachment);

              return { ...currentFile, attachmentSource: imageUrl };
            }
          );

          // Wait for all attachment promises to resolve
          fileList.value = await Promise.all(attachmentPromises);
        } else {
          if (fileList.value?.length) {
            fileList.value = fileList.value.filter(
              (file: any) => file.id !== id
            );
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        state.galleryIsLoading = false;
      }
    };

    const onSuccess = async (event: any) => {
      state.alertType = "success";
      state.actionType = event;
      state.alert = true;
      state.alertConfig = {
        text:
          state.actionType === "edit"
            ? "Edited Successfully"
            : "Deleted Successfully",
        title: "Successful",
      };
    };

    const hideAlert = () => {
      setTimeout(() => {
        state.alert = false;
      }, 3000);
    };

    watch(
      () => state.alert,
      (newValue, oldValue) => {
        if (newValue) {
          hideAlert();
        }
      },
      { deep: true, immediate: true }
    );

    const onDeleteInsurance = async (insuranceId: string) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserInsurance`, {
          userId: user.value.id,
          userInsuranceId: insuranceId,
        });
        if (userInsurances.value.length === 0 && isWorkStationVerified.value) {
          const responce: any = await insurancesService.isInsuranceExpired(
            user.value.id
          );

          store.commit(
            `${INSURANCE_STORE}/setInsuranceExpired`,
            responce.isFreezed
          );
        }
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };
    const onEditInsurance = async (insurance: object) => {
      userInsuranceData.value = insurance;
      isOuterEditInsurance.value = true;
      openedModel.value = modelName.INSURANCE;
    };

    const onEditDescription = async (description: string) => {
      userDescriptionData.value = description;
      isOuterEditDescription.value = true;
      HeaderTittle.value = userDescriptionData.value ? "Edit" : "Add";
      openedModel.value = modelName.DESCRIPTION;
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      getTeamMember();
    };

    const onClickUpdateCertificate = async () => {
      await store.getters[`${USER_STORE}/userCertificates`];
      onClickCloseModel();
      userCertificateData.value = null;
    };

    const userDescription = async (value: any) => {
      userDescriptionData.value = value;
      await store.dispatch(`${WORKSTATION}/activateWorkstationDescription`, {
        userId: user.value?.id,
        userWorkstationId: activeUserWorkstation.value?.id,
        description: userDescriptionData.value,
      });
    };

    const onClickManageSpecialism = () => {
      activeTab.value = "Services";
    };

    const onClickAddInsurance = () => {
      activeTab.value = "Insurances";
    };

    const onClickManageCertifications = () => {
      activeTab.value = "Certifications";
    };
    const onClickManageGallery = () => {
      activeTab.value = "Gallery";
    };

    const routeToReviewsTab = () => {
      activeTab.value = "Reviews";
    };

    watch(
      () => activeUserWorkstation.value,
      (newValue, oldValue) => {
        userDescriptionData.value = activeUserWorkstation.value?.description;
        isAvailable.value = activeUserWorkstation.value?.isAvailable;
      },
      { immediate: true }
    );

    const formatNumberWithCommas = (number: any) => {
      const numericValue =
        typeof number === "number" ? number : parseFloat(number);
      return numericValue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const navigateToCourse = () => {
      router.push({ name: COURSE_ROUTE });
    };
    const onClickEditVatAndTax = () => {
      openedModel.value = modelName.TAXANDVAT;
    };

    const activeWorkStationType = (businessType: string) => {
      if (isUserProprtyOwner.value) {
        return activeUserWorkstation.value?.user?.userBusinessDetails?.type ===
          BusinessDetailTypeEnum.LIMITED_COMPANY
          ? "LTD Company"
          : "Sole Trader";
      }
      return businessType === BusinessDetailTypeEnum.LIMITED_COMPANY
        ? "LTD Company"
        : "Sole Trader";
    };

    const workstationCreatedDate = (date: any) => {
      const wsDate = new Date(date);

      const day = wsDate.getDate();
      const month = wsDate.toLocaleString("en-US", { month: "long" });
      const year = wsDate.getFullYear();

      return `${day} ${month} ${year}`;
    };

    const workstationAddress = (type: string) => {
      if (activeUserWorkstation.value?.user) {
        let address: string;
        if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
          const businessAddress =
            activeUserWorkstation.value?.user?.userBusinessDetails?.businessAddress?.replace(
              activeUserWorkstation.value?.user?.userBusinessDetails
                ?.businessPostcode,
              ""
            );
          address = businessAddress?.replace(/,([^,]*)$/, ".$1");
        } else if (
          activeUserWorkstation.value?.user?.userBusinessDetails
            ?.residencyAddressLookup
        ) {
          const residencyAddressLookup =
            activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLookup?.replace(
              activeUserWorkstation.value?.user?.userBusinessDetails
                ?.residencyPostcode,
              ""
            );
          address = residencyAddressLookup?.replace(/,([^,]*)$/, ".$1");
        } else {
          address = `${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLine} ${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyTown}`;
        }
        return address;
      } else {
        return null;
      }
    };

    const workstationPostcode = (type: string) => {
      if (activeUserWorkstation.value?.user) {
        return type === BusinessDetailTypeEnum.LIMITED_COMPANY
          ? activeUserWorkstation.value?.user?.userBusinessDetails
              ?.businessPostcode
          : activeUserWorkstation.value?.user?.userBusinessDetails
              ?.residencyPostcode;
      } else {
        return null;
      }
    };

    const onClickEditWorkStation = () => {
      isShowEditWorkStationModal.value = true;
    };

    const closeEditWorkStationModal = async () => {
      isShowEditWorkStationModal.value = false;
      await store.dispatch(`${USER_STORE}/initializeUserState`);
    };

    const onClickUpdateUserRates = () => {
      isShowUserRateModal.value = true;
    };

    const closeUpdateUserRatesModal = () => {
      isShowUserRateModal.value = false;
    };

    const activeWorkStationSubscription = computed(
      () => activeUserWorkstation.value?.subscription
    );

    const getWorkStationPlanType = computed(() => {
      if (
        activeWorkStationSubscription.value?.metadata?.planType ==
        PaymentPlanType.MONTHLY
      ) {
        return "Monthly";
      } else if (
        activeWorkStationSubscription.value?.metadata?.planType ==
        PaymentPlanType.YEARLY
      ) {
        return "Yearly";
      } else {
        return "";
      }
    });

    const onDeleteItem = (
      assignedTo: any,
      id: any,
      type: string,
      label: string
    ) => {
      confirmDeleteId.value = id;
      confirmDeleteLabel.value = label;
      confirmDeleteType.value = type;
      isOpenConfirmDelete.value = true;
      confirmDeleteAssignedMembers.value = assignedTo.map(
        (member: any) => member.id
      );
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteId.value = null;
      confirmDeleteLabel.value = "";
      confirmDeleteType.value = "";
      isOpenConfirmDelete.value = false;
    };

    const deleteCertificate = async (certificateId: number, members: any) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
          userId: user?.value?.id,
          userCertificateId: certificateId,
        });

        // Delete related Members
        let payload = {
          teamMemberIds: members,
        };

        if (members?.length) {
          await store.dispatch(
            `${USER_STORE}/removeCertificateRelatedMembers`,
            {
              userId: user?.value?.id,
              teamMemberIds: payload,
            }
          );
        }

        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const deleteItem = async (e: any) => {
      if (e === confirmDeleteCertificateData.value.type) {
        await deleteCertificate(
          confirmDeleteId.value,
          confirmDeleteAssignedMembers.value
        );
        await onCloseConfirmDelete();
      }
      if (e === confirmDeleteInsuranceData.value.type) {
        await onDeleteInsurance(confirmDeleteId.value);
        await onCloseConfirmDelete();
      }
    };

    const onClickManageTeam = () => {
      openedModel.value = modelName.TEAMMEMBERS;
    };

    const onEditCertificate = (certificate: any) => {
      openedModel.value = modelName.CERTIFICATION;
      userCertificateData.value = certificate;
    };

    const subscriptionType = (type: any) => {
      let plan = "";
      if (type) {
        if (type.subscription) {
          const subscription = JSON.parse(type?.subscription);
          const planType = subscription.metadata.planType;

          if (planType === PaymentPlanType.MONTHLY.toString()) {
            plan = "Monthly";
          }
          if (planType === PaymentPlanType.YEARLY.toString()) {
            plan = "Yearly";
          }
        }
      }

      return plan;
    };

    const onClickUpdateUserExperience = () => {
      isShowUserExperienceModal.value = true;
    };
    const closeUpdateUserExperienceModal = () => {
      isShowUserExperienceModal.value = false;
    };

    const getTeamMember = async () => {
      try {
        const projectDataResponse = await store.dispatch(
          `${MEMBER_STORE}/getTeamMember`,
          {
            userId: user.value?.id,
          }
        );
        teamMemberList.value = projectDataResponse;
      } catch (err) {
        console.log();
      }
    };

    const getWorkstationProfile = async (profileImage: string) => {
      if (!profileImage) {
        return null;
      }
      try {
        const imagePath = profileImage;
        const response = await store.dispatch(
          `${USER_STORE}/getImage`,
          imagePath
        );
        if (response) {
          return response;
        }
        return null;
      } catch (error) {
        return null;
      }
    };

    const modifyMembersList = async () => {
      loadingImages.value = true;
      if (!teamMemberList.value && !teamMemberList.value?.length) {
        loadingImages.value = false;
        return;
      }
      try {
        const defaultMember = teamMemberList.value[0];
        const { profileImage } = defaultMember.userWorkstation;

        const source = await getWorkstationProfile(profileImage);

        teamMemberList.value.forEach((member: any, index: number) => {
          if (index === 0) {
            modifiedMembers.value.push({
              ...member,
              attachmentSource: source,
            });
          } else {
            modifiedMembers.value.push({
              ...member,
            });
          }
        });
      } catch (error) {
        loadingImages.value = false;
      } finally {
        loadingImages.value = false;
      }
    };

    const formatSum = (sum: any) => {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(sum);
    };

    const importWorkstationData = () => {
      isShowImportWorkStation.value = true;
    };

    const contractorTradePassportWorkstationId = computed(
      () =>
        store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
        ]
    );

    const getInitialData = async () => {
      try {
        isLoading.value = true;
        if (isUserProprtyOwner.value) {
          if (user.value && contractorTradePassportWorkstationId.value) {
            await store.dispatch(
              `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`,
              {
                workStationId: contractorTradePassportWorkstationId.value,
                userId: user.value.id,
              }
            );
            await store
              .dispatch(`${USER_STORE}/setContractorInsurances`, {
                userId: user.value.id,
                workStationId: contractorTradePassportWorkstationId.value,
              })
              .then(() => {
                insuranceLoading.value = false;
              });

            await store
              .dispatch(`${USER_STORE}/getContractorSkills`, {
                userId: user.value.id,
                workStationId: contractorTradePassportWorkstationId.value,
              })
              .then(() => {
                specialismsLoading.value = false;
              });

            await store
              .dispatch(`${USER_STORE}/setContractorCertificates`, {
                userId: user.value.id,
                workStationId: contractorTradePassportWorkstationId.value,
              })
              .then(() => {
                certificateLoading.value = false;
              });

            contractorworkStationImage.value = await getImageStringToImageURL(
              activeUserWorkstation.value?.profileImage
            );

            // nationalInsuranceNumber.value =
            //   user?.value.userBusinessDetails?.nationalInsuranceNumber;
            await getTradePassportGallery();
          } else {
            router.go(-1);
          }
        } else {
          // initializing.value = true;
          // certificateLoading.value = true;
          // specialismsLoading.value = true;
          // insuranceLoading.value = true;
          state.galleryIsLoading = true;
          if (user.value) {
            await getTeamMember();
            await modifyMembersList();
            if (!userInsurances.value?.length) {
              await store
                .dispatch(`${USER_STORE}/setUserInsurances`, user.value?.id)
                .then(() => {
                  insuranceLoading.value = false;
                });
            }
            if (!userSpecialisms.value?.length) {
              await store
                .dispatch(`${USER_STORE}/getUserSkills`, user.value.id)
                .then(() => {
                  specialismsLoading.value = false;
                });
            }
            if (!userCertificates.value?.length) {
              await store
                .dispatch(`${USER_STORE}/setUserCertificates`, user.value?.id)
                .then(() => {
                  certificateLoading.value = false;
                });
            }
            nationalInsuranceNumber.value =
              user?.value.userBusinessDetails?.nationalInsuranceNumber;
            await getTradePassportGallery();
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
        initializing.value = false;
      }
    };

    const chipLabel = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "Pending";
      else if (UserVerificationStatusEnum.REJECTED === status)
        return "Rejected";
      else if (UserVerificationStatusEnum.VERIFIED === status)
        return "Verified";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "Not added";
    };
    const chipColor = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "#FAA500";
      else if (UserVerificationStatusEnum.REJECTED === status) return "red";
      else if (UserVerificationStatusEnum.VERIFIED === status) return "green";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "#7467B7";
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const onCloseImportWorkstationModal = () => {
      isShowImportWorkStation.value = false;
    };

    const onClickAssignData = () => {
      isShowSelectTeamMember.value = true;
    };

    const onCloseSelectTeam = () => {
      isShowSelectTeamMember.value = false;
    };

    const onClickContinueSelectTeamMember = () => {
      isShowSelectTeamMember.value = false;
      isShowAssignModal.value = true;
    };

    const onCloseAssignModal = () => {
      isShowSelectTeamMember.value = true;
      isShowAssignModal.value = false;
    };

    const onContinueButtonClick = () => {
      isShowAssignModal.value = false;
      isShowConfirmAssignModal.value = true;
    };

    const onCloseConfirmAssignModal = () => {
      isShowConfirmAssignModal.value = false;
    };

    const onClickNoConfirmAssignModal = () => {
      isShowAssignModal.value = true;
      isShowConfirmAssignModal.value = false;
    };

    const onClickBackEstimateButton = () => {
      router.go(-1);
    };
    const onChangeGallery = async () => {
      await getTradePassportGallery();
    };
    const WorkStationSiltVerificationStatus = computed(
      () => store.getters[`${WORKSTATION}/getWorkStationSiltVerificationStatus`]
    );
    const getVerificationStatus = computed(() => {
      const statusString =
        user.value?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
          ? "KYB"
          : "KYC";

      const verificationStatus: any = {
        ERROR: {
          label: `${statusString} Not Verified`,
          icon: "mdi-information",
          statusClass: "info",
        },
        PENDING: {
          label: `${statusString} Verification Pending`,
          icon: "mdi-clock-time-eight",
          statusClass: "pending",
        },
        SUCCESS: {
          label: `${statusString} Verified`,
          icon: "mdi-check-circle",
          statusClass: "success",
        },
      };
      if (WorkStationSiltVerificationStatus.value) {
        const status = WorkStationSiltVerificationStatus?.value?.status;
        return verificationStatus[status] || verificationStatus.ERROR;
      } else {
        return verificationStatus.ERROR;
      }
    });

    const isUserProfileVerified = computed(
      () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
    );
    const isSubmitWsForVerification = computed(
      () => store.getters[`${WORKSTATION}/getIsSubmitWsForVerification`]
    );
    const isWsDirectlySubmittedForVerification = computed(
      () =>
        store.getters[`${WORKSTATION}/getIsWsDirectlySubmittedForVerification`]
    );
    const isUserAddBusinessAddress = computed(() => {
      if (
        user.value?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
      ) {
        return user.value.userBusinessDetails.businessPostcode;
      } else {
        return user.value.userBusinessDetails.residencyPostcode;
      }
    });

    const getAllProjectReviewList = computed<
      reviewRatingState["allProjectReviewList"]
    >(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);

    const combineSameProjectReview = computed(() =>
      getCombineSameProjectReview(getAllProjectReviewList.value)
    );
    const getAverageOfAllProjectReview = computed(() => {
      const totalAvg = combineSameProjectReview.value.reduce(
        (totalRating: number, review: any) => {
          totalRating += review.totalAvg || 0;
          return totalRating;
        },
        0
      );
      return totalAvg
        ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) /
            100
        : totalAvg;
    });

    const onClickViewRatings = () => {
      store.dispatch(
        `${REVIEW_RATING_STORE}/setTradePassportRedirect`,
        "Reviews"
      );
      router.push({ name: TRADE_PASSPOSRT_ROUTE });
    };

    const onClickSubmitVerification = () => {
      openedModel.value = modelName.WORKSTATIONVERIFYPREVIEWMODAL;
    };

    const onClickCloseWsVerificationPreviewModel = () => {
      onClickCloseModel();
      if (
        isSubmitWsForVerification.value ||
        isWsDirectlySubmittedForVerification.value
      ) {
        store.commit(
          `${WORKSTATION}/setIsWsDirectlySubmittedForVerification`,
          false
        );
        router.push({ name: DASHBOARD_ROUTE });
      }
    };
    const onTabChange = async (val: any) => {
      if (
        val == "Overview" &&
        userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED
      ) {
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      }
    };

    const onClickAddBusinessAddressModal = () => {
      if (!isUserAddBusinessAddress.value) {
        openedModel.value = modelName.ADDBUSINESSADDRESSMODAL;
      }
    };

    const onClickIDVerificationModal = () => {
      if (isUserVerifiedKYCAndKYB.value) return;
      openedModel.value = modelName.VERIFYMODAL;
    };

    const onClickManageTradePassportPreviewModal = () => {
      if (
        userWorkStationStatus.value ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      ) {
        console.log("Workstation Locked");
      } else {
        openedModel.value = modelName.TRADEPASSPORTPREVIEWMODAL;
      }
    };

    const onClickSubmitVerificationPreviewModal = () => {
      if (
        isUserProfileVerified.value &&
        activeUserWorkstation.value?.verificationStatus ===
          UserVerificationStatusEnum.NOT_ADDED &&
        isUserVerifiedKYCAndKYB.value
      ) {
        openedModel.value = modelName.WORKSTATIONVERIFYPREVIEWMODAL;
      }
    };
    const onClickCloseSuccessModal = async () => {
      openedModel.value = "";
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    };
    onMounted(async () => {
      try {
        allUserConnection.value =
          store.getters[`${TRADE_NETWORK}/getUserNetworkConnectedList`];

        await store.dispatch(
          `${REVIEW_RATING_STORE}/resetAllProjectReviewList`
        );
        const activeTabState =
          store.getters[`${REVIEW_RATING_STORE}/getTradePassportRedirect`];
        if (activeTabState) {
          activeTab.value = activeTabState;
          store.dispatch(`${REVIEW_RATING_STORE}/resetTradePassportRedirect`);
        }
        await getInitialData();
        const res = await store.dispatch(
          `${WORKSTATION}/getWorkStationLinkTreeBannerStatus`,
          {
            workStationId: activeUserWorkstation?.value?.id,
          }
        );
        isShowWorkStatonLinkTreeStatus.value = res?.status;
        isSameWorkstaion.value = res?.isSame;

        if (
          userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED
        ) {
          await store.dispatch(`${USER_STORE}/getAccountStatuses`);
        }
      } catch (error) {
        console.error("Eroro While Loading Data", error);
      }
    });

    onUnmounted(async () => {
      store.dispatch(`${TRADE_ESTIMATE_STORE}/resetTradeUserAccount`);
    });
    return {
      UserVerificationStatusEnum,
      userWorkStationStatus,
      tradeItems,
      isItemDisable,
      activeTab,
      filterUserSpecialisms,
      activeUserWorkstation,
      user,
      BusinessDetailTypeEnum,
      userInsurances,
      onDeleteInsurance,
      onEditInsurance,
      openedModel,
      isOuterEditInsurance,
      userInsuranceData,
      modelName,
      onClickCloseModel,
      onEditDescription,
      userDescriptionData,
      isOuterEditDescription,
      HeaderTittle,
      userDescription,
      userFullName,
      userWorkStationImage,
      userWorkStationBanner,
      onClickManageSpecialism,
      onClickAddInsurance,
      onClickManageCertifications,
      userCertificates,
      navigateToCourse,
      onClickEditVatAndTax,
      isExpiredDate,
      activeWorkStationType,
      workstationCreatedDate,
      workstationAddress,
      workstationPostcode,
      isShowEditWorkStationModal,
      onClickEditWorkStation,
      closeEditWorkStationModal,
      onClickUpdateUserRates,
      isShowUserRateModal,
      closeUpdateUserRatesModal,
      activeWorkStationSubscription,
      getWorkStationPlanType,
      userCertificateData,
      onEditCertificate,
      deleteCertificate,
      isShowUserExperienceModal,
      closeUpdateUserExperienceModal,
      onClickUpdateUserExperience,
      subscriptionType,
      onClickManageTeam,
      teamMemberList,
      chipLabel,
      chipColor,
      hasData,
      isShowPassword,
      nationalInsuranceNumber,
      modifiedMembers,
      loadingImages,
      formatSum,
      isOpenConfirmDelete,
      onDeleteItem,
      confirmDeleteInsuranceData,
      confirmDeleteCertificateData,
      confirmDeleteType,
      confirmDeleteLabel,
      onCloseConfirmDelete,
      deleteItem,
      importWorkstationData,
      isShowImportWorkStation,
      onCloseImportWorkstationModal,
      isAvailable,
      onClickAssignData,
      isShowSelectTeamMember,
      onCloseSelectTeam,
      onClickContinueSelectTeamMember,
      isShowAssignModal,
      onCloseAssignModal,
      onContinueButtonClick,
      isShowConfirmAssignModal,
      onCloseConfirmAssignModal,
      onClickNoConfirmAssignModal,
      userAllWorkStation,
      getUserWorkStationFieldAccess,
      isUserWorkStationAdmin,
      formatNumberWithCommas,
      formatDate,
      CurrencyFilter,
      onClickManageGallery,
      state,
      modals,
      onCloseDeleteConfirmationModal,
      onOpenDeleteConfirmationModal,
      fileList,
      initializing,
      certificateLoading,
      specialismsLoading,
      insuranceLoading,
      onSuccess,
      getTradePassportGallery,
      onClosePhotoPreviewModal,
      onOpenPhotoPreviewModal,
      onOpenVideo,
      contractorworkStationImage,
      isUserProprtyOwner,
      getIsOpenTradePassportasViewMode,
      onClickBackEstimateButton,
      onChangeGallery,
      userInsurenceExperiedStatus,
      isShowFreezeModal,
      onCloseFreezeModal,
      isWorkStationVerified,
      isLoading,
      isUserProfileVerified,
      isUserAddBusinessAddress,
      onClickSubmitVerification,
      onTabChange,
      getAverageOfAllProjectReview,
      onClickViewRatings,
      isShowRates,
      onClickUpdateCertificate,
      WorkStationSiltVerificationStatus,
      getVerificationStatus,
      accountStatuses,
      userBusinessType,
      isUserVerifiedKYCAndKYB,
      onClickAddBusinessAddressModal,
      onClickIDVerificationModal,
      onClickManageTradePassportPreviewModal,
      onClickSubmitVerificationPreviewModal,
      onClickCloseSuccessModal,
      onClickCloseWsVerificationPreviewModel,
      allUserConnection,
      isShowWorkStationLinkTree,
      isShowWorkStatonLinkTreeStatus,
      shouldShowBanner,
      isSameWorkstaion,
      routeToReviewsTab,
      getLinkData,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";

.gallery-content-container {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
.back-btn {
  margin-right: auto;
}

.card-services {
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(120px, 1fr));
}

.view-rating-box {
  padding: 12px 16px;
  background-color: #f3f3f6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  flex-wrap: wrap;

  .v-rating {
    :deep(.v-rating__wrapper) {
      .v-rating__item {
        .v-btn {
          width: auto;
          height: auto;
        }
      }
    }
  }

  p {
    @include fluidFont(16, 16, 1.2);
    font-weight: 700;
    letter-spacing: 0.12px;
    color: rgba($blueDark, 1);
  }

  .v-btn {
    padding: 5px 8px;
    min-height: auto;
    display: flex;
    text-transform: capitalize;
    height: auto;
    margin: 0;
  }
}
</style>
