<template>
  <main @scroll="onScroll">
    <!-- desktop filter -->
    <div
      class="desktop__wrapper tw-mt-6"
      :class="{ 'md:!tw-hidden': toggledView === 'map' }"
    >
      <div class="lg:tw-hidden tw-flex tw-justify-between tw-items-center">
        <div class="tw-flex tw-gap-[8px] tw-w-full tw-mb-2">
          <InputField
            prepend-inner-icon="mdi-magnify"
            class="tw-max-w-[294px] c-input input_field"
            placeholder="Search Project name, Type, Description"
            v-model="filters.search"
          />
          <InputField
            prepend-inner-icon="mdi-map-marker"
            class="tw-max-w-[143px] c-input input_field"
            placeholder="KT19 9JG"
            v-model="filters.postcode"
          />
        </div>

        <div class="tw-flex tw-gap-2 tw-justify-end tw-items-center">
          <v-btn-toggle v-model="toggledView" mandatory class="rmd:!tw-gap-4">
            <v-btn
              prepend-icon="mdi-view-list"
              :active="toggledView === 'tile'"
              :color="toggledView === 'tile' ? '#FAA100' : ''"
              class="tab__buttons"
              variant="plain"
              value="tile"
              >List</v-btn
            >
            <v-btn
              prepend-icon="mdi-map"
              :active="toggledView === 'map'"
              :color="toggledView === 'map' ? '#FAA100' : ''"
              class="tab__buttons"
              variant="plain"
              value="map"
              >Map</v-btn
            >
          </v-btn-toggle>
          <div class="sort__wrapper desktop__wrapper">
            <v-menu
              :persistent="true"
              :close-on-content-click="false"
              class="desktop_sort_menu"
            >
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain">
                  Sort By
                  <span>
                    <img :src="require('@/assets/icons/sort.svg')" alt="" />
                  </span>
                </v-btn>
              </template>
              <v-radio-group v-model="filters.order">
                <v-radio
                  label="Most Recent"
                  value="DESC"
                  color="indigo"
                ></v-radio>
                <v-radio label="Oldest" value="ASC" color="indigo"></v-radio>
              </v-radio-group>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="lg:tw-hidden tw-flex tw-gap-2 tw-w-full">
        <!-- <v-select
          prepend-inner-icon="mdi-tools"
          class="tw-max-w-full tw-w-1/4 custom-select input_field"
          placeholder="Skills Required"
          item-value="id"
          item-title="tpLabel"
          :items="listOfCategories"
          v-model="filters.specialism"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          multiple
          clearable
          variant="outlined"
        ></v-select> -->
        <!-- prepend-inner-icon="mdi-newspaper-variant" -->

        <v-select
          class="custom-select input_field"
          placeholder="All Jobs "
          :items="jobTypes"
          item-title="text"
          item-value="value"
          v-model="filters.projectTypes"
          clearable
          variant="outlined"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          @update:modelValue="handleJobType"
        >
          <template v-slot:prepend-inner>
            <img
              class="tw-w-[10px] tw-h-[10px]"
              v-if="selectedJobTypeIcon"
              :src="require(`@/assets/icons/${selectedJobTypeIcon}.svg`)"
            />
            <img v-else src="@/assets/icons/all-jobs-filter.svg" />
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props">
              <v-icon v-if="item.raw.iconName" class="list_content_btn">
                <img
                  v-if="item.raw.iconName"
                  :src="require('@/assets/icons/' + item.raw.iconName + '.svg')"
                  alt="icon"
                />
              </v-icon>
            </v-list-item>
          </template>
          <template v-slot:clear>
            <v-btn
              text
              @click="clearSelection('projectTypes')"
              class="tw-text-blue-500 tw-underline tw-font-normal hover:tw-text-blue-700 tw-text-xs tw-tracking-[0.25px] tw-capitalize"
            >
              Clear
            </v-btn>
          </template>
        </v-select>

        <v-select
          class="custom-select input_field"
          placeholder="Premium Property Member"
          :items="propertiesMembers"
          item-title="text"
          item-value="value"
          v-model="filters.projectOwnerType"
          clearable
          variant="outlined"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          @update:modelValue="handleMemberStatus"
        >
          <template v-slot:prepend-inner>
            <img
              class="tw-w-[10px] tw-h-[10px]"
              v-if="selectedMemberIcon"
              :src="require(`@/assets/icons/${selectedMemberIcon}.svg`)"
            />
            <img v-else src="@/assets/icons/premium-filter.svg" />
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props">
              <v-icon v-if="item.raw.iconName" class="list_content_btn">
                <img
                  v-if="item.raw.iconName"
                  :src="require('@/assets/icons/' + item.raw.iconName + '.svg')"
                  alt="icon"
                />
              </v-icon>
            </v-list-item>
          </template>
          <template v-slot:clear>
            <v-btn
              text
              @click="clearSelection('projectOwnerType')"
              class="tw-text-blue-500 tw-underline tw-font-normal hover:tw-text-blue-700 tw-text-xs tw-tracking-[0.25px] tw-capitalize"
            >
              Clear
            </v-btn>
          </template>
        </v-select>

        <v-select
          class="tw-max-w-full tw-w-1/4 custom-select input_field"
          placeholder="Commercial / Residential"
          item-title="text"
          item-value="value"
          :items="propertyTypeItems"
          v-model="filters.propertyCategory"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          clearable
          variant="outlined"
          @update:modelValue="handlePropertyType"
        >
          <template v-slot:prepend-inner>
            <img
              class="tw-w-[10px] tw-h-[10px]"
              v-if="selectedPropertyTypeIcon"
              :src="require(`@/assets/icons/${selectedPropertyTypeIcon}.svg`)"
            />
            <img v-else src="@/assets/icons/Commercial.svg" />
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props">
              <v-icon v-if="item.raw.iconName" class="list_content_btn">
                <img
                  v-if="item.raw.iconName"
                  :src="require('@/assets/icons/' + item.raw.iconName + '.svg')"
                  alt="icon"
                />
              </v-icon>
            </v-list-item>
          </template>
          <template v-slot:clear>
            <v-btn
              text
              @click="clearSelection('propertyCategory')"
              class="tw-text-blue-500 tw-underline tw-font-normal hover:tw-text-blue-700 tw-text-xs tw-tracking-[0.25px] tw-capitalize"
            >
              Clear
            </v-btn>
          </template>
        </v-select>
      </div>
    </div>

    <!-- Mobile filter -->
    <div
      class="mobile__wrapper tw-hidden lg:tw-block mobile__filter tw-mt-6"
      :class="{ 'md:!tw-hidden': toggledView === 'map' }"
    >
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <template v-slot:title>
            <img src="../../../assets/icons/filter-icon.svg" />
            <h6>All Filters</h6>
          </template>
          <v-expansion-panel-text>
            <div class="mobile__inputs">
              <div class="tw-flex tw-gap-[8px] tw-w-full tw-mb-2">
                <InputField
                  prepend-inner-icon="mdi-magnify"
                  class="tw-max-w-full tw-w-3/5 c-input input_field"
                  placeholder="Search"
                  v-model="filters.search"
                />
                <InputField
                  prepend-inner-icon="mdi-map-marker"
                  class="tw-max-w-full tw-w-1/5 c-input input_field"
                  placeholder="KT19 9JG"
                  v-model="filters.postcode"
                />
              </div>
              <div class="tw-flex tw-gap-2 tw-w-full tw-flex-col">
                <!-- <v-select
                  prepend-inner-icon="mdi-tools"
                  class="tw-max-w-full c-select input_field"
                  placeholder="Skills Required"
                  item-value="id"
                  item-title="tpLabel"
                  :items="listOfCategories"
                  v-model="filters.specialism"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  multiple
                  clearable
                  variant="outlined"
                ></v-select> -->
                <v-select
                  class="tw-max-w-full c-select input_field"
                  placeholder="All Jobs (Including Emergency)"
                  :items="jobTypes"
                  item-title="text"
                  item-value="value"
                  v-model="filters.projectTypes"
                  clearable
                  variant="outlined"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  @update:modelValue="handleJobType"
                >
                  <template v-slot:prepend-inner>
                    <img
                      class="tw-w-[10px] tw-h-[10px]"
                      v-if="selectedJobTypeIcon"
                      :src="
                        require(`@/assets/icons/${selectedJobTypeIcon}.svg`)
                      "
                    />
                    <img v-else src="@/assets/icons/all-jobs-filter.svg" />
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props">
                      <v-icon v-if="item.raw.iconName" class="list_content_btn">
                        <img
                          v-if="item.raw.iconName"
                          :src="
                            require('@/assets/icons/' +
                              item.raw.iconName +
                              '.svg')
                          "
                          alt="icon"
                        />
                      </v-icon>
                    </v-list-item>
                  </template>
                  <template v-slot:clear>
                    <v-btn
                      text
                      @click="clearSelection('projectTypes')"
                      class="tw-text-blue-500 tw-underline tw-font-normal hover:tw-text-blue-700 tw-text-xs tw-tracking-[0.25px] tw-capitalize"
                    >
                      Clear
                    </v-btn>
                  </template>
                </v-select>
                <v-select
                  class="custom-select input_field"
                  placeholder="Premium Property Member"
                  :items="propertiesMembers"
                  item-title="text"
                  item-value="value"
                  v-model="filters.projectOwnerType"
                  clearable
                  variant="outlined"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  @update:modelValue="handleMemberStatus"
                >
                  <template v-slot:prepend-inner>
                    <img
                      class="tw-w-[10px] tw-h-[10px]"
                      v-if="selectedMemberIcon"
                      :src="require(`@/assets/icons/${selectedMemberIcon}.svg`)"
                    />
                    <img v-else src="@/assets/icons/premium-filter.svg" />
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props">
                      <v-icon v-if="item.raw.iconName" class="list_content_btn">
                        <img
                          v-if="item.raw.iconName"
                          :src="
                            require('@/assets/icons/' +
                              item.raw.iconName +
                              '.svg')
                          "
                          alt="icon"
                        />
                      </v-icon>
                    </v-list-item>
                  </template>
                  <template v-slot:clear>
                    <v-btn
                      text
                      @click="clearSelection('projectOwnerType')"
                      class="tw-text-blue-500 tw-underline tw-font-normal hover:tw-text-blue-700 tw-text-xs tw-tracking-[0.25px] tw-capitalize"
                    >
                      Clear
                    </v-btn>
                  </template>
                </v-select>
                <v-select
                  class="tw-max-w-full c-select input_field"
                  placeholder="Commercial / Residential"
                  item-title="text"
                  item-value="value"
                  :items="propertyTypeItems"
                  v-model="filters.propertyCategory"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  clearable
                  variant="outlined"
                  @update:modelValue="handlePropertyType"
                >
                  <template v-slot:prepend-inner>
                    <img
                      class="tw-w-[10px] tw-h-[10px]"
                      v-if="selectedPropertyTypeIcon"
                      :src="
                        require(`@/assets/icons/${selectedPropertyTypeIcon}.svg`)
                      "
                    />
                    <img v-else src="@/assets/icons/Commercial.svg" />
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props">
                      <v-icon v-if="item.raw.iconName" class="list_content_btn">
                        <img
                          v-if="item.raw.iconName"
                          :src="
                            require('@/assets/icons/' +
                              item.raw.iconName +
                              '.svg')
                          "
                          alt="icon"
                        />
                      </v-icon>
                    </v-list-item>
                  </template>
                  <template v-slot:clear>
                    <v-btn
                      text
                      @click="clearSelection('propertyCategory')"
                      class="tw-text-blue-500 tw-underline tw-font-normal hover:tw-text-blue-700 tw-text-xs tw-tracking-[0.25px] tw-capitalize hover:tw-bg-transparent"
                    >
                      Clear
                    </v-btn>
                  </template></v-select
                >
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div>
      <div
        v-if="loading"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
      >
        <Loader :show="loading" />
      </div>
      <div v-if="!loading">
        <div class="tw-hidden lg:tw-flex tw-items-center tw-justify-between">
          <v-btn-toggle v-model="toggledView" mandatory class="rmd:!tw-gap-4">
            <v-btn
              prepend-icon="mdi-view-list"
              :active="toggledView === 'tile'"
              :color="toggledView === 'tile' ? '#FAA10066' : ''"
              class="tw-text-[12px]"
              variant="plain"
              value="tile"
              @click="onClickToggleView('tile')"
              >List</v-btn
            >
            <v-btn
              prepend-icon="mdi-map"
              :active="toggledView === 'map'"
              :color="toggledView === 'map' ? '#FAA10066' : ''"
              class="tw-text-[12px]"
              variant="plain"
              value="map"
              @click="onClickToggleView('map')"
              >Map</v-btn
            >
          </v-btn-toggle>
          <div class="sort__wrapper mobile__wrapper">
            <v-menu
              :persistent="true"
              :close-on-content-click="false"
              class="mobile_sort_menu"
              :class="{ 'is-map-view': toggledView === 'map' }"
            >
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain" @click="openSortButton">
                  Sort By
                  <span>
                    <img :src="require('@/assets/icons/sort.svg')" alt="" />
                  </span>
                </v-btn>
              </template>
              <v-radio-group v-model="filters.order">
                <v-radio
                  label="Most Recent"
                  value="DESC"
                  color="indigo"
                ></v-radio>
                <v-radio label="Oldest" value="ASC" color="indigo"></v-radio>
              </v-radio-group>
            </v-menu>
          </div>
        </div>

        <div
          class="tw-flex tw-gap-3 tw-mt-5 tw-mb-4 md:!tw-mt-0"
          :class="{ 'md:!tw-hidden': toggledView === 'map' }"
        >
          <Text variant="p" textColor="rgba(12, 15, 74, .5)" textWeight="400">
            <Text variant="p" textColor="rgba(12, 15, 74, .5)">{{
              totalJobCount
            }}</Text>
            Results
          </Text>
        </div>

        <div
          class="tw-flex tw-gap-4 tw-overflow-y-auto tw-overflow-x-hidden no-scrollbar md:tw-max-h-[unset] working-2"
          :class="
            toggledView === 'map' && jobsState.length
              ? 'md:tw-px-0 md:tw-overflow-x-auto'
              : ''
          "
        >
          <div
            class=""
            :class="
              toggledView === 'tile' || !jobsState.length
                ? 'tw-w-full '
                : ' tw-w-1/2 md:tw-absolute md:tw-z-[9] md:tw-w-full md:tw-max-w-full md:tw-overflow-x-auto  md:tw-flex  md:tw-items-end working-1 tw-max-h-full tw-overflow-auto  no-scrollbar md:tw-justify-center tw-h-full tw-pl-2 sm:!tw-w-[96%] md:!tw-h-[345px] xxs:!tw-h-[300px] md:!tw-overflow-y-auto md:!tw-overflow-y-auto md:!tw-bottom-[0] md:!tw-pr-[.3rem]'
            "
          >
            <div
              v-if="!loading && !jobsState.length"
              class="tw-w-[45%] tw-m-auto tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-9"
            >
              <div class="tw-flex tw-flex-col">
                <img
                  width="340"
                  height="168"
                  src="../../../assets/images/property-owner-dashboard.png"
                />
              </div>
              <div class="title">
                <h3 class="tw-mb-7">Find your next job, simply and easily.</h3>
                <p>Change your radius or add services to find more jobs.</p>
              </div>
            </div>
            <!-- ADD TABLE HERE -->
            <v-table
              v-if="!loading && jobsState.length"
              class="tw-bg-[#F8FBFD] tw-rounded-lg tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-w-full tw-hidden rmd:!tw-flex"
            >
              <thead>
                <tr>
                  <th class="text-left tw-w-[393px]">
                    <Text
                      class="!tw-text-[10.83px] !tw-uppercase"
                      whiteSpace="nowrap"
                      >Job Name</Text
                    >
                  </th>
                  <th class="text-left tw-w-[130px]">
                    <Text
                      class="!tw-text-[10.83px] !tw-uppercase"
                      whiteSpace="nowrap"
                      >Location</Text
                    >
                  </th>
                  <th class="text-left tw-w-[130px]">
                    <div class="tw-flex tw-gap-2 tw-items-center">
                      <Text
                        class="!tw-text-[10.83px] !tw-uppercase"
                        whiteSpace="nowrap"
                        >Client</Text
                      >
                      <!-- <v-icon
                        icon="mdi-information"
                        color="rgba(12, 15, 74, 0.5)"
                      ></v-icon> -->
                    </div>
                  </th>
                  <th class="text-left tw-w-[150px]">
                    <Text
                      class="!tw-text-[10.83px] !tw-uppercase"
                      whiteSpace="nowrap"
                      >Job Added</Text
                    >
                  </th>
                  <!-- <th class="text-left tw-w-[130px]">
                    <Text
                      class="!tw-text-[10.83px] !tw-uppercase"
                      whiteSpace="nowrap"
                      >Ests. Received</Text
                    >
                  </th> -->
                  <th class="text-left tw-w-[130px]">
                    <Text
                      class="!tw-text-[10.83px] !tw-uppercase"
                      whiteSpace="nowrap"
                      >Job type</Text
                    >
                  </th>
                  <th class="text-left tw-w-[130px]">
                    <Text
                      class="!tw-text-[10.83px] !tw-uppercase"
                      whiteSpace="nowrap"
                      >Property Type</Text
                    >
                  </th>
                  <th class="text-left tw-w-[120px]">
                    <Text
                      class="!tw-text-[10.83px] !tw-uppercase"
                      whiteSpace="nowrap"
                      >Action</Text
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(job, index) in jobsList" :key="index">
                  <td class="text-left">
                    <div
                      class="tw-w-full tw-max-w-[340px] tw-flex tw-items-center tw-justify-start"
                    >
                      <Text
                        variant="span"
                        whiteSpace="nowrap"
                        textWeight="500"
                        class="!tw-truncate"
                        >{{ job?.name }}</Text
                      >
                    </div>
                  </td>
                  <td class="text-left">
                    <Text variant="span" whiteSpace="nowrap" textWeight="500">{{
                      job?.location
                    }}</Text>
                  </td>
                  <td class="text-left">
                    <!-- <UnverifiedBadge /> -->
                    <div
                      class="tw-h-[24px] tw-bg-[#fff] tw-px-2 tw-rounded-md tw-border-solid tw-border-[1px] tw-border-[rgba(12,15,74,0.1)] tw-flex tw-gap-2 tw-items-center tw-justify-center"
                    >
                      <div
                        class="tag tw-rounded-full tw-flex tw-items-center tw-justify-center tw-w-[18px] tw-h-[18px]"
                      >
                        <img
                          v-if="job?.userWorkstation?.subscription"
                          class="tw-w-[16px] tw-h-[16px] tw-object-contain"
                          src="@/assets/icons/premium-bg-white.svg"
                        />
                        <img
                          v-else
                          class="tw-w-[16px] tw-h-[16px] tw-object-contain"
                          src="@/assets/icons/free-member-bg-white.svg"
                        />
                      </div>
                      <Text variant="span" whiteSpace="nowrap">
                        {{
                          job?.userWorkstation?.subscription
                            ? "PREMIUM"
                            : "FREE"
                        }}
                        <Text
                          variant="span"
                          whiteSpace="nowrap"
                          textColor="#FFA500"
                          >MEMBER</Text
                        >
                      </Text>
                    </div>
                  </td>
                  <td class="text-left">
                    <div
                      class="tw-h-[37px] tw-w-full tw-rounded-[6px] tw-border-solid tw-border-[1px] tw-border-[rgba(12,15,74,0.1)] tw-bg-white tw-px-4 tw-box-border tw-flex tw-gap-[.3rem] tw-items-center tw-justify-center"
                    >
                      <Text variant="span"
                        >{{ job?.elapsedTime?.days
                        }}<Text variant="span" textColor="#8587A4"
                          >D</Text
                        ></Text
                      >
                      <Text variant="span"
                        >{{ job?.elapsedTime?.hours
                        }}<Text variant="span" textColor="#8587A4"
                          >H</Text
                        ></Text
                      >
                      <Text variant="span"
                        >{{ job?.elapsedTime?.minutes
                        }}<Text variant="span" textColor="#8587A4"
                          >M</Text
                        ></Text
                      >
                      <Text variant="span"
                        >{{ job?.elapsedTime?.seconds
                        }}<Text variant="span" textColor="#8587A4"
                          >S</Text
                        ></Text
                      >
                    </div>
                  </td>
                  <!-- <td class="text-left">
                    <Text variant="span" whiteSpace="nowrap" textWeight="500"
                      >0</Text
                    >
                  </td> -->
                  <td class="text-left">
                    <EmergencyChip
                      label="Emergency"
                      v-if="job?.type === 'emergency'"
                      class="!tw-w-[97px]"
                    />
                    <StandardChip
                      v-if="job?.type === 'standard'"
                      class="!tw-w-[90px]"
                    />
                    <RoutineChip
                      v-if="job?.type === 'routine'"
                      class="!tw-w-[80px]"
                    />
                    <VideoConsultationChip
                      v-if="job?.type === 'video consultation'"
                    />
                  </td>
                  <td class="text-left">
                    <ResidentialChip
                      v-if="job?.propertyType?.isResidential"
                      class="!tw-p-0"
                    />
                    <CommercialChip
                      v-if="job?.propertyType?.isCommercial"
                      class="!tw-p-0"
                    />
                  </td>
                  <td class="text-left">
                    <Text
                      @click="setSelectedJob(job?.refId)"
                      variant="span"
                      whiteSpace="nowrap"
                      textWeight="500"
                      textColor="#4F55F0"
                      class="tw-cursor-pointer"
                      >View Details</Text
                    >
                  </td>
                </tr>
              </tbody>
            </v-table>

            <div
              v-if="!loading && jobsState.length"
              class="mobile-job-container scroll-container tw-gap-4 rmd:!tw-hidden"
            >
              <div
                v-for="(job, index) in jobsList"
                :key="index"
                class="tw-bg-[#F8FBFD] tw-w-full tw-rounded-lg tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-4"
              >
                <div
                  class="tw-w-full tw-flex tw-items-start tw-justify-between tw-gap-2"
                >
                  <div
                    class="tw-flex tw-gap-1 tw-flex-col tw-items-start tw-w-[66%]"
                  >
                    <Text variant="xsmall" lineHeight="18px">NAME</Text>
                    <Text
                      variant="span"
                      textWeight="400"
                      lineHeight="18px"
                      textAlign="left"
                      class="!tw-truncate !tw-w-full"
                      >{{ job?.name }}</Text
                    >
                  </div>
                  <!-- <UnverifiedBadge /> -->
                  <div
                    class="tw-h-[24px] tw-bg-[#fff] tw-px-2 tw-rounded-md tw-border-solid tw-border-[1px] tw-border-[rgba(12,15,74,0.1)] tw-flex tw-gap-2 tw-items-center tw-justify-center"
                  >
                    <div
                      class="tag tw-rounded-full tw-flex tw-items-center tw-justify-center tw-w-[18px] tw-h-[18px]"
                    >
                      <img
                        v-if="job?.userWorkstation?.subscription"
                        class="tw-w-[16px] tw-h-[16px] tw-object-contain"
                        src="@/assets/icons/premium-bg-white.svg"
                      />
                      <img
                        v-else
                        class="tw-w-[16px] tw-h-[16px] tw-object-contain"
                        src="@/assets/icons/free-member-bg-white.svg"
                      />
                    </div>
                    <Text variant="span" whiteSpace="nowrap">
                      {{
                        job?.userWorkstation?.subscription ? "PREMIUM" : "FREE"
                      }}
                      <Text
                        variant="span"
                        whiteSpace="nowrap"
                        textColor="#FFA500"
                        >MEMBER</Text
                      >
                    </Text>
                  </div>
                </div>

                <div class="tw-w-full tw-flex tw-items-center tw-gap-3">
                  <div
                    class="tw-flex tw-gap-1 tw-flex-col tw-items-start tw-w-[50%]"
                  >
                    <Text variant="xsmall" lineHeight="18px">LOCATION</Text>
                    <Text variant="span" textWeight="400" lineHeight="18px">{{
                      job?.location
                    }}</Text>
                  </div>
                  <div
                    class="tw-flex tw-gap-1 tw-flex-col tw-items-start tw-w-[50%]"
                  >
                    <Text variant="xsmall" lineHeight="18px"
                      >PROPERTY TYPE</Text
                    >
                    <ResidentialChip
                      v-if="job?.propertyType?.isResidential"
                      class="!tw-p-0"
                    />
                    <CommercialChip
                      v-if="job?.propertyType?.isCommercial"
                      class="!tw-p-0"
                    />
                  </div>
                </div>

                <div class="tw-w-full tw-flex tw-items-center tw-gap-3">
                  <div
                    class="tw-flex tw-gap-1 tw-flex-col tw-items-start tw-w-[50%]"
                  >
                    <Text variant="xsmall" lineHeight="18px">JOB ADDED</Text>
                    <div
                      class="tw-h-[35px] tw-w-full tw-rounded-[6px] tw-border-solid tw-border-[1px] tw-border-[rgba(12,15,74,0.1)] tw-bg-white tw-px-2 tw-box-border tw-flex tw-gap-[.3rem] tw-items-center tw-justify-center"
                    >
                      <Text variant="span"
                        >{{ job?.elapsedTime?.days
                        }}<Text variant="span" textColor="#8587A4"
                          >D</Text
                        ></Text
                      >
                      <Text variant="span"
                        >{{ job?.elapsedTime?.hours
                        }}<Text variant="span" textColor="#8587A4"
                          >H</Text
                        ></Text
                      >
                      <Text variant="span"
                        >{{ job?.elapsedTime?.minutes
                        }}<Text variant="span" textColor="#8587A4"
                          >M</Text
                        ></Text
                      >
                      <Text variant="span"
                        >{{ job?.elapsedTime?.seconds
                        }}<Text variant="span" textColor="#8587A4"
                          >S</Text
                        ></Text
                      >
                    </div>
                  </div>
                  <div
                    class="tw-flex tw-gap-1 tw-flex-col tw-items-start tw-w-[50%]"
                  >
                    <Text variant="xsmall" lineHeight="18px">JOB TYPE</Text>
                    <EmergencyChip
                      label="Emergency"
                      v-if="job?.type === 'emergency'"
                      class="!tw-w-[97px]"
                    />
                    <StandardChip
                      v-if="job?.type === 'standard'"
                      class="!tw-w-[90px]"
                    />
                    <RoutineChip
                      v-if="job?.type === 'routine'"
                      class="!tw-w-[80px]"
                    />
                    <VideoConsultationChip
                      v-if="job?.type === 'video consultation'"
                    />
                  </div>
                </div>

                <div class="tw-w-full tw-flex tw-items-center tw-gap-3">
                  <div class="tw-flex tw-gap-1 tw-flex-col tw-items-start">
                    <Text variant="xsmall" lineHeight="18px"
                      >ESTS. RECEIVED</Text
                    >
                    <Text variant="span" textWeight="400" lineHeight="18px"
                      >0</Text
                    >
                  </div>
                </div>

                <div
                  class="tw-w-full tw-flex tw-items-center tw-justify-start tw-pt-3 tw-border-t-dashed-custom"
                >
                  <Text
                    @click="setSelectedJob(job?.refId)"
                    variant="span"
                    textWeight="500"
                    textColor="#4F55F0"
                    class="!tw-underline !tw-cursor-pointer"
                    lineHeight="18px"
                    >View Details</Text
                  >
                </div>
              </div>
            </div>
            <!-- <div
              v-if="!loading && jobsState.length"
              class="tw-flex tw-gap-5 tw-flex-wrap scroll-container"
              :class="
                toggledView === 'map' && jobsState.length ? 'working ' : ''
              "
            >
              <JobCard
                v-for="job in jobsList"
                :key="job.refId"
                @onSelect="setSelectedJob"
                :value="job.refId"
                :job="job"
                jobType="all"
                class="!tw-w-full md:!tw-min-h-[350px]"
                :class="`${
                  selectedJob === job.refId
                    ? 'tw-shadow-[0_0_0_4px_#FAA100_inset]'
                    : ''
                }`"
              />
            </div> -->
            <div
              v-if="isLoadingMorePage"
              class="tw-absolute tw-bottom-[150px] tw-left-1/2 tw-translate-1/2 md:!tw-left-[40%]"
            >
              <CommonLoader
                v-if="isLoadingMorePage"
                :loading="isLoadingMorePage"
                class="loader-container"
              />
            </div>
          </div>
          <template v-if="toggledView === 'map' && jobsState.length">
            <div
              class="tw-w-[calc(50%-16px)] tw-max-w-full md:tw-w-full md:tw-relative md:z-0 tw-h-[620px] md:!tw-h-[calc(100vh-18.5rem)]"
            >
              <!-- <JobMap :data="jobsState" /> -->
              <PropertyMapModal :properties="jobPropertyAddress" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </main>
  <PageRestrictionNoticeModal
    v-if="isShowWorkstationRestrictionModal"
    @on-close="toggleRestrictionModal"
  />
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import JobCard from "./JobCard.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import Text from "@/core/components/ui/general/Text.vue";

import JobMap from "./JobMap.vue";
import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { JOB_DETAILS_ROUTE } from "../routes";
import { computed } from "vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { JobFilters, JobsState } from "@/core/models/jobs";
import debounce from "lodash/debounce";
import { USER_STORE } from "@/store/modules/user";
import {
  ProjectOwnerTypesEnum,
  ProjectTypesEnum,
  PropertyCategoryEnum,
} from "@/core/enums/ProjectsEnum";
import { WORKSTATION } from "@/store/modules/workstation";
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";
import moment from "moment";
import Loader from "@/core/components/common/Loader.vue";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import PropertyMapModal from "@/modules/properties/components/Modals/PropertyMapModal.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import VerifiedBadge from "@/core/components/common/VerifiedBadge.vue";
import UnverifiedBadge from "@/core/components/common/UnverifiedBadge.vue";
// import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import ResidentialChip from "@/core/uni-components/Chips/ResidentialChip.vue";
import VideoConsultationChip from "@/core/uni-components/Chips/VideoConsultationChip.vue";
import ChipState from "@/core/components/common/ChipState.vue";

const router = useRouter();
const store = useStore();
const loading = ref(false);
const selectedJob = ref("");
const toggledView = ref("tile");
const isShowWorkstationRestrictionModal = ref(false);
const listOfCategories = ref([]) as any;
const panel = ref([]);
const isLoadingMorePage = ref(false);
const selectedJobTypeIcon = ref(null) as any;
const selectedMemberIcon = ref(null) as any;
const selectedPropertyTypeIcon = ref(null) as any;

const handleJobType = (selectedValue: any) => {
  const selected = jobTypes.value.find(
    (item: any) => item.value === selectedValue
  );
  selectedJobTypeIcon.value = selected ? selected.iconName : null;
};
const handleMemberStatus = (selectedValue: any) => {
  const selected = propertiesMembers.value.find(
    (item: any) => item.value === selectedValue
  );
  selectedMemberIcon.value = selected ? selected.iconName : null;
};
const handlePropertyType = (selectedValue: any) => {
  const selected = propertyTypeItems.value.find(
    (item: any) => item.value === selectedValue
  );
  selectedPropertyTypeIcon.value = selected ? selected.iconName : null;
};
const jobTypes = ref([
  {
    text: "All",
    value: null,
    iconName: "all-jobs-filter",
  },
  {
    text: "Emergency",
    value: ProjectTypesEnum.EMERGENCY,
    iconName: "emergency-filter",
  },
  {
    text: "Routine ",
    value: ProjectTypesEnum.ROUTINE,
    iconName: "routine-filter",
  },
  {
    text: "Standard",
    value: ProjectTypesEnum.STANDARD,
    iconName: "standard-filter",
  },

  {
    text: "Video Consultation",
    value: ProjectTypesEnum.VIDEO_CONSULTATION,
    iconName: "video-filter",
  },
]);

const propertyTypeItems = computed(() => {
  return [
    {
      text: "All",
      value: null,
      iconName: "all-jobs-filter",
    },
    {
      text: "Commercial",
      value: PropertyCategoryEnum.COMMERCIAL,
      iconName: "commercial-filter",
    },
    {
      text: "Residential",
      value: PropertyCategoryEnum.RESIDENTIAL,
      iconName: "residential-filter",
    },
  ];
});
const propertiesMembers = computed(() => {
  return [
    {
      text: "All",
      value: "",
      iconName: "all-jobs-filter",
    },
    {
      text: "Premium Property Member",
      value: ProjectOwnerTypesEnum.PREMIUM_PROPERTY_MEMBER,
      iconName: "premium-filter",
    },
    {
      text: "Free Property Member",
      value: ProjectOwnerTypesEnum.FREE_PROPERTY_MEMBER,
      iconName: "Commercial",
    },
    {
      text: "External Clients",
      value: ProjectOwnerTypesEnum.EXTERNAL_CLIENTS,
      iconName: "Commercial",
    },
  ];
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const totalJobCount = computed(
  () => store.getters[`${JOBS_STORE}/getTotalJobs`]
);

const jobsState = computed<JobsState["jobs"]>(
  () => store.getters[`${JOBS_STORE}/getJobs`]
);

const jobPropertyAddress = computed(() => {
  let address: any = [];

  jobsState.value.forEach((job: any) => {
    if (job?.projectProperties?.length) {
      job?.projectProperties?.forEach((item: any) => {
        address.push({ addressData: item?.property?.addressData });
      });
    }
  });
  return address;
});

const jobsList = ref<any>([]);

const getPropertyCategory = (properties: any[] = []) => ({
  isResidential: properties.some(
    (property) =>
      property?.propertyCategory === PropertyCategoryEnum.RESIDENTIAL
  ),
  isCommercial: properties.some(
    (property) => property?.propertyCategory === PropertyCategoryEnum.COMMERCIAL
  ),
});

const calculateProperties = (properties: any[] = []) => {
  if (properties.length > 1) {
    return `${properties.length.toString()} Properties`;
  } else {
    return properties[0]?.postcode ?? "N/A"; // Return "N/A" if postcode is missing
  }
};

// const calfun = () => {
//   jobsList.value = jobsState.value?.map((job: any) => {
//     job.elapsedTime = calculateElapsedTime(job?.dateCreated);
//     job.location = calculateProperties(job?.properties);
//     return job;
//   });
// };

const realTimeSetInterval = setInterval(() => {
  calfun();
}, 500);

const calculateElapsedTime = (date: string): any => {
  const now = moment();
  const createdDate = moment(date);

  const total_seconds = now.diff(createdDate, "seconds");
  const total_minutes = now.diff(createdDate, "minutes");
  const total_hours = now.diff(createdDate, "hours");
  const days = now.diff(createdDate, "days");
  // const weeks = now.diff(createdDate, "weeks");
  // const months = now.diff(createdDate, "months");
  // const years = now.diff(createdDate, "years");

  // if (years > 0) {
  //   return `${years} Year${years > 1 ? "s" : ""}`;
  // } else if (months > 0) {
  //   return `${months} Month${months > 1 ? "s" : ""}`;
  // } else if (weeks > 0) {
  //   return `${weeks} Week${weeks > 1 ? "s" : ""}`;

  const hours = total_hours - days * 24; // Adjust hours based on full days
  const minutes = total_minutes - total_hours * 60; // Adjust minutes based on total hours
  const seconds = total_seconds - total_minutes * 60; // Adjust seconds based on total minutes

  let final_time = "";

  if (total_seconds > 0) {
    if (days > 0) {
      final_time += `${days}D `;
    }
    if (hours > 0) {
      final_time += `${hours}H `;
    }
    if (minutes > 0) {
      final_time += `${minutes}M `;
    }
    if (seconds > 0) {
      final_time += `${seconds}S`;
    }
  }
  return {
    days,
    hours,
    minutes,
    seconds,
    fullTime: final_time.trim(),
  };
  // return final_time.trim(); // Trim any extra spaces
};

// Function to update jobsList
const calfun = () => {
  jobsList.value = jobsState.value?.map((job: any) => ({
    ...job,
    elapsedTime: calculateElapsedTime(job?.dateCreated),
    location: calculateProperties(job?.properties),
    propertyType: getPropertyCategory(job?.properties || []), // Adding propertyType
  }));
};

// Watch `jobsState` to update jobsList automatically
// watch(
//   jobsState,
//   () => {
//     calfun();
//   },
//   { deep: true }
// );

const selectedCategory = ref([]);
const filters = reactive({
  search: "",
  postcode: "",
  radius: "",
  projectTypes: null,
  projectOwnerType: null,
  propertyCategory: null,
  specialism: [],
  limit: 20,
  page: 1,
  // order: "ASC",
}) as any;

const prevPage = ref(filters.page);
const clearSelection = (field: any) => {
  filters[field] = null;
};
const isWorkStationVerified = computed(() => {
  return (
    store.getters[`${WORKSTATION}/activeUserWorkstation`]
      ?.verificationStatus === UserVerificationStatusEnum.VERIFIED
  );
});
const setSelectedJob = async (value: string) => {
  if (!isWorkStationVerified.value) {
    toggleRestrictionModal();
    return;
  }
  store.commit(`${JOBS_STORE}/setJobDetails`, {});

  // store.dispatch(`${JOBS_STORE}/fetchJobDetails`, value);

  router.push({ name: JOB_DETAILS_ROUTE, params: { refId: value } });
  selectedJob.value = value;
};

const setDefaultSelectedJob = async (job: any) => {
  toggleRestrictionModal();
  // store.commit(`${JOBS_STORE}/setJobDetails`, job);
  // router.push({ name: JOB_DETAILS_ROUTE, params: { refId: job.refId } });
  // selectedJob.value = job;
};

const onClickToggleView = (value: string) => {
  toggledView.value = value;
};

// FetchJobs
const refetchJobs = debounce(async (state: JobFilters) => {
  loading.value = true;
  console.log("state", state);
  try {
    await store.dispatch(`${JOBS_STORE}/fetchJobs`, {
      ...state,
    });
  } catch (err) {
    console.log("Errror", err);
  } finally {
    loading.value = false;
  }
}, 500);

watch(
  filters,
  async (newState: any, oldState: any) => {
    let params = JSON.parse(JSON.stringify({ ...newState }));
    if (newState.page == prevPage.value && newState.page != 1) {
      newState.page = 1;
    }
    prevPage.value = newState.page;
    // if (params.projectOwnerType === "All (Project Owners)") {
    //   // Handle projectOwnerType logic
    //   delete params.projectOwnerType;
    // } else if (params.projectOwnerType === "Verified & Clients") {
    //   params.projectOwnerType = true;
    // }

    if (!params.specialism?.length) {
      delete params.specialism;
    } else {
      params.specialism = params.specialism.toString();
    }

    params = Object.fromEntries(
      Object.entries(params).filter((value: any) =>
        value[1] === 0 ? true : value[1]
      )
    );
    if (filters.page > 1) {
      await store.dispatch(`${JOBS_STORE}/fetchJobs`, {
        ...params,
      });
      isLoadingMorePage.value = false;
    } else {
      refetchJobs(params);
    }
  },
  { deep: true }
);

const openSortButton = () => {
  panel.value = [];
};

const totalPages = computed(() => store.getters[`${JOBS_STORE}/getTotalPages`]);
const onScroll = (event: Event) => {
  const container = event.target as HTMLElement;
  const tolerance = 1;
  if (
    Math.abs(
      container.scrollHeight - container.scrollTop - container.clientHeight
    ) <= tolerance
  ) {
    if (filters.page + 1 <= totalPages.value) {
      isLoadingMorePage.value = true;
      setTimeout(() => {
        filters.page += 1;
      }, 1000);
    }
  }
};

onMounted(async () => {
  loading.value = true;
  listOfCategories.value = await store.dispatch(`${USER_STORE}/getCategories`);
  let postData = JSON.parse(JSON.stringify({ ...filters }));
  if (!postData.specialism?.length) {
    delete postData["specialism"];
  } else {
    postData.specialism = postData.specialism.toString();
  }
  postData = Object.fromEntries(
    Object.entries(postData).filter((value: any) =>
      value[1] === 0 ? true : value[1]
    )
  );

  // if (user.value.postcode) {
  //   postData.postcode = user.value.postcode;
  //   filters.postcode = user.value.postcode;
  // }

  try {
    await store.dispatch(`${JOBS_STORE}/fetchJobs`, postData);
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(() => {
      loading.value = false;
    }, 500);

    jobsList.value = jobsState.value.map((job: any) => ({
      ...job,
      location: calculateProperties(job?.properties),
    }));
    console.log(jobsState.value, "jobsState");
    console.log(jobsList.value, "jobsList");
  }
});

const clearPropertyCategorySelection = () => {
  filters.propertyCategory = null;
};

const toggleRestrictionModal = () => {
  isShowWorkstationRestrictionModal.value =
    !isShowWorkstationRestrictionModal.value;
};

// const workstationPostcode = (type: string) => {
//   if (activeUserWorkstation.value?.user) {
//     return type === BusinessDetailTypeEnum.LIMITED_COMPANY
//       ? activeUserWorkstation.value?.user?.userBusinessDetails?.businessPostcode
//       : activeUserWorkstation.value?.user?.userBusinessDetails
//           ?.residencyPostcode;
//   } else {
//     return null;
//   }
// };

onUnmounted(() => {
  clearInterval(realTimeSetInterval);
});
</script>
<style lang="scss" scoped>
.input_field {
  :deep(.v-input__control) {
    .v-field {
      gap: 8px;
    }
  }
}
.custom-select {
  @include respond(md) {
    width: 100% !important;
  }
}
main {
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
  padding-right: 0.7rem;
  // padding-right: 0;
}
.scroll-container {
  // max-height: calc(100vh - 21rem);
  // overflow-y: scroll;
  padding-bottom: 5rem !important;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

  max-height: unset;
  height: 100%;
  overflow-y: unset;
  @include respond(md) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    max-height: unset;
    height: 100%;
    overflow-y: unset;
  }
}
/* Sort Button */
.sort__wrapper {
  .v-btn {
    padding: 0;
    height: auto;
    :deep(.v-btn__content) {
      text-transform: capitalize;
      display: flex;
      gap: 10px;
      align-items: center;
      span {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: rgba($blueDark, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    :deep(.v-ripple__container) {
      display: none !important;
    }
  }
}
.v-menu {
  :deep(.v-overlay__content) {
    background-color: rgba($white, 1);
    min-width: 185px !important;
    border-radius: 16px;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    padding: 16px;
    // top: 222px !important;
    // left: auto !important;
    // right: 40px !important;
    // @include respond(xlg) {
    //   right: 10px !important;
    // }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   width: 30px;
    //   height: 20px;
    //   background-image: url(../../../assets/icons/triangle.svg);
    //   right: 10px;
    //   top: -10px;
    // }
    .v-input {
      .v-input__control {
        .v-selection-control-group {
          gap: 10px;
          .v-selection-control {
            gap: 8px;
            .v-selection-control__wrapper {
              width: auto;
              height: auto;
              .v-selection-control__input {
                width: auto;
                height: auto;
              }
            }
            .v-label {
              @include fluidFont(14, 14, 1.3);
              font-weight: 600;
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
/* mobile Sort */
.mobile_sort_menu {
  :deep(.v-overlay__content) {
    right: auto !important;
    left: 140px !important;
    top: 180px !important;
    @include respond(s1024) {
      // right: 10px !important;
      // left: auto !important;
      // top: 400px !important;
      right: 20px !important;
      left: auto !important;
      top: 295px !important;
    }
    @include respond(sm) {
      left: auto !important;
      // right: 0 !important;
      // top: 450px !important;
      // right: 14px !important;
      // top: 277px !important;
      // top: 231px !important;
      // right: 5px !important;
      top: 243px !important;
      right: 17px !important;
    }
  }
}
.mobile_sort_menu.is-map-view {
  :deep(.v-overlay__content) {
    @include respond(sm) {
      left: auto !important;
      top: 169px !important;
      right: 9px !important;
    }
  }
}
/*Mobile Filters */
.mobile__filter {
  :deep(.v-expansion-panels) {
    .v-expansion-panel {
      background-color: transparent;
      border: 1px solid #c5d2f2;
      border-radius: 8px;
      .v-expansion-panel__shadow {
        box-shadow: none;
      }
      .v-expansion-panel-title {
        .v-expansion-panel-title__overlay {
          background-color: transparent;
        }
        img {
          width: 16px;
          margin-right: 8px;
        }
        h6 {
          @include fluidFont(12, 12, 1.2);
          font-weight: 700;
          letter-spacing: 0.4px;
          color: rgba($blueDark, 1);
        }
      }
      .v-expansion-panel-text {
        .v-expansion-panel-text__wrapper {
          padding: 8px 24px 16px;
          flex: 1 1 auto;
          max-width: 100%;
          .mobile__inputs {
            .v-input {
              width: 100%;
              max-width: 100%;
              min-width: unset;
              background-color: transparent;
              margin-bottom: 0;
              .v-input__control {
                .v-field {
                  padding: 0 10px;
                  border: 1px solid rgba($buttonText, 0.4);
                  border-radius: 8px;
                  background-color: transparent;
                  .v-field__prepend-inner {
                    padding: 0;
                    width: 16px;
                    height: 16px;
                    .v-icon {
                      opacity: 1;
                      @include fluidFont(16, 16, 1);
                    }
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      filter: invert(13%) sepia(20%) saturate(5279%)
                        hue-rotate(219deg) brightness(80%) contrast(112%);
                      vertical-align: middle;
                    }
                  }
                  .v-field__field {
                    display: block;
                    .v-field__input {
                      padding: 0;
                      background-color: transparent;
                      min-height: 40px;
                      padding-left: 8px;
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      max-width: 100%;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      margin: 0;
                      top: 0;
                      input {
                        top: 50%;
                        transform: translateY(-50%);
                        &::placeholder {
                          color: rgba($blueDark, 1);
                          opacity: 1;
                        }
                      }
                    }
                  }
                  .c-input .v-field__outline {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.v-expansion-panel-title:hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}

.v-input {
  width: 100%;
  min-width: unset;
  background-color: transparent;
  margin-bottom: 0;
  :deep(.v-input__control) {
    .v-field {
      padding: 0 10px;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      background-color: rgba($white, 1);
      align-items: center;
      gap: 6px;
      .v-field__prepend-inner {
        padding: 0;
        width: 16px;
        height: 16px;
        .v-icon {
          opacity: 1;
          @include fluidFont(16, 16, 1);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          // filter: invert(13%) sepia(20%) saturate(5279%) hue-rotate(219deg)
          //   brightness(80%) contrast(112%);
          vertical-align: middle;
        }
      }
      .v-field__field {
        display: block;
        .v-field__input {
          padding: 0 !important;
          background-color: transparent;
          min-height: 40px;
          padding-left: 8px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          letter-spacing: 0.4px;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0;
          top: 0;
          input {
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            &::placeholder {
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
        }
      }
      .v-field__outline {
        display: none;
        &::before {
          border: none;
        }
      }
    }
  }
}
.v-field--variant-filled .v-field__outline::before,
.v-field--variant-underlined .v-field__outline::before {
  border: none;
}
.tab__buttons {
  padding: 0;
  opacity: 1;
  :deep(.v-btn__content) {
    @include fluidFont(12, 12, 1.2);
    font-weight: 600;
    text-transform: capitalize;
    color: rgba($blueDark, 0.4);
  }
  :deep(.v-btn__prepend) {
    color: rgba($blueDark, 0.4);
  }
}
.v-btn--active {
  :deep(.v-btn__content) {
    color: rgba($orange, 1);
  }
  :deep(.v-btn__prepend) {
    color: rgba($orange, 1);
  }
}
.description {
  text-align: left;
  margin: 0;
}

@media (max-width: 767px) {
  .working {
    flex-wrap: unset;
  }
}
// .all-jobs-wrapper {
//   .v-icon {
//     position: absolute;
//     left: 10px;
//     top: 50%;
//     transform: translateY(-50%);
//     z-index: 1;
//   }
//   .input_field {
//     :deep(.v-input__control) {
//       .v-field {
//         .v-field__field {
//           .v-field__input {
//             input::placeholder {
//               padding-left: 25px;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
