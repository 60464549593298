import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c6e9e92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trade_passport_wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "overview tw-pb-12 rlg:!tw-pb-16"
}
const _hoisted_4 = {
  key: 0,
  class: "tw-w-full tw-mb-3 tw-flex tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[rgba(255,165,0,0.2)] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
}
const _hoisted_5 = {
  key: 1,
  class: "tw-w-full tw-mb-3 tw-flex tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[rgba(255,165,0,0.2)] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
}
const _hoisted_6 = {
  key: 2,
  class: "btn-import-workshop"
}
const _hoisted_7 = {
  key: 0,
  class: "back-btn"
}
const _hoisted_8 = { class: "overview-content tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-start rlg:!tw-flex-row" }
const _hoisted_9 = { class: "tw-w-full tw-flex tw-flex-col tw-gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_NoticeIcon = _resolveComponent("NoticeIcon")!
  const _component_Text = _resolveComponent("Text")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_WorkStationLinkTreeWidget = _resolveComponent("WorkStationLinkTreeWidget")!
  const _component_Card = _resolveComponent("Card")!
  const _component_TradePassportProfileHeader = _resolveComponent("TradePassportProfileHeader")!
  const _component_TradePassportSummary = _resolveComponent("TradePassportSummary")!
  const _component_TradePassportAbout = _resolveComponent("TradePassportAbout")!
  const _component_TradePassportServices = _resolveComponent("TradePassportServices")!
  const _component_TradePassportInsurances = _resolveComponent("TradePassportInsurances")!
  const _component_TradePassportCertificate = _resolveComponent("TradePassportCertificate")!
  const _component_TradePassportExperience = _resolveComponent("TradePassportExperience")!
  const _component_TradePassportClients = _resolveComponent("TradePassportClients")!
  const _component_TradePassportDbsPoliceCheck = _resolveComponent("TradePassportDbsPoliceCheck")!
  const _component_TradePassportGalleryAttachments = _resolveComponent("TradePassportGalleryAttachments")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_TradePassportCertification = _resolveComponent("TradePassportCertification")!
  const _component_TradePassportInsurance = _resolveComponent("TradePassportInsurance")!
  const _component_TradePassportSpecialism = _resolveComponent("TradePassportSpecialism")!
  const _component_TradePassportGallery = _resolveComponent("TradePassportGallery")!
  const _component_TradePassportJobs = _resolveComponent("TradePassportJobs")!
  const _component_TradePassportReviews = _resolveComponent("TradePassportReviews")!
  const _component_CertificateEditModal = _resolveComponent("CertificateEditModal")!
  const _component_AddInsuranceModal = _resolveComponent("AddInsuranceModal")!
  const _component_DescriptionEditorComponent = _resolveComponent("DescriptionEditorComponent")!
  const _component_TaxAndVatModel = _resolveComponent("TaxAndVatModel")!
  const _component_EditWorkStationDetailsPopup = _resolveComponent("EditWorkStationDetailsPopup")!
  const _component_AddUserRatesModal = _resolveComponent("AddUserRatesModal")!
  const _component_AddUserExperienceModal = _resolveComponent("AddUserExperienceModal")!
  const _component_TeamMemberModal = _resolveComponent("TeamMemberModal")!
  const _component_ConfirmDeleteModal = _resolveComponent("ConfirmDeleteModal")!
  const _component_ImportWorkStationModal = _resolveComponent("ImportWorkStationModal")!
  const _component_SelectTeamMemberModal = _resolveComponent("SelectTeamMemberModal")!
  const _component_AssignModal = _resolveComponent("AssignModal")!
  const _component_ConfirmAssignModal = _resolveComponent("ConfirmAssignModal")!
  const _component_DocumentDeleteConfirmationModal = _resolveComponent("DocumentDeleteConfirmationModal")!
  const _component_GalleryPhotoPreview = _resolveComponent("GalleryPhotoPreview")!
  const _component_FreezeInsuranceExpired = _resolveComponent("FreezeInsuranceExpired")!
  const _component_AddBusinessAddressPreviewModal = _resolveComponent("AddBusinessAddressPreviewModal")!
  const _component_CompleteIDVerificationPreviewModal = _resolveComponent("CompleteIDVerificationPreviewModal")!
  const _component_TradePassportPreviewModal = _resolveComponent("TradePassportPreviewModal")!
  const _component_WorkstationForVerificationPreviewModal = _resolveComponent("WorkstationForVerificationPreviewModal")!
  const _component_WorkstationVerificationModal = _resolveComponent("WorkstationVerificationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_tabs, {
        "onUpdate:modelValue": [
          $setup.onTabChange,
          _cache[0] || (_cache[0] = ($event: any) => (($setup.activeTab) = $event))
        ],
        class: "tab-list",
        "align-tabs": "center",
        stacked: "",
        modelValue: $setup.activeTab
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tradeItems, (item) => {
            return (_openBlock(), _createBlock(_component_v_tab, {
              key: item.name,
              value: item.name,
              disabled: $setup.isItemDisable(item?.name)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  icon: item.icon
                }, null, 8, ["icon"]),
                _createTextVNode(" " + _toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["value", "disabled"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onUpdate:modelValue", "modelValue"]),
      ($setup.isLoading && $setup.activeTab === 'Overview')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Loader, { show: $setup.isLoading }, null, 8, ["show"])
          ]))
        : _createCommentVNode("", true),
      ($setup.activeTab === 'Overview' && !$setup.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (
          $setup.activeUserWorkstation?.verificationStatus ===
            $setup.UserVerificationStatusEnum.NOT_ADDED &&
          (($setup.getVerificationStatus.statusClass === 'info' &&
            !$setup.isLoading &&
            $setup.activeUserWorkstation?.verificationStatus !==
              $setup.UserVerificationStatusEnum.VERIFIED &&
            $setup.accountStatuses?.forms?.insurance !== 0 &&
            $setup.accountStatuses?.forms?.certification !== 0 &&
            $setup.userBusinessType === $setup.BusinessDetailTypeEnum.SOLETRADER &&
            $setup.accountStatuses?.forms?.skills !== 0 &&
            $setup.getUserWorkStationFieldAccess) ||
            ($setup.activeUserWorkstation?.verificationStatus ===
              $setup.UserVerificationStatusEnum.VERIFIED &&
            $setup.userBusinessType === $setup.BusinessDetailTypeEnum.SOLETRADER
              ? $setup.activeUserWorkstation?.user?.userKYCVerificationStatus ===
                $setup.UserVerificationStatusEnum.NOT_ADDED
              : false))
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_NoticeIcon),
                  (
            !$setup.activeUserWorkstation?.user?.userBusinessDetails
              ?.residencyPostcode &&
            $setup.userBusinessType === $setup.BusinessDetailTypeEnum.SOLETRADER
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 0,
                        variant: "span",
                        textColor: "#0C0F4A",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Add Business Address. "),
                          _createVNode(_component_Text, {
                            class: "tw-cursor-pointer tw-underline",
                            variant: "span",
                            textColor: "#4B4BFF",
                            onClick: $setup.onClickAddBusinessAddressModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Manage Address")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
            $setup.activeUserWorkstation?.user?.userKYCVerificationStatus ===
              $setup.UserVerificationStatusEnum.NOT_ADDED &&
            $setup.activeUserWorkstation?.user?.userBusinessDetails
              ?.residencyPostcode &&
            $setup.userBusinessType === $setup.BusinessDetailTypeEnum.SOLETRADER &&
            $setup.activeUserWorkstation?.verificationStatus !==
              $setup.UserVerificationStatusEnum.PENDING_VERIFICATION
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 1,
                        variant: "span",
                        textColor: "#FA4B00",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Complete ID Verification. "),
                          _createVNode(_component_Text, {
                            class: "tw-cursor-pointer tw-underline",
                            variant: "span",
                            textColor: "#4B4BFF",
                            onClick: $setup.onClickIDVerificationModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Get Verified")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
            ($setup.userBusinessType === $setup.BusinessDetailTypeEnum.LIMITED_COMPANY
              ? $setup.activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : $setup.activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode) &&
            ($setup.activeUserWorkstation?.user?.userKYCVerificationStatus ===
              $setup.UserVerificationStatusEnum.PENDING_VERIFICATION ||
              $setup.activeUserWorkstation?.user?.userKYCVerificationStatus ===
                $setup.UserVerificationStatusEnum.VERIFIED) &&
            !$setup.isUserProfileVerified
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 2,
                        variant: "span",
                        textColor: "#FA4B00",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Complete Your Trade Passport. "),
                          _createVNode(_component_Text, {
                            class: "tw-cursor-pointer tw-underline",
                            variant: "span",
                            textColor: "#4B4BFF"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Manage Trade Passport")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
            $setup.activeUserWorkstation?.verificationStatus ==
              $setup.UserVerificationStatusEnum.NOT_ADDED &&
            $setup.isUserProfileVerified &&
            $setup.activeUserWorkstation?.user?.userBusinessDetails
              ?.residencyPostcode &&
            $setup.activeUserWorkstation?.user?.userKYCVerificationStatus !==
              $setup.UserVerificationStatusEnum.NOT_ADDED
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 3,
                        variant: "span",
                        textColor: "#FA4B00",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Complete Workstation Verification. "),
                          _createVNode(_component_Text, {
                            class: "tw-cursor-pointer tw-underline",
                            variant: "span",
                            textColor: "#4B4BFF",
                            onClick: $setup.onClickSubmitVerification
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Get Verified")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
            $setup.activeUserWorkstation?.verificationStatus ==
              $setup.UserVerificationStatusEnum.PENDING_VERIFICATION &&
            $setup.isUserProfileVerified &&
            $setup.activeUserWorkstation?.user?.userBusinessDetails?.residencyPostcode
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 4,
                        variant: "span",
                        textColor: "#FA4B00",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Verification Pending. ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (
          $setup.activeUserWorkstation?.verificationStatus ===
            $setup.UserVerificationStatusEnum.NOT_ADDED &&
          (($setup.getVerificationStatus.statusClass === 'info' &&
            !$setup.isLoading &&
            $setup.activeUserWorkstation?.verificationStatus !==
              $setup.UserVerificationStatusEnum.VERIFIED &&
            $setup.accountStatuses?.forms?.insurance !== 0 &&
            $setup.accountStatuses?.forms?.certification !== 0 &&
            $setup.userBusinessType === $setup.BusinessDetailTypeEnum.LIMITED_COMPANY &&
            $setup.accountStatuses?.forms?.skills !== 0 &&
            $setup.getUserWorkStationFieldAccess) ||
            ($setup.activeUserWorkstation?.verificationStatus ===
              $setup.UserVerificationStatusEnum.VERIFIED &&
              $setup.userBusinessType === $setup.BusinessDetailTypeEnum.LIMITED_COMPANY &&
              !$setup.WorkStationSiltVerificationStatus))
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_NoticeIcon),
                  (
            !$setup.activeUserWorkstation?.user?.userBusinessDetails
              ?.businessPostcode &&
            $setup.userBusinessType === $setup.BusinessDetailTypeEnum.LIMITED_COMPANY
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 0,
                        variant: "span",
                        textColor: "#0C0F4A",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Add Business Address. "),
                          _createVNode(_component_Text, {
                            class: "tw-cursor-pointer tw-underline",
                            variant: "span",
                            textColor: "#4B4BFF",
                            onClick: $setup.onClickAddBusinessAddressModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Manage Address")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
            !$setup.WorkStationSiltVerificationStatus &&
            $setup.activeUserWorkstation?.user?.userBusinessDetails
              ?.businessPostcode &&
            $setup.userBusinessType === $setup.BusinessDetailTypeEnum.LIMITED_COMPANY &&
            $setup.userWorkStationStatus !==
              $setup.UserVerificationStatusEnum.PENDING_VERIFICATION
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 1,
                        variant: "span",
                        textColor: "#FA4B00",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Complete ID & Business Verification. "),
                          _createVNode(_component_Text, {
                            class: "tw-cursor-pointer tw-underline",
                            variant: "span",
                            textColor: "#4B4BFF",
                            onClick: $setup.onClickIDVerificationModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Get Verified")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString($setup.WorkStationSiltVerificationStatus) + " ", 1),
                  (
            ($setup.WorkStationSiltVerificationStatus &&
            $setup.userBusinessType === $setup.BusinessDetailTypeEnum.LIMITED_COMPANY
              ? $setup.activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : $setup.activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode) &&
            ($setup.activeUserWorkstation?.user?.userKYCVerificationStatus ===
              $setup.UserVerificationStatusEnum.PENDING_VERIFICATION ||
              $setup.activeUserWorkstation?.user?.userKYCVerificationStatus ===
                $setup.UserVerificationStatusEnum.VERIFIED) &&
            !$setup.isUserProfileVerified
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 2,
                        variant: "span",
                        textColor: "#FA4B00",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Complete Your Trade Passport. "),
                          _createVNode(_component_Text, {
                            class: "tw-cursor-pointer tw-underline",
                            variant: "span",
                            textColor: "#4B4BFF",
                            onClick: $setup.onClickManageTradePassportPreviewModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Manage Trade Passport")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
            $setup.isUserVerifiedKYCAndKYB &&
            $setup.isUserProfileVerified &&
            ($setup.userBusinessType === $setup.BusinessDetailTypeEnum.LIMITED_COMPANY
              ? $setup.activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : $setup.activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode) &&
            $setup.userWorkStationStatus === $setup.UserVerificationStatusEnum.NOT_ADDED
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 3,
                        variant: "span",
                        textColor: "#FA4B00",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Complete Workstation Verification. "),
                          _createVNode(_component_Text, {
                            class: "tw-cursor-pointer tw-underline",
                            variant: "span",
                            textColor: "#4B4BFF",
                            onClick: $setup.onClickSubmitVerification
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Get Verified")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
            $setup.activeUserWorkstation?.verificationStatus ===
              $setup.UserVerificationStatusEnum.PENDING_VERIFICATION &&
            $setup.isUserProfileVerified &&
            ($setup.userBusinessType === $setup.BusinessDetailTypeEnum.LIMITED_COMPANY
              ? $setup.activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : $setup.activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode)
          )
                    ? (_openBlock(), _createBlock(_component_Text, {
                        key: 4,
                        variant: "span",
                        textColor: "#FA4B00",
                        textAlign: "left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Action Required: Verification Pending. ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (
          $setup.isUserProprtyOwner &&
          $setup.userAllWorkStation?.length > 2 &&
          $setup.getUserWorkStationFieldAccess
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  ($setup.isUserProprtyOwner)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_BackButton, {
                          label: 
              $setup.isShowRates ? 'Back to Your Estimates' : 'Back To Dashboard'
            ,
                          onClick: $setup.onClickBackEstimateButton
                        }, null, 8, ["label", "onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (
          !$setup.isShowRates &&
          $setup.activeUserWorkstation?.verificationStatus ===
            $setup.UserVerificationStatusEnum.VERIFIED &&
          ($setup.getIsOpenTradePassportasViewMode
            ? true
            : $setup.activeUserWorkstation?.workstationLinkTree) &&
          $setup.shouldShowBanner
        )
              ? (_openBlock(), _createBlock(_component_Card, {
                  key: 3,
                  class: "tw-p-5 !tw-max-h-[unset] !tw-mb-5"
                }, {
                  content: _withCtx(() => [
                    _createVNode(_component_WorkStationLinkTreeWidget, { isViewMode: $setup.getIsOpenTradePassportasViewMode }, null, 8, ["isViewMode"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_TradePassportProfileHeader, {
                class: "tw-inline rlg:!tw-hidden",
                userWorkStationImage: $setup.userWorkStationImage,
                userWorkStationBanner: $setup.userWorkStationBanner,
                contractorworkStationImage: $setup.contractorworkStationImage
              }, null, 8, ["userWorkStationImage", "userWorkStationBanner", "contractorworkStationImage"]),
              _createVNode(_component_TradePassportSummary, {
                userWorkStationImage: $setup.userWorkStationImage,
                userWorkStationBanner: $setup.userWorkStationBanner,
                contractorworkStationImage: $setup.contractorworkStationImage,
                userServices: $setup.filterUserSpecialisms,
                onOnAddRates: $setup.onClickUpdateUserRates
              }, null, 8, ["userWorkStationImage", "userWorkStationBanner", "contractorworkStationImage", "userServices", "onOnAddRates"]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_TradePassportProfileHeader, {
                  class: "tw-hidden rlg:!tw-inline",
                  userWorkStationImage: $setup.userWorkStationImage,
                  userWorkStationBanner: $setup.userWorkStationBanner,
                  contractorworkStationImage: $setup.contractorworkStationImage,
                  onRouteToReviewsTab: $setup.routeToReviewsTab
                }, null, 8, ["userWorkStationImage", "userWorkStationBanner", "contractorworkStationImage", "onRouteToReviewsTab"]),
                _createVNode(_component_TradePassportAbout, {
                  userDescription: $setup.userDescriptionData,
                  onOnHandleClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onEditDescription($setup.userDescriptionData)))
                }, null, 8, ["userDescription"]),
                _createVNode(_component_TradePassportServices, {
                  isLoading: $setup.specialismsLoading,
                  filterUserSpecialisms: $setup.filterUserSpecialisms
                }, null, 8, ["isLoading", "filterUserSpecialisms"]),
                _createVNode(_component_TradePassportInsurances, { userInsurances: $setup.userInsurances }, null, 8, ["userInsurances"]),
                _createVNode(_component_TradePassportCertificate, { userCertificates: $setup.userCertificates }, null, 8, ["userCertificates"]),
                _createVNode(_component_TradePassportExperience),
                _createVNode(_component_TradePassportClients),
                _createVNode(_component_TradePassportDbsPoliceCheck),
                _createVNode(_component_TradePassportGalleryAttachments, {
                  fileList: $setup.fileList,
                  isLoading: $setup.state.galleryIsLoading,
                  onRouteToGalleryTab: $setup.onClickManageGallery,
                  onOnOpenDeleteConfirmationModal: _cache[2] || (_cache[2] = ($event: any) => (
              $setup.onOpenDeleteConfirmationModal($event)
            )),
                  onOnOpenPhotoPreviewModal: _cache[3] || (_cache[3] = ($event: any) => ($setup.onOpenPhotoPreviewModal($event))),
                  onOnOpenVideo: _cache[4] || (_cache[4] = ($event: any) => ($setup.onOpenVideo($event)))
                }, null, 8, ["fileList", "isLoading", "onRouteToGalleryTab"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    ($setup.state.alert)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 0,
          class: "tw-absolute tw-bottom-[1rem] tw-right-0",
          transition: "slide-y-transition",
          title: $setup.state.alertConfig.title,
          text: $setup.state.alertConfig.text,
          type: $setup.state.alertType === 'success' ? $setup.state.alertType : 'error'
        }, null, 8, ["title", "text", "type"]))
      : _createCommentVNode("", true),
    ($setup.activeTab === 'Certifications')
      ? (_openBlock(), _createBlock(_component_TradePassportCertification, { key: 1 }))
      : _createCommentVNode("", true),
    ($setup.activeTab === 'Insurances')
      ? (_openBlock(), _createBlock(_component_TradePassportInsurance, { key: 2 }))
      : _createCommentVNode("", true),
    ($setup.activeTab === 'Services')
      ? (_openBlock(), _createBlock(_component_TradePassportSpecialism, {
          key: 3,
          class: "tw-relative"
        }))
      : _createCommentVNode("", true),
    ($setup.activeTab === 'Gallery')
      ? (_openBlock(), _createBlock(_component_TradePassportGallery, {
          key: 4,
          onOnChangeGallery: $setup.onChangeGallery
        }, null, 8, ["onOnChangeGallery"]))
      : _createCommentVNode("", true),
    ($setup.activeTab === 'Jobs')
      ? (_openBlock(), _createBlock(_component_TradePassportJobs, { key: 5 }))
      : _createCommentVNode("", true),
    ($setup.activeTab === 'Reviews')
      ? (_openBlock(), _createBlock(_component_TradePassportReviews, { key: 6 }))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.CERTIFICATION)
      ? (_openBlock(), _createBlock(_component_CertificateEditModal, {
          key: 7,
          onOnSaveData: $setup.onClickUpdateCertificate,
          onOnClickCloseTab: $setup.onClickCloseModel,
          userCertificateData: $setup.userCertificateData
        }, null, 8, ["onOnSaveData", "onOnClickCloseTab", "userCertificateData"]))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.INSURANCE)
      ? (_openBlock(), _createBlock(_component_AddInsuranceModal, {
          key: 8,
          onOnClickCloseTab: $setup.onClickCloseModel,
          isOuterEditInsurance: $setup.isOuterEditInsurance,
          userInsuranceData: $setup.userInsuranceData
        }, null, 8, ["onOnClickCloseTab", "isOuterEditInsurance", "userInsuranceData"]))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.DESCRIPTION)
      ? (_openBlock(), _createBlock(_component_DescriptionEditorComponent, {
          key: 9,
          onOnClickCloseTab: $setup.onClickCloseModel,
          isOuterEditDescription: $setup.isOuterEditDescription,
          userDescriptionData: $setup.userDescriptionData,
          onUserDescriptionData: $setup.userDescription,
          HeaderTittle: $setup.HeaderTittle
        }, null, 8, ["onOnClickCloseTab", "isOuterEditDescription", "userDescriptionData", "onUserDescriptionData", "HeaderTittle"]))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.TAXANDVAT)
      ? (_openBlock(), _createBlock(_component_TaxAndVatModel, {
          key: 10,
          onOnClickCloseTab: $setup.onClickCloseModel,
          mode: "Edit"
        }, null, 8, ["onOnClickCloseTab"]))
      : _createCommentVNode("", true),
    ($setup.isShowEditWorkStationModal)
      ? (_openBlock(), _createBlock(_component_EditWorkStationDetailsPopup, {
          key: 11,
          postCode: 
      $setup.workstationPostcode(
        $setup.activeUserWorkstation?.user?.userBusinessDetails?.type
      )
    ,
          workstationAddressValue: 
      $setup.workstationAddress(
        $setup.activeUserWorkstation?.user?.userBusinessDetails?.type
      ) !== 'null null'
        ? $setup.workstationAddress(
            $setup.activeUserWorkstation?.user?.userBusinessDetails?.type
          ) || undefined
        : undefined
    ,
          onCloseEditWorkStationModal: $setup.closeEditWorkStationModal
        }, null, 8, ["postCode", "workstationAddressValue", "onCloseEditWorkStationModal"]))
      : _createCommentVNode("", true),
    ($setup.isShowUserRateModal)
      ? (_openBlock(), _createBlock(_component_AddUserRatesModal, {
          key: 12,
          onCloseUpdateUserRatesModal: $setup.closeUpdateUserRatesModal
        }, null, 8, ["onCloseUpdateUserRatesModal"]))
      : _createCommentVNode("", true),
    ($setup.isShowUserExperienceModal)
      ? (_openBlock(), _createBlock(_component_AddUserExperienceModal, {
          key: 13,
          onCloseUpdateUserExperienceModal: $setup.closeUpdateUserExperienceModal
        }, null, 8, ["onCloseUpdateUserExperienceModal"]))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.TEAMMEMBERS)
      ? (_openBlock(), _createBlock(_component_TeamMemberModal, {
          key: 14,
          onOnClickCloseTab: $setup.onClickCloseModel
        }, null, 8, ["onOnClickCloseTab"]))
      : _createCommentVNode("", true),
    ($setup.isOpenConfirmDelete)
      ? (_openBlock(), _createBlock(_component_ConfirmDeleteModal, {
          key: 15,
          type: $setup.confirmDeleteType,
          label: $setup.confirmDeleteLabel,
          onOnClose: $setup.onCloseConfirmDelete,
          onOnDelete: $setup.deleteItem
        }, null, 8, ["type", "label", "onOnClose", "onOnDelete"]))
      : _createCommentVNode("", true),
    ($setup.isShowImportWorkStation)
      ? (_openBlock(), _createBlock(_component_ImportWorkStationModal, {
          key: 16,
          onOnCloseImportWorkstationModal: $setup.onCloseImportWorkstationModal
        }, null, 8, ["onOnCloseImportWorkstationModal"]))
      : _createCommentVNode("", true),
    ($setup.isShowSelectTeamMember)
      ? (_openBlock(), _createBlock(_component_SelectTeamMemberModal, {
          key: 17,
          onOnCloseModal: $setup.onCloseSelectTeam,
          onOnClickContinue: $setup.onClickContinueSelectTeamMember
        }, null, 8, ["onOnCloseModal", "onOnClickContinue"]))
      : _createCommentVNode("", true),
    ($setup.isShowAssignModal)
      ? (_openBlock(), _createBlock(_component_AssignModal, {
          key: 18,
          onOnCloseModal: $setup.onCloseAssignModal,
          onOnContinueButtonClick: $setup.onContinueButtonClick
        }, null, 8, ["onOnCloseModal", "onOnContinueButtonClick"]))
      : _createCommentVNode("", true),
    ($setup.isShowConfirmAssignModal)
      ? (_openBlock(), _createBlock(_component_ConfirmAssignModal, {
          key: 19,
          onOnCloseConfirmModal: $setup.onCloseConfirmAssignModal,
          onOnclickNo: $setup.onClickNoConfirmAssignModal
        }, null, 8, ["onOnCloseConfirmModal", "onOnclickNo"]))
      : _createCommentVNode("", true),
    ($setup.modals.deleteConfirmation)
      ? (_openBlock(), _createBlock(_component_DocumentDeleteConfirmationModal, {
          key: 20,
          onOnClose: $setup.onCloseDeleteConfirmationModal,
          selectedDocument: $setup.state.selectedDocument,
          deleteType: "files",
          isGallery: true,
          onOnSuccess: _cache[5] || (_cache[5] = ($event: any) => ($setup.onSuccess('delete'))),
          onOnSuccessGallery: _cache[6] || (_cache[6] = ($event: any) => (
      $setup.getTradePassportGallery($event, true, $setup.state.selectedDocument?.id)
    ))
        }, null, 8, ["onOnClose", "selectedDocument"]))
      : _createCommentVNode("", true),
    ($setup.modals.photoPreview)
      ? (_openBlock(), _createBlock(_component_GalleryPhotoPreview, {
          key: 21,
          file: $setup.state.selectedDocument,
          onOnClose: $setup.onClosePhotoPreviewModal
        }, null, 8, ["file", "onOnClose"]))
      : _createCommentVNode("", true),
    (
      $setup.userInsurenceExperiedStatus &&
      $setup.isShowFreezeModal &&
      $setup.isWorkStationVerified &&
      !$setup.isUserProprtyOwner
    )
      ? (_openBlock(), _createBlock(_component_FreezeInsuranceExpired, {
          key: 22,
          onOnClose: $setup.onCloseFreezeModal
        }, null, 8, ["onOnClose"]))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.ADDBUSINESSADDRESSMODAL)
      ? (_openBlock(), _createBlock(_component_AddBusinessAddressPreviewModal, {
          key: 23,
          onOnClickCloseTab: $setup.onClickCloseModel,
          onOnClickCloseSuccessTab: $setup.onClickCloseSuccessModal
        }, null, 8, ["onOnClickCloseTab", "onOnClickCloseSuccessTab"]))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.VERIFYPREVIEWMODAL)
      ? (_openBlock(), _createBlock(_component_CompleteIDVerificationPreviewModal, {
          key: 24,
          onOnClickCloseTab: $setup.onClickCloseModel
        }, null, 8, ["onOnClickCloseTab"]))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.TRADEPASSPORTPREVIEWMODAL)
      ? (_openBlock(), _createBlock(_component_TradePassportPreviewModal, {
          key: 25,
          onOnClickCloseTab: $setup.onClickCloseModel
        }, null, 8, ["onOnClickCloseTab"]))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.WORKSTATIONVERIFYPREVIEWMODAL)
      ? (_openBlock(), _createBlock(_component_WorkstationForVerificationPreviewModal, {
          key: 26,
          onOnClickCloseTab: $setup.onClickCloseWsVerificationPreviewModel
        }, null, 8, ["onOnClickCloseTab"]))
      : _createCommentVNode("", true),
    ($setup.openedModel === $setup.modelName.VERIFYMODAL)
      ? (_openBlock(), _createBlock(_component_WorkstationVerificationModal, {
          key: 27,
          isShowKyb: $setup.userBusinessType === $setup.BusinessDetailTypeEnum.LIMITED_COMPANY,
          onClickCloseTab: $setup.onClickCloseModel
        }, null, 8, ["isShowKyb", "onClickCloseTab"]))
      : _createCommentVNode("", true)
  ], 64))
}