<template>
  <Card
    class="left_task_container"
    v-for="(phase, index) of estimatePhases"
    :key="phase.id"
  >
    <template #content>
      <section
        class="small-phase-item tw-flex tw-h-full tw-border-solid tw-border tw-border-[#dfdfdf] tw-rounded-lg tw-p-2 tw-bg-[#f8fbfd]"
        :class="{ '': noPaddingY }"
      >
        <div class="border-0 tw-w-full tw-flex tw-items-center">
          <div name="1" class="phase-content">
            <div class="task_left_icons">
              <PhaseTitle
                :textColor="phase?.color"
                :estimate="phase"
                :title="phase?.name"
                :isPolicyPhase="phase?.isPolicyPhase"
                :policyIndex="phase?.policyIndex + 1"
                :phaseNumber="index + 1"
              />
              <div class="custom_icons">
                <v-icon v-if="phase?.isLock">
                  <img src="../../../../assets/icons/lock.svg" alt="" />
                </v-icon>
                <v-icon
                  v-if="phase?.estimationPhaseTasks?.length"
                  @click="onClickTaskTab(index)"
                  icon="mdi-chevron-down"
                ></v-icon>
              </div>
            </div>
            <div :id="`child-task-${index}`" class="hide">
              <div
                v-for="subTask of phase?.estimationPhaseTasks"
                :key="subTask?.id"
                class="tw-w-full tw-flex tw-items-center tw-my-[17px] sub-tasks tw-h-[32px]"
              >
                <p
                  class="subtask_text tw-text-xs tw-font-semibold tw-tracking-[0.25px] tw-py-2 tw-px-4"
                  :style="{ color: `${phase.color}` }"
                >
                  {{ subTask?.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </Card>
</template>
<script setup>
import Card from "@/core/components/ui/general/Card.vue";
import PhaseTitle from "@/modules/project/components/common/PhaseTitle";
import { ref, watch } from "vue";
const props = defineProps({
  estimatePhases: Array,
  chartClickTask: Object,
  noPaddingY: Boolean,
});
const emits = defineEmits(["on-click-task"]);
const onClickTaskTab = (taskIndex) => {
  const subTaskItemElement = document.getElementById(`child-task-${taskIndex}`);

  if (subTaskItemElement) subTaskItemElement.classList.toggle("hide");
  emits("on-click-task", {
    taskIndex,
    value: subTaskItemElement.classList.contains("hide") ? false : true,
    type: "sideBar",
  });
};
watch(
  () => props.chartClickTask,
  (newValue) => {
    const subTaskItemElement = document.getElementById(
      `child-task-${newValue.taskIndex}`
    );
    if (
      subTaskItemElement &&
      subTaskItemElement.classList.contains("hide") === newValue.value
    )
      onClickTaskTab(newValue.taskIndex);
  }
);
</script>
<script>
export default {
  name: "SmallPhaseCollapseItem",
};
</script>
<style lang="scss" scoped>
.small-phase-item {
  &__item {
    border-right: 1px solid rgba(12, 15, 74, 0.2);

    &:last-child {
      border-right: unset;
    }
  }
}
:deep(.el-collapse-item__header) {
  @apply tw-border-0;
}
:deep(.el-collapse-item__wrap) {
  @apply tw-border-0;
}

.small-phase-item {
  :deep(.el-collapse) {
    .el-collapse-item {
      width: 100%;
      border-bottom: 1px dashed rgba($black, 0.1);
      .el-collapse-item__header {
        height: 100%;
        padding: 13px 0;
      }
      .el-collapse-item__wrap {
        .el-collapse-item__content {
          padding: 0;
        }
      }
    }
    .is-active {
      transition: 0.5s ease all;
    }
  }
}
.sub-tasks:last-child {
  margin-bottom: 0;
}
.hide {
  display: none;
}

.left_task_container:last-child {
  margin-bottom: 22px;
}
.custom_icons {
  display: flex;
  gap: 10px;
  align-items: center;
}
.task_left_icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.phase-content {
  width: 100%;
}
.subtask_text {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card {
  box-shadow: none !important;
}
</style>
