<template>
  <div
    class="tw-w-full md:tw-absolute md:tw-z-[999] md:tw-bottom-[60px] md:tw-w-full md:tw-max-w-full md:tw-h-auto md:tw-overflow-y-hidden md:tw-overflow-x-auto md:tw-flex md:tw-items-end working-1 tw-max-h-[620px] tw-overflow-auto no-scrollbar md:tw-justify-center"
    :class="{
      'md:!tw-pr-8 md:!tw-h-full md:!tw-justify-center md:!tw-items-center md:!tw-bottom-0 md:!tw-top-16 md:!tw-z-[1] rmd:!tw-flex rmd:!tw-items-center rmd:!tw-justify-center rmd:!tw-h-[calc(100dvh-11rem)] rmd:!tw-max-h-[unset]':
        !loading && !jobsState.length,
    }"
  >
    <template v-if="!loading && !jobsState.length">
      <div class="empty__content !tw-pb-0 !tw-box-border !tw-h-auto">
        <div
          class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <div class="empty__banner__img md:!tw-w-[50%]">
            <img
              :src="require('../../../assets/images/jobs-empty.png')"
              alt=""
              class="tw-w-full"
            />
          </div>
          <div v-if="!isUserProprtyOwner" class="empty__banner__content">
            <h4>
              You don't have any<br class="tw-hidden md:!tw-flex" />
              jobs yet!
            </h4>
            <p>
              Start exploring new opportunities and find the perfect job to
              match your skills and schedule.
            </p>
          </div>
          <div v-else class="empty__banner__content">
            <h4>Don't have any jobs yet!</h4>
            <p>All job listings will be displayed here.</p>
          </div>

          <div class="empty__banner__footer tw-w-full">
            <v-btn
              @click="onClickFindJobs"
              v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
              class="button button-purple"
              width="100%"
              >Find Jobs</v-btn
            >
          </div>
        </div>
      </div>
    </template>
    <!-- <CommonLoader :loading="loading" class="loader-container" /> -->
    <div
      v-if="loading"
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-[calc(100dvh-221px)]"
    >
      <Loader :show="loading" />
    </div>
    <div
      v-if="jobsState.length && !loading"
      class="tw-flex flex-row tw-gap-5 tw-flex-wrap"
    >
      <JobCard
        v-for="job in jobsState"
        :key="job.refId"
        @onSelect="setSelectedJob"
        :value="job.refId"
        :job="job"
        jobType="active"
        :hasProgressBar="true"
        :class="`${
          selectedJob === job.refId ? 'tw-shadow-[0_0_0_4px_#FAA100_inset]' : ''
        }`"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { JOBS_ROUTE } from "@/modules/jobs/routes";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import JobCard from "@/modules/jobs/components/JobCard.vue";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import debounce from "lodash/debounce";
const store = useStore();
import Loader from "@/core/components/common/Loader.vue";
const router = useRouter();
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { JOBS_STORE } from "@/store/modules/jobs";

import { JOB_DETAILS_ROUTE } from "@/modules/jobs/routes";
import { JobsState } from "@/core/models/jobs";

const getUserWorkStationFieldAccess = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const loading = ref(false);
const toggledView = ref("tile");
const selectedJob = ref("");

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const setSelectedJob = async (value: string) => {
  store.commit(`${JOBS_STORE}/setJobDetails`, {});
  router.push({ name: JOB_DETAILS_ROUTE, params: { refId: value } });
  selectedJob.value = value;
};

const jobsState = computed<JobsState["jobs"]>(() => {
  const result = store.getters[`${JOBS_STORE}/getCompletedJobs`]
    .map((job: any) => ({
      ...job,
      properties: job.projectProperties
        ?.filter((projProperty: any) => projProperty.property)
        ?.map((projProperty: any) => projProperty.property),
    }))
    .filter((property: any) => property);
  console.log("result ", result);
  return result;
});

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);
const contractorTradePassportWorkstationId = computed(
  () =>
    store.getters[
      `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
    ]
);
const onClickFindJobs = () => {
  router.push({ name: JOBS_ROUTE });
};

const onClickToggleView = (value: string) => {
  toggledView.value = value;
};

onMounted(async () => {
  loading.value = true;
  try {
    if (isUserProprtyOwner.value) {
      await store.dispatch(`${JOBS_STORE}/fetchCompletedJobs`, {
        workStationId: contractorTradePassportWorkstationId.value,
      });
    } else {
      await store.dispatch(`${JOBS_STORE}/fetchCompletedJobs`, {
        workStationId: activeUserWorkstation.value.id,
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});
</script>

<style lang="scss" scoped>
/*Empty Screen */
.empty__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty__banner {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    @include respond(sm) {
      width: 100%;
    }
    // .empty__banner__img {
    //   img {
    //     @include respond(s720) {
    //       width: 100%;
    //       max-width: 100%;
    //     }
    //   }
    // }
    .empty__banner__content {
      margin-top: 46px;
      @include respond(sm) {
        margin: 0;
      }
      h4 {
        @include fluidFont(28, 28, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 1);
      }
      p {
        margin-top: 16px;
        @include fluidFont(14, 14, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.15px;
      }
      .empty__banner__content__list {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0 27px;
        .list__item {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          .v-icon {
            @include fluidFont(24, 24, 1);
            color: rgba($skyBlue, 1);
          }
          p {
            @include fluidFont(14, 14, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            margin: 0;
            text-align: left;
          }
        }
      }
    }
    .empty__banner__footer {
      margin-top: 36px;
      @include respond(sm) {
        margin-top: 16px;
      }
    }
  }
}
</style>
