<template>
  <ChipState
    class="tw-border-solid tw-border-[1px] tw-border-[#FA4B00]"
    :label="label"
    textColor="#FA4B00"
    bgColor="#D9EFD9"
  >
    <!-- <template #prefix>
      <v-icon icon="mdi-alert-circle" color="#FFA500"></v-icon>
    </template> -->
  </ChipState>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import { USER_STORE } from "@/store/modules/user";
import ChipState from "@/core/components/common/ChipState.vue";
import {
  BusinessDetailTypeEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";

// const props = defineProps({
//   label: {
//     type: String,
//     default: "KYC VERIFICATION Required",
//   },
// });

const store = useStore();

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const userBusinessType = computed(() => user.value?.userBusinessDetails?.type);

const label = computed(() => {
  return userBusinessType.value === BusinessDetailTypeEnum.SOLETRADER
    ? "KYC Verification Required"
    : "KYB Verification Required";
});
</script>
<style lang="scss" scoped></style>
