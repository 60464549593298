<template>
  <GeneralDialog
    class=""
    @on-close="onCloseModal"
    width="350px"
    max-width="100%"
  >
    <template #body>
      <div class="payment__successful tw-text-center">
        <div class="payment_success_icon">
          <img src="../../../assets/icons/payment-success.svg" alt="" />
        </div>
        <h4
          class="tw-mt-8 tw-text-xl tw-tracking-[0.15px] tw-leading-6 tw-font-bold tw-text-[#0C0F4A]"
        >
          Payment Successful
        </h4>
        <p
          class="tw-text-sm tw-tracking-[0.4px] tw-leading-5 tw-font-normal tw-text-[#0C0F4A] tw-mt-5"
        >
          Congratulations! 🥳 <br />Your project is ready to go
        </p>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-items-center">
        <Button label="done" @click="onConfirm" variant="accent" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ContractSignYellowIcon from "@/core/components/icons/ContractSignYellowIcon.vue";
import { defineEmits } from "vue";

const emits = defineEmits(["on-close", "on-confirm"]);
const onCloseModal = () => {
  emits("on-close");
};

const onConfirm = () => {
  emits("on-confirm");
};
</script>
