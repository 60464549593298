<template>
  <section
    class="tw-w-full tw-flex tw-items-center tw-justify-between md:!tw-gap-[.5rem] md:!tw-flex-col"
  >
    <div class="tw-flex tw-items-center tw-w-auto md:!tw-w-full">
      <InputField
        v-if="!isPropertyDocument"
        prepend-inner-icon="mdi-magnify"
        class="!tw-min-w-[400px] md:!tw-min-w-[unset] md:!tw-w-full"
        placeholder="Search"
        v-model="filters.keywords"
        @input="onHandleSearchAction"
      />
    </div>
    <div
      class="tw-flex tw-items-center tw-gap-4 md:!tw-w-full md:!tw-justify-end md:!tw-gap-[.2rem]"
    >
      <v-menu v-if="!isPropertyDocument">
        <template v-slot:activator="{ props }">
          <Button
            v-bind="props"
            variant="text"
            :isCapitalize="true"
            label="Sort by"
            class="!tw-w-[auto]"
            :ripple="false"
          >
            <template #prefix>
              <SortIcon />
            </template>
          </Button>
        </template>
        <DocumentSortMenu
          @set-selected-sort="setSelectedSortMenu"
          :selectedSortMenu="state.selectedSortMenu"
        />
      </v-menu>
      <v-menu
        v-if="(isPropertyDocument && isUserHasEditRight) || !isPropertyDocument"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="getUserWorkStationFieldAccess"
            v-bind="props"
            :isCapitalize="true"
            label="New"
            class="button button-orange !tw-flex !tw-w-full rmd:!tw-w-auto"
            :ripple="false"
            prepend-icon="mdi-plus-circle"
          >
            <!-- <PlusFilledYellowIcon /> -->

            New Documents
          </v-btn>
        </template>
        <AddDocumentMenu @on-handle-action="onHandleAction" />
      </v-menu>
    </div>

    <!-- MODALS -->
    <CreateNewFolderModal
      v-if="modals.newFolderModal"
      @on-close="onCloseNewFolderModal"
      @on-success="onUploadSuccess"
      :isPropertyDocument="isPropertyDocument"
    />
    <FileUploadModal
      v-if="modals.fileUploadModal"
      @on-close="onCloseFileUploadModal"
      @on-success="onUploadSuccess"
      :isGallery="isGallery"
      :forDocuments="true"
      :isPropertyDocument="isPropertyDocument"
    />
    <FolderUploadModal
      v-if="modals.addFolderModal"
      @on-close="onCloseAddFolderModal"
      @on-success="onUploadSuccess"
      :isPropertyDocument="isPropertyDocument"
    />
  </section>
</template>
<script setup>
import { reactive, computed } from "vue";
import Button from "@/core/components/ui/general/Button.vue";
import PlusFilledYellowIcon from "@/core/components/icons/PlusFilledYellowIcon.vue";
import AddDocumentMenu from "@/modules/documents/components/AddDocumentMenu.vue";
import SortIcon from "@/core/components/icons/SortIcon.vue";
import InputField from "@/core/components/common/InputField.vue";
import DocumentSortMenu from "@/modules/documents/components/DocumentSortMenu.vue";
import CreateNewFolderModal from "@/modules/documents/components/modal/CreateNewFolderModal.vue";
import FileUploadModal from "@/modules/documents/components/modal/FileUploadModal.vue";
import FolderUploadModal from "@/modules/documents/components/modal/FolderUploadModal.vue";
import documentConstants from "@/modules/documents/contants";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { WORKSTATION } from "@/store/modules/workstation";
import { ref } from "vue";
import { CONNECTIONS } from "@/store/modules/connections";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";

const emits = defineEmits(["on-success"]);

const props = defineProps({
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
  isPropertyDocument: {
    type: Boolean,
    default: false,
  },
  isGallery: Boolean,
});
const store = useStore();
const filters = reactive({
  page: "1",
  limit: "20",
  keywords: "",
});
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const hasParentParam = null;
const documentPromises = [];

const setFolderList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFolderList`, params);
};

const setFileList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFileList`, params);
};

const state = reactive({
  selectedSortMenu: documentConstants.ALL,
});

const modals = reactive({
  newFolderModal: false,
  addFolderModal: false,
  fileUploadModal: false,
});

const onOpenNewFolderModal = () => {
  modals.newFolderModal = true;
};

const onCloseNewFolderModal = () => {
  modals.newFolderModal = false;
  // onHandleSearchAction();
};

const onOpenAddFolderModal = () => {
  modals.addFolderModal = true;
};

const onCloseAddFolderModal = () => {
  modals.addFolderModal = false;
  // onHandleSearchAction();
};

const onOpenFileUploadModal = () => {
  modals.fileUploadModal = true;
};

const onCloseFileUploadModal = () => {
  modals.fileUploadModal = false;
  // onHandleSearchAction();
};

const onHandleAction = (name) => {
  if (name === documentConstants.NEW_FOLDER) {
    onOpenNewFolderModal();
  }
  if (name === documentConstants.FOLDER) {
    onOpenAddFolderModal();
  }
  if (name === documentConstants.FILE) {
    onOpenFileUploadModal();
  }
};

const getUserDocumentCalculatedSize = async (params) => {
  return await store.dispatch(
    `${DOCUMENTS_STORE}/getUserDocumentCalculatedSize`,
    params
  );
};

let debounceTimeout;

const onHandleSearchAction = async () => {
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(async () => {
    let params = {};
    if (filters.keywords?.length >= 2) {
      params = {
        userId: user.value?.id,
        options: {
          search: filters.keywords,
        },
      };
      const userDocumentList = await store.dispatch(
        `${DOCUMENTS_STORE}/getUserDocuments`,
        params
      );
      let folderList = [];
      let fileList = [];
      if (userDocumentList.length) {
        userDocumentList.map((item) => {
          if (item.isDir) {
            folderList.push(item);
          } else {
            fileList.push(item);
          }
        });
      }
      setFileList(fileList);
      setFolderList(folderList);
    } else {
      params = {
        userId: user.value?.id,
        options: {},
      };
      try {
        const userDocuments = await store.dispatch(
          `${DOCUMENTS_STORE}/getUserDocuments`,
          params
        );
        state.currentFolderList = userDocuments.filter(
          (userDocument) =>
            userDocument.isDir === true &&
            userDocument.parentId === hasParentParam
        );

        state.currentFileList = userDocuments.filter(
          (userDocument) =>
            !userDocument.isDir && userDocument.parentId === hasParentParam
        );

        state.currentFileList = state.currentFileList.map((file) => {
          return { ...file, name: file.originalName, attachment: file.name };
        });

        state.currentFolderList.forEach((folderItem) => {
          documentPromises.push(
            getUserDocumentCalculatedSize({
              userId: user.value?.id,
              userDocumentId: folderItem.id,
            })
          );
        });

        // Wait for all promises to resolve
        await Promise.all(documentPromises);

        if (documentPromises.length) {
          const documentFolderSizes = await Promise.all(documentPromises);
          state.currentFolderList = state.currentFolderList.map((item) => {
            const findFolder = documentFolderSizes.find(
              (x) => x.id === item.id
            );

            return { ...item, ...findFolder };
          });
        }
        setFolderList(state.currentFolderList);
        setFileList(state.currentFileList);
      } catch (e) {
        const formError = "Error getting list. Please try again.";
        // Note: Add notification logic
      }
    }
  }, 500);
};

// const onHandleSearchAction = async () => {
//   let params = {};
//   if (filters.keywords?.length >= 2) {
//     params = {
//       userId: user.value?.id,
//       options: {
//         search: filters.keywords,
//       },
//     };
//     const userDocumentList = await store.dispatch(
//       `${DOCUMENTS_STORE}/getUserDocuments`,
//       params
//     );
//     userDocumentList.map((item) => {
//       if (item.isDir) {
//         setFolderList(userDocumentList);
//         setFileList([]);
//       } else {
//         setFileList(userDocumentList);
//         setFolderList([]);
//       }
//     });
//   } else {
//     params = {
//       userId: user.value?.id,
//       options: {},
//     };
//     try {
//       const userDocuments = await store.dispatch(
//         `${DOCUMENTS_STORE}/getUserDocuments`,
//         params
//       );
//       state.currentFolderList = userDocuments.filter(
//         (userDocument) =>
//           userDocument.isDir === true &&
//           userDocument.parentId === hasParentParam
//       );

//       state.currentFileList = userDocuments.filter(
//         (userDocument) =>
//           !userDocument.isDir && userDocument.parentId === hasParentParam
//       );

//       state.currentFileList = state.currentFileList.map((file) => {
//         return { ...file, name: file.originalName, attachment: file.name };
//       });

//       state.currentFolderList.forEach((folderItem) => {
//         documentPromises.push(
//           getUserDocumentCalculatedSize({
//             userId: user.value?.id,
//             userDocumentId: folderItem.id,
//           })
//         );
//       });

//       // Wait for all promises to resolve
//       await Promise.all(documentPromises);

//       if (documentPromises.length) {
//         const documentFolderSizes = await Promise.all(documentPromises);
//         state.currentFolderList = state.currentFolderList.map((item) => {
//           const findFolder = documentFolderSizes.find((x) => x.id === item.id);

//           return { ...item, ...findFolder };
//         });
//       }
//       setFolderList(state.currentFolderList);
//       setFileList(state.currentFileList);
//     } catch (e) {
//       const formError = "Error getting list. Please try again.";
//       // Note: Add notification logic
//       console.log(formError);
//     }
//   }
// };

const setSelectedSortMenu = (event) => {
  state.selectedSortMenu = event;
};

const onUploadSuccess = (event) => {
  emits("on-success", event);
};

const currentPropertyConnectionRole = computed(
  () => store.getters[`${CONNECTIONS}/getPropertyConnectionRole`]
);
const isUserHasEditRight = computed(
  () =>
    currentPropertyConnectionRole.value !== PropertyConnectionRoleEnum.VIEWER
);
</script>
<style lang="scss" scoped></style>
