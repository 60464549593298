<template>
  <div class="batch_detail">
    <Button
      label="Back To Jobs"
      @click="onClickBackToJobs"
      variant="tertiary"
      class="button-transparent back__button"
      ><template #prefix
        ><v-icon
          icon="mdi-chevron-left"
          size="24"
          color="#0c0f4a"
        ></v-icon></template
    ></Button>
    <div class="batch_info">
      <div class="batch__title">
        <h2>Iknowa Batch #{{ batchDetail?.id }}</h2>
        <NwgJobStatusChip status="locked" />
      </div>
      <div class="batch_overview__list">
        <div class="batch_overview__list__item">
          <div class="left__block">
            <p>Created on</p>
          </div>
          <div class="right__block">
            <p>{{ formateDate(batchDetail?.dateCreated, "DD/MM/YYYY") }}</p>
          </div>
        </div>
        <!-- <div class="batch_overview__list__item">
          <div class="left__block">
            <p>Created By</p>
          </div>
          <div class="right__block">
            <p>Thomas Crossman</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="engineer_detail">
      <h5>Engineer Details</h5>
      <Button
        v-if="!batchDetail?.engineer"
        variant="tertiary"
        label="Assign Engineer"
        width="auto"
        class="button button-purple-border"
        @click="toggleAssignEngineerModal"
      />
      <EngineerDetailCard
        v-else
        :name="`${batchDetail?.engineer?.firstName} ${batchDetail?.engineer?.lastName}`"
        :profileImage="
          setDirectStoragePathToImage(
            batchDetail?.engineer?.userPublicProfile?.profileImage
          )
        "
        :postCode="getEngineerPostcode"
      />
    </div>

    <div class="customer_detail_list">
      <h5>Customer Details</h5>
      <el-table
        class="table_content__wrapper"
        :data="sortedJobList"
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column prop="id" width="128">
          <template #header>
            <span>NWG Job #</span>
            <img
              :class="{
                sort_arrow_rotate_up: sortingField.id.sortOrder === 'ASC',
              }"
              @click="onClickSortBy(sortingField.id)"
              src="@/assets/icons/down-arrow.svg"
            />
          </template>
        </el-table-column>

        <el-table-column prop="batchId" width="176">
          <template #header>
            <span>iknowa Batch #</span>
            <img
              :class="{
                sort_arrow_rotate_up: sortingField.batch.sortOrder === 'ASC',
              }"
              @click="onClickSortBy(sortingField.batch)"
              src="@/assets/icons/down-arrow.svg"
            />
          </template>
        </el-table-column>

        <el-table-column prop="dateCreated" width="176">
          <template #header>
            <span>Date Created</span>
            <img
              :class="{
                sort_arrow_rotate_up:
                  sortingField.dateCreated.sortOrder === 'ASC',
              }"
              @click="onClickSortBy(sortingField.dateCreated)"
              src="@/assets/icons/down-arrow.svg"
            />
          </template>
          <template #default="scope">
            {{
              formateDate(scope.row.dateCreated, "MMM D, YYYY") ||
              scope.row.dateCreated
            }}
          </template>
        </el-table-column>

        <el-table-column prop="status" width="176">
          <template #header>
            <span>Status</span>
            <img
              :class="{
                sort_arrow_rotate_up: sortingField.status.sortOrder === 'ASC',
              }"
              @click="onClickSortBy(sortingField.status)"
              src="@/assets/icons/down-arrow.svg"
            />
          </template>
          <template #default="scope">
            <NwgJobStatusChip :status="scope.row.status" />
          </template>
        </el-table-column>

        <el-table-column prop="customerName" width="232">
          <template #header>
            <span>Customer</span>
            <img
              :class="{
                sort_arrow_rotate_up:
                  sortingField.customerName.sortOrder === 'ASC',
              }"
              @click="onClickSortBy(sortingField.customerName)"
              src="@/assets/icons/down-arrow.svg"
            />
          </template>
        </el-table-column>

        <el-table-column prop="contractor" width="220">
          <template #header>
            <span>Contractor</span>
            <img
              :class="{
                sort_arrow_rotate_up:
                  sortingField.contractor.sortOrder === 'ASC',
              }"
              @click="onClickSortBy(sortingField.contractor)"
              src="@/assets/icons/down-arrow.svg"
            />
          </template>
          <template #default="scope">
            {{ scope.row?.contractorName }}
          </template>
        </el-table-column>

        <el-table-column width="129">
          <template #header>
            <span>Actions</span>
          </template>
          <template #default="scope">
            <el-link
              @click="onClickViewJob(scope.row)"
              type="primary"
              class="edit-link"
              >View</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="job_map_container">
      <h5>Job Map</h5>
      <div class="job_map_wrapper">
        <div class="map_left">
          <PropertyDetailCard
            v-for="job of batchDetail.jobs"
            :key="job.id"
            :property="job.property"
            :isSelected="selectedPropertyId === job.property.id"
            @visitProperty="onclickProperty(job.property.id)"
          />
        </div>
        <div class="map_right">
          <NWGPropertyMapComponent
            v-if="propertyList"
            className="custom_map"
            :properties="propertyList"
            @onClickMarker="onclickProperty"
            :selectedPropertyId="selectedPropertyId"
          />
        </div>
      </div>
    </div>
  </div>
  <AssignEngineerModal
    v-if="isShowAssignEnginnerModal"
    @on-close="toggleAssignEngineerModal"
  />
</template>

<script setup>
import { useStore } from "vuex";

import { useRouter } from "vue-router";
import { NWG_JOB_DETAIL_ROUTE, NWG_JOB_ROUTE } from "../routes";
import { computed, onMounted, ref } from "vue";
import { NWG_JOBS_STORE } from "@/store/modules/nwg-job";
import NWGPropertyMapComponent from "@/core/components/map/NWGPropertyMapComponent.vue";
import NwgJobStatusChip from "@/core/uni-components/Chips/NwgJobStatusChip.vue";
import PropertyDetailCard from "@/core/uni-components/PropertyDetailCard.vue";
import Button from "@/core/components/ui/general/Button.vue";
import AssignEngineerModal from "../components/models/AssignEngineerModal.vue";
import EngineerDetailCard from "../components/EngineerDetailCard.vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import { formateDate, setDirectStoragePathToImage } from "@/core/utils/common";
const store = useStore();
const router = useRouter();
const jobList = ref([]);
const loading = ref(false);
const selectedPropertyId = ref(0);
const sortingField = ref({
  id: { sortBy: "id", sortOrder: "ASC" },
  dateCreated: { sortBy: "dateCreated", sortOrder: "DESC" },
  status: { sortBy: "status", sortOrder: "DESC" },
  customerName: { sortBy: "customerName", sortOrder: "DESC" },
  batch: { sortBy: "batchId", sortOrder: "DESC" },
  contractor: { sortBy: "contractorName", sortOrder: "DESC" },
});
const isShowAssignEnginnerModal = ref(false);

const batchDetail = computed(
  () => store.getters[`${NWG_JOBS_STORE}/getBatchDetails`]
);
const propertyList = computed(
  () => batchDetail.value?.jobs.map((job) => job?.property) || []
);
const getEngineerPostcode = computed(() => {
  if (
    batchDetail.value?.engineer?.userBusinessDetails.type ===
    BusinessDetailTypeEnum.LIMITED_COMPANY
  ) {
    return batchDetail.value?.engineer?.userBusinessDetails?.businessPostcode;
  }
  return batchDetail.value?.engineer?.userBusinessDetails?.residencyPostcode;
});
const onClickBackToJobs = () => {
  router.push({ name: NWG_JOB_ROUTE });
};
const sortField = ref(null);
const onClickSortBy = (field) => {
  field.sortOrder = field.sortOrder === "ASC" ? "DESC" : "ASC";
  sortField.value = field;
};
const sortedJobList = computed(() => {
  if (sortField.value) {
    return jobList.value.sort((a, b) => {
      const fieldA = a[sortField.value.sortBy];
      const fieldB = b[sortField.value.sortBy];

      if (sortField.value.sortOrder === "DESC") {
        if (fieldA < fieldB) return 1;
        if (fieldA > fieldB) return -1;
        return 0;
      } else {
        // 'asc'
        if (fieldA < fieldB) return -1;
        if (fieldA > fieldB) return 1;
        return 0;
      }
    });
  } else {
    return jobList.value;
  }
});

const onClickViewJob = (event) => {
  if (event?.id)
    router.push({ name: NWG_JOB_DETAIL_ROUTE, params: { id: event.id } });
};

const onclickProperty = (propertyId) => {
  if (propertyId) selectedPropertyId.value = propertyId;
};

const toggleAssignEngineerModal = () => {
  isShowAssignEnginnerModal.value = !isShowAssignEnginnerModal.value;
};
onMounted(() => {
  if (batchDetail.value) {
    jobList.value = batchDetail.value?.jobs.map((job) => ({
      ...job,
      contractorId: batchDetail.value?.contractor?.id,
      contractorName: batchDetail.value?.contractor?.name,
      batchId: batchDetail.value.id,
    }));
  }
});
</script>
<script>
export default {};
</script>

<style lang="scss" scoped>
.batch_detail {
  text-align: left;
  width: 100%;
  max-width: 100%;
  .batch_info {
    padding-bottom: 40px;
    padding-top: 16px;
    .batch__title {
      padding-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        @include fluidFont(25, 25, 22.59px);
        font-weight: 400;
        color: rgba($blueDark, 1);
      }
    }
    .batch_overview__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &__item {
        display: flex;
        gap: 56px;
        align-items: center;
        .left__block {
          p {
            @include fluidFont(14, 14, 17.57px);
            font-weight: 500;
            color: rgba($blueDark, 1);
            letter-spacing: 0.25px;
          }
        }
        .right__block {
          p {
            @include fluidFont(14, 14, 17.57px);
            font-weight: 500;
            color: rgba($orange, 1);
            letter-spacing: 0.25px;
          }
        }
      }
    }
  }
  .engineer_detail {
    max-width: 412px;
    h5 {
      @include fluidFont(18, 18, 28px);
      font-weight: 700;
      color: rgba($blueDark, 1);
      margin-bottom: 16px;
    }
  }
  .customer_detail_list {
    padding-top: 48px;
    h5 {
      @include fluidFont(18, 18, 28px);
      font-weight: 700;
      color: rgba($blueDark, 1);
      margin-bottom: 16px;
    }
    .table_content__wrapper {
      :deep(.el-table__header-wrapper) {
        .el-table__header {
          width: 100% !important;
          max-width: 100%;
          thead {
            tr {
              th {
                background-color: rgba(249, 250, 251, 1);
                .cell {
                  display: flex;
                  gap: 4px;
                  align-items: center;
                  span {
                    @include fluidFont(12, 12, 18px);
                    font-weight: 500;
                    color: rgba(71, 84, 103, 1);
                    white-space: nowrap;
                  }
                }
              }
              .el-table__cell {
                border-right: none;
                padding: 10px 0;
              }
            }
          }
        }
      }
      :deep(.el-table__body-wrapper) {
        .el-scrollbar {
          .el-scrollbar__wrap {
            .el-scrollbar__view {
              width: 100%;
              max-width: 100%;
              .el-table__body {
                width: 100% !important;
                max-width: 100%;
                tbody {
                  .el-table__row {
                    cursor: pointer;
                    .el-table__cell {
                      padding: 12px 0;
                      @include fluidFont(14, 14, 20px);
                      font-weight: 500;
                      color: rgba(16, 24, 40, 1);
                      .cell {
                        .el-link--danger {
                          color: rgba(71, 84, 103, 1);
                          @include fluidFont(14, 14, 20px);
                          font-weight: 600;
                          text-decoration: none;
                          &:hover {
                            &:after {
                              border: none;
                            }
                          }
                        }
                        .el-link--primary {
                          color: rgba($orange, 1);
                          @include fluidFont(14, 14, 20px);
                          font-weight: 600;
                          text-decoration: none;
                          &:hover {
                            &:after {
                              border: none;
                            }
                          }
                        }
                      }
                      &:last-child {
                        .cell {
                          display: flex;
                          gap: 12px;
                          align-items: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .sort_arrow_rotate_up {
        transform: rotate(180deg);
      }
    }
  }

  .job_map_container {
    padding-top: 48px;
    h5 {
      @include fluidFont(18, 18, 28px);
      font-weight: 700;
      color: rgba($blueDark, 1);
      margin-bottom: 16px;
    }
    .job_map_wrapper {
      display: grid;
      grid-template-columns: 370px 1fr;
      grid-column-gap: 24px;
      @include respond(md) {
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
      }
      .map_left {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .map_right {
        height: 580px;
        .custom_map {
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
