<template>
  <GeneralDialog
    headerTitle="Move to"
    @on-close="onCloseModal"
    width="380px"
    maxWidth="100%"
    borderRadius="20px"
  >
    <template #body>
      <section
        class="modal-content tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
      >
        <div class="tw-flex tw-flex-col tw-gap-4 tw-justify-between tw-w-full">
          <div class="modal-content__content tw-flex tw-flex-col tw-gap-4">
            <div class="modal-content__content-list">
              <el-scrollbar>
                <div
                  class="modal-content__scrollbar-content tw-flex tw-flex-col tw-gap-4"
                >
                  <el-tree
                    :data="state.currentFolderList"
                    node-key="id"
                    :props="state.defaultProps"
                    @node-click="setActiveIndex"
                  >
                    <template #default="{ node, data }">
                      <span
                        class="modal-content__item tw-flex tw-items-center tw-gap-4"
                        :class="{ 'is-active': data.id === state.activeIndex }"
                      >
                        <DocumentFolderIcon :fill="data?.color" />
                        <Text variant="p">{{ node.label }}</Text>
                      </span>
                    </template>
                  </el-tree>
                </div>
              </el-scrollbar>
            </div>
            <v-divider></v-divider>
            <div
              class="modal-content__cta-container tw-flex tw-items-center tw-gap-4"
            >
              <div
                class="modal-content__cta-wrapper tw-flex tw-items-center tw-gap-4"
                v-if="!state.showInputCreateFolder"
                @click="state.showInputCreateFolder = true"
              >
                <DocumentFolderIcon />
                <Text variant="h6">Create new folder</Text>
              </div>
              <div class="modal-content__input-wrapper tw-grid" v-else>
                <v-form
                  :rules="validation"
                  ref="folderNameFormRef"
                  class="tw-w-full"
                >
                  <v-text-field
                    density="compact"
                    :rules="validation.folderName"
                    v-model="form.folderName"
                    variant="outlined"
                    class="tw-rounded-[8px]"
                    label="Enter folder name"
                  ></v-text-field>
                  <Text
                    variant="span"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >You have {{ getRemainingCharacters }} characters
                    remaining</Text
                  >
                </v-form>
                <div class="tw-flex tw-items-start tw-gap-2 tw-justify-end">
                  <v-icon
                    class="tw-cursor-pointer"
                    @click="create"
                    icon="mdi-check-circle"
                    color="#198754"
                  ></v-icon>
                  <v-icon
                    class="tw-cursor-pointer"
                    @click="state.showInputCreateFolder = false"
                    icon="mdi-close-circle"
                    color="#d11a2a"
                  ></v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-gap-3"
      >
        <Button
          :isActive="state.activeIndex !== null"
          label="move here"
          variant="accent"
          @click="onMove"
        />
      </section>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import documentsConstants from "@/modules/documents/contants";
import Card from "@/core/components/ui/general/Card.vue";
import DocumentFolderIcon from "@/core/components/icons/DocumentFolderIcon.vue";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";
import { PROPERTY_STORE } from "@/store/modules/property";

const emits = defineEmits(["on-close", "on-success-move"]);
const props = defineProps({
  selectedDocument: { type: Object },
  isPropertyDocument: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const route = useRoute();

const state = reactive({
  showInputCreateFolder: false,
  folderCount: 10,
  activeIndex: null,
  currentFolderList: [],

  folderTarget: {},
  isMoving: false,
  defaultProps: {
    children: "children",
    label: "name",
  },

  loading: false,
});

const colors = ["#FFA500", "#919191", "#2A52CB", "#615B52", "#68C693"];

const form = reactive({
  folderName: "",
});

const folderNameFormRef = ref(null);
const validation = ref({
  folderName: [
    (v) => !!v || "Folder name is required",
    (v) =>
      (!!v && v.length <= 50) || "Folder name must be less than 50 characters",
  ],
});

const getRemainingCharacters = computed(() => {
  const limit = 50;
  const remaining = limit - form.folderName.length;
  return remaining >= 0 ? remaining : 0;
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const openedFolder = computed(
  () => store.getters[`${DOCUMENTS_STORE}/openedFolder`]
);
const fileList = computed(() => store.getters[`${DOCUMENTS_STORE}/fileList`]);

const setActiveIndex = (folder) => {
  state.activeIndex = folder.id;

  state.folderTarget = folder;
};

const setFolderList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFolderList`, params);
};

const setFileList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFileList`, params);
};

const setDocumentList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setDocumentList`, params);
};

const getUserDocumentCalculatedSize = async (params) => {
  if (props.isPropertyDocument) {
    params.workStationId = propertyDetails.value?.userWorkstation?.id;
  }
  return await store.dispatch(
    `${DOCUMENTS_STORE}/getUserDocumentCalculatedSize`,
    params
  );
};

const listToTree = (list) => {
  const map = {};
  let node;
  const roots = [];
  let i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    // eslint-disable-next-line no-param-reassign
    list[i].children = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId) {
      const ref = list[map[node.parentId]];
      if (ref && "children" in ref) {
        ref.children.push(node);
      }
    } else {
      roots.push(node);
    }
  }

  return roots;
};

const getUserDocuments = async (params) => {
  const { id: userId } = user.value;
  const { folderTarget } = state;
  const { selectedDocument } = props;
  const { parentId } = folderTarget;
  const hasParentParam = parentId || null;
  let folders = null;
  let files = fileList.value;
  const documentPromises = [];
  try {
    const userDocuments = await store.dispatch(
      `${DOCUMENTS_STORE}/getUserDocuments`,
      params
    );

    folders = userDocuments.filter(
      (userDocument) =>
        userDocument.isDir === true && userDocument.parentId === hasParentParam
    );
    folders.forEach((folderItem) => {
      documentPromises.push(
        getUserDocumentCalculatedSize({
          userId,
          userDocumentId: folderItem.id,
        })
      );
    });

    // Wait for all promises to resolve
    await Promise.all(documentPromises);

    if (documentPromises.length) {
      const documentFolderSizes = await Promise.all(documentPromises);
      folders = folders.map((item) => {
        const findFolder = documentFolderSizes.find((x) => x.id === item.id);

        return { ...item, ...findFolder };
      });
    }

    files = userDocuments.filter(
      (userDocument) =>
        !userDocument.isDir && userDocument.parentId === hasParentParam
    );
    files = files.map((file) => {
      return { ...file, name: file.originalName, attachment: file.name };
    });
  } catch (error) {
    const formError = "Error getting documents. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    setFolderList(folders);
    setFileList(files);
    setDocumentList([...folders, ...files]);
  }
};

const onMove = async () => {
  const { folderTarget } = state;
  const { selectedDocument } = props;
  const { id: userId } = user.value;
  const { id } = openedFolder.value;

  const { parentId } = folderTarget;
  const hasParentParam = parentId || null;
  const params = {
    userId,
    formData: {
      parentId: folderTarget.id,

      // TD: should support mulitple
      documents: [
        {
          id: selectedDocument.id,
        },
      ],
    },
  };

  if (props.isPropertyDocument) {
    params.formData.workStationId = propertyDetails.value?.userWorkstation?.id;
    params.userId = propertyDetails.value?.propertyCreator?.id;
  }
  state.isMoving = true;

  try {
    await store.dispatch(`${DOCUMENTS_STORE}/moveUserDocuments`, params);
    // await getUserDocuments(params);
    emits("on-success-move", { parentId: id });
  } catch (error) {
    const formError = "Error moving document. Please try again.";
    // Note: Add notification logic
  } finally {
    state.isMoving = false;
    onCloseModal();
  }
};
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const currentPropertyDocumentData = computed(
  () => propertyDetails.value?.userDocument
);

const getFolderList = async () => {
  const { selectedDocument } = props;
  const { id: userId } = user.value;
  const params = { userId, options: { folderTree: 1 } };
  let list = null;

  if (props.isPropertyDocument) {
    params.formData.workStationId = propertyDetails.value?.userWorkstation?.id;
    params.userId = propertyDetails.value?.propertyCreator?.id;
  }
  try {
    const userDocuments = await store.dispatch(
      `${DOCUMENTS_STORE}/getUserDocuments`,
      params
    );
    list = userDocuments.filter(
      (userDocument) => userDocument.id !== selectedDocument.id
    );

    // Add color to list
    let numColors = colors.length;
    list = list.map((item, index) => {
      let color = colors[index % numColors];
      return { ...item, color };
    });
    state.currentFolderList = props.isPropertyDocument
      ? listToTree(list).filter(
          (document) => document.id === currentPropertyDocumentData.value.id
        )
      : listToTree(list);
  } catch (error) {
    const formError = "Error getting document. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  }
};

const getFolderListForCreateFolder = async (options = {}) => {
  const { id: userId } = user.value;
  const params = { userId };
  const { parentId } = options;
  const hasParentParam = parentId || null;
  let list = null;

  if (Object.keys(options).length) {
    params.options = options;
  }

  await getUserDocuments(params);
};

const create = async () => {
  const { id } = openedFolder.value;
  const parentId = id || null;
  const { id: userId } = user.value;
  const params = {
    userId,
    formData: {
      name: form.folderName,
      parentId,
    },
  };

  if (props.isPropertyDocument) {
    params.formData.workStationId = propertyDetails.value?.userWorkstation?.id;
    params.userId = propertyDetails.value?.propertyCreator?.id;
  }
  let docParams = {};
  if ("id" in openedFolder.value) {
    docParams = { parentId: id, isDir: 1 };
  }

  try {
    await store.dispatch(`${DOCUMENTS_STORE}/createUserDocument`, params);
    await getFolderList();
    // await getFolderListForCreateFolder(docParams);
  } catch (error) {
    const formError = "Error creating folder. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    state.loading = false;
    form.folderName = "";
    state.showInputCreateFolder = false;
    // onCloseModal();
  }
};

const onCloseModal = () => {
  emits("on-close");
};

onMounted(async () => {
  await getFolderList();
});
</script>

<style lang="scss" scoped>
.modal-content {
  &__header-title {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  &__actions {
    width: 100%;
  }
  &__cta-wrapper {
    width: 200px;
    cursor: pointer;
    margin: 1rem 0;
  }
  &__item {
    cursor: pointer;
    // padding: .7rem;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    &:hover {
      // background: rgba(255, 165, 0, 0.1);
      .modal-content__item-name {
        color: #ffa500;
      }
    }
  }
  &__item.is-active {
    // background: rgba(255, 165, 0, 0.1);
    .modal-content__item-name {
      color: #ffa500;
    }
  }
  &__item-name {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
  &__cta-text {
    font-size: 14px;
    font-weight: 600;
  }
  &__content-list {
    //height: 240px;
    margin-bottom: 0.7rem;
  }
  &__scrollbar-content {
    padding: 0 0.7rem 0.5rem 0;
    gap: 0.3rem;
  }
  &__input-wrapper {
    width: 100%;
    grid-template-columns: 3fr 1fr;
  }
}
:deep(.el-scrollbar__bar) {
  display: block !important;
}
:deep(.el-scrollbar__bar.is-vertical) {
  top: 0;
  right: 0;
  width: 6px !important;
}
.el-divider {
  margin: 0;
}
:deep(.el-tree-node__content) {
  height: 42px;
  border-radius: 8px;
}
:deep(.el-tree-node__content:hover) {
  background: rgba(255, 165, 0, 0.2);
}
:deep(.el-input__inner) {
  border-radius: 50px;
  border: none;
  background: rgba(12, 15, 74, 0.05);
  border: none;
  color: #111;
  border-radius: 30px;
  font-size: 14px;
  font-family: Mulish;
}
.active {
  background-color: #ffa500;
  color: #fff;

  &:hover {
    background-color: #ffa500;
    color: #fff;
  }
}
</style>
